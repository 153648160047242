import React from 'react';
import { modalActions} from "../../_actions";
import FullScreenModal from "../FullScreenModal";
import Dialog from "../Dialog";
import {connect} from "react-redux";
import {appHelpers} from "../../_helpers";

const DialogFullScreenModal = ({
                                 displayDialogAlert, dispatch, element, declineClicked,
                                 buttonDeclineText, approveClicked, buttonApproveText,
                                 requesting
  
}) => {
  return (
    <FullScreenModal
      show={displayDialogAlert.show}
      width={"35%"}
      callback={(res)  => {
        appHelpers.fullScreenCallback.call(this, res, dispatch, displayDialogAlert, modalActions.displayDialogAlert({
          show: false
        }))
      }}
      zIndex={requesting ? 10 : 999}
    >
      <Dialog
        element={element}
        declineClicked={declineClicked}
        buttonDeclineText={buttonDeclineText}
        approveClicked={approveClicked}
        buttonApproveText={buttonApproveText}
      />
      
    </FullScreenModal>
  );
};

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps)(DialogFullScreenModal);

