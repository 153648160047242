import React from 'react';
import {Button, Card, CardBody, FormGroup, Input} from "reactstrap";

const ShareInvoice = ({handleShareEmailChange, shareInvoice, requesting, onkeydownEnterShareInvoice}) => {
    return (
        <div>
            <Card style={{ borderRadius: "10px", height: "50%"}}>
                <CardBody style={{padding: "5rem 4rem"}}>
                    <FormGroup>
                        <Input
                            placeholder={"Enter email address to share invoice"}
                            type={"email"}
                            name={"shareEmail"}
                            id={"share-email"}
                            onKeyDown={onkeydownEnterShareInvoice}
                            onChange={handleShareEmailChange}
                        />
                    </FormGroup>

                    <Button
                        color={"success"}
                        className="btn-sm btn-rounded px-4 mr-2 text-white"
                        onClick={shareInvoice}
                        disabled={requesting}
                    >
                        Share
                    </Button>
                </CardBody>
            </Card>
        </div>
    );
};

export default ShareInvoice;