import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Nav, Collapse, Button } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

// import profile from '../../../assets/images/users/female.png';
import profile from "../../../assets/images/users/generic-user.png";
import SidebarRight from "./sidebar-right";
import { appHelpers } from "../../../_helpers";

const mapStateToProps = (state) => ({
  ...state,
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.expandLogo = this.expandLogo.bind(this);
    this.toggle = this.toggle.bind(this);
    this.activeRoute.bind(this);
    this.state = {
      authentication: this.activeRoute("/authentication") !== "" ? true : false,
      uicomponents: this.activeRoute("/ui-components") !== "" ? true : false,
      samplepages: this.activeRoute("/sample-pages") !== "" ? true : false,
      dashboardpages: this.activeRoute("/dahboards") !== "" ? true : false,
      iconsPages: this.activeRoute("/icons") !== "" ? true : false,
      formlayoutPages: this.activeRoute("/form-layouts") !== "" ? true : false,
      formpickerPages: this.activeRoute("/form-pickers") !== "" ? true : false,
      collapse: false,
      showRightSidebar: false,
      settings: false,
      manageReports: false,
      wahoo: false,
      menuName: "",
      toggler: false,
      organizationManagement: false,
      organizationManagement2: false,
      styleChildMenu: false,
      width: window.innerWidth,
      showIcons: false,
      creditNote: false,
      Support: false,
      helpCenter: false,
    };
  }

  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook, Applies when loading or resizing App                           */
  /*--------------------------------------------------------------------------------*/
  componentDidMount() {
    window.addEventListener("load", this.updateIcons);
    window.addEventListener("resize", this.updateIcons);
  }

  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  updateIcons = () => {
    // const {dispatch} = this.props;
    this.setState({
      width: window.innerWidth,
    });

    if (this.state.width < 1170) {
      this.setState({ showIcons: true });
    } else {
      this.setState({ showIcons: false });
    }
  };

  /*--------------------------------------------------------------------------------*/
  /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
  /*--------------------------------------------------------------------------------*/
  expandLogo() {
    document.getElementById("logobg").classList.toggle("expand-logo");
  }
  toggle() {
    this.setState((state) => ({ collapse: !state.collapse }));
  }
  /*--------------------------------------------------------------------------------*/
  /*Verifies if routeName is the one active (in browser input)                      */
  /*--------------------------------------------------------------------------------*/
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
      ? "selected"
      : "";
  }

  showSidebarRight = (value, menuName, prop) => {
    console.log(value, menuName, prop, "SEE");
    this.setState(
      (prevState) => ({
        settings: value === "settings" && !prevState.settings,
        manageReports: value === "manageReports" && !prevState.manageReports,
        creditNote: value === "creditNote" && !prevState.creditNote,
        Support: value === "Support"  && !prevState.Support,
        menuName: menuName,
        styleChildMenu: false,
      }),
      () => {
        this.setState(
          {
            showRightSidebar: this.state[value] === true,
          },
          () => {
            if (this.state.showRightSidebar === false) {
              prop.forEach((item) => {
                if (item.child) {
                  item.child.forEach((item) => {
                    let childState = item["state"];
                    this.setState({ [childState]: false });
                  });
                }
              });
            }
          }
        );
      }
    );
  };

  hideSidebar = (value) => {
    this.setState({
      [value]: false,
      settings: false,
      showRightSidebar: false,
      roles: false,
    });
  };

  toggleSubMenus = (seconddd) => {
    this.setState(seconddd);
    this.setState((prevState) => ({
      styleChildMenu: true,
    }));
  };

  render() {
    const { user } = this.props;
    const { showRightSidebar, styleChildMenu, showIcons } = this.state;
    return (
      <aside
        className="left-sidebar"
        id="sidebarbg"
        data-sidebarbg={this.props.settings.activeSidebarBg}
        onMouseEnter={this.expandLogo}
        onMouseLeave={this.expandLogo}
      >
        <div className="scroll-sidebar">
          <PerfectScrollbar className="sidebar-nav">
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar Menus will go here                                                */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav id="sidebarnav">
              <li className="sidebar-item user-profile">
                {/* <span onClick={this.toggle} className="sidebar-link has-arrow" aria-expanded="false">
									<img src={profile} alt="user" />
									<span className="hide-menu">Steave Jobs </span>
								</span>
								<Collapse isOpen={this.state.collapse}>
									<ul>
										<li><a href="/" className="sidebar-link p-0">My Profile </a></li>
										<li><a href="/" className="sidebar-link p-0">My Balance</a></li>
										<li><a href="/" className="sidebar-link p-0">Inbox</a></li>
										<li><a href="/" className="sidebar-link p-0">Logout</a></li>
									</ul>
								</Collapse> */}
                {!this.props.showMiniSidebarDropdown && (
                  <div className="text-center">
                    <img
                      src={profile}
                      alt="user"
                      className="rounded-circle my-4 shadow"
                      width="100"
                    />
                    <h4
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        appHelpers.navigateRoute.call(
                          this,
                          "/settings-view-user-profile"
                        )
                      }
                      className=""
                    >
                      {user.fullName}
                    </h4>
                    <h6 className="">{user.userRole}</h6>
                    <h4 className="" style={{ color: "#40D999" }}>
                      {user.userLocation}
                    </h4>
                    <Button
                      onClick={() =>
                        appHelpers.navigateRoute.call(
                          this,
                          "/settings-view-user-profile"
                        )
                      }
                      outline
                      color="info"
                    >
                      View Profile
                    </Button>
                    {/* <h6 className="">{user.supervisor}</h6> */}
                    {/*<h6 className="text-success"></h6>*/}
                  </div>
                )}
              </li>
              {this.props.routes.map((prop, key) => {
                if (prop.redirect) {
                  return null;
                }
                if (prop.notVisible) {
                  return <li style={{ display: "none" }} key={key}></li>;
                } else if (prop.navlabel) {
                  return (
                    <li className="nav-small-cap" key={key}>
                      <i className={prop.icon}></i>
                      <span className="hide-menu">{prop.name}</span>
                    </li>
                  );
                } else if (prop.collapse) {
                  let firstdd = {};
                  firstdd[prop["state"]] = !this.state[prop.state];

                  let stateProp;
                  stateProp = prop["state"];

                  let menuName = prop["name"];
                  return (
                    /*--------------------------------------------------------------------------------*/
                    /* Menus wiil be here                                                        */
                    /*--------------------------------------------------------------------------------*/
                    <li
                      className={this.activeRoute(prop.path) + " sidebar-item"}
                      key={key}
                    >
                      <span
                        data-toggle="collapse"
                        className="sidebar-link has-arrow"
                        aria-expanded={this.state[prop.state]}
                        onClick={() =>
                          this.showSidebarRight(
                            stateProp,
                            menuName,
                            this.props.routes
                          )
                        }
                      >
                        <i className={prop.icon} />
                        <span className="hide-menu">
                          {prop.name}
                          <span className={prop.badges}>{prop.badgeno}</span>
                        </span>
                      </span>
                      {/*--------------------------------------------------------------------------------*/}
                      {/* Sub-Menus wiil be displayed here if the showMiniSidebarDropdown reducer is set to true. */}
                      {/* This is displayed when the window width is reduced or the sidebar toggle icon is clicked */}
                      {/*--------------------------------------------------------------------------------*/}

                      {this.props.showMiniSidebarDropdown && (
                        <Collapse isOpen={this.state[prop.state]}>
                          <ul className="first-level">
                            {prop?.child?.length>0 && prop.child.map((prop, key) => {
                              console.log(prop, "thisprop");
                              if (prop.redirect) return null;
                              if (!prop.collapse) {
                                //If the route is a child and does not have a subchild and the collapse value is false
                                let seconddd = {};
                                seconddd[prop["state"]] = !this.state[
                                  prop.state
                                ];
                                return (
                                  <li
                                    className={
                                      this.activeRoute(prop.path) +
                                      " sub-sidebar-item"
                                    }
                                    key={key}
                                  >
                                    {/*<span*/}
                                    {/*	  className="sidebar-link "*/}
                                    {/*	  aria-expanded={this.state[prop.state]}*/}
                                    {/*	  onClick={() => this.setState(seconddd)}>*/}
                                    <NavLink
                                      to={{
                                        pathname: prop.path,
                                        state: { from: prop.path },
                                      }}
                                      className="sidebar-link"
                                      activeClassName="active"
                                    >
                                      {showIcons && <i className={prop.icon} />}
                                      <span className="hide-menu">
                                        {prop.name}
                                      </span>
                                    </NavLink>
                                    {/*</span>*/}
                                  </li>
                                );
                              }
                              if (prop.collapse) {
                                //If the route is a child and has a sunchild and the collapse property is set to true
                                let seconddd = {};
                                seconddd[prop["state"]] = !this.state[
                                  prop.state
                                ];
                                return (
                                  <li
                                    className={
                                      this.activeRoute(prop.path) +
                                      " sub-sidebar-item"
                                    }
                                    key={key}
                                  >
                                    <span
                                      data-toggle="collapse"
                                      className="sidebar-link has-arrow"
                                      aria-expanded={this.state[prop.state]}
                                      onClick={() => this.setState(seconddd)}
                                    >
                                      {/*<span data-toggle="collapse" className="sidebar-link has-arrow"  onClick={() => this.setState({showRightSidebar: true})} >*/}
                                      {showIcons && <i className={prop.icon} />}
                                      <span className="hide-menu">
                                        {prop.name}
                                      </span>
                                    </span>
                                    {/*--------------------------------------------------------------------------------*/}
                                    {/* Sub-Menus wiil be here                                                    */}
                                    {/*--------------------------------------------------------------------------------*/}
                                    <Collapse isOpen={this.state[prop.state]}>
                                      <ul className="second-level">
                                        {prop?.subchild?.length>0 && prop?.subchild?.map((prop, key) => {
                                          if (prop?.redirect) return null;
                                          return (
                                            <li
                                              className={
                                                this.activeRoute(prop.path) +
                                                " sub-sidebar-item"
                                              }
                                              key={key}
                                            >
                                              <NavLink
                                                to={{
                                                  pathname: prop.path,
                                                  state: { from: prop.path },
                                                }}
                                                activeClassName="active"
                                                className="sidebar-link"
                                              >
                                                <i className={prop.icon} />
                                                <span className="hide-menu">
                                                  {prop.name}
                                                </span>
                                              </NavLink>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </Collapse>
                                  </li>
                                );
                              }
                              return (
                                /*--------------------------------------------------------------------------------*/
                                /* Adding Sidebar Item                                                            */
                                /*--------------------------------------------------------------------------------*/
                                <li
                                  className={
                                    this.activeRoute(prop.path) +
                                    (prop.pro ? " active active-pro" : "") +
                                    " sidebar-item"
                                  }
                                  key={key}
                                >
                                  <NavLink
                                    to={{
                                      pathname: prop.path,
                                      state: { from: prop.path },
                                    }}
                                    className="sidebar-link"
                                    activeClassName="active"
                                  >
                                    <i className={prop.icon} />
                                    <span className="hide-menu">
                                      {prop.name}
                                    </span>
                                  </NavLink>
                                </li>
                              );
                            })}
                          </ul>
                        </Collapse>
                      )}
                    </li>
                  );
                } else {
                  let stateProp;
                  stateProp = prop["state"];

                  // let menuName = prop["name"];
                  return (
                    /*--------------------------------------------------------------------------------*/
                    /* Adding Sidebar Item                                                            */
                    /*--------------------------------------------------------------------------------*/
                    <li
                      className={
                        this.activeRoute(prop.path) +
                        (prop.pro ? " active active-pro" : "") +
                        " sidebar-item"
                      }
                      key={key}
                      onClick={() => this.hideSidebar(stateProp)}
                    >
                      <NavLink
                        to={{ pathname: prop.path, state: { from: prop.path } }}
                        className="sidebar-link"
                        activeClassName="active"
                      >
                        <i className={prop.icon} />
                        <span className="hide-menu">{prop.name}</span>
                      </NavLink>
                    </li>
                  );
                }
              })}
            </Nav>
          </PerfectScrollbar>

          {/*Displaying the second sidebar to the right*/}
          {!this.props.showMiniSidebarDropdown && showRightSidebar && (
            <SidebarRight>
              <div style={{ display: "flex" }}>
                <h3
                  className={"right-sidebar-header"}
                  style={{ fontSize: "17px", fontWeight: 500, color: "#111" }}
                >
                  {this.state.menuName}
                </h3>
                <h3
                  className="spanClose"
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    marginLeft: "auto",
                    marginRight: "10px",
                  }}
                  onClick={() =>
                    this.setState({ showRightSidebar: false, settings: false })
                  }
                >
                  &times;
                </h3>
              </div>

              <ul className="second-level">
                {this.props.routes && this.props.routes.map((prop, key) => {
                  // if (prop.redirect) return null;
                  let stateProp;
                  stateProp = prop["state"];
                  if (prop.collapse && this.state[prop.state]) {
                    return prop.child?.map((prop, key) => {
                      let childItemState = prop.state;

                      if (prop.collapse) {
                        let seconddd = {};
                        seconddd[prop["state"]] = !this.state[prop.state];
                        return (
                          <li
                            className={
                              this.activeRoute(prop.path) +
                              " sidebar-item-right"
                            }
                            key={key}
                          >
                            {/*<span data-toggle="collapse" className="sidebar-link-right has-arrow" aria-expanded={this.state[prop.state]} onClick={() => this.setState(seconddd)} >*/}
                            <span
                              data-toggle="collapse"
                              className="sidebar-link-right has-arrow"
                              aria-expanded={this.state[prop.state]}
                              onClick={() =>
                                this.toggleSubMenus(seconddd, childItemState)
                              }
                            >
                              {/*<NavLink className="sidebar-link" activeClassName="active">*/}
                              {/*	<i className={prop.icon} />*/}
                              <span
                                className={
                                  styleChildMenu
                                    ? "child-menu"
                                    : "hide-menu sub-menu-name"
                                }
                              >
                                {prop.name}
                              </span>
                            </span>
                            {/*</NavLink>*/}

                            <Collapse isOpen={this.state[childItemState]}>
                              <ul className="second-level">
                                {prop.subchild &&
                                  prop.subchild.map((prop, key) => {
                                    if (prop.redirect) return null;
                                    return (
                                      <li
                                        className={
                                          this.activeRoute(prop.path) +
                                          " sidebar-item-right"
                                        }
                                        key={key}
                                        onClick={() =>
                                          this.hideSidebar(
                                            prop.state,
                                            stateProp
                                          )
                                        }
                                      >
                                        <NavLink
                                          to={{
                                            pathname: prop.path,
                                            state: { from: prop.path },
                                          }}
                                          activeClassName="active"
                                          className="sub-sidebar-link-right"
                                        >
                                          {/*<i className={prop.icon} />*/}
                                          <span className="hide-menu">
                                            {prop.name}
                                          </span>
                                        </NavLink>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </Collapse>
                          </li>
                        );
                      } else if (!prop.collapse) {
                        //rendering child route that has no sub-child
                        let seconddd = {};
                        seconddd[prop["state"]] = !this.state[prop.state];
                        return (
                          <li
                            className={
                              this.activeRoute(prop.path) +
                              " sidebar-item-right"
                            }
                            key={key}
                          >
                            <span
                              className="sidebar-link-right"
                              aria-expanded={this.state[prop.state]}
                              onClick={() =>
                                this.hideSidebar(prop.state, stateProp)
                              }
                            >
                              <NavLink
                                to={{
                                  pathname: prop.path,
                                  state: { from: prop.path },
                                }}
                                className="sidebar-link"
                                activeClassName="active"
                              >
                                <span className="hide-menu">{prop.name}</span>
                              </NavLink>
                            </span>
                          </li>
                        );
                      }

                      return (
                        <li
                          className={
                            this.activeRoute(prop.path) +
                            (prop.pro ? " active active-pro" : "") +
                            " sidebar-item"
                          }
                          key={key}
                          onClick={() => this.hideSidebar(stateProp)}
                        >
                          <NavLink
                            to={{
                              pathname: prop.path,
                              state: { from: prop.path },
                            }}
                            className="sidebar-link"
                            activeClassName="active"
                          >
                            {/*<i className={prop.icon} />*/}
                            <span className="hide-menu">{prop.name}</span>
                          </NavLink>
                        </li>
                      );
                    });
                  }
                  return null;
                })}
              </ul>
            </SidebarRight>
          )}
        </div>
      </aside>
    );
  }
}
export default connect(mapStateToProps)(Sidebar);
