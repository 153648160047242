import React, { Component } from "react";
import ContentLayout from "../../../components/ContentLayout";
import "./index.css";
import SearchPanel from "./search-panel";
import { Button } from "reactstrap";
import StaffProfile from "./staff-profile";
import { appHelpers } from "../../../_helpers";
import { FaArrowLeft, FaCheckCircle, FaTimes } from "react-icons/fa";
import { alertActions } from "../../../_actions";
import { commonService } from "../../../_services";
import { staffService} from "../../../_services/staff.service";
import { appConstants } from "../../../_constants";
import { connect } from "react-redux";
import axios from "axios";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { RenderAlertModal } from "../../../components/AlertModal";
import CheckboxGrid from "../../../components/CheckboxGrid";

let source;
class CreateStaffs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionsData: [],
      revenueData: [],
      locationData: [],
      firstName: "",
      lastName: "",
      email: "",
      roles: [],
      role: "",
      organization: "",
      organizationId: null,
      pageTitle: appConstants.CREATE_STAFF,
      showStaffProfile: false,
      staffDetails: null,
      editingStaffProfile: false,
      phoneNo: "",
      showAlertModal: false,
      alertMessage: "",
      alertIcon: false,
      componentErrorMessages: [],
      staffStatus: false,
      selectAllRevenue: false,
      selectAllLocations: false,
      title: null,
      titles: [],
      customerDetails: []
    };

    source = axios.CancelToken.source();

  }

  componentWillMount() {
    
    let stateFromPath = this.props.history.location.state; //This is gotten from the manage user route and used to set the initial state of the page.
    if (stateFromPath) {
      let staffDetails = stateFromPath.item;
      let pageTitle = stateFromPath.pageTitle;
      let showStaffProfile = stateFromPath.showStaffProfile;
      
      if (staffDetails) {
        this.setState({
          firstName: staffDetails.firstName,
          lastName: staffDetails.lastName,
          email: staffDetails.email,
          title: appHelpers.getUserTitle(staffDetails.title),
          staffStatus: staffDetails.status,
          phoneNo: staffDetails.phoneNumber,
          role:staffDetails.roleId,
          pageTitle: stateFromPath.pageTitle,
          staffDetails: staffDetails,
          locationIds: staffDetails.locationId
        });
      }
      if (pageTitle) {
        this.setState({ pageTitle: pageTitle });
      }
      if (showStaffProfile !== undefined) {
  
        this.setState({
          showStaffProfile: showStaffProfile,
          staffDetails: staffDetails,
        });
      }
    }
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";
    
    this.getRoles();
    this.fetchDesignations();
    this.getCustomerStaffDetails();
    this.getSelfServiceUserLocation();
  }

  // This onChange handler, gets the checked value
  checkboxChange = (e, type) => {
    const { permissionsData, revenueData, locationData } = this.state;

    let checkedStatus = e.target.checked; //Value of the check input
    let changedPermission = e.target.parentNode.parentNode.textContent; //The text for the element that was checked.
    // This would be used to identify the object in the array that was checked, so as to accurately update it's activated property.

    let updatedRecord; //Variable to hold the record that was updated
    if (type === "permissions") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        permissionsData
      );
      this.setState({ permissionsData: updatedRecord });
    }
    if (type === "revenue") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        revenueData
      );
      this.setState({ revenueData: updatedRecord });
    }
    if (type === "location") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        locationData
      );
      this.setState({ locationData: updatedRecord });
    }
  };

  //onChange handler for select fields
  handleSelectChange = (payload, attribute) => {
    let currState = this.state;
    // let errorMessages = this.state.componentErrorMessages;
    // errorMessages[attribute] = false;
    // currState[attribute] = (payload) ? payload[attribute] : ''; //Cater for setting up data and clearing data
    if (!payload) {
      currState[attribute] = "";
      
      if (attribute === "role") {
        currState.componentErrorMessages.role =
          "Please select a role to display available roles";
      }
    } else {
      if (attribute === "role") {
        this.setState({ role: payload }, () => {
        });
      } else if (attribute === "title") {
        currState[attribute] = payload;
        } else {
        currState[attribute] = payload.Id;
      }
    }
    this.setState({ currState });
  };

  //onchange handler for Input fields
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  filterValues = (type) => {
    const { permissionsData, revenueData, locationData } = this.state;
    if (type === "permissions") {
      const filteredPermissions = permissionsData
        .filter((item) => item.activated === true)
        .map((item) => item.permissionId);
      return filteredPermissions ? filteredPermissions : [];
    }
    if (type === "revenue") {
      const filteredPermissions = revenueData
        .filter((item) => item.activated === true)
        .map((item) => item.revenueId);
      return filteredPermissions ? filteredPermissions : [];
    }
    if (type === "location") {
      const filteredPermissions = locationData
        .filter((item) => item.activated === true)
        .map((item) => item.locationId);
      return filteredPermissions ? filteredPermissions : [];
    }
  };

  getRoles = () => {
    const { dispatch } = this.props;
    const { pageTitle, staffDetails } = this.state;
    // dispatch(alertActions.startRequest());
    staffService.getRoles(source.token).then((res) => {
      // dispatch(alertActions.stopRequest());
      let response = res.response; 
      if (response.data) {
        this.setState({ roles: response.data }, ()=>{
        if(pageTitle === appConstants.EDIT_STAFF) {
        this.setState({ role: appHelpers.getUserRoleById(this.state.roles, staffDetails.roleId) },)
        }
        pageTitle === appConstants.STAFF_PROFILE &&
        this.setState(
        { role: appHelpers.getUserRoleById(this.state.roles, staffDetails.roleId) },
        )
        
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getCustomerStaffDetails = () => {
    let { dispatch } = this.props;
    // dispatch(alertActions.startRequest());
    staffService.getCustomerStaffDetails(source.token).then((res) => {
      // dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (response.data) {
          this.setState({ customerDetails: response.data });
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };
  
  fetchDesignations = () => {
    let { dispatch } = this.props;
   const{staffDetails} = this.state;
    // dispatch(alertActions.startRequest());
    commonService.getAllDesignations().then((res) => {
      let response = res.response;
      // dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          titles: response.data,
        },()=>{
          if(staffDetails){
            this.setState({ title: appHelpers.getUserTitle(this.state.titles, staffDetails.titleId)})
            }
        },
        );

      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
   
  };
  getSelfServiceUserLocation = () => {
    const { dispatch } = this.props;
    const { pageTitle, staffDetails } = this.state;
    
    const payload = {};
    if (
    pageTitle === appConstants.EDIT_STAFF ||
    pageTitle === appConstants.STAFF_PROFILE
    ) {
    payload.CustomerStaffId = staffDetails.id
    }
    

    dispatch(alertActions.startRequest());
    staffService.getSelfServiceUserLocation(payload, source.token).then((res) => {
    dispatch(alertActions.stopRequest());
    let response = res.response;
    if (res.status === appConstants.SUCCESS_RESPONSE) {
    if (response.data) {
    this.setState({ locationData: response.data});
    }
    } else if (res.status === appConstants.ERROR_RESPONSE) {
    dispatch(alertActions.error(res.response));
    }
    });
    
    }

  createStaff = () => {
    const {
      firstName,
      lastName,
      email,
      role,
      title,
      organization,
      phoneNo,
      staffDetails,
      pageTitle,
      customerDetails
    } = this.state;
    const { dispatch, user } = this.props;
    let locationIds = this.filterValues("location");

    if (this.validateInput()) {
      let payload = {
        titleId: title.id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        roleId: role.roleId,
        organizationId:
          user.vggRole !== appConstants.ROLES.VGG_ADMIN
            ? user.organizationId
            : (user.vggRole === appConstants.ROLES.VGG_ADMIN) ? organization.id
              :
            organization,
            phoneNumber: phoneNo,
            customerId: customerDetails.id,
            locationIds: locationIds,

      };

      //If the is on edit view, pass the userId of the record gotten from the staffDetails property
      //in pathState to the payload.
      if (pageTitle === appConstants.EDIT_STAFF) {
        payload.id = staffDetails.id;
      }

      dispatch(alertActions.startRequest());

      staffService.createStaffs(payload, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
              ),
              alertMessage: response.message,
            },
            () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
              this.resetFields();
            }
          );
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (typeof response === "string") {
            // dispatch(alertActions.error(response));
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response,
              },
              () => appHelpers.dismissModal.call(this, "showAlertModal")
            );
          } else {
            dispatch(alertActions.error(response.message));
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response.message,
              },
              () => appHelpers.dismissModal.call(this, "showAlertModal")
            );
          }
        }
      });
    }
  };

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  resetFields = () => {
    const { pageTitle } = this.state;
    if (pageTitle !== appConstants.EDIT_STAFF) {
      this.setState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        organization: "",
        role: "",
        title: "",
        revenueData: appHelpers.deactivateCheckboxes(this.state.revenueData),
        locationData: appHelpers.deactivateCheckboxes(this.state.locationData),
        selectAllRevenue: false,
        selectAllLocations: false,
      });
    }
  };

  cancel = () => {
    this.resetFields();
  };

  // for editing user via the user profile view .
  // The editingStaffProfile variable in state is used to determine what is displayed when the back button is clicked
  editStaffProfile = () => {
    this.setState(
      {
        showStaffProfile: false,
        pageTitle: appConstants.EDIT_STAFF,
        editingStaffProfile: true,
      },
      () =>{
        this.getRoles();
      
      }
    );
  };

  //Handler that controls what is displayed when the back button is clicked
  navigateBack = () => {
    if (this.state.editingStaffProfile) {
      this.setState({ showStaffProfile: true });
      window.location.reload();
    } else {
      this.props.history.goBack();
    }
  };

  //Validate input fields
  validateInput = () => {
    window.scrollTo(0, 0);
  
    let _errs_ = [];
    let formValid = true;
    if (this.state.firstName === "") {
      _errs_["firstName"] = "Please enter first name";
      formValid = false;
    }
    if (this.state.title === "") {
      _errs_["title"] = "Please select a title";
      formValid = false;
    }
    if (this.state.lastName === "") {
      _errs_["lastName"] = "Please enter last name";
      formValid = false;
    }
    
    if (this.state.email === "") {
      _errs_["email"] = "Please enter an email address";
      formValid = false;
    }
    if (this.state.role === "") {
      _errs_["role"] = "Please select a role";
      formValid = false;
    }
    if (this.state.phoneNo === "") {
      _errs_["phoneNumber"] = "Please enter a phone number";
      formValid = false;
    }
    if (this.state.phoneNo.length !== 11) {
      _errs_["phoneNumber"] =
        "Phone number should not be more or less than eleven digits";
      formValid = false;
    }

    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  activateStaff = (payload) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    staffService.activateStaff(payload, source.token).then((res) => {  
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState(
          {
            showAlertModal: true,
            alertIcon: (
              <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
            ),
            alertMessage: response.message,
            staffStatus: true,
          },
          () => {
            appHelpers.dismissModal.call(this, "showAlertModal");
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaTimes size={100} color={appConstants.DANGER_COLOR} />
              ),
              alertMessage: response,
              // staffStatus: false
            },
            () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
            }
          );
        } else {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaTimes size={100} color={appConstants.DANGER_COLOR} />
              ),
              alertMessage: response.message,
              // staffStatus: false
            },
            () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
            }
          );
        }
      }
    });
  };

  deactivateStaff = (payload) => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());
    staffService.deactivateStaff(payload, source.token).then((res) => {  
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState(
          {
            showAlertModal: true,
            alertIcon: (
              <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
            ),
            alertMessage: response.message,
            staffStatus: false,
          },
          () => {
            appHelpers.dismissModal.call(this, "showAlertModal");
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (typeof response === "string") {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response,
                // staffStatus: false
              },
              () => {
                appHelpers.dismissModal.call(this, "showAlertModal");
              }
            );
          } else {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response.message,
                // staffStatus: false
              },
              () => {
                appHelpers.dismissModal.call(this, "showAlertModal");
              }
            );
        }
      }
    });
  };

  handleActivationStatus = (e) => {
    const { staffStatus, staffDetails } = this.state;

    if (staffStatus === false) {
      this.activateStaff(staffDetails.userId);
    }
    if (staffStatus === true) {
      this.deactivateStaff(staffDetails.userId);
    }
  };

  selectAllCheckboxes = (type) => {
    const { locationData } = this.state;
    
    if (type === "location") {
      this.setState(
        (prevState) => ({ selectAllLocations: !prevState.selectAllLocations }),
        () => {
          if (this.state.selectAllLocations) {
            let locationsActivated = locationData.map((item) => {
              item.activated = true;
              return item;
            });
            this.setState({ locationData: locationsActivated });
          } else if (!this.state.selectAllLocations) {
            let locationsActivated = locationData.map((item) => {
              item.activated = false;
              return item;
            });
            this.setState({ locationData: locationsActivated });
          }
        }
      );
    }
  };

  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
   }

  render() {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
      email,
      roles,
      role,
      titles,
      pageTitle,
      showStaffProfile,
      staffDetails,
      title,
      locationData,
      phoneNo,
      showAlertModal,
      alertMessage,
      alertIcon,
      componentErrorMessages,
      staffStatus,
      selectAllLocations,
    } = this.state;
    const { user, requesting } = this.props;


    return (
      <ContentLayout
        requesting={requesting}
        pageTitle={pageTitle}
        actions={
          <div className={"float-right"}>
            <Button
              color="info"
              outline
              className="btn-rounded mr-2 big-action-btn"
              onClick={this.navigateBack}
            >
              <FaArrowLeft className={"mb-1 icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <div className={"search-container"}>
          {!showStaffProfile && (
            <SearchPanel
              firstName={firstName}
              lastName={lastName}
              password={password}
              confirmPassword={confirmPassword}
              email={email}
              roles={roles}
              role={role}
              phoneNo={phoneNo}
              titles={titles}
              title={title}
              handleChange={this.handleChange}
              selectChange={this.handleSelectChange}
              user={user}
              componentErrorMessages={componentErrorMessages}
              editing={pageTitle === appConstants.EDIT_STAFF}
            />
          )}
          {showStaffProfile && (
            <StaffProfile
              userName={`${firstName} ${lastName}`}
              email={email}
              staffDetails={staffDetails}
              status={staffStatus}
              handleActivationStatus={this.handleActivationStatus}
              editStaffProfile={this.editStaffProfile}
              role={appHelpers.isObject(role) ? role.roleName : role}
            />
          )}
        </div>
 
        <div className={"permissions-container"}>
          {locationData.length === 0 && (
            <EmptyRecordMessage message={"No location record"} />
          )}
          {locationData.length > 0 && (
         <CheckboxGrid
              title={"Locations"}
              checkAll={selectAllLocations}
              label={!selectAllLocations ? "Select all" : "Deselect all"}
              displayCheckAllBoxes={true}
              checkAllBoxes={() => this.selectAllCheckboxes("location")}
              data={locationData}
              checkboxChange={(e) => this.checkboxChange(e, "location")}
            />
          )}
        </div> 

        {!showStaffProfile && (
          <div>
            <Button
              color="success"
              className="btn-sm btn-rounded mr-2 text-white"
              onClick={this.createStaff}
              disabled={requesting}
            >
              {pageTitle === appConstants.CREATE_STAFF ? "Create Staff" : "Save"}
            </Button>
            <Button
               outline
               className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
              onClick={this.cancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {showAlertModal && (
          <RenderAlertModal
            message={alertMessage}
            icon={alertIcon}
            showAlertModal={showAlertModal}
            hideAlertView={this.hideAlertView}
          />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { alert, user,progress,modal, requesting, organizations } = state;
  return {
    modal,
    progress,
    alert,
    user,
    requesting,
    organizations,
  };
};

export default connect(mapStateToProps)(CreateStaffs);
