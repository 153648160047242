import React from 'react';
import {Row, Col, Label, FormGroup, Input} from 'reactstrap';
import SelectList from "../../../components/SelectList/selectList";

const LocationDetails = ({countries, country, address, localGovernments, LG, zipCode, selectChange,
                             componentErrorMessages, states, state, handleChange}) => {
    return (
        <div>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label for="state"> Country </Label>
                        <SelectList
                            placeholder={'Select country...'}
                            data={countries}
                            valueKey={'id'}
                            textField={'name'}
                            selectValue={country}
                            onValueChange={(event) => {selectChange(event, 'country')}}/>
                    </FormGroup>
                    {componentErrorMessages.country && <span className={"inputError"}>{componentErrorMessages.country}</span>}
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <FormGroup>
                        <Label for="state"> Address </Label>
                        <Input
                            placeholder={'Enter an address'}
                            type="text"
                            name="address"
                            id="address"
                            value={address}
                            onChange={handleChange}
                            defaultValue=""
                            className={"search-inputs"}
                        />
                    </FormGroup>
                    {componentErrorMessages.address && <span className={"inputError"}>{componentErrorMessages.address}</span>}
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <FormGroup>
                    <SelectList
                        placeholder={'Select state...'}
                        data={states}
                        valueKey={'id'}
                        textField={'name'}
                        selectValue={state}
                        onValueChange={(event) => {selectChange(event, 'state')}}/>
                    </FormGroup>
                    {componentErrorMessages.state && <span className={"inputError"}>{componentErrorMessages.state}</span>}
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <SelectList
                            placeholder={'Select local government'}
                            data={localGovernments}
                            valueKey={'id'}
                            textField={'name'}
                            selectValue={LG}
                            onValueChange={(event) => {selectChange(event, 'LG')}}/>

                </FormGroup>
                    {componentErrorMessages.LG && <span className={"inputError"}>{componentErrorMessages.LG}</span>}
                </Col>
                <Col md={3}>
                    <FormGroup>
                    {/*<Label for="state"> Website </Label>*/}
                    <Input
                        type="text"
                        placeholder={'Enter a zipcode'}
                        name="zipCode"
                        id="zipCode"
                        value={zipCode}
                        onChange={handleChange}
                        defaultValue=""
                        className={"search-inputs"}
                    />
                </FormGroup>
                    {componentErrorMessages.zipCode && <span className={"inputError"}>{componentErrorMessages.zipCode}</span>}
                </Col>
            </Row>
        </div>
    );
};

export default LocationDetails;