import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  Modal,
  ModalBody,
  ModalHeader,
  CardBody,
} from "reactstrap";
import { DatePicker } from "../../components/DatePicker";
import ContentLayout from "../../components/ContentLayout";
import moment from "moment";
// import TableFormat from "./audittrailtable";
import { alertActions, commonActions } from "../../_actions";
import { appConstants } from "../../_constants/app.constants";
// import SelectList from "../../components/SelectList/selectList";
import Select from "react-select";
// import "react-select/dist/react-select.css";
import axios from "axios";
import { connect } from "react-redux";
import EmptyRecordMessage from "../../components/EmptyRecordMessage/EmptyRecordMessage";
import { appHelpers } from "../../_helpers";
import { FaArrowLeft } from "react-icons/fa";
import { supportService } from "../../_services";
import AccordionItem from "./faq-accordion";
import DetailedItem from "./nested-Accordion";
// import ChatIcon from "react-icons/lib/md/chat";
// import EmailIcon from "react-icons/lib/md/email";
// import CallIcon from "react-icons/lib/";
// import WhatsaAppIcon from "react-icons/lib/md/voice-chat";
import CardDetails from "./card-details";
import { FaPhone, FaEnvelope, FaComment, FaWhatsapp } from "react-icons/fa";

let source;

const presentDate = new Date();

class HelpCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      search: "",
      currentPage: 1,
      organizationId: 0,
      auditTypes: [],
      status: "",
      pageSize: 10,
      totalCount: 0,
      searching: false,
      records: null,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      activeIndex: null,
      details: null,
      modalOpen: false,
      searchInput: "", // New state for search input
      filteredRecords: null, // New state for filtered records
      selectedIndex: 0,
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    //Call branding if there is no method to display select agency view
    // appHelpers.checkDerivedStateFromPropsMethod.call(this);
    // this.props.dispatch(commonActions.setPeriodPickerVisibility(false));
    // document.querySelectorAll(".react-datepicker-wrapper").forEach((item) => {
    //   item.classList.add("form-control");
    // });
    // document.querySelectorAll(".pagination").forEach(item => {
    //   item.classList.add("moveRight");
    // });
    this.getFaq();
  }

  getFaq = (pageNumber = 0) => {
    const { dispatch } = this.props;
    const { pageSize, search } = this.state;

    let payload = {
      pageSize: pageSize,
      pageNumber: pageNumber,
      parameter: {
        // query: search,
        productName: "EBIPS",
        status: 3,
      },
    };
    dispatch(alertActions.startRequest());
    supportService.searchFaq(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          totalCount: response.data.count,
          records: response.data.object,
          dataContent: response.data.object[0].content,
          currentPage: pageNumber === 0 ? 1 : pageNumber,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  generateAccordionData = () => {
    const { filteredRecords, records } = this.state;
    const data = filteredRecords || records;

    return data.reduce((acc, record) => {
      const existingCategory = acc.find(
        (item) => item.categoryName === record.categoryName
      );
      console.log(existingCategory, "cat");
      if (existingCategory) {
        const titleLines = record.title.split("\n");
        existingCategory.titles.push(...titleLines);
        const contentLines = record.content.split("\n"); // Split content into lines
        existingCategory.content.push(...contentLines); // Push content lines
      } else {
        acc.push({
          categoryName: record.categoryName,
          titles: record.title.split("\n"),
          content: record.content.split("\n"), // Split content into lines
        });
      }
      return acc;
    }, []);
  };

  handleSearchInputChange = (event) => {
    const { value } = event.target;
    const { records } = this.state;

    // Filter records based on the search input
    const filteredRecords = records.filter((record) =>
      record.title.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      searchInput: value,
      filteredRecords,
    });
    console.log(filteredRecords, "rec");
  };

  showDetails = (details) => {
    this.setState({
      details: {
        firstContent: details.firstContent,
        subContent: details.subContent,
      },
    });
    console.log(details, "who");
  };

  handleIndexSelected = (index) => {
    this.setState({
      selectedIndex: index,
    });
  };

  openWhatsAppChat = () => {
    window.open("https://wa.me/+2347046270754", "_blank");
  };

  callPhoneNumber = () => {
    window.open("tel:+2347046270753");
  };

  sendEmail = () => {
    window.location.href = "mailto:support@avitech.ng";
  };

  openLiveChat = () => {
    if (typeof Tawk_API !== "undefined") {
      // Tawk_API.toggle();
    }
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      tableData,
      startDate,
      endDate,
      search,
      pageSize,
      currentPage,
      totalCount,
      requesting,
      selectedIndex,
      details,
      searchInput,
      records,
    } = this.state;

    const { user } = this.props;
    console.log(tableData, "realData");
    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle="Help Center"
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className="big-action-btn btn-rounded mr-2"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <Row>
          <Col md={"6"}>
            <p>
              Hi <strong>{user.fullName.split(" ")[0]}</strong>, How can we help
              you?
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={"4"}>
            <FormGroup>
              <div className="input-group">
                <Input
                  type="text"
                  name="search"
                  onChange={this.handleSearchInputChange}
                  id="search"
                  value={searchInput}
                  className="form-control"
                  placeholder="Search"
                />
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="ti-search"></i>
                  </span>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <div className="clearfis">&nbsp;</div>

        <br />
        <br />

        <br />
        <br />
        {!requesting && !records && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}
        {!requesting && records && (
          <CardBody>
            <Row style={{ marginTop: "-79px" }}>
              <Col md={4}>
                {/* Accordion Items */}
                {!requesting && records && (
                  <div>
                    <Row style={{ marginRight: "72%" }}>
                      <Col md={"6"}>
                        <p style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                          Faq Categories
                        </p>
                      </Col>
                    </Row>
                    {this.generateAccordionData().map((groupedData, index) => {
                      console.log(groupedData, "grouped");

                      return (
                        <AccordionItem
                          key={index}
                          title={groupedData.categoryName}
                          showDetails={this.showDetails}
                          subContent={groupedData.content}
                          firstContent={groupedData.titles}
                          handleSelectedIndex={this.handleIndexSelected.bind(
                            this
                          )}
                        />
                      );
                    })}
                  </div>
                )}
              </Col>
              {/* {!requesting && !records && (
                <EmptyRecordMessage message="No record match your search!" />
              )} */}
              <Col md={8} className="detailed-item-col">
                {/* DetailedItem */}
                {details && (
                  <DetailedItem
                    contentTitle={details.firstContent}
                    subContent={details.subContent}
                    selectedIndex={selectedIndex}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        )}

        <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}></ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <CardDetails
                  icon={<FaComment size="15" />}
                  item1="Live Chat"
                  item2="chat with a representative with our chat box on your screen"
                  // item3="click here"
                  onClickItem={this.openLiveChat}
                />
              </div>
              <div className="col-md-6">
                <CardDetails
                  icon={<FaEnvelope size="15" />}
                  item1="Email us"
                  item2="We'll reply in one business day"
                  item3="support@avitech.ng"
                  onClickItem={this.sendEmail}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <CardDetails
                  icon={<FaPhone size="15" />}
                  item1="call us"
                  item2="speak with a representative"
                  item3="+234 704 627 0753"
                  onClickItem={this.callPhoneNumber}
                />
              </div>
              <div className="col-md-6">
                <CardDetails
                  icon={<FaWhatsapp size="15" />}
                  item1="whatsaApp"
                  item2="chat with a representative"
                  item3="+234 704 627 0754"
                  onClickItem={this.openWhatsAppChat}
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
        <div style={{marginTop:"40%"}}>
          <Row style={{ marginRight: "46%" }}>
            <Col md={4}>
              <p className="help-text">Need more help?</p>
            </Col>
          </Row>
          <Row style={{ marginRight: "40%" }}>
            <Col md={4}>
              <p className="support-modal" onClick={this.toggleModal}>
                Talk to support
              </p>
            </Col>
          </Row>
        </div>
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, alert, progress, user, requesting } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(HelpCenter);
