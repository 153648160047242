import React, { Component } from "react";
import { Button, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import ContentLayout from "../../../components/ContentLayout";
import TableStructure from "./customer-staff-table";
import NewStaff from "./new-staff";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import CustomerDetailsView from "./customer-details-view";
import axios from "axios";
import FullScreenModal from "../../../components/FullScreenModal";
import { commonService, customerService } from "../../../_services";
import {alertActions, modalActions} from "../../../_actions";
import { appConstants } from "../../../_constants";
import {selfService} from "../../../_services/self-service";
import GeneralAlert from "../../../components/Api-response-alert";
import {appHelpers} from "../../../_helpers";

let source;

class CustomerDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerStaff: [],
      customerDetails: {},
      selectedCustomer: {},
      pageSize: 10,
      totalCount: 0,
      newCustomerTitle: {},
      newCustomerFirstName: "",
      newCustomerLastName: "",
      newCustomerEmail: "",
      newCustomerPhoneNo: "",
      designations: [],
      isEditMode: false,
      companyRepId: "",
      settingsByKeyRecord: null,
    };

    source = axios.CancelToken.source();

  }

  static getDerivedStateFromProps(props, state) {
    /**
     * @var {String} pathState describes the state on the /manage-customers route
     */
    let pathState = props.location.state;

    if (pathState) {
      if (pathState.selectedCustomer !== state.selectedCustomer) {
        return {
          selectedCustomer: pathState.selectedCustomer,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    this.fetchDesignations();
    this.getSettingsByKey();
    this.fetchCustomerDetails();
    this.fetchCustomerStaff();
  }

  getSettingsByKey = () => {
    let { dispatch } = this.props;
    let key = "CompanyRepresentative";
    dispatch(alertActions.startRequest());
    commonService.getSettingsByKey(key, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          companyRepId: parseInt(response.data.value),
          settingsByKeyRecord: response.data
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  fetchCustomerDetails = () => {
    let { dispatch } = this.props;
    let { selectedCustomer } = this.state;

    let payload = {
      customerId: selectedCustomer.id,
      agencyId: selectedCustomer.agencyId, //only VGG admins would select an agency, others dont need this    };
    };

    dispatch(alertActions.startRequest());
    customerService.getCustomerDetails(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          customerDetails: response.data,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  fetchCustomerStaff = () => {
    let { dispatch } = this.props;
    let { selectedCustomer } = this.state;

    let payload = {
      customerId: selectedCustomer.id,
    };

    dispatch(alertActions.startRequest());
    customerService.getCustomerStaff(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          customerStaff: response.data === null ? [] : response.data,
          totalCount: response.totalCount ? response.totalCount : 0,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        // this.setState({
        //   customerStaff: response.data,
        //   totalCount: response.totalCount,
        // });
      }
    });
  };
  
  activateCustomerStaff = (item) => {
    const { dispatch } = this.props;
  
    let payload = {
      customerStaffId: item.id
    };
    dispatch(alertActions.startRequest());
    customerService.activateCustomerStaff(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "success"}));
        this.fetchCustomerStaff();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "failure"}));
      }
    });
  };
  
  deactivateCustomerStaff = (item) => {
    let payload = {
      customerStaffId: item.id
    }
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    customerService.deactivateCustomerStaff(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "success"}));
        this.fetchCustomerStaff();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "failure"}));
      }
    });
  };
  
  activateCustomer = (item) => {
    const { dispatch } = this.props;

    let payload = {
      customerId: item.id
    };

    dispatch(alertActions.startRequest());
    customerService.activateCustomer(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "success"}));
        this.fetchCustomerDetails();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "failure"}));
      }
    });
  };
  
  deactivateCustomer = (item) => {
    const { dispatch } = this.props;
    
    let payload = {
      customerId: item.id
    };

    dispatch(alertActions.startRequest());
    customerService.deactivateCustomer(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "success"}));
        this.fetchCustomerDetails();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "failure"}));
      }
    });
  };

  addCustomerStaff = () => {
    let { dispatch } = this.props;
    let {
      newCustomerTitle,
      newCustomerFirstName,
      newCustomerLastName,
      newCustomerEmail,
      newCustomerPhoneNo,
      selectedCustomer,
      isEditMode,
      staffId,
    } = this.state;

    let payload = {
      id: isEditMode ? staffId : 0,
      customerId: selectedCustomer.id,
      titleId: newCustomerTitle.id,
      firstName: newCustomerFirstName,
      lastName: newCustomerLastName,
      email: newCustomerEmail,
      phoneNumber: newCustomerPhoneNo,
    };

    dispatch(alertActions.startRequest());
    customerService.addCustomerStaff(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.success(response.message));
        this.toggleAddNewStaffModal();
        this.fetchCustomerStaff();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(response.message));
      }
    });
  };

  fetchDesignations = () => {
    let { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    commonService.getAllDesignations(source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          designations: response.data,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };
  handleChange = (event, attribute) => {
    this.setState({ [attribute]: event.target.value });
  };

  handleSelectChange = (payload, attribute) => {
    this.setState({ [attribute]: payload });
  };

  showAddNewStaff = () => {
    let count = 0;
    const {
      showAddNewStaffModal,
      newCustomerTitle,
      newCustomerFirstName,
      newCustomerLastName,
      newCustomerEmail,
      newCustomerPhoneNo,
      designations,
      isEditMode,
      companyRepId,
      settingsByKeyRecord
    } = this.state;
    
    
    let modifiedDesignations = designations;
    
    if (newCustomerTitle.id !== companyRepId) {
       modifiedDesignations = appHelpers.filterRecordByValue(designations, settingsByKeyRecord, "id", "value", true)
    }
    
    return (
      <FullScreenModal
        show={showAddNewStaffModal}
        key={count + 1}
        callback={this.toggleAddNewStaffModal}
        zIndex={10}
        width={"35%"}
      >
        <NewStaff
          newCustomerTitle={newCustomerTitle}
          newCustomerFirstName={newCustomerFirstName}
          newCustomerLastName={newCustomerLastName}
          newCustomerEmail={newCustomerEmail}
          newCustomerPhoneNo={newCustomerPhoneNo}
          handleChange={this.handleChange}
          handleSelectChange={this.handleSelectChange}
          closeAddNewCustomerModal={this.toggleAddNewStaffModal}
          designations={modifiedDesignations}
          addCustomerStaff={this.addCustomerStaff}
          isEditMode={isEditMode}
          companyRepId={companyRepId}
        />
      </FullScreenModal>
    );
  };

  handleSwitchChecked = (e, item) => {
    let payload = {
      customerStaffId: item.id,
    };
    if (e.target.checked === true) {
      this.activateCustomerStaff(payload);
    }
    if (e.target.checked === false) {
      this.deactivateCustomerStaff(payload);
    }
  };

  handleActivateCustomer = (e, item) => {

    if (e.target.checked === true) {
      this.activateCustomer(item);
    }
    if (e.target.checked === false) {
      this.deactivateCustomer(item);
    }
  };

  toggleAddNewStaffModal = () => {
    this.setState(
      { showAddNewStaffModal: !this.state.showAddNewStaffModal },
      this.resetModal()
    );
  };

  /**
   * @param {String} pathname path to be routed to
   * @param {String} attribute name of the state attribute in new route
   * @param {*} value state to be passed into the new route
   */
  handleChangeRoute = (pathname, attribute, value) => {
    this.props.history.push({
      pathname,
      state: { [attribute]: value },
    });
  };

  editCustomerStaff = (selectedCustomerStaff) => {
    this.setState(
      {
        isEditMode: true,
        staffId: selectedCustomerStaff.id,
        newCustomerTitle: {
          id: selectedCustomerStaff.titleId,
          name: selectedCustomerStaff.titleName,
        },
        newCustomerEmail: selectedCustomerStaff.email,
        newCustomerFirstName: selectedCustomerStaff.firstName,
        newCustomerLastName: selectedCustomerStaff.lastName,
        newCustomerPhoneNo: selectedCustomerStaff.phoneNumber,
      },
      this.toggleAddNewStaffModal()
    );
  };
  
  activateStaffOnSSO = (item) => {
    let payload = {
      customerStaffId: item.id,
    };
  
   this.migrateCustomerStaffOnSSO(payload)
  };
  
  // currently not used. remove after test
  deactivateStaff = (item) => {
    let payload = {
      customerStaffId: item.id,
      status: "deactivate"
    };
    
    this.staffActivationStatus(payload)
  };
  
  // currently not used. remove after test
  staffActivationStatus = (payload) => {
    const {dispatch} = this.props;
  
    dispatch(alertActions.startRequest());
    let service = payload.status === "activate" ? selfService.activateSelfServiceCustomerStaff(payload, source.token) : selfService.deactivateSelfServiceCustomerStaff(payload, source.token);
    service.then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "success"}));
        this.fetchCustomerDetails();
        this.fetchCustomerStaff();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "failure"}));
      }
    });
  };
  
  migrateCustomerStaffOnSSO = (payload) => {
    const {dispatch} = this.props;
    dispatch(alertActions.startRequest());
    selfService.migrateCustomerStaffOnSSO(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "success"}));
        this.fetchCustomerStaff();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "failure"}));
      }
    });
  };

  resetModal = () => {
    this.setState({
      isEditMode: false,
      staffId: undefined,
      newCustomerTitle: {},
      newCustomerEmail: "",
      newCustomerFirstName: "",
      newCustomerLastName: "",
      newCustomerPhoneNo: "",
    });
  };

  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
   }

  render() {
    const { customerStaff, customerDetails, showAddNewStaffModal, companyRepId } = this.state;
    const {user} = this.props;
    return (
      <ContentLayout
        pageTitle={
          <Row>
            <Col>
              <p>{customerDetails ? customerDetails.customerName : ""}</p>
              {
                customerDetails.allowSelfService ?
                  <p className={"title-badge"}>Self service</p>
                  :
                  null
              }
            </Col>
            <Col>
              <Toggle
                checked={customerDetails ? customerDetails.status : false}
                onChange={(e) =>
                  this.handleActivateCustomer(e, customerDetails)
                }
              />
              <div className="h6">
                {customerDetails && customerDetails.status
                  ? "Active"
                  : "Inactive"}
              </div>
            </Col>
          </Row>
        }
        requesting={this.props.requesting}
        actions={
          <div className="float-right">
            <Button
              className="btn-rounded create-action-btn text-white"
              color="success"
              style={{
                backgroundColor:
                  customerDetails && !customerDetails.status && "#C96CAC",
              }}
              onClick={() =>
                this.handleChangeRoute(
                  "/new-customer",
                  "customerDetails",
                  customerDetails
                )
              }
              disabled={
                customerDetails && customerDetails.status ? false : true
              }
            >
              Edit customer
            </Button>
          </div>
        }
      >
        <Row>
          <CustomerDetailsView customerDetails={customerDetails} />
        </Row>

        <hr />
        <Row className="my-2">
          <Col>
            <h4 className="font-weight-bold">Customer Staff details</h4>
          </Col>
          {(user.vggRole === appConstants.ROLES.VGG_ADMIN && customerDetails.allowSelfService)
            ?
            null
            :
          <Col>
            <Button
              className="btn-rounded float-right w-25 bg-white text-dark font-weight-bold"
              onClick={this.toggleAddNewStaffModal}
            >
              Add Staff
            </Button>
          </Col>
          }
        </Row>
        {customerStaff.length > 0 && (
          <Row>
            <Col md={"12"}>
              <TableStructure
                tableData={customerStaff}
                handleSwitchChecked={this.handleSwitchChecked}
                editCustomerStaff={this.editCustomerStaff}
                activateStaff={this.activateCustomerStaff}
                deactivateStaff={this.deactivateCustomerStaff}
                migrateStaff={this.activateStaffOnSSO}
                companyRepId={companyRepId}
                user={user}
                allowSelfService={customerDetails.allowSelfService}
              />
            </Col>
          </Row>
        )}

        {customerStaff.length === 0 && (
          <EmptyRecordMessage message={"No Customer Staff Found"} />
        )}
        {showAddNewStaffModal && this.showAddNewStaff()}
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, displayGeneralAlert } = state;
  return {
    user,
    requesting,
    displayGeneralAlert
  };
};

export default connect(mapStateToProps)(CustomerDetails);
