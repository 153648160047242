import React, {Component} from "react";
import {Table} from 'reactstrap';

const RenderRow = (props) =>{
    return props.keys.map((key, index)=>{
        return <td key={props.data[key]}>{props.data[key]}</td>
    })
}
class TableStructure extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    getKeys = () => {
        const {tableData} = this.props;
        return Object.keys(tableData[0])
    };

    getHeader = () => {
        let keys = this.getKeys();
        return keys.map((key, index)  => {
            return <th key={index}>{key}</th>
        })
    };

    getRowsData = () => {
        const {tableData} = this.props;
        let keys = this.getKeys();

        return tableData.map((row, index) => {
            return <tr key={index}><RenderRow key={index} data={row} keys={keys} /></tr>
        })
    }

    render() {
        const {tableData, bgColor} = this.props;
        return (
            <Table data={tableData}>
                <thead style={{backgroundColor: bgColor, color: "#fff"}}>
                <tr>{this.getHeader()}</tr>
                </thead>
                <tbody>
                {this.getRowsData()}
                </tbody>
            </Table>
        )
    }
} ;

export default TableStructure;