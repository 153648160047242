import React from "react";
import "./index.scss";
import {
  Card,
  CardBody,
  Row,
  Col,
  Table,
  ListGroupItem,
  ListGroup,
  Tooltip,
} from "reactstrap";

import { appHelpers, pageHelpers } from "../../_helpers";
import ProgressLoader from "../ContentLayout";
import { FaCopy } from "react-icons/fa";

const InvoiceSheet = ({
  invoiceDate,
  rrrNumber,
  companyName,
  companyLocation,
  invoiceSheetTitle,
  customerName,
  customerNumber,
  invoiceNumber,
  customerAddress,
  revenueCode,
  invoiceItems,
  totalAmount,
  paymentStatus,
  invoiceCreator,
  requesting,
  miniDisplay,
  organizationLogo,
  customerPhone,
  customerEmail,
  currency,
  coin,
  revenueGroupTag,
  revenueGroup,
  currencySymbol,
  copyDescription,
  // copyAmount,
  copyRRR,
  copyInvoiceNo,
  openTooltip,
  tooltipTarget,
  toggleTooltip,
  // mouseOver,
  toolTipPlacement,
  copyText,
  invSheetCopyIconMouseOut,
  showInvoiceSheetTooltip,
  isLiveView,
  invoiceSheetMouseOver,
  invoiceSheetMouseOut,
  invoiceSheetCopyIconMouseover,
  decimalPrecision,
  invoiceTitle, // used to tell if invoice is an old or new invoice.
  isNewInvoice,
  updatedFields, //array with lists of fields that was edited in new invoice
  showReasonForUpdate,
  reasonForUpdatingInvoice,
  enableMouseOver = true,
  enableMouseOut = true,
  creditNoteAmountString,
  amountPayableString,
  creditNoteRequestStatus,
  amountPayable,
  amountString,
  creditNoteReference,
}) => {
  console.log(customerName, "customer");
  console.log(revenueGroup, "group");
  console.log(totalAmount, "invAmount");
  console.log(invoiceNumber, "invNum");

  return (
    // <Container>
    <Card>
      {requesting && <ProgressLoader message={"loading..."} />}
      <Row className={"background"}>
        <Col>
          <p
            className={`paid-status ${
              isNewInvoice &&
              pageHelpers.highLight("paymentStatus", updatedFields)
            }`}
          >
            {paymentStatus}
          </p>
        </Col>
      </Row>

      <CardBody className={"invoice-sheet-body"}>
        {invoiceTitle && (
          <Row>
            <Col md={12} className="d-flex justify-content-center">
              <h3 class={`font-weight-bold`}> {invoiceTitle}</h3>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm="6">
            <img
              className={"invoice-sheet-logo"}
              src={organizationLogo}
              alt={"logo"}
              width={100}
            />
          </Col>
          {!miniDisplay && (
            <Col sm="3" className="offset-sm-3">
              <h1 className="">Invoice</h1>
              <div
                className="inv-no-wrapper"
                style={{ display: "flex" }}
                // remove functionality on live view
                onMouseLeave={(e) => {
                  if (!isLiveView && enableMouseOut) {
                    invoiceSheetMouseOut(e);
                  } else return null;
                }}
                onMouseOver={(e) => {
                  if (!isLiveView && enableMouseOver) {
                    invoiceSheetMouseOver(e);
                  } else return null;
                }}
              >
                <span>No: </span>
                <span
                  className={`inv-no ${
                    isNewInvoice &&
                    pageHelpers.highLight("invoiceNumber", updatedFields)
                  }`}
                  id={"inv-number"}
                >
                  {invoiceNumber}
                </span>
                {!isLiveView && invoiceNumber ? (
                  <span
                    onClick={() => copyInvoiceNo("inv-no")}
                    className={"inv-copy-wrapper inv-copy-wrapper-hide"}
                  >
                    <FaCopy
                      id={"inv-icon"}
                      className="copy-icon"
                      onMouseOver={(e) => {
                        if (enableMouseOver) {
                          invoiceSheetCopyIconMouseover(
                            e,
                            "openInvSheetTooltip",
                            "bottom-end",
                            "Copy"
                          );
                        } else return null;
                      }}
                      onMouseOut={() => {
                        if (enableMouseOut) {
                          invSheetCopyIconMouseOut("openInvSheetTooltip");
                        } else return null;
                      }}
                    />
                  </span>
                ) : null}
              </div>
            </Col>
          )}
          {miniDisplay && (
            <Col sm="4" className="offset-sm-2">
              <h1 className="">Invoice</h1>
              <p
                className={`${
                  isNewInvoice &&
                  pageHelpers.highLight("InvoiceNumber", updatedFields)
                }`}
              >
                No: {invoiceNumber}
              </p>
            </Col>
          )}
        </Row>
        <div className="text-center">
          <h2
            className={`${
              isNewInvoice &&
              pageHelpers.highLight("CustomerName", updatedFields)
            }`}
          >
            {companyName}
          </h2>
          <h4
            className={`text-uppercase ${
              isNewInvoice && pageHelpers.highLight("Location", updatedFields)
            }`}
          >
            {companyLocation}
          </h4>
          <p
            className={`${
              isNewInvoice && pageHelpers.highLight("Revenue", updatedFields)
            }`}
          >
            {invoiceSheetTitle}
          </p>
        </div>

        <Row className="mb-5">
          <Col sm="12">
            <ListGroup
              flush
              className="font-12"
              style={{ borderTop: "2px solid", borderBottom: "2px solid" }}
            >
              <ListGroupItem>
                <Row>
                  <Col
                    sm={`5 ${
                      isNewInvoice &&
                      pageHelpers.highLight("CustomerName", updatedFields)
                    }`}
                  >
                    Customer Name: {customerName}
                  </Col>
                  <Col
                    sm="3"
                    className={`${
                      isNewInvoice &&
                      pageHelpers.highLight("Customer", updatedFields)
                    }`}
                  >
                    Customer No: {customerNumber}
                  </Col>
                  <Col sm="4">
                    <div
                      className="rrr-wrapper d-flex"
                      onMouseLeave={(e) => {
                        if (!isLiveView && enableMouseOver) {
                          invoiceSheetMouseOut(e);
                        } else return null;
                      }}
                      onMouseOver={(e) => {
                        if (!isLiveView && enableMouseOut) {
                          invoiceSheetMouseOver(e);
                        } else return null;
                      }}
                    >
                      <span>RRR: </span>
                      <span
                        className={`inv-rrr ${
                          isNewInvoice &&
                          pageHelpers.highLight("rrrNumber", updatedFields)
                        }`}
                        id="inv-rrr-number"
                      >
                        {rrrNumber}
                      </span>
                      {/*  {rrrNumber}*/}
                      {/*</textarea>{" "}*/}
                      {!isLiveView && rrrNumber ? (
                        <span
                          onClick={() => copyRRR("inv-rrr")}
                          className={"inv-copy-wrapper inv-copy-wrapper-hide"}
                        >
                          <FaCopy
                            id={"rrr-icon"}
                            className="copy-icon"
                            onMouseOut={() => {
                              if (enableMouseOut) {
                                invSheetCopyIconMouseOut("openInvSheetTooltip");
                              } else return null;
                            }}
                            onMouseOver={(e) => {
                              if (enableMouseOver) {
                                invoiceSheetCopyIconMouseover(
                                  e,
                                  "openInvSheetTooltip",
                                  "bottom-end",
                                  "Copy"
                                );
                              } else return null;
                            }}
                          />
                        </span>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col
                    sm={`5 ${
                      isNewInvoice &&
                      pageHelpers.highLight(
                        "CustomerPhoneNumber",
                        updatedFields
                      )
                    }`}
                  >
                    Customer Phone: {customerPhone}
                  </Col>
                  <Col
                    sm="3"
                    className={`${
                      isNewInvoice &&
                      pageHelpers.highLight("CustomerEmail", updatedFields)
                    }`}
                  >
                    Customer Email: {customerEmail}
                  </Col>
                  <Col
                    sm="4"
                    className={`${
                      isNewInvoice &&
                      pageHelpers.highLight("InvoiceDate", updatedFields)
                    }`}
                  >
                    Invoice Date: {appHelpers.formatInvoiceDate(invoiceDate)}
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col
                    sm={`5 ${
                      isNewInvoice &&
                      pageHelpers.highLight("CustomerAddress", updatedFields)
                    }`}
                  >
                    Address: {customerAddress}
                  </Col>
                  <Col
                    sm="3"
                    className={`${
                      isNewInvoice &&
                      pageHelpers.highLight("RevenueCode", updatedFields)
                    }`}
                  >
                    Revenue Code: {revenueCode}
                  </Col>
                  <Col
                    sm="4"
                    className={`${
                      isNewInvoice &&
                      pageHelpers.highLight("invoiceCreator", updatedFields)
                    }`}
                  >
                    Invoice Issued By: {invoiceCreator}
                  </Col>
                </Row>
              </ListGroupItem>
              {revenueGroupTag && (
                <ListGroupItem>
                  <Row>
                    <Col
                      sm={`5 ${
                        isNewInvoice &&
                        pageHelpers.highLight("Directorate", updatedFields)
                      }`}
                    >
                      {revenueGroupTag}: {revenueGroup}
                    </Col>
                  </Row>
                </ListGroupItem>
              )}
            </ListGroup>
          </Col>
        </Row>
        <h3 className="text-center">ITEMS</h3>
        <Table responsive={true}>
          <thead>
            <tr>
              <th>S/No</th>
              <th>Item Description</th>
              <th>Qty</th>
              <th>Rate</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {!invoiceItems && (
              <tr className="text-center">
                <td colSpan="5">
                  <p>No items available</p>
                </td>
              </tr>
            )}
            {invoiceItems &&
              invoiceItems.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="td-desc">
                      <div
                        onMouseLeave={(e) => {
                          if (!isLiveView && enableMouseOut) {
                            invoiceSheetMouseOut(e);
                          } else return null;
                        }}
                        onMouseOver={(e) =>
                          !isLiveView &&
                          enableMouseOver &&
                          invoiceSheetMouseOver(e)
                        }
                        className={"description-wrappers d-flex"}
                      >
                        <span
                          id={`invoice-description-${index}`}
                          className={`inv-description-${index} ${
                            isNewInvoice &&
                            pageHelpers.highLight(
                              "ItemDescription",
                              updatedFields
                            )
                          }`}
                          style={{ flex: "0 0 30%" }}
                        >
                          {item.description}
                        </span>
                        {!isLiveView && item.description ? (
                          <span
                            className={"inv-copy-wrapper inv-copy-wrapper-hide"}
                          >
                            <FaCopy
                              id={`description-icon-${index}`}
                              className="copy-icon"
                              onClick={() =>
                                copyDescription(`inv-description-${index}`)
                              }
                              onMouseOut={() => {
                                if (enableMouseOut) {
                                  invSheetCopyIconMouseOut(
                                    "openInvSheetTooltip"
                                  );
                                } else return null;
                              }}
                              onMouseOver={(e) => {
                                if (enableMouseOver) {
                                  invoiceSheetCopyIconMouseover(
                                    e,
                                    "openInvSheetTooltip",
                                    "bottom-end",
                                    "Copy"
                                  );
                                } else return null;
                              }}
                            />
                          </span>
                        ) : null}
                      </div>
                    </td>
                    <td
                      className={`${
                        isNewInvoice &&
                        pageHelpers.highLight("ItemQuantity", updatedFields)
                      }`}
                    >
                      {appHelpers.numberWithCommasOnly(item.quantity)}
                    </td>
                    <td
                      className={`${
                        isNewInvoice &&
                        pageHelpers.highLight("ItemRate", updatedFields)
                      }`}
                    >
                      {appHelpers.numberWithCommasOnly(
                        item.rate,
                        decimalPrecision
                      )}
                    </td>
                    <td>
                      {/* <div className="amount-wrappers"> */}
                      <span
                        id={`invoice-amt-${index}`}
                        className={`inv-amt-${index} ${
                          isNewInvoice &&
                          pageHelpers.highLight("Amount", updatedFields)
                        }`}
                      >
                        {appHelpers.numberWithCommasOnly(item.amount, 2)}
                      </span>
                      {/* {!(isLiveView && item.amount) ? (
                        <>
                          <span
                            onClick={() => copyAmount(`inv-amt-${index}`)}
                            onMouseOver={() =>
                              mouseOver(
                                `amount-icon-${index}`,
                                "openInvSheetTooltip",
                                "bottom-end",
                                "Copy amount"
                              )
                            }
                            onMouseOut={() => invSheetCopyIconMouseOut("openInvSheetTooltip")}
                          >
                            <FaCopy
                              id={`amount-icon-${index}`}
                              className="copy-icon"
                            />
                          </span>
                        </>
                      ) : null} */}
                      {/* </div> */}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {creditNoteRequestStatus !== 1 && (
          <Row>
            <Col sm="12">
              <ListGroup
                flush
                className="font-12"
                style={{ borderTop: "2px solid", borderBottom: "2px solid" }}
              >
                <ListGroupItem>
                  <Row>
                    <Col sm="2">
                      <b>Amount in Words:</b>
                    </Col>
                    <Col sm="6" className="">
                      {`${appHelpers.withDecimal(totalAmount, currency, coin)}`}
                    </Col>
                    <Col sm={"4"}>
                      <span className="text-uppercase inv-total">TOTAL:</span>
                      <span className={"inv-total-num"}>
                        <b>
                          <span
                            className={` ${
                              isNewInvoice &&
                              pageHelpers.highLight("Currency", updatedFields)
                            }`}
                          >
                            {currencySymbol}
                          </span>{" "}
                          {appHelpers.numberWithCommasOnly(
                            Number(totalAmount),
                            2
                          )}
                        </b>
                      </span>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Col>
            {showReasonForUpdate && (
              <Col md="8" className={"reason-for-change"}>
                <h4 className="font-weight-bold">Reason for Update</h4>
                <p>{reasonForUpdatingInvoice}</p>
              </Col>
            )}
          </Row>
        )}

        {creditNoteRequestStatus === 1 && (
          <Row style={{ padding: "10px 0", marginTop: "20px" }}>
            <Col>
              <div
                style={{ marginRight: "10%" }}
                className="d-flex justify-content-end "
              >
                <div>
                  <div>Less Credit Amount:</div>
                  <div className="font-weight-bold">{creditNoteReference}</div>
                </div>

                <b className="ml-3">{creditNoteAmountString}</b>
              </div>
              <div
                style={{ marginRight: "24%", textAlign: "right" }}
                className="less-credit font-weight-bold"
              >
                <b className="ml-3">&nbsp;</b>
              </div>
            </Col>
          </Row>
        )}

        {creditNoteRequestStatus === 1 && (
          <Row
            style={{
              padding: "10px 0",
              borderBottom: "2px solid",
              borderTop: "2px solid",
            }}
          >
            <Col sm="12">
              <div className="d-flex justify-content-between">
                <div
                  style={{ marginRight: "12%", textAlign: "right" }}
                  className="less-credit font-weight-bold"
                >
                  Amount in words:&nbsp;{" "}
                  <span className="font-weight-normal">{`${appHelpers.withDecimal(
                    totalAmount,
                    currency,
                    coin
                  )}`}</span>
                </div>
                <div
                  style={{ marginRight: "12%", textAlign: "right" }}
                  className="less-credit"
                >
                  TOTAL:&nbsp;
                  <b className="ml-3">{amountString}</b>
                </div>
              </div>
            </Col>
          </Row>
        )}

        {showInvoiceSheetTooltip && (
          <Tooltip
            placement={toolTipPlacement}
            isOpen={openTooltip}
            target={tooltipTarget}
            toggle={toggleTooltip}
          >
            {copyText}
          </Tooltip>
        )}
      </CardBody>
    </Card>
  );
};

export default InvoiceSheet;
