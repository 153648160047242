import React, { Component } from "react";
import { Button, Row, Col, Badge } from "reactstrap";
import ContentLayout from "../../../components/ContentLayout";
import { FaArrowLeft } from "react-icons/fa";
import { appHelpers } from "../../../_helpers";
import { connect } from "react-redux";
import "./index.scss";
import { alertActions, modalActions } from "../../../_actions";
import { appConstants } from "../../../_constants/app.constants";
import { commonService } from "../../../_services";
import moment from "moment";
import axios from "axios";
import GeneralAlert from "../../../components/Api-response-alert/index";
let source;

const Fields = ({ label, value }) => {
  return (
    <div>
      <h6>{label}</h6>
      <p className='font-weight-bold'>{value ? value : "-"}</p>
    </div>
  );
};

class RequestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestDetailsRecord: {},
      comment: "",
      requestApprovalStatus: "",
      processStatus: null,
    };
    source = axios.CancelToken.source();
  }

  static getDerivedStateFromProps(props, state) {
    let pathState = props.location.state;

    if (pathState) {
      if (
        props.location.state.requestDetailsRecord !== state.requestDetailsRecord
      ) {
        return {
          requestDetailsRecord: props.location.state.requestDetailsRecord,
        };
      }
    }

    return null;
  }

  processCreditNote = (requestDetailsRecord) => {
    const { dispatch } = this.props;
    const { comment, processStatus } = this.state;
    const { referenceNumber } = requestDetailsRecord;

    let payload = {
      referenceNumbers: [referenceNumber],
      processStatus: processStatus,
      comment: comment,
    };

    dispatch(alertActions.startRequest());
    commonService.processCreditNote(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "success",
          })
        );
        setTimeout(() => {
          appHelpers.navigateRoute.call(this, "/my-requests");
        }, 3000);
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "failure",
          })
        );
      }
    });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(modalActions.displayGeneralAlert({ show: false }));
    if (source) {
      source.cancel();
    }
  }

  requestButton = (action) => {
    const { requestDetailsRecord } = this.state;
    if (action === "Approved") {
      this.setState({ processStatus: "1" }, () => {
        this.processCreditNote(requestDetailsRecord);
      });
    }

    if (action === "Disapproved") {
      this.setState({ processStatus: "2" }, () => {
        this.processCreditNote(requestDetailsRecord);
      });
    }
  };

  /**
   * @param {String} pathname path to be routed to
   * @param {String} attribute name of the state attribute in new route
   * @param {*} value state to be passed into the new route
   */

  render() {
    const { requestDetailsRecord } = this.state;
    const {
      referenceNumber,
      createdOn,
      invoiceNumber,
      serviceProviderName,
      revenueName,
      creditAmount,
      creditNoteTypeName,
      requestStatusName,
      comment,
      newInvoiceAmount,
      invoiceAmount,
      currencyName,
    } = requestDetailsRecord;

    const { requesting } = this.props;

    const currencyXter = appHelpers.getCurrencyXter(currencyName.toLowerCase());

    return (
      <div>
        <ContentLayout
          requesting={requesting}
          pageTitle={"Credit Note Details"}
          actions={
            <div className={"float-right"}>
              <Button
                outline
                color='info'
                className=' btn-rounded mr-2 big-action-btn'
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          }
        >
          <Row>
            <Col md={"3"}>
              <Fields label='Credit Ref' value={referenceNumber} />
            </Col>
            <Col md={"3"}>
              <Fields
                label='Date'
                value={createdOn && moment(createdOn).format("Do MMMM, YYYY")}
              />
            </Col>
            <Col md={"3"}>
              <Fields label='invoice number' value={invoiceNumber} />
            </Col>
            <Col md={"3"}>
              <Fields label='Customer' value={serviceProviderName} />
            </Col>
          </Row>
          <Row>
            <Col md={"3"}>
              <Fields label='Revenue Line' value={revenueName} />
            </Col>
            <Col md={"3"}>
              <Fields
                label='Credit Amount'
                value={
                  <span>
                    {currencyXter}
                    {appHelpers.numberWithCommas(creditAmount)}
                  </span>
                }
              />
            </Col>
            <Col md={"3"}>
              <Fields label='Type' value={creditNoteTypeName} />
            </Col>
            <Col md={"3"}>
              <Fields
                label='Status'
                value={
                  <Badge color={`${requestStatusName ? "success" : "danger"}`}>
                    {requestStatusName}
                  </Badge>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={"3"}>
              <Fields
                label='Old Invoice Amount'
                value={
                  <span>
                    {currencyXter}
                    {appHelpers.numberWithCommas(invoiceAmount)}
                  </span>
                }
              />
            </Col>
            <Col md={"3"}>
              <Fields
                label='Payable Amount'
                value={
                  <span>
                    {currencyXter}
                    {appHelpers.numberWithCommas(newInvoiceAmount)}
                  </span>
                }
              />
            </Col>
          </Row>
          <br />
          <hr />

          <Row>
            <Col md={12}>
              <h6 className='font-weight-bold mt-2'>Comment</h6>
              <textarea
                name='comment'
                id=''
                cols=''
                rows='5'
                placeholder={"Type your comment..."}
                value={comment}
                onChange={this.handleInputChange}
                style={{
                  borderRadius: "10px",
                  borderColor: "#ebebeb",
                  padding: "10px",
                  fontSize: "14px",
                  width: "100%",
                  marginBottom: "30px",
                }}
              ></textarea>
            </Col>
          </Row>
          <Row>
            <Col md='12' className='d-flex justify-content-right'>
              <Button
                color='success'
                className='btn-sm btn-rounded px-4 mr-2 text-white'
                onClick={(e) => this.requestButton("Approved")}
              >
                Approve
              </Button>
              <Button
                color='danger'
                className='btn-sm btn-rounded px-4 mr-2 text-white'
                onClick={(e) => this.requestButton("Disapproved")}
              >
                Reject
              </Button>
            </Col>
          </Row>
          {/* <Row>
        <Col md={12}>
          <Button color={"success"} className={"btn-rounded ml-3 mb-2 mt-2 px-4"} style={{ float: "right", marginTop: "10px", marginLeft: "5px" }} onClick={submitClicked}>{buttonSubmitText}</Button>
        </Col>

      </Row> */}
          {this.props.displayGeneralAlert.show && <GeneralAlert />}
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, apiReducer, displayGeneralAlert } = state;
  return {
    user,
    requesting,
    apiReducer,
    displayGeneralAlert,
  };
};

export default connect(mapStateToProps)(RequestDetails);
