import React, { Component } from "react";
import Permissions from "../create-user/permissions";
import ContentLayout from "../../../components/ContentLayout";
import "./index.css";
import SearchPanel from "./search-panel";
import { Button } from "reactstrap";
import UserRole from "./user-role";
import { appHelpers } from "../../../_helpers";
import { FaArrowLeft } from "react-icons/fa";
import { alertActions } from "../../../_actions";
import { commonService } from "../../../_services";
import { appConstants } from "../../../_constants";
import axios from "axios";
import { connect } from "react-redux";
import { RenderAlertModal } from "../../../components/AlertModal";
import { FaCheckCircle, FaTimes, FaCheck } from "react-icons/fa";

let source;
class createEditRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionsData: [],
      revenueData: [
        { name: "permission 1", activated: false },
        { name: "permission 2", activated: false },
        { name: "permission 3", activated: false },
        { name: "permission 4", activated: false },
        { name: "permission 5", activated: false },
        { name: "permission 6", activated: false },
        { name: "permission 7", activated: false },
        { name: "permission 8", activated: false },
        { name: "permission 9", activated: false },
        { name: "permission 10", activated: false },
      ],
      vggRoles: [],
      vggRole: "",
      roles: [],
      role: "",
      organizations: [],
      organization: "",
      organizationId: null,
      pageTitle: appConstants.CREATE_ROLE,
      showRoleProfile: false,
      userDetails: false,
      editingRoleProfilePermissions: false,
      showAlertModal: false,
      alertIcon: "",
      alertMessage: "",
      userStatus: false,
      selectAllPermissions: false,
    };

    source = axios.CancelToken.source();
  }

  componentWillMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    const { user } = this.props;

    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      this.setState({ vggRoles: appConstants.VGG_ROLES });
    } else {
      this.setState({ vggRoles: appConstants.CLIENT_ROLES });
    }
    let stateFromPath = this.props.history.location.state; //This is gotten from the manage user route and used to set the initial state of the page.

    if (stateFromPath) {
      let userDetails = stateFromPath.item;
      let pageTitle = stateFromPath.pageTitle;
      let showRoleProfile = stateFromPath.showRoleProfile;
      if (userDetails) {
        this.setState(
          {
            role: userDetails.roleName,
            vggRole: appHelpers.getVggRole(userDetails.vggRole),
            pageTitle: stateFromPath.pageTitle,
            userStatus: userDetails.status,
            organizationId: userDetails.agencyId,
            userDetails: userDetails,
          },
          () => {
            if (this.state.userDetails) {
              this.getRolePermissions();
            }
          }
        );
      }
      if (pageTitle) {
        this.setState({ pageTitle: pageTitle });
      }
      if (showRoleProfile !== undefined) {
        this.setState({
          showRoleProfile: showRoleProfile,
          userDetails: userDetails,
        });
      }

      if (!userDetails) {
        this.setState({ organizationId: stateFromPath.organizationId }, () => {
          this.getRolePermissions();
        });
      }
    }
  }

  getRolePermissions = () => {
    const { userDetails } = this.state;
    const { dispatch, user } = this.props;
    const payload = {};

    if (userDetails.roleId) {
      payload.RoleId = userDetails.roleId;
    }

    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      payload.AgencyId = 0;
    } else {
      payload.AgencyId = user.organizationId;
    }

    dispatch(alertActions.startRequest());

    commonService.getRolePermissions(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          permissionsData: response,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
          this.setState({ alertMessage: response });
        } else {
          dispatch(alertActions.error(response.message));
          this.setState({ alertMessage: response.message });
        }
      }
    });
  };

  // This onChange handler, gets the checked value
  checkboxChange = (e, type) => {
    const { permissionsData, revenueData } = this.state;

    let checkedStatus = e.target.checked; //Value of the check input
    let changedPermission = e.target.parentNode.parentNode.textContent; //The text for the element that was checked.
    // This would be used to identify the object in the array that was checked, so as to accurately update it's activated property.

    let updatedRecord; //Variable to hold the record that was updated
    if (type === "permissions") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        permissionsData
      );
      this.setState({ permissionsData: updatedRecord });
    } else if (type === "revenue") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        revenueData
      );
      this.setState({ revenueData: updatedRecord });
    }
  };

  handleSelectChange = (payload, attribute) => {
    let currState = this.state;
    // let errorMessages = this.state.componentErrorMessages;
    // errorMessages[attribute] = false;
    // currState[attribute] = (payload) ? payload[attribute] : ''; //Cater for setting up data and clearing data
    if (!payload) {
      currState[attribute] = "";
    } else {
      if (attribute === "vggRole") {
        currState[attribute] = payload;
      } else if (attribute === "organization") {
        currState[attribute] = payload.id;
      } else if (attribute === "revenue") {
        currState[attribute] = payload.id;
      } else {
        currState[attribute] = payload.Id;
      }
    }
    this.setState({ currState });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // for editing user, when the role profile view is displayed.
  // The editingRoleProfilePermissions variable in state is used to determine what is displayed when the back button is clicked
  editRoleProfile = (userDetails) => {
    this.setState({
      showRoleProfile: false,
      pageTitle: appConstants.EDIT_ROLES,
      editingRoleProfilePermissions: true,
    });
  };

  //Handler the control what is displayed when the back button is clicked
  navigateBack = () => {
    if (this.state.editingRoleProfilePermissions) {
      this.setState({ showRoleProfile: true });
      window.location.reload();
    } else {
      this.props.history.goBack();
      // this.props.history.push("/manage-users")
    }
  };

  filterPermission = () => {
    const { permissionsData } = this.state;

    const filteredPermissions = permissionsData
      .filter((item) => item.activated === true)
      .map((item) => item.permissionId);
    return filteredPermissions ? filteredPermissions : [];
  };

  createEditRole = () => {
    const {
      role,
      vggRole,
      pageTitle,
      userDetails,
      organizationId,
    } = this.state;
    const { dispatch } = this.props;
    let permissionsId = this.filterPermission();

    if (permissionsId) {
      let payload = {
        roleName: role,
        vggRole: vggRole.id,
        permissionIds: permissionsId,
        agencyId: organizationId,
      };

      if (pageTitle === appConstants.EDIT_ROLES) {
        payload.roleId = userDetails.roleId;
      }

      dispatch(alertActions.startRequest());

      commonService.createEditRoles(payload, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState(
            {
              selectAllPermissions: false,
              showAlertModal: true,
              alertIcon: (
                <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
              ),
              alertMessage: response.message,
            },
            () => this.dismissModal("showAlertModal")
          );
          if (pageTitle === appConstants.CREATE_ROLE) {
            this.resetFields();
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (response === "Please Check your internet connectivity") {
            dispatch(alertActions.error(response));
          } else if (typeof response === "string") {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response,
              },
              () => {
                appHelpers.dismissModal.call(this, "showAlertModal");
              }
            );
          } else {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response.message,
              },
              () => {
                appHelpers.dismissModal.call(this, "showAlertModal");
              }
            );
          }
        }
      });
    }
  };

  activateRole = (payload) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    commonService.activateRole(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState(
          {
            showAlertModal: true,
            alertIcon: (
              <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
            ),
            alertMessage: response.message,
            userStatus: true,
          },
          () => {
            appHelpers.dismissModal.call(this, "showAlertModal");
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response, false);
      }
    });
  };

  deactivateRole = (payload) => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());
    commonService.deactivateRole(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState(
          {
            showAlertModal: true,
            alertIcon: (
              <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
            ),
            alertMessage: response.message,
            userStatus: false,
          },
          () => {
            appHelpers.dismissModal.call(this, "showAlertModal");
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response, false);
      }
    });
  };

  handleActivationStatus = (e) => {
    const { userDetails } = this.state;
    let payload = {
      RoleId: userDetails.roleId,
    };

    if (e.target.checked === true) {
      this.activateRole(payload);
    }
    if (e.target.checked === false) {
      this.deactivateRole(payload);
    }
  };

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  resetFields = () => {
    let { permissionsData } = this.state;
    let updatedPermissions = permissionsData.map((item) => {
      if (item.activated === true) item.activated = false;
      return item;
    });

    this.setState({
      role: "",
      vggRole: "",
      permissionsData: updatedPermissions,
      selectAllPermissions: false,
    });
  };

  dismissModal = (alert) => {
    setTimeout(() => {
      const body = document.body;
      body.style.overflow = "scroll";
      this.setState({ [alert]: false });
    }, 5000);
  };

  cancel = () => {
    const { pageTitle } = this.state;
    if (pageTitle === appConstants.CREATE_ROLE) {
      this.resetFields();
    }
    if (
      pageTitle === appConstants.EDIT_ROLES ||
      pageTitle === appConstants.ROLE_PROFILE
    ) {
      appHelpers.navigateToPreviousRoute.call(this);
    }
  };

  selectAllCheckboxes = () => {
    const { permissionsData } = this.state;

    this.setState(
      (prevState) => ({
        selectAllPermissions: !prevState.selectAllPermissions,
      }),
      () => {
        if (this.state.selectAllPermissions) {
          let permissionsActivated = permissionsData.map((item) => {
            item.activated = true;
            return item;
          });
          this.setState({ permissionsData: permissionsActivated });
        } else if (!this.state.selectAllPermissions) {
          let permissionsActivated = permissionsData.map((item) => {
            item.activated = false;
            return item;
          });
          this.setState({ permissionsData: permissionsActivated });
        }
      }
    );
  };

  //Alert Notifications
  displayErrorResponseAlert = (response, action, query) => {
    const { dispatch } = this.props;
    if (response === "Please Check your internet connectivity") {
      dispatch(alertActions.error(response));
    } else if (typeof response === "string") {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    } else {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response.message,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    }
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      permissionsData,
      role,
      pageTitle,
      showRoleProfile,
      userDetails,
      vggRoles,
      vggRole,
      showAlertModal,
      alertMessage,
      alertIcon,
      userStatus,
      selectAllPermissions,
      requesting,
    } = this.state;

    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle={pageTitle}
        actions={
          <div className={"float-right"}>
            <Button
              color="info"
              outline
              className="btn-rounded mr-2 big-action-btn"
              onClick={this.navigateBack}
              disabled={requesting}
            >
              <FaArrowLeft className={"mb-1 mr-1"} /> Back
            </Button>
          </div>
        }
      >
        <div className={"search-container"}>
          {!showRoleProfile && (
            <SearchPanel
              role={role}
              handleChange={this.handleChange}
              vggRoles={vggRoles}
              vggRole={vggRole}
              selectChange={this.handleSelectChange}
            />
          )}
          {showRoleProfile && (
            <UserRole
              role={role}
              userDetails={userDetails}
              editRoleProfile={this.editRoleProfile}
              status={userStatus}
              handleActivationStatus={this.handleActivationStatus}
            />
          )}
        </div>
        {/*<hr />*/}

        <div className={"permissions-container"}>
          <h2 className={"permissions-title"}>
            Permissions{" "}
            {!selectAllPermissions && pageTitle !== appConstants.ROLE_PROFILE && (
              <span onClick={() => this.selectAllCheckboxes()}>
                {" "}
                <FaCheck /> Select all{" "}
              </span>
            )}
            {selectAllPermissions && pageTitle !== appConstants.ROLE_PROFILE && (
              <span onClick={() => this.selectAllCheckboxes()}>
                {" "}
                <FaTimes /> Deselect all{" "}
              </span>
            )}
          </h2>
          <Permissions
            permissions={permissionsData}
            isChecked={permissionsData.activated}
            checkboxChange={(e) => this.checkboxChange(e, "permissions")}
          />
        </div>
        {pageTitle !== "Role Profile" && (
          <div>
            <Button
              color="success"
              className="btn-sm btn-rounded mr-2 text-white"
              onClick={this.createEditRole}
              disabled={requesting}
            >
              {pageTitle === appConstants.CREATE_ROLE ? "Create Role" : "Save"}
            </Button>
            <Button
              outline
              className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
              onClick={this.cancel}
              disabled={requesting}
            >
              Cancel
            </Button>
          </div>
        )}
        {showAlertModal && (
          <RenderAlertModal
            message={alertMessage}
            icon={alertIcon}
            showAlertModal={showAlertModal}
            hideAlertView={this.hideAlertView}
          />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, alert, progress, user, requesting } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(createEditRole);
