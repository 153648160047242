import React, {Component} from 'react';
import Header from "../../layouts/layout-components/header/header";
import {connect} from "react-redux";
import {showSidebarLayout} from "../../redux/settings/action";
import Footer from "../../layouts/layout-components/footer/footer";
import {commonActions} from "../../_actions";


class NoSidebarLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
    }
  }
  
  componentDidMount() {
    const {dispatch} = this.props;
    
    dispatch(showSidebarLayout(false))
    dispatch(commonActions.displayLoaderFullOverlay(true));
    
  }
  
  componentWillUnmount() {
    const {dispatch} = this.props;

    dispatch(showSidebarLayout(true))

  }
  
  render() {
    return (
      <div
        id="main-wrapper"
        dir={this.props.settings.activeDir}
        data-theme={this.props.settings.activeTheme}
        data-layout={this.props.settings.activeThemeLayout}
        data-header-position={this.props.settings.activeHeaderPos}
        data-boxed-layout={this.props.settings.activeLayout}
      >
        <Header />
        <div className="page-wrapper d-block">
          <div style={{background: "#07345B", minHeight: "90px"}}>
          
          </div>
          <div className="page-content no-sidebar-page-content">
            {this.props.children}
          </div>
          <Footer />
        </div>
      
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});


export default connect(mapStateToProps)(NoSidebarLayout);