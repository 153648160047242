import React from "react";
import SearchBar from "../../SearchBar";
import { Row, Col, Button } from "reactstrap";

const QuickSearch = ({
  right,
  value,
  onChange,
  search,
  searchInvoiceNumber,
  searchCustomerName,
  color,
  invoiceBtnOutline,
  customerBtnOutline,
  isCustomer,
}) => {
  return (
    <div>
      <Row>
        <Col sm={{ size: 6, offset: 3 }} className="text-center">
          <SearchBar
            right={right}
            value={value}
            onChange={onChange}
            search={search}
            name={"quickSearchValue"}
          />
        </Col>
        <Col sm={12} className="text-center">
          <Button
            outline={invoiceBtnOutline}
            color={color}
            className="border-radius mt-2 mr-2"
            onClick={searchInvoiceNumber}
          >
            Search by Invoice Number
          </Button>
          {!isCustomer && (
            <Button
              outline={customerBtnOutline}
              color={color}
              className={"border-radius mt-2"}
              onClick={searchCustomerName}
            >
              Search by Customer Name
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default QuickSearch;
