import React, { Component } from "react";
import { Row, Col, Form, Button, FormGroup, Label, Input } from "reactstrap";
import ContentLayout from "../../../components/ContentLayout";
import BackButton from "../../../components/BackButton";
import TableStructure from "./selected-invoices-record";
import { appHelpers } from "../../../_helpers";
import { connect } from "react-redux";
import { appConstants } from "../../../_constants";
import { alertActions, commonActions, modalActions } from "../../../_actions";
import GeneralAlert from "../../../components/Api-response-alert";
import { creditNoteService } from "../../../_services/creditNote.service";
import axios from "axios";

let source;

class ApplyCreditNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedInvoices: [],
      referenceNumber: "",
      creditNotesDiscount: 0,
      reasonForIssuance: "",
      creditType: "discount",
    };

    source = axios.CancelToken.source();
  }

  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;
    if (pathState) {
      if (pathState.selectedAgencyDetails !== state.selectedAgencyDetails) {
        return {
          organizationDetails: pathState.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { organizationDetails } = this.state;

    //get route the user is coming from
    let previousPath;
    if (this.props.history.location.state) {
      previousPath = this.props.history.location.state.from;
    }

    //set Role related state
    let vggAdminRole =
      user.vggRole === appConstants.ROLES.VGG_ADMIN ||
      user.vggRole === appConstants.ROLES.VGG_USER;

    //Everytime a user with a VGG Role vists this page, the user must select an organization
    //organizationDetails===undefined prevents redirect from happening again once an organization is selected

    if (vggAdminRole && organizationDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationId: vggAdminRole
            ? organizationDetails && organizationDetails.id
            : user.organizationId,
        },
        () => this.fetchInitialReferenceData()
      );
    }
  };

  componentDidMount() {
    const body = document.body;
    body.style.overflow = "scroll";

    this.setState({ logo: this.props.settings.setLogo });
    this.props.dispatch(commonActions.setPeriodPickerVisibility(false));

    let pathState = this.props.location.state;
    let { selectedRecord } = pathState;

    if (pathState) {
      this.setState({ pathState });

      if (selectedRecord) {
        this.setState({ selectedInvoices: selectedRecord });
      }
    }
    this.routeUserBasedOnRole();
  }

  fetchInitialReferenceData = () => {};

  componentDidUpdate(prevProps) {
    if (this.props.settings.setLogo !== prevProps.settings.setLogo) {
      this.setState({
        logo: this.props.settings.setLogo,
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  applyCreditNote = () => {
    const { dispatch } = this.props;
    const {
      selectedInvoices,
      creditNoteDiscount,
      creditType,
      reasonForIssuance,
    } = this.state;

    let items = selectedInvoices.map((item) => {
      let validCreditNoteDiscount = 0;
      if (creditType === "discount") {
        validCreditNoteDiscount = (
          (creditNoteDiscount / 100) *
          item.amount
        ).toFixed(3);
      } else {
        validCreditNoteDiscount = creditNoteDiscount;
      }

      let percentageDiscount = 0;
      if (creditType === "discount" && creditNoteDiscount) {
        percentageDiscount = creditNoteDiscount;
      } else if (creditType === "value" && creditNoteDiscount) {
        percentageDiscount = ((creditNoteDiscount * 100) / item.amount).toFixed(
          3
        );
      }
      return {
        agencyId: item.agencyId,
        creditNoteTypeId: 1,
        customerId: item.customerId,
        revenueId: item.revenueId,
        locationId: item.locationId,
        creditAmount: Number(validCreditNoteDiscount),
        invoiceId: item.invoiceId,
        invoiceAmountPercentage: Number(percentageDiscount),
        currencyId: item.currencyId,
      };
    });

    let payload = {
      entries: items,
      reasonForIssuance: reasonForIssuance,
    };
    // console.log("payload", payload)

    dispatch(alertActions.startRequest());
    creditNoteService
      .initiateCreditNoteRequest(payload, source.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          let record = {
            appliedCreditNotes: response.result,
            message: response.message,
            from: "/apply-credit-note",
          };

          appHelpers.navigateRoute.call(this, "/applied-credit-note", record);
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
          if (typeof response === "string") {
            dispatch(alertActions.error(response));
          } else {
            this.props.dispatch(
              modalActions.displayGeneralAlert({
                show: true,
                message: response.message,
                action: "success",
              })
            );
            dispatch(alertActions.error(response.message));
          }
        }
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleRadioButtonChange = (event, attribute, value) => {
    this.setState({ [attribute]: value });
  };

  displayApplyBtn = () => {
    const { reasonForIssuance, creditNoteDiscount } = this.state;
    if (reasonForIssuance && creditNoteDiscount) {
      return true;
    }
    return false;
  };

  creditNoteStatus = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case 2:
        return "Disapproved";
      case null:
        return "No credit note applied";
      default:
        return "No credit note applied";
    }
  };

  render() {
    const { selectedInvoices, creditNoteDiscount, creditType } = this.state;
    const { requesting } = this.props;

    return (
      <ContentLayout
        pageTitle={`Waivers and Discount`}
        requesting={requesting}
        actions={
          <BackButton
            navigateBack={() => appHelpers.navigateToPreviousRoute.call(this)}
          />
        }
      >
        <Row className=" mb-4 ml-1">
          <h2>
            <b>Apply Credit Note</b>
          </h2>
        </Row>
        <Form>
          <Row>
            <Col md="12">
              <h4>Select Credit Type</h4>
            </Col>
            <Col md="12" className="my-2">
              <FormGroup tag="fieldset">
                <FormGroup check inline>
                  <Label check>
                    <Input
                      name="radio1"
                      type="radio"
                      checked={creditType === "discount"}
                      style={{ width: "30px" }}
                      onClick={(e) =>
                        this.handleRadioButtonChange(
                          e,
                          "creditType",
                          "discount"
                        )
                      }
                    />
                    Discount %
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      name="radio1"
                      type="radio"
                      checked={creditType === "value"}
                      style={{ width: "30px" }}
                      onClick={(e) =>
                        this.handleRadioButtonChange(e, "creditType", "value")
                      }
                    />
                    Value
                  </Label>
                </FormGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className="my-3">
            <Col md={4}>
              <FormGroup>
                <Label for="creditNoteDiscount">Enter Discount</Label>
                <Input
                  type="number"
                  name="creditNoteDiscount"
                  id="creditNoteDiscount"
                  onChange={this.handleChange}
                  className={"search-inputs"}
                />
              </FormGroup>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Label for="reasonForIssuance">Reason for Issuance</Label>
                <Input
                  type="text"
                  name="reasonForIssuance"
                  id="reasonForIssuance"
                  onChange={this.handleChange}
                  className={"search-inputs"}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        {selectedInvoices.length > 0 && (
          <Row style={{ marginTop: "25px" }}>
            <Col className="d-flex justify-content-between">
              <div>
                {" "}
                <h2 className="mb-0">Selected Invoices</h2>{" "}
              </div>{" "}
              {this.displayApplyBtn() && (
                <div>
                  <Button
                    className="btn-rounded mr-2  float-right mb-2  px-5 btn btn-outline-info"
                    onClick={(e) => this.applyCreditNote(e)}
                  >
                    Apply
                  </Button>
                </div>
              )}
            </Col>
            <Col md={"12"}>
              <TableStructure
                // currentPage={currentPage}
                // fetchMore={this.ApplyCreditNote}
                // pageSize={pageSize}
                // totalCount={totalCount}
                data={selectedInvoices}
                user={this.props.user}
                creditType={creditType}
                creditNoteDiscount={creditNoteDiscount}
                creditNoteStatus={this.creditNoteStatus}
              />
            </Col>
          </Row>
        )}
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, apiReducer, displayGeneralAlert, settings } = state;
  return {
    user,
    requesting,
    apiReducer,
    displayGeneralAlert,
    settings,
  };
};

export default connect(mapStateToProps)(ApplyCreditNote);
