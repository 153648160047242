import React from 'react';
import {Row, Col} from "reactstrap";
import SummaryCard from "../../../components/SummaryCard";

const Summary = ({invoicedTopValue, paidTopValue, countTopValue, paidNairaTopValue, paidDollarBottomValue,
                     invoicedBottomValue, paidBottomValue,      
                     countBottomValue, marginTop}) => {
    return (
        <div style={{marginTop: marginTop}} className={"summary"}>
           {/* <Row>
               <Col md={12}>
                   <h3 className={"mb-0"}>Summary</h3>
               </Col>
           </Row> */}
        {/* <hr className={"mb-4"}/> */}
        <Row>
            <Col md={3}>
                <SummaryCard
                    cardTitle={"Total Overdue Invoice"}
                    bgColor={"#def5fd"}
                    topValue= { invoicedTopValue}
                    bottomValue={invoicedBottomValue}
                />
            </Col>
            <Col md={3}>
                <SummaryCard
                    cardTitle={"Total Paid Invoice"}
                    bgColor={"#e3f8ef"}
                    topValue={ paidTopValue}
                    bottomValue={paidBottomValue}
                    />
            </Col>
            
            <Col md={3}>
                <SummaryCard
                    cardTitle ={"Count (N, $)"}
                    bgColor={"#f6e9f3"}
                    topValue= {countTopValue }
                    bottomValue={countBottomValue}
                />
            </Col>
            <Col md={3}>
                <SummaryCard
                    cardTitle ={"Paid Count (N, $)"}
                    bgColor={"#FDE4E0"}
                    topValue= {paidNairaTopValue }
                    bottomValue={paidDollarBottomValue}
                />
            </Col>
        </Row>
        </div>
    );
};

export default Summary;