import React, { Component } from "react";
import { Table, Badge } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "./index.scss";
import "react-toggle/style.css" // for ES6 modules
import Toggle from 'react-toggle'
import {appConstants} from "../../../_constants";





const Records = ({ tableData , showRoleProfile, editRolePermissions,
                    handleSwitchChecked, user}) => {
  return (
    <Table responsive={true}  className={"table-main"} style={{ fontSize: "14px" , cursor: "pointer"}}>
      <thead style={{ backgroundColor: "#6C77C9", color: "white" }}>
        <tr>
          <th style={{ padding: "none" }}>Role</th>
            {user.vggRole === appConstants.ROLES.VGG_ADMIN &&
            <th style={{padding: "none"}}>Agency</th>
            }
          <th style={{ padding: "none" }}>System Role</th>
          <th style={{ padding: "none" }}>Action</th>
          <th style={{ padding: "none"}}></th>
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.map((item,index) => {
          return (
            <tr key={index} className={"table-row"} onClick={(e) =>{item.status !== false &&  showRoleProfile(e, item) } }>
              <th style={{ padding: "none" }}>{item.roleName}</th>
                {user.vggRole === appConstants.ROLES.VGG_ADMIN &&
                <th style={{ padding: "none" }}>{item.agency}</th>
                }
              <th style={{ padding: "none" }}>{item.vggRole}</th>
              <th style={{ padding: "none" }}>
                {item.status !== false &&(
              <Badge onClick={(e) => editRolePermissions(e, item)}
              className={"badge-spacing"}
              size="xl" pill>Edit</Badge>)}
              {
                  <label className={"toggle-label"}>
                      <Toggle
                          // defaultChecked={item.status}
                          checked={item.status}
                          onChange={(e) => handleSwitchChecked(e, item)} />
                  </label>
                }</th>        
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};


export default class TableStructure extends Component {
  render() {
    let {
      tableData,
      currentPage,
      fetchMoreRecords,
      pageSize,
      totalCount,
        showRoleProfile,
        editRolePermissions,
        activateRole,
      toggle,
      handleSwitchChecked,
      changeSwitch,
        user
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : ((pageSize * (currentPage - 1)) + 1);
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMoreRecords}
          pageSize={pageSize}
          totalCount={totalCount}
          target={<Records tableData={tableData}
                           pageIndex={pageIndex}
                           showRoleProfile={showRoleProfile}
                           editRolePermissions={editRolePermissions}
                           activateRole={activateRole}
                           toggle={toggle}
                           handleSwitchChecked={handleSwitchChecked}
                           changeSwitch={changeSwitch}
                           user={user}
          />}
        />
      </div>
    );
  }
}
