import React, { Component } from "react";
import * as serviceWorker from "./serviceWorker";
import { store } from "react-notifications-component";
import { Button } from "reactstrap";

export default class ServiceWorkerWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      newVersionAvailable: false,
      waitingWorker: {},
    };
  }

  componentDidMount = () => {
    if (process.env.NODE_ENV === "production") {
      // Learn more about service workers: https://bit.ly/CRA-PWA
      serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });
    }
  };

  onServiceWorkerUpdate = (registration) => {
    this.setState({
      waitingWorker: registration && registration.waiting,
      newVersionAvailable: true,
    });
  };

  updateServiceWorker = () => {
    const { waitingWorker } = this.state;
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    this.setState({ newVersionAvailable: false });
    window.location.reload();
  };

  NotificationContent = (key) => {
    return (
      <div className="text-center bg-white w-100 p-3 border border-info">
        <h4 className="text-info">There's a new update available!</h4>
        <div className="text-info">
          Close other existing open tabs, then click refresh button below.
        </div>

        <Button
          className="btn-rounded big-action-btn text-white bg-black mt-3"
          onClick={() => this.updateServiceWorker()}
          color="info"
        >
          Refresh
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.state.newVersionAvailable &&
          //show notification with refresh button
          store.addNotification({
            title: "There's a new update available!",
            message: "Click to reload page",
            type: "success",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 300000,
              onScreen: true,
              showIcon: true,
              click: false,
              pauseOnHover: true,
            },
            width: 500,
            content: this.NotificationContent(),
          })}
      </div>
    );
  }
}
