import React, { Component } from "react";
import TableStructure from "./record";
import { Button } from "reactstrap";
import SearchBar from '../../../components/SearchBar';
import ContentLayout from "../../../components/ContentLayout";
import {  FaArrowLeft, FaCheckCircle, FaPlusCircle, FaTimes } from "react-icons/fa";
import {Row, Col} from "reactstrap";
import { appHelpers } from "../../../_helpers";
import { commonService } from "../../../_services/common.service";
import { appConstants } from "../../../_constants/app.constants";
import { connect } from "react-redux";
import axios from "axios";
import { alertActions } from  "../../../_actions";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import "./index.scss";
import {RenderAlertModal} from "../../../components/AlertModal";

let source;

class RolesAndPermissions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [
            ],
            currentPage: 1,
            pageSize: 10,
            totalCount: 0,
            toggle: false,
            changeSwitch: false,
            showAlertModal: false,
            alertIcon: "",
            alertMessage: "",
            roleSearchValue: "",
            isVggRole: undefined
        }
        source = axios.CancelToken.source();

    }
  
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first
    
    let pathState = props.location.state;
    if (pathState) {
      if (pathState.selectedAgencyDetails !== state.selectedAgencyDetails) {
        return {
          organizationDetails: pathState.selectedAgencyDetails,
        };
      }
    }
    
    return null;
  }
  
  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { organizationDetails } = this.state;
    
    //get route the user is coming from
    let previousPath;
    if (this.props.history.location.state) {
      previousPath = this.props.history.location.state.from
    }
    
    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;
    
    //Everytime a user with a VGG Role vists this page, the user must select an organization
    //organizationDetails===undefined prevents redirect from happening again once an organization is selected
    
    if (vggAdminRole && organizationDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationId: vggAdminRole
            ? organizationDetails && organizationDetails.id
            : user.organizationId,
        },
        () => this.fetchInitialReferenceData()
      );
    }
  };
    
  
    componentDidMount() {
      /* Remove overflow hidden caused by modal */
      const body = document.body;
      body.style.overflow = "scroll";
      this.routeUserBasedOnRole();
    }
  
  fetchInitialReferenceData = () => {
    this.searchRoles();
  };

    
    searchRoles = (pageNumber = 1) => {
        const {dispatch} = this.props;
        const {roleSearchValue, pageSize, organizationId } = this.state;
        dispatch(alertActions.startRequest());

        const payload = {
            pageSize: pageSize,
            from: pageNumber,
            parameter: {
                searchQuery: roleSearchValue,
              organizationId
            }
        };


        commonService.searchRoles(payload, source.token)
            .then(res => {
                dispatch(alertActions.stopRequest());
                let response = res.response;

                if (res.status === appConstants.SUCCESS_RESPONSE) {
                    this.setState({
                        currentPage: pageNumber === 0 ? 1 : pageNumber,
                        data: response.results,
                        totalCount: response.totalCount
                    })
                }
                else if (res.status === appConstants.ERROR_RESPONSE) {
                  if (response === "Please Check your internet connectivity") {
                    dispatch(alertActions.error(response));
                  }
                }
            })

    }

    showRoleProfile = (e, item) => {
        if(e.target.parentElement.className === "table-row"){
            this.props.history.push({
                pathname: '/create-role',
                state: {item, showRoleProfile: true, "pageTitle": appConstants.ROLE_PROFILE}
            })
        }

    };

    editRolePermissions = (e, item) => {

        e.stopPropagation();
        this.props.history.push({
            pathname: '/create-role',
            state: {item, pageTitle: appConstants.EDIT_ROLES, showRoleProfile: false}
        })
    };

    showCreateRole = () => {
        this.props.history.push('/create-role')
    };

    activateRole = (payload) => {
        const {dispatch} = this.props;

        dispatch(alertActions.startRequest());
        commonService.activateRole(payload, source.token)
            .then(res => {
                dispatch(alertActions.stopRequest());
                let response = res.response;

                if (res.status === appConstants.SUCCESS_RESPONSE) {
                  this.displaySuccessResponseAlert(response, true, this.searchRoles())
                //     this.setState({
                //         showAlertModal: true,
                //         alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />,
                //         alertMessage: response.message,
                //
                //     }, () => {
                //         this.searchRoles();appHelpers.dismissModal.call(this, "showAlertModal");
                //     });
                //
                }
                else if (res.status === appConstants.ERROR_RESPONSE) {
                  this.displayErrorResponseAlert(response, true, this.searchRoles())
                //     if (typeof response === "string") {
                //         this.searchRoles()
                //         dispatch(alertActions.error(response));
                //         this.setState({alertMessage: response})
                //     } else {
                //         this.searchRoles();
                //         dispatch(alertActions.error(response.message));
                //         this.setState({alertMessage: response.message})
                //     }
                }
            })

    };

    deactivateRole = (payload) => {
        const {dispatch} = this.props;
        dispatch(alertActions.startRequest())
        commonService.deactivateRole(payload, source.token)
            .then(res => {
                dispatch(alertActions.stopRequest());
                let response = res.response;

                if (res.status === appConstants.SUCCESS_RESPONSE) {
                    this.displaySuccessResponseAlert(response, true, this.searchRoles())
                    // this.setState({
                    //     showAlertModal: true,
                    //     alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />,
                    //     alertMessage: response.message,
                    //
                    // }, () => {
                    //     this.searchRoles();appHelpers.dismissModal.call(this, "showAlertModal");
                    // });

                }
                else if (res.status === appConstants.ERROR_RESPONSE) {
                  this.displayErrorResponseAlert(response, true, this.searchRoles())
                    // this.searchRoles();
                    // if (typeof response === "string") {
                    //     dispatch(alertActions.error(response));
                    //     this.setState({alertMessage: response})
                    // } else {
                    //     this.searchRoles();
                    //     dispatch(alertActions.error(response.message));
                    //     this.setState({alertMessage: response.message})
                    // }
                }
            })

    };

    handleSwitchChecked = (e, item) => {
        let payload = {
            RoleId : item.roleId
        };
        
        if (e.target.checked === true ){
            this.activateRole(payload)
        }
        if (e.target.checked === false) {
            this.deactivateRole(payload)
        }

    };


    dismissModal = (alert) => {

        setTimeout(() => {
            const body = document.body;
            body.style.overflow = "scroll";
            this.setState({[alert]: false})
        }, 5000)
    };

    searchBarChange = (e) => {
        this.setState({roleSearchValue: e.target.value}, () => {
            if (this.state.roleSearchValue === "") {
                this.searchRoles()
            }
        })
    };

    search = (e) => {
        e.preventDefault();
        this.searchRoles()
    };
  
  //Alert Notifications
  displaySuccessResponseAlert = (response, action, query) => {
    this.setState(
      {
        showAlertModal: true,
        alertIcon: (
          <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
        ),
        alertMessage: response.message,
      },
      () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
      }
    );
    if (action) {
      return {query}
    }
  };
  
  displayErrorResponseAlert = (response, action, query) => {
    const {dispatch} = this.props;
    if (response === "Please Check your internet connectivity") {
      dispatch(alertActions.error(response));
    }else if (typeof response === "string") {
      this.setState({
        showAlertModal: true,
        alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
        alertMessage: response,
        
      }, () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
        if(action){
          return query
        }
      });
      
    } else {
      this.setState({
        showAlertModal: true,
        alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
        alertMessage: response.message,
        
      }, () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
        if(action){
          return query
        }
      });
    }
  };

  componentWillUnmount () {
   
    if (source) {
      source.cancel()
    }
   }

    render() {
        const { data, currentPage, totalCount, pageSize,
              changeSwitch, showAlertModal,
        alertMessage, alertIcon, roleSearchValue, organizationId} = this.state;

        return (
          <ContentLayout
            requesting={this.props.requesting}
            pageTitle={appConstants.ROLES_AND_PERMISSIONS}
            actions={
              <div className={"float-right"}>
                <Button
                  outline
                  color="info"
                  className="big-action-btn btn-rounded mr-2"
                  onClick={() =>appHelpers.navigateToPreviousRoute.call(this )}

                >
                  <FaArrowLeft className={"icon-margin"} />
                  Back
                </Button>
              </div>
            }
          >
            <Row className={"row-space"}>
              <Col>
                <Button
                 color="success"
                 className="btn-rounded create-action-btn mr-2 text-white"
                  onClick={() =>
                    appHelpers.navigateRoute.call(this, "/create-role", {
                      createRole: true,
                      organizationId
                    })
                  }
                >
                  <FaPlusCircle className={" mb-1"} /> Create Role
                </Button>
              </Col>
            </Row>

            <Row className={"settings-spacing"}>
              <Col sm={{ size: 5 }} className="text-center">
                <SearchBar
                  placeholder="Search role"
                  onChange={this.searchBarChange}
                  value={roleSearchValue}
                  showSearchButton
                  search={this.search}
                />
              </Col>
            </Row>
            {data.length === 0 && (
              <EmptyRecordMessage message={"No record match your search"} />
            )}
            {data.length > 0 && (
             <Row>
               <Col md={"12"}>
              <TableStructure
                tableData={data}
                showRoleProfile={this.showRoleProfile}
                editRolePermissions={this.editRolePermissions}
                handleSwitchChecked={this.handleSwitchChecked}
                changeSwitch={changeSwitch}
                pageSize={pageSize}
                totalCount={totalCount}
                currentPage={currentPage}
                fetchMoreRecords={this.searchRoles}
                user={this.props.user}
              />
              </Col>
              </Row>
            )}
            {showAlertModal && (
              <RenderAlertModal
                message={alertMessage}
                icon={alertIcon}
                showAlertModal={showAlertModal}
                hideAlertView={this.hideAlertView}
              />
            )}
          </ContentLayout>
        );

    }

}

const mapStateToProps = state => {
    const { modal, alert, progress, user, requesting } = state;
    return {
        modal,
        alert,
        progress,
        user,
        requesting
    };
};


export default connect(mapStateToProps)(RolesAndPermissions);