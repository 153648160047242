import React from "react";
import { Card, CardBody, Row, Col, Table, Button } from "reactstrap";
import SummaryCard from "../../components/SummaryCard";
import TableStructure from "../../components/DynamicTable";
import ServerPaginatedTable from "../../components/ServerPagination";
import SearchBar from "../../components/SearchBar";
import PeriodPicker from "../../components/PeriodPicker";
import {
  DatePicker,
  // MonthDatePicker
} from "../../components/DatePicker";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import VoidRequestForm from "../../components/VoidRequestForm";
import VoidRequestDetails from "../../components/VoidRequestDetails";
import Alert from "../../components/Alert";
import { FaCheckCircle } from "react-icons/fa";
import ActionCard from "../../components/ActionCard";
import ActionImage from "../../assets/images/big/d2.jpg";
import RequestCard from "../../components/RequestCard";
import FullScreenModal from "../../components/FullScreenModal";
import ActivityCard from "../../components/ActivityCard";
import OverviewCard from "../../components/OverviewCard";
import { appHelpers } from "../../_helpers";
import BarChartCard from "../../components/BarChartCard";
import DoughnutChartCard from "../../components/DoughnutChartCard";
import InvoiceSheet from "../../components/InvoiceSheet";
import ContentLayout from "../../components/ContentLayout";
// import ProgressLoader from "../../components/ProgressLoader";
import { store } from "react-notifications-component";
import Dialog from "../../components/Dialog";

// invoice items array
const invoiceItems = [
  { id: 1, description: "shipping", quantity: 2, rate: 20000, amount: 40000 },
];

//Doughnut Chart data template
const doughnutData = {
  labels: [
    "Cargo Port Charge",
    "Electricity Recoveries",
    "VIP Lounge Fees",
    "Port Charge (Import)",
    "Port Charge (Export)",
  ],

  datasets: [
    {
      data: [400, 50, 100, 80, 150],
      backgroundColor: ["#40D999", "#F57734", "#C96CAC", "#28BAF0", "#6C77C9"],
      hoverBackgroundColor: [
        "#40D999",
        "#F57734",
        "#C96CAC",
        "#28BAF0",
        "#6C77C9",
      ],
    },
  ],
};

// bar chart data template
let rFactor = function () {
  return Math.round(Math.random() * 100);
};
let barData = {
  labels: [
    "Port Charge (Export)",
    "Port Charge (Export)",
    "Port Charge (Import)",
    "Port Charge (Export)",
    "Vip Lounge Fees",
    "Electricity Recoveries",
    "Cargo Port Charge",
    "Car Park",
    "Access Gate",
    "Car Hire Service",
  ],
  datasets: [
    {
      label: "Invoice",
      backgroundColor: "#40D999",
      borderColor: "#40D999",
      data: [
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
      ],
    },
    {
      label: "Payment",
      backgroundColor: "#28BAF0",
      borderColor: "#28BAF0",
      data: [
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
        rFactor(),
      ],
    },
  ],
};

const TableDemo = ({ coordinates }) => {
  return (
    <Table>
      <thead style={{ backgroundColor: "#29baf1" }}>
        <tr>
          <th>Longitude</th>
          <th>Latitude</th>
          <th>Altitude</th>
        </tr>
      </thead>
      <tbody>
        {coordinates.map((item, index) => {
          return (
            <tr key={index}>
              <th>{item.longitude}</th>
              <th>{item.latitude}</th>
              <th>{item.altitude}</th>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const coordinates2 = [
  {
    longitude: "10.01154",
    latitude: "10.91026",
    altitude: "19675",
  },
  {
    longitude: "10.00981",
    latitude: "10.91058",
    altitude: "19700",
  },
  {
    longitude: "10.00809",
    latitude: "10.91095",
    altitude: "19750",
  },
  {
    longitude: "10.00502",
    latitude: "3.91145",
    altitude: "19800",
  },
  {
    longitude: "10.00192",
    latitude: "3.91197",
    altitude: "19875",
  },
  {
    longitude: "10.42050",
    latitude: "4.20842",
    altitude: "27875",
  },
  {
    longitude: "10.37982",
    latitude: "4.22578",
    altitude: "27875",
  },
  {
    longitude: "10.27680",
    latitude: "4.29497",
    altitude: "27875",
  },
  {
    longitude: "10.27029",
    latitude: "4.29943",
    altitude: "27875",
  },
  {
    longitude: "10.26186",
    latitude: "4.30506",
    altitude: "27900",
  },
];

class ComponentsShowcase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      totalCount: 20,
      value: "",
      date: new Date(),
      students: [
        { id: 1, name: "Wasif", age: 21, email: "wasif@email.com" },
        { id: 2, name: "Ali", age: 19, email: "ali@email.com" },
        { id: 3, name: "Saad", age: 16, email: "saad@email.com" },
        { id: 4, name: "Asad", age: 25, email: "asad@email.com" },
      ],
      coordinates: [
        {
          longitude: "9.01154",
          latitude: "3.91026",
          altitude: "19675",
        },
        {
          longitude: "9.00981",
          latitude: "3.91058",
          altitude: "19700",
        },
        {
          longitude: "9.00809",
          latitude: "3.91095",
          altitude: "19750",
        },
        {
          longitude: "9.00502",
          latitude: "3.91145",
          altitude: "19800",
        },
        {
          longitude: "9.00192",
          latitude: "3.91197",
          altitude: "19875",
        },
        {
          longitude: "8.42050",
          latitude: "4.20842",
          altitude: "27875",
        },
        {
          longitude: "8.37982",
          latitude: "4.22578",
          altitude: "27875",
        },
        {
          longitude: "8.27680",
          latitude: "4.29497",
          altitude: "27875",
        },
        {
          longitude: "8.27029",
          latitude: "4.29943",
          altitude: "27875",
        },
        {
          longitude: "8.26186",
          latitude: "4.30506",
          altitude: "27900",
        },
      ],
    };
  }

  fetchMore = () => {
    this.setState({ currentPage: 2, coordinates: coordinates2 });
  };

  onChange = (e) => {
    this.setState({ value: e.target.value });
    this.setState({ value: e.target.value });
  };

  tabcallback = (response) => {};
  changeDatePicker = (date) => {
    this.setState({ date });
  };

  acceptClicked = (date) => {
    // console.log("clicked");
  };

  declineClicked = (date) => {
    // console.log("decline clicked");
  };

  showNotitifcation = () => {
    store.addNotification({
      // title: "",
      message: "Unable to process request",
      type: "danger",
      insert: "top",
      width: 250,
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        // onScreen: true
      },
    });
  };

  render() {
    const { students, coordinates } = this.state;

    return (
      <ContentLayout
        pageTitle="Showcase"
        actions={
          <div className="float-right mb-2">
            <Button color="info" className="btn-sm btn-rounded px-4 mr-2">
              Save
            </Button>
            <Button color="info" outline className="btn-sm btn-rounded px-4">
              Proceed
            </Button>
          </div>
        }
      >
        <div>
          <Row>
            <Col>
              <InvoiceSheet
                rrrNumber={22356555}
                companyName={"Federal Airports Authority of Nigeria"}
                companyLocation={"MMIA (INTERNATIONAL)"}
                invoiceSheetTitle={"Cargo Port Charge"}
                customerName={"Anthony Joshua"}
                customerNumber={22356555}
                invoiceNumber={54678765}
                customerAddress={"Airport Road"}
                revenueCode={"A346"}
                invoiceDate={"10-12-2019"}
                invoiceItems={invoiceItems}
                totalAmount={appHelpers.numberWithCommas(40000)}
                totalAmountInWords={"Forty Thousand Naira Only"}
                paymentStatus={false}
              />
            </Col>
            <Col>
              {/* <ProgressLoader message="Loading data..."/> */}

              <Button onClick={this.showNotitifcation}>
                Show Notification
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="7">
              <BarChartCard
                title="Total number of invoices issued and payments made"
                barData={barData}
              />
            </Col>
            <Col md="5">
              <DoughnutChartCard
                title="Top 5 Revenue Lines (Invoices)"
                doughnutData={doughnutData}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <OverviewCard
                label="Invoices This Month"
                currency="naira"
                amount={`${appHelpers.numberWithCommas(32564560)}`}
                count={200}
                borderColor={"#40D999"}
              />
            </Col>
            <Col>
              <OverviewCard
                label="Invoices This Month"
                currency="dollar"
                amount={`${appHelpers.numberWithCommas(32564560)}`}
                count={200}
                borderColor={"#40D999"}
              />
            </Col>
            <Col>
              <OverviewCard
                label="Payments This Month"
                currency="naira"
                amount={`${appHelpers.numberWithCommas(32564560)}`}
                count={200}
                borderColor={"#28BAF0"}
              />
            </Col>
            <Col>
              <OverviewCard
                label="Payments This Month"
                currency="dollar"
                amount={`${appHelpers.numberWithCommas(32564560)}`}
                count={200}
                borderColor={"#28BAF0"}
              />
            </Col>
          </Row>

          <PeriodPicker
            placeholders={["ThisMonth", "Week", "Today"]}
            callback={this.tabcallback}
          />
          <FullScreenModal show={false}>
            <SummaryCard
              cardTitle={"Amount Invoiced"}
              bgColor={"#def5fd"}
              topValue={
                <h2 style={{ marginBottom: 0 }}>&#36; 108, 440,175.93</h2>
              }
              bottomValue={<h2>&#8358; 40,400,500</h2>}
            />
          </FullScreenModal>
          <ActivityCard
            bgColor="#28BAF0"
            placeholder="Invoices Raised"
            number={504}
          />
          <Card>
            <CardBody>Start your project from here...</CardBody>
          </Card>
          <Row>
            <Col md={"3"}>
              <SummaryCard
                cardTitle={"Amount Invoiced"}
                bgColor={"#def5fd"}
                topValue={
                  <h2 style={{ marginBottom: 0 }}>&#36; 108, 440,175.93</h2>
                }
                bottomValue={<h2>&#8358; 40,400,500</h2>}
              />
            </Col>
            <Col md={"3"}>
              <SummaryCard
                cardTitle={"Amount Invoiced"}
                bgColor={"#def5fd"}
                topValue={
                  <h2 style={{ marginBottom: 0 }}>&#36; 108, 440,175.93</h2>
                }
                bottomValue={<h2>&#8358; 40,400,500</h2>}
              />
            </Col>
            <Col md={"3"}>
              <SummaryCard
                cardTitle={"Amount Invoiced"}
                bgColor={"#def5fd"}
                topValue={
                  <h2 style={{ marginBottom: 0 }}>&#36; 108, 440,175.93</h2>
                }
                bottomValue={<h2>&#8358; 40,400,500</h2>}
              />
            </Col>
            <Col md={"3"}>
              <SummaryCard
                cardTitle={"Amount Invoiced"}
                bgColor={"#def5fd"}
                topValue={
                  <h2 style={{ marginBottom: 0 }}>&#36; 108, 440,175.93</h2>
                }
                bottomValue={<h2>&#8358; 40,400,500</h2>}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TableStructure tableData={students} bgColor={"#29baf1"} />
            </Col>
            {/*<Col md={12}>*/}
            {/*<TableStructure tableData={coordinates}/>*/}
            {/*</Col>*/}
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <ServerPaginatedTable
                  currentPage={this.state.currentPage}
                  fetchMore={this.fetchMore}
                  pageSize={this.state.pageSize}
                  totalCount={this.state.totalCount}
                  target={<TableDemo coordinates={coordinates} />}
                />
              </Card>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <Row>
                <Col md={6}>
                  <SearchBar placeholder={"search invoice"} right={"5%"} />
                </Col>
                <Col md={6}>
                  <SearchBar placeholder={"search invoice"} right={"5%"} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <DatePicker
                    date={this.state.date}
                    onChange={this.changeDatePicker}
                  />
                </Col>
                <Col md={6}>
                  {/* <MonthDatePicker
                    date={this.state.date}
                    setDate={this.changeDatePicker}
                  /> */}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col md={12}>
              <VoidRequestForm
                role={"Supervisor"}
                name={"Chigozie Adekola"}
                invoiceNumber={51102292}
                customer={"faan"}
                customerRole={"Staff"}
                revenue={"port charge"}
                invoiceDate={"23/01/2019"}
                amount={40000000}
                placeholder={"Insert comment here"}
                buttonText={"Send"}
                value={this.state.value}
                onChange={this.onChange}
              />
            </Col>
            <Col md={12}>
              <VoidRequestDetails
                role={"Supervisor"}
                name={"Chigozie Adekola"}
                invoiceNumber={51102292922}
                customer={"faan"}
                customerRole={"Staff"}
                revenue={"port charge"}
                invoiceDate={"23/01/2019"}
                amount={40000000}
                placeholder={"Insert comment here"}
                buttonAcceptText={"Accept"}
                buttonDeclineText={"Decline"}
                acceptClicked={this.acceptClicked}
                declineClicked={this.declineClicked}
                comment={"I just voided it"}
              />
            </Col>
            <Col md={6}>
              <Alert
                icon={<FaCheckCircle size={100} color={"#06d79d"} />}
                message={"Invoice Voided"}
              />
            </Col>
            <Col md={4}>
              <ActionCard
                image={
                  <img
                    style={{ width: "auto", height: "100%" }}
                    src={ActionImage}
                    alt="action"
                  />
                }
                action={"Manage Void Requests"}
                actionDetails={"Send invoices to your customers"}
              />
            </Col>
            <Col md={3}>
              <RequestCard
                bgColor={"#def5fd"}
                color={"#404a4c"}
                action={"Total Requests"}
                value={60}
              />
            </Col>
            <Col md={3}>
              <RequestCard
                bgColor={"#e3f8ef"}
                color={"#404a4c"}
                action={"Approved Requests"}
                value={41}
              />
            </Col>
            <Col md={3}>
              <RequestCard
                bgColor={"#fde4e0"}
                color={"#404a4c"}
                action={"Declined Requests"}
                value={14}
              />
            </Col>
            <Col md={3}>
              <RequestCard
                bgColor={"#c86cab"}
                color={"#fff"}
                headerColor={"#fff"}
                action={"Unattended Requests"}
                value={5}
              />
            </Col>
            <Col md={6}>
              <Dialog
                dialogText={"Are you sure you want to cancel this invoice"}
                declineClicked={this.declineClicked}
                buttonDeclineText={"No"}
                approveClicked={this.acceptClicked}
                buttonApproveText={"Yes"}
              />
            </Col>
          </Row>
        </div>
      </ContentLayout>
    );
  }
}

export default ComponentsShowcase;
