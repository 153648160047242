import React, { Component } from "react";
import { Table, Button, Input } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "react-toggle/style.css"; // for ES6 modules
// import "./index.scss";
import moment from "moment";

const Records = (props) => {
  const { apiData, user, takeRecord} = props;
console.log(takeRecord, "record");
//   console.log(takeRecord && takeRecord.some(i => i.invoiceNumber === takeRecord[1].invoiceNumber), "fix");
  return (
    <Table responsive={true} className={"table-main"}>
      <thead className={"th-head tracker-head"}>
        <tr>
          <th></th>
          <th>Invoice No</th>
          <th>RRR</th>
          <th>Invoice Amount</th>
          <th>Status</th>
          <th>Date</th>
          <th>Amount Paid</th>
          <th>Revenue</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {apiData.map((item, index) => {
          return (
            <tr key={index}>
              <th>
                
                <Input
                  style={{ position: "static", width: "40px" }}
                  type="checkbox"
                  id={`checkbox-${index}`}
                  checked={ takeRecord && takeRecord.some(i => i.invoiceNumber === item.invoice.invoiceNumber)} 
                  onChange={(e) => props.checkboxChange(e, item.invoice)}
                />
              </th>
              <th>{item.invoice.invoiceNumber}</th>
              <th>{item.invoice.remitaRetrievalReference}</th>
              <th>{item.invoice.amountString}</th>
              <th>{item.invoice.status}</th>
              <th>{moment(item.invoice.createdDate).format("YYYY-MM-DD")}</th>
              <th>{item.invoice.amountPaid}</th>
              <th>{item.invoice.revenue}</th>
              <th>{item.invoice.location}</th>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default class TableStructure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false, // State to track "Check all" checkbox

    };
  }

  toggleSelectAll = () => {
    this.setState((prevState) => ({
        ...prevState,
      selectAll: !prevState.selectAll,
    }));
  };
  render() {

    let {
      apiData,
      currentPage,
      fetchMore,
      pageSize,
      totalCount,
      user,
      checkboxChange,
      checkboxChangeAll,
      takeRecord,
      initiateRRR,
    } = this.props;
    const pageIndex = pageSize * (currentPage - 1) + 1;

    return (
      <div>
        {takeRecord.length > 0 && (
          <div>
            {/* <Input
             style={{ position: "static", width: "40px" }}
             type="checkbox"
             id="checkbox"
             onChange={(e) => checkboxChange(e)}
           /> */}
            <div style={{marginLeft: "-11px"}}>
              <Input
                style={{ position: "static", width: "40px", marginLeft: "5px" }}
                type="checkbox"
                id="checkbox"
                // checked={this.state.selectAll}
                // onChange={this.toggleSelectAll}
                onChange={(e) => checkboxChangeAll(e)}
              />
              <label htmlFor="checkbox">Check all</label>
            </div>
            <Button
              className="btn-rounded mr-2  float-right mb-2 mt-3 btn btn-outline-info"
              onClick={(e) => initiateRRR(e)}
            >
              Bulk Mark as Paid
            </Button>
          </div>
        )}
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageSize={pageSize}
          totalCount={totalCount}
        
          target={
            <Records
              apiData={apiData}
              pageIndex={pageIndex}
              user={user}
              checkboxChange={checkboxChange}
              checkboxChangeAll={checkboxChangeAll}
              selectAll={this.state.selectAll}
              takeRecord={takeRecord}
            />
          }
        />
      </div>
    );
  }
}
