import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function showMiniSidebarDropdown(state = initialState.showMiniSidebarDropdown, action) {

    switch (action.type) {
        case appConstants.SHOW_MINI_SIDEBAR_DROPDOWN:
            return action.show;
        default:
            return state;
    }
}
