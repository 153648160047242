import React, { Component } from "react";
import { Button, Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import ContentLayout from "../../../components/ContentLayout";
import TableStructure from "./customers-table";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import FilterParameters from "./filter-parameters";
import { Link } from "react-router-dom";
import { appConstants } from "../../../_constants";
import { appHelpers } from "../../../_helpers";
import axios from "axios";
import { customerService } from "../../../_services";
import { alertActions, modalActions } from "../../../_actions";
import DialogFullScreenModal from "../../../components/DialogFullScreenModal";
import GeneralAlert from "../../../components/Api-response-alert";
import SecondaryAlert from "../../../components/SecondaryAlert";
import { DuplicateMergeCustomersTable } from "./duplicateMergeCustomersTable";

let source;
class ManageCustomers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customers: [],
      name: "",
      ain: "",
      customerCode: "",
      isVggRole: false,
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      mergeBtnActive: false,
      unMergeBtnActive: false,
      dialogMergeMessage: "Are you sure you want to cancel the merge process?",
      dialogUnMergeMessage:
        "Are you sure you want to cancel the unmerge process?",
      // approveCancelMergeText: "Yes, cancel the merge process",
      // approveCancelUnMergeText: "Yes, camcel the unmerge process",
      selectedCustomers: [],
      customersWithSubs: [],
      secondaryAlertMessage: "",
      selfServiceCustomers: [],
      hasSelfService: false,
      allowForSelfService: null,
      hasSubCustomers: null,
      selectedParentCustomerToUnMerge: null,
    };
    source = axios.CancelToken.source();
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   */
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;

    if (pathState) {
      if (
        props.location.state.selectedAgencyDetails !==
        state.selectedAgencyDetails
      ) {
        return {
          agencyDetails: props.location.state.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    this.routeUserBasedOnRole();
  }

  /**
   * @description Route the user based on the role of the logged in user
   */
  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { agencyDetails } = this.state;

    //get route the user is coming from
    let previousPath;
    if (this.props.history.location.state) {
      previousPath = this.props.history.location.state.from;
    }

    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    //Everytime a user with a VGG Role vists this page, the user must select an agency
    //agencyDetails===undefined prevents redirect from happening again once an agency is selected
    if (vggAdminRole && agencyDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is already selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationName: agencyDetails
            ? agencyDetails.name
            : user.organization,
        },
        () => this.searchCustomer()
      );
    }
  };

  searchCustomer = (pageNumber = 1, searchClicked) => {
    const { dispatch, user } = this.props;
    const {
      agencyDetails,
      name,
      ain,
      customerCode,
      pageSize,
      mergeBtnActive,
      allowForSelfService,
      hasSubCustomers,
      unMergeBtnActive,
    } = this.state;

    if (
      (searchClicked && mergeBtnActive) ||
      (searchClicked && unMergeBtnActive)
    ) {
      this.cancelMergeProcess();
    }

    let payload = {
      pageSize,
      from: pageNumber,
      parameter: {
        name,
        ain,
        accountCode: customerCode,
        agencyId:
          user.vggRole === appConstants.ROLES.VGG_ADMIN
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
        allowForService: allowForSelfService,
        hasSubCustomers: hasSubCustomers,
      },
    };
    dispatch(alertActions.startRequest());
    customerService.searchCustomer(payload, source.token).then((res) => {
      let response = res.response;

      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        let results = response.results.map((item) => {
          // create a property called selected and set it's value to be false.
          // The value of the selected property would be used to control the checkboxes state for merge and unmerge customer
          item.selected = false;
          return item;
        });
        let modifiedResults = appHelpers.compareAndModifyArrayPropertyWithAnotherArray(
          results,
          this.state.selectedCustomers,
          "id",
          "selected",
          true
        );
        this.setState({
          currentPage: pageNumber === 0 ? 1 : pageNumber,
          customers: modifiedResults,
          totalCount: response.totalCount,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.setState({
          customers: [],
          totalCount: 0,
        });
      }
    });
  };

  activateCustomer = (payload) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    customerService.activateCustomer(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.success(response.message));
        this.searchCustomer();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(response.message));
      }
    });
  };

  deactivateCustomer = (payload) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    customerService.deactivateCustomer(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.success(response.message));
        this.searchCustomer();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(response.message));
      }
    });
  };

  handleChange = (event, attribute) => {
    this.setState({ [attribute]: event.target.value });
  };

  handleSwitchChecked = (e, item) => {
    let payload = {
      customerId: item.id,
    };
    if (e.target.checked === true) {
      this.activateCustomer(payload);
    }
    if (e.target.checked === false) {
      this.deactivateCustomer(payload);
    }
  };

  /**
   * @param {String} pathname path to be routed to
   * @param {String} attribute name of the state attribute in new route
   * @param {*} value state to be passed into the new route
   */
  handleChangeRoute = (pathname, attribute, value) => {
    this.props.history.push({
      pathname,
      state: { [attribute]: value },
    });
  };

  handleBtnClick = (action) => {
    let { hasSubCustomers } = this.state;
    let { savedRoutes, dispatch } = this.props;

    //check the user routes to know if they have the permission to view  "/settings-customers-to-merge" and  "/settings-merged-customers" routes.
    let hasPermission = savedRoutes.routes.some(
      (item) =>
        item.path === "/settings-customers-to-merge" ||
        item.path === "/settings-merged-customers"
    );

    //if user clicks on merge button, after having clicked on unmerge customers. (hasSubcustomers would be true if user clicked on unmerge customer)
    if (hasPermission) {
      if (action === "merge" && !hasSubCustomers) {
        this.setState({
          mergeBtnActive: true,
          unMergeBtnActive: false,
          allowForSelfService: null,
          hasSubCustomers: null,
        });
      }

      // If user clicks on merge button after clicking
      // on unmerge button, requery search customer API
      // to update table data
      if (action === "merge" && hasSubCustomers) {
        this.setState(
          {
            mergeBtnActive: true,
            unMergeBtnActive: false,
            allowForSelfService: null,
            hasSubCustomers: null,
          },
          () => this.searchCustomer()
        );
      }

      if (action === "unmerge") {
        this.setState(
          {
            unMergeBtnActive: true,
            mergeBtnActive: false,
            allowForSelfService: false,
            hasSubCustomers: true,
          },
          () => {
            this.searchCustomer();
          }
        );
      }
    } else {
      dispatch(
        modalActions.displayGeneralAlert({
          show: true,
          action: "failure",
          message: "You dont have permission to merge or unmerge customers",
        })
      );
    }
  };

  removeDialogAlert = () => {
    const { dispatch } = this.props;
    appHelpers.enableScroll();
    dispatch(modalActions.displayDialogAlert({ show: false }));
    // this.setState({ showDiscardModal: false });
  };

  cancelMergeProcess = () => {
    const { dispatch } = this.props;
    const { mergeBtnActive, unMergeBtnActive } = this.state;
    appHelpers.enableScroll();
    if (mergeBtnActive) {
      this.setState((prevState) => ({
        mergeBtnActive: false,
        selectedCustomers: [],
        secondaryAlertMessage: "",
        // return the selected property of all customer objects to false
        customers: prevState.customers.map((item) => {
          item.selected = false;

          return item;
        }),
        // hasSelfService: false,
        allowForSelfService: null,
        hasSubCustomers: null,
      }));
    } else if (unMergeBtnActive) {
      this.setState({
        unMergeBtnActive: false,
        customersWithSubs: [],
        secondaryAlertMessage: "",
        allowForSelfService: null,
        // hasSelfService: false,
      });
    }
    dispatch(
      modalActions.displayDialogAlert({
        show: false,
        message: "",
        declineText: "",
        approveText: "",
      })
    );
  };

  displayCancelDialog = () => {
    const { dispatch } = this.props;
    const {
      mergeBtnActive,
      unMergeBtnActive,
      dialogMergeMessage,
      dialogUnMergeMessage,
    } = this.state;
    // Display message
    if (mergeBtnActive) {
      dispatch(
        modalActions.displayDialogAlert({
          show: true,
          message: dialogMergeMessage,
          declineText: "No",
          approveText: "Yes",
        })
      );
    }
    if (unMergeBtnActive) {
      dispatch(
        modalActions.displayDialogAlert({
          show: true,
          message: dialogUnMergeMessage,
          declineText: "No",
          approveText: "Yes",
        })
      );
    }
  };

  continueProcess = () => {
    const {
      mergeBtnActive,
      unMergeBtnActive,
      selectedCustomers,
      agencyDetails,
    } = this.state;
    const { user } = this.props;

    if (mergeBtnActive) {
      if (this.validateMergeProcess()) {
        let pathState = {
          customersToMerge: selectedCustomers,
          agencyId:
            user.vggRole === appConstants.ROLES.VGG_ADMIN
              ? agencyDetails && agencyDetails.id
              : user.organizationId,
          agencyDetails,
        };
        appHelpers.navigateRoute.call(
          this,
          "/settings-customers-to-merge",
          pathState
        );
      }
    }
    if (unMergeBtnActive) {
      this.getSelectedCustomerDetails();
    }
  };

  validateMergeProcess = () => {
    let { selectedCustomers } = this.state;
    const { dispatch } = this.props;
    let isValid = true;
    let message = "";

    let customersWithSubs = selectedCustomers.filter(
      (item) => item.subCustomersCount > 0
    );

    let selfServiceCustomers = selectedCustomers.filter(
      (item) => item.allowSelfService === true
    );

    if (customersWithSubs.length > 0) {
      message =
        "These selected customers have been merged before. Please unmerge them before proceeding with the merge process.";
    }

    if (customersWithSubs.length === 1) {
      message =
        "This selected customer have been merged before. Please unmerge them before proceeding with the merge process.";
    }

    if (selfServiceCustomers.length > 0) {
      message =
        "These selected customers are self-service customers and cannot be merged with another customer.";
    }

    if (selfServiceCustomers.length === 1) {
      message =
        "This selected customer is a self-service customer and cannot be merged with another customer.";
    }

    if (customersWithSubs.length > 0) {
      this.setState({
        customersWithSubs: customersWithSubs,
        secondaryAlertMessage: message,
        hasSelfService: false,
      });
    }

    if (selfServiceCustomers.length > 0) {
      this.setState({
        selfServiceCustomers: selfServiceCustomers,
        secondaryAlertMessage: message,
        hasSelfService: true,
      });
    }

    if (selectedCustomers.length < 2) {
      isValid = false;
      // prompt an error notifying the users to select two (2) or more customers to continue merging.
      dispatch(
        modalActions.displayGeneralAlert({
          show: true,
          message:
            "Please select two or more customers to continue the merge process.",
          action: "failure",
        })
      );
    } else if (customersWithSubs.length > 0) {
      isValid = false;
      dispatch(modalActions.displaySecondaryAlert({ show: true }));
    } else if (selfServiceCustomers.length > 0) {
      isValid = false;
      dispatch(modalActions.displaySecondaryAlert({ show: true }));
    }

    return isValid;
  };

  validateUnMergeProcess = () => {
    const { selectedParentCustomerToUnMerge } = this.state;
    const { dispatch } = this.props;
    let isValid = true;

    if (!selectedParentCustomerToUnMerge) {
      isValid = false;
      dispatch(
        modalActions.displayGeneralAlert({
          show: true,
          message: "Please select a parent customer to unmerge.",
          action: "failure",
        })
      );
    }

    return isValid;
  };

  storeSelectedCustomerToMerge = (e, selectedItem) => {
    e.persist();
    if (e.target.checked) {
      this.setState((prevState) => ({
        selectedCustomers: [...prevState.selectedCustomers, selectedItem],
        customers: prevState.customers.map((item) => {
          if (item.id === selectedItem.id) {
            item.selected = true;
          }
          return item;
        }),
      }));
    } else if (!e.target.checked) {
      this.setState((prevState) => ({
        selectedCustomers: prevState.selectedCustomers.filter(
          (item) => item.id !== selectedItem.id
        ),
        customers: prevState.customers.map((item) => {
          if (item.id === selectedItem.id) {
            item.selected = false;
          }
          return item;
        }),
      }));
    }
  };

  storeSelectedCustomerToUnMerge = (e, item) => {
    this.setState({ selectedParentCustomerToUnMerge: item });
  };

  getSelectedCustomerDetails = () => {
    const { dispatch, user } = this.props;
    const { selectedParentCustomerToUnMerge, agencyDetails } = this.state;

    if (this.validateUnMergeProcess()) {
      let agencyId =
        user.vggRole === appConstants.ROLES.VGG_ADMIN
          ? agencyDetails && agencyDetails.id
          : user.organizationId;
      let payload = {
        agencyId,
        parentCustomerId: selectedParentCustomerToUnMerge.id,
      };

      dispatch(alertActions.startRequest());
      customerService
        .getMergeCustomerDetails(payload, source.token)
        .then((res) => {
          let response = res.response;
          dispatch(alertActions.stopRequest());
          if (res.status === appConstants.SUCCESS_RESPONSE) {
            let response = res.response;
            let pathState = {
              customersToUnMerge: response.data,
              route: "/manage-customers",
              agencyId,
              agencyDetails,
            };
            appHelpers.navigateRoute.call(
              this,
              "/settings-merged-customers",
              pathState
            );
          } else if (res.status === appConstants.ERROR_RESPONSE) {
            dispatch(
              modalActions.displayGeneralAlert({
                show: true,
                message: response.message,
                action: "failure",
              })
            );
          }
        });
    }
  };

  hideSecondaryAlert = () => {
    appHelpers.enableScroll();
    this.props.dispatch(modalActions.displaySecondaryAlert({}));
  };

  componentWillUnmount() {
    this.props.dispatch(modalActions.displaySecondaryAlert({}));
    this.props.dispatch(
      modalActions.displayGeneralAlert({ show: false, message: "" })
    );

    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      customers,
      isVggRole,
      name,
      ain,
      customerCode,
      organizationName,
      agencyDetails,
      currentPage,
      pageSize,
      totalCount,
      mergeBtnActive,
      unMergeBtnActive,
      customersWithSubs,
      secondaryAlertMessage,
      hasSelfService,
      selfServiceCustomers,
    } = this.state;
    const { requesting, alertComponentsReducer } = this.props;
    const dialogAlert = alertComponentsReducer.dialogAlert;
    return (
      <ContentLayout
        pageTitle="Manage Customer"
        requesting={requesting}
        actions={
          isVggRole && (
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          )
        }
      >
        <Row className={"row-space"}>
          <Col>
            <Link to={{ pathname: "/new-customer", state: { agencyDetails } }}>
              <Button
                className="btn-rounded mr-2 create-action-btn text-white"
                color={"success"}
              >
                Add new customer
              </Button>
            </Link>
          </Col>
        </Row>

        <Row className="mt-5">
          <FilterParameters
            name={name}
            ain={ain}
            customerCode={customerCode}
            organizationName={organizationName}
            handleChange={this.handleChange}
            search={this.searchCustomer}
            requesting={requesting}
            isVggRole={isVggRole}
          />
        </Row>

        {customers.length === 0 && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}

        {customers.length > 0 && (
          <>
            <Label className="ml-2">{totalCount} Customer Results</Label>
            <Row>
              <Col md={"12"}>
                <TableStructure
                  tableData={customers}
                  handleSwitchChecked={this.handleSwitchChecked}
                  handleChangeRoute={this.handleChangeRoute}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  fetchMoreRecords={this.searchCustomer}
                  mergeBtnActive={mergeBtnActive}
                  unMergeBtnActive={unMergeBtnActive}
                  mergeBtnClick={this.handleBtnClick}
                  unMergeBtnClick={this.handleBtnClick}
                  onCancelClick={this.displayCancelDialog}
                  onContinueClick={this.continueProcess}
                  selectedCustomer={this.storeSelectedCustomerToMerge}
                  storeSelectedCustomerToUnMerge={
                    this.storeSelectedCustomerToUnMerge
                  }
                />
              </Col>
            </Row>
          </>
        )}

        {dialogAlert.show && (
          <DialogFullScreenModal
            displayDialogAlert={dialogAlert}
            dispatch={this.props.dispatch}
            element={<p>{dialogAlert.message}</p>}
            buttonDeclineText={dialogAlert.declineText}
            buttonApproveText={dialogAlert.approveText}
            requesting={requesting}
            approveClicked={this.cancelMergeProcess}
            declineClicked={this.removeDialogAlert}
          />
        )}
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
        {this.props.alertComponentsReducer.secondaryAlert.show && (
          <SecondaryAlert modalWidth={"50%"} dispatch={this.props.dispatch}>
            <p>{secondaryAlertMessage}</p>
            <DuplicateMergeCustomersTable
              tableData={
                hasSelfService ? selfServiceCustomers : customersWithSubs
              }
            />
            <section className={"proceed-section"}>
              <div className={"proceed-section__prompt"}></div>
              <div className={"proceed-section__buttons"}>
                <Button
                  color="primary"
                  className="btn-sm px-4 btn-rounded mr-2 text-white"
                  onClick={this.hideSecondaryAlert}
                >
                  Close
                </Button>
              </div>
            </section>
          </SecondaryAlert>
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user,
    requesting,
    alertComponentsReducer,
    displayGeneralAlert,
    savedRoutes,
  } = state;
  return {
    user,
    requesting,
    alertComponentsReducer,
    displayGeneralAlert,
    savedRoutes,
  };
};

export default connect(mapStateToProps)(ManageCustomers);
