import React from "react";
import { appConstants } from "../../_constants/app.constants";
import { appHelpers } from "../../_helpers/app.helpers";
// import moment from "moment";
import { Button, ButtonGroup } from "reactstrap";
import { connect } from "react-redux";

import "./period.css";

class PeriodPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callback: this.props.callback,
      selectedPeriod: appConstants.THISMONTH,
      month: null,
      week: null,
      today: null
    };
  }

  // callBack = (tab, e) => {
  //   switch (tab) {
  //     case appConstants.THISWEEK:
  //       this.setState(
  //         {
  //           selected: tab,
  //           week: appHelpers.getCurrentWeek()
  //         },
  //         () => {
  //           this.state.callback(this.state.week);
  //         }
  //       );
  //       break;
  //     case appConstants.THIMONTH:
  //       this.setState(
  //         {
  //           selected: tab,
  //           month: appHelpers.getCurrentMonth()
  //         },
  //         () => {
  //           this.state.callback(this.state.month);
  //         }
  //       );
  //       break;
  //     case appConstants.TODAY:
  //       this.setState(
  //         { selected: tab, today: appHelpers.getCurrentDay() },
  //         () => {
  //           this.state.callback(this.state.today);
  //         }
  //       );
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // renderMonthTab = placeholder => {
  //   let tab = "MONTH";
  //   let { selected } = this.state;
  //   let bgColor =
  //     selected === tab
  //       ? appConstants.PERIODPICKER_SELECTED_COLOR
  //       : appConstants.PERIODPICKER_BG_COLOR;
  //   return (
  //     <li className="btnOne-group__item">
  //       <div
  //         className="btnOne btnOne--action smallSize"
  //         style={{ backgroundColor: bgColor }}
  //         onClick={this.callBack.bind(this, tab)}
  //       >
  //         {placeholder}
  //       </div>
  //     </li>
  //   );
  // };
  // renderTodayTab = placeholder => {
  //   let tab = "TODAY";
  //   let { selected } = this.state;
  //   let bgColor =
  //     selected === tab
  //       ? appConstants.PERIODPICKER_SELECTED_COLOR
  //       : appConstants.PERIODPICKER_BG_COLOR;
  //   return (
  //     <li className="btnOne-group__item">
  //       <div
  //         className="btnOne btnOne--action smallSize"
  //         style={{ backgroundColor: bgColor }}
  //         onClick={this.callBack.bind(this, tab)}
  //       >
  //         {placeholder}
  //       </div>
  //     </li>
  //   );
  // };
  // renderWeekTab = placeholder => {
  //   let tab = "WEEK";
  //   let { selected } = this.state;
  //   let bgColor =
  //     selected === tab
  //       ? appConstants.PERIODPICKER_SELECTED_COLOR
  //       : appConstants.PERIODPICKER_BG_COLOR;
  //   return (
  //     <li className="btnOne-group__item">
  //       <div
  //         className="btnOne btnOne--action smallSize"
  //         style={{ backgroundColor: bgColor }}
  //         onClick={this.callBack.bind(this, tab)}
  //       >
  //         {placeholder}
  //       </div>
  //     </li>
  //   );
  // };

  onPeriodButtonClick = periodId => {
    this.setState({ selectedPeriod: periodId });
    switch (periodId) {
      case appConstants.THISMONTH:
        this.props.selectedPeriod(appHelpers.getCurrentMonth());
        this.props.dispatch(this.success(appHelpers.getCurrentMonth(),appConstants.THISMONTH));
        break;
      case appConstants.THISWEEK:
        this.props.selectedPeriod(appHelpers.getCurrentWeek());
        this.props.dispatch(this.success(appHelpers.getCurrentWeek(),appConstants.THISWEEK));
        break;
      case appConstants.TODAY:
        this.props.selectedPeriod(appHelpers.getCurrentDay());
        this.props.dispatch(this.success(appHelpers.getCurrentDay(),appConstants.TODAY));
        break;

      default:
        break;
    }
  };

  success =(value,type) => {
    let val = type===appConstants.TODAY ? `${type}`:`THIS ${type}`
    return { type: appConstants.PERIOD_PICKER_SELECTED, value, val };
  }

  render() {
    const { selectedPeriod } = this.state;

    return (
      <ButtonGroup>
        <Button
          className="period-button"
          style={{
            borderTopLeftRadius: "1.5em",
            borderBottomLeftRadius: "1.5em",
            color: "#ffffff",
            backgroundColor: `${
              selectedPeriod === appConstants.THISMONTH
                ? `rgba(255, 255, 255, 0.3)`
                : `transparent`
            }`
          }}
          outline
          //   color="primary"
          onClick={() => this.onPeriodButtonClick(appConstants.THISMONTH)}
          //   active={this.state.rSelected === 1}
        >
          This Month
        </Button>
        <Button
          className="period-button"
          style={{
            color: "#ffffff",
            backgroundColor: `${
              selectedPeriod === appConstants.THISWEEK
                ? `rgba(255, 255, 255, 0.3)`
                : `transparent`
            }`
          }}
          outline
          //   color="primary"
          onClick={() => this.onPeriodButtonClick(appConstants.THISWEEK)}
          //   active={this.state.rSelected === 2}
        >
          This Week
        </Button>
        <Button
          className="period-button"
          style={{
            borderTopRightRadius: "1.5em",
            borderBottomRightRadius: "1.5em",
            color: "#ffffff",
            backgroundColor: `${
              selectedPeriod === appConstants.TODAY
                ? `rgba(255, 255, 255, 0.3)`
                : `transparent`
            }`
          }}
          outline
          //   color="primary"
          onClick={() => this.onPeriodButtonClick(appConstants.TODAY)}
          //   active={this.state.rSelected === 3}
        >
          This Day
        </Button>
      </ButtonGroup>

      // <div>
      //     <div className="section">
      //         <ul className="btnOne-group">
      //             {this.renderMonthTab("This Month")}
      //             {this.renderWeekTab("This Week")}
      //             {this.renderTodayTab("Today")}
      //         </ul>
      //     </div>

      // </div>
    );
  }
}
function mapStateToProps(state) {
  
}

export default connect(mapStateToProps)(PeriodPicker);
