import React from 'react';
import SelectList from '../SelectList/selectList';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Label, Input, CustomInput } from 'reactstrap';

const AddEditCard = ({ modalTitle, states, state, selectChange, labelOne, rate,
                         labelTwo, labelThree, locationModal, codeModal,
                         labelFour, tax, showRate, addClick, cancelClick, fieldTwoTextField,
                         addText, cancelText, showSelectRegion, selectState,
                         showLabelTwo, fieldThreePlaceholder, fieldThreeData,
                         fieldThreeValueKey, fieldTextField, fieldSelectValue,
                         handleChange, fieldTwoValueKey, disableAgency, requesting,
                       defaultValue, fieldTwoDefaultValue
                     }) => {
    return (
        <Card style={{ borderRadius: "10px" }}>
            <CardBody style={{ padding: "30px" }}>
                <CardTitle style={{ fontSize: "18px", padding: "5px", fontWeight: "bold", borderBottom: "0.5px solid rgba(0, 0, 0, 0.3)", marginBottom: "20px" }}>{modalTitle}</CardTitle>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label>{labelOne}</Label>
                            <Input type="text" 
                            value={locationModal}
                            name= "locationModal"
                            onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                    {showLabelTwo && (
                    <Col md="4">
                        <FormGroup>
                            <Label>{labelTwo}</Label>
                            <Input type="text" 
                            value={rate}
                            name= "rate"
                            onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>)}
                    {showSelectRegion && (
                        <Col xs="5" style={{fontSize:"12px", lineHeight: "10px"}}>
                        <FormGroup>
                        <Label>{selectState}</Label>
                        <SelectList
                            placeholder={"Select states"}
                            data={states}
                            valueKey={fieldTwoValueKey}
                            textField={fieldTwoTextField}
                            selectValue={state[fieldTwoValueKey]}
                            defaultValue={fieldTwoDefaultValue}
                            onValueChange={(event) => {selectChange(event, 'state')}}
                        />
                    </FormGroup>
                    </Col>     )}
                </Row>

               <Row>
                    <Col md="6" style={{fontSize:"12px", lineHeight: "10px"}}>
                        <FormGroup>
                            <Label >{labelThree}</Label>
                            <SelectList
                            placeholder={fieldThreePlaceholder}
                            data={fieldThreeData}
                            valueKey={fieldThreeValueKey}
                            textField={fieldTextField}
                            selectValue={fieldSelectValue[fieldThreeValueKey]}
                            disabled={disableAgency}
                            defaultValue={defaultValue}
                            onValueChange={(event) => {selectChange(event, 'agency')}}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="5">
                        <FormGroup>
                            <Label>{labelFour}</Label>
                            <Input type="text" 
                            value={codeModal}
                            name= "codeModal"                            
                            onChange={handleChange}/>
                        </FormGroup>
                    </Col>
                </Row>

                {showRate &&
                    <FormGroup row>
                        <Col sm={{ size: 10 }}>
                            <FormGroup>
                                <Label>
                                    <CustomInput
                                        className="m-1"
                                        type="checkbox"
                                        id="checkbox"
                                        color=""
                                    />
                                </Label>
                                <div
                                    style={{ float: "right", marginTop: "20px", marginRight: "68%" }}
                                >{tax}</div>
                            </FormGroup>
                        </Col>
                    </FormGroup>}
                <div style={{ float: "left" }}>
                    <Button
                        // className={"big-button align-button"}
                        color="success"
                        size="sm"
                        className="btn-rounded  mr-2 text-white"
                        disabled={requesting}
                        onClick={addClick}>{addText}</Button>
                    <Button
                        outline
                        className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
                        // outline color="secondary"
                        disabled={requesting}
                        onClick={cancelClick}>{cancelText}</Button>
                </div>
            </CardBody>
        </Card >
    )
};

export default AddEditCard