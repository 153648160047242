import React, { Component } from "react";
import { FormGroup, Label, Input, Row, Col, Button, Table } from "reactstrap";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import { appHelpers } from "../../../_helpers";
import axios from "axios";
import Alert from "../../../components/Alert";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { supportService } from "../../../_services";
import { alertActions, modalActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import "./index.scss";
import moment from "moment";
import { FaArrowLeft, FaTimes, FaCheckCircle } from "react-icons/fa";
import FullScreenModal from "../../../components/FullScreenModal";

let source;

class PaymentReferenceUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetails: [],
      invoiceNumber: "",
      rrrNumber: "",
      viewDialogOpen: false,
      showAlertModal: false,
      alertIcon: "",
      alertMessage: "",
      componentErrorMessages: [],
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";
  }

  handleChange = (event, attribute) => {
    this.setState({ [attribute]: event.target.value });
  };

  validateInputReference = () => {
    let _errs_ = [];
    let formValid = true;
    if (this.state.rrrNumber === "") {
      _errs_["rrrNumber"] = "Please input the new payment reference";
      formValid = false;
    }
    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  validateInput = () => {
    let _errs_ = [];
    let formValid = true;
    if (this.state.invoiceNumber === "") {
      _errs_["invoiceNumber"] = "Please input an invoice number";
      formValid = false;
    }
    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };
  getInvoiceDetails = () => {
    let { dispatch } = this.props;
    let { invoiceNumber } = this.state;
    if (this.validateInput()) {
      let payload = {
        InvoiceNumber: invoiceNumber,
      };

      dispatch(alertActions.startRequest());
      supportService.fetchInvoiceDetails(payload).then((res) => {
        let response = res.response;
        dispatch(alertActions.stopRequest());
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({
            invoiceDetails: response.data,
          });
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          this.setState({
            invoiceDetails: [],
          });
        }
      });
    }
  };

  initiateNewRRR = () => {
    // console.log(item, "help");
    let { dispatch } = this.props;
    let { invoiceNumber, rrrNumber, invoiceDetails } = this.state;
    if (this.validateInputReference()) {
      let payload = {
        InvoiceNumber: invoiceNumber,
        remitaRetrievalReference: rrrNumber,
        amount: invoiceDetails.amount,
        id: invoiceDetails.id,
      };

      dispatch(alertActions.startRequest());
      supportService.initiateRRRUpdate(payload).then((res) => {
        let response = res.response;
        dispatch(alertActions.stopRequest());
        console.log(res.response, "whatNow");
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState(
            {
              showAlertModal: true,
              alertMessage: "Update sent for approval successfully",
              alertIcon: (
                <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
              ),
            },
            () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
              this.handleDialogClose();
            }
          );
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          this.setState(
            {
              showAlertModal: true,
              alertMessage: res.response,
              alertIcon: (
                <FaTimes size={100} color={appConstants.DANGER_COLOR} />
              ),
            },
            () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
              // this.handleDialogClose();
            }
          );
        }
      });
    }
  };

  handleDialogClose = () => {
    this.setState({
      viewDialogOpen: false,
      invoiceNumber: "",
      invoiceDetails: [],
    });
    // window.location.reload()
    this.resetForm();
  };

  handleDialogClickOpen = () => {
    this.setState({
      viewDialogOpen: true,
    });
  };

  cancel = () => {
    // this.resetForm();
    window.location.reload();
  };


  resetError() {
    this.props.dispatch(modalActions.clear());
    this.props.dispatch(alertActions.clear());
  }
  resetForm() {
    this.setState({
      rrrNumber: "",
      invoiceNumber: "",
    });
    this.resetError();
  }
  renderAlertModal = (message, icon) => {
    let alertView = [];
    let count = 0;
    const { showAlertModal } = this.state;

    alertView.push(
      <FullScreenModal
        show={showAlertModal}
        key={count + 1}
        isInvoiceSheet={true}
        callback={this.hideAlertView}
        width={"35%"}
        top={"10%"}
      >
        <Alert icon={icon} message={message} />
      </FullScreenModal>
    );
    return alertView;
  };
  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }
  render() {
    const {
      invoiceDetails,
      invoiceNumber,
      rrrNumber,
      showAlertModal,
      alertIcon,
      alertMessage,
      viewDialogOpen,
      componentErrorMessages,
    } = this.state;
    console.log(invoiceDetails, "details");
    const { requesting } = this.props;
    let isInvoice = Object.keys(invoiceDetails).length > 0 ? true : false;

    return (
      <div>
        <ContentLayout
          requesting={this.props.requesting}
          pageTitle={"Payment Reference Update"}
          actions={
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          }
        >
          <Row>
            <Col md="6" xs="12">
              <p>
                <b>Input your invoice number to view your invoice details</b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="3" xs="12">
              <FormGroup>
                <Label>Invoice Number</Label>
                <Input
                  type="text"
                  name="invoiceNumber"
                  value={invoiceNumber}
                  onChange={(event) =>
                    this.handleChange(event, "invoiceNumber")
                  }
                  _errs_={componentErrorMessages.invoiceNumber}
                />
              </FormGroup>
              {componentErrorMessages.invoiceNumber && (
                <span className={"inputError"}>
                  {componentErrorMessages.invoiceNumber}
                </span>
              )}
            </Col>

            <Col md="3" xs="12" className="text-right">
              <FormGroup>
                <Label className="invisible">Invisible Button Label</Label>
                {/* <Button
                  className="btn-rounded create-action-btn text-white w-75"
                  onClick={() => this.getInvoiceDetails()}
                  color={"success"}
                  disabled={requesting}
                >
                  Search
                </Button> */}

                <div style={{ display: "flex" }}>
                  <Button
                    style={{ color: "white" }}
                    className="btn-rounded ml-3 px-3"
                    color="success"
                    disabled={requesting}
                    onClick={() => this.getInvoiceDetails()}
                  >
                    Search
                  </Button>
                  <Button
                    className="btn-rounded ml-3 px-3"
                    outline
                    color="secondary"
                    disabled={requesting}
                    onClick={this.cancel}
                  >
                    Cancel
                  </Button>
                </div>
              </FormGroup>
            </Col>
          </Row>

          {invoiceDetails && Object.keys(invoiceDetails).length === 0 && (
            <EmptyRecordMessage message={"No record match your search"} />
          )}

          {invoiceDetails && Object.keys(invoiceDetails).length > 0 && (
            <React.Fragment>
              <h5>
                <b>Invoice Details</b>
              </h5>
              <Table responsive={true} style={{ width: "70%" }}>
                <tr>
                  <th className="paymentTable">Invoice Number:</th>
                  <td className="paymentTable">
                    {isInvoice && invoiceDetails.invoiceNumber}
                  </td>
                </tr>
                <tr>
                  <th className="paymentTable">Amount:</th>
                  <td className="paymentTable">
                    {isInvoice && invoiceDetails.amountString}
                  </td>
                </tr>
                <tr>
                  <th className="paymentTable">Revenue:</th>
                  <td className="paymentTable">
                    {isInvoice && invoiceDetails.revenueName}
                  </td>
                </tr>
                <tr>
                  <th className="paymentTable">Date Created:</th>
                  <td className="paymentTable">
                    {isInvoice &&
                      moment(invoiceDetails.createdDate).format("YYYY-MM-DD")}
                  </td>
                </tr>
              </Table>
            </React.Fragment>
          )}

          <br />
          <br />

          {invoiceDetails && Object.keys(invoiceDetails).length > 0 && (
            <React.Fragment>
              <Row>
                <Col md="6" xs="12">
                  <p>
                    <b>Update your Invoice with your new payment reference</b>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="3" xs="12">
                  <FormGroup>
                    <Label>Payment Reference (RRR)</Label>
                    <Input
                      type="text"
                      name="rrrNumber"
                      value={rrrNumber}
                      onChange={(event) =>
                        this.handleChange(event, "rrrNumber")
                      }
                    />
                  </FormGroup>
                  {componentErrorMessages.rrrNumber && (
                    <span className={"inputError"}>
                      {componentErrorMessages.rrrNumber}
                    </span>
                  )}
                </Col>
                <Col md="3" xs="12" className="text-right">
                  <FormGroup>
                    <Label className="invisible">Invisible Button Label</Label>
                    <Button
                      className="btn-rounded text-white w-75"
                      onClick={() => this.initiateNewRRR()}
                      color={"success"}
                      disabled={requesting}
                    >
                      Update
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </React.Fragment>
          )}

          {showAlertModal === true &&
            this.renderAlertModal(alertMessage, alertIcon)}
          <br />
          <br />
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, alert } = state;
  return {
    user,
    requesting,
    alert,
  };
};
export default connect(mapStateToProps)(PaymentReferenceUpdate);
