import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const selfService = {
  getSelfServiceRevenues,
  getSelfServiceLocations,
  getSelfServiceCustomerDetails,
  manageUnpaidInvoiceTracker,
  searchUnpaidInvoiceTracker,
  activateSelfServiceCustomerStaff,
  deactivateSelfServiceCustomerStaff,
  migrateCustomerStaffOnSSO
};

function getSelfServiceRevenues(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SelfService/GetSelfServiceRevenuesBDA`, cancelToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getSelfServiceLocations(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SelfService/GetSelfServiceLocationsBDA`, cancelToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getSelfServiceCustomerDetails(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SelfService/GetSelfServiceCustomerDetailsBDA`, cancelToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function manageUnpaidInvoiceTracker(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/UnpaidInvoiceTrackerSetup/ManageUnpaidInvoiceTrackerBDA`, payload, cancelToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchUnpaidInvoiceTracker(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/UnpaidInvoiceTrackerSetup/SearchUnpaidInvoiceTrackerBDA`, payload, cancelToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function activateSelfServiceCustomerStaff(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SelfService/ActivateSelfServiceCustomerStaffBDA?CustomerStaffId=${payload.customerStaffId}`, cancelToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function deactivateSelfServiceCustomerStaff(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SelfService/DeactivateSelfServiceCustomerStaffBDA?CustomerStaffId=${payload.customerStaffId}`, cancelToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function migrateCustomerStaffOnSSO(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SelfService/MigrateCustomerStaffOnSSOBDA?CustomerStaffId=${payload.customerStaffId}`, cancelToken)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}
