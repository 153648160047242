import React from "react";
import { Table, Input } from "reactstrap";
import { appHelpers } from "../../../_helpers/app.helpers";

export const MergeCustomersTable = ({
  tableData,
  hasSubCustomers,
  storeSelectedSubCustomer,
}) => {
  return (
    <Table responsive striped hover>
      <thead className={"table-head-blue"}>
        <tr>
          {!hasSubCustomers && <th></th>}
          <th>Name</th>
          <th>ID</th>
          <th>Merge Date</th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((item, index) => {
            return (
              <tr key={index} className={"table-row"}>
                {!hasSubCustomers && (
                  <td>
                    <Input
                      style={{
                        position: "static",
                        width: "40px",
                        marginLeft: "3px",
                      }}
                      type="checkbox"
                      id="checkbox"
                      onChange={(e) => storeSelectedSubCustomer(e, item)}
                      checked={item.selected}
                    />
                  </td>
                )}
                <td>{item.customerName}</td>
                <td>{item.customerId}</td>
                <td>{appHelpers.formatDate(item.mergeDate)}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};
