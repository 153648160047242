import React, {Component} from 'react';
import ContentLayout from "../../../components/ContentLayout";
import {Button, Col, Row} from "reactstrap";
import {appHelpers} from "../../../_helpers";
import {FaArrowLeft} from "react-icons/fa";
import EmptyState from "../../../components/EmptyState";
import AddRecordButton from "../../../components/AddRecordButton";
import SearchBar from "../../../components/SearchBar";
import SelfServiceRecords from "./record";
import {connect} from "react-redux";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import {appConstants} from "../../../_constants";
import {selfService} from "../../../_services/self-service";
import {alertActions} from "../../../_actions";
import axios from "axios";

let source

class ViewSelfServiceSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVggRole: false,
      emptysetupLocations: [],
      setupRecords: [],
      searchQuery: "",
      agencyDetails: "",
      totalCount: 0,
      pageSize: 10,
      currentPage: 1,
      organizationId: null,
      // organizationDetails: undefined,
    };
  
    source = axios.CancelToken.source();
  }
  
  
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first
    
    let pathState = props.location.state;
    if (pathState) {
      if (pathState.selectedAgencyDetails !== state.selectedAgencyDetails) {
        return {
          organizationDetails: pathState.selectedAgencyDetails,
        };
      }
    }
    
    return null;
  }
  
  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { organizationDetails } = this.state;
    
    //get route the user is coming from
    let previousPath;
    if (this.props.history.location.state) {
      previousPath = this.props.history.location.state.from
    }
    
    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN || user.vggRole === appConstants.ROLES.VGG_USER;
    
    //Everytime a user with a VGG Role vists this page, the user must select an organization
    //organizationDetails===undefined prevents redirect from happening again once an organization is selected
    
    if (vggAdminRole && organizationDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationId: vggAdminRole
            ? organizationDetails && organizationDetails.id
            : user.organizationId,
        },
        () => this.fetchInitialReferenceData()
      );
    }
  };
  
  fetchInitialReferenceData = () => {
    this.getSetup();
  };
  
  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";
    
    this.routeUserBasedOnRole();
  }
  
  searchBarChange = (e) => {
    this.setState({ searchQuery: e.target.value }, () => {
      if (this.state.searchQuery === "") {
        this.getSetup();
      }
    });
  };
  
  search = (e) => {
    e.preventDefault();
    this.getSetup();
  };
  
  
  getSetup = (pageNumber = 1) => {
    const { organizationId, pageSize, searchQuery} = this.state;
    const {dispatch} = this.props;
     let payload = {
       pageSize: pageSize,
       from: pageNumber === 1 || pageNumber === 0 ? 1 : pageNumber,
       parameter: {
         organizationId: organizationId
       }
     };
  
     
     if (searchQuery !== ""){
       payload.parameter.location = searchQuery
     }
     
    dispatch(alertActions.startRequest());
    selfService.searchUnpaidInvoiceTracker(payload, source.token).then(res => {
       let response = res.response;
       dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          setupRecords: response.results,
          totalCount: response.totalCount,
          currentPage: pageNumber === 0 ? 1 : pageNumber,
  
        })
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(response.message));
      }
     })
  };
  
  navigateToCreateSetup = () => {
    const { organizationId } = this.state;
    
    const pathState = {
      pageTitle: appConstants.CREATE_SETUP,
      organizationId: organizationId,
      previousPath: window.location.pathname
    };
    appHelpers.navigateRoute.call(this, "/settings-create-self-service-setup", pathState);
  };
  
  
  navigateToReviewSetup = (e, item) => {
    const { organizationId } = this.state;
    let record = {
      ...item
    };
  
    const pathState = {
      organizationId: organizationId,
      record,
      pageTitle: appConstants.REVIEW_SETUP,
      previousPath: window.location.pathname
    };
  
    if (e.target.parentElement.className === "table-row") {
      appHelpers.navigateRoute.call(this, "/settings-review-self-service-setup", pathState);
    }
  };
  
  editRecord = (e, item) => {
    const {organizationId} = this.state;
   
    let record = {
      ...item
    };
    
    const pathState = {
      pageTitle: appConstants.EDIT_SETUP,
      organizationId: organizationId,
      record,
      previousPath: window.location.pathname
    };
    
    
  
    if (e.target.classList.contains("edit-setup")) {
      appHelpers.navigateRoute.call(this, "/settings-create-self-service-setup", pathState);
    }
  };
  
  componentWillUnmount() {
    // const {dispatch} = this.props;
  
    if (source) {
      source.cancel()
    }
  }
 
  render() {
    const { setupRecords, searchQuery, agencyDetails,
    totalCount, pageSize, currentPage} = this.state;
    const {requesting, user} = this.props;
  
    return (
      <ContentLayout
        pageTitle={"View Self Service Setup"}
        requesting={requesting}
        actions={
          // isVggRole && (
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          // )
        }
      >
        {setupRecords.length < 1 && searchQuery === "" && (
          <EmptyState
            message={"You do not have any setup records yet"}
            actionText={"Create new setup"}
            addRecord={() => this.navigateToCreateSetup()}
          />
        )}
        {(setupRecords.length >= 1 || searchQuery !== "") && (
          <>
          <div
            className={"d-flex justify-content-between align-items-center"}
          >
            <h4>
              {agencyDetails && agencyDetails.name
                ? agencyDetails.name
                : null}
            </h4>
            <AddRecordButton
              actionText={"Create new setup"}
              addRecord={this.navigateToCreateSetup}
            />
          </div>
            <Row className={"settings-spacing"}>
              <Col sm={{ size: 6 }} className="text-center">
                <SearchBar
                  placeholder="Search location"
                  onChange={this.searchBarChange}
                  value={searchQuery}
                  showSearchButton
                  search={this.search}
                />
              </Col>
            </Row>
            <div>
              <SelfServiceRecords
                setupRecords={setupRecords}
                user={user}
                totalCount={totalCount}
                pageSize={pageSize}
                currentPage={currentPage}
                editRecord={this.editRecord}
                showReviewSetup={this.navigateToReviewSetup}
                fetchMore={this.getSetup}
              />
            </div>
          </>
        )}
        {setupRecords.length === 0 && searchQuery !== "" && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, apiReducer } = state;
  return {
    user,
    requesting,
    apiReducer,
  };
};

export default connect(mapStateToProps)(ViewSelfServiceSetup);