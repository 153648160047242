import React, { Component } from "react";
import './faq.css'


class DetailedItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleAccordion = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const { contentTitle, subContent,} = this.props;
    const { isOpen } = this.state;

    return (
    //   <div className="nested-item">
    //   <div className="content-title">
    //     <h2>{contentTitle}</h2>
    //   </div>
    //   <div className="content-body" dangerouslySetInnerHTML={{ __html: subContent }} />

    // </div>
    contentTitle.map((item,index)=>
    <div className="nested-item">
    <div className="content-title">
      <h2>{index === this.props.selectedIndex ? contentTitle[index]: ""}</h2>
    </div>
    {index === this.props.selectedIndex ?<div className="content-body" dangerouslySetInnerHTML={{ __html: subContent[index] }} />
    : ''}
    {/* // <div className="content-body" dangerouslySetInnerHTML={{ __html: subContent[index] }} /> */}

  </div>)
    );
  }
}


export default DetailedItem;
