import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const settlementSplitService = {
  searchSettlementSplits,
  createSettlementSplitApproval,
  updateSettlementSplitApproval,
  getSettlementSplitById,
  getSettlementSplitTypes,
  getSettlementSplitRevenue,
};

function searchSettlementSplits(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SettlementSplit/SearchSettlementSplitsBDA`,
      payload, cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function createSettlementSplitApproval(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SettlementSplit/CreateSettlementSplitBDA`,
      payload, cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function updateSettlementSplitApproval(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SettlementSplit/UpdateSettlementSplitBDA`,
      payload, cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getSettlementSplitById(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SettlementSplit/GetSettlementSplitsBDA?settlementSplitId=${payload.settlementSplitId}`,
      payload, cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getSettlementSplitTypes(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SettlementSplit/GetAllSplitTypeBDA`, cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getSettlementSplitRevenue(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SettlementSplit/GetSettlementSplitRevenueBDA?agencyId=${payload.agencyId}`, cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}
