import React from 'react';
import {Button} from "reactstrap";
import {ReactComponent as EditLogo} from "../../assets/images/icons/edit-icon.svg";

  const ButtonEdit = ({onClick}) => {
  return (
    <Button
      className={"btn btn-outline-success px-3 br-4 "}
      onClick={onClick}
    >
      <EditLogo/> Edit
    </Button>
  );
};

export default ButtonEdit;


