import React from "react";
import { Table, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "./index.scss";

const Record = ({ tableData, handleChangeRoute }) => {
  return (
    <Table responsive striped hover>
      <thead className={"table-head-blue"}>
        <tr>
          <th>Revenue Lines</th>
          <th>Primary Settlement Account</th>
          <th>Date Created</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((item, index) => {
            return (
              <tr
                key={index}
                className={"table-row"}
                onClick={() =>
                  handleChangeRoute(
                    "/view-settlement-split-per-revenue",
                    "revenueSplitDetails",
                    item
                  )
                }
              >
                <td>{item.revenueName}</td>
                <td>{item.settlementAccountName}</td>
                <td>
                  {item.dateCreated &&
                    moment(item.dateCreated).format("Do MMMM, YYYY")}
                </td>
                <td
                  onClick={() =>
                    handleChangeRoute(
                      "/add-settlement-split",
                      "settlementSplitDetails",
                      item
                    )
                  }
                >
                  <div id={`Tooltip${index}`}>Edit</div>
                  <UncontrolledTooltip
                    placement="bottom-end"
                    target={`Tooltip${index}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

const TableStructure = ({
  tableData,
  handleSwitchChecked,
  handleChangeRoute,
  currentPage,
  fetchMoreRecords,
  pageSize,
  totalCount,
}) => {
  const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
  return (
    <div>
      <ServerPaginatedTable
        currentPage={currentPage}
        fetchMore={fetchMoreRecords}
        pageSize={pageSize}
        totalCount={totalCount}
        target={
          <Record
            tableData={tableData}
            handleSwitchChecked={handleSwitchChecked}
            handleChangeRoute={handleChangeRoute}
            pageIndex={pageIndex}
          />
        }
      />
    </div>
  );
};

export default TableStructure;
