import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { appHelpers } from "../../_helpers";
// import "./_index.scss";

const VoidRequestForm = ({
  name,
  role,
  invoiceNumber,
  customer,
  customerRole,
  revenue,
  invoiceDate,
  amount,
  placeholder,
  buttonText,
  decimalPrecision,
  value,
  onChange,
  sendClicked,
  initiateRequest,
  notificationColor,
  visible,
  onDismiss,
  requestType,
}) => {
  return (
    <Card style={{ borderRadius: "10px" }}>
      <CardBody style={{ padding: "30px 50px" }}>
        <Row>
          <Col md={12}>
            <p style={{ float: "right", marginBottom: "2px" }}>
              <span style={{ fontWeight: "bold" }}>{role}</span>
              <br />
              {name}
            </p>
            <h4
              style={{
                float: "left",
                marginBottom: "1px",
                marginTop: "20px",
                fontWeight: 600,
              }}
            >
              {requestType}
            </h4>
          </Col>
        </Row>
        <hr style={{ marginTop: 0 }} />
        <Row style={{ paddingTop: "25px" }}>
          <Col md={3}>
            <h6 className="font-weight-bold">Invoice Number</h6>
            <p>{invoiceNumber}</p>
          </Col>
          <Col md={2}>
            <h6 className="font-weight-bold">Customer</h6>
            <p>
              <span style={{ textTransform: "uppercase" }}>{customer}</span>{" "}
            </p>
          </Col>
          <Col md={3}>
            <h6 className="font-weight-bold">Revenue Line</h6>
            <p>{revenue}</p>
          </Col>
          <Col md={2}>
            <h6 className="font-weight-bold">Invoice Date</h6>
            <p>{invoiceDate}</p>
          </Col>
          <Col md={2}>
            <h3>
              {appHelpers.numberWithCommasOnly(
                appHelpers.convertStringToNumber(amount),
                decimalPrecision
              )}
            </h3>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={12}>
            <h6 className="font-weight-bold mt-2">Comments</h6>
          </Col>
          <Col md={12}>
            <textarea
              name="voidComment"
              id=""
              cols=""
              rows="10"
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              style={{
                borderRadius: "10px",
                borderColor: "#ebebeb",
                padding: "10px",
                fontSize: "14px",
                width: "100%",
              }}
            ></textarea>
          </Col>
          <Col md={12}>
            <Button
              onClick={initiateRequest}
              className={"small-button"}
              style={{ float: "right" }}
            >
              {buttonText}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default VoidRequestForm;
