import React, { useState } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { FaCopy } from "react-icons/fa";

const ActionAlert = ({ icon, message, detailMessage, generatedNumber, onClose, onDoneClick }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = (textToCopy) => {
    const el = document.createElement("textarea");
    el.value = textToCopy;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 2500);
  };

  // const handleDoneClick = () => {
  //   onClose();
  // };

  const handleDoneClick = () => {
    onClose();
    // Call the additional function if it's provided as a prop
    if (onDoneClick) {
      onDoneClick();
    }
  };

  const handleCopyClick = () => {
    copyToClipboard(generatedNumber);
  };

  return (
    <Card style={{ borderRadius: "10px", height: "70%", overflowY: "auto" }}>
      <CardBody className={"d-flex justify-content-center"} style={{ padding: "4rem 2rem" }}>
        <div>
          <p className={"text-center"}>{icon}</p>
          <h2 style={{ textAlign: "center" }}>{message}</h2>
          <h3 style={{ fontSize: "14px", textAlign: "center" }}>
            Your RRR for invoice number {detailMessage} has been <br /> successfully generated and updated on your invoice
          </h3>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#FAFAFA",
              padding: "8px",
              borderRadius: "5px",
            }}
          >
            <span style={{ marginRight: "70px" }}>Generated RRR</span>
            <span
              style={{ marginRight: "8px" }}
              // onClick={handleCopyClick}
            >
              {generatedNumber}
              {copySuccess && <span style={{fontSize: "10px", fontWeight: "bold"}} className="tooltips">Copied!</span>}
            </span>
            <FaCopy onClick={handleCopyClick} style={{ cursor: "pointer" }} color="green" />
          </div>

          <div style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
            <Button
              className="btn-rounded create-action-btn text-white w-75"
              color={"success"}
              onClick={handleDoneClick}
            >
              Done
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ActionAlert;
