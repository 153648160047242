import React from 'react';
import { Table} from "reactstrap";

const ErrorTable = ({accountRecords}) => {
  return (
    <div>
      <Table responsive={true} className={"table-main"}>
        <thead className={"th-head tracker-head"}>
        <tr>
          <th>Settlement Account</th>
          <th>Revenues</th>
          <th>Locations</th>
        </tr>
        </thead>
        <tbody>
        {accountRecords.map((item, index) => {
          return <tr>
            <th>{item.settlementAccount}</th>
            <th>{item.revenue}</th>
            <th>{item.location}</th>
          </tr>
        })}
        </tbody>
      </Table>
    </div>
  );
};

export default ErrorTable;

