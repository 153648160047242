import React from 'react';
import { Row, Col, Label, FormGroup, Input } from 'reactstrap';
import SelectList from "../../../components/SelectList/selectList";
import ActiveCheckbox from "../../../components/ActiveCheckbox";

const AddBankInformation = ({
                              handleChange,
                              banks,
                              bankValue,
                              accountType,
                              currencyValue,
                              accountNumber,
                              alias,
                              handleSelectChange,
                              accountTypes,
                              currencies,
                              handleAccountChange,
                              displayHelperText,
                              checkboxChange,
                              checked,
                               inputError,
                            }) => {
  return (
    <div>
      <Row>
        <Col lg={4}>
        <FormGroup>
            <Label>Bank Name&#x2a;</Label>
            <SelectList
              placeholder={'Select bank name...'}
              data={banks}
              valueKey={'id'}
              textField={'name'}
              selectValue={bankValue}
              onValueChange={(item) => {
               handleSelectChange(item, "bankValue", );
              }}
            />
          {inputError.bankValue &&
            <span className={'small red--100'}>{inputError.bankValue}</span>
          }
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label>Account Number&#x2a;</Label>
            <Input
              placeholder={"Enter account number"}
              // className={`${displayHelperText.color === "green" ? "border-green" : null} ${displayHelperText.color === "red" ? "border-red": null}`}
              style={{border: displayHelperText.color === "green" ? "1px solid #40D999" : displayHelperText.color === "red" ? "1px solid #F54A34" : "null"}}
              type="text"
              name="accountNumber"
              id="account-number"
              value={accountNumber}
              onChange={handleAccountChange}
              required
            />
            {
              displayHelperText.show &&
              <span className={`small ${displayHelperText.color === "green" ? "green--100" : "red--100" }`}>{displayHelperText.text}</span>
  
            }
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label>Alias (optional)</Label>
            <Input
              placeholder="Give this account a name"
              name="alias"
              id="alias"
              value={alias}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <FormGroup>
            <Label>Currency&#x2a;</Label>
            <SelectList
              placeholder={'Select currency...'}
              data={currencies}
              valueKey={'id'}
              textField={'name'}
              selectValue={currencyValue}
              onValueChange={(item) => {
                handleSelectChange(item, "currencyValue");
              }}
            />
            {inputError.currencyValue &&
            <span className={'small red--100'}>{inputError.currencyValue}</span>
            }
          </FormGroup>
        </Col>
        <Col lg={4}>
          <FormGroup>
            <Label>Account Type&#x2a;</Label>
            <SelectList
              placeholder={'Select account type...'}
              data={accountTypes}
              valueKey={'id'}
              textField={'name'}
              selectValue={accountType}
              onValueChange={(item) => {
                handleSelectChange(item, "accountType");
              }}
            />
            {inputError.accountType &&
            <span className={'small red--100'}>{inputError.accountType}</span>
            }
          </FormGroup>
        </Col>
        <Col lg={4} className={"mt-5"}>
          <ActiveCheckbox
            text={"Set as active account"}
            checkboxChange={checkboxChange}
            checked={checked}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AddBankInformation;