import React, { Component } from "react";
import ContentLayout from "../../../components/ContentLayout";
import { appConstants } from "../../../_constants";
import { Button } from "reactstrap";
import { appHelpers } from "../../../_helpers";
import { FaArrowLeft } from "react-icons/fa";
import CheckboxGrid from "../../../components/CheckboxGrid";
import { connect } from "react-redux";
import {
  commonActions,
  modalActions,
  selfServiceActions,
} from "../../../_actions";
import axios from "axios";
import ButtonEdit from "../../../components/ButtonEdit";

let source;

class ReviewSelfServiceSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxUnpaidInvoice: 0,
      locations: [],
      pathState: null,
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    const { selfServiceReducer, selectedLocationIds } = this.props;
    const { selfServiceUserLocations } = selfServiceReducer;

    // The selectedLocationIds reducer is used to keep track of
    // all selected locations ids both when a user is coming from the
    // table to edit a record and when the user has successfully
    // created/edited a record. This will be used to preserve
    // the checked state of the checkboxes

    let pathState = this.props.location.state;
    if (pathState) {
      let { record } = pathState;
      this.setState({ pathState: pathState }, () => {
        if (!pathState.record) {
          if (!selfServiceUserLocations) {
            // this.getLocations()
          } else {
            this.setState({ locations: selfServiceUserLocations });
          }
        }

        if (pathState && record) {
          this.setState({
            maxUnpaidInvoice: pathState.record.count,
          });

          let id = [];
          id.push(pathState.record.locationId);
          if (!selectedLocationIds) {
            this.getSelectedLocationIds(id);
          }
          if (!selfServiceUserLocations) {
            this.getLocations();
          } else {
            let modifiedLocations = appHelpers.compareAndModifyArrayPropertyWithAnotherArray(
              selfServiceUserLocations,
              id,
              "locationId",
              "activated"
            );
            this.setState({ locations: modifiedLocations });
          }
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { selfServiceReducer, selectedLocationIds } = this.props;
    const { pathState } = this.state;

    if (!pathState.record) {
      if (
        selfServiceReducer.selfServiceUserLocations !==
        prevProps.selfServiceReducer.selfServiceUserLocations
      ) {
        this.setState({
          locations: selfServiceReducer.selfServiceUserLocations,
        });
      }
    }

    if (pathState.record) {
      if (
        selfServiceReducer.selfServiceUserLocations !==
        prevProps.selfServiceReducer.selfServiceUserLocations
      ) {
        let modifiedLocations = appHelpers.compareAndModifyArrayPropertyWithAnotherArray(
          selfServiceReducer.selfServiceUserLocations,
          selectedLocationIds,
          "locationId",
          "activated"
        );
        this.setState({
          locations: modifiedLocations,
        });
      }
    }
  }

  getLocations = () => {
    const { dispatch } = this.props;
    const { pathState } = this.state;

    let payload = {
      CustomerStaffId: 0,
      OrganizationId: pathState.organizationId,
    };

    dispatch(
      selfServiceActions.fetchSelfServiceUserLocation(payload, source.token)
    );
  };

  getSelectedLocationIds = (locationIds) => {
    const { dispatch } = this.props;
    dispatch(commonActions.saveSelectedLocationIds(locationIds));
  };

  editSetup = () => {
    const { pathState } = this.state;

    const editPathState = {
      record: pathState.record,
      organizationId: pathState.organizationId,
      pageTitle: appConstants.EDIT_SETUP,
      previousPath: window.location.pathname,
    };

    appHelpers.navigateRoute.call(
      this,
      "/settings-create-self-service-setup",
      editPathState
    );
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(modalActions.displayGeneralAlert({ show: false }));
    dispatch(commonActions.saveSelectedLocationIds(false));

    if (source) {
      source.cancel();
    }
  }

  render() {
    const { maxUnpaidInvoice, locations } = this.state;
    const { requesting } = this.props;

    return (
      <ContentLayout
        pageTitle={appConstants.REVIEW_SETUP}
        requesting={requesting}
        actions={
          // isVggRole && (
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className=" btn-rounded mr-2 big-action-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
          // )
        }
      >
        <div className={"d-flex justify-content-end"}>
          <ButtonEdit onClick={this.editSetup} />
        </div>
        <div className={"mt-4"}>
          <p>
            <strong>Maximum Unpaid Invoice:</strong> {maxUnpaidInvoice}
          </p>
        </div>
        <div>{locations.length > 0 && <CheckboxGrid data={locations} />}</div>
      </ContentLayout>
    );
  }
}
const mapStateToProps = (state) => {
  const { user, requesting, selfServiceReducer, selectedLocationIds } = state;
  return {
    user,
    requesting,
    selfServiceReducer,
    selectedLocationIds,
  };
};

export default connect(mapStateToProps)(ReviewSelfServiceSetup);
