import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const brandingService = {
  createBranding,
  getBranding,
  getBrandingByDomain
  // getBrandingPath
};

function createBranding(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Branding/CreateBranding`, payload, cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getBranding(payload) {
  return appHelpers
    .getRequest(
      // `${appConstants.PAS_URL}/api/Branding/GetBrandingPath?${payload.agencyId && `agencyId=${payload.agencyId}`}${payload.agencyCode && `&agencyCode=${payload.agencyCode}`}${payload.domainURL ? `&domainURL=${payload.domainURL}`:`&domainURL=`}`
      `${appConstants.PAS_URL}/api/Branding/GetBrandingPath?agencyId=${payload.agencyId}&agencyCode=${payload.agencyCode}&domainURL=${payload.domainURL}`
      // domainURL=${payload.domainURL}&agencyId=${payload.agencyId}&agencyCode=${payload.agencyCode}`
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getBrandingByDomain(payload) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Branding/GetBrandingPath?${payload.agencyId && `agencyId=${payload.agencyId}`}${payload.agencyCode && `&agencyCode=${payload.agencyCode}`}${payload.domainURL ? `&domainURL=${payload.domainURL}`:`&domainURL=`}`
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}