import React, { Component } from "react";
import TableStructure from "./record";
import { Button } from "reactstrap";
import SearchBar from '../../../components/SearchBar';
import ContentLayout from "../../../components/ContentLayout";
import {  FaArrowLeft, FaCheckCircle,  FaPlusCircle, FaTimes } from "react-icons/fa";
import {Row, Col} from "reactstrap";
import { appHelpers } from "../../../_helpers";
import { staffService} from "../../../_services/staff.service";
import { appConstants } from "../../../_constants/app.constants";
import { connect } from "react-redux";
import axios from "axios";
import {alertActions, modalActions} from "../../../_actions";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import "./index.scss";
import {RenderAlertModal} from "../../../components/AlertModal";
import {selfService} from "../../../_services/self-service";
import GeneralAlert from "../../../components/Api-response-alert";


let source;

class ManageStaffs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            currentPage: 1,
            pageSize: 10,
            totalCount: 0,
            staffSearchValue: "",
            showAlertModal: false,
            alertMessage: "",
            alertIcon: "",
            organizationId: null,
            organization: "",
        }

        source = axios.CancelToken.source();
    }
    
    static getDerivedStateFromProps(props, state) {
        //path state is from /select-agency route
        //path state will not exist if the user is NOT a VGG Admin because only
        //VGG admins are routed to the /select-agency page first
        
        let pathState = props.location.state;
        if (pathState) {
            if (pathState.selectedAgencyDetails !== state.selectedAgencyDetails) {
                return {
                    organizationDetails: pathState.selectedAgencyDetails,
                };
            }
        }
        
        return null;
    }

    componentDidMount() {
        /* Remove overflow hidden caused by modal */
        const body = document.body;
        body.style.overflow = "scroll";
    
        //Call branding if there is no method to display select agency view
        appHelpers.checkDerivedStateFromPropsMethod.call(this);
    
        this.routeUserBasedOnRole();
    
    }
    
    routeUserBasedOnRole = () => {
        const {user} = this.props;
        const {organizationDetails} = this.state;
    
        //get route the user is coming from
        let previousPath;
        if (this.props.history.location.state) {
            previousPath = this.props.history.location.state.from
        }
    
        //set Role related state
        let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;
    
        //Everytime a user with a VGG Role vists this page, the user must select an organization
        //organizationDetails===undefined prevents redirect from happening again once an organization is selected
    
        if (vggAdminRole && organizationDetails === undefined) {
            this.props.history.push({
                pathname: "/select-agency",
                state: {from: previousPath},
            });
        } else {
            //if organization is selected, set the following states
            this.setState(
              {
                  isVggRole: vggAdminRole ? true : false,
                  organizationId: vggAdminRole
                    ? organizationDetails && organizationDetails.id
                    : user.organizationId,
              },
              () => this.fetchInitialReferenceData()
            );
        }
    };
    
    fetchInitialReferenceData = () => {
        this.searchStaff();
    }
    
    searchStaff = (pageNumber = 1) => {
        const {dispatch, user} = this.props;
        const {staffSearchValue, pageSize, organization} = this.state;
        dispatch(alertActions.startRequest());

        const payload = {
            pageSize: pageSize,
            from: pageNumber,
            parameter: {
                searchQuery: staffSearchValue,
                organizationId:  user.vggRole !== appConstants.ROLES.VGG_ADMIN
                ? user.organizationId
                : (user.vggRole === appConstants.ROLES.VGG_ADMIN) ? organization.id
                  :
                organization,

            }
        };

        staffService.searchSelfServiceCustomerStaff(payload, source.token)
            .then(res => {
                dispatch(alertActions.stopRequest());
                let response = res.response;
                if (res.status === appConstants.SUCCESS_RESPONSE) {
                    this.setState({
                        currentPage: pageNumber === 0 ? 1 : pageNumber,
                        data: response.results,
                        totalCount: response.totalCount
                    })
                }
                else if (res.status === appConstants.ERROR_RESPONSE) {
                    if (response === "Please Check your internet connectivity") {
                        dispatch(alertActions.error(response));
                    }
                }
            })

    }

    createStaffs = () => {
        this.props.history.push({
            pathname: '/settings-create-staffs',
            state: {pageTitle: appConstants.CREATE_STAFF, showStaffProfile: false}
        })
    };

    editStaff = (e, item) => {
        e.stopPropagation();
        const {user} = this.props;
        if (this.staffConditions(user.vggRole, item )) {
            this.props.history.push({
                pathname: '/settings-create-staffs',
                state: {item, pageTitle: appConstants.EDIT_STAFF, showStaffProfile: false}
            })
        }
    };

    showStaffProfile = (e, item) => {
        const {user} = this.props;
        if (e.target.parentElement.className === "table-row" || e.target.classList.contains("table-row")) {
           if (this.staffConditions(user.vggRole, item )) {
               this.props.history.push({
                   pathname: '/settings-create-staffs',
                       state: {item, showStaffProfile: true, "pageTitle": appConstants.STAFF_PROFILE}
                      });
           }
    }    
    };

    staffConditions = ( item) => {
        const { dispatch, user} = this.props;

        let pushToView = false;
        if ((user.userRole === appConstants.ROLES.CUSTOMER_ADMIN || item.vggRole === appConstants.ROLES.CLIENT_ADMIN)
           ){
            pushToView = true;
        }  else {
            dispatch(alertActions.notify("You dont have permission to perform this action"));
        }

        return pushToView
    };

    activateStaff = (item) => {
        let payload = {
            CustomerStaffId: item.id,
        };
        const {dispatch} = this.props;

        dispatch(alertActions.startRequest());
        staffService.activateStaff(payload, source.token)
            .then(res => {
                dispatch(alertActions.stopRequest());
                let response = res.response;

                if (res.status === appConstants.SUCCESS_RESPONSE) {
                    this.displaySuccessResponseAlert(response, true, this.searchStaff())
                }
                else if (res.status === appConstants.ERROR_RESPONSE) {
                    this.displayErrorResponseAlert(response, true, this.searchStaff())
                }
            })

    };

    deactivateStaff = (item) => {
        let payload = {
            CustomerStaffId: item.id,
        };
        const {dispatch} = this.props;
        
        dispatch(alertActions.startRequest())
        staffService.deactivateStaff(payload, source.token) 
            .then(res => {
                dispatch(alertActions.stopRequest());
                let response = res.response;

                if (res.status === appConstants.SUCCESS_RESPONSE) {
                    this.displaySuccessResponseAlert(response, true, this.searchStaff())
                }
                else if (res.status === appConstants.ERROR_RESPONSE) {
                    this.displayErrorResponseAlert(response, true, this.searchStaff())
                }
            })

    }

    // handleSwitchChecked = (e, item) => {

    //     if (e.target.checked === true ){
    //         this.activateStaff(item.CustomerStaffId)
    //     }
    //     if (e.target.checked === false) {
    //         this.deactivateStaff(item.CustomerStaffId)
    //     }

    // };

    // handleSwitchChecked = (e, item) => {
    //     let payload = {
    //       CustomerStaffId: item.id,
    //     };
    //     if (e.target.checked === true) {
    //       this.activateStaff(payload);
    //     }
    //     if (e.target.checked === false) {
    //       this.deactivateStaff(payload);
    //     }
    //   };
    
    searchBarChange = (e) => {
        this.setState({staffSearchValue: e.target.value}, () => {
            if (this.state.staffSearchValue === "") {
                this.searchStaff();
            }
        })
    };


    search = (e) => {
        e.preventDefault();
        this.searchStaff()
    };
    
    //Alert Notifications
    displaySuccessResponseAlert = (response, action, query) => {
        this.setState(
          {
              showAlertModal: true,
              alertIcon: (
                <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
              ),
              alertMessage: response.message,
          },
          () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
          }
        );
        if (action) {
            return { query };
        }
    };
    
    displayErrorResponseAlert = (response, action, query) => {
        const { dispatch } = this.props;
        if (response === "Please Check your internet connectivity") {
            dispatch(alertActions.error(response));
        } else if (typeof response === "string") {
            this.setState(
              {
                  showAlertModal: true,
                  alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
                  alertMessage: response,
              },
              () => {
                  appHelpers.dismissModal.call(this, "showAlertModal");
                  if (action) {
                      return query;
                  }
              }
            );
        } else {
            this.setState(
              {
                  showAlertModal: true,
                  alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
                  alertMessage: response.message,
              },
              () => {
                  appHelpers.dismissModal.call(this, "showAlertModal");
                  if (action) {
                      return query;
                  }
              }
            );
        }
    };
    
    migrateToSSO = (item) => {
        let payload = {
            customerStaffId: item.id
        };
        
        const {dispatch} = this.props;
        dispatch(alertActions.startRequest());
        selfService.migrateCustomerStaffOnSSO(payload, source.token).then((res) => {
            let response = res.response;
            dispatch(alertActions.stopRequest());
            if (res.status === appConstants.SUCCESS_RESPONSE) {
                dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "success"}));
                this.searchStaff();
            } else if (res.status === appConstants.ERROR_RESPONSE) {
                dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "failure"}));
            }
        });
        
    };
    
    componentWillUnmount () {
        if (source) {
          source.cancel()
        }
       }

    render() {
        const { data, currentPage, totalCount, pageSize,
            staffSearchValue, showAlertModal, alertMessage, alertIcon, requesting} = this.state;
        const {user} = this.props;
        return (
            <ContentLayout
                pageTitle={appConstants.MANAGE_STAFFS}
                requesting={this.props.requesting}
                actions={
                    <div className={"float-right"}>
                        <Button
                            outline
                            color="info"
                            className=" btn-rounded mr-2 big-action-btn"
                            onClick={() =>appHelpers.navigateToPreviousRoute.call(this )}

                        >
                            <FaArrowLeft className={"icon-margin"}/>Back
                        </Button>
                    </div>
                }
            >
                
                <Row className={"row-space"}>
                    <Col>
                        <Button
                        className="btn-rounded mr-2 create-action-btn text-white"
                        onClick={this.createStaffs}
                        color={"success"}
                        disabled={requesting}
                    >
                        <FaPlusCircle className={"icon-margin mb-1"}/>
                        Create New Staff
                        </Button>
                    </Col>
                </Row>

                <Row className={"settings-spacing"}>
                <Col sm={{ size: 5}} className="text-center">
                    <SearchBar
                        placeholder="Search staff"
                        onChange={this.searchBarChange}
                        value={staffSearchValue}
                        showSearchButton
                        search={this.search}/>
                </Col>
                </Row>
                {
                    data.length === 0 &&
                    <EmptyRecordMessage message={'No record match your search'}/>
                }
                {data.length > 0 &&
             <Row>
                 <Col md={"12"}>
                <TableStructure
                    tableData={data}
                    editStaff={this.editStaff}
                    showStaffProfile={this.showStaffProfile}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    currentPage={currentPage}
                    fetchMoreRecords={this.searchStaff}
                    handleSwitchChecked={this.handleSwitchChecked}
                    user={user}
                    activateStaff={this.activateStaff}
                    deactivateStaff={this.deactivateStaff}
                    migrateToSSO={this.migrateToSSO}
                />
                </Col>
                </Row>
                }
                {showAlertModal && (
                  <RenderAlertModal
                    message={alertMessage}
                    icon={alertIcon}
                    showAlertModal={showAlertModal}
                    hideAlertView={this.hideAlertView}
                  />
                )}
                {this.props.displayGeneralAlert.show && <GeneralAlert />}
            </ContentLayout>
        );

    }

}

const mapStateToProps = state => {
    const { alert, user, requesting, progress,modal, displayGeneralAlert } = state;
    return {
        alert,
        user,
        requesting,
        progress,
        modal,
        displayGeneralAlert
    };
};


export default connect(mapStateToProps)(ManageStaffs);