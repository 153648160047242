import React from "react";
import { appHelpers } from "../../../_helpers";
import { modalActions } from "../../../_actions";
import FullScreenModal from "./../../../components/FullScreenModal";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  InputGroupText,
} from "reactstrap";

function ChangePassword({
  oldPassword,
  newPassword,
  confirmPassword,
  handleChange,
  togglePassword,
  changePassword,
  cancel,
  inputError,
  handleMouseOut,
  // invalidNewPassword,
  // invalidConfirmPassword,
}) {
  return (
    <Card className="bda-card">
      <CardBody className="bda-card__body">
        <CardTitle className="bda-card__title"> Change Password</CardTitle>
        <Form>
          <FormGroup>
            <Label for="oldPassword">Old Password &#42;</Label>
            <InputGroup>
              <Input
                id="oldPassword"
                type="password"
                name="oldPassword"
                value={oldPassword}
                onChange={handleChange}
              ></Input>
              <InputGroupText>
                <i
                  id="toggleOldPassword"
                  onClick={() =>
                    togglePassword("oldPassword", "toggleOldPassword")
                  }
                  className="fa fa-eye"
                  aria-hidden={true}
                  // style={{ color: "#675cff", fontSize: "18px" }}
                ></i>
              </InputGroupText>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="newPassword">New Password &#42;</Label>
            <InputGroup>
              <Input
                type="password"
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                valid={!inputError.newPassword || newPassword !== ""}
                invalid={inputError.newPassword || newPassword === ""}
                // invalid={invalidNewPassword}
                onMouseOut={handleMouseOut}
                // onBlur={handleMouseOut}
              ></Input>
              <InputGroupText>
                <i
                  id="toggleNewPassword"
                  onClick={() =>
                    togglePassword("newPassword", "toggleNewPassword")
                  }
                  className="fa fa-eye"
                  aria-hidden={true}
                ></i>
              </InputGroupText>
              {inputError.newPassword && (
                <FormFeedback>{inputError.newPassword}</FormFeedback>
              )}
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label for="confirmPassword">Confirm Password &#42;</Label>
            <InputGroup>
              <Input
                id={"confirmPassword"}
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
                valid={!inputError.confirmPassword || confirmPassword !== ""}
                invalid={inputError.confirmPassword || confirmPassword === ""}
                // invalid={invalidConfirmPassword}
                // onBlur={handleMouseOut}
                onMouseOut={handleMouseOut}
              ></Input>
              <InputGroupText>
                <i
                  id="toggleConfirmPassword"
                  onClick={() =>
                    togglePassword("confirmPassword", "toggleConfirmPassword")
                  }
                  className="fa fa-eye"
                  aria-hidden={true}
                ></i>
              </InputGroupText>
              {inputError.confirmPassword && (
                <FormFeedback>{inputError.confirmPassword}</FormFeedback>
              )}
            </InputGroup>
          </FormGroup>
        </Form>
        <div className={"mt-2 right-aligned-action-buttons"}>
          <button className={"ghost-button mr-2"} onClick={cancel}>
            Cancel
          </button>
          <Button
            color="success"
            className="btn-sm btn-rounded mr-2 text-white"
            onClick={changePassword}
            disabled={!appHelpers.isEmptyObject(inputError)}
          >
            Change Proceed
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}

// export default ChangePassword;

const changePasswordModal = ({
  oldPassword,
  newPassword,
  confirmPassword,
  handleChange,
  togglePassword,
  changePassword,
  cancel,
  displayChangePasswordModal,
  dispatch,
  requesting,
  inputError,
  handleMouseOut,
  // invalidNewPassword,
  // invalidConfirmPassword,
}) => {
  return (
    <FullScreenModal
      show={displayChangePasswordModal.show}
      width={"30%"}
      zIndex={requesting ? 10 : 999}
      callback={(res) => {
        appHelpers.fullScreenCallback.call(
          this,
          res,
          dispatch,
          displayChangePasswordModal,
          modalActions.displayChangePasswordModal({
            show: false,
          })
        );
      }}
    >
      <ChangePassword
        oldPassword={oldPassword}
        newPassword={newPassword}
        confirmPassword={confirmPassword}
        handleChange={handleChange}
        togglePassword={togglePassword}
        changePassword={changePassword}
        cancel={cancel}
        inputError={inputError}
        handleMouseOut={handleMouseOut}
        // invalidNewPassword={invalidNewPassword}
        // invalidConfirmPassword={invalidConfirmPassword}
      />
    </FullScreenModal>
  );
};

export default changePasswordModal;
