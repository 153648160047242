import React from 'react';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Label } from 'reactstrap';
import SelectList from '../SelectList/selectList';

const UpdateUserCard = ({ modalTitle, name, role, labelTitle, updateClick,updateText,
                            cancelText , cancelClick, supervisorList, supervisor, selectChange, requesting}) => {
    return (
        <Card style={{ borderRadius: "10px" }}>
            <CardBody style={{ padding: "30px" }}>
                <CardTitle style={{ fontSize: "18px", padding: "5px", fontWeight: "bold", borderBottom: "0.5px solid rgba(0, 0, 0, 0.3)", marginBottom: "20px" }}>{modalTitle}</CardTitle>
                <Row>
                <Col >
                        <div 
                        style={{flexDirection:"column"}}
                        className="d-flex">
                        <p style={{fontSize:"30px", fontWeight:"bold", marginBottom:"0px"}}>{name}</p>
                        <p style={{fontSize:"12px"}}>{role}</p> 
                        </div>
                    </Col>
                    </Row>
                    <br/>
                    <Row>
                        {/* <Col md="6">
                            <FormGroup>
                                <Label>{labelTitle}</Label>
                                 <Input
                                    name={'updateWorkflowName'}
                                    type="updateWorkflowName"
                                    value={updateWorkflowName}
                                    onChange={handleChange}
                                    disabled={disableUpdateWorkflowName}
                        />
                            </FormGroup>
                        </Col> */}
                    <Col md="6">
                        <FormGroup>
                            <Label>{labelTitle}</Label>
                            <SelectList
                                placeholder={"Select Supervisor"}
                                data={supervisorList}
                                valueKey={'userId'}
                                textField={'name'}
                                selectValue={supervisor}
                                onValueChange={(event) => {selectChange(event, 'supervisor')}}
                            />
                        </FormGroup>
                    </Col>
                </Row>
               <br/>
               <br/>

                
                <div style={{ float: "left" }}>
                    <Button
                        style={{ color: "white" }}
                        className={"btn-rounded ml-3   px-3"}
                        color="success"
                        disabled={requesting}
                        onClick={updateClick}>{updateText}</Button>
                    <Button
                        className={"btn-rounded ml-3  px-3"}
                        outline color="secondary"
                        disabled={requesting}
                        onClick={cancelClick}>{cancelText}</Button>
                </div>
            </CardBody>
        </Card >
    )
};

export default UpdateUserCard