import React, { Component } from "react";
import { connect } from "react-redux";
import ContentLayout from "../../components/ContentLayout";
import InvoiceSheet from "../../components/InvoiceSheet";
import { commonService, customerService } from "../../_services";
import {
  alertActions,
  apiActions,
  commonActions,
  modalActions,
} from "../../_actions";
import { appConstants } from "../../_constants";
import axios from "axios";
import moment from "moment";
import FullScreenModal from "../../components/FullScreenModal";
import "./createinvoice.scss";
import { appHelpers } from "../../_helpers";
import PrintInvoice from "../../components/MobilePrint/print-invoice";
import AddNewCustomer from "../../components/page-components/create-invoice-components/add-new-customer";
import "../../components/page-components/create-invoice-components/similar-customer";
import SimilarCustomer from "../../components/page-components/create-invoice-components/similar-customer";
import SearchPanel from "../../components/page-components/create-invoice-components/search-panel";
import DialogFullScreenModal from "../../components/DialogFullScreenModal";
import GeneralAlert from "../../components/Api-response-alert";
import ShareInvoiceFullScreenModal from "../../components/ShareInvoice/ShareInvoiceFullScreenModal";
import DisplayInvoiceFullScreenModal from "../../components/DisplayInvoiceFullScreenModal";
import CreatedInvoiceFullScreenModal from "../../components/CreatedInvoiceFullScreenModal";
import CreateInvoiceActionButtons from "../../components/page-components/create-invoice-components/action-buttons";
import { pageHelpers } from "../../_helpers/page.helpers";

let source;

class CreateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revenues: [],
      customers: [],
      customersList: [],
      locations: [],
      revenueGroups: [],
      revenueGroup: "",
      revenue: "",
      subRevenues: [],
      subRevenue: "",
      revenueCode: "",
      location: "",
      customer: "",
      currency: { id: 566, name: "Naira", code: "NGN", currencySymbol: "₦" },
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      customerNumber: "",
      billDate: new Date(),
      totalAmount: 0,
      rrrNumber: "",
      showDiscardModal: false,
      showPreview: false,
      componentError: false,
      showDialog: false,
      dialogMessage: "",
      shareEmail: "",
      invoiceItems: [
        {
          description: "",
          quantity: 1,
          rate: 0,
          amount: 0,
        },
      ],
      isLiveView: false,
      componentErrorMessages: [],
      showCreatedInvoiceSheet: false,
      showShareModal: false,
      showAlertModal: false,
      invoiceSheetActions: [
        {
          name: "Proceed",
          icon: "fa-arrow-right",
          action: () => this.proceed(),
          width: "6.5rem",
        },
        {
          name: "Edit",
          icon: "fa-pen-square",
          action: this.editInvoice,
          width: "6rem",
        },
        {
          name: "Discard",
          icon: "fa-trash-alt",
          action: this.showDiscardModal,
          width: "8rem",
        },
      ],
      createdInvoiceActions: [
        {
          name: "Share",
          icon: "fa-upload",
          action: this.handleShareAction,
          width: "6.5rem",
        },
        {
          name: "Print",
          icon: "fa-print",
          action: () => this.downloadInvoice(true),
          width: "6rem",
        },
        {
          name: "Mobile Print",
          icon: "fa-print",
          action: () => appHelpers.mobilePrintAction("print-panel"),
        },
        {
          name: "Download",
          icon: "fa-download",
          action: () => this.downloadInvoice(),
          width: "8rem",
        },
      ],
      logo: "",
      invoiceNumber: "",
      newContactFirstName: "",
      newContactLastName: "",
      newCustomerName: "",
      newCustomerEmail: "",
      newCustomerPhoneNo: "",
      newCustomerAddress: "",
      showAddNewCustomer: false,
      showSimilarCustomer: false,
      openInvSheetTooltip: false,
      invSheetTooltipTarget: false,
      invSheetToolTipPlacement: "right",
      invSheetTooltipCopyText: "",
      showInvoiceSheetTooltip: false,
      decimalPrecision: appConstants.DEFAULT_PRECISION,
      isLocationMapping: false,
      pageTitle: appConstants.CREATE_INVOICE,
      invoiceToEditDetails: null,
      reasonForChange: "",
      redirectToSearchInvoiceAfterEdit: false,
      creditNoteRequestStatus: 0,
      footerNote: "",
      footerOptions: []
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    this.routeUserBasedOnRole();
    const body = document.body;
    body.style.overflow = "scroll";
  }

  fetchInitialReferenceData = async () => {
    const { apiReducer, dispatch } = this.props;
    const { currencies } = apiReducer;
    const pathState = this.props.location.state;

    this.setState({ logo: this.props.settings.setLogo });
    dispatch(commonActions.setPeriodPickerVisibility(false));

    await this.getCustomers();
    await this.getSettingsByKey();
    await this.getRevenueGroupSettings();
    await this.getRevenues();
    await this.getLocations();
    await this.getFooterOptions();


    //Redux states
    if (!currencies) {
      this.getCurrencies();
    } else {
      this.setState({ currencies });
    }

    //get invoice number to edit details
    if (pathState && pathState.pageTitle === appConstants.EDIT_INVOICE) {
      this.setState(
        {
          pageTitle: appConstants.EDIT_INVOICE,
        },
        () => this.fetchEditInvoiceDetails()
      );
    }
  };

  componentDidUpdate(prevProps) {
    const { redirectToSearchInvoiceAfterEdit } = this.state;
    const { alertComponentsReducer, apiReducer } = this.props;

    if (this.props.settings.setLogo !== prevProps.settings.setLogo) {
      this.setState({
        logo: this.props.settings.setLogo,
      });
    }

    if (this.props.displayGeneralAlert !== prevProps.displayGeneralAlert) {
      if (this.props.displayGeneralAlert.performAction) {
        this.displayCreatedInvoiceSheet();
      }
    }

    if (
      alertComponentsReducer.displayCreatedInvoice !==
      prevProps.alertComponentsReducer.displayCreatedInvoice
    ) {
      if (!alertComponentsReducer.displayCreatedInvoice.show) {
        this.clearInputFields();
      }
    }

    if (apiReducer.currencies !== prevProps.apiReducer.currencies) {
      this.setState({
        currencies: apiReducer.currencies,
      });
    }

    if (
      apiReducer.revenuesByOrganization !==
      prevProps.apiReducer.revenuesByOrganization
    ) {
      this.setState({
        revenues: apiReducer.revenuesByOrganization,
      });
    }

    if (
      apiReducer.locationsByOrganization !==
      prevProps.apiReducer.locationsByOrganization
    ) {
      this.setState({
        locations: apiReducer.locationsByOrganization,
      });
    }
    if (apiReducer.subRevenues !== prevProps.apiReducer.subRevenues) {
      this.setState({
        subRevenues: apiReducer.subRevenues,
      });
    }
    if (
      alertComponentsReducer.displayCreatedInvoice.show !==
      prevProps.alertComponentsReducer.displayCreatedInvoice.show
    ) {
      if (
        redirectToSearchInvoiceAfterEdit &&
        !alertComponentsReducer.displayCreatedInvoice.show
      ) {
        appHelpers.navigateRoute.call(this, "/search-invoice");
      }
    }
  }

  /**
   * @param {*} props
   * @param {*} state
   */
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first
    let pathState = props.location.state;
    if (pathState) {
      if (pathState.selectedAgencyDetails !== state.selectedAgencyDetails) {
        let stateObj = {
          organizationDetails: pathState.selectedAgencyDetails,
        };
// console.log(stateObj, "details");
        if (pathState.pageTitle === appConstants.EDIT_INVOICE) {
          stateObj.pageTitle = pathState.pageTitle;
          stateObj.invoiceNumberToEdit = pathState.invoiceNumber;
          stateObj.invoiceIdToEdit = pathState.invoiceId;
        }
       
        return stateObj;
      }
    }

    return null;
  }

  /**
   * @description Route the user based on the role of the logged in user
   */
  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { organizationDetails } = this.state;

    let pathState = this.props.history.location.state;
    //get route the user is coming from
    let previousPath;
    if (this.props.history.location.state) {
      previousPath = this.props.history.location.state.from;
    }

    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    //Everytime a user with a VGG Role vists this page, the user must select an organization
    //organizationDetails===undefined prevents redirect from happening again once an organization is selected

    if (vggAdminRole && organizationDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is selected, set the following states
      this.setState(
        {
          invoiceNumberToEdit: pathState?.invoiceNumber,
          invoiceIdToEdit: pathState?.invoiceId,
          pageTitle: pathState?.pageTitle
            ? pathState.pageTitle
            : appConstants.CREATE_INVOICE,
          // organizationDetails: pathState.selectedAgencyDetails,
          isVggRole: vggAdminRole ? true : false,
          organizationId: vggAdminRole
            ? organizationDetails && organizationDetails.id
            : user.organizationId,
        },
        () => this.fetchInitialReferenceData()
      );
    }
  };

  //  isOrganizationFaant = ()=> {
  //   const { user } = this.props;

  //   FAANT
  //  }
  setInvoiceToEditDetails = async (data) => {
    console.log(data, "editData");
    const { user } = this.props;
    const {
      revenues,
      customers,
      locations,
      currencies,
      revenueGroups,
      footerOptions
    } = this.state;
    const {
      revenueId,
      currencyId,
      customerId,
      customerAIN,
      locationId,
      bills,
      decimalPrecisions,
      remitaRetrievalReference,
      invoiceDate,
      subRevenueId,
      amount,
      invoiceNumber,
      revenueGroupId,
      optionValue,
    } = data.invoice;
    console.log(data.invoice, "help");
    // debugger;
    const customer = appHelpers.getCustomer(customers, customerAIN, customerId);
    const revenue = appHelpers.getRevenueObj(revenues, revenueId);
    const currency = appHelpers.getCurrencyValue(currencies, currencyId);
    const location = appHelpers.getLocationObj(locations, locationId);
    const footerNote = appHelpers.findOptionValue(footerOptions,optionValue);
    const revenueGroup = appHelpers.getRevenueGroup(
      revenueGroups,
      revenueGroupId
    );
    await this.getSubRevenues(revenueId);
    const subRevenue = appHelpers.getSubRevenueObj(
      this.state.subRevenues,
      subRevenueId
    );
    this.setState(
      {
        revenue: revenue ? revenue : "",
        subRevenue: subRevenue ? subRevenue : revenue,
        customer: customer ? customer : "",
        currency: currency,
        location: location,
        decimalPrecision: decimalPrecisions,
        rrrNumber: remitaRetrievalReference,
        name: customer ? customer.customerName : "",
        email: customer ? customer.email : "",
        phoneNumber: customer ? customer.phone : "",
        address: customer ? customer.address : "",
        billDate: moment(invoiceDate).format("YYYY-MM-DD"),
        invoiceItems: bills,
        totalAmount: amount,
        invoiceNumber: invoiceNumber,
        revenueGroup: revenueGroup ? revenueGroup : "",
        footerNote: footerNote,
        // revenueGroupTag: user.organizationId === 10 ? "Directorate" : null
      },
      () => this.getRevenueCustomerMapping()
    );
  };

  /* API CALLS */

  // If user wants to edit an invoice
  fetchEditInvoiceDetails = () => {
    const {
      invoiceNumberToEdit,
      invoiceIdToEdit,
      organizationDetails,
      organizationId,
    } = this.state;
    const { dispatch, user } = this.props;

    const vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    const payload = {
      invoiceNumber: invoiceNumberToEdit,
      invoiceId: invoiceIdToEdit,
      agencyId: vggAdminRole ? organizationDetails.id : organizationId,
    };

    dispatch(alertActions.startRequest());
    commonService.GetInvoiceEditDetails(payload, source.token).then((res) => {
      let { response } = res;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (response.data) {
          let data = response.data;
          this.setState({
            invoiceToEditDetails: data,
          });

          this.setInvoiceToEditDetails(data);
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "failure",
          })
        );

        dispatch(alertActions.error(res.response));
      }
    });
  };

  getRevenues = () => {
    let payload = {
      organizationId: this.state.organizationId,
      isSelfService: false,
    };
    return this.props.dispatch(apiActions.getRevenues(payload, source, true));
  };

  getSubRevenues = (revenueId) => {
    let payload = {
      organizationId: this.state.organizationId,
      revenueGroupId: revenueId,
    };
    return this.props.dispatch(apiActions.getSubRevenues(payload, source));
  };

  getCustomers = () => {
    let { dispatch } = this.props;
    dispatch(alertActions.startRequest());

    let payload = {
      organizationId: this.state.organizationId,
    };
    commonService.getCustomers(payload, source.token).then((res) => {
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (response.data) {
          this.setState(
            {
              customers: response.data,
              customersList: response.data, //put this here as a backup for the customers list, when it is modified by filtering
            },
            () => {
              dispatch(alertActions.stopRequest()); //This is put here because the customer endpoint takes a while to return response data
              //even after you receive a 200 response.
            }
          );
        } else if (response.data === null) {
          dispatch(alertActions.stopRequest());
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getRevenueCustomerMapping = async () => {
    let { dispatch } = this.props;
    const { subRevenue } = this.state;

    dispatch(alertActions.startRequest());
    commonService
      .getRevenueCustomerMapping(subRevenue?.id, source.token)
      .then(async (res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          let filteredCustomers = this.state.customersList;

          //filter the customersList using the response from getRevenueCustomerMapping (i.e. Filter an array using another array)
          // we are filtering with customersList here beacause the customers array is already being mutated
          if (response.data !== null && response.data.length > 0) {
            let customersMappedToRevenue = response.data;
            filteredCustomers = await this.state.customersList.filter(
              (customer) =>
                customersMappedToRevenue.find(
                  (item) => customer.id === item.customerId
                )
            );
          }
          //remove others from customers list if allowUnRegisterCustomer attribute in the selected revenue is false
          if (subRevenue.allowUnRegisterCustomer === false) {
            let customersListExcludingOthers = await filteredCustomers.filter(
              (item) => item.id !== 100
            );
            this.setState({
              customers: customersListExcludingOthers,
            });
          } else {
            this.setState({
              customers: filteredCustomers,
            });
          }

          //clear these inputs
          if (this.state.pageTitle === appConstants.CREATE_INVOICE) {
            this.setState({
              customer: "",
              name: "",
              email: "",
              phoneNumber: "",
              address: "",
            });
          }
        }

        if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(alertActions.error(res.response));
        }
      });
  };

  getLocations = () => {
    let payload = {
      organizationId: this.state.organizationId,
    };
    return this.props.dispatch(apiActions.getLocations(payload, source, false));
  };

  getRevenueLocationMapping = () => {
    let { dispatch, apiReducer } = this.props;
    const { subRevenue } = this.state;
    dispatch(alertActions.startRequest());
    commonService
      .getRevenueLocationMapping(subRevenue.id, source.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          if (response.data !== null && response.data.length > 0) {
            this.setState({
              locations: response.data,
              isLocationMapping: true,
            });
          }
          if (response.data === null) {
            this.setState({
              isLocationMapping: false,
              locations: apiReducer.locationsByOrganization,
            });
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
        }
      });
  };

  getCurrencies = () => {
    return this.props.dispatch(apiActions.getCurrencies(source.token, false));
  };

  getSettingsByKey = () => {
    let { dispatch } = this.props;
    let key = "CompanyRepresentative";
    dispatch(alertActions.startRequest());
    commonService.getSettingsByKey(key, source.token).then((res) => {
              dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          companyRepId: response.data.value,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getRevenueGroupSettings() {
    //TODO: change this implementation once Kayode provides the settings API
    const { organizationId, organizationDetails } = this.state;
    // const {user} = this.props;
    this.setState(
      {
        //revenueGroupTag should be set to the API response
        //it is currently hardcoded to cater for NCAA directorate request
        revenueGroupTag: organizationId === 10 || organizationId === 3918 || organizationId === 5087 || organizationId === 19946 ? "Directorate" : null,
        // revenueGroupTag: user.organizationCode === "NCAA" || user.organizationCode === "FAANT" ? "Directorate" : null,

      },
      () => {
        if (this.state.revenueGroupTag) {
          this.getRevenueGroups();
        }
      }
    );
  }

  getRevenueGroups = () => {
    let { dispatch } = this.props;
    let { organizationId } = this.state;

    let payload = {
      agencyId: organizationId,
    };
    dispatch(alertActions.startRequest());
    commonService.getRevenueGroups(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          revenueGroups: response?.data,
        });
        // console.log(response?.data[0].logoUrl, "help me");
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getFooterOptions = () => {
    let { dispatch } = this.props;
    let { organizationId } = this.state;

    let payload = {
      agencyId: organizationId,
    };
    dispatch(alertActions.startRequest());
    commonService.getFooterOptions(payload, source.token).then((res) => {
      let response = res.response;
      console.log(response, "footer response");
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          footerOptions: response,
        });
        // console.log(response?.data[0].logoUrl, "help me");
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(res.response));
      }
    });
  };

  proceed = () => {
    const { pageTitle } = this.state;

    if (pageTitle === appConstants.CREATE_INVOICE) {
      return this.createInvoice(true);
    } else if (pageTitle === appConstants.EDIT_INVOICE) {
      return this.updateInvoice();
    }
  };

  createInvoice = (msg) => {
    const { dispatch, user } = this.props;
    const {
      billDate,
      subRevenue,
      customer,
      currency,
      location,
      name,
      email,
      phoneNumber,
      address,
      rrrNumber,
      invoiceItems,
      revenueGroup,
      organizationId,
      isLocationMapping,
      footerNote,
    } = this.state;

    let payload = {
      invoiceDate: moment(billDate).format("YYYY-MM-DD"),
      revenueId: subRevenue.id,
      customerAIN: customer.customerAIN,
      currencyId: currency.id,
      locationId: isLocationMapping ? location.locationId : location.id,
      name: name,
      emails: email,
      phone: phoneNumber,
      address: address,
      bills: invoiceItems.map((inv) => {
        console.log(
          "This is the number of places  ====>> ",
          appHelpers.convertToNumOfPlaces(inv.amount, 2).toFixed(3)
        );
        const amount = appHelpers.convertToNumOfPlaces(inv.amount, 3);
        return {
          ...inv,
          amount: amount,
        };
      }),
      RevenueGroupId: revenueGroup.id ? revenueGroup.id : 0,
      rrr: rrrNumber,
      enableCredit: false,
      userId: user.userId,
      checkSimilarInvoice: msg,
      agencyId: organizationId,
      footerOption: footerNote.optionName,
    };

    console.log(payload, "payNow");
    dispatch(alertActions.startRequest());
    commonService.createInvoice(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "success",
          })
        );
        dispatch(modalActions.displayInvoiceSheet({ show: false }));
        dispatch(modalActions.displayCreatedInvoice({ show: true }));
        this.setState(
          { invoiceNumber: response.id, rrrNumber: response.paymentRef },
          () => {
            this.downloadInvoice();
            if (this.props.displayGeneralAlert.show) {
              this.dismissModals();
            }
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (
          response?.message &&
          response?.message.includes("similar invoice")
        ) {
          dispatch(
            modalActions.displayDialogAlert({
              show: true,
              message: response?.message,
              declineText: "No",
              approveText: "Yes create invoice",
            })
          );
          this.setState({ showDialog: true, dialogMessage: response?.message });
        } else {
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response?.message,
              action: "failure",
              performAction: true,
            })
          );
          dispatch(modalActions.displayInvoiceSheet({ show: false }));
          dispatch(
            modalActions.displayCreatedInvoice({ showCreatedInvoice: false })
          );
          this.dismissModals();
        }
      }
    });
  };

  //Update previously created Invoice
  updateInvoice = async () => {
    const { dispatch } = this.props;
    const {
      invoiceItems,
      billDate,
      subRevenue,
      customer,
      currency,
      location,
      name,
      email,
      phoneNumber,
      address,
      revenueGroup,
      rrrNumber,
      invoiceToEditDetails,
      organizationId,
      reasonForChange,
    } = this.state;

    const { invoiceNumber, id } = invoiceToEditDetails.invoice;
    const payload = {
      invoiceDate: moment(billDate).format("YYYY-MM-DD"),
      revenueId: subRevenue.id,
      customerAIN: customer.customerAIN,
      currencyId: currency.id,
      locationId: location.id,
      name: name,
      emails: email,
      phone: phoneNumber,
      address: address,
      bills: invoiceItems.map((inv) => {
        const amount = appHelpers.convertToNumOfPlaces(inv.amount, 3);
        return {
          ...inv,
          // amount: appHelpers.roundOff(inv.amount, 3),
          amount: amount,
        };
      }),
      revenueGroupId: revenueGroup.id ? revenueGroup.id : 0,
      rrr: rrrNumber,
      agencyId: organizationId,
      enableCredit: false,
      invoiceID: id,
      invoiceNumber: invoiceNumber,
      editComment: reasonForChange,
      // footerOption: footerNote.optionName,
    };

    dispatch(alertActions.startRequest());
    commonService.editInvoice(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          invoiceNumber: response.id,
          rrrNumber: response.paymentRef,
          redirectToSearchInvoiceAfterEdit: true,
        });
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "success",
          })
        );
        dispatch(modalActions.displayInvoiceSheet({ show: false }));
        dispatch(modalActions.displayCreatedInvoice({ show: true }));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "failure",
            // performAction: true,
          })
        );
      }
    });
  };

  shareInvoice = () => {
    let { dispatch } = this.props;
    let { invoiceNumber, shareEmail } = this.state;

    let payload = {
      invoiceNumber: invoiceNumber,
      recipientEmail: shareEmail,
    };

    dispatch(apiActions.shareInvoice.call(this, payload, source.token));
  };

  // download invoice
  downloadInvoice = (print) => {
    const { dispatch } = this.props;
    const { invoiceNumber } = this.state;

    let payload = { invoiceNumber: invoiceNumber };
    dispatch(apiActions.downloadSearchedInvoice(print, payload, source.token));
  };

  /* Handlers */
  handleSelectChange = (selected, attribute) => {
    let errorMessages = this.state.componentErrorMessages;
    const { pageTitle } = this.state;
    errorMessages[attribute] = false;
    if (!selected) {
      this.setState({ [attribute]: "" }, () => {
        if (this.state.customer === "") {
          this.setState({
            name: "",
            email: "",
            phoneNumber: "",
            address: "",
          });
        }
      });
    } else {
      if (attribute === "revenue") {
        this.setState(
          {
            [attribute]: selected,
            subRevenue: "",
            subRevenues: [],
          },
          () => {
            // Check if the revenue selected has subRevenue,
            // If true, call getSubRevenue endpoint and pass the revenue ID
            // If false, pass the revenue object to the subRevenue
            console.log(selected, "whichRevenue");
            if (selected.hasSubRevenue) {
              this.getSubRevenues(selected.id);
            }
            if (!selected.hasSubRevenue) {
              this.setState({ subRevenues: [selected] });
            }
            // if (selected.id === 112) {
            //   this.getFooterOptions();
            // }
          }
        );
      } else if (attribute === "subRevenue") {
        let newRate = selected.rate === null ? 0 : selected.rate;
        let invoiceDetails = this.state.invoiceToEditDetails;
        let updatedInvoiceItemsRate;

        //check if invoice has been paid
        if (invoiceDetails?.invoice?.status === "Paid") {
          updatedInvoiceItemsRate = this.state.invoiceItems.map((item) => {
            item.rate = item.rate;
            item.amount = item.amount;
            return item;
          });
        } else if (
          pageTitle === appConstants.EDIT_INVOICE &&
          invoiceDetails?.invoice?.status === "Unpaid"
        ) {
          updatedInvoiceItemsRate = this.state.invoiceItems.map((item) => {
            let updatedRate =
              selected.rate === null || selected.rate === 0
                ? item.rate
                : selected.rate;
            item.rate = updatedRate;
            item.amount =
              updatedRate && updatedRate > 0
                ? item.quantity * updatedRate
                : item.amount;
            return item;
          });
        } else {
          updatedInvoiceItemsRate = this.state.invoiceItems.map((item) => {
            item.rate = newRate;
            item.amount = item.quantity * newRate;
            return item;
          });
        }
        this.setState(
          {
            [attribute]: selected,
            invoiceItems: updatedInvoiceItemsRate,
            decimalPrecision: selected.decimalPrecision,
            disableRate: selected.rate ? true : false,
          },
          () => {
            this.getRevenueCustomerMapping();
            this.getRevenueLocationMapping();
          }
        );
      } else if (attribute === "customer") {
        this.setState({
          [attribute]: selected,
          name: selected.customerName,
          email: selected.email,
          phoneNumber: selected.phone,
          address: selected.address,
        });
      } else if (attribute === "location") {
        this.setState({ [attribute]: selected });
      } else if (attribute === "currency") {
        this.setState({ [attribute]: selected });
      } else if (attribute === "revenueGroup") {
        this.setState({ [attribute]: selected });
      }else if (attribute === "footerNote") {
          this.setState({ [attribute]: selected });
      } else {
        this.setState({ [attribute]: selected.Id });
      }
    }
    this.setState({ componentErrorMessage: errorMessages });
  };

  addInvoiceItemFields = () => {
    let newRate =
      this.state.subRevenue.rate === null ? 0 : this.state.subRevenue.rate;
    this.setState({
      invoiceItems: [
        ...this.state.invoiceItems,
        {
          description: "",
          quantity: 1,
          rate: newRate,
          amount: 1 * newRate,
          precision: appConstants.DEFAULT_PRECISION,
        },
      ],
    });
  };

  removeInvoiceItem = (index) => (event) => {
    const invoiceItems = this.state.invoiceItems.filter(
      (e, idx) => idx !== index
    );

    let totalAmount = invoiceItems.reduce(this.addItemsAmount, 0);
    this.setState({ invoiceItems, totalAmount });
  };

  validateInput = () => {
    const { invoiceItems, pageTitle } = this.state;
    let _errs_ = [];
    let formValid = true;
    if (this.state.revenue === "") {
      _errs_["revenue"] = "Please select revenue";
      formValid = false;
    }
    if (this.state.footerNote === "" && this.state.revenue.id === 112) {
      _errs_["footerNote"] = "Please select foot note option";
      formValid = false;
    }
    if (this.state.subRevenue === "") {
      _errs_["subRevenue"] = "Please select sub revenue";
      formValid = false;
    }
    if (this.state.customer === "") {
      _errs_["customer"] = "Please select customer";
      formValid = false;
    }
    if (this.state.name === "") {
      _errs_["name"] = "Please enter a name";
      formValid = false;
    }
    if (this.state.email === "") {
      _errs_["email"] = "Please enter an email address";
      formValid = false;
    }
    if (this.state.phoneNumber === "") {
      _errs_["phoneNumber"] = "Please enter a phone number";
      formValid = false;
    }
    if (this.state.location === "") {
      _errs_["location"] = "Please select location";
      formValid = false;
    }
    if (this.state.address === "") {
      _errs_["address"] = "Please enter an address";
      formValid = false;
    }
    if (this.state.currency === "") {
      _errs_["currency"] = "Please select currency";
      formValid = false;
    }
    if (this.state.revenueGroup === "" && this.state.revenueGroupTag) {
      _errs_["revenueGroup"] = `Please select ${this.state.revenueGroupTag}`;
      formValid = false;
    }
    if (
      pageTitle === appConstants.EDIT_INVOICE &&
      this.state.reasonForChange === ""
    ) {
      _errs_[
        "reasonForChange"
      ] = `Please enter a reason for updating the invoice`;
      formValid = false;
    }

    for (let i = 0; i < invoiceItems.length; i++) {
      const errors = {}; // Store errors for the current item
  
      if (invoiceItems[i].description === "") {
        errors["description"] = "Please add an item description";
        formValid = false;
      }
      if (
        invoiceItems[i].quantity === "" ||
        invoiceItems[i].quantity === 0
      ) {
        errors["quantity"] = "Please add a quantity";
        formValid = false;
      } else if (!/^(\d+(\.\d{1,5})?|\.\d{1,5})$/.test(invoiceItems[i].quantity)) {
        errors["quantity"] = "Quantity must have at most 5 decimal places";
        formValid = false;
      }
  
      if (
        invoiceItems[i].rate === "" ||
        invoiceItems[i].rate === 0
      ) {
        errors["rate"] = "Please add a rate";
        formValid = false;
      }
      if (invoiceItems[i].quantity < 0) {
        errors["quantity"] = "Quantity cannot be less than 1";
        formValid = false;
      }
      if (invoiceItems[i].rate < 0) {
        errors["rate"] = "Rate cannot be less than zero";
        formValid = false;
      }
  
      // Store errors for the current item in the _errs_ object
      if (Object.keys(errors).length > 0) {
        _errs_[i] = errors;
      }
    }
  

    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  showDiscardModal = () => {
    appHelpers.enableScroll();
    this.props.dispatch(
      modalActions.displayDialogAlert({
        show: true,
        approveText: "Yes",
        declineText: "No",
        message: "Are you sure you want to cancel this invoice",
      })
    );
    this.setState({ showDiscardModal: true });
  };

  handleItemChange = (index) => (event) => {
    const newInvoiceItems = this.state.invoiceItems.map((item, key) => {
      if (index !== key) return item;

      let updatedItem = { ...item, [event.target.name]: event.target.value };

      if (updatedItem.rate < 0) {
        this.props.dispatch(
          alertActions.notify("Rate cannot be less than zero")
        );
      }
      if (updatedItem.quantity < 0) {
        this.props.dispatch(
          alertActions.notify("Quantity cannot be less than 1")
        );
      }

      if (updatedItem.quantity !== "" && updatedItem.rate !== "") {
        updatedItem.amount = updatedItem.quantity * updatedItem.rate;
      } else {
        updatedItem.amount = 0;
      }
      return { ...updatedItem };
    });

    let totalAmount = newInvoiceItems.reduce(this.addItemsAmount, 0);
    this.setState({ invoiceItems: newInvoiceItems, totalAmount: totalAmount });
  };


  handleFocus = (index) => (event) => {
    let { invoiceItems } = this.state;

    const focusedInvoiceItems = invoiceItems.map((item, key) => {
      if (index !== key) return item;
      let focusedItem;
      if (event.target.value === "0") {
        focusedItem = { ...item, [event.target.name]: "" };
      } else {
        focusedItem = { ...item };
      }

      return focusedItem;
    });

    this.setState({ invoiceItems: focusedInvoiceItems });
  };

  handleBlur = (index) => (event) => {
    let { invoiceItems } = this.state;

    const bluredInvoiceItems = invoiceItems.map((item, key) => {
      if (index !== key) return item;
      let bluredItem;
      if (event.target.value === "") {
        bluredItem = { ...item, [event.target.name]: 0 };
      } else {
        bluredItem = { ...item };
      }

      return bluredItem;
    });

    this.setState({ invoiceItems: bluredInvoiceItems });
  };

  addItemsAmount = (total, item) => {
    return total + item.amount;
  };

  handleChange = (event, attribute) => {
    let currState = this.state;
    currState[attribute] = event.target.value;
    let errorMessages = this.state.componentErrorMessages;
    errorMessages[attribute] = false;
    this.setState(currState);
    this.setState({ componentErrorMessages: errorMessages });
  };

  handleShareAction = () => {
    this.props.dispatch(
      modalActions.displayShareInvoiceModal({
        show: true,
      })
    );
  };

  handleShareEmailChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onkeydownEnterShareInvoice = (e) => {
    if (e.key === "Enter") {
      this.shareInvoice();
    }
  };

  changeRRR = (event) => {
    this.setState({ rrrNumber: event.target.value });
  };
  changeDatePicker = (date) => {
    this.setState({ billDate: date });
  };

  toggleLiveView = () => {
    this.setState({
      isLiveView: !this.state.isLiveView,
    });
  };

  isShowPreview = () => {
    this.setState({ showPreview: true });
  };

  editInvoice = () => {
    appHelpers.enableScroll();
    this.props.dispatch(modalActions.displayInvoiceSheet({ show: false }));
  };

  addCustomer = () => {
    const { dispatch, user } = this.props;
    const {
      newContactFirstName,
      newContactLastName,
      newCustomerName,
      newCustomerEmail,
      newCustomerPhoneNo,
      newCustomerAddress,
      companyRepId,
    } = this.state;

    let payload = {
      id: 0,
      name: newCustomerName,
      // lastName: newContactLastName,
      category: 1,
      type: 1,
      address: newCustomerAddress,
      rcNumber: "",
      incorpDate: "",
      debtRecovery: 0,
      accountCode: "",
      agencyId:
        user.vggRole === appConstants.ROLES.VGG_ADMIN
          ? user.organizationId
          : undefined,
      fromCreateInvoice: true,
      customerStaff: [
        {
          titleId: companyRepId,
          // name: newContactName,
          firstName: newContactFirstName,
          lastName: newContactLastName,
          email: newCustomerEmail,
          phoneNumber: newCustomerPhoneNo,
        },
      ],
    };

    dispatch(alertActions.startRequest());
    customerService.addCustomer(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.getCustomers();
        this.hideSimilarCustomer();
        this.hideAddNewCustomer();
        this.setState({
          customer: {
            id: response.id.id,
            customerName: response.id.name,
            customerAIN: response.id.customerAIN,
          },
          name: response.id.name,
          email: response.id.email,
          phoneNumber: response.id.phoneNumber,
          address: response.id.address,
        });
        dispatch(alertActions.success(response.message));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(response.message));
        this.setState({
          customer: "",
          name: "",
          email: "",
          phoneNumber: "",
          customerAIN: "",
          address: "",
        });
      }
    });
  };

  similarCustomerCheck = () => {
    const { dispatch } = this.props;
    const { newCustomerName } = this.state;

    let payload = {
      customerName: newCustomerName,
    };

    dispatch(alertActions.startRequest());
    customerService.similarCustomerCheck(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          similarCustomersList: response.data,
        });
        if (response.data) {
          this.showSimilarCustomer();
        } else {
          this.addCustomer();
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
      }
    });
  };

  resetCreateCustomerForm = () => {
    this.setState({
      newContactFirstName: "",
      newContactLastName: "",
      newCustomerName: "",
      newCustomerEmail: "",
      newCustomerPhoneNo: "",
      newCustomerAddress: "",
      cancelPressed: false,
    });
  };

  renderInvoiceView = () => {
    const { user } = this.props;
 
    const {
      rrrNumber,
      invoiceItems,
      invoiceNumber,
      billDate,
      totalAmount,
      address,
      subRevenue,
      revenueGroupTag,
      revenueGroup,
      location,
      customer,
      logo,
      name,
      phoneNumber,
      email,
      currency,
      openInvSheetTooltip,
      invSheetTooltipTarget,
      invSheetToolTipPlacement,
      invSheetTooltipCopyText,
      showInvoiceSheetTooltip,
      isLiveView,
      decimalPrecision,
      reasonForChange,
      pageTitle,
      creditNoteRequestStatus,
      creditNoteReference,
      organizationId,
      organizationDetails,
    } = this.state;
    console.log(totalAmount, "invAg");
    console.log(phoneNumber, "phNum");
    return (
      <InvoiceSheet
        // organizationLogo={user.organizationCode === "FAANT" ? revenueGroup?.logoUrl : logo}
        organizationLogo={organizationId === 3918 || organizationId === 5087 || organizationId === 19946 ? revenueGroup?.logoUrl : logo}
        invoiceNumber={invoiceNumber}
        rrrNumber={rrrNumber}
        // companyName={user.organization}
        companyName={name}
        companyLocation={location ? location.address : ""}
        invoiceSheetTitle={subRevenue ? subRevenue.name : ""}
        customerName={name}
        customerNumber={customer ? customer.customerAIN : ""}
        revenueCode={subRevenue ? subRevenue.code : ""}
        revenueGroupTag={revenueGroupTag}
        revenueGroup={revenueGroup ? revenueGroup.name : ""}
        // revenueGroup={revenueGroup ? revenueGroup : ""}
        invoiceDate={billDate}
        customerAddress={address}
        invoiceItems={invoiceItems}
        totalAmount={totalAmount}
        totalAmountInWords=""
        invoiceCreator={user.fullName}
        customerPhone={phoneNumber}
        customerEmail={email}
        currency={currency.name}
        currencySymbol={currency.currencySymbol}
        coin={
          currency.id === 840 ? "Cents" : currency.id === 978 ? "Cents" : "Kobo"
        }
        copyDescription={pageHelpers.copyToClipboard}
        copyAmount={pageHelpers.copyToClipboard}
        copyRRR={pageHelpers.copyToClipboard}
        copyPhone={pageHelpers.copyToClipboard}
        copyInvoiceNo={pageHelpers.copyToClipboard}
        tooltipTarget={invSheetTooltipTarget}
        openTooltip={openInvSheetTooltip}
        toolTipPlacement={invSheetToolTipPlacement}
        copyText={invSheetTooltipCopyText}
        showInvoiceSheetTooltip={showInvoiceSheetTooltip}
        invSheetCopyIconMouseOut={this.invSheetCopyIconMouseOut}
        // mouseOver={this.invSheetMouseOver}
        invoiceSheetMouseOver={this.invoiceSheetMouseOver}
        invoiceSheetMouseOut={this.invoiceSheetMouseOut}
        invoiceSheetCopyIconMouseover={this.invoiceSheetCopyIconMouseover}
        isLiveView={isLiveView}
        decimalPrecision={decimalPrecision}
        showReasonForUpdate={pageTitle === appConstants.EDIT_INVOICE}
        reasonForUpdatingInvoice={reasonForChange}
        creditNoteRequestStatus={creditNoteRequestStatus}
        creditNoteReference={creditNoteReference}
      />
    );
  };

  renderPreviewView = (showPreview) => {
    let prevElem = [];
    let count = 0;
    const { requesting } = this.props;
    if (showPreview) {
      prevElem.push(
        <FullScreenModal
          show={showPreview}
          key={count + 1}
          callback={this.hidePreviewView}
          zIndex={requesting ? 10 : 999}
        >
          {this.renderInvoiceView()}
        </FullScreenModal>
      );
      return prevElem;
    }
    console.log(prevElem, "preview");
  };

  /**
   * @description Create customer on customer dropdown
   */
  renderAddNewCustomer = () => {
    let nodeElems = [];
    let count = 0;
    const {
      showAddNewCustomer,
      newContactFirstName,
      newContactLastName,
      newCustomerName,
      newCustomerEmail,
      newCustomerPhoneNo,
      newCustomerAddress,
      similarCustomersList,
    } = this.state;

    nodeElems.push(
      <FullScreenModal
        show={showAddNewCustomer}
        key={count + 1}
        callback={this.hideAddNewCustomer}
        zIndex={10}
        width={"35%"}
      >
        <AddNewCustomer
          newContactFirstName={newContactFirstName}
          newContactLastName={newContactLastName}
          newCustomerName={newCustomerName}
          newCustomerEmail={newCustomerEmail}
          newCustomerPhoneNo={newCustomerPhoneNo}
          newCustomerAddress={newCustomerAddress}
          handleChange={this.handleChange}
          closeAddNewCustomerModal={this.hideAddNewCustomer}
          similarCustomerCheck={this.similarCustomerCheck}
          similarCustomersList={similarCustomersList}
        />
      </FullScreenModal>
    );
    return nodeElems;
  };

  renderSimilarCustomer = () => {
    let nodeElems = [];
    let count = 0;
    const { requesting } = this.props;
    const { showSimilarCustomer, similarCustomersList } = this.state;

    nodeElems.push(
      <FullScreenModal
        show={showSimilarCustomer}
        key={count + 1}
        callback={this.hideSimilarCustomer}
        zIndex={20}
        width={"35%"}
      >
        <SimilarCustomer
          requesting={requesting}
          addCustomer={this.addCustomer}
          closeSimilarCustomerModal={this.hideSimilarCustomer}
          selectCustomer={this.selectCustomer}
          similarData={similarCustomersList}
        />
      </FullScreenModal>
    );
    return nodeElems;
  };

  declineCancelInvoice = () => {
    const { dispatch } = this.props;
    appHelpers.enableScroll();
    dispatch(modalActions.displayDialogAlert({ show: false }));
    this.setState({ showDiscardModal: false });
  };

  discardInvoice = () => {
    appHelpers.enableScroll();
    const { dispatch } = this.props;
    dispatch(modalActions.displayDialogAlert({ show: false }));
    dispatch(modalActions.displayInvoiceSheet({ show: false }));
    this.setState({
      rrrNumber: "",
      name: "",
      invoiceNumber: "",
      billDate: new Date(),
      address: "",
      invoiceItems: [
        {
          description: "",
          quantity: 1,
          rate: 0,
          amount: 0,
        },
      ],
      totalAmount: 0,
      customer: "",
      location: "",
      phoneNumber: "",
      revenue: "",
      subRevenue: "",
      email: "",
      currency: { id: 566, name: "Naira", code: "NGN" },
    });
  };

  //clear input fields on close of created invoice
  clearInputFields = () => {
    this.setState({
      revenue: "",
      subRevenue: "",
      customer: "",
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      location: "",
      currency: { id: 566, name: "Naira", code: "NGN" },
      rrrNumber: "",
      totalAmount: 0,
      invoiceNumber: "",
      invoiceItems: [
        {
          description: "",
          quantity: 1,
          rate: 0,
          amount: 0,
        },
      ],
      revenueGroup: "",
      isLocationMapping: false,
      disableRate: false,
      reasonForChange: "",
      billDate: new Date(),
      footerNote:"",
    });
  };

  selectCustomer = (item) => {
    this.setState({
      customer: item,
      name: item.customerName,
      email: item.email,
      phoneNumber: item.phone,
      customerAIN: item.customerAIN,
      address: item.address,
    });
    this.hideSimilarCustomer();
    this.hideAddNewCustomer();
  };

  /* Modal Callbacks */
  cancelDialog = () => {
    const { dispatch } = this.props;

    appHelpers.enableScroll();
    dispatch(
      modalActions.displayDialogAlert({
        show: false,
        message: "",
        declineText: "",
        approveText: "",
      })
    );
    this.setState({ showDialog: false });
  };

  approveDialog = () => {
    const { dispatch } = this.props;

    this.createInvoice(false);
    dispatch(
      modalActions.displayDialogAlert({
        show: false,
        message: "",
        declineText: "",
        approveText: "",
      })
    );
    this.setState({ showDialog: false });
  };

  displayCreatedInvoiceSheet = () => {
    const { displayGeneralAlert, dispatch } = this.props;

    if (displayGeneralAlert?.message) {
      if (displayGeneralAlert?.message.includes("successfully")) {
        dispatch(modalActions.displayInvoiceSheet({ show: false }));
        dispatch(modalActions.displayCreatedInvoice({ show: true }));
      }
    }
  };

  showInvoiceSheet = () => {
    if (this.validateInput()) {
      this.props.dispatch(modalActions.displayInvoiceSheet({ show: true }));
    }
  };

  hidePreviewView = () => {
    this.setState({ showPreview: false });
  };

  openAddNewCustomerModal = () => {
    appHelpers.enableScroll();
    this.setState({ showAddNewCustomer: true });
  };

  hideAddNewCustomer = () => {
    appHelpers.enableScroll();
    this.setState({ showAddNewCustomer: false });
    this.resetCreateCustomerForm();
  };

  showSimilarCustomer = () => {
    appHelpers.enableScroll();
    this.setState({ showSimilarCustomer: true });
  };

  hideSimilarCustomer = () => {
    appHelpers.enableScroll();
    this.setState({ showSimilarCustomer: false });
  };

  dismissModals = () => {
    appHelpers.enableScroll();
    setTimeout(() => {
      this.props.dispatch(modalActions.displayGeneralAlert({ show: false }));
      this.props.dispatch(modalActions.displayInvoiceSheet({ show: false }));
      this.setState({
        showPreview: false,
      });
    }, 5000);
  };

  invSheetCopyIconMouseOut = (tooltip) => {
    this.closeTooltip(tooltip);
  };

  toggleTooltip = (tooltip) => {
    this.setState({ [tooltip]: true });
  };

  closeTooltip = (tooltip) => {
    this.setState({ [tooltip]: false, showInvoiceSheetTooltip: false });
  };

  invoiceSheetMouseOver = (e) => {
    const target = e.currentTarget.lastElementChild;
    target.classList.remove("inv-copy-wrapper-hide");
    target.classList.add("inv-copy-wrapper-show");
  };

  invoiceSheetMouseOut = (e) => {
    const target = e.currentTarget.lastElementChild;
    target.classList.add("inv-copy-wrapper-hide");
    target.classList.remove("inv-copy-wrapper-show");
  };

  invoiceSheetCopyIconMouseover = (e, tooltip, placement, copyText) => {
    let id = e.currentTarget.id;
    const element = e.currentTarget.parentElement;
    if (element.classList.contains("inv-copy-wrapper-show")) {
      this.setState(
        {
          invSheetTooltipTarget: id,
          invSheetToolTipPlacement: placement,
          invSheetTooltipCopyText: copyText,
          showInvoiceSheetTooltip: true,
        },
        () => {
          this.toggleTooltip(tooltip);
        }
      );
    }
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    this.setState({
      showPreview: false,
    });
    dispatch(modalActions.displayGeneralAlert({ show: false, message: "" }));
    dispatch(modalActions.displayInvoiceSheet({ show: false, message: "" }));
    dispatch(modalActions.displayShareInvoiceModal({ show: false }));
    dispatch(modalActions.displayCreatedInvoice({ show: false }));
    dispatch(modalActions.displayDialogAlert({ show: false, message: "" }));

    if (source) {
      source.cancel();
    }
  }

  render() {
    console.log(this.props.apiReducer?.currencies, "money");
    console.log(this.state.footerOptions,  "footer");

    const {
      revenues,
      subRevenue,
      subRevenues,
      customers,
      locations,
      currencies,
      revenue,
      location,
      customer,
      currency,
      name,
      email,
      address,
      revenueGroups,
      revenueGroup,
      isRequesting,
      invoiceItems,
      isLiveView,
      rrrNumber,
      invoiceNumber,
      billDate,
      totalAmount,
      phoneNumber,
      componentErrorMessages,
      showPreview,
      showDiscardModal,
      showDialog,
      logo,
      showAddNewCustomer,
      showSimilarCustomer,
      revenueGroupTag,
      invoiceSheetActions,
      createdInvoiceActions,
      decimalPrecision,
      disableRate,
      isLocationMapping,
      pageTitle,
      reasonForChange,
      invoiceToEditDetails,
      organizationId,
      footerNote,
      footerOptions
    } = this.state;
    const { user, alertComponentsReducer, requesting, dispatch } = this.props;
    const dialogAlert = alertComponentsReducer.dialogAlert;
    const shareInvoiceModal = alertComponentsReducer.shareInvoiceModal;
    const displayInvoiceSheet = alertComponentsReducer.displayInvoiceSheet;
    const displayCreatedInvoice = alertComponentsReducer.displayCreatedInvoice;
console.log(user, "whatuser");
    let printInvDate =
      billDate !== null || undefined
        ? moment(billDate).format("YYYY-MM-DD")
        : "";
    let printDate = moment().format("YYYY-MM-DD");
console.log(this.state.totalAmount, "AMOUNTCRE");
    return (
      <div>
        <ContentLayout
          requesting={requesting}
          pageTitle={pageTitle}
          actions={
            <CreateInvoiceActionButtons
              showPreview={showPreview}
              isLiveView={isLiveView}
              isShowPreview={this.isShowPreview}
              toggleLiveView={this.toggleLiveView}
            />
          }
        >
          <SearchPanel
            isLiveView={isLiveView}
            componentErrorMessages={componentErrorMessages}
            revenues={revenues}
            revenue={revenue}
            subRevenues={subRevenues}
            subRevenue={subRevenue}
            customers={customers}
            customer={customer}
            billDate={billDate}
            name={name}
            email={email}
            phoneNumber={phoneNumber}
            address={address}
            currencies={currencies}
            currency={currency}
            rrrNumber={rrrNumber === null ? "" : rrrNumber}
            handleChange={this.handleChange}
            location={location}
            locations={locations}
            revenueGroupTag={revenueGroupTag}
            revenueGroups={revenueGroups === null ? "": revenueGroups}
            revenueGroup={revenueGroup}
            invoiceItems={invoiceItems}
            removeInvoiceItem={this.removeInvoiceItem}
            handleItemChange={this.handleItemChange}
            handleFocus={this.handleFocus}
            handleBlur={this.handleBlur}
            addInvoiceItemFields={this.addInvoiceItemFields}
            showInvoiceSheet={this.showInvoiceSheet}
            isRequesting={isRequesting}
            renderInvoiceView={this.renderInvoiceView()}
            handleSelectChange={this.handleSelectChange}
            openAddNewCustomerModal={this.openAddNewCustomerModal}
            changeDatePicker={this.changeDatePicker}
            cancelInvoice={this.clearInputFields}
            decimalPrecision={decimalPrecision}
            disableRate={disableRate}
            isLocationMapping={isLocationMapping}
            action={
              pageTitle === appConstants.CREATE_INVOICE
                ? "Create invoice"
                : "Update Invoice"
            }
            requestingText={
              pageTitle === appConstants.CREATE_INVOICE
                ? "Creating invoice..."
                : "Updating Invoice..."
            }
            displayReasonForChange={pageTitle === appConstants.EDIT_INVOICE}
            reasonForChange={reasonForChange}
            pageTitle={pageTitle}
            editableFields={
              invoiceToEditDetails && invoiceToEditDetails.editableFields
            }
            invoiceStatus={
              invoiceToEditDetails && invoiceToEditDetails.invoice.status
            }
            footerNote={footerNote}
            footerOptions={footerOptions}
          />

          <div id="print-panel" style={{ display: "none" }}>
            <PrintInvoice
              invoiceNumber={invoiceNumber}
              clientName={name}
              paymentStatus={"Unpaid"}
              invoiceDate={printInvDate}
              issuerName={user.fullName}
              printDate={printDate}
              bills={invoiceItems}
              rrr={rrrNumber}
              amountString={totalAmount}
              // logo={logo}
              logo={organizationId === 3918 || organizationId === 5087 || organizationId === 19946 ? revenueGroup?.logoUrl : logo}
              currencySign={
                currency.id === 840 ? (
                  <span>&#36;</span>
                ) : currency.id === 978 ? (
                  <span>&#163;</span>
                ) : (
                  <span>&#8358;</span>
                )
              }
              decimalPrecision={decimalPrecision}
            />
          </div>

          {displayInvoiceSheet.show && (
            <DisplayInvoiceFullScreenModal
              displayInvoiceSheet={displayInvoiceSheet}
              invoiceSheetActions={invoiceSheetActions}
              requesting={requesting}
              dispatch={dispatch}
              renderInvoiceView={this.renderInvoiceView()}
            />
          )}
          {this.props.displayGeneralAlert.show && <GeneralAlert />}

          {dialogAlert.show && (
            <DialogFullScreenModal
              displayDialogAlert={dialogAlert}
              dispatch={this.props.dispatch}
              element={<p>{dialogAlert.message}</p>}
              declineClicked={
                showDiscardModal
                  ? this.declineCancelInvoice
                  : showDialog
                  ? this.cancelDialog
                  : ""
              }
              buttonDeclineText={dialogAlert.declineText}
              approveClicked={
                showDiscardModal
                  ? this.discardInvoice
                  : showDialog
                  ? this.approveDialog
                  : ""
              }
              buttonApproveText={dialogAlert.approveText}
              requesting={requesting}
            />
          )}
          {showPreview === true && this.renderPreviewView(showPreview)}
          {displayCreatedInvoice.show && (
            <CreatedInvoiceFullScreenModal
              displayCreatedInvoice={displayCreatedInvoice}
              invoiceSheetActions={
                pageTitle === appConstants.CREATE_INVOICE
                  ? createdInvoiceActions
                  : null
              }
              requesting={requesting}
              dispatch={dispatch}
              renderInvoiceView={this.renderInvoiceView()}
              clearInputFields={this.clearInputFields}
            />
          )}
          {shareInvoiceModal.show && (
            <ShareInvoiceFullScreenModal
              displayShareInvoice={shareInvoiceModal}
              dispatch={this.props.dispatch}
              handleShareEmailChange={this.handleShareEmailChange}
              shareInvoice={this.shareInvoice}
              onkeydownEnterShareInvoice={this.onkeydownEnterShareInvoice}
              isRequesting={this.props.isRequesting}
              requesting={requesting}
            />
          )}
          {showAddNewCustomer && this.renderAddNewCustomer()}
          {showSimilarCustomer && this.renderSimilarCustomer()}
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user,
    requesting,
    settings,
    alertComponentsReducer,
    displayGeneralAlert,
    apiReducer,
  } = state;
  return {
    user,
    requesting,
    settings,
    alertComponentsReducer,
    displayGeneralAlert,
    apiReducer,
  };
};

export default connect(mapStateToProps)(CreateInvoice);
