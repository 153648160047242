import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";
import ContentLayout from "../../../components/ContentLayout";
import { FaArrowLeft, FaCheckCircle, FaTimes } from "react-icons/fa";
import { appHelpers } from "../../../_helpers";
import FullScreenModal from "../../../components/FullScreenModal";
import { commonService } from "../../../_services/common.service";
import { appConstants } from "../../../_constants/app.constants";
import { connect } from "react-redux";
import { alertActions } from "../../../_actions";
import "./index.scss";
import TableStructure from "../workflow-management/map-record";
import SearchPanel from "./search-panel";
import UpdateUserCard from "../../../components/UpdateUserCard";
import { RenderAlertModal } from "../../../components/AlertModal";
import axios from "axios";

let source;

class UserMapping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapUser: false,
      pageTitle: "User Mapping",
      showModalView: false,
      showUserProfile: false,
      data: [],
      userDetails: [],
      name: "",
      role: "",
      displayTable: false,
      workFlowLevels: [],
      workFlowProcess: [],
      supervisorList: [],
      supervisor: "",
      process: "",
      selectedWorkflowDetails: null,
      addLevel: false,
      showAlertModal: false,
      componentErrorMessages: [],
      disableProcess: false,
      selectedWorkflowId: false,
      alertIcon: "",
      alertMessage: "",
      mappedUserId: null,
      selectedLevel: [],
      selectedLevelSupervisor: "",
      workflowName: "",
      disableWorkflowName: false,
      updateWorkflowName: "",
      disableUpdateWorkflowName: false,
      noMappingFound: false, // used to determine if the record coming from workflow management has workflow or not
    };

    source = axios.CancelToken.source();
  }

  componentWillMount() {
    let stateFromPath = this.props.history.location.state;
    if (stateFromPath) {
      let userDetails = stateFromPath.userDetails;
      let workFlowLevels = stateFromPath.workFlowLevels;
      let selectedWorkflowDetails = stateFromPath.selectedWorkflowDetails;
  

      if (userDetails) {
        this.setState(
          {
            workFlowLevels: workFlowLevels,
            name: userDetails.name,
            role: userDetails.roleName,
            userDetails: userDetails,
            mappedUserId: userDetails.userId,
          },
          () => {
            this.getWorkFlowProcess();
            this.getSupervisors(userDetails.agencyId); //this is called here because thats after the state gets updated
          }
        );
      } else if (workFlowLevels === null) {
        this.setState(
          {
            noMappingFound: true,
            name: selectedWorkflowDetails.name,
            role: selectedWorkflowDetails.roleName,
            disableProcess: true,
            process: selectedWorkflowDetails.process,
            addLevel: true,
            selectedWorkflowId: selectedWorkflowDetails.id,
            mappedUserId: selectedWorkflowDetails.userId,
            workflowName: selectedWorkflowDetails.name,
            disableWorkflowName: true,
            selectedWorkflowDetails,
            // }
          },
          () => {
            this.getWorkFlowProcess();
            this.getSupervisors(selectedWorkflowDetails.agencyId);
          }
        );
      } else if (workFlowLevels !== null) {
        this.setState(
          {
            workFlowLevels: workFlowLevels,
            name: selectedWorkflowDetails.name,
            role: selectedWorkflowDetails.roleName,
            disableProcess: true,
            process: selectedWorkflowDetails.process,
            mappedUserId: workFlowLevels[0].personId,
            addLevel: true,
            workflowName: selectedWorkflowDetails.name,
            disableWorkflowName: true,
            selectedWorkflowId: selectedWorkflowDetails.id,
            selectedWorkflowDetails,
            // }
          },
          () => {
            this.getWorkFlowProcess();
            this.getSupervisors(selectedWorkflowDetails.agencyId);
            if (workFlowLevels.length > 0) {
              this.getWorkFlowLevels(workFlowLevels[0]); // get current workflow levels on page reload
            }
          }
        );
      }
    }
    // this.getWorkFlowProcess();
  }

  getWorkFlowProcess = () => {
    const { dispatch } = this.props;
    const { selectedWorkflowDetails } = this.state;

    dispatch(alertActions.startRequest());

    commonService.getWorkFlowProcess(source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        response.data !== null &&
          this.setState({ workFlowProcess: response.data }, () => {
            if (selectedWorkflowDetails) {
              this.setState({
                process: appHelpers.getProcess(
                  this.state.workFlowProcess,
                  selectedWorkflowDetails.process
                ),
              });
            }
          });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (response === "Please Check your internet connectivity") {
          dispatch(alertActions.error(response));
        }
      }
    });
  };

  createWorkFlow = () => {
    const { dispatch, user } = this.props;
    const {
      supervisor,
      process,
      selectedWorkflowId,
      mappedUserId,
      workflowName,
    } = this.state;

    if (this.validateInput()) {
      const payload = {
        name: workflowName, // On create invoice for the first time pass the name inputted by the user as this name.
        agencyId: user.organizationId,
        process: process.name,
        userId: mappedUserId,
        supervisorId: supervisor.userId,
      };

      if (selectedWorkflowId) {
        payload.id = selectedWorkflowId;
      }

      dispatch(alertActions.startRequest());

      commonService.createWorkFlow(payload, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
              ),
              alertMessage: response.message,
            },
            () => appHelpers.dismissModal.call(this, "showAlertModal")
          );
          //construct workflow object to be pass to getWorkfloLevels endpoint
          let workflowObj = {
            workflowId: response.id,
          };
          //fetch workflow levels to populate the grid after new workflow has been successfully created
          this.getWorkFlowLevels(workflowObj);
          if (!selectedWorkflowId) {
            //if user did not updating/edit workflow
            this.resetFields();
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (typeof response === "string") {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response,
              },
              () => appHelpers.dismissModal.call(this, "showAlertModal")
            );
          } else {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response.message,
              },
              () => appHelpers.dismissModal.call(this, "showAlertModal")
            );
          }
        }
      });
    }
  };

  addWorkFlowLevel = () => {
    const { dispatch } = this.props;
    const {
      workFlowLevels,
      supervisor,
      selectedWorkflowId,
      noMappingFound,
    } = this.state;

    const payload = {
      id: 0,
      workflowLevel: 0,
      supervisorId: supervisor.userId,
      workflowId: noMappingFound
        ? selectedWorkflowId
        : workFlowLevels[0].workflowId,
    };

    if (this.validateInput()) {
      dispatch(alertActions.startRequest());

      commonService.addWorkFlowLevels(payload, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
              ),
              alertMessage: response.message,
            },
            () => {
              let workflowId = noMappingFound
                ? { workflowId: selectedWorkflowId }
                : workFlowLevels[0];
              this.getWorkFlowLevels(workflowId);
              appHelpers.dismissModal.call(this, "showAlertModal");
            }
          );
          // if (!selectedWorkflowId) { //if user did not updating/editing workflow
          //     this.resetFields();
          // }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (typeof response === "string") {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response,
              },
              () => {
                this.getWorkFlowLevels(workFlowLevels[0]);
                appHelpers.dismissModal.call(this, "showAlertModal");
              }
            );
          } else {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response.message,
              },
              () => {
                this.getWorkFlowLevels(workFlowLevels[0]);
                appHelpers.dismissModal.call(this, "showAlertModal");
              }
            );
          }
        }
      });
    }
  };

  updateWorkFlowLevel = () => {
    const { dispatch } = this.props;
    const { workFlowLevels, supervisor, selectedLevel } = this.state;

    const payload = {
      id: selectedLevel.id,
      workflowId: selectedLevel.workflowId,
      workflowLevel: selectedLevel.workFlowLevel,
      supervisorId: supervisor.userId,
    };

    if (this.validateInput()) {
      dispatch(alertActions.startRequest());

      commonService.updateWorkFlowLevel(payload, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
              ),
              alertMessage: response.message,
            },
            () => {
              this.getWorkFlowLevels(workFlowLevels[0]);
              appHelpers.dismissModal.call(this, "showAlertModal");
            }
          );
          // if (!selectedWorkflowId) { //if user did not updating/editing workflow
          //     this.resetFields();
          // }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (typeof response === "string") {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response,
              },
              () => {
                this.getWorkFlowLevels(workFlowLevels[0]);
                appHelpers.dismissModal.call(this, "showAlertModal");
              }
            );
          } else {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response.message,
              },
              () => {
                this.getWorkFlowLevels(workFlowLevels[0]);
                appHelpers.dismissModal.call(this, "showAlertModal");
              }
            );
          }
        }
      });
    }
  };

  getSupervisors = (agencyId) => {
    const { dispatch } = this.props;
    // const {userDetails} = this.state;

    const payload = {
      agencyId: agencyId,
    };

    dispatch(alertActions.startRequest());

    commonService.getSupervisors(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        response.data !== null &&
          this.setState({ supervisorList: response.data });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (response === "Please Check your internet connectivity") {
          dispatch(alertActions.error(response));
        }
      }
    });
  };

  handleSelectChange = (payload, attribute) => {
    let currState = this.state;
    // let errorMessages = this.state.componentErrorMessages;
    // errorMessages[attribute] = false;
    // currState[attribute] = (payload) ? payload[attribute] : ''; //Cater for setting up data and clearing data
    if (!payload) {
      currState[attribute] = "";
    } else {
      if (attribute === "process") {
        currState[attribute] = payload;
      } else if (attribute === "supervisor") {
        currState[attribute] = payload;
      } else {
        currState[attribute] = payload.Id;
      }
    }
    this.setState({ currState });
  };

  hideModal = () => {
    this.setState({ showModalView: false, supervisor: "" });
  };

  isShowModal = (e, item) => {
    const { supervisorList } = this.state;
    this.setState({
      showModalView: true,
      supervisor: appHelpers.getSupervisor(supervisorList, item.personId),
      selectedLevel: item,
    });
  };

  renderModalView = () => {
    let modal = [];
    let count = 0;
    const {
      showModalView,
      name,
      roleName,
      supervisorList,
      supervisor,
    } = this.state;

    modal.push(
      <FullScreenModal
        show={showModalView}
        key={count + 1}
        callback={this.hideModal}
        width={"37%"}
        zIndex={10}
      >
        <UpdateUserCard
          modalTitle={"Update User Map"}
          name={name}
          role={roleName}
          labelOne={`Map ${name} to`}
          updateClick={this.updateWorkFlowLevel}
          updateText={"Update"}
          cancelText={"Cancel"}
          cancelClick={() =>
            appHelpers.cancelModal.call(this, "showModalView", "supervisor")
          }
          labelTitle={`Map ${name} to`}
          supervisorList={supervisorList}
          supervisor={supervisor}
          selectChange={this.handleSelectChange}
        />
      </FullScreenModal>
    );
    return modal;
  };

  getWorkFlowLevels = (item) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());

    commonService
      .getWorkFlowLevels(item.workflowId, source.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        // let workFlowLevels = {...response.data};

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({
            workFlowLevels: response.data,
          });
          if (!response.data) {
            this.setState({ noMappingFound: true });
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (typeof response === "string") {
            dispatch(alertActions.error(response));
            this.setState({ alertMessage: response, workFlowLevels: [] });
          } else {
            // dispatch(alertActions.error(response.message));
            this.setState(
              { alertMessage: response ? response.message : "" },
              () => {
                if (response.data === null) {
                  this.setState({ workFlowLevels: [] });
                }
              }
            );
          }
        }
      });
  };

  deleteWorkFlow = (item) => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());
    commonService.deleteWorkFlowLevel(item.id, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState(
          {
            showAlertModal: true,
            alertIcon: (
              <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
            ),
            alertMessage: response.message,
          },
          () => {
            this.getWorkFlowLevels(item);
            appHelpers.dismissModal.call(this, "showAlertModal");
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        // this.searchWorkflow();
        if (typeof response === "string") {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaTimes size={100} color={appConstants.DANGER_COLOR} />
              ),
              alertMessage: response,
            },
            () => {
              this.getWorkFlowLevels(item);
              appHelpers.dismissModal.call(this, "showAlertModal");
            }
          );
        } else {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaCheckCircle size={100} color={appConstants.DANGER_COLOR} />
              ),
              alertMessage: response.message,
            },
            () => {
              this.getWorkFlowLevels(item);
              appHelpers.dismissModal.call(this, "showAlertModal");
            }
          );
        }
      }
    });
  };

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  resetFields = () => {
    !this.state.disableProcess && this.setState({ process: "" });
    this.setState({
      supervisor: "",
    });
  };

  validateInput = () => {
    let _errs_ = [];
    let formValid = true;
    if (this.state.process === "") {
      _errs_["process"] = "Please select a workflow process";
      formValid = false;
    }
    if (this.state.supervisor === "") {
      _errs_["supervisor"] = "Please select a supervisor";
      formValid = false;
    }
    if (this.state.workflowName === "") {
      _errs_["workflowName"] = "Please input a workflow name";
      formValid = false;
    }
    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      showModalView,
      name,
      role,
      workFlowLevels,
      supervisorList,
      supervisor,
      workFlowProcess,
      process,
      addLevel,
      componentErrorMessages,
      disableProcess,
      alertIcon,
      showAlertModal,
      alertMessage,
      workflowName,
      disableWorkflowName,
    } = this.state;
    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle={"User Mapping"}
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className=" big-action-btn btn-rounded mr-2"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <SearchPanel
          name={name}
          role={role}
          supervisorList={supervisorList}
          supervisor={supervisor}
          ProcessList={workFlowProcess}
          process={process}
          disableProcess={disableProcess}
          addLevel={addLevel}
          selectChange={this.handleSelectChange}
          createWorkFlow={
            addLevel ? this.addWorkFlowLevel : this.createWorkFlow
          }
          componentErrorMessages={componentErrorMessages}
          workflowName={workflowName}
          handleChange={this.handleChange}
          disableWorkflowName={disableWorkflowName}
        />
        {workFlowLevels && workFlowLevels.length > 0 && (
          <Row>
            <Col md={"12"} className={"mapping-spacing"}>
              <TableStructure
                tableData={workFlowLevels}
                isShowModal={this.isShowModal}
                deleteWorkFlow={this.deleteWorkFlow}
                process={process ? process.name : null}
              />
            </Col>
          </Row>
        )}
        {showModalView === true && this.renderModalView()}

        {showAlertModal && (
          <RenderAlertModal
            message={alertMessage}
            icon={alertIcon}
            showAlertModal={showAlertModal}
            hideAlertView={this.hideAlertView}
          />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, alert, progress, user, requesting } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(UserMapping);
