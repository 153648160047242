import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const AddNewCustomer = ({
  newContactFirstName,
  newContactLastName,
  newContactName,
  newCustomerName,
  newCustomerEmail,
  newCustomerPhoneNo,
  newCustomerAddress,
  handleChange,
  requesting,
  similarCustomerCheck,
  closeAddNewCustomerModal,
}) => {
  return (
    <div className={"Add-new-customer"}>
      <Card className="card" style={{ borderRadius: "10px", height: "50%" }}>
        <CardBody className={"card-body"}>
          <CardTitle className={"custom-card-title"}>
            Add New Customer
          </CardTitle>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  value={newCustomerName}
                  newCustomerName="newCustomerName"
                  onChange={(e) => handleChange(e, "newCustomerName")}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Contact Email</Label>
                <Input
                  type="email"
                  value={newCustomerEmail}
                  newCustomerName="newCustomerEmail"
                  onChange={(e) => handleChange(e, "newCustomerEmail")}
                />
              </FormGroup>
            </Col>
            
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Contact First Name</Label>
                <Input
                  type="text"
                  value={newContactFirstName}
                  newContactFirstName="newContactFirstName"
                  onChange={(e) => handleChange(e, "newContactFirstName")}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Contact Last Name</Label>
                <Input
                  type="text"
                  value={newContactLastName}
                  newContactLastName="newContactLastName"
                  onChange={(e) => handleChange(e, "newContactLastName")}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Contact Phone Number</Label>
                <Input
                  type="text"
                  value={newCustomerPhoneNo}
                  newCustomerName="newCustomerPhoneNo"
                  onChange={(e) => handleChange(e, "newCustomerPhoneNo")}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Address</Label>
                <Input
                  type="text"
                  value={newCustomerAddress}
                  newCustomerName="newCustomerAddress"
                  onChange={(e) => handleChange(e, "newCustomerAddress")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <div className={"pl-3 py-4 float-left"}>
              <Button
                color="success"
                size="sm"
                className="btn-rounded mr-2 text-white py-1 px-3"
                disabled={requesting}
                onClick={() => similarCustomerCheck()}
              >
                Add customer
              </Button>
              <Button
                outline
                className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
                disabled={requesting}
                onClick={closeAddNewCustomerModal}
              >
                Cancel
              </Button>
            </div>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddNewCustomer;
