import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import moment from "moment";
import { FaArrowLeft } from "react-icons/fa";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import { commonService } from "../../../_services";
import { alertActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import { appHelpers } from "../../../_helpers";
import TableStructure from "./record";
import axios from "axios";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import SearchPanel from "./search-panel";
import RequestCard from "./request-card";

let source;
const presentDate = new Date();

class RemitaFailureReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      startDate: moment().subtract(1, "month"),
      endDate: moment(presentDate, "YYYY-MM-DD"),
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      remitaFailureRecord: [],
      totalAmountChannel: 0,
      totalAmountRemita: 0,
      totalAmountProcessedBda: 0,
      organizationId: 0,
      percentageFailure: 0,
    };

    source = axios.CancelToken.source();
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   */
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;

    if (pathState) {
      if (
        props.location.state.selectedAgencyDetails !==
        state.selectedAgencyDetails
      ) {
        return {
          agencyDetails: props.location.state.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    this.routeUserBasedOnRole();
  }

  /**
   * @description Route the user based on the role of the logged in user
   */
  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { agencyDetails } = this.state;

    //get route the user is coming from
    let previousPath = this.props.history.location.state.from;

    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    //Everytime a user with a VGG Role vists this page, the user must select an agency
    //agencyDetails===undefined prevents redirect from happening again once an agency is selected
    if (vggAdminRole && agencyDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is already selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationName: agencyDetails
            ? agencyDetails.name
            : user.organization,
          organizationId: vggAdminRole
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
        },
        () => {
          this.getAllRemitaChannel();
          this.getAllNotificationType();
          this.searchRemitaFailureReport();
        }
      );
    }
  };

  getAllRemitaChannel = () => {
    let { dispatch } = this.props;
    // dispatch(alertActions.startRequest());
    commonService.getAllRemitaChannel(source.token).then((res) => {
      // dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        response.data !== null && this.setState({ channels: response.data });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getAllNotificationType = () => {
    let { dispatch } = this.props;
    // dispatch(alertActions.startRequest());
    commonService.getAllNotificationType(source.token).then((res) => {
      // dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        response.data !== null &&
          this.setState({ notifications: response.data });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  searchRemitaFailureReport = (pageNumber = 0) => {
    const { dispatch } = this.props;
    const { endDate, startDate, pageSize, channel, notification } = this.state;

    var nextPageNUmber = 0;
    if (typeof pageNumber === "number") {
      nextPageNUmber = pageNumber;
    }
    let payload = {
      pageSize: pageSize,
      from: nextPageNUmber,
      parameter: {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        remitaChannel: channel ? channel.id : 0,
        remitaNotificationType: notification ? notification.id : 0,
      },
    };

    dispatch(alertActions.startRequest());
    commonService.remitaFailureReport(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          remitaFailureRecord: response.data.resultList,
          totalCount: response.data.totalCount,
          currentPage: pageNumber === 0 ? 1 : pageNumber,
          totalAmountProcessedBda: response.bdaAmount,
          totalAmountRemita: response.remitaAmount,
          totalAmountChannel: response.unprocessedAmount,
          percentageFailure: response.percentageFailure,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.data.errors));
        }
      }
    });
  };
  clearFields = () => {
    this.setState({
      takeRecord: [],
    });
  };

  //Alert Notifications

  /*HANDLERS */
  handleSelectChange = (payload, attribute) => {
    this.setState({ [attribute]: payload });
  };

  //   handleChange = (e) => {
  //     this.setState({ [e.target.name]: e.target.value });
  //   };

  changeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }
  render() {
    const {
      startDate,
      endDate,
      percentageFailure,
      currentPage,
      pageSize,
      totalCount,
      isRequesting,
      isVggRole,
      searchQuery,
      remitaFailureRecord,
      totalAmountChannel,
      totalAmountRemita,
      totalAmountProcessedBda,
      channels,
      channel,
      notifications,
      notification,
    } = this.state;
    return (
      <div>
        <ContentLayout
          requesting={this.props.requesting}
          pageTitle={"Manage Remita Failure Report"}
          actions={
            isVggRole && (
              <div className={"float-right"}>
                <Button
                  outline
                  color="info"
                  className=" btn-rounded mr-2 big-action-btn"
                  onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
                >
                  <FaArrowLeft className={"icon-margin"} />
                  Back
                </Button>
              </div>
            )
          }
        >
          <Row>
            <Col md="6" lg="3">
              <RequestCard
                label="Total Amount Processed on BDA"
                requestDetails={
                  <span>
                    {" "}
                    &#8358;{" "}
                    {appHelpers.numberWithCommasOnly(totalAmountProcessedBda)}
                  </span>
                }
                borderColor="#1354D3"
                // getCardDetails={this.updateTable.bind(this, 0)}
              />
            </Col>

            <Col md="6" lg="3">
              <RequestCard
                label="Total Amount From Remita"
                requestDetails={
                  <span>
                    {" "}
                    &#8358; {appHelpers.numberWithCommasOnly(totalAmountRemita)}
                  </span>
                }
                borderColor="#13676D"
                // getCardDetails={this.updateTable.bind(this, 1)}
              />
            </Col>
            <Col md="6" lg="3">
              <RequestCard
                label="Total Amount on Channel and notification not processed"
                requestDetails={
                  <span>
                    {" "}
                    &#8358;{" "}
                    {appHelpers.numberWithCommasOnly(totalAmountChannel)}
                  </span>
                }
                borderColor="#FFC31A"
                // getCardDetails={this.updateTable.bind(this, 2)}
              />
            </Col>
            <Col md="6" lg="3">
              <RequestCard
                label="Percentage of failure"
                requestDetails={percentageFailure}
                borderColor="#07345B"
                // getCardDetails={this.updateTable.bind(this, 2)}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5px" }}>
            <SearchPanel
              placeholder="Search"
              onSearch={this.searchRemitaFailureReport}
              startDate={startDate}
              endDate={endDate}
              channels={channels}
              notifications={notifications}
              changeDatePicker={this.changeDatePicker}
              selectChange={this.handleSelectChange}
            />
          </Row>
          <br />

          {remitaFailureRecord.length === 0 && (
            <EmptyRecordMessage message={"No record match your search"} />
          )}
          {remitaFailureRecord.length > 0 && (
            <Row style={{ marginTop: "-24px" }}>
              <Col md={"12"}>
                <TableStructure
                  currentPage={currentPage}
                  fetchMoreRecords={this.searchRemitaFailureReport}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  tableData={remitaFailureRecord}
                />
              </Col>
            </Row>
          )}
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, alert } = state;
  return {
    user,
    requesting,
    alert,
  };
};
export default connect(mapStateToProps)(RemitaFailureReport);
