import { apiConstants } from "../_constants";

const INIT_STATE = {
  currencies: null,
  revenuesByOrganization: null,
  locationsByOrganization: null,
  revenues: null,
  subRevenues: null,
  locations: null,
  banks: null,
  accountTypes: null,
  bankAccounts: null,
  searchAccountsResponse: null,
  createdAccountMessage: null,
  settlementAccountRevenues: null,
  settlementAccountLocations: null,
  activateDeactivateMessage: "",
  customers: null,
  invoiceSettings: null, //used to get the configured invoice settings for an agency
  invoiceChanges: null, //used to get old and updated invoices in manageworkflow approval view
  invoiceChangesForSearch: null, //used to get old and updated invoices in search invoice view
  creditTypes: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case apiConstants.CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.currencies,
      };
    case apiConstants.REVENUES_SUCCESS:
      return {
        ...state,
        revenuesByOrganization: action.revenues,
      };
    case apiConstants.SUB_REVENUES_SUCCESS:
      return {
        ...state,
        subRevenues: action.subRevenues,
      };
    case apiConstants.LOCATIONS_SUCCESS:
      return {
        ...state,
        locationsByOrganization: action.locations,
      };
    case apiConstants.REVENUE_LOCATION_SUCCESS:
      return {
        ...state,
        revenues: action.revenueLocationData.revenues,
        locations: action.revenueLocationData.locations,
      };
    case apiConstants.CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: action.customers,
      };
    case apiConstants.FETCH_BANKS_SUCCESS:
      return {
        ...state,
        banks: action.banks,
      };
    case apiConstants.FETCH_ACCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        accountTypes: action.accountTypes,
      };
    case apiConstants.FETCH_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        bankAccounts: action.saveBankAccountsPayload.response.results,
        searchAccountsResponse: {
          currentPage:
            action.saveBankAccountsPayload.pageNumber === 0
              ? 1
              : action.saveBankAccountsPayload.pageNumber,
          totalCount: action.saveBankAccountsPayload.response.totalCount,
        },
      };
    case apiConstants.CREATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        createdAccountMessage: action.message,
      };
    case apiConstants.ACCOUNT_REVENUE_LOCATION_SUCCESS:
      return {
        ...state,
        settlementAccountRevenues: action.accountRevenueLocationData.revenues,
        settlementAccountLocations: action.accountRevenueLocationData.locations,
      };
    case apiConstants.ACTIVATE_DEACTIVATE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        removedAccountsObj: {
          message: action.message,
          emptyCheckedRecords: true,
        },
      };
    case apiConstants.INVOICE_SETTINGS_SUCCESS:
      return {
        ...state,
        invoiceSettings: action.invoiceSettings,
      };
    case apiConstants.INVOICE_CHANGES_SUCCESS:
      return {
        ...state,
        invoiceChanges: action.invoiceChanges,
      };
    case apiConstants.INVOICE_CHANGES_FOR_SEARCH_SUCCESS:
      return {
        ...state,
        invoiceChangesForSearch: action.invoiceChangesForSearch,
      };
      case apiConstants.CREDITNOTE_TYPE:
        return {
          ...state,
          creditTypes: action.creditTypes,
        };
    default:
      return state;
  }
};
