import React, {Component} from 'react';

class Support extends Component {
    render() {
        return (
            <div>
                just a support page
            </div>
        );
    }
}

export default Support;