import React, { Component } from "react";
import SearchPanel from "./search-panel";
import { Button, Row, Col } from "reactstrap";
import ContentLayout from "../../../components/ContentLayout";
import { FaArrowLeft } from "react-icons/fa";
import ReportCard from "./report-card";
import { connect } from "react-redux";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { commonService } from "../../../_services";
import { alertActions, apiActions, modalActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import { appHelpers} from "../../../_helpers";
import TableStructure from "./record";
import axios from "axios";
import moment from "moment";
import "./index.scss";
import GeneralAlert from "../../../components/Api-response-alert";


let source;
const presentDate = new Date();


class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      tableData: [],
      creditTypes: [],
      creditType: "",
      totalRequest: 0,
      approvedRequest: 0,
      pendingRequest: 0,
      creditNoteValue: 0,
      selectedCard: 0,
      startDate: moment().subtract(1, "month"), // This keeps the start date from a month ago
      endDate: moment(presentDate, "YYYY-MM-DD"), //End date by default will always be current date
      selectedStatus: "",
      invoiceModalData: false,
      shareEmail: "",
      clickedRow: false,
      // actions: [
      //   {
      //     name: "Share",
      //     icon: "fa-upload",
      //     action: this.handleShareAction,
      //     width: "6.5rem",
      //   },
      //   {
      //     name: "Download",
      //     icon: "fa-download",
      //     action: () => this.downloadInvoice(false),
      //     width: "8rem",
      //   },
      // ],
    }
    source = axios.CancelToken.source();
  }

  componentDidMount() {
    const body = document.body;
    body.style.overflow = "scroll";
    this.fetchInitialReferenceData();
    this.searchReport();

  }

  fetchInitialReferenceData = () => {
    const { apiReducer } = this.props;
    const { creditTypes } = apiReducer;

    if (!creditTypes) {
      this.getAllCreditNoteType();
    } else {
      this.setState({ creditTypes: creditTypes });
    }
  };

  componentDidUpdate(prevProps) {
    const { apiReducer} = this.props;
    if (apiReducer.creditTypes !== prevProps.apiReducer.creditTypes) {
      this.setState({
        creditTypes: apiReducer.creditTypes,
      });
    }
  }
  getAllCreditNoteType = () => {
    return this.props.dispatch(apiActions.getAllCreditNoteType(source.token));

  }
  //  handler
 
  handleShareEmailChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  // api for search

  searchReport = (pageNumber = 0) => {
    const { dispatch, user } = this.props;
    const { pageSize, startDate, endDate, searchQuery, creditType, selectedStatus } = this.state;
    var nextPageNUmber = 0;
    if (typeof pageNumber === "number") {
      nextPageNUmber = pageNumber;
    }

    let payload = {
      pageSize: pageSize,
      from: nextPageNUmber,
      parameter: {
        startDate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59",
        agencyId: user.organizationId,
        searchText: searchQuery,
        creditNoteType: creditType.id,
        requestStatus: selectedStatus,
      },
    };

    dispatch(alertActions.startRequest());
    commonService.searchCreditNote(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          // totalCount: response.totalCount,
          totalCount: selectedStatus === 0 ? response.totalPendingCount : selectedStatus === 1 ? response.totalApprovedCount : response.totalCount,
          tableData: response.results,
          pendingRequest: response.totalPendingCount,
          totalRequest: response.totalCount,
          approvedRequest: response.totalApprovedCount,
          creditNoteValue: response.totalApprovedCreditAmountString,
          currentPage: pageNumber === 0 ? 1 : pageNumber,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.message));
        }
      }
    });
  };

  downloadSearchInvoice = (type) => {
    const { dispatch, user } = this.props;
    const {startDate, endDate, selectedStatus,searchQuery,creditType}= this.state;
    let payload = {
      parameter: {
        pageSize: -1,
        downloadFormat: type,
        agencyId: user.organizationId,
        startDate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
        endDate: moment(endDate).format("YYYY-MM-DD") + "T23:00:00.000Z",
        requestStatus: selectedStatus,
        searchText: searchQuery,
        creditNoteType: creditType.id,
      },
    };
    dispatch(alertActions.startRequest());
    commonService.downloadSearchReport(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.success(response.message));
        appHelpers.downloadFile(response.responseType.downloadUrl, "Invoices");
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.message));
        }
        this.setState({ requestingPayments: false });
      }
    });
  };

  downloadInvoice = (e, item) => {
    e.persist();
    const { dispatch } = this.props;
    let payload = { invoiceNumber: item.invoiceNumber };
    dispatch(alertActions.startRequest());
    commonService.downloadInvoice(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());

      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.success(response.message));
        // appHelpers.downloadFile(response.downloadUrl, "_blank");
        window.open(response.downloadUrl, "_blank");
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.message));
        }
        this.setState({ requestingPayments: false });
      }
    });
  };

  handleSelectChange = (payload, attribute) => {
    let currState = this.state;
    if (!payload) {
      currState[attribute] = "";
    } else {
      if (attribute === "creditType") {
        currState[attribute] = payload;
      }
    }
    this.setState({ currState });
  };

  updateTable = id => {
    switch (id) {
      case 0:
        this.setState({ selectedCard: 0, selectedStatus: "" }, () => {
          this.searchReport();
        });
        break;
      case 1:
        this.setState({ selectedCard: 1, selectedStatus: 1 }, () => {
          this.searchReport();
        });
        break;
      case 2:
        this.setState({ selectedCard: 2, selectedStatus: 0 }, () => {
          this.searchReport();
        });
        break;
      default:
        this.setState({ selectedCard: 0, selectedStatus: "" });
        break;
    }
  };

  searchBarChange = (event) => {
    this.setState({ [event.target.name]: event.target.value },
    );
  };

  search = () => {
    // e.preventDefault();
    this.searchReport();
  };

  showInvoice = (e, item) => {
    e.persist();
    this.setState({
      invoiceModalData: item,
      invoiceNumber: item.invoiceNumber,
    },
      () => {
        if (e.target.nodeName !== "path") {
          this.props.dispatch(
            modalActions.displayCreatedInvoice({ show: true })
          );
        }
      }
    );
  };

  handleShareAction = () => {
    this.props.dispatch(
      modalActions.displayShareInvoiceModal({
        show: true,
      })
    );
  };

  onkeydownEnterShareInvoice = (e) => {
    if (e.key === "Enter") {
      this.shareInvoice();
    }
  };
  componentWillUnmount() {
    const { dispatch } = this.props;
   dispatch(modalActions.displayGeneralAlert({ show: false }));

    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      totalCount,
      currentPage,
      pageSize,
      searchQuery,
      tableData,
      creditTypes,
      startDate,
      endDate,
      pendingRequest,
      approvedRequest,
      creditNoteValue,
      totalRequest,
    } = this.state;

    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle={"Report"}
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className=" btn-rounded mr-2 big-action-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <Row>
          <SearchPanel
            placeholder="Search"
            value={searchQuery}
            onSearch={this.searchReport}
            onChange={this.searchBarChange}
            creditTypes={creditTypes}
            startDate={startDate}
            endDate={endDate}
            changeDatePicker={this.changeDatePicker}
            selectChange={this.handleSelectChange}
          />
        </Row>
        <br />
        <Row>
          <Col md="6" lg="3">
            <ReportCard
              label="Total request"
              requestDetails={totalRequest}
              borderColor="#1354D3"
              getCardDetails={this.updateTable.bind(this, 0)}
            />
          </Col>
          <Col md="6" lg="3">
            <ReportCard
              label="Approved"
              requestDetails={approvedRequest}
              borderColor="#13676D"
              getCardDetails={this.updateTable.bind(this, 1)}
            />
          </Col>
          <Col md="6" lg="3">
            <ReportCard
              label="Pending"
              requestDetails={pendingRequest}
              borderColor="#FFC31A"
              getCardDetails={this.updateTable.bind(this, 2)}
            />
          </Col>
          <Col md="6" lg="3">
            <ReportCard
              label="Credit Note Value"
              requestDetails={creditNoteValue}
              borderColor="#FFC31A"
            />
          </Col>
        </Row>
        {tableData.length === 0 && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}
        {tableData.length > 0 && (
          <Row style={{ marginTop: "50px" }}>
            <Col md={"12"}>
              <TableStructure
                currentPage={currentPage}
                fetchMoreRecords={this.searchReport}
                pageSize={pageSize}
                totalCount={totalCount}
                tableData={tableData}
                selectedCard={this.state.selectedCard}
                downloadInvoice={this.downloadInvoice}
                downloadSearchInvoice={this.downloadSearchInvoice}
              />
            </Col>
          </Row>
        )}
      
        {this.props.displayGeneralAlert.show && <GeneralAlert />}

      </ContentLayout>
    );
  }
}


const mapStateToProps = (state) => {
  const { user, requesting, alert, creditTypes, apiReducer, alertComponentsReducer, displayGeneralAlert } = state;
  return {
    user,
    requesting,
    alert,
    creditTypes,
    apiReducer,
    alertComponentsReducer,
    displayGeneralAlert,
  };
};
export default connect(mapStateToProps)(Report);