import React, { Component } from "react";
import { Row, Table } from "reactstrap";

import ContentLayout from "../../../components/ContentLayout";
import BackButton from "../../../components/BackButton";
import { appHelpers } from "../../../_helpers";
import { connect } from "react-redux";
import { appConstants } from "../../../_constants";
import GeneralAlert from "../../../components/Api-response-alert";
import { modalActions, commonActions } from "../../../_actions";

class AppliedCreditNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appliedCreditNotes: [],
    };
  }
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;
    if (pathState) {
      if (pathState.selectedAgencyDetails !== state.selectedAgencyDetails) {
        return {
          organizationDetails: pathState.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { organizationDetails } = this.state;

    //get route the user is coming from
    let previousPath;
    if (this.props.history.location.state) {
      previousPath = this.props.history.location.state.from;
    }

    //set Role related state
    let vggAdminRole =
      user.vggRole === appConstants.ROLES.VGG_ADMIN ||
      user.vggRole === appConstants.ROLES.VGG_USER;

    //Everytime a user with a VGG Role vists this page, the user must select an organization
    //organizationDetails===undefined prevents redirect from happening again once an organization is selected

    if (vggAdminRole && organizationDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationId: vggAdminRole
            ? organizationDetails && organizationDetails.id
            : user.organizationId,
        },
        () => this.fetchInitialReferenceData()
      );
    }
  };

  componentDidMount() {
    const body = document.body;
    body.style.overflow = "scroll";

    this.setState({ logo: this.props.settings.setLogo });
    this.props.dispatch(commonActions.setPeriodPickerVisibility(false));

    let pathState = this.props.location.state;
    let { appliedCreditNotes, message } = pathState;
    if (message) {
      this.props.dispatch(
        modalActions.displayGeneralAlert({
          show: true,
          message,
          action: "success",
        })
      );
    }

    if (pathState) {
      this.setState({ pathState });

      if (appliedCreditNotes) {
        this.setState({ appliedCreditNotes: appliedCreditNotes });
      }
    }
    this.routeUserBasedOnRole();
  }

  fetchInitialReferenceData = () => {};

  componentDidUpdate(prevProps) {
    if (this.props.settings.setLogo !== prevProps.settings.setLogo) {
      this.setState({
        logo: this.props.settings.setLogo,
      });
    }
  }

  render() {
    const { appliedCreditNotes } = this.state;
    const { requesting } = this.props;

    return (
      <ContentLayout
        pageTitle={`Waivers and Discount`}
        requesting={requesting}
        actions={
          <BackButton
            navigateBack={() =>
              appHelpers.navigateRoute.call(this, "/create-credit-note")
            }
          />
        }
      >
        <Row className=" mb-4 ml-1">
          <h2>
            <b>Applied Credit Note</b>
          </h2>
        </Row>
        <Table responsive={true} className={"table-main"}>
          <thead className={"th-head tracker-head"}>
            <tr>
              <th>Invoice No</th>
              <th>Customer</th>
              <th>Old Amount</th>
              <th>New Amount</th>
              <th>Credit Note</th>
              <th>Discount</th>
              <th>Credit Note Status</th>
            </tr>
          </thead>
          <tbody>
            {appliedCreditNotes.map((item, index) => {
              return (
                <tr key={index}>
                  <th>{item.invoiceNumber}</th>
                  <th>{item.customer}</th>
                  <th> {item.invoiceAmount}</th>
                  <th>{item.newInvoiceAmount}</th>
                  <th>{item.creditAmount}</th>
                  <th>{item.invoiceAmountPercentage}</th>
                  <th>
                    {item.requestStatusName ? item.requestStatusName : "Nil"}
                  </th>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, displayGeneralAlert, settings } = state;
  return {
    user,
    requesting,
    displayGeneralAlert,
    settings,
  };
};

export default connect(mapStateToProps)(AppliedCreditNote);
