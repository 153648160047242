import { appConstants } from "../_constants";
import moment from "moment/moment";


// const startDate = moment().startOf('month').format('YYYY-MM-DD');
// const endDate = moment().format("YYYY-MM-DD");

const startDate = moment().startOf('month').format('YYYY-MM-DD');
const endDate =  moment().format("YYYY-MM-DD");
let currentMonth = {
  startDate,
  endDate
}
const initialState = {
  picker: currentMonth,
  type:"THIS "+appConstants.THISMONTH
}
export function dashboard(state = initialState, action) {


  switch (action.type) {
    case appConstants.PERIOD_PICKER_SELECTED:
      return {
        ...state,
        picker: action.value,
        type:action.val
      };

    default:
      return state;
  }
}
