import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import "./invoice-items.scss";
import { appHelpers } from "../../_helpers/app.helpers";
import { pageHelpers } from "../../_helpers";
const InvoiceItems = ({
  invoiceItems,
  removeInvoiceItem,
  handleItemChange,
  handleFocus,
  handleBlur,
  addInvoiceItemFields,
  componentErrorMessages,
  isCustomer,
  disableRate,
  editableFields,
  pageTitle,
  invoiceStatus,
}) => {
  const isPaidInvoice = invoiceStatus && invoiceStatus.toLowerCase() === "paid";
  const isPartiallyPaidInvoice =
    invoiceStatus && invoiceStatus.toLowerCase() === "partial payment";
  console.log(invoiceItems, "items");

  return (
    <div className={"invoice-items"}>
      <h2 className="bold mt-5">Items</h2>
      <Card>
        <CardHeader className={"invoice-items__header"}>
          <Row className="font-bold">
            <Col sm="5"> Item Description</Col>
            <Col sm="2"> Quantity/Weight</Col>
            <Col sm="2"> Rate</Col>
            <Col sm="2"> Amount</Col>
            <Col sm="1"> </Col>
          </Row>
        </CardHeader>

        <CardBody className={"invoice-items__header invoice-items__body"}>
          {invoiceItems.map((item, index) => {
            console.log("amount", item.amount);
            console.log(
              "The is the 4 decimal places  ===> ",
              parseFloat(item.amount).toFixed(4)
            );

            //amount 3445.9424999999997

            const amount = parseFloat(item.amount).toFixed(4); // ===>  3445.9425

            console.log(
              "3 decimal places ===>  ",
              parseFloat(amount).toFixed(3) //===>   3445.943
            );
            return (
              <Row key={index}>
                <Col sm="5">
                  <FormGroup>
                    <Input
                      type="text"
                      name="description"
                      id="item-description"
                      value={item.description}
                      onChange={handleItemChange(index)}
                      disabled={pageHelpers.disableField(
                        editableFields,
                        "ItemDescription",
                        pageTitle
                      )}
                    />
                    {componentErrorMessages[index] &&
                      componentErrorMessages[index].description && (
                        <span className={"inputError"}>
                          {componentErrorMessages[index].description}
                        </span>
                      )}
                  </FormGroup>
                </Col>
                <Col sm="2">
                  <FormGroup>
                    <Input
                      type="number"
                      name="quantity"
                      id="item-quantity"
                      min={1}
                      value={item.quantity}
                      onChange={handleItemChange(index)}
                      onFocus={handleFocus(index)}
                      onBlur={handleBlur(index)}
                      disabled={
                        pageHelpers.disableField(
                          editableFields,
                          "ItemQuantity",
                          pageTitle
                        ) ||
                        isPaidInvoice ||
                        isPartiallyPaidInvoice
                      }
                    />
                    {componentErrorMessages[index] &&
                      componentErrorMessages[index].quantity && (
                        <span className={"inputError"}>
                          {componentErrorMessages[index].quantity}
                        </span>
                      )}
                  </FormGroup>
                </Col>
                <Col sm="2">
                  <FormGroup>
                    <Input
                      type="number"
                      name="rate"
                      id="item-rate"
                      value={item.rate}
                      onChange={handleItemChange(index)}
                      onFocus={handleFocus(index)}
                      onBlur={handleBlur(index)}
                      disabled={
                        isCustomer ||
                        disableRate ||
                        pageHelpers.disableField(
                          editableFields,
                          "ItemRate",
                          pageTitle
                        ) ||
                        isPaidInvoice ||
                        isPartiallyPaidInvoice
                      }
                    />
                    {componentErrorMessages[index] &&
                      componentErrorMessages[index].rate && (
                        <span className={"inputError"}>
                          {componentErrorMessages[index].rate}
                        </span>
                      )}
                  </FormGroup>
                </Col>
                <Col sm="2">
                  <FormGroup>
                    <Input
                      disabled
                      type="text"
                      name="amount"
                      id="item-amount"
                      // value={appHelpers.numberWithCommasOnly(item.amount, 2)}
                      value={appHelpers.numberWithCommasOnly(item.amount, 2)}
                      // onChange={this.handleItemChange(index)}
                      //   disabled={pageHelpers.disableField(
                      //   editableFields,
                      //   "Amount",
                      //   pageTitle
                      // )}
                    />
                  </FormGroup>
                </Col>
                <Col sm="1">
                  <FormGroup>
                    <h4
                      className="center-block pointer-cursor"
                      onClick={removeInvoiceItem(index)}
                    >
                      <i className="fa fa-minus-circle pr-2 "></i>
                    </h4>
                  </FormGroup>
                </Col>
              </Row>
            );
          })}

          <h5 onClick={addInvoiceItemFields} className={"pointer-cursor"}>
            <i className="fa fa-plus-circle pr-2"></i>Add another item
          </h5>
        </CardBody>
      </Card>
    </div>
  );
};

export default InvoiceItems;
