import React, { Component } from "react";
import { Row, Col, Label } from "reactstrap";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import TableStructure from "./agency-table";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import FilterParameters from "./filter-parameters";
import { commonService } from "../../../_services";
import { alertActions, commonActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import {appHelpers} from "../../../_helpers";

class SelectAgency extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      name: "",
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
    };
  }

  componentDidMount() {
    this.searchOrganizations();
  }

  /**
   * @param {Object} selectedAgencyDetails
   */
  handleSelectAgency = (selectedAgencyDetails) => {
    const {dispatch} = this.props;
    
    let payload = {
      agencyId: selectedAgencyDetails.id,
      agencyCode: selectedAgencyDetails.code,
      domainURL: appHelpers.getDomainOrigin(),
    };
  
    dispatch(commonActions.getBranding(payload));
    /**
     * @var {String} previousPath describes the path the user is coming from
     * the route before landing on this route (/select-agency)
     */
    
    let previousPath = this.props.history.location.state.from;
    this.props.history.push({
      pathname: previousPath,
      state: { selectedAgencyDetails },
    });
    
    
  };

  searchOrganizations = (pageNumber = 1) => {
    let { dispatch } = this.props;
    let { pageSize, name } = this.state;

    let payload = {
      pageSize,
      from: pageNumber,
      parameter: {
        name,
      },
    };

    dispatch(alertActions.startRequest());
    commonService.searchOrganizations(payload).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          currentPage: pageNumber === 0 ? 1 : pageNumber,
          organizations: response.results,
          totalCount: response.totalCount,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.setState({
          organizations: [],
          totalCount: 0,
        });
      }
    });
  };

  handleChange = (event, attribute) => {
    this.setState({ [attribute]: event.target.value });
  };

  render() {
    const {
      organizations,
      name,
      currentPage,
      pageSize,
      totalCount,
    } = this.state;
    const { requesting } = this.props;

    return (
      <ContentLayout pageTitle="Select Organization" requesting={requesting}>
        <Row>
          <FilterParameters
            name={name}
            searchOrganizations={this.searchOrganizations}
            requesting={requesting}
            handleChange={this.handleChange}
          />
        </Row>

        {organizations.length === 0 && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}

        {organizations.length > 0 && (
          <>
            <Label className="ml-2">{totalCount} Results</Label>
            <Row>
              <Col md={"12"}>
                <TableStructure
                  tableData={organizations}
                  handleSelectAgency={this.handleSelectAgency}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  fetchMoreRecords={this.searchOrganizations}
                />
              </Col>
            </Row>
          </>
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting } = state;
  return {
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(SelectAgency);
