import React from 'react';
import {Row, Col, Label, FormGroup, Input} from 'reactstrap';
import SelectList from "../../../components/SelectList/selectList";

const MerchantDetails = ({website, organizations, orgName,  industry,
                             componentErrorMessages, orgCode, selectChange, handleChange}) => {
    return (
        <div>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label for="state"> Organization Name </Label>
                        <SelectList
                            placeholder={'Select role...'}
                            data={organizations}
                            valueKey={'id'}
                            textField={'name'}
                            selectValue={orgName}
                            disabled
                            onValueChange={(event) => {selectChange(event, 'orgName')}}/>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for="state"> Website </Label>
                        <Input
                            type="text"
                            name="website"
                            id="website"
                            value={website}
                            onChange={handleChange}
                            defaultValue=""
                            className={"search-inputs"}
                        />
                    </FormGroup>
                    {componentErrorMessages.website && <span className={"inputError"}>{componentErrorMessages.website}</span>}
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label for="status">Organization Industry </Label>
                        <Input
                            type="text"
                            name="industry"
                            id="industry"
                            value={industry}
                            onChange={handleChange}
                            defaultValue=""
                            className={"search-inputs"}
                        />
                    </FormGroup>
                    {componentErrorMessages.industry && <span className={"inputError"}>{componentErrorMessages.industry}</span>}
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label for="state"> Organization Code </Label>
                        <Input
                            type="text"
                            name="orgCode"
                            id="orgCode"
                            value={orgCode}
                            disabled
                            onChange={handleChange}
                            defaultValue=""
                            className={"search-inputs"}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
};

export default MerchantDetails;