import React, { Fragment } from "react";
import "./_index.scss";
import Select, { components } from "react-select";

const menuHeaderStyle = {
  padding: "8px 12px"
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 0
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: 200
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.43,
    color: "var(--greyish)"
  })
};

// solution fix input not focus in custom menu
// onMouseDown
const Menu = ({ actionText, innerProps, ...restProps }) => {
  return (
    <Fragment>
      <components.Menu
        style={menuHeaderStyle}
        innerProps={{
          ...innerProps
          // onMouseDown: () => console.log(restProps.selectValue, restProps),
          // onChange: () => console.log("changed")
        }}
        {...restProps}
      >
        {restProps.children}
        <span
          className={"add-new"}
          onClick={restProps.selectProps.actionClicked}
        >
         {restProps.selectProps.actionText}
        </span>
      </components.Menu>
    </Fragment>
  );
};

const CustomizedMenuSelect = ({
  data,
  valueKey,
  textField,
  selectValue,
  onValueChange,
  multiSelectMode,
  placeholder,
  disabled,
  clearable,
  actionText,
  actionClicked,
}) => (
  <Select
    placeholder={placeholder}
    options={data}
    components={{ Menu }}
    styles={customStyles}
    onChange={onValueChange}
    value={selectValue}
    getOptionLabel={(data) => data[textField]}
    getOptionValue={(data) => data[valueKey]}
    clearable={clearable}
    isDisabled={disabled}
    // menuIsOpen
    className={"select-list"}
    classNamePrefix={"react-select"}
    actionText={actionText}
    actionClicked={actionClicked}
  />
);

export default CustomizedMenuSelect;