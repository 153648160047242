import React from "react";
import {Button} from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";

 const BackButton = ({navigateBack}) => {
    return (
        <div className={"float-right"}>
        <Button
          color="info"
          outline
          className="btn-rounded mr-2 big-action-btn"
          onClick={navigateBack}
        >
          <FaArrowLeft className={"mb-1 mr-1"} /> Back
        </Button>
      </div>
    )
}

export default BackButton;
