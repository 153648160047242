import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function displayGeneralAlert(state = initialState.displayGeneralAlert, action) {
  
  switch (action.type) {
    case appConstants.DISPLAY_GENERAL_ALERT:
      return action.payload;
    default:
      return state;
  }
}