import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function selectedLocationIds(state = initialState.selectedLocationIds, action) {
  
  switch (action.type) {
    case appConstants.SAVE_SELECTED_LOCATION_IDS:
      return action.selectedIds;
    default:
      return state;
  }
}
