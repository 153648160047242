import React, { Component } from "react";
import ContentLayout from "../../../components/ContentLayout";
import AccountRecords from "./record";
import { connect } from "react-redux";
import { appConstants } from "../../../_constants";
import EmptyState from "../../../components/EmptyState";
import AddRecordButton from "../../../components/AddRecordButton";
import { appHelpers } from "../../../_helpers";
import { alertActions, apiActions } from "../../../_actions";
import GeneralAlert from "../../../components/Api-response-alert";
import SearchBar from "../../../components/SearchBar";
import { Button, Col, Row } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import axios from "axios";

let source;

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountRecords: [],
      totalCount: 0,
      pageSize: 10,
      organizationName: null,
      agencyDetails: undefined,
      checkedRecords: [],
      displayAddButton: false,
      displayDisableButton: false,
      searchQuery: "",
      isVggRole: false,
    };

    source = axios.CancelToken.source();
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   */
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;

    if (pathState) {
      if (
        props.location.state.selectedAgencyDetails !==
        state.selectedAgencyDetails
      ) {
        return {
          agencyDetails: props.location.state.selectedAgencyDetails,
        };
      }
      //this check happens after an account has been created/updated successfully
      if (
        (pathState.from === "/review-account-info" ||
          pathState.from === "/add-new-bank-account") &&
        pathState.agencyDetails
      ) {
        return { agencyDetails: pathState.agencyDetails };
      }
    }

    return null;
  }

  componentDidMount() {
    this.routeUserBasedOnRole();
  }

  fetchSetupData() {
    const { apiReducer, dispatch } = this.props;
    const { bankAccounts, searchAccountsResponse } = apiReducer;
    const { agencyDetails } = this.state;

    if (!bankAccounts || agencyDetails) {
      this.getAccounts();
    }
    // if (agencyDetails) {
    //   this.getAccounts();
    // }
    if (bankAccounts) {
      this.setState({ accountRecords: bankAccounts });
    }
    if (searchAccountsResponse) {
      this.setState({
        currentPage: searchAccountsResponse.currentPage,
        totalCount: searchAccountsResponse.totalCount,
      });
    }

    if (apiReducer.createdAccountMessage) {
      dispatch(alertActions.success(apiReducer.createdAccountMessage));
    }
  }

  /**
   * @description Route the user based on the role of the logged in user
   */
  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { agencyDetails } = this.state;

    //get route the user is coming from
    let previousPath = this.props.history.location.state.from;

    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    //Everytime a user with a VGG Role vists this page, the user must select an agency
    //agencyDetails===undefined prevents redirect from happening again once an agency is selected
    if (vggAdminRole && agencyDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is already selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationName: agencyDetails
            ? agencyDetails.name
            : user.organization,
          agencyId: vggAdminRole
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
        },
        () => this.fetchSetupData()
      );
    }
  };

  componentDidUpdate(prevProps) {
    const { apiReducer } = this.props;

    if (apiReducer.bankAccounts !== prevProps.apiReducer.bankAccounts) {
      this.setState({
        accountRecords: apiReducer.bankAccounts,
      });
    }

    // update currentPage and totalCount from the response gotten from search settlement account
    if (
      apiReducer.searchAccountsResponse !==
      prevProps.apiReducer.searchAccountsResponse
    ) {
      this.setState({
        currentPage: apiReducer.searchAccountsResponse.currentPage,
        totalCount: apiReducer.searchAccountsResponse.totalCount,
      });
    }
  }

  getAccounts = (pageNumber = 0) => {
    const { dispatch } = this.props;
    const { searchQuery, pageSize, agencyId } = this.state;

    const payload = {
      pageSize: pageSize,
      from: pageNumber === 1 || pageNumber === 0 ? 0 : pageNumber - 1,
      parameter: {
        searchQuery: searchQuery,
        agencyId,
      },
    };

    dispatch(apiActions.searchSettlementAccount(payload, pageNumber, source.token));
  };

  handleSwitchChecked = (e, item) => {
    const { dispatch } = this.props;
    const { agencyId, pageSize } = this.state;

    let payload = {
      accountId: item.accountId,
    };

    const searchAccountPayload = {
      pageSize: pageSize,
      from: 0,
      parameter: {
        searchQuery: "",
        agencyId,
      },
    };

    if (e.target.checked === true) {
      dispatch(
        apiActions.activateSettlementAccount(payload, searchAccountPayload)
      );
    }
    if (e.target.checked === false) {
      dispatch(
        apiActions.deactivateSettlementAccount(payload, searchAccountPayload)
      );
    }
  };

  editAccount = (e, item) => {
    e.persist();
    const { agencyDetails } = this.state;

    let record = {
      accountId: item.accountId,
      accountNumber: item.accountNumber,
      accountName: item.accountName,
      accountType: item.accountType,
      agencyId: item.agencyId,
      bankValue: item.bankId,
      currencyValue: item.currencyId,
      isPrimaryAccount: item.isPrimaryAccount,
      status: item.status,
      alias: item.alias,
    };

    const pathState = {
      pageTitle: appConstants.EDIT_ACCOUNT,
      record,
      agencyDetails: agencyDetails,
      action: "edit",
      from: "/settings-bank-account",
    };

    if (e.target.classList.contains("edit-accounts")) {
      appHelpers.navigateRoute.call(this, "/add-new-bank-account", pathState);
    }
  };

  searchBarChange = (e) => {
    this.setState({ searchQuery: e.target.value }, () => {
      if (this.state.searchQuery === "") {
        this.getAccounts();
      }
    });
  };

  search = (e) => {
    e.preventDefault();
    this.getAccounts();
  };

  navigateToAddNewAccount = () => {
    const { agencyDetails } = this.state;

    const pathState = {
      pageTitle: appConstants.ADD_NEW_ACCOUNT,
      agencyDetails: agencyDetails,
    };
    appHelpers.navigateRoute.call(this, "/add-new-bank-account", pathState);
  };

  navigateToReviewAccount = (e, item) => {
    const { agencyDetails } = this.state;

    let record = {
      accountId: item.accountId,
      accountNumber: item.accountNumber,
      accountName: item.accountName,
      accountType: item.accountType,
      agencyId: item.agencyId,
      bankValue: item.bankId,
      currencyValue: item.currencyId,
      isPrimaryAccount: item.isPrimaryAccount,
      status: item.status,
      alias: item.alias,
    };

    const reviewPagePathState = {
      from: "/settings-bank-account",
      pageTitle: appConstants.ADD_NEW_ACCOUNT,
      agencyDetails: agencyDetails,
      record,
      action: "view",
    };

    if (e.target.parentElement.className === "table-row") {
      appHelpers.navigateRoute.call(
        this,
        "/review-account-info",
        reviewPagePathState
      );
    }
  };

  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
  }

  render() {
    const {
      accountRecords,
      pageSize,
      agencyDetails,
      searchQuery,
      totalCount,
      isVggRole,
    } = this.state;
    const { user, requesting } = this.props;

    return (
      <ContentLayout
        pageTitle={"Bank Accounts"}
        requesting={requesting}
        actions={
          isVggRole && (
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          )
        }
      >
        {accountRecords.length < 1 && searchQuery === "" && (
          <EmptyState
            message={"You do not have any accounts yet"}
            actionText={"Add new account"}
            addRecord={() => this.navigateToAddNewAccount()}
          />
        )}
        {(accountRecords.length >= 1 || searchQuery !== "") && (
          <>
            <div
              className={"d-flex justify-content-between align-items-center"}
            >
              <h4>
                {agencyDetails && agencyDetails.name
                  ? agencyDetails.name
                  : null}
              </h4>
              <AddRecordButton
                actionText={"Add new account"}
                addRecord={this.navigateToAddNewAccount}
              />
            </div>
            <Row className={"settings-spacing"}>
              <Col sm={{ size: 6 }} className="text-center">
                <SearchBar
                  placeholder="Search accounts"
                  onChange={this.searchBarChange}
                  value={searchQuery}
                  showSearchButton
                  search={this.search}
                />
              </Col>
            </Row>
            <div>
              <AccountRecords
                accountRecords={accountRecords}
                user={user}
                totalCount={totalCount}
                pageSize={pageSize}
                editAccount={this.editAccount}
                handleSwitchChecked={this.handleSwitchChecked}
                showReviewAccountPage={this.navigateToReviewAccount}
              />
            </div>
          </>
        )}
        {accountRecords.length === 0 && searchQuery !== "" && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, apiReducer, displayGeneralAlert } = state;
  return {
    user,
    requesting,
    apiReducer,
    displayGeneralAlert,
  };
};

export default connect(mapStateToProps)(Accounts);
