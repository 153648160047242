import React from "react";
import "./index.scss";
import { alertActions, modalActions } from "../../_actions";
// import logodarktext from "../../assets/images/ebips-assets/ebips-logo.svg";
import { appConstants } from "../../_constants";
import { commonService } from "../../_services";
import { connect } from "react-redux";
import EPIBSLogo from "../../assets/images/ebips-assets/ebips-logo.svg";
import { appHelpers } from "../../_helpers";
import GeneralAlert from "../../components/Api-response-alert";

class ActivateUserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newUser: "",
      showAlertModal: false,
      message: false,
      domainURL: "",
    };
  }

  componentDidMount() {
    this.props.dispatch(alertActions.error("hello"));
    this.confirmNewUSer();
    appHelpers.getBrandingFromDomainURL.call(this);
  }

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  confirmNewUSer = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());

    // http://bdaweb.test.vggdev.com/activate?userToken=CjEqF7t72NuFFzLfy4vhN3vwnARs2ZpNYlxB+PAVFRtfdV7EMju2WSpLuDhUKFdZ

    const queryArray = window.location.href.split("=");
    const userToken = queryArray[1];

    const payload = {
      userToken: userToken,
    };

    dispatch(alertActions.startRequest());
    commonService.confirmNewUser(payload).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({ message: response.message }, () => {
          this.redirect();
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        typeof response === "string"
          ? dispatch(
              modalActions.displayGeneralAlert({
                show: true,
                message: response,
                action: "failure",
              })
            )
          : dispatch(
              modalActions.displayGeneralAlert({
                show: true,
                message: response.message,
                action: "failure",
              })
            );
      }
    });
  };

  redirect = () => {
    setTimeout(() => {
      this.props.history.push("/login");
    }, 7000);
  };

  render() {
    const { message } = this.state;
    const { userData } = this.props;

    let logo = userData.logoUrl ? userData.logoUrl : EPIBSLogo;
    return (
      <div className={"backgrd"}>
        <div className={"logo-wrapper"}>
          <img
            src={logo}
            className="colStructure activate-logo"
            alt="homepage"
          />
        </div>
        {message && (
          <div className={"activate-message"}>
            <p>
              {message}.{" "}
              <span>
                Please check your email address for your login details
              </span>
            </p>
            <p>
              Redirecting <i className="fa fa-spinner fa-spin fa-2x"></i>
            </p>
          </div>
        )}
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, user, requesting, userData, displayGeneralAlert } = state;
  return {
    alert,
    user,
    requesting,
    userData,
    displayGeneralAlert,
  };
}

export default connect(mapStateToProps)(ActivateUserPage);
