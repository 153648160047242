import React from "react";
import { Row, Input, Button } from "reactstrap";
import "./fmsUpload.css";
import ContentLayout from "../../components/ContentLayout";
import TableFormat from "./fmsTable";
import { alertActions, modalActions } from "../../_actions";
import { commonService } from "../../_services/common.service";
import { appConstants } from "../../_constants/app.constants";
import { connect } from "react-redux";
import Alert from "../../components/Alert";
import { appHelpers } from "../../_helpers";
import { FaArrowLeft, FaTimes, FaCheckCircle } from "react-icons/fa";
import FileUploadIcon from '../../assets/images/icons/file-upload.svg'
import FullScreenModal from "../../components/FullScreenModal";



class NIBBSPaymentUpload extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      replaceRRR: null,
      tableData: [],
      file: null,
      takeRecord: [],
      count: 0,
      viewDialogOpen: false,
      showAlertModal: false,
      alertIcon: "",
      alertMessage: "",
      componentErrorMessages: [],
    };

  }

  componentDidMount() {

  }

  handleRadio = (e) => {
    let errorMessages = this.state.componentErrorMessages;
    this.setState({
      replaceRRR: e.target.value
    })
    this.setState({ componentErrorMessages: errorMessages });
  }

  handleFileChange = (event) => {
    let errorMessages = this.state.componentErrorMessages;
    this.setState({ file: event.target.files[0] });
    this.setState({ componentErrorMessages: errorMessages });
  };

  
  resetError() {
    this.props.dispatch(modalActions.clear());
    this.props.dispatch(alertActions.clear());
  }

  handleDialogClose = () => {
    this.setState({
      viewDialogOpen: false,
      file: null,
      tableData: []
    });
    window.location.reload()
    this.resetForm();
  };

  validateUploadInitiator = () => {
    const { takeRecord } = this.state;
  console.log(takeRecord.errors, "myError");
    // Check if errors object is empty
    if (takeRecord.errors !== 0 && takeRecord.errors !== null) {
      this.setState({
        showAlertModal: true,
        alertMessage: "Fix error on file and upload again",
        alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />
      }, () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
      });
    } else if (takeRecord.errors === null) {
      // Call API InitiateApproval
      this.InitiateApproval();
    }
  }
 
  
  validateInput = () => {
    let _errs_ = [];
    let formValid = true;
    if (this.state.replaceRRR === null) {
        _errs_['replaceRRR'] = 'Please select a payment option';
        formValid = false;
    }
    
    if (this.state.file === null) {
        _errs_['file'] = 'Please select an excel file';
        formValid = false;
    }
    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
};
  resetForm() {
    this.setState({
      // selectedFile: "",
      file: null,
      replaceRRR: null,
    });
    this.resetError();
  }
  uploadFile = (event) => {
    // event.preventDefault();
    // const file = event.target.files[0]
    if (this.validateInput()) {
    const { replaceRRR, file, } = this.state
    const { dispatch } = this.props;
    let payload = {
      replaceRRR,
    }
    dispatch(alertActions.startRequest());

    let data = new FormData();
    data.append("file", file ? file : undefined);
    // data.append("replaceRRR", replaceRRR);
    commonService.uploadReportSheet(payload, data)
      .then(res => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        console.log(response, "RESPONSE");
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.handleDialogClickOpen();
          this.setState({
            tableData: response.result.dataItems,
            takeRecord: response,
          });
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "success",
            })
          );
        }

        else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "failure",
            })
          );
          // this.setState({
          //   showAlertModal: true,
          //   alertMessage: response.message,
          //   alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />
          // }, ()=> {
          //   appHelpers.dismissModal.call(this, "showAlertModal");
          // }
          // )
        }

      })
      .catch(err => {
        dispatch(alertActions.error(err.response))
      });
    }
  };

  InitiateApproval = (e) => {
    // e.persist();
    const { dispatch } = this.props;
    const { takeRecord } = this.state;

    let payload = takeRecord;
    dispatch(alertActions.startRequest());
    commonService.InitiateReportApproval(payload)
      .then(res => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        console.log(response, "initiate");
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({
            showAlertModal: true,
            alertMessage: "file sent for approval successfully",
            alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
          }, () => {
            appHelpers.dismissModal.call(this, "showAlertModal");
            this.handleDialogClose();
        })
        
        }

        else if (res.response.status === appConstants.ERROR_RESPONSE) {
          this.setState({
            showAlertModal: true,
            alertMessage: res.response,
            alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />
        }, () => {
            appHelpers.dismissModal.call(this, "showAlertModal");
            this.handleDialogClose();
        })
        }

      })
      .catch(err => {
        dispatch(alertActions.error(err.response))
      });
  }

 ;
  handleDialogClickOpen = () => {
    this.setState({
      viewDialogOpen: true,
    });
  };

  cancel= ()=>{

  }
  renderAlertModal = (message, icon) => {
    let alertView = [];
    let count = 0;
    const { showAlertModal } = this.state;

    alertView.push(
        <FullScreenModal
            show={showAlertModal}
            key={count + 1}
            isInvoiceSheet={true}
            callback={this.hideAlertView}
            width={"35%"}
            top={"10%"}
        >
            <Alert icon={icon} message={message} />
        </FullScreenModal>
    );
    return alertView;
};
  render() {
    const {
      tableData,
      viewDialogOpen,
      takeRecord,
      count,
      showAlertModal,
      alertIcon,
      alertMessage,
      requesting,
      componentErrorMessages
    } = this.state;
    return (
      <ContentLayout
         requesting={this.props.requesting}
        pageTitle="Upload Report Sheet"
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className="big-action-btn btn-rounded mr-2"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
          
        }
      >        {componentErrorMessages.replaceRRR && <span className={"inputError"}>{componentErrorMessages.replaceRRR}</span>}

        <div name="paymentContainer" 
        className="paymentOptions"
        >
          <div  className="labelDesign floatBlock">
          <label for="remita">
          <Input 
           _errs_={componentErrorMessages.replaceRRR}
          name="replaceRRR" type="radio" value={false} checked={JSON.parse(this.state.replaceRRR) === false}
                onChange={(e) => this.handleRadio(e)}
              />Use Remita Ref</label>
          </div>

          <div  className="floatBlock">
          <label 
            // className="labelDesign2"
             for="nibbs">
              <Input 
              _errs_={componentErrorMessages.replaceRRR}
              name="replaceRRR" type="radio" value={true} checked={JSON.parse(this.state.replaceRRR) === true}
                onChange={(e) => this.handleRadio(e)}
              />Use NIBBS Ref</label>
          </div>
        </div>

        <div
          className="fileDesign"

        >
          <img
            className="w-[40px]"
            src={FileUploadIcon}
            alt="file upload"
          />

          <div className="text-[#7F91A8]">
            <h4>Browse file</h4>
            <p>File format: csv less than 10mb</p>
          </div>

          <input
            className="hidden"
            type="file"
            accept=".csv"
            ref={this.inputRef}
            onChange={this.handleFileChange}
            _errs_={componentErrorMessages.file}

          />
                              {componentErrorMessages.file && <span className={"inputError"}>{componentErrorMessages.file}</span>}
        </div>
        <Row>
          <div style={{
            marginLeft: "73%"
          }}>
            <input
              // ref={this.inputRef}
              style={{ display: "none" }}
              className="csv-input"
              type="file"
              name="file"
              placeholder={null}
            // onChange={this.handleFileChange}
            >
            </input>
            <Button
              variant={"contained"}
              color="success"
              className="big-action-btn btn-rounded mx-10  text-white"
              // onClick={() => this.inputRef.current.click()}
              onClick={() => this.uploadFile()}
            >
              Upload Report Sheet
            </Button>

          </div>
        </Row>

        {tableData.length > 0 && (
                <FullScreenModal
                callback={this.handleDialogClose.bind(this)}
                  show={viewDialogOpen}
                  key={count + 1}
                  title={"Report Sheet"}
                  width={"75%"}
                  zIndex={requesting ? 10 : 999}
                  >
                  <TableFormat
                  
                    tableData={tableData}
                    onClickDecline={this.handleDialogClose.bind(this)}
                    // onClickApprove={this.InitiateApproval}
                    onClickApprove={this.validateUploadInitiator}
                    requesting={this.props.requesting}
                  />
                </FullScreenModal>)}
        
                {showAlertModal === true &&
                    this.renderAlertModal(alertMessage, alertIcon)}        
                    <br />
        <br />

      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, alert, progress, user, requesting, } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(NIBBSPaymentUpload);
