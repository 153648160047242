import React from "react";

const CustomerAwarenessPolicy = () => {
  return (
    <div className="bg-white p-5 text-justify">
      <div className="d-flex justify-content-end pt-2">
        <a href="/privacy-policy" className="pr-5">
          Privacy policy
        </a>
        <a href="/cookie-policy" className="pr-5">
          Cookie policy
        </a>
        <a href="/login" className="pr-5">
          Login
        </a>
      </div>

      <h1 className="pt-5">CUSTOMER AWARENESS POLICY</h1>
      <div className="pt-3">
        <h5>Preface</h5>
        AviTech Software Solutions strongly believes in providing security
        awareness for its staff and customers to ensure protection of the
        security of our customer’s personal data. This helps keeps our customers
        aware and updated on security threats and how to identify and avoid
        them. We are consistently creating and implementing security measures to
        ensure that the integrity and confidentiality of our customers
        information and transactions performed while using our platforms.
      </div>
      <ol>
        <li>
          <h5 className="pt-3">Overview</h5>
          Malicious actors have intensified their efforts in getting
          unsuspecting users’ personal information, as a result, it is important
          that we keep our customers safe while they are using our applications,
          services, or portals. AviTech Software Solutions is committed to
          prioritizing the safety of our customers and we achieve this through
          sensitization on information security and tips on how to keep safe
          online and protect your data.
        </li>

        <li className="pt-5">
          <h5>Security Tips</h5>

          <ol type="i">
            <li>
              <h5 className="pt-3"> General Online Security</h5>
              <ul>
                <li>Ensure you system and web browser are kept updated</li>
                <li>Be extra cautious of emails</li>
                <li>Set strong passwords and change them regularly</li>
                <li>Log-out of sessions or account when not in use</li>
                <li>Never leave your system open and unattended.</li>
                <li>
                  Use an anti-virus, personal firewall and VPN when possible
                </li>
                <li>
                  Ensure a site visited is secured i.e., has “HTTPS” in the URL.
                </li>
              </ul>
            </li>
            <li>
              <h5 className="pt-3">Secured Password</h5>
              It is important that passwords used for accounts are well
              constructed and maintained. Here are some tips on creating a
              strong password
              <ul>
                <li>Use a minimum of 8 characters in length</li>
                <li>
                  Use a combination of upper- and lower-case letters, special
                  characters (such as !@#$&) and numbers
                </li>
                <li>Use a passphrase that can be easily remembered</li>
                <li>Use a password manager to store your password</li>
                <li>Change your password often or upon suspicious activity</li>
                <li>Do not repeat passwords used for other accounts</li>
                <li>Do not use names and common words / phrases</li>
                <li>Do not recycle old passwords</li>
                <li>
                  Do not write your password down where it can easily be
                  accessed
                </li>
                <li>
                  Do not send your password as clear text via messaging platform
                </li>
              </ul>
            </li>
            <li>
              <h5 className="pt-3">Phishing</h5>
              <div>
                This is the process of attempting to acquire sensitive
                information by masquerading as a trustworthy entity through
                email. This could appear to be a legitimate email having a
                company’s name, logo. email signature, and or spoof company
                domain.
              </div>
              <div>
                <h5 className="pt-3">Validate emails</h5>
                Emails may be sent from people you know and do not know. It is
                important that in either situation the identity of the sender of
                the email be validated. If it’s someone you know, you can reach
                out to the sender through a different medium (such as SMS or
                other medium apart from email) to validate that they actually
                sent you the email. When the sender is unknown, use a trusted
                search engine to research the fact about the sender.
              </div>
              <div>
                <h5 className="pt-3">Social Engineering Tactics</h5>
                <div>
                  A phishing email could request for information or could have a
                  link to a website that looks legitimate. In most cases
                  however, the message would use any one of these tactics that
                  play on your emotions:
                </div>

                <ol>
                  <li>
                    Authority: uses a position of seniority to exploit a user’s
                    emotions
                  </li>
                  <li>
                    Urgency: uses the need to perform an action within a
                    specified period to pressure users to perform an action
                  </li>
                  <li>
                    Scarcity: uses the promotion of a limited quantity of an
                    enticing offer to trick users into performing an action
                  </li>
                  <li>
                    Consensus: uses the enticing ideas that people within your
                    network are partaking in an activity and tries to make you
                    perform an action based on it.
                  </li>
                  <li>
                    Familiarity: uses pretence of someone you know and trust to
                    trick you into performing an action.
                  </li>
                  <li>
                    Reciprocity: this is also known as quid-pro-quo and requires
                    you to do something in exchange for something else.
                  </li>
                </ol>
              </div>
              <div>
                <h5 className="pt-3">Links and Attachments</h5>
                <div>
                  Be careful the call to action in an email such as clicking of
                  links and opening of attachments. You should check for the
                  actual site being referenced in a link by carefully hovering
                  your mouse over it and checking the following:
                </div>

                <ul>
                  <li>
                    that the sender and content of the email aligns with the and
                    the URL path shown in the link.
                  </li>
                  <li>
                    Check for grammatical errors in spellings in the URL path
                  </li>
                  <li>
                    Check to see if the path is secured - “HTTPS” sites mean the
                    path is encrypted, however, “HTTP” means the site is not
                    protected and should be careful in accessing it.
                  </li>
                  <li>Check that the path’s length is short and meaningful.</li>
                </ul>
                <div>
                  Be careful of attachments in an email as they can be used to
                  deliver and install malware that can cause a lot of harm to
                  your computer and information.
                </div>
                <div>
                  The essence of this tricky is to have you disclose personal
                  information such as user ID or passwords, credit card
                  information, account number, etc. It is important that you be
                  sceptical of unsolicited or unexpected emails you receive and
                  avoid clicking on suspicious links, downloading attachments
                  and responding to unknown senders.
                </div>
              </div>
            </li>
            <li>
              <h5 className="pt-3">Pharming</h5>
              This occurs when you type in a web address in your browser but get
              redirected to a fraudulent site that looks like the legitimate
              site without your knowledge or consent. To safeguard yourself,
              watch out for spelling errors, logos that looks out of place,
              unusual questions and when in doubt, log out of the site.
            </li>
            <li>
              <h5 className="pt-3">Smishing and Vishing</h5>
              Smishing is an act of using SMS test messages to lure a user into
              performing specific actions. Vishing, known as phone phishing, is
              when a malicious actor tries to gain access to user’s private
              information through a phone conversation. Watch out for unknown
              individuals who ask for information through suspicious messages or
              phone calls. Do not share information if you are unsure of the
              person communicating with you.
            </li>
          </ol>
        </li>

        <li className="pt-5">
          <h5>Contact</h5>
          If you want to lodge a complaint over a security breaches, need our
          help with figuring out how to identify possible security attack or
          have further requests, please contact the AviTech Software Solutions
          Information Security Team at{" "}
          <a
            href="mailto:security@
          avitechng.com"
          >
            security@ avitechng.com
          </a>
        </li>
      </ol>
    </div>
  );
};

export default CustomerAwarenessPolicy;
