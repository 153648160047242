import React, { Component } from 'react';
import './faq.css'

class CardDetails extends Component {
  render() {
    const { icon, item1, item2, item3, onClickItem} = this.props;

    return (
      <div className="card-details">
        <div className="card-contents">
          <div className="icon-card">
            {icon}
          </div>
          <div className="details">
            <div className="row">
              <p className="item1" onClick={onClickItem}>{item1}</p>
              <p className="item2" onClick={onClickItem}>{item2}</p>
              <p className="item3" onClick={onClickItem}>{item3}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardDetails;
