import React, { Component } from "react";
import { Table, Badge } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "./index.scss";
import Toggle from "react-toggle";
import { appConstants } from "../../../_constants";
const Records = ({
  tableData,
  user,
  editUser,
  showUserProfile,
  handleSwitchChecked,
  confirmEmail,
}) => {
  return (
    <Table responsive={true} className={"table-main"}>
      <thead className={"table-head"}>
        <tr>
          <th style={{ padding: "none" }}>Name</th>
          <th style={{ padding: "none" }}>Email</th>
          {user.vggRole === appConstants.ROLES.VGG_ADMIN && (
            <th style={{ padding: "none" }}>Agency</th>
          )}
          <th style={{ padding: "none" }}>Status</th>
          <th style={{ padding: "none" }}>Email Confirmation Status</th>
          <th style={{ padding: "none" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((item, index) => {
            return (
              <tr
                key={index}
                onClick={(e) => showUserProfile(e, item)}
                className={"table-row"}
              >
                <th
                  style={{ padding: "none" }}
                >{`${item.firstName} ${item.lastName}`}</th>
                <th style={{ padding: "none" }}>{item.email}</th>
                {user.vggRole === appConstants.ROLES.VGG_ADMIN && (
                  <th style={{ padding: "none" }}>{item.organization}</th>
                )}
                <th style={{ padding: "none" }}>
                  <span
                    className={`btn-rounded px-2 text-white ${
                      item.status === true ? "bg-green--100" : "bg-red--100"
                    }`}
                  >{`${
                    item.status === true ? "activated" : "deactivated"
                  }`}</span>
                </th>
                <th style={{ padding: "none" }}>
                  <span className={`mr-3`}>{`${
                    item.emailConfirmationStatus === true ? "Yes" : "No"
                  }`}</span>

                  {!item.emailConfirmationStatus && (
                    <span
                      className="btn-rounded px-2 bg-info text-white"
                      onClick={() => confirmEmail(item.userId)}
                    >
                      Confirm Email
                    </span>
                  )}
                </th>
                <th style={{ padding: "none" }}>
                  {/*If the vggRole of the logged in user is client Admin and the vggRole of the record is client admin*/}
                  {/*or the vggRole of the logged in user is Client Admin and the vggRole of the record is client User*/}
                  {/*show the Edit Badge and deactivate toggle button*/}
                  {((user.vggRole === appConstants.ROLES.CLIENT_ADMIN &&
                    item.vggRole === appConstants.ROLES.CLIENT_ADMIN) ||
                    (user.vggRole === appConstants.ROLES.CLIENT_ADMIN &&
                      item.vggRole === appConstants.ROLES.CLIENT_USER)) && (
                    <span>
                      {item.status !== false && (
                        <Badge
                          onClick={(e) => editUser(e, item)}
                          className={"badge-spacing"}
                          size="xl"
                          pill
                        >
                          Edit
                        </Badge>
                      )}
                      {
                        <label className={"toggle-label"}>
                          <Toggle
                            defaultChecked={item.status}
                            checked={item.status}
                            onChange={(e) => handleSwitchChecked(e, item)}
                          />
                        </label>
                      }
                    </span>
                  )}
                  {/*If the vggRole of the logged in user is VGG Admin and the vggRole of the record is client admin*/}
                  {/*or the vggRole of the logged in user is VGG Admin and the vggRole of the record is VGG User*/}
                  {/*show the Edit Badge and deactivate toggle button*/}
                  {((user.vggRole === appConstants.ROLES.VGG_ADMIN &&
                    item.vggRole === appConstants.ROLES.CLIENT_ADMIN) ||
                    (user.vggRole === appConstants.ROLES.VGG_ADMIN &&
                      item.vggRole === appConstants.ROLES.VGG_USER)) && (
                    <span>
                      {item.status !== false && (
                        <Badge
                          onClick={(e) => editUser(e, item)}
                          className={"badge-spacing"}
                          size="xl"
                          pill
                        >
                          Edit
                        </Badge>
                      )}
                      {
                        <label className={"toggle-label"}>
                          <Toggle
                            // defaultChecked={item.status}
                            checked={item.status}
                            onChange={(e) => handleSwitchChecked(e, item)}
                          />
                        </label>
                      }
                    </span>
                  )}
                </th>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default class TableStructure extends Component {
  render() {
    let {
      tableData,
      currentPage,
      fetchMoreRecords,
      pageSize,
      totalCount,
      editUser,
      showUserProfile,
      handleSwitchChecked,
      user,
      confirmEmail,
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMoreRecords}
          pageSize={pageSize}
          totalCount={totalCount}
          target={
            <Records
              tableData={tableData}
              pageIndex={pageIndex}
              editUser={editUser}
              showUserProfile={showUserProfile}
              user={user}
              handleSwitchChecked={handleSwitchChecked}
              confirmEmail={confirmEmail}
            />
          }
        />
      </div>
    );
  }
}
