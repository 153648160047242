import { apiConstants, appConstants } from "../../_constants";
import { commonService, settlementAccountService } from "../../_services";
import { history } from "../../jwt/_helpers";
import { alertActions } from "../alert.actions";
import { modalActions } from "../modal.actions";
import { appHelpers } from "../../_helpers";

export const apiActions = {
  getCurrencies,
  getRevenues,
  getSubRevenues,
  getLocations,
  getUserRevenueAndLocation,
  fetchBanks,
  fetchAccountTypes,
  searchSettlementAccount,
  createSettlementAccount,
  getSettlementAccountLocationRevenue,
  activateSettlementAccount,
  deactivateSettlementAccount,
  shareInvoice,
  downloadSearchedInvoice,
  initiateVoidRequest,
  getCustomers,
  getInvoiceSettings,
  getInvoiceChanges,
  getInvoiceChangesForSearch,
  getAllCreditNoteType,
};

function getCurrencies(cancelToken, removeStopRequestAction) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return commonService.getCurrency(cancelToken).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (!removeStopRequestAction) {
          dispatch(alertActions.stopRequest());
        }
        dispatch(saveCurrency(response.data));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
        dispatch(failure(res.response));
      }
    });
  };
  function saveCurrency(currencies) {
    return { type: apiConstants.CURRENCIES_SUCCESS, currencies };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getRevenues(payload, source, removeStopRequestAction) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return commonService
      .GetAllRevenuesAndRevenueGroup(payload, source.token)
      .then((res) => {
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          if (!removeStopRequestAction) {
            dispatch(alertActions.stopRequest());
          }
          if (response.data) {
            dispatch(saveRevenues(response.data));
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
          dispatch(failure(res.response));
        }
      });
  };
  function saveRevenues(revenues) {
    return { type: apiConstants.REVENUES_SUCCESS, revenues };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getSubRevenues(
  payload,
  source,
  removeStopRequestAction,
) {
  return (dispatch) => {
      dispatch(alertActions.startRequest());
    
    return commonService.GetSubRevenues(payload, source.token).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (!removeStopRequestAction) {
          dispatch(alertActions.stopRequest());
        }
        if (response.data) {
          dispatch(saveSubRevenues(response.data));
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(res.response));
        dispatch(failure(res.response));
      }
    });
  };
  function saveSubRevenues(subRevenues) {
    return { type: apiConstants.SUB_REVENUES_SUCCESS, subRevenues };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getLocations(payload, source, removeStopRequestAction) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return commonService.getLocations(payload, source.token).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (!removeStopRequestAction) {
          dispatch(alertActions.stopRequest());
        }
        if (response.data) {
          dispatch(saveLocations(response.data));
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
        dispatch(failure(res.response));
      }
    });
  };
  function saveLocations(locations) {
    return { type: apiConstants.LOCATIONS_SUCCESS, locations };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getUserRevenueAndLocation(payload) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return commonService.getUserLocationRevenue(payload).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(saveRevenueLocationData(response.data));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(res.response));
        dispatch(failure(res.response));
      }
    });
  };
  function saveRevenueLocationData(revenueLocationData) {
    return { type: apiConstants.REVENUE_LOCATION_SUCCESS, revenueLocationData };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getSettlementAccountLocationRevenue(payload, cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return settlementAccountService
      .getSettlementAccountLocationRevenue(payload, cancelToken)
      .then((res) => {
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(saveSettlementAccountLocationRevenue(response.data));
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(alertActions.error(res.response));
          dispatch(failure(res.response));
        }
      });
  };
  function saveSettlementAccountLocationRevenue(accountRevenueLocationData) {
    return {
      type: apiConstants.ACCOUNT_REVENUE_LOCATION_SUCCESS,
      accountRevenueLocationData,
    };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function fetchBanks(cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return settlementAccountService.getAllBanks(cancelToken).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(saveBanks(response.data));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(res.response));
        dispatch(failure(res.response));
      }
    });
  };
  function saveBanks(banks) {
    return { type: apiConstants.FETCH_BANKS_SUCCESS, banks };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function fetchAccountTypes(cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return settlementAccountService.getAccounts(cancelToken).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(saveAccountTypes(response.data));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(res.response));
        dispatch(failure(res.response));
      }
    });
  };
  function saveAccountTypes(accountTypes) {
    return { type: apiConstants.FETCH_ACCOUNT_TYPES_SUCCESS, accountTypes };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function searchSettlementAccount(payload, pageNumber, cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return settlementAccountService
      .searchSettlementAccount(payload, cancelToken)
      .then((res) => {
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(alertActions.stopRequest());
          let saveBankAccountsPayload = {
            response,
            pageNumber,
          };
          dispatch(saveBankAccounts(saveBankAccountsPayload));
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(alertActions.error(res.response));
          dispatch(failure(response.message));
        }
      });
  };
  function saveBankAccounts(saveBankAccountsPayload) {
    return {
      type: apiConstants.FETCH_BANK_ACCOUNT_SUCCESS,
      saveBankAccountsPayload,
    };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function createSettlementAccount(
  payload,
  cancelToken,
  agencyDetails,
  setSimilarAccountRecords
) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return settlementAccountService
      .createSettlementAccount(payload, cancelToken)
      .then((res) => {
        const { response } = res;
        dispatch(alertActions.stopRequest());

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(createAccounts(response.message));
          let pathStateFromCreateAccount = {
            from: "/review-account-info",
            agencyDetails,
          };
          history.push("/settings-bank-account", pathStateFromCreateAccount);
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          // Check if it is has similar revenue and dispatch displaySecondaryAlert action (this will open up the secondary alert modal) below
          // return dispatch(commonActions.displaySecondaryAlert({show: true, message: response.message}))

          if (response.existingItems && response.existingItems.length > 0) {
            setSimilarAccountRecords(response);

            return dispatch(
              modalActions.displaySecondaryAlert({
                show: true,
                message: response.message,
              })
            );
          }
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "failure",
            })
          );
          dispatch(failure(response.message));
        }
      });
  };
  function createAccounts(message) {
    return { type: apiConstants.CREATE_BANK_ACCOUNT_SUCCESS, message };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function activateSettlementAccount(
  activateBankAccountPayload,
  searchAccountPayload
) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return settlementAccountService
      .activateSettlementAccount(activateBankAccountPayload)
      .then((res) => {
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(activateBankAccount(response.message));
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "success",
            })
          );
          dispatch(searchSettlementAccount(searchAccountPayload));
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "failure",
            })
          );
          dispatch(failure(response.message));
        }
      });
  };
  function activateBankAccount(message) {
    return {
      type: apiConstants.ACTIVATE_DEACTIVATE_BANK_ACCOUNT_SUCCESS,
      message,
    };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function deactivateSettlementAccount(
  deactivateBankAccountPayload,
  searchAccountPayload
) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return settlementAccountService
      .deactivateSettlementAccount(deactivateBankAccountPayload)
      .then((res) => {
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(activateBankAccount(response.message));
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "success",
            })
          );
          dispatch(searchSettlementAccount(searchAccountPayload));
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "failure",
            })
          );
          dispatch(failure(response.message));
        }
      });
  };
  function activateBankAccount(message) {
    return {
      type: apiConstants.ACTIVATE_DEACTIVATE_BANK_ACCOUNT_SUCCESS,
      message,
    };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function shareInvoice(payload, cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return commonService.shareInvoice(payload, cancelToken).then((res) => {
      dispatch(alertActions.stopRequest());
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "success",
          })
        );
        this.dismissModals();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "failure",
          })
        );
        this.dismissModals();
      }
    });
  };
}

function downloadSearchedInvoice(print, payload, cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    commonService.downloadInvoice(payload, cancelToken).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.success(response.message));
        if (print) {
          window.open(response.downloadUrl, "_blank");
        } else {
          appHelpers.downloadFile(response.downloadUrl, "Invoice");
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(res.response));
        }
      }
    });
  };
}

function initiateVoidRequest(payload, cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    commonService.initiateVoidRequest(payload, cancelToken).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "success",
          })
        );
        this.dismissModals();
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (response === "Please Check your internet connectivity") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "failure",
            })
          );
          this.setState({ notificationColor: "danger" }, () => {
            this.dismissModals();
          });
        }
      }
    });
  };
}

function getCustomers(payload, cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return commonService
      .getCustomers(payload, cancelToken.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          if (response.data) {
            dispatch(saveCustomer(response.data));
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
          dispatch(failure(res.response));
        }
      });
  };
  function saveCustomer(customers) {
    return { type: apiConstants.CUSTOMER_SUCCESS, customers };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getInvoiceSettings(payload, cancelToken) {
  return (dispatch) => {
    // dispatch(alertActions.startRequest());
    return commonService.getInvoiceSettings(payload, cancelToken).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(saveInvoiceSettings(response.data));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
        dispatch(failure(res.response));
      }
    });
  };
  function saveInvoiceSettings(invoiceSettings) {
    return { type: apiConstants.INVOICE_SETTINGS_SUCCESS, invoiceSettings };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getInvoiceChanges(payload, cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return commonService
      .getInvoiceChanges(payload, cancelToken)
      .then((res) => {
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
              dispatch(alertActions.stopRequest());
          dispatch(saveInvoiceChanges(response.data));
        } else if (res.status === appConstants.ERROR_RESPONSE) {
            dispatch(alertActions.stopRequest());
          dispatch(alertActions.error(res.response));
          dispatch(failure(res.response));
        }
      });
  };
  function saveInvoiceChanges(invoiceChanges) {
    return { type: apiConstants.INVOICE_CHANGES_SUCCESS, invoiceChanges };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

  function getInvoiceChangesForSearch(payload, cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return commonService
      .getInvoiceChangesForSearch(payload, cancelToken)
      .then((res) => {
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
              dispatch(alertActions.stopRequest());
          dispatch(saveInvoiceChangesForSearch(response.data));
        } else if (res.status === appConstants.ERROR_RESPONSE) {
            dispatch(alertActions.stopRequest());
          dispatch(alertActions.error(res.response));
          dispatch(failure(res.response));
        }
      });
  };
  function saveInvoiceChangesForSearch(invoiceChangesForSearch) {
    return {
      type: apiConstants.INVOICE_CHANGES_FOR_SEARCH_SUCCESS,
      invoiceChangesForSearch,
    };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getAllCreditNoteType(cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return commonService
      .getAllCreditTypes(cancelToken)
      .then((res) => {
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
              dispatch(alertActions.stopRequest());
          dispatch(saveAllCreditTypes(response.results));
          console.log(response, "credit");
        } else if (res.status === appConstants.ERROR_RESPONSE) {
            dispatch(alertActions.stopRequest());
          dispatch(alertActions.error(res.response));
          dispatch(failure(res.response));
        }
      });
  };

  function saveAllCreditTypes(creditTypes) {
    return { type: apiConstants.CREDITNOTE_TYPE,
       creditTypes
      };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}