import React from 'react';
import { Card, CardBody, Button} from 'reactstrap';

const Dialog = ({dialogText, declineClicked,buttonDeclineText, approveClicked, buttonApproveText, element}) => {
    return (
        <Card style={{textAlign: "center"}}>
            <CardBody style={{padding: "70px"}}>
                {element}
                {/*<p>{dialogText}</p>*/}
                <div>
                    <Button
                        color={"success"}
                        className={"btn-rounded ml-3 mb-2 mt-2 px-4"}
                        style={{ marginTop: "10px", marginLeft: "5px"}}
                        onClick={approveClicked}>
                        {buttonApproveText}
                    </Button>

                    <Button
                        color={"danger"}
                        className={"btn-rounded ml-3 mb-2 mt-2 px-4"}
                        style={{ marginTop: "10px", marginLeft: "5px"}}
                        onClick={declineClicked}>
                        {buttonDeclineText}
                    </Button>
                </div>
            </CardBody>
        </Card>
    )
};

export default Dialog