import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import ContentLayout from "../../../components/ContentLayout";
import BackButton from "../../../components/BackButton";
import TableStructure from "./record";

import { appHelpers } from "../../../_helpers";
import { connect } from "react-redux";
import { appConstants } from "../../../_constants";
import { alertActions, apiActions, commonActions } from "../../../_actions";
import { commonService } from "../../../_services";
import CreditNoteSearchPanel from "./search-panel";
import moment from "moment";
import axios from "axios";

let source;

class SearchCreditNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceNumbers: "",
      invoiceNumber: "",
      revenues: [],
      revenue: {},
      customers: [],
      customer: {},
      locations: [],
      // statusArray: [
      //   { name: "All", id: "-1" },
      //   { name: "Paid", id: 1 },
      //   { name: "Partly paid", id: 2 },
      //   { name: "Unpaid", id: 3 },
      //   { name: "Voided", id: 4 },
      // ],
      status: 3,
      location: "",
      dateTypes: [
        { name: "Created Date", id: 1 },
        { name: "Invoice Date", id: 2 },
      ],
      dateType: 1,
      currencies: [],
      currency: "",
      startDate: moment().startOf("day"),
      endDate: moment(),
      selectedRecord: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      creditNoteData: [],
      subRevenues: [],
      subRevenue: {},
      states: [],
      state: "",
      invoiceDetails: [],
    };

    source = axios.CancelToken.source();
  }

  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;
    if (pathState) {
      if (pathState.selectedAgencyDetails !== state.selectedAgencyDetails) {
        return {
          organizationDetails: pathState.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { organizationDetails } = this.state;

    //get route the user is coming from
    let previousPath;
    if (this.props.history.location.state) {
      previousPath = this.props.history.location.state.from;
    }

    //set Role related state
    let vggAdminRole =
      user.vggRole === appConstants.ROLES.VGG_ADMIN ||
      user.vggRole === appConstants.ROLES.VGG_USER;

    //Everytime a user with a VGG Role vists this page, the user must select an organization
    //organizationDetails===undefined prevents redirect from happening again once an organization is selected

    if (vggAdminRole && organizationDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationId: vggAdminRole
            ? organizationDetails && organizationDetails.id
            : user.organizationId,
        },
        () => this.fetchInitialReferenceData()
      );
    }
  };

  componentDidMount() {
    const body = document.body;
    body.style.overflow = "scroll";

    this.setState({ logo: this.props.settings.setLogo });
    this.props.dispatch(commonActions.setPeriodPickerVisibility(false));

    this.routeUserBasedOnRole();
  }

  fetchInitialReferenceData = () => {
    this.getLocations();
    this.getCustomers();
    this.getRevenues();
    const { states, apiReducer } = this.props;
    const { currencies } = apiReducer;

    if (!states) {
      this.getStates();
    } else {
      this.setState({ states: states.data });
    }

    //Redux states
    if (!currencies) {
      this.getCurrencies();
    } else {
      this.setState({ currencies });
    }

    this.searchInvoiceNumber(0, null, "onPageLoad");
  };

  componentDidUpdate(prevProps) {
    const { apiReducer } = this.props;
    if (this.props.settings.setLogo !== prevProps.settings.setLogo) {
      this.setState({
        logo: this.props.settings.setLogo,
      });
    }

    if (this.props.states !== prevProps.states) {
      this.setState({
        states: this.props.states.data,
      });
    }

    if (apiReducer.currencies !== prevProps.apiReducer.currencies) {
      this.setState({
        currencies: apiReducer.currencies,
      });
    }

    if (
      apiReducer.locationsByOrganization !==
      prevProps.apiReducer.locationsByOrganization
    ) {
      this.setState({
        locations: apiReducer.locationsByOrganization,
      });
    }

    if (
      apiReducer.revenuesByOrganization !==
      prevProps.apiReducer.revenuesByOrganization
    ) {
      this.setState({
        revenues: apiReducer.revenuesByOrganization,
      });
    }

    if (apiReducer.customers !== prevProps.apiReducer.customers) {
      this.setState({
        customers: apiReducer.customers,
      });
    }

    if (apiReducer.subRevenues !== prevProps.apiReducer.subRevenues) {
      this.setState({
        subRevenues: apiReducer.subRevenues,
      });
    }
  }

  /* API CALLS */
  getCustomers = () => {
    let { dispatch } = this.props;
    const { organizationId } = this.state;
    let payload = {
      organizationId: organizationId,
      includeChildCustomers: true,
    };

    dispatch(apiActions.getCustomers(payload, source));
  };

  getStates = () => {
    this.props.dispatch(commonActions.getAllStates(source.token));
  };

  getLocations = () => {
    let payload = {
      organizationId: this.state.organizationId,
    };
    return this.props.dispatch(apiActions.getLocations(payload, source));
  };

  getRevenues = () => {
    let payload = {
      organizationId: this.state.organizationId,
      isSelfService: false,
    };
    return this.props.dispatch(apiActions.getRevenues(payload, source));
  };

  getCurrencies = () => {
    return this.props.dispatch(apiActions.getCurrencies(source.token, true));
  };

  fetchRevenueCustomerandLocationMapping = (subRevenueId) => {
    this.getRevenueCustomerMapping(subRevenueId);
    this.getRevenueLocationMapping(subRevenueId);
  };

  getRevenueCustomerMapping = (subRevenueId) => {
    let { dispatch, apiReducer } = this.props;

    dispatch(alertActions.startRequest());
    commonService
      .getRevenueCustomerMapping(subRevenueId, source.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          //if there is a response, it means that the selected revenue is tied to a particular customer, so you need to reset the locations
          //Otherwise the revenue is available to any customer, so no need to reset the state - as explained by Afis
          if (response.data !== null && response.data.length > 0) {
            this.setState({
              customers: response.data,
            });
          }
          if (response.data === null) {
            this.setState({
              customers: apiReducer.customers,
            });
          }
        }
        if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
        }
      });
  };

  getRevenueLocationMapping = (subRevenueId) => {
    let { dispatch, apiReducer } = this.props;

    dispatch(alertActions.startRequest());
    commonService
      .getRevenueLocationMapping(subRevenueId, source.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          //if there is a response, it means that the selected revenue is tied to a particular location, so you need to reset the locations
          //Otherwise the revenue is available to any location, so no need to reset the state - as explained by Afis
          if (response.data !== null && response.data.length > 0) {
            this.setState({
              locations: response.data,
            });
          }
          if (response.data === null) {
            this.setState({
              locations: apiReducer.locationsByOrganization,
            });
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
        }
      });
  };

  searchInvoiceNumber = (pageNumber = 0, searchValue = null, searchType) => {
    const { dispatch, user } = this.props;
    const {
      pageSize,
      location,
      subRevenue,
      status,
      currency,
      dateType,
      invoiceNumber,
      customer,
      state,
      startDate,
      endDate,
      organizationId,
    } = this.state;

    let payload = {
      pageSize: pageSize,
      from: pageNumber === 1 || pageNumber === 0 ? 0 : pageNumber - 1,
      parameter: {},
    };

    //If user is a vgg admin pass the selected organization Id
    // else pass the user's organization Id as agencyId
    if (
      user.vggRole === appConstants.ROLES.VGG_ADMIN ||
      user.vggRole === appConstants.ROLES.VGG_USER
    ) {
      payload.parameter.agencyId = organizationId;
    } else {
      payload.parameter.agencyId = user.organizationId;
    }

    if (searchType !== "invoice" && searchType !== "customer") {
      // start date, end date, dateType should not be passed on
      // invoice or customer quick search
      payload.parameter.startDate =
        moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      payload.parameter.endDate =
        endDate.format("YYYY-MM-DD") + "T23:00:00.000Z";
      payload.parameter.dateType = dateType;
    }

    //Stop endpoint from being called if search input is empty
    if (searchType === "invoice" && searchValue === "") {
      dispatch(alertActions.notify("Please input an invoice number"));
      return;
    }

    if (searchType === "customer" && searchValue === "") {
      dispatch(alertActions.notify("Please input a customer name"));
      return;
    }

    if (
      searchType === "invoice" &&
      (searchValue !== null || searchValue !== "")
    ) {
      payload.parameter.invoiceNumber = searchValue;
    } else if (invoiceNumber !== "" && searchType !== "customer") {
      payload.parameter.invoiceNumber = invoiceNumber;
    }
    if (searchType === "customer" && searchValue !== null) {
      payload.parameter.name = searchValue;
    } else if (customer !== "") {
      // payload.parameter.name = customer.customerName; // removed based on Kayode's request
      payload.parameter.providerId = customer.id;
    }

    if (location !== "") {
      payload.parameter.locationId = location;
    }

    if (subRevenue?.id !== "") {
      //assign subRevenue to revenueId
      payload.parameter.revenueId = subRevenue.id;
    }

    if (status !== "") {
      payload.parameter.status = status;
    }

    if (currency !== "") {
      payload.parameter.currencyId = currency.id;
    }

    if (state !== "") {
      payload.parameter.state = state;
    }

    this.clearTableData();
    dispatch(alertActions.startRequest());
    commonService.searchInvoice(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          invoiceDetails: response.results,
          currentPage: pageNumber === 0 ? 1 : pageNumber,
          totalCount: response.totalCount,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        // dispatch(alertActions.error(response.message ? res.response : 'Something went wrong'));

        if (response === "Please Check your internet connectivity") {
          this.setState({
            invoiceDetails: [],
            totalCount: 0,
          });
        } else {
          this.setState({
            invoiceDetails: [],
            totalCount: 0,
          });
        }
      }
    });
  };

  clearTableData = () => {
    this.setState({
      invoiceDetails: [],
      totalCount: 0,
    });
  };

  handleSelectChange = (payload, attribute) => {
    let currState = this.state;
    if (!payload) {
      currState[attribute] = "";
      if (attribute === "revenue") {
        // location and customer need to be reset once revenue is cleared
        this.setState(
          {
            subRevenue: {},
            subRevenues: [],
            location: "",
          },
          () => {
            this.getLocations();
            this.getCustomers();
          }
        );
      }
    } else {
      if (attribute === "location") {
        currState[attribute] = payload.id;
      } else if (attribute === "customer") {
        currState[attribute] = payload;
      } else if (attribute === "revenue") {
        currState[attribute] = payload;
        if (payload.hasSubRevenue) {
          //empty previous state, then set new state through API call
          this.setState(
            {
              subRevenue: {},
              subRevenues: [],
              location: "",
            },
            () => this.getSubRevenues(payload.id)
          );
        } else {
          this.setState({
            subRevenue: payload,
            subRevenues: this.state.revenues,
          });
          //fetch customer and location mappings using revenueId  since there are no subrevenues
          this.fetchRevenueCustomerandLocationMapping(payload.id);
        }
      } else if (attribute === "subRevenue") {
        currState[attribute] = payload;
        //fetch customer and location mappings using subRevenueId
        this.fetchRevenueCustomerandLocationMapping(payload.id);
      } else if (attribute === "state") {
        currState[attribute] = payload.name;
      } else if (attribute === "status") {
        currState[attribute] = payload.id;
      } else if (attribute === "currency") {
        currState[attribute] = payload.id;
        currState[attribute] = payload;
      } else if (attribute === "dateType") {
        currState[attribute] = payload.id;
      } else {
        currState[attribute] = payload.Id;
      }
    }
    this.setState({ currState });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  clearInputs = () => {
    this.setState({
      invoiceNumbers: "",
      invoiceNumber: "",
      revenue: {},
      subRevenue: {},
      state: {},
      customer: {},
      status: "",
      location: "",
      dateTypes: [
        { name: "Created Date", id: 1 },
        { name: "Invoice Date", id: 2 },
      ],
      dateType: 1,
      currency: "",
      startDate: moment().startOf("day"),
      endDate: moment(),
    });
  };

  checkboxChange = (event, selectedItem) => {
    event.persist();
    const target = event.target;

    if (target.checked) {
      let joined = this.state.selectedRecord.concat(selectedItem);
      this.setState({ selectedRecord: joined });
    } else {
      this.setState({
        selectedRecord: this.state.selectedRecord.filter(
          (item) => item !== selectedItem
        ),
      });
    }
  };

  proceedToApply = (event) => {
    event.persist();
    const { selectedRecord } = this.state;

    let record = {
      selectedRecord: selectedRecord,
      from: "/search-credit-note",
    };

    appHelpers.navigateRoute.call(this, "/apply-credit-note", record);
  };

  creditNoteStatus = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case 2:
        return "Disapproved";
      case null:
        return "No credit note applied";
      default:
        return "No credit note applied";
    }
  };

  render() {
    const {
      invoiceNumbers,
      invoiceNumber,
      revenues,
      revenue,
      subRevenues,
      subRevenue,
      state,
      states,
      customers,
      customer,
      locations,
      dateTypes,
      dateType,
      currencies,
      currency,
      startDate,
      location,
      status,
      referenceNumber,
      endDate,
      pageSize,
      totalCount,
      currentPage,
      selectedRecord,
      invoiceDetails,
    } = this.state;
    const { requesting } = this.props;
    return (
      <ContentLayout
        pageTitle={`Waivers and Discount`}
        requesting={requesting}
        actions={
          <BackButton
            navigateBack={() => appHelpers.navigateToPreviousRoute.call(this)}
          />
        }
      >
        <Row className="mt-2 mb-4 ml-1">
          <h2>
            <b>Search Credit Note</b>
          </h2>
        </Row>
        <CreditNoteSearchPanel
          invoiceNumbers={invoiceNumbers}
          invoiceNumber={invoiceNumber}
          selectChange={this.handleSelectChange}
          handleChange={this.handleChange}
          revenueLine={revenues}
          revenue={revenue}
          subRevenues={subRevenues}
          subRevenue={subRevenue}
          states={states}
          state={state}
          customers={customers}
          customer={customer}
          locations={locations}
          location={location}
          status={status}
          dateTypes={dateTypes}
          dateType={dateType}
          currencies={currencies}
          currency={currency}
          startDate={startDate}
          endDate={endDate}
          changeDatePicker={this.changeDatePicker}
          clearInputs={() => this.clearInputs()}
          searchInvoiceNumber={() => this.searchInvoiceNumber()}
          referenceNumber={referenceNumber}
        />
        {invoiceDetails.length > 0 && (
          <Row style={{ marginTop: "76px" }}>
            <Col md={"12"}>
              <TableStructure
                currentPage={currentPage}
                fetchMore={this.searchInvoiceNumber}
                pageSize={pageSize}
                totalCount={totalCount}
                data={invoiceDetails}
                checkboxChange={this.checkboxChange}
                proceedToApply={this.proceedToApply}
                user={this.props.user}
                selectedRecord={selectedRecord}
                creditNoteStatus={this.creditNoteStatus}
              />
            </Col>
          </Row>
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user,
    requesting,
    apiReducer,
    displayGeneralAlert,
    settings,
    states,
  } = state;
  return {
    user,
    requesting,
    apiReducer,
    displayGeneralAlert,
    settings,
    states,
  };
};

export default connect(mapStateToProps)(SearchCreditNote);
