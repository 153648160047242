import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button, Col, Input, Row, Label, Form, FormGroup} from "reactstrap";
import {appHelpers, pageHelpers} from "../../../_helpers";
import {FaArrowLeft} from "react-icons/fa";
import ContentLayout from "../../../components/ContentLayout";
import CheckboxGrid from "../../../components/CheckboxGrid";
import {appConstants} from "../../../_constants";
import {alertActions, commonActions, modalActions, selfServiceActions} from "../../../_actions";
import axios from "axios";
import {selfService} from "../../../_services/self-service";
import GeneralAlert from "../../../components/Api-response-alert";

let source;

class CreateSelfServiceSetup extends Component{
  constructor(props) {
    super(props);
    this.state = {
      maxUnpaidInvoice: 0,
      locations: [],
      selectAllLocations: false,
      pageTitle: "Create Self-Service Setup",
      editRecord: null,
      organizationId: null,
      selectedLocationIds: [],
      previousPath: null,
      identifiedAgency: false,
      pathState: null,
      inputError: false
    };
  
    source = axios.CancelToken.source();
  }
  
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first
    
    let pathState = props.location.state;
    if (pathState) {
      if (pathState.selectedAgencyDetails !== state.selectedAgencyDetails) {
        return {
          organizationDetails: pathState.selectedAgencyDetails,
        };
      }
    }
    
    return null;
  }
  
  componentDidMount() {
    let pathState= this.props.location.state;
    
    //If there is a record object, which means the user wants to edit the record
    if (pathState){
      let { record } = pathState;
      this.setState({
        pathState: pathState
      });
      //  If user is coming from "./settings-view-self-service-setup"
      // or "./settings-review-self-service-setup",
      // set the identifiedAgency variable to true.This will prevent
      // the page from navigating to the "/select-agency" route
      if (pathState && record) {
        this.setState({
          editRecord: pathState.record,
          maxUnpaidInvoice: pathState.record.count,
          pageTitle: pathState.pageTitle,
          previousPath: pathState.previousPath,
          organizationId: pathState.organizationId,
          pathState: pathState,
          identifiedAgency: true,
        }, () => {
          this.routeUserBasedOnRole(pathState.record);
        });
      } else {
        //  If user is coming from "./settings-view-self-service-setup"
        // set the identifiedAgency variable to true. This will prevent
        // the page from navigating to the "/select-agency" route
        if (pathState.previousPath){
          this.setState({
            previousPath: pathState.previousPath,
            identifiedAgency: true,
            organizationId: pathState.organizationId,
            pathState: pathState
          }, () => {
            this.routeUserBasedOnRole();
          })
        }
       
      }
    }
  
    // If user is not coming from "./settings-view-self-service-setup" or "./settings-review-self-service-setup",
    // call the routerUserBasedOnRole function, this will redirect the user to selectAgency.
    if (!pathState.record && !pathState.previousPath) {
      this.routeUserBasedOnRole();
    }
  }
  
  routeUserBasedOnRole = (record) => {
    const { user } = this.props;
    const { organizationDetails, pathState} = this.state;
    //get route the user is coming from
    let previousPath;
    if (this.props.history.location.state) {
      previousPath = this.props.history.location.state.from
    }
    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN || user.vggRole === appConstants.ROLES.VGG_USER;
    
    //Everytime a user with a VGG Role vists this page and they are not coming from either
    // "./settings-view-self-service-setup" or "./settings-review-self-service-setup" ,
    // the user must select an organization
    //organizationDetails===undefined && !this.state.identifiedAgency
    // prevents redirect from happening again once an organization is selected
    if (vggAdminRole && organizationDetails === undefined
      && !this.state.identifiedAgency
    ) {
        this.props.history.push({
          pathname: "/select-agency",
          state: {from: previousPath},
        });
    } else {
      //if organization is selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationId: this.state.identifiedAgency ? pathState.organizationId
            : vggAdminRole && !this.state.identifiedAgency
            ? organizationDetails && organizationDetails.id
            : user.organizationId,
        },
        () => {
          if (record) {
            this.fetchInitialReferenceData(record)
          } else {
            this.fetchInitialReferenceData()
          }
          
        }
      );
    }
  };
  
  fetchInitialReferenceData = (record) => {
    const {selfServiceReducer, selectedLocationIds,} = this.props;
    const {selfServiceUserLocations} = selfServiceReducer;
    
    // The selectedLocationIds reducer is used to keep track of
    // all selected locations ids both when a user is coming from the
    // table to edit a record and when the user has successfully
    // created/edited a record. This will be used to preserve
    // the checked state of the checkboxes
    
   if (record){
     let id = [];
     id.push(record.locationId);
     if (!selectedLocationIds){
        this.getSelectedLocationIds(id)
     }
     if (!selfServiceUserLocations){
       this.getLocations()
     } else {
       let modifiedLocations = appHelpers.compareAndModifyArrayPropertyWithAnotherArray(selfServiceUserLocations, id, "locationId", "activated");
       this.setState({ locations: modifiedLocations });
     }
   }
   
   if (!record){
     if (!selfServiceUserLocations){
       this.getLocations()
     } else {
       this.setState({locations: selfServiceUserLocations})
     }
   }
    
  };
  
  componentDidUpdate(prevProps) {
    const {editRecord} = this.state;
    const {selfServiceReducer, selectedLocationIds} = this.props;
    
    
    if(!editRecord) {
      if (selfServiceReducer.selfServiceUserLocations !== prevProps.selfServiceReducer.selfServiceUserLocations) {
        this.setState({
          locations: selfServiceReducer.selfServiceUserLocations,
        });
      }
    }
    
    if(editRecord){
      if(selfServiceReducer.selfServiceUserLocations !== prevProps.selfServiceReducer.selfServiceUserLocations) {
         let modifiedLocations = appHelpers.compareAndModifyArrayPropertyWithAnotherArray(selfServiceReducer.selfServiceUserLocations, selectedLocationIds, "locationId", "activated");
            this.setState({
               locations: modifiedLocations,
            });
      }
    }
  }
  
  handleChange = (event) => {
     this.setState({[event.target.name]: event.target.value});
  };
  
  getLocations = () => {
    const { dispatch } = this.props;
    const { organizationId } = this.state;
    let payload = {
      CustomerStaffId: 0,
      OrganizationId: organizationId
    };
    dispatch(selfServiceActions.fetchSelfServiceUserLocation(payload, source.token))
    
  };
  
  getSelectedLocationIds = (locationIds) => {
    const { dispatch } = this.props;
    dispatch(commonActions.saveSelectedLocationIds(locationIds))
  };
  
  selectAllCheckboxes = () => {
    const { locations} = this.state;
      this.setState(
        (prevState) => ({ selectAllLocations: !prevState.selectAllLocations }),
        () => {
          if (this.state.selectAllLocations) {
            let locationsActivated = locations.map((item) => {
              item.activated = true;
              return item;
            });
            this.setState({ locations: locationsActivated });
          } else if (!this.state.selectAllLocations) {
            let locationsActivated = locations.map((item) => {
              item.activated = false;
              return item;
            });
            this.setState({ locations: locationsActivated });
          }
        }
      );
  };
  
  createSetup = () => {
     const { maxUnpaidInvoice, locations, organizationId, pageTitle, editRecord, selectAllLocations} = this.state;
     const {dispatch} = this.props;
     let locationIds = this.filterCheckboxId(locations);
     
    if(this.validateInput()) {
      let payload = {
        count: maxUnpaidInvoice,
        agencyId: organizationId
      };
  
      if (pageTitle === appConstants.EDIT_SETUP) {
        payload.id = editRecord.id
      }
  
      if (selectAllLocations) {
        payload.isAllLocation = true
      } else {
        payload.locationIds = locationIds
      }
  
      dispatch(alertActions.startRequest());
      selfService.manageUnpaidInvoiceTracker(payload, source.token).then(res => {
        let response = res.response;
        dispatch(alertActions.stopRequest());
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "success"}));
          if (this.state.pageTitle === appConstants.EDIT_SETUP) {
        
            // get the id of all selected locations
            // dispatch the saveSelectedLocationIds action
            // and pass the ids to it. this will update the
            // selectedLocationIds reducer which will preserve the state
            // if in the event the component remounts e.g during page reload.
        
            let selectedLocationIds = [];
            locations.forEach(item => {
              if (item.activated === true) selectedLocationIds.push(item.locationId)
            });
            this.props.dispatch(commonActions.saveSelectedLocationIds(selectedLocationIds));
          }
          if (this.state.pageTitle === appConstants.CREATE_SETUP) {
            this.resetFields();
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(modalActions.displayGeneralAlert({show: true, message: response.message, action: "failure"}));
        }
    
      })
    }
  };
  
  filterCheckboxId = (data) => {
    const filteredPermissions = data
      .filter((item) => item.activated === true)
      .map((item) => item.locationId);
    return filteredPermissions ? filteredPermissions : [];
  };
  
  cancel = () => {
    window.scrollTo(0, 0);
    this.resetFields();
  };
  
  resetFields = () => {
      this.setState({
        maxUnpaidInvoice: 0,
        locations: this.setCheckboxGridToFalse(this.state.locations)
      })
  };
  
  validateInput = () => {
    let err = [];
    let formValid = true;
    if (this.state.maxUnpaidInvoice < 0) {
      err["maxUnpaidInvoice"] = "unpaid invoice value cannot be less zero";
      formValid = false;
    }
    this.setState({ inputError: err });
    window.scrollTo(0, 0);
    return formValid;
    
  }
  
  setCheckboxGridToFalse = (data) => {
    data.map(item => {
      return item.activated = false
    });
    
    return data;
  };
  
  componentWillUnmount () {
    const {dispatch} = this.props;
    dispatch(modalActions.displayGeneralAlert({show: false}));
    dispatch(commonActions.saveSelectedLocationIds(false));
  
    if (source) {
      source.cancel()
    }
  }
  
  render () {
    const {maxUnpaidInvoice, locations, selectAllLocations, pageTitle, inputError} = this.state;
    const {requesting} = this.props;
    
    return (
      <ContentLayout
        pageTitle={pageTitle}
        requesting={requesting}
        actions={
          // isVggRole && (
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          // )
        }
      >
        <Row>
            <Form>
              <FormGroup row>
                <Label for="maxUnpaidInvoice" sm={6}>Maximum Unpaid Invoice</Label>
                <Col sm={6}>
                  <Input
                    type="number"
                    name="maxUnpaidInvoice"
                    id="maxUnpaidInvoice"
                    value={maxUnpaidInvoice}
                    onChange={this.handleChange}
                    min={0}
                  />
                  {
                    inputError.maxUnpaidInvoice &&
                    <span className={"red--100"}>{inputError.maxUnpaidInvoice}</span>
                  }
                </Col>
              </FormGroup>
            </Form>
          {/*</Col>*/}
        </Row>
        <Row className={"mt-4"}>
          <Col md={12}>
            {locations.length > 0 && (
              <CheckboxGrid
                title={"Select locations"}
                checkAll={selectAllLocations}
                label={!selectAllLocations ? "Select all" : "Deselect all"}
                displayCheckAllBoxes={true}
                checkAllBoxes={() => pageHelpers.selectAllCheckboxes.call(this, "location")}
                data={locations}
                checkboxChange={(e) => pageHelpers.checkboxChange.call(this, e, "location")}
              />
            )}
          </Col>
        </Row>
        <div className={"mt-4"}>
          <Button
            color="success"
            className="btn-sm btn-rounded mr-2 text-white"
            onClick={this.createSetup}
            disabled={requesting}
          >
            {pageTitle === appConstants.CREATE_SETUP ? "Create Setup" : "Save"}
          </Button>
          {pageTitle === appConstants.CREATE_SETUP
            &&
            <Button
              outline
              className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
              onClick={this.cancel}
            >
              Cancel
            </Button>
          }
        </div>
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </ContentLayout>
    )
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, apiReducer, displayGeneralAlert, selfServiceReducer, selectedLocationIds} = state;
  return {
    user,
    requesting,
    apiReducer,
    displayGeneralAlert,
    selfServiceReducer,
    selectedLocationIds
  };
};

export default connect(mapStateToProps)(CreateSelfServiceSetup);