import React, { useEffect } from "react";
import { Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import "./index.scss";
import CookiesConsent from "./cookies-consent";

const CookiesPolicy = () => {
  let cookieName = "bdaweb:cc";

  const setCookies = (name, value, days) => {
    let expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
    toggleCookieDisplay();
  };

  const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const toggleCookieDisplay = () => {
    let cookieObj = getCookie(cookieName);
    let cookieWrapperElement = document.querySelector(".cookies-poilcy-footer");
    if (cookieObj === null) {
      cookieWrapperElement.style.display = "flex";
    } else {
      cookieWrapperElement.style.display = "none";
    }
  };

  useEffect(() => {
    const body = document.body;
    body.style.overflow = "scroll";
    toggleCookieDisplay();
  });
  return (
    <>
      <Container className={"mt-5"}>
        <Row className={"d-flex justify-content-end"}>
          <Link to="/privacy-policy" className="pr-5">
            Privacy Policy
          </Link>
          <Link to="/customer-awarness-policy" className="pr-5">
            Customer Awareness Policy
          </Link>
          <Link to="/login">Login</Link>
        </Row>
        <Row>
          <h2 className="font-weight-bold">Cookie Policy</h2>
        </Row>
        <Row>
          <p>
            Please read this cookie policy (“cookie policy”, "policy") carefully
            before using this website (“website”, "service") operated by Avitech
            ("us", 'we", "our").
          </p>
        </Row>
        <Row>
          <h4 className="font-weight-bold">What are Cookies?</h4>
          <p>
            Cookies are simple text files that are stored on your computer or
            mobile device by a website’s server. Each cookie is unique to your
            web browser. It will contain some anonymous information such as a
            unique identifier, website’s domain name, and some digits and
            numbers.
          </p>
        </Row>
        <Row>
          <h4 className="font-weight-bold">What type of cookies do we use? </h4>
        </Row>
        <Row>
          <h6 className="font-weight-bold">Necessary Cookies</h6>
        </Row>
        <Row>
          <p>
            {" "}
            Necessary cookies allow us to offer you the best possible experience
            when accessing and navigating through our website and using its
            features. For example, these cookies let us recognize that you have
            created an account and have logged into that account.
          </p>
          <h6 className="font-weight-bold">Functionality Cookies</h6>
          <p>
            Functionality cookies let us operate the site in accordance with the
            choices you make. For example, we will recognize your username and
            remember how you customized the site during future visits.
          </p>
          <h6 className="font-weight-bold">Performance Cookies</h6>
          <p>
            These are used to gather data on how users use our website and the
            pages visited most often. They monitor only the performance of our
            website as the user interacts with it. All data collected by these
            cookies are anonymous and are solely used to improve the
            functionality of our website.
          </p>
        </Row>
        <Row>
          <h4 className="font-weight-bold">How to delete Cookies?</h4>
        </Row>
        <Row>
          <p>
            If you want to restrict or block the cookies that are set by our
            website, you can do so through your browser setting.
          </p>
        </Row>
        <Row>
          <h4 className="font-weight-bold">Contacting us</h4>
        </Row>
        <Row>
          <p>
            If you have any questions about this policy or our use of cookies,
            please contact us.{" "}
            <a
              href="mailto:dpo@avitechng.com"
              className="text-decoration: underline; color: inherit;"
            >
              dpo@avitechng.com
            </a>{" "}
          </p>
        </Row>
        <Row></Row>
      </Container>
      <CookiesConsent setCookies={setCookies} cookieName={cookieName} />
    </>
  );
};

export default CookiesPolicy;
