import React from "react";
import { Row, Col, FormGroup, Button, Container } from "reactstrap";
import "./forgotpassword.css";
import { alertActions} from "../../_actions";
import { appConstants } from "../../_constants";
import { commonService } from "../../_services";
import { history } from "../../jwt/_helpers";
// import { appHelpers } from "../../_helpers";
import axios from "axios";
import { connect } from "react-redux";
import { store } from 'react-notifications-component';
import {appHelpers} from "../../_helpers";
import EPIBSBGImage from "../../assets/images/ebips-assets/Ebips-image.png";
import EPIBSLogo from "../../assets/images/ebips-assets/ebips-logo.svg";

let source;
class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      isRequesting: false,
      error: false,
      componentError: false,
      componentErrorMessages: [],
      year: "",
      domainURL: ""
    };

    source = axios.CancelToken.source();

  }
  componentDidMount() {
    //Get copyright year
    let date = new Date ();
    let year = date.getFullYear();
    this.setState({year})
    
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";
    
    const { dispatch } = this.props;
    dispatch(alertActions.resetApp());
  
    appHelpers.getBrandingFromDomainURL.call(this)
  }

  componentWillReceiveProps = function(nextProps) {
    const { alert } = nextProps;
    if (alert) {
      if (alert.type === appConstants.SUCCESS) {
        this.setState({ error: false, success: alert.message });
      } else if (alert.type === appConstants.CLEAR) {
        this.setState({ success: false, error: false });
      } else {
        this.setState({ success: false, error: alert.message });
      }
    }
  };


  handleChange = (event, attribute) => {
    let currState = this.state;
    currState[attribute] = event.target.value;
    let errorMessages = this.state.componentErrorMessages;
    errorMessages[attribute] = false;
    this.setState(currState);
    this.setState({ componentErrorMessages: errorMessages });
  };

  // handleInputChange = e => {
  //   this.setState({
  //     data: { ...this.state.data, [e.target.name]: e.target.value }
  //   });
  // };

  //   enterHandler = () => {
  //     window.addEventListener("keyup", function (event) {
  //         if (event.keyCode === 13) {
  //             event.preventDefault();
  //             let resetLogin  =  document.querySelector("#resetButton");
  //             if(resetLogin  && resetLogin !== null ){
  //                 resetLogin.click();
  //             }
  //         }
  //     });
  // };
  validateInput = () => {
    let _errs_ = [];
    let formValid = true;
    if (this.state.email === "") {
      _errs_["email"] = "Please enter email";
      formValid = false;
    }
    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  handleSubmit = event => {
    event.preventDefault();

    this.resetError();
    if (this.validateInput()) {
      this.setState({ isRequesting: true });
      this.processSubmit();
    }
  };

  processSubmit = () => {
    const { email } = this.state;
    const { dispatch } = this.props;

    commonService.forgotPassword(email, source.token).then(
      response => {


        this.setState({ isRequesting: false });
        if (response.status === appConstants.SUCCESS_RESPONSE) {
          let user = response.response;
          dispatch(success(user));
          store.addNotification({
            message: response.response.message,
            type:"success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              // onScreen: true
            }
          });
          history.push("/login");
          
        }else {
          store.addNotification({
            message: response.response.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              // onScreen: true
            }
      });
    }

  },
      error => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Unable to handle request";
        dispatch(failure(errorMessage));
        this.setState({ isRequesting: false });
        // dispatch(alertActions.stopRequest());
      }
    );

    function success(user) {
      return { type: appConstants.SIGN_IN_SUCCESS, user };
    }
    function failure(message) {
      return { type: appConstants.ERROR, message };
    }
  };

  resetError() {
    this.props.dispatch(alertActions.clear());
  }

  renderPageResponse() {
    if (this.state.error) {
      return (
        <div className="alert alert-danger text-capitalize-first">
          {this.state.error}
        </div>
      );
    } else if (this.state.componentError) {
      return (
        <div className="alert alert-danger text-capitalize-first">
          {this.state.componentError}
        </div>
      );
    } else if (this.state.success) {
      return (
        <div className="alert alert-success text-capitalize-first">
          {this.state.success}
        </div>
      );
    }
  }

  componentWillUnmount () {
   
    if (source) {
      source.cancel()
    }
   }
  
  render() {
    let pageResponse = this.renderPageResponse();
    const { isRequesting, componentErrorMessages, year} = this.state;
    const {userData} = this.props;
    
    let backgroundImage = userData.backgroundUrl ? userData.backgroundUrl :  EPIBSBGImage;
    let logo = userData.logoUrl ? userData.logoUrl : EPIBSLogo;
    return (
      <div
      style={{
        backgroundImage: `linear-gradient(  rgb(4, 36, 65) 20%, rgba(7, 52, 91, 0.705) 90%),url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflow: "hidden",
        height: "100vh"
      }}
      >
        <Container>
          <Row className={"d-flex align-items-center justify-content-center mt-4 vh100"}>
            <Col md={6}>
              <h1
                className="headerText"
                style={{
                  fontWeight: "600",
                  marginTop: "5px",
                  fontSize: "25px"
                }}
              >
                Electronic Billing, Invoicing <br /> and Payment Solution
              </h1>
              <p
                className="baseText"
                style={{ fontSize: "11px", fontWeight: "300" }}
              >
                Improve transparency and accountability and streamline <br />{" "}
                your invoice-to-Cash Operations across all Payment Methods{" "}
                <br /> and Channel on a Single Platform.
              </p>
            </Col>
            <Col  sm={12} md={6}>
              <div
                className="card o-hidden border-0 shadow-lg my-5"
                id="cardForm"
              >
                <div className="card-body p-0" style={{ margin: "0 auto" }}>
                  <Row>
                    <img
                      src={logo}
                      alt="BDA Logo"
                      style={{
                        verticalAlign: "middle",
                        borderStyle: "none",
                        width: "25%",
                        marginLeft: "40%",
                        marginTop: "45px"
                      }}
                    />
                    <center>
                      <p
                        style={{
                          fontSize: "0.6rem",
                          margin: "50px 0px 0 141px",
                          fontWeight: "bold",
                          textAlign: "center"
                        }}
                      >
                       Enter a valid email address for your{" "}
                        <br />
                        Password reset instructions
                      </p>
                    </center>

                    <div className="col-lg-12">
                      <div className="p-9" style={{ marginTop: "8%" }}>
                        <div className="text-center" />
                        <form className="user">
                          <center>
                            {pageResponse}
                            <FormGroup>
                              <input
                                size="lg"
                                type="email"
                                name="email"
                                value={this.state.email}
                                id="exampleInputEmail"
                                // aria-describedby="emailHelp"
                                placeholder="Email"
                                _errs_={componentErrorMessages.email}
                                onChange={event =>
                                  this.handleChange(event, "email")
                                }
                              />
                              {componentErrorMessages.email && <span className={"inputError"}>{componentErrorMessages.email}</span>}
                              <br/>
                              <Button
                                color={"success"}
                                onClick={this.handleSubmit}
                                id="loginButton"
                                className={
                                  "btn-rounded ml-3 mb-2 mt-2 px-4 loginBtn"
                                }
                                disabled={isRequesting}
                              >
                                {isRequesting ? (
                                  <span>
                                    Submitting...{" "}
                                    <i className="fa fa-spinner fa-spin"></i>
                                  </span>
                                ) : (
                                  `Submit`
                                )}

                                {this.state.btn}
                              </Button>
                            </FormGroup>
                          </center>
                          <center>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginBottom: "0.1rem",
                                fontSize: "10px"
                              }}
                            >
                              Federal Ministry of Aviation, Nigeria
                            </p>
                            <p style={{ marginTop: "5px", fontSize: "10px" }}>
                              {`AVITECH All Rights Reserved, Copyright \u00A9 ${year}`}
                            </p>
                          </center>
                        </form>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, user, requesting, userData } = state;
  return {
    alert,
    user,
    requesting,
    userData
  };
}

export default connect(mapStateToProps)(ForgotPasswordPage);
