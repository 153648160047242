import React, { Component } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import ServerPaginatedTable from "../../components/ServerPagination";

export const AuditTrailTable = ({ apiData }) => {
  return (
    <Table responsive={true} className={"table-main"}>
      <thead className={"audit-head"}>
        <tr>
          <th style={{ padding: "none" }}>Name</th>
          <th style={{ padding: "none" }}>Event Date</th>
          <th style={{ padding: "none" }}>VIA-API</th>
          <th style={{ padding: "none" }}>IP-ADDRESS</th>
          <th style={{ padding: "none" }}>Source</th>
          <th style={{ padding: "none" }}>Action Performed</th>
        </tr>
      </thead>
      <tbody>
        {apiData.map((item, index) => {
          return (
            <tr key={index} style={{ borderTop: "12px solid transparent" }}>
              <th>{item.personName}</th>
              <th>
                <span className={"audit-day"}>
                  {moment(item.timeStamp).format("D")}
                </span>
                <span className={"audit-month audit-date"}>
                  {moment(item.timeStamp).format("MMM YYYY")}
                </span>
                <span className={"audit-time red--100"}>
                  {moment(item.timeStamp).format("hh:mm a")}
                </span>
              </th>
              {/* <th><div class="checkboxOverride"><Input type="checkbox" id="checkboxInputOverride" readOnly checked={item.apiCall ? true : false} /><label for="checkboxInputOverride"></label></div></th> */}
              <th>
                <div class="checkboxOverride">
                  <label for="checkboxInputOverride"></label>
                </div>
              </th>
              <th>{item.ipAddress}</th>
              <th>{item.source}</th>
              <th>{item.description}</th>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default class TableFormat extends Component {
  render() {
    let {
      apiData,
      currentPage,
      fetchMore,
      pageSize,
      totalCount,
      downloadPdf,
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageSize={pageSize}
          totalCount={totalCount}
          positionBottomPagination={"left"}
          showBottomPagination
          topDownload
          bottomDownload
          downloadPdf={() => downloadPdf("pdf")}
          downloadExcel={() => downloadPdf("excel")}
          target={<AuditTrailTable apiData={apiData} pageIndex={pageIndex} />}
        />
      </div>
    );
  }
}
