import React from "react";
import {connect} from "react-redux";
import Spinner from '../../assets/images/spinner.svg'
import "./index.scss";

export const ProgressLoader = ({message, displayLoaderFullOverlay}) => (
  <div style={{ textAlign: "center" }} className={`${displayLoaderFullOverlay ? "full-overlay" : "overlay"}`}>
      <div className={"loading-icon"}>
        <img className="w-75" src={Spinner} alt="loading"/>
          <h5>{message}</h5>
      </div>
  </div>
)

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps)(ProgressLoader);