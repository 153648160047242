import React, { Component } from 'react';
import {
  Input, FormGroup, Label,
  Col,
  Container, Row, FormText, Table
} from 'reactstrap';
import moment from "moment";
import ServerPaginatedTable from "../../components/ServerPagination";
import { appConstants } from "../../_constants";

const FmsTemplate = ({ record, handleChange, Comment, payload }) => {
  console.log(payload, "payload");
  if (!Array.isArray(payload)) {
    return null;
  }

  const headers = Object.keys(payload[0]);
  return (
    <Container>
     <Table responsive={true} className={"table-main"} style={{ backgroundColor: "white" }}>
     <thead className={"audit-head"}>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {payload.map((row, rowIndex) => (
            <tr key={rowIndex} style={{ borderTop: "12px solid transparent" }}>
              {headers.map((header, cellIndex) => (
                <td key={cellIndex}>
                  {/* {row[header]} */}
                  {header === 'dateTransmitted'
                    ? moment(row[header]).format('YYYY-MM-DD')
                    : row[header]}
                  </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <Row>
        {
          record.statusId !== appConstants.MAKER_CHECKER_STATUS.PENDING &&
          <Col sm="12">
            <FormGroup className="mb-4">
              <Label>Comment</Label>
              <Input type="text" disabled value={record.comment} name="Comment" />
            </FormGroup>
          </Col>
        }
        {
          record.statusId === appConstants.MAKER_CHECKER_STATUS.PENDING &&
          <Col sm="12">
            <FormGroup className="mb-4">
              <Label>Comment</Label>
              <Input type="text" value={Comment} name="Comment" onChange={handleChange} />
              {!Comment && <FormText color="danger">Comment is required.</FormText>}
            </FormGroup>
          </Col>
        }
      </Row>

    </Container>
  );
};

export default class TableFormat extends Component {
  render() {
    let {
      payload,
      currentPage,
      fetchMore,
      pageSize,
      totalCount,
      record,
       handleChange, 
       Comment,
      // onClickDecline,
      // onClickApprove,
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageSize={pageSize}
          //   onClickDecline={(e)=> onClickDecline()}
          //   onClickApprove={(e)=> onClickApprove(e)}
          totalCount={totalCount}
          target={<FmsTemplate
            payload={payload} pageIndex={pageIndex} record={record} handleChange={handleChange} Comment={Comment}
          />}
        />
      </div>
    );
  }
} 
