import React from "react";
import { FaFilter, FaArrowLeft } from "react-icons/fa";
import {Button} from "reactstrap";

const SearchInvoiceActionButtons = ({isVggRole, navigateToPreviousRoute, searchType, advancedClicked}) => {
    return (
        <>
           {/* {isVggRole && ( */}
                <div className={"float-right"}>
                    <Button
                    outline
                    color="info"
                    className=" btn-rounded mr-2 big-action-btn"
                    onClick={navigateToPreviousRoute}
                    >
                    <FaArrowLeft className={"icon-margin"} />
                    Back
                    </Button>
                </div>
               {/* ) */}
           {/* } */}
              <div className={"float-right"}>
                {searchType === 0 && ( //If on quick search, display advance search button
                  <Button
                    outline
                    color="info"
                    className="btn-rounded mr-2 big-action-btn"
                    onClick={() => advancedClicked(1)}
                  >
                    Advanced Filter <FaFilter />
                  </Button>
                )}
                {searchType === 1 && ( //if on advanced search display quick search button
                  <Button
                    outline
                    color="info"
                    className="btn-rounded mr-2 big-action-btn"
                    onClick={() => advancedClicked(0)}
                  >
                    <FaArrowLeft size={"20px"} className={"icon-margin"} />
                    Back to Quick Search
                  </Button>
                )}
            </div>
        </>
    )
}

export default SearchInvoiceActionButtons;