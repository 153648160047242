import React, { Component } from "react";
import { Table } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "react-toggle/style.css" // for ES6 modules
import "./index.scss";
import moment from "moment"
import { appConstants } from "../../../_constants";

const Records = (props) => {
    const { apiData, user } = props;
    return (
        <Table responsive={true} className={"table-main"}>
            <thead className={"tracker-head"}>
                <tr>
                    <th style={{ padding: "none" }}>InvoiceNo</th>
                    <th style={{ padding: "none" }}>Customer</th>
                    {user.vggRole === appConstants.ROLES.VGG_ADMIN &&
                        <th style={{ padding: "none" }}>Agency</th>
                    }
                    <th style={{ padding: "none" }}>Revenue</th>
                    <th style={{ padding: "none" }}>Amount</th>
                    <th style={{ padding: "none" }}>Date</th>
                    <th style={{ padding: "none" }}>Location</th>
                    <th style={{ padding: "none" }}>Status</th>
                </tr>
            </thead>
            <tbody>
                {apiData.map((item, index) => {
                    return (
                        <tr key={index}>
                            <th style={{ padding: "none" }}>{item.invoiceNo}</th>
                            <th style={{ padding: "none" }}>{item.customer}</th>
                            {user.vggRole === appConstants.ROLES.VGG_ADMIN &&
                                <th style={{ padding: "none" }}>{item.agencyName}</th>
                            }
                            <th style={{ padding: "none" }}>{item.revenue}</th>
                            <th style={{ padding: "none" }}>{item.amountString}</th>
                            <th style={{ padding: "none" }}>{moment(item.createdDate).format('YYYY-MM-DD')}</th>
                            <th style={{ padding: "none" }}>{item.location}</th>
                            <th style={{ padding: "none" }}>
                                {(item.status).toLowerCase() === "paid"
                                    ?
                                    <span className={"paid-invoice"}>{item.status}</span>
                                    :
                                    (item.status).toLowerCase() === "unpaid"
                                        ?
                                        <span className={"unpaid-invoice"}>{item.status}</span>
                                        :
                                        <span>{item.status}</span>
                                }
                            </th>                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};


export default class TableStructure extends Component {
    render() {
        let {
            apiData,
            currentPage,
            fetchMore,
            pageSize,
            totalCount,
            toggleModalFunction,
            handleSwitchChecked,
            toggle,
            acivateLocation,
            changeSwitch,
            user,
            downloadPerformanceTracker
        } = this.props;
        const pageIndex = currentPage === 0 ? 1 : ((pageSize * (currentPage - 1)) + 1);
        return (
            <div>
                <ServerPaginatedTable
                    currentPage={currentPage}
                    fetchMore={fetchMore}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    bottomDownload
                    downloadPdf={() => {
                        downloadPerformanceTracker(0, 'pdf');
                    }}
                    downloadExcel={() => {
                        downloadPerformanceTracker(0, 'excel');
                    }}
                    target={<Records apiData={apiData}
                        toggleModalFunction={toggleModalFunction}
                        changeSwitch={changeSwitch}
                        toggle={toggle}
                        acivateLocation={acivateLocation}
                        handleSwitchChecked={handleSwitchChecked}
                        pageIndex={pageIndex}
                        user={user}
                    />}
                />
            </div>
        );
    }
}
