import React, { useState, useEffect } from "react";
import "./modal.css";
import { Button, Dropdown, DropdownItem, DropdownMenu,DropdownToggle } from "reactstrap";

const FullScreenModal = (props) => {
  let {
    show,
    children,
    callback,
    isInvoiceSheet,
    actions,
    width,
    top,
    zIndex,
    left,
    nestedActions,
  } = props;
  const body = document.body;
  body.style.overflow = "hidden";
  const [shouldShow, showModal] = useState(show);

    const [dropdownOpen, setDropdownOpen] = useState({state: false, value: false});

    const toggleDropdown = (id) => setDropdownOpen((prevState) => {
      return {state: !prevState.state, value: id}
    }
      );

  const toggle = () => {
    showModal(!shouldShow);
    const body = document.body;
    body.style.overflow = "scroll";
    if (callback) {
      callback(false);
    }
  };

  useEffect((effect) => {
    if (!shouldShow) {
      const body = document.body;
      body.style.overflow = "scroll";
    }
  });

  const renderActions = (actions, nestedActions) => {
    let actionElems = [];
    let count = 0;
    for (let act in actions) {
      count++;
      let icon = `fa ${actions[act].icon}`;
      let name = actions[act].name;
      let width = actions[act].width ? actions[act].width : `auto`;
      let action = actions[act].action ? actions[act].action : null;
      actionElems.push(
        <div key={count}>
          <Button
            key={count}
            className="invoiceButtons"
            onClick={action ? action : () => {}}
            style={{ width: `${width}` }}
          >
            <i className={icon}></i> &nbsp;{name}
          </Button>
        </div>
      );
    }
    let nestedContainer = "";
    if (nestedActions) {
      nestedContainer = (
        nestedActions.map(item => {
           
           return (
             <Dropdown
             className="fullscreen-dropdown"
               key={item.id}
               isOpen={dropdownOpen.value === item.id && dropdownOpen.state}
               toggle={() => toggleDropdown(item.id)}
             >
               <DropdownToggle caret>
                 {item.title}
               </DropdownToggle>
               <DropdownMenu left>
                 {item.actions.map((item) => {
                    const nestedAction = item.action
                    const icon = `fa ${item.icon}`;
                    const name = item.name;
                    const width = item.width
                      ? item.width
                      : `100%`;
                   return (
                     <DropdownItem
                       key={item.id}
                       onClick={nestedAction ? nestedAction : () => {}}
                       style={{ width: `${width}` }}
                     >
                       <i className={icon}></i> &nbsp;{name}
                     </DropdownItem>
                   );
                 })}
               </DropdownMenu>
             </Dropdown>
           );
        }))}
       
      actionElems.push(nestedContainer);
    
  return actionElems;
  };

  return (
    <React.Fragment>
      {shouldShow && (
        <div
          id="myModal"
          className="activityModal"
          style={{ zIndex: zIndex ? zIndex : 9999 }}
        >
          <div
            className="activityModal-content"
            style={{
              width: width ? width : "70%",
              top: top ? top : 0,
              left: left ? left : "5%",
            }}
          >
            <div
              className="modal-header"
              style={{ padding: 0, margin: 0, border: 0, borderTop: 0 }}
            >
              <span
                className="close "
                style={{ marginRight: "-50px", opacity: "1" }}
              >
                <div
                  className="spanClose"
                  style={{ marginBottom: "1rem", color: "white" }}
                  onClick={toggle}
                >
                  &times;
                </div>

                {isInvoiceSheet && actions && (
                  <React.Fragment>{renderActions(actions, nestedActions)}</React.Fragment>
                )}
              </span>
            </div>
            {children}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default FullScreenModal;
