import React from "react";
import { Table, UncontrolledTooltip, Button, Input } from "reactstrap";
import Toggle from "react-toggle";
import { FaEllipsisV } from "react-icons/fa";
import PaginatedTableWithCustomButtons from "../../../components/PaginatedTableWithCustomButtons.js";
import "./index.scss";

const CustomersTable = ({
  tableData,
  handleSwitchChecked,
  handleChangeRoute,
  mergeBtnActive,
  unMergeBtnActive,
  selectedCustomer,
  storeSelectedCustomerToUnMerge,
}) => {
  return (
    <Table responsive striped hover>
      <thead className={"table-head-blue"}>
        <tr>
          {(mergeBtnActive && <th></th>) || (unMergeBtnActive && <th></th>)}
          <th>Name</th>
          <th>Email address</th>
          <th>IN</th>
          <th>Account Code</th>
          <th>No of Sub customers</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((item, index) => {
            return (
              <tr key={index} className={"table-row"}>
                {mergeBtnActive && (
                  <td>
                    <Input
                      style={{
                        position: "static",
                        width: "40px",
                        marginLeft: "3px",
                      }}
                      type="checkbox"
                      id="checkbox"
                      onChange={(e) => selectedCustomer(e, item)}
                      checked={item.selected}
                    />
                  </td>
                )}
                {unMergeBtnActive && (
                  <td>
                    <Input
                      style={{
                        position: "static",
                        width: "40px",
                        marginLeft: "3px",
                      }}
                      type="radio"
                      name="unMergeBtnCustomer"
                      onChange={(e) => storeSelectedCustomerToUnMerge(e, item)}
                    />
                  </td>
                )}
                <td
                  onClick={() =>
                    handleChangeRoute(
                      "/customer-details",
                      "selectedCustomer",
                      item
                    )
                  }
                >
                  {item.customerName}
                </td>
                <td
                  onClick={() =>
                    handleChangeRoute(
                      "/customer-details",
                      "selectedCustomer",
                      item
                    )
                  }
                >
                  {item.email}
                </td>
                <td
                  onClick={() =>
                    handleChangeRoute(
                      "/customer-details",
                      "selectedCustomer",
                      item
                    )
                  }
                >
                  {item.customerAIN}
                </td>
                <td
                  onClick={() =>
                    handleChangeRoute(
                      "/customer-details",
                      "selectedCustomer",
                      item
                    )
                  }
                >
                  {item.accountCode}
                </td>
                <td>{item.subCustomersCount}</td>
                <td onClick={(e) => handleSwitchChecked(e, item)}>
                  <Toggle
                    checked={item.status}
                    onChange={(e) => handleSwitchChecked(e, item)}
                  />
                </td>
                <td>
                  <FaEllipsisV
                    onClick={() =>
                      handleChangeRoute(
                        "/customer-details",
                        "selectedCustomer",
                        item
                      )
                    }
                    id={`Tooltip${index}`}
                  />
                  <UncontrolledTooltip
                    placement="bottom-end"
                    target={`Tooltip${index}`}
                  >
                    Customer Details
                  </UncontrolledTooltip>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

const TableStructure = ({
  tableData,
  handleSwitchChecked,
  handleChangeRoute,
  currentPage,
  fetchMoreRecords,
  pageSize,
  totalCount,
  mergeBtnActive,
  unMergeBtnActive,
  mergeBtnClick,
  unMergeBtnClick,
  selectedCustomer,
  requesting,
  onCancelClick,
  onContinueClick,
  storeSelectedCustomerToUnMerge,
}) => {
  const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
  return (
    <div>
      <PaginatedTableWithCustomButtons
        currentPage={currentPage}
        fetchMore={fetchMoreRecords}
        pageSize={pageSize}
        totalCount={totalCount}
        topElement
        topChildren={
          <>
            <Button
              onClick={() => mergeBtnClick("merge")}
              className={
                mergeBtnActive
                  ? `active btn-rounded btn-outline-info mr-2`
                  : `btn-rounded btn-outline-info mr-2`
              }
            >
              Merge
            </Button>
            <Button
              onClick={() => unMergeBtnClick("unmerge")}
              className={
                unMergeBtnActive
                  ? `active btn-rounded btn-outline-info mr-2`
                  : `btn-rounded btn-outline-info mr-2`
              }
            >
              Unmerge
            </Button>
          </>
        }
        bottomElement={mergeBtnActive || unMergeBtnActive}
        bottomChildren={
          <>
            <Button
              outline
              className="btn-sm btn-rounded mr-2 cancel-btn"
              onClick={onCancelClick}
              disabled={requesting}
            >
              Cancel
            </Button>
            <Button
              color="success"
              className="btn-sm btn-rounded mr-2 text-white"
              onClick={onContinueClick}
              disabled={requesting}
            >
              Continue
            </Button>
          </>
        }
        target={
          <CustomersTable
            tableData={tableData}
            handleSwitchChecked={handleSwitchChecked}
            handleChangeRoute={handleChangeRoute}
            pageIndex={pageIndex}
            selectedCustomer={selectedCustomer}
            storeSelectedCustomerToUnMerge={storeSelectedCustomerToUnMerge}
            mergeBtnActive={mergeBtnActive}
            unMergeBtnActive={unMergeBtnActive}
          />
        }
      />
    </div>
  );
};

export default TableStructure;
