import React from "react";
import {
  Table,
  Card,
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

const SimilarCustomer = ({
  requesting,
  addCustomer,
  closeSimilarCustomerModal,
  selectCustomer,
  similarData,
}) => {
  return (
    <div className={"similar-customer"}>
      <Card className={"similar-customer__card"}>
        <div className={"similar-customer__title"}>
          <h6 className={"similar-customer__title-main"}>
            Similar customer list
          </h6>
          <span className={"similar-customer__title-subtitle"}>
            Please confirm that the customer you want to add does not already
            exist. If customer exists, click customer to proceed to create
            invoice
          </span>
        </div>
        <Table striped className={"similar-customer__table"} hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Code</th>
            </tr>
          </thead>
          <tbody>
            {similarData.map((item, i) => {
              return (
                <>
                  <tr key={i} id={`PopoverLegacy${i}`}>
                    <th>{item.customerName}</th>
                    <th>{item.customerAIN}</th>
                  </tr>
                  <UncontrolledPopover
                    trigger="legacy"
                    placement="bottom"
                    target={`PopoverLegacy${i}`}
                  >
                    <PopoverHeader>
                      Are you sure you want to select {item.customerName}?
                    </PopoverHeader>
                    <PopoverBody>
                      <span className="text-center">
                        <Button
                          color="success"
                          size="sm"
                          className="btn-rounded mr-2 text-white py-1 px-4"
                          disabled={requesting}
                          onClick={() => selectCustomer(item)}
                        >
                          Proceed
                        </Button>
                      </span>
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              );
            })}
          </tbody>
        </Table>
        <div className={"pl-3 py-4 similar-customer__buttons"}>
          <Button
            color="success"
            size="sm"
            className="btn-rounded mr-2 text-white py-1 px-4"
            disabled={requesting}
            onClick={() => addCustomer()}
          >
            Proceed
          </Button>
          <Button
            outline
            className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
            disabled={requesting}
            onClick={closeSimilarCustomerModal}
          >
            Cancel
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default SimilarCustomer;
