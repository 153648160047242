import { appConstants } from '../_constants';
import {initialState} from "../_store";

export function entities(state=initialState.entities, action) {
    switch (action.type) {
        case appConstants.GET_ENTITIES:
            return action.entities;
        default:
            return state
      }
}
