export const alertConstants = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  NOTIFY: "ALERT_NOTIFY",
  CLEAR: "ALERT_CLEAR",
  START_REQUEST: "START_REQUEST",
  STOP_REQUEST: "STOP_REQUEST",
  START_APP_REQUEST: "START_APP_REQUEST",
  STOP_APP_REQUEST: "STOP_APP_REQUEST",
  RESET_APP: "RESET_APP",
};

export const modalConstants = {
  SUCCESS: "MODAL_SUCCESS",
  ERROR: "MODAL_ERROR",
  NOTIFY: "MODAL_NOTIFY",
  CLEAR: "MODAL_CLEAR",
  DISPLAY_SECONDARY_ALERT: "DISPLAY_SECONDARY_ALERT",
  DISPLAY_DIALOG_ALERT: "DISPLAY_DIALOG_ALERT",
  DISPLAY_GENERAL_ALERT: "DISPLAY_GENERAL_ALERT",
  DISPLAY_SHARE_INVOICE: "DISPLAY_SHARE_INVOICE",
  DISPLAY_INVOICE_SHEET: "DISPLAY_INVOICE_SHEET",
  DISPLAY_CREATED_INVOICE: "DISPLAY_CREATED_INVOICE",
  DISPLAY_VOID_REQUEST: "DISPLAY_VOID_REQUEST",
  DISPLAY_CHANGE_PASSWORD_MODAL: "DISPLAY_CHANGE_PASSWORD_MODAL",
  DISPLAY_DOUBLE_INVOICES: "DISPLAY_DOUBLE_INVOICES",
};
