import React from "react";
import Select from "react-select";
// import "react-select/dist/react-select.css";

class SelectList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      clearable: true,
      selectValue: ""
    };
  }

  render() {
    // let { clearable } = this.state;

    let {
      data,
      valueKey,
      textField,
      selectValue,
      onValueChange,
      multiSelectMode,
      placeholder,
      disabled,
      // clearable,
      customStyles,
      defaultValue,
        name,
      notClearable,
      rest
    } = this.props;
    multiSelectMode = multiSelectMode !== undefined;
    // disabled = disabled !== undefined ? disabled : false;
    // clearable = clearable !== undefined ? clearable : true;
    return (
      <div>
        <Select
          styles={customStyles}
          placeholder={placeholder}
          options={data}
          isMulti={multiSelectMode}
          defaultValue={defaultValue}
          autofocus
          isClearable={notClearable ? false: true}
          isDisabled={disabled}
          value={selectValue}
          onChange={onValueChange}
          name={name}
          getOptionLabel={(data) => data[textField]}
          getOptionValue={(data) => data[valueKey]}
          className={"select-list"}
          classNamePrefix={"react-select"}
           {...rest}
          // isOptionDisabled={(option: {}, value: [{}]) => boolean}
          // isOptionSelected={(option: {}, value: [{}]) => boolean}
        />
      </div>
    );
  }
}

export default SelectList;
