import React, { Component } from "react";
import ContentLayout from "../../../components/ContentLayout";
import CreditNoteCard from './credit-note-card';
import {Row, Col, Button} from 'reactstrap';
import BackButton from '../../../components/BackButton';
import { appHelpers } from "../../../_helpers";
export default class CreateCreditNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "/dashboard"
    };
  }

  clickedCard = (link) => {
    this.setState({link})

  }
  proceed = () => {
    const {link} = this.state;
   appHelpers.navigateRoute.call(this, link)
  }
  render() {
    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle="Apply Credit Note"
        actions={
          <BackButton
            navigateBack={() => appHelpers.navigateToPreviousRoute.call(this)}
          />
        }
      >
        <Row>
          <Col md="6">
            <CreditNoteCard
              title="Discount and Waivers"
              text="Apply waivers or discount to bulk or single invoice transactions"
              clickedCard={() => this.clickedCard('/search-credit-note')}

            />
          </Col>
          <Col md="6">
            <CreditNoteCard
              title="Over Payment"
              text="Make adjustment to a the total cost on an invoice "
              clickedCard={() => this.clickedCard('/over-payment')}
            />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <CreditNoteCard
              title="Credit-Note Issuance"
              text="Apply waivers or discount to bulk or single invoice transactions"
              clickedCard={() => this.clickedCard('/credit-note-issuance')}

            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="12" className="text-right">
          <Button
            color="success"
            className="btn-sm px-5 py-2 btn-rounded mr-2 text-white"
            onClick={this.proceed}
          >
            Proceed
          </Button>
          </Col>
        </Row>
      </ContentLayout>
    );
  }
}
