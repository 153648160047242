import React from 'react';
import ActiveCheckbox from "../../../components/ActiveCheckbox";

const ViewBankInformation = ({bankName, accountNumber, accountName, alias, accountType, currency, checked}) => {
  return (
    <div className={"view-bank-information"}>
      <div className={"view-bank-information__detail"}>
        <span className={"view-bank-information__detail-title"}>
          Bank Name
        </span>
        <p>{bankName}</p>
      </div>
      <div className={"view-bank-information__detail"}>
        <span className={"view-bank-information__detail-title"}>
          Account Number
        </span>
        <p>{accountNumber} ({accountName})</p>
      </div>
      <div className={"view-bank-information__detail"}>
        <span className={"view-bank-information__detail-title"}>
          Alias
        </span>
        <p>{alias}</p>
      </div>
      <div className={"view-bank-information__detail"}>
        <span className={"view-bank-information__detail-title"}>
          Account Type
        </span>
        <p>{accountType}</p>
      </div>
      <div className={"view-bank-information__detail"}>
        <span className={"view-bank-information__detail-title"}>
          Currency
        </span>
        <p>{currency}</p>
      </div>
      <div className={"view-bank-information__detail mt-3"}>
        <ActiveCheckbox
          text={"Set as active account"}
          checked={checked}
        />
      </div>
    </div>
  );
};

export default ViewBankInformation;