import React, { Component } from "react";
import { Button } from "reactstrap";
import ContentLayout from "../../../components/ContentLayout";
import { appHelpers } from "../../../_helpers";
import { modalActions, alertActions } from "../../../_actions";
import { connect } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { MergeCustomersTable } from "./mergeCustomersTable";
import { customerService } from "../../../_services";
import axios from "axios";
import { appConstants } from "../../../_constants";
import GeneralAlert from "../../../components/Api-response-alert";

let source;
// This view is used for unmerge customer and merged customers success view
class MergedCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      selectedSubCustomers: [],
      processType: "Unmerge Customers",
      unMergeSuccessStatus: false,
      successMessage: null,
      parentCustomer: null,
      agencyId: null,
    };
    source = axios.CancelToken.source();
  }

  componentDidMount = () => {
    let stateFromPath = this.props.history.location.state;
    const { dispatch } = this.props;
    if (stateFromPath) {
      // if user is coming straight from "/manage-customer" route,
      // that means the user wants to unmerge customers
      if (stateFromPath.route === "/manage-customers") {
        let parentCustomer = stateFromPath.customersToUnMerge.filter(
          (item) => item.isParent === true
        );
        // let tableData = stateFromPath.customersToUnMerge
        //   .filter((item) => item.isParent === false)
        //   .map((item) => {
        //     item.selected = false;
        //     return item;
        //   });
        this.setState(
          {
            processType: "Unmerge Customers",
            // tableData,
            parentCustomer: parentCustomer[0],
            agencyId: stateFromPath.agencyId,
            agencyDetails: stateFromPath.agencyDetails,
          },
          () => {
            if (this.state.parentCustomer.customerId) {
              this.getSelectedCustomerDetails();
            }
          }
        );
      }
    }
    // If user is coming from "/settings-customers-to-merge" route,
    // that means the user is done with the merge process
    if (stateFromPath.route === "/settings-customers-to-merge") {
      let parentCustomer = stateFromPath.mergedCustomers.filter(
        (item) => item.isParent === true
      );
      this.setState(
        {
          processType: "Merged Customers",
          tableData: stateFromPath.mergedCustomers.filter(
            (item) => item.isParent === false
          ),
          parentCustomer: parentCustomer[0],
          agencyId: stateFromPath.agencyId,
          successMessage: stateFromPath.message,
          agencyDetails: stateFromPath.agencyDetails,
        },
        () => {
          if (this.state.successMessage) {
            dispatch(alertActions.success(this.state.successMessage));
          }
        }
      );
    }
  };

  storeSelectedSubCustomer = (e, selectedItem) => {
    e.persist();
    if (e.target.checked) {
      this.setState((prevState) => ({
        selectedSubCustomers: [...prevState.selectedSubCustomers, selectedItem],
        tableData: prevState.tableData.map((item) => {
          if (item.customerId === selectedItem.customerId) {
            item.selected = true;
          }
          return item;
        }),
      }));
    } else if (!e.target.checked) {
      this.setState((prevState) => ({
        selectedSubCustomers: prevState.selectedSubCustomers.filter(
          (item) => item.customerId !== selectedItem.customerId
        ),
        tableData: prevState.tableData.map((item) => {
          if (item.customerId === selectedItem.customerId) {
            item.selected = false;
          }
          return item;
        }),
      }));
    }
  };

  unMergeCustomers = () => {
    const { agencyId, parentCustomer, selectedSubCustomers } = this.state;
    const { dispatch } = this.props;
    if (this.validateUnMergeProcess()) {
      let childCustomerIds = selectedSubCustomers.map(
        (item) => item.customerId
      );

      let payload = {
        agencyId,
        parentCustomerId: parentCustomer.customerId,
        childCustomerIds,
      };

      dispatch(alertActions.startRequest());
      customerService.unMergeCustomer(payload, source.token).then((res) => {
        let response = res.response;
        dispatch(alertActions.stopRequest());
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({ refreshing: true, selectedSubCustomers: [] });
          this.getSelectedCustomerDetails();

          dispatch(alertActions.success(response.message));
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(response.message));
        }
      });
    }
  };

  getSelectedCustomerDetails = () => {
    const { dispatch } = this.props;
    const { parentCustomer, agencyId } = this.state;

    let payload = {
      agencyId,
      parentCustomerId: parentCustomer.customerId,
    };
    dispatch(alertActions.startRequest());
    customerService
      .getMergeCustomerDetails(payload, source.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        let parentCustomer;
        if (response) {
          parentCustomer = response.data.filter(
            (item) => item.isParent === true
          );
        }

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          let response = res.response;
          this.setState({
            tableData: response.data.filter((item) => item.isParent === false),
            parentCustomer: parentCustomer[0],
          });
          if (response.data.length <= 0 || response.data === null) {
            this.setState({ unMergeSuccessStatus: true });
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "failure",
            })
          );
        }
      });
  };

  cancelUnmergeProcess = () => {
    this.setState({ selectedSubCustomers: [] });
    appHelpers.navigateRoute.call(this, "/manage-customers");
  };

  validateUnMergeProcess = () => {
    const { dispatch } = this.props;
    const { selectedSubCustomers } = this.state;
    let isValid = true;
    if (selectedSubCustomers.length <= 0) {
      isValid = false;
      dispatch(
        modalActions.displayGeneralAlert({
          show: true,
          message: "Please select subcustomer(s) to unmerge",
          action: "failure",
        })
      );
    }

    return isValid;
  };

  componentWillUnmount = () => {
    if (source) {
      source.cancel();
    }
  };
  render() {
    const {
      tableData,
      processType,
      unMergeSuccessStatus,
      parentCustomer,
      agencyDetails,
    } = this.state;
    const { requesting } = this.props;
    let previousPath = this.props.history.location.state.route;
    let pathState = {
      pathName: previousPath,
      selectedAgencyDetails: agencyDetails,
    };
    return (
      <ContentLayout
        requesting={requesting}
        pageTitle={processType}
        actions={
          <div className={"float-right"}>
            {processType === "Unmerge Customers" && (
              <Button
                color="info"
                outline
                className="btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
                disabled={requesting}
              >
                <FaArrowLeft className={"mb-1 mr-1"} /> Back
              </Button>
            )}
          </div>
        }
      >
        {processType === "Unmerge Customers" && (
          <div className="mb-5">
            <h5>Choose sub customer(s) to unmerge</h5>
          </div>
        )}
        {parentCustomer && (
          <div
            className={
              "d-flex justify-content-between  align-items-center mb-2"
            }
          >
            <h6>Parent Customer : {parentCustomer.customerName}</h6>
            <h6>ID: {parentCustomer.customerId}</h6>
            <h6>Sub Customers : {tableData.length}</h6>
          </div>
        )}
        {processType === "Unmerge Customers" && tableData.length === 0 && (
          <div
            className={"d-flex justify-content-center align-items-center mt-4"}
          >
            <p>No sub customers to unmerge</p>
          </div>
        )}
        {tableData.length > 0 && (
          <MergeCustomersTable
            tableData={tableData}
            storeSelectedSubCustomer={this.storeSelectedSubCustomer}
            hasSubCustomers={processType === "Merged Customers" ? true : false}
          />
        )}
        <section className={"proceed-section"}>
          <div className={"proceed-section__prompt"}></div>
          <div className={"proceed-section__buttons"}>
            {!unMergeSuccessStatus && processType === "Unmerge Customers" && (
              <button
                className={"ghost-button"}
                onClick={this.cancelUnmergeProcess}
              >
                Cancel
              </button>
            )}
            {!unMergeSuccessStatus && (
              <Button
                color="success"
                className="btn-sm btn-rounded px-4 text-white"
                onClick={() =>
                  processType === "Merged Customers"
                    ? appHelpers.navigateRoute.call(
                        this,
                        "/manage-customers",
                        pathState
                      )
                    : this.unMergeCustomers()
                }
              >
                {processType === "Merged Customers" ? "Done" : "Unmerge"}
              </Button>
            )}
            {unMergeSuccessStatus && (
              <Button
                color="success"
                className="btn-sm px-4 btn-rounded mr-2 text-white"
                onClick={() =>
                  appHelpers.navigateRoute.call(
                    this,
                    "/manage-customers",
                    pathState
                  )
                }
              >
                Done
              </Button>
            )}
          </div>
        </section>
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, displayGeneralAlert } = state;
  return {
    user,
    requesting,
    displayGeneralAlert,
  };
};

export default connect(mapStateToProps)(MergedCustomers);
