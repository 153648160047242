import React from "react";
import { Card, CardBody } from "reactstrap";

const MobileAlert = ({ icon, message }) => {
  return (
    <Card style={{ borderRadius: "10px", height: "30%",}}>
      <CardBody
        className={"d-flex justify-content-center"}
        style={{ padding: "4rem 2rem" }}
      >
        <div>
          <p className={"text-center"}> {icon}</p>
          <h3 style={{ fontWeight: "300", textAlign: "center", fontSize:"16px" }}>{message}</h3>
        </div>
      </CardBody>
    </Card>
  );
};

export default MobileAlert;
