import React from 'react';
import SecondaryAlert from "../../../components/SecondaryAlert";
import ErrorTable from "./error-table";
import {Button} from "reactstrap";

const SimilarRevenueAlert = ({cancel, addNewAccount, requesting, message, accountRecords}) => {
  return (
    <SecondaryAlert>
      <p>{message}</p>
      <ErrorTable
        accountRecords={accountRecords}
      />
      <div className={"next-step-buttons"}>
        <button className={"ghost-button"}
                onClick={cancel}
        >
          Cancel
        </button>
        <Button
          color="success"
          className="btn-sm btn-rounded mr-2 text-white"
          onClick={addNewAccount}
          disabled={requesting}
        >
          Proceed
        </Button>
      </div>
    </SecondaryAlert>
  );
};

export default SimilarRevenueAlert;