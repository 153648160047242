import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import Header from "./layout-components/header/header";
import Sidebar from "./layout-components/sidebar/sidebar";
import Footer from "./layout-components/footer/footer";
// import ThemeRoutes from "../routes/router"; // previously used route, routes now come from the login endpoint
import ComponentsShowcase from "../views/components-showcase";
import { commonActions } from "../_actions";
import SearchInvoice from "../views/search-invoice/index";
import Dashboard from "../views/dashboard/dashboard";
import AuditTrail from "../views/audittrail/audittrail";
import ManageWorkflowApprovals from "../views/managevoid-requests";
import CreateInvoice from "../views/createinvoice";
import Settings from "../views/Settings";
import UpdateProfile from "../views/Settings/update-profile";
import ManageLocations from "../views/Settings/manage-locations";
import RolesAndPermissions from "../views/Settings/roles-and-permissions";
import ManageServiceType from "../views/Settings/manage-service-type";
import ManageUsers from "../views/Settings/manage-users";
import ManageStaffs from "../views/Settings/manage-staffs";
import CreateUser from "../views/Settings/create-user";
import CreateRole from "../views/Settings/create-role";
import CreateStaffs from "../views/Settings/create-staffs";
import WorkflowManagement from "../views/Settings/workflow-management";
import UserMapping from "../views/Settings/workflow-management/user-mapping";
import MakerChecker from "../views/maker-checker";
import InvoiceAgeAnalysis from "../views/manage-reports/invoice-age-analysis";
import PerformanceTracker from "../views/manage-reports/performance-tracker";
import ManageCustomers from "../views/Settings/manage-customers";
import NewCustomer from "../views/Settings/manage-customers/new-customer";
import SelectAgency from "../views/Settings/vgg-admin-agency";
import CustomerDetails from "../views/Settings/manage-customers/customer-details";
import Branding from "../views/Settings/Branding";
import { history } from "../jwt/_helpers";
// import {appConstants} from "../_constants";
// import ProgressLoader from "../components/ProgressLoader";
// import Customizer from "./layout-components/customizer/customizer";
// import {setNavbarBg} from "../redux/settings/action";
// import ProgressLoader from "../components/ProgressLoader";
import { AppProgressLoader } from "../components/AppProgressLoader";
import PaymentReference from "../views/payments";
import Accounts from "../views/Settings/accounts";
import SettlementSplit from "../views/Settings/settlement";
import AddNewBankAccount from "../views/Settings/accounts/add-new-account";
import ReviewAccount from "../views/Settings/accounts/review-account";
import AddSettlementSplit from "../views/Settings/settlement/add-settlement-split";
import EditSettlementSplit from "../views/Settings/settlement/edit-settlement-split";
import ReviewSettlementSplit from "../views/Settings/settlement/review-settlement-split";
import ViewSettlementSplitPerRevenue from "../views/Settings/settlement/view-settlement-split-per-revenue";
import CustomerCreateInvoice from "../views/self-service/customer-create-invoice";
import CustomerSearchInvoice from "../views/self-service/customer-search-invoice";
import ViewSelfServiceSetup from "../views/Settings/self-service-setup/view-self-service-setup";
import CreateSelfServiceSetup from "../views/Settings/self-service-setup/create-self-service-setup";
import ReviewSelfServiceSetup from "../views/Settings/self-service-setup/review-self-service-setup";

import { showSidebarLayout } from "../redux/settings/action";
import ViewUserProfile from "../views/Settings/user-profile";
import CustomersToMerge from "../views/Settings/manage-customers/customers-to-merge";
import MergedCustomers from "../views/Settings/manage-customers/merged-customers";
import EditInvoiceSettings from "../views/Settings/edit-invoice-settings";
import CreateCreditNote from "../views/credit-note/create-credit-note/create-credit-note";
import MyRequests from "../views/credit-note/my-requests/my-requests";
import Report from "../views/credit-note/report/index.js";
import searchCreditNote from "../views/credit-note/create-credit-note/search-credit-note";
import ApplyCreditNote from "../views/credit-note/create-credit-note/apply-credit-note";
import AppliedCreditNote from "../views/credit-note/create-credit-note/applied-credit-note";
import RequestDetails from "../views/credit-note/my-requests/request-details";
import NIBBSPaymentUpload from "../views/fmsUpload/fmsUpload";
import RemitaFailureReport from "../views/manage-reports/remita-failure-report";
import InvoiceCreatorUpdate from "../views/Support/Invoice-creator-update";
import Support from "../views/Support/support";
import PaymentReferenceUpdate from "../views/Support/RRRPaymentUpdate/index"
import ManagePaymentReport from "../views/Support/ManagePaymentReport";
import InvoiceAgingConfiguration from "../views/manage-reports/invoice-aging-configuration";
import InvoiceApprovalUpdate from "../views/Support/InvoiceApprovalUpdate";
import HelpCenter from "../views/help-center/index"

const mapStateToProps = (state) => ({
  ...state,
});

class Fulllayout extends React.Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      isOpen: false,
      width: window.innerWidth,
      dynamicRoutes: [],
    };

    this.props.history.listen((location, action) => {
      // const {dispatch} = this.props;
      if (
        window.innerWidth < 767 &&
        document
          .getElementById("main-wrapper")
          .className.indexOf("show-sidebar") !== -1
      ) {
        document
          .getElementById("main-wrapper")
          .classList.toggle("show-sidebar");
      }
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook, Applies when loading or resizing App                           */
  /*--------------------------------------------------------------------------------*/

  componentDidMount() {
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);

    this.dynamicRoutes();
    this.configureTheme();
    this.props.dispatch(showSidebarLayout(true));

    // this.fetchBranding();
  }

  //Point the components in the routes from the
  // login endpoint to the imported components
  //and set the dynamic routes in state.
  dynamicRoutes = () => {
    const { user, dispatch } = this.props;
    const { routes } = user;

    if (routes && routes.length > 0) {
      let firstRoute = routes[0];
      let baseRoute = firstRoute.path;

      let { path, name } = firstRoute;
      dispatch(commonActions.baseRoute(baseRoute));

      let modifiedUserRoutes = routes.map((item) => {
        if (item.name === "Dashboard") {
          item.component = Dashboard;
        }
        if (item.path === "/search-invoice") {
          item.component = SearchInvoice;
        }
        if (item.path === "/audit-trails") {
          item.component = AuditTrail;
        }
        if (item.path === "/payments") {
          item.component = PaymentReference;
        }
        if (item.path === "/create-invoice") {
          item.component = CreateInvoice;
        }
        if (item.path === "/customer-create-invoice") {
          item.component = CustomerCreateInvoice;
        }
        if (item.path === "/customer-search-invoice") {
          item.component = CustomerSearchInvoice;
        }
        if (item.path === "/void-requests") {
          item.component = ManageWorkflowApprovals;
        }
        if (item.path === "/settings") {
          item.component = Settings;
          delete item.notVisible;
        }
        if (item.path === "/maker-checker") {
          item.component = MakerChecker;
        }
        if (item.path === "/create-role") {
          item.component = CreateRole;
        }
        if (item.path === "/user-mapping") {
          item.component = UserMapping;
        }
        if (item.path === "/new-customer") {
          item.component = NewCustomer;
        }
        if (item.path === "/select-agency") {
          item.component = SelectAgency;
        }
        if (item.path === "/customer-details") {
          item.component = CustomerDetails;
        }
        if (item.path === "/settings-customers-to-merge") {
          item.component = CustomersToMerge;
        }
        if (item.path === "/settings-merged-customers") {
          item.component = MergedCustomers;
        }
        if (item.path === "/add-new-bank-account") {
          item.component = AddNewBankAccount;
        }
        if (item.path === "/review-account-info") {
          item.component = ReviewAccount;
        }
        if (item.path === "/add-settlement-split") {
          item.component = AddSettlementSplit;
        }
        if (item.path === "/edit-settlement-split") {
          item.component = EditSettlementSplit;
        }
        if (item.path === "/review-settlement-split") {
          item.component = ReviewSettlementSplit;
        }
        if (item.path === "/view-settlement-split-per-revenue") {
          item.component = ViewSettlementSplitPerRevenue;
        }
        if (item.path === "/settings-review-self-service-setup") {
          item.component = ReviewSelfServiceSetup;
        }
        if (item.path === "/search-credit-note") {
          item.component = searchCreditNote;
        }
        if (item.path === "/apply-credit-note") {
          item.component = ApplyCreditNote;
        }
        if (item.path === "/applied-credit-note") {
          item.component = AppliedCreditNote;
        }
        if (item.path === "/request-details") {
          item.component = RequestDetails;
        }
        if (item.path === "/nibbs-payment-upload") {
          item.component = NIBBSPaymentUpload;
        }
        if (item.path === "/help-center") {
          item.component = HelpCenter;
        }
        
        return item;
      });

      let settingsRoutes;
      let settingsParent = modifiedUserRoutes.find(
        (item) => item.path === "/settings"
      );

      if (settingsParent && settingsParent.child) {
        settingsRoutes = settingsParent.child.map((item) => {
          if (item.path === "") {
            delete item.path;
          }
          if (item.component === "") {
            delete item.component;
          }
          return item;
        });
      }

      let newRoute = modifiedUserRoutes.map((item) => {
        if (item.path === "/settings") {
          item.child = settingsRoutes;
        }
        return item;
      });

      let updatedRoute = newRoute.map((item) => {
        if (item.collapse && item.child) {
          item.child.forEach((item) => {
            delete item.notVisible;
            if (item.path === "/workflow-management") {
              item.component = WorkflowManagement;
            }
            if (item.path === "/settings-user-profile") {
              item.component = ViewUserProfile;
            }
             if (item.path === "/edit-invoice-settings") {
               item.component = EditInvoiceSettings;
             }
             if (item.path === "/create-credit-note") {
              item.component = CreateCreditNote;
            }
            if (item.path === "/my-requests") {
              item.component = MyRequests;
            }
            if (item.path === "/report") {
              item.component = Report;
            }
            if (item.path === "/remita-failure-report") {
              item.component = RemitaFailureReport;
            } 
            if (item.path === "/payment-reference-update") {
              item.component = PaymentReferenceUpdate;
            }
            if (item.path === "/invoice-creator-update") {
              item.component = InvoiceCreatorUpdate;
            }
            if (item.path === "/manage-payment-report") {
              item.component = ManagePaymentReport;
            }

            if (item.path === "/invoice-aging-configuration") {
              item.component = InvoiceAgingConfiguration;
            }
            if (item.path === "/invoice-approval-update") {
              item.component = InvoiceApprovalUpdate;
            }

            if (item.collapse && item.subchild) {
              item.subchild.forEach((item) => {
                delete item.notVisible;

                if (item.path === "/settings-update-profile") {
                  item.component = UpdateProfile;
                }
                if (item.path === "/settings-manage-locations") {
                  item.component = ManageLocations;
                }
                if (item.path === "/manage-service-type") {
                  item.component = ManageServiceType;
                }
                if (item.path === "/roles-and-permissions") {
                  item.component = RolesAndPermissions;
                }
                if (item.path === "/settings-create-user") {
                  item.component = CreateUser;
                }
                if (item.path === "/settings-create-staffs") {
                  item.component = CreateStaffs;
                }
                if (item.path === "/manage-staffs") {
                  item.component = ManageStaffs;
                }
                if (item.path === "/manage-users") {
                  item.component = ManageUsers;
                }
                if (item.path === "/manage-staffs") {
                  item.component = ManageStaffs;
                }
                if (item.path === "/settings-bank-account") {
                  item.component = Accounts;
                }

                if (item.path === "/settings-settlements") {
                  item.component = SettlementSplit;
                }
                if (item.path === "/manage-customers") {
                  item.component = ManageCustomers;
                }
                if (item.path === "/invoice-age-analysis") {
                  item.component = InvoiceAgeAnalysis;
                }
                if (item.path === "/performance-tracker") {
                  item.component = PerformanceTracker;
                }              
                if (item.path === "/branding") {
                  item.component = Branding;
                }
                if (item.path === "/settings-create-self-service-setup") {
                  item.component = CreateSelfServiceSetup;
                }
                if (item.path === "/settings-view-self-service-setup") {
                  item.component = ViewSelfServiceSetup;
                }
               

              });
            }
          });
        }
        return item;
      });
      this.setState(
        {
          dynamicRoutes: [
            ...updatedRoute,
            {
              path: "/settings-view-user-profile",
              name: "User Profile",
              state: "userprofile",
              icon: "ti-user",
              component: ViewUserProfile,
              notVisible: true,
            },
            { path: "/", pathTo: path, name: name, redirect: true },
          ],
        },
        () => {
          dispatch(commonActions.getRoutes(this.state.dynamicRoutes));
        }
      );
    }
  };

  /*------------------------------------------------*/
  /*Set custom theme for each agency via redux actions*/
  /*-------------------------------------------------*/
  configureTheme = () => {
    // const {user, dispatch} = this.props;
    // if (user.organizationId === 30) {
    // 	dispatch(setNavbarBg('skin4'))
    // } else if (user.organizationId === 10) {
    // 	dispatch(setNavbarBg('skin3'))
    // } else {
    // 	dispatch(setNavbarBg('skin1'))
    // }
  };

  /*------------------------------------------------*/
  /* Dispatch getBranding action
	/* commented this out as it is currently being handled
	/* in the login component
	/*-------------------------------------------------*/
  // fetchBranding = () => {
  // 	const {user, dispatch} = this.props;
  //
  // 	let payload = {
  // 		agencyId: user.organizationId,
  // 		agencyCode: user.organizationCode
  // 	};
  //
  // 	dispatch(commonActions.getBranding(payload, user))
  // };
  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  updateDimensions() {
    let element = document.getElementById("main-wrapper");
    const { dispatch } = this.props;
    this.setState({
      width: window.innerWidth,
    });
    switch (this.props.settings.activeSidebarType) {
      case "full":
      case "iconbar":
        if (this.state.width < 1170) {
          dispatch(commonActions.showMiniSidebarDropdown(true));

          element.setAttribute("data-sidebartype", "mini-sidebar");
          element.classList.add("mini-sidebar");
        } else {
          dispatch(commonActions.showMiniSidebarDropdown(false));

          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
          element.classList.remove("mini-sidebar");
        }
        break;

      case "overlay":
        if (this.state.width < 767) {
          const { dispatch } = this.props;
          dispatch(commonActions.showMiniSidebarDropdown(true));
          element.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          dispatch(commonActions.showMiniSidebarDropdown(false));

          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
        }
        break;

      default:
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook                                                                 */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    window.removeEventListener("resize", this.updateDimensions);
  }
  render() {
    const { appRequesting } = this.props;
    const { dynamicRoutes } = this.state;
    /*--------------------------------------------------------------------------------*/
    /* Theme Setting && Layout Options wiil be Change From Here                       */
    /*--------------------------------------------------------------------------------*/
    return (
      <div
        id="main-wrapper"
        dir={this.props.settings.activeDir}
        data-theme={this.props.settings.activeTheme}
        data-layout={this.props.settings.activeThemeLayout}
        data-sidebartype={this.props.settings.activeSidebarType}
        data-sidebar-position={this.props.settings.activeSidebarPos}
        data-header-position={this.props.settings.activeHeaderPos}
        data-boxed-layout={this.props.settings.activeLayout}
      >
        <div>
          {appRequesting === "start app request" && (
            <AppProgressLoader message={"Sending Request"} />
          )}
          {/*------------------------------------------------------------------------------*/}
          {/* Header                                                                         */}
          {/*--------------------------------------------------------------------------------*/}
          <Header />
          {/*--------------------------------------------------------------------------------*/}
          {/* Sidebar                                                                        */}
          {/*--------------------------------------------------------------------------------*/}
          <Sidebar {...this.props} routes={dynamicRoutes} />
          {/* <Sidebar {...this.props} routes={ThemeRoutes} /> */}
          {/*--------------------------------------------------------------------------------*/}
          {/* Page Main-Content                                                              */}
          {/*--------------------------------------------------------------------------------*/}
          <div className="page-wrapper d-block">
            {/*{requesting &&*/}
            {/*<ProgressLoader message={"loading..."}/>*/}
            {/*}*/}
            <div
              className={"header-panel"}
              style={{ minHeight: "90px" }}
              data-panel={this.props.settings.activeNavbarBg}
            ></div>
            <div className="page-content container-fluid">
              <Router basename="/" history={history}>
                <Switch>
                  <Route path={"/showcase"} component={ComponentsShowcase} />
                  {dynamicRoutes.map((prop, key) => {
                    if (prop.navlabel) {
                      return null;
                    } else if (prop.collapse && prop.child) {
                      return prop.child.map((prop2, key2) => {
                        if (prop2.collapse && prop2.subchild) {
                          return prop2.subchild.map((prop3, key3) => {
                            return (
                              <Route
                                path={prop3.path}
                                component={prop3.component}
                                key={key3}
                              />
                            );
                          });
                        }
                        return (
                          <Route
                            path={prop2.path}
                            component={prop2.component}
                            key={key2}
                          />
                        );
                      });
                    } else if (prop.redirect) {
                      return (
                        <Redirect from={prop.path} to={prop.pathTo} key={key} />
                      );
                    } else {
                      return (
                        <Route
                          path={prop.path}
                          component={prop.component}
                          key={key}
                        />
                      );
                    }
                  })}
                </Switch>
              </Router>
            </div>
            <Footer />
          </div>
          {/*--------------------------------------------------------------------------------*/}
          {/* Customizer from which you can set all the Layout Settings                      */}
          {/*--------------------------------------------------------------------------------*/}
          {/*Temporarily commented due to no use of it yet in the project*/}
          {/*<Customizer*/}
          {/*	*/}
          {/*	boxedTheme={this.boxedTheme}*/}
          {/*	rtl={this.rtl}*/}
          {/*	headerPosition={this.headerPosition}*/}
          {/*	sidebarPosition={this.sidebarPosition}*/}

          {/*/>*/}
        </div>
        {/*}*/}
      </div>
    );
  }
}
export default connect(mapStateToProps)(Fulllayout);
