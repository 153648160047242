import React from 'react';
import {FiInfo} from 'react-icons/fi';

import {
    CardBody
} from 'reactstrap';

const EmptyRecordMessage = ({message}) => (
    <div>
        <CardBody className="table-responsive">
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center">
                        <FiInfo size="70" color="#43a8df"/>
                    </div>
                    <h5 className="text-center mt-2">
                        {message}
                    </h5>
                </div>
            </div>
        </CardBody>
    </div>
);
export default EmptyRecordMessage;
