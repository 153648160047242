import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function baseRoute(state = initialState.showMiniSidebarDropdown, action) {
  
  switch (action.type) {
    case appConstants.BASE_ROUTE:
      return action.route;
    default:
      return state;
  }
}
