import React from "react";
import { modalActions } from "../../_actions";
import FullScreenModal from "../FullScreenModal";
import { connect } from "react-redux";
import { appHelpers } from "../../_helpers";

const DoubleInvoiceModal = ({
  displayDoubleInvoices,
  dispatch,
  children,
//   invoiceSheetActions,
//   renderInvoiceView,
  requesting,
}) => {
  return (
    <FullScreenModal
      show={displayDoubleInvoices.show}
      isInvoiceSheet={true}
      width={"90%"}
      left={"0%"}
      //   actions={invoiceSheetActions}
      callback={(res) => {
        appHelpers.fullScreenCallback.call(
          this,
          res,
          dispatch,
          displayDoubleInvoices,
          modalActions.displayDoubleInvoices({
            show: false,
          })
        );
      }}
      zIndex={requesting ? 10 : 999}
    >
    {children}
    </FullScreenModal>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(DoubleInvoiceModal);
