import React, {Component} from 'react';
import {Button} from "reactstrap";
import ContentLayout from "../../components/ContentLayout";
import SearchBar from "../../components/SearchBar";
import {connect} from "react-redux";
import NoSidebarLayout from "../../layouts/layout-components/no-sidebar-layout";
import "./sprite-ui.scss"
import {commonService} from "../../_services";
import {appConstants} from "../../_constants";
import {alertActions, commonActions, modalActions} from "../../_actions";
import GeneralAlert from "../../components/Api-response-alert";


class PaymentSearchInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
       invoiceNumber: null,
       invoiceDetails: {},
      paymentChannels: [],
      errorMessage: null,
    }
  }
  
  componentDidMount() {
    this.props.dispatch(commonActions.saveGatewayCode(null))

    let pathState = this.props.location.state;
  
    if (pathState){
      this.setState({invoiceNumber: pathState})
    }
  }
  
  handleChange = event => {
    this.setState({[event.target.name]: event.target.value}, () => {
      if (this.state.invoiceNumber !== "") {
        this.setState({errorMessage: null})
      }
    })
  };
  
  searchInvoiceDetails = () => {
    const {invoiceNumber} = this.state;
    let { dispatch } = this.props;
  
    if (invoiceNumber === null || invoiceNumber === " ") {
      this.setState({errorMessage: "Please input an invoice number"});
      return;
    }
  
    dispatch(alertActions.startRequest());
    
    //Call endpoint to search the invoice number
    commonService.getWebPaymentDetails(invoiceNumber)
      .then(res =>  {
        dispatch(alertActions.stopRequest());
        let {response} = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {   // call navigate to invoiceDetailsPage
          this.setState({invoiceDetails: response.data, paymentChannels: response.data.paymentChannels}, () => {
            this.navigateToInvoiceDetailsPage();
          })
        }
        if (res.status === appConstants.ERROR_RESPONSE) {
          if (typeof response === "string") {
            dispatch(modalActions.displayGeneralAlert({ show: true, message: response, action: "failure"}))
          } else {
            dispatch(modalActions.displayGeneralAlert({ show: true, message: response.message, action: "failure"}))
  
          }
        }
      }
    )
  };
  
  // navigate to invoiceDetailsPage passing the data from getWebPaymentDetails,
  navigateToInvoiceDetailsPage = () => {
    const {invoiceDetails, paymentChannels} = this.state;
    
    this.props.history.push({
      pathname: '/invoice-details',
      state: {invoiceDetails, paymentChannels}
    })
  };
  
  render() {
    const {errorMessage} = this.state;
    return (
      <div>
        <NoSidebarLayout>
          <ContentLayout
            pageTitle={"Search Invoice for Payment"}
            height={'70vh'}
            requesting={this.props.requesting}
          >
            <div className="sprite-search-invoice">
              <SearchBar
                placeholder={"Please search for an Invoice"}
                onChange={this.handleChange}
                name={"invoiceNumber"}
                value={this.state.invoiceNumber}
              />
              {
                errorMessage && <span className={'sprite-search-invoice__error'}>{errorMessage} </span>
              }
             
              <Button className={"btn-outline"} color={'info'} onClick={() => this.searchInvoiceDetails()}>
                Search by Invoice Number
              </Button>
            </div>
          </ContentLayout>
          {this.props.displayGeneralAlert.show &&
          <GeneralAlert/>
          }
        </NoSidebarLayout>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});


export default connect(mapStateToProps)(PaymentSearchInvoice);