import React, { Component } from "react";
import Permissions from "./permissions";
import ContentLayout from "../../../components/ContentLayout";
import "./index.css";
import SearchPanel from "./search-panel";
import { Button } from "reactstrap";
import UserProfile from "./user-profile";
import { appHelpers } from "../../../_helpers";
import { FaArrowLeft, FaCheckCircle, FaTimes } from "react-icons/fa";
import { alertActions, commonActions } from "../../../_actions";
import { commonService } from "../../../_services";
import axios from "axios";
import { appConstants } from "../../../_constants";
import { connect } from "react-redux";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { RenderAlertModal } from "../../../components/AlertModal";
import CheckboxGrid from "../../../components/CheckboxGrid";

let source;
class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionsData: [],
      revenueData: [],
      locationData: [],
      firstName: "",
      lastName: "",
      // password: "",
      // confirmPassword: "",
      email: "",
      roles: [],
      role: "",
      organizations: [],
      organization: "",
      organizationId: null,
      pageTitle: appConstants.CREATE_USER,
      showUserProfile: false,
      userDetails: null,
      editingUserProfile: false,
      phoneNo: "",
      showAlertModal: false,
      alertMessage: "",
      alertIcon: false,
      componentErrorMessages: [],
      userStatus: false,
      selectAllRevenue: false,
      selectAllLocations: false,
    };

    source = axios.CancelToken.source();
  }

  componentWillMount() {
    let stateFromPath = this.props.history.location.state; //This is gotten from the manage user route and used to set the initial state of the page.
    if (stateFromPath) {
      let userDetails = stateFromPath.item;
      let pageTitle = stateFromPath.pageTitle;
      let showUserProfile = stateFromPath.showUserProfile;

      if (userDetails) {
        this.setState({
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
          userStatus: userDetails.status,
          phoneNo: userDetails.phoneNum,
          organizationId: userDetails.organizationId,
          pageTitle: stateFromPath.pageTitle,
          userDetails: userDetails,
        });
      }
      if (pageTitle) {
        this.setState({ pageTitle: pageTitle });
      }
      if (showUserProfile !== undefined) {
        this.setState({
          showUserProfile: showUserProfile,
          userDetails: userDetails,
        });
      }
    }
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    const { user, dispatch, organizations } = this.props;
    const { pageTitle, userDetails } = this.state;

    // this.getRevenues();
    // this.getLocations();'

    if (organizations) {
      this.setState({ organizations: organizations.data }, () => {
        if (
          pageTitle === (appConstants.EDIT_USER || appConstants.USER_PROFILE)
        ) {
          this.setState({
            organization: appHelpers.getOrganizationId(
              this.state.organizations,
              userDetails.organizationId
            ),
          });
        }
      });
    } else {
      dispatch(commonActions.getAllOrganization(source.token));
    }

    //If user is not a VGGAdmin call getUserLocationRevenue and he wants to edit or view a record
    // pass the userId and agencyId to the getUserLocationRevenueEndpoint
    // else if the user is not a vggadmin and he wants to create a record,
    // pass only the agencyID to the getUserLocationRevenueEndpoint

    if (
      user.vggRole !== appConstants.ROLES.VGG_ADMIN &&
      (this.state.pageTitle === appConstants.EDIT_USER ||
        this.state.pageTitle === appConstants.USER_PROFILE)
    ) {
      this.getUserLocationRevenue(user.organizationId, userDetails.userId);
      this.getRoles(user.organizationId);
    } else if (
      user.vggRole !== appConstants.ROLES.VGG_ADMIN &&
      this.state.pageTitle === appConstants.CREATE_USER
    ) {
      this.getUserLocationRevenue(user.organizationId);
      this.getRoles(user.organizationId);
    }

    let prompts = [];

    // If user is a vgg admin and he wants to edit a users record
    if (
      user.vggRole === appConstants.ROLES.VGG_ADMIN &&
      (this.state.pageTitle === appConstants.EDIT_USER ||
        this.state.pageTitle === appConstants.USER_PROFILE)
    ) {
      this.getUserLocationRevenue(
        this.state.organizationId,
        this.state.userDetails.userId
      );
      this.getRoles(this.state.organizationId);
    } else if (user.vggRole !== appConstants.ROLES.VGG_ADMIN) {
      this.setState({ organization: user.organization });
    }

    // If user is a client admin, set his organization in state from the organization value in user object
    // if (user.vggRole === appConstants.ROLES.CLIENT_ADMIN && this.state.pageTitle === appConstants.CREATE_USER) {
    //     this.setState({organization: user.organization})
    // }

    //If user is a vgg admin, prompt him to select an organization so as to display available role
    if (
      user.vggRole === appConstants.ROLES.VGG_ADMIN &&
      (this.state.role === "" || this.state.role === undefined)
    ) {
      prompts["role"] =
        "Please select an organization to display available roles";
    }

    //If user is a not a vgg admin, prompt him to select a role
    if (
      user.vggRole !== appConstants.ROLES.VGG_ADMIN &&
      (this.state.role === "" || this.state.role === undefined)
    ) {
      prompts["role"] = "Please select a role to display available permissions";
    }

    //If user is a vgg admin, prompt him to select an organization so as to
    //populate location and revenue grids
    if (
      user.vggRole === appConstants.ROLES.VGG_ADMIN &&
      (this.state.organization === "" ||
        this.state.organization === undefined) &&
      this.state.pageTitle === appConstants.CREATE_USER
    ) {
      prompts["organization"] =
        "Please select an organization to display revenues and locations";
      // this.setState({ componentErrorMessages: {organization: "Please select an organization to display revenues and locations"} })
    }

    this.setState({ componentErrorMessages: prompts });
  }

  componentWillReceiveProps = function (nextProps) {
    const { pageTitle, userDetails } = this.state;

    const { organizations } = nextProps;
    let organization;

    if (organizations) {
      this.setState({ organizations: organizations.data }, () => {
        if (
          pageTitle === (appConstants.EDIT_USER || appConstants.USER_PROFILE)
        ) {
          organization = appHelpers.getOrganizationId(
            this.state.organizations,
            userDetails.organizationId
          );
          this.setState({ organization });
        }
      });
    }
  };

  // This onChange handler, gets the checked value
  checkboxChange = (e, type) => {
    const { permissionsData, revenueData, locationData } = this.state;

    let checkedStatus = e.target.checked; //Value of the check input
    let changedPermission = e.target.parentNode.parentNode.textContent; //The text for the element that was checked.
    // This would be used to identify the object in the array that was checked, so as to accurately update it's activated property.

    let updatedRecord; //Variable to hold the record that was updated
    if (type === "permissions") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        permissionsData
      );
      this.setState({ permissionsData: updatedRecord });
    }
    if (type === "revenue") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        revenueData
      );
      this.setState({ revenueData: updatedRecord });
    }
    if (type === "location") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        locationData
      );
      this.setState({ locationData: updatedRecord });
    }
  };

  //onChange handler for select fields
  handleSelectChange = (payload, attribute) => {
    const { user } = this.props;
    let currState = this.state;
    // let errorMessages = this.state.componentErrorMessages;
    // errorMessages[attribute] = false;
    // currState[attribute] = (payload) ? payload[attribute] : ''; //Cater for setting up data and clearing data
    if (!payload) {
      currState[attribute] = "";
      if (attribute === "organization") {
        currState.componentErrorMessages.organization =
          "Please select an organization to display revenues and locations";
      }
      if (attribute === "role") {
        currState.componentErrorMessages.role =
          "Please select a role to display available roles";
      }
    } else {
      if (attribute === "role") {
        this.setState({ role: payload }, () => {
          this.getRolePermissions(this.state.role.roleId);
        });
      } else if (attribute === "organization") {
        // currState[attribute] = payload.id;
        this.setState({ organization: payload }, () => {
          this.getUserLocationRevenue(this.state.organization.id, user.userId);
          this.getRoles(this.state.organization.id);
          //If user is a vgg admin, prompt him to select an organization so as to display available role
          if (this.state.organization) {
            if (
              user.vggRole === appConstants.ROLES.VGG_ADMIN &&
              (this.state.role === "" || this.state.role === undefined)
            ) {
              currState.componentErrorMessages.role =
                "Please select role to display available permissions";
            }
          }
        });
      } else if (attribute === "revenue") {
        currState[attribute] = payload.id;
      } else {
        currState[attribute] = payload.Id;
      }
    }
    this.setState({ currState });
  };

  //onchange handler for Input fields
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  filterValues = (type) => {
    const { permissionsData, revenueData, locationData } = this.state;
    if (type === "permissions") {
      const filteredPermissions = permissionsData
        .filter((item) => item.activated === true)
        .map((item) => item.permissionId);
      return filteredPermissions ? filteredPermissions : [];
    }
    if (type === "revenue") {
      const filteredPermissions = revenueData
        .filter((item) => item.activated === true)
        .map((item) => item.revenueId);
      return filteredPermissions ? filteredPermissions : [];
    }
    if (type === "location") {
      const filteredPermissions = locationData
        .filter((item) => item.activated === true)
        .map((item) => item.locationId);
      return filteredPermissions ? filteredPermissions : [];
    }
  };

  getRoles = (organizationId) => {
    const { dispatch, user } = this.props;
    const { pageTitle, userDetails } = this.state;

    let payload = {
      OrganizationId: organizationId,
    };

    dispatch(alertActions.startRequest());
    commonService.getAllRole(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      let roles;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (response.data) {
          if (user.vggRole !== appConstants.ROLES.VGG_ADMIN) {
            //filter other  admin roles
            roles = response.data.filter(
              (item) =>
                item.vggRole === appConstants.ROLES.CLIENT_ADMIN ||
                item.vggRole === appConstants.ROLES.CLIENT_USER
            );
            //set roles in state to filtered roles
            //If user is on edit view, filter role, with roleName from user details gotten from manage user table to get roleId
            //query getRolePermissions endpoint with roleId
            //If user is on user profile view, set the role with the roleName from user details and
            // query get Role Permissions endpoint with role
            this.setState({ roles }, () => {
              pageTitle === appConstants.EDIT_USER &&
                this.setState(
                  { role: appHelpers.getUserRole(roles, userDetails.userRole) },
                  () => {
                    if (this.state.role) {
                      this.getRolePermissions(this.state.role.roleId);
                    } else {
                    }
                  }
                );
              if (pageTitle === appConstants.USER_PROFILE) {
                let role = appHelpers.getUserRole(roles, userDetails.userRole);
                this.setState({ role: userDetails.userRole });
                if (role) {
                  this.getRolePermissions(role.roleId);
                }
              }
            });
          }
          if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
            //filter vgg admin roles
            roles = response.data.filter(
              (item) =>
                item.vggRole === appConstants.ROLES.VGG_USER ||
                item.vggRole === appConstants.ROLES.CLIENT_ADMIN
            );
            //set roles in state to filtered roles
            //If user is on edit view, filter role, with roleName from user details gotten from manage user table to get roleId
            //query getRolePermissions endpoint with roleId
            //If user is on user profile view, set the role with the roleName from user details and
            // query get Role Permissions endpoint with role if
            this.setState({ roles }, () => {
              if (pageTitle === appConstants.EDIT_USER) {
                this.setState(
                  { role: appHelpers.getUserRole(roles, userDetails.userRole) },
                  () => {
                    let role = this.state.role;
                    if (role) {
                      this.getRolePermissions(this.state.role.roleId);
                    } else {
                      dispatch(
                        alertActions.notify("The user role is not available")
                      );
                    }
                  }
                );
              }

              if (pageTitle === appConstants.USER_PROFILE) {
                let role = appHelpers.getUserRole(roles, userDetails.userRole);
                this.setState({ role: userDetails.userRole });
                if (role) {
                  this.getRolePermissions(role.roleId);
                } else {
                  dispatch(
                    alertActions.notify("The user role is not available")
                  );
                }
              } else if (res.status === appConstants.ERROR_RESPONSE) {
                if (response === "Please Check your internet connectivity") {
                  dispatch(alertActions.error(response));
                }
              }
            });
          }
        }
      }
    });
  };

  //If user is a ClientAdmin or Vgg Admin call getUserLocationRevenue and pass the userId and agencyId
  getUserLocationRevenue = (agencyId, userId) => {
    const { dispatch, user } = this.props;
    const { componentErrorMessages, pageTitle } = this.state;

    if (
      user.vggRole === appConstants.ROLES.VGG_ADMIN &&
      (agencyId === "" || agencyId === undefined)
    ) {
      this.setState({
        componentErrorMessages: {
          organization:
            "Please select an organization to display revenue and locations",
        },
      });
    } else {
      componentErrorMessages.organization = ""; //Modify and update organization in componentErrorMessages

      const payload = {
        agencyId: agencyId,
      };
      if (
        pageTitle === appConstants.EDIT_USER ||
        pageTitle === appConstants.USER_PROFILE
      ) {
        payload.UserId = userId;
      }
      dispatch(alertActions.startRequest());
      commonService
        .getUserLocationRevenue(payload, source.token)
        .then((res) => {
          dispatch(alertActions.stopRequest());
          let response = res.response;

          if (res.status === appConstants.SUCCESS_RESPONSE) {
            if (response.data) {
              this.setState({
                revenueData: response.data.revenues,
                locationData: response.data.locations,
              });
            } else {
              dispatch(
                alertActions.notify(
                  "There is no revenue or location assigned to the user"
                )
              );
            }
          } else if (res.status === appConstants.ERROR_RESPONSE) {
            if (response === "Please Check your internet connectivity") {
              dispatch(alertActions.error(response));
            }
          }
        });
    }
  };

  getRolePermissions = (roleId) => {
    const { componentErrorMessages } = this.state;
    const { dispatch } = this.props;
    if (roleId === "" || roleId === undefined) {
      this.setState({
        componentErrorMessages: { role: "Please select a role" },
      });
    } else {
      componentErrorMessages.role = ""; //Modify and update role in componentErrorMessages

      const payload = {
        RoleId: roleId,
      };
      this.setState({
        permissionsData: [],
      });
      dispatch(alertActions.startRequest());

      commonService.getRolePermissions(payload, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({
            permissionsData: response,
          });
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (response === "Please Check your internet connectivity") {
            dispatch(alertActions.error(response));
          }
        }
      });
    }
  };

  createUser = () => {
    const {
      firstName,
      lastName,
      email,
      role,
      organization,
      phoneNo,
      userDetails,
      pageTitle,
    } = this.state;
    const { dispatch, user } = this.props;
    // let permissionId = this.filterValues('permissions');
    let revenueIds = this.filterValues("revenue");
    let locationIds = this.filterValues("location");

    if (this.validateInput()) {
      let payload = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        roleId: role.roleId,
        organizationId:
          user.vggRole !== appConstants.ROLES.VGG_ADMIN
            ? user.organizationId
            : user.vggRole === appConstants.ROLES.VGG_ADMIN
            ? organization.id
            : organization,
        revenueIds: revenueIds,
        locationIds: locationIds,
        mobileNo: phoneNo,
      };

      //If the is on edit view, pass the userId of the record gotten from the userDetails property
      //in pathState to the payload.
      if (pageTitle === appConstants.EDIT_USER) {
        payload.userId = userDetails.userId;
      }

      dispatch(alertActions.startRequest());

      commonService.createNewUsers(payload, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
              ),
              alertMessage: response.message,
            },
            () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
              this.resetFields();
            }
          );
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (typeof response === "string") {
            // dispatch(alertActions.error(response));
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response,
              },
              () => appHelpers.dismissModal.call(this, "showAlertModal")
            );
          } else {
            dispatch(alertActions.error(response.message));
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response.message,
              },
              () => appHelpers.dismissModal.call(this, "showAlertModal")
            );
          }
        }
      });
    }
  };

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  resetFields = () => {
    const { pageTitle } = this.state;
    if (pageTitle !== appConstants.EDIT_USER) {
      this.setState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        organization: "",
        role: "",
        revenueData: appHelpers.deactivateCheckboxes(this.state.revenueData),
        locationData: appHelpers.deactivateCheckboxes(this.state.locationData),
        selectAllRevenue: false,
        selectAllLocations: false,
      });
    }
  };

  cancel = () => {
    this.resetFields();
  };

  // for editing user via the user profile view .
  // The editingUserProfile variable in state is used to determine what is displayed when the back button is clicked
  editUserProfile = (userDetails) => {
    this.setState(
      {
        showUserProfile: false,
        pageTitle: appConstants.EDIT_USER,
        editingUserProfile: true,
      },
      () => {
        this.getRoles(userDetails.organizationId);
        this.getUserLocationRevenue(
          userDetails.organizationId,
          userDetails.userId
        );
      }
    );
  };

  //Handler that controls what is displayed when the back button is clicked
  navigateBack = () => {
    if (this.state.editingUserProfile) {
      this.setState({ showUserProfile: true });
      window.location.reload();
    } else {
      this.props.history.goBack();
    }
  };

  //Validate input fields
  validateInput = () => {
    window.scrollTo(0, 0);

    let _errs_ = [];
    let formValid = true;
    if (this.state.firstName === "") {
      _errs_["firstName"] = "Please enter first name";
      formValid = false;
    }
    if (this.state.lastName === "") {
      _errs_["lastName"] = "Please enter last name";
      formValid = false;
    }
    if (this.state.organization === "") {
      _errs_["organization"] = "Please select an organization";
      formValid = false;
    }
    if (this.state.email === "") {
      _errs_["email"] = "Please enter an email address";
      formValid = false;
    }
    if (this.state.role === "") {
      _errs_["role"] = "Please select a role";
      formValid = false;
    }
    if (this.state.phoneNo === "") {
      _errs_["phoneNumber"] = "Please enter a phone number";
      formValid = false;
    }
    if (this.state.phoneNo.length !== 11) {
      _errs_["phoneNumber"] =
        "Phone number should not be more or less than eleven digits";
      formValid = false;
    }

    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  activateUser = (payload) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    commonService.activateUser(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState(
          {
            showAlertModal: true,
            alertIcon: (
              <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
            ),
            alertMessage: response.message,
            userStatus: true,
          },
          () => {
            appHelpers.dismissModal.call(this, "showAlertModal");
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaTimes size={100} color={appConstants.DANGER_COLOR} />
              ),
              alertMessage: response,
            },
            () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
            }
          );
        } else {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaTimes size={100} color={appConstants.DANGER_COLOR} />
              ),
              alertMessage: response.message,
            },
            () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
            }
          );
        }
      }
    });
  };

  deactivateUser = (payload) => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());
    commonService.deactivateUser(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState(
          {
            showAlertModal: true,
            alertIcon: (
              <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
            ),
            alertMessage: response.message,
            userStatus: false,
          },
          () => {
            appHelpers.dismissModal.call(this, "showAlertModal");
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaTimes size={100} color={appConstants.DANGER_COLOR} />
              ),
              alertMessage: response,
              // userStatus: false
            },
            () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
            }
          );
        } else {
          this.setState(
            {
              showAlertModal: true,
              alertIcon: (
                <FaTimes size={100} color={appConstants.DANGER_COLOR} />
              ),
              alertMessage: response.message,
              // userStatus: false
            },
            () => {
              appHelpers.dismissModal.call(this, "showAlertModal");
            }
          );
        }
      }
    });
  };

  handleActivationStatus = (e) => {
    const { userStatus, userDetails } = this.state;

    if (userStatus === false) {
      this.activateUser(userDetails.userId);
    }
    if (userStatus === true) {
      this.deactivateUser(userDetails.userId);
    }
  };

  selectAllCheckboxes = (type) => {
    const { revenueData, locationData } = this.state;
    if (type === "revenue") {
      this.setState(
        (prevState) => ({ selectAllRevenue: !prevState.selectAllRevenue }),
        () => {
          if (this.state.selectAllRevenue) {
            let revenueActivated = revenueData.map((item) => {
              item.activated = true;
              return item;
            });
            this.setState({ revenueData: revenueActivated });
          } else if (!this.state.selectAllRevenue) {
            let revenueActivated = revenueData.map((item) => {
              item.activated = false;
              return item;
            });
            this.setState({ revenueData: revenueActivated });
          }
        }
      );
    }
    if (type === "location") {
      this.setState(
        (prevState) => ({ selectAllLocations: !prevState.selectAllLocations }),
        () => {
          if (this.state.selectAllLocations) {
            let locationsActivated = locationData.map((item) => {
              item.activated = true;
              return item;
            });
            this.setState({ locationData: locationsActivated });
          } else if (!this.state.selectAllLocations) {
            let locationsActivated = locationData.map((item) => {
              item.activated = false;
              return item;
            });
            this.setState({ locationData: locationsActivated });
          }
        }
      );
    }
  };

  //Alert Notifications

  displaySuccessResponseAlert = (response, action, query) => {
    this.setState(
      {
        showAlertModal: true,

        alertIcon: (
          <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
        ),

        alertMessage: response,
      },

      () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
      }
    );

    if (action) {
      return { query };
    }
  };

  displayErrorResponseAlert = (response, action, query) => {
    const { dispatch } = this.props;

    if (response === "Please Check your internet connectivity") {
      dispatch(alertActions.error(response));
    } else if (typeof response === "string") {
      this.setState(
        {
          showAlertModal: true,

          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,

          alertMessage: response,
        },

        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");

          if (action) {
            return query;
          }
        }
      );
    } else {
      this.setState(
        {
          showAlertModal: true,

          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,

          alertMessage: response.message,
        },

        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");

          if (action) {
            return query;
          }
        }
      );
    }
  };

  adminResetPassword = (payload) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());

    commonService.adminResetPassword(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());

      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(response.message);
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response);
      }
    });
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }
  render() {
    const {
      permissionsData,
      firstName,
      lastName,
      password,
      confirmPassword,
      email,
      roles,
      role,
      organizations,
      organization,
      pageTitle,
      showUserProfile,
      userDetails,
      revenueData,
      locationData,
      phoneNo,
      showAlertModal,
      alertMessage,
      alertIcon,
      componentErrorMessages,
      userStatus,
      selectAllLocations,
      selectAllRevenue,
      requesting,
    } = this.state;
    const { user } = this.props;
    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle={pageTitle}
        actions={
          <div className={"float-right"}>
            <Button
              color="info"
              outline
              className="btn-rounded mr-2 big-action-btn"
              onClick={this.navigateBack}
            >
              <FaArrowLeft className={"mb-1 icon-margin"} />
              Back
            </Button>
            {(user.vggRole === appConstants.ROLES.VGG_ADMIN ||
              user.vggRole === appConstants.ROLES.CLIENT_ADMIN) &&
              pageTitle === appConstants.EDIT_USER && (
                <Button
                  className="btn-rounded create-action-btn text-white"
                  onClick={() => this.adminResetPassword(userDetails.email)}
                  color={"success"}
                  disabled={requesting}
                >
                  Admin Reset password
                </Button>
              )}
          </div>
        }
      >
        <div className={"search-container"}>
          {!showUserProfile && (
            <SearchPanel
              firstName={firstName}
              lastName={lastName}
              password={password}
              confirmPassword={confirmPassword}
              email={email}
              roles={roles}
              role={role}
              phoneNo={phoneNo}
              organizations={organizations}
              organization={
                pageTitle === appConstants.CREATE_USER
                  ? organization
                  : pageTitle === appConstants.EDIT_USER &&
                    user.vggRole !== appConstants.ROLES.VGG_ADMIN
                  ? organization.name
                  : organization
              }
              handleChange={this.handleChange}
              selectChange={this.handleSelectChange}
              user={user}
              componentErrorMessages={componentErrorMessages}
              editing={pageTitle === appConstants.EDIT_USER}
            />
          )}
          {showUserProfile && (
            <UserProfile
              userName={`${firstName} ${lastName}`}
              email={email}
              userDetails={userDetails}
              status={userStatus}
              handleActivationStatus={this.handleActivationStatus}
              editUserProfile={this.editUserProfile}
              role={typeof role === "object" ? role.roleName : role}
            />
          )}
        </div>

        <div className={"permissions-container"}>
          <h2 className={"permissions-title"}>Permissions </h2>
          {permissionsData.length === 0 && (
            <EmptyRecordMessage message={"No Permissions record"} />
          )}
          {permissionsData.length > 0 && (
            <Permissions
              permissions={permissionsData}
              isChecked={permissionsData.activated}
            />
          )}
        </div>
        <div className={"permissions-container"}>
          {revenueData.length === 0 && (
            <EmptyRecordMessage message={"No revenue record"} />
          )}
          {revenueData.length > 0 && (
            <CheckboxGrid
              title={"Revenues"}
              checkAll={selectAllRevenue}
              label={!selectAllRevenue ? "Select all" : "Deselect all"}
              displayCheckAllBoxes={true}
              checkAllBoxes={() => this.selectAllCheckboxes("revenue")}
              data={revenueData}
              checkboxChange={(e) => this.checkboxChange(e, "revenue")}
            />
          )}
        </div>
        <div className={"permissions-container"}>
          {locationData.length === 0 && (
            <EmptyRecordMessage message={"No location record"} />
          )}
          {locationData.length > 0 && (
            <CheckboxGrid
              title={"Locations"}
              checkAll={selectAllLocations}
              label={!selectAllLocations ? "Select all" : "Deselect all"}
              displayCheckAllBoxes={true}
              checkAllBoxes={() => this.selectAllCheckboxes("location")}
              data={locationData}
              checkboxChange={(e) => this.checkboxChange(e, "location")}
            />
          )}
        </div>
        {!showUserProfile && (
          <div>
            <Button
              color="success"
              className="btn-sm btn-rounded mr-2 text-white"
              onClick={this.createUser}
              disabled={requesting}
            >
              {pageTitle === appConstants.CREATE_USER ? "Create User" : "Save"}
            </Button>
            <Button
              outline
              className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
              onClick={this.cancel}
            >
              Cancel
            </Button>
          </div>
        )}
        {showAlertModal && (
          <RenderAlertModal
            message={alertMessage}
            icon={alertIcon}
            showAlertModal={showAlertModal}
            hideAlertView={this.hideAlertView}
          />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, alert, progress, user, requesting, organizations } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
    organizations,
  };
};

export default connect(mapStateToProps)(CreateUser);
