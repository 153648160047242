import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import SelectList from "../../../components/SelectList/selectList";


const SearchPanel = ({role, handleChange, vggRoles, vggRole, selectChange}) => {
    return (
        <div>
            <Form>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="invoiceNumber">Insert Role</Label>
                            <Input
                                type="text"
                                name="role"
                                id="role"
                                value={role}
                                onChange={handleChange}
                                defaultValue=""
                                className={"search-inputs"}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="status">VGG Role </Label>
                            <SelectList
                                placeholder={'Select role...'}
                                data={vggRoles}
                                valueKey={'id'}
                                textField={'name'}
                                selectValue={vggRole}
                                onValueChange={(event) => {selectChange(event, 'vggRole')}}/>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default SearchPanel;