import React from 'react';
import {Row, Col, Card, CardBody, Button} from 'reactstrap';

const VoidRequestForm = ({name, role, invoiceNumber, customer, customerRole,
                             revenue, invoiceDate, amount, placeholder, buttonAcceptText,
                             buttonDeclineText, comment, acceptClicked, declineClicked, requestTypeName}) => {
    return (
        <Card style={{borderRadius: "10px"}} responsive={true}>
            <CardBody style={{padding: "30px 50px"}}>
                <Row>
                    <Col md={12}>
                        <p style={{float: "right", marginBottom: "1px"}}><label style={{fontWeight:"bold"}}>{role}</label><br/>{name}</p>
                        <h4 style={{float: "left", marginBottom: "1px", marginTop: "20px", fontWeight: 600 }}>{requestTypeName}</h4>
                    </Col>
                </Row>
                <hr style={{marginTop: 0}}/>
                <Row style={{paddingTop: "25px"}}>
                    <Col md={2}>
                        <h6 className="font-weight-bold">Invoice Number</h6>
                        <p>{invoiceNumber}</p>
                    </Col>
                    <Col md={2}>
                        <h6 className="font-weight-bold">Customer</h6>
                        <p><span style={{textTransform: "uppercase"}}>{customer}</span> {customerRole}</p>
                    </Col>
                    <Col md={3}>
                        <h6 className="font-weight-bold">Revenue Line</h6>
                        <p >{revenue}</p>
                    </Col>
                    <Col md={2}>
                        <h6 className="font-weight-bold">Invoice Date</h6>
                        <p>{invoiceDate}</p>
                    </Col>
                    <Col md={3}>
                        <h3>{amount}</h3>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md={12}>
                        <h6 className="font-weight-bold mt-2">Comments</h6>
                    </Col>
                    <Col md={12}>
                    <p style={{marginBottom: "40px"}}>{comment}</p>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md={12}>
                        <Button color={"danger"} className={"btn-rounded ml-3 mb-2 mt-2 px-4"} style={{float: "right", marginTop: "10px", marginLeft: "5px"}} onClick={declineClicked}>{buttonDeclineText}</Button>
                        <Button color={"success"} className={"btn-rounded ml-3 mb-2 mt-2 px-4"} style={{float: "right", marginTop: "10px", marginLeft: "5px"}} onClick={acceptClicked}>{buttonAcceptText}</Button>

                    </Col>

                </Row>

            </CardBody>
        </Card>
    )
};

export default VoidRequestForm;