import React from "react";
import { Card, CardBody } from "reactstrap";
import "./index.scss";


const RequestCard = ({ label, requestDetails, borderColor, lowerDetails, getCardDetails}) => {
  return (
    <Card className="" 
    style={{
       borderRadius: "0.25rem", 
       boxShadow: "0px 0px 10px rgba(0,0,0,.5)",
       borderTop: `0.3rem solid ${borderColor}` 
       }} onClick={getCardDetails}
       >
      <CardBody>
        <div className="d-flex">
          <div className="stats">
            <div>
                <p><b>{label}</b></p>
                </div>
            <h4 className="mt-1 mb-4 font-bold font-20">
              {`${requestDetails}`}
            </h4>
            {/* <hr/> */}
            <div><small className="text-uppercase">{lowerDetails}</small></div>
          </div>
        </div>
      </CardBody>

    </Card>
  );
};

export default RequestCard;
