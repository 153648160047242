import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import SelectList from "../../../components/SelectList/selectList";

const AddNewStaff = ({
  designations,
  newCustomerTitle,
  newCustomerFirstName,
  newCustomerLastName,
  newCustomerEmail,
  newCustomerPhoneNo,
  handleChange,
  handleSelectChange,
  requesting,
  addCustomerStaff,
  closeAddNewCustomerModal,
  isEditMode,
  companyRepId,
}) => {
  return (
    <div className={"Add-new-customer"}>
      <Card className="card" style={{ borderRadius: "10px", height: "50%" }}>
        <CardBody className={"card-body"}>
          <CardTitle className={"custom-card-title"}>
            {isEditMode ? "Edit Staff" : "Add New Staff"}
          </CardTitle>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Title</Label>
                <SelectList
                  placeholder={"Select category..."}
                  data={designations}
                  valueKey={"id"}
                  textField={"name"}
                  selectValue={newCustomerTitle}
                  onValueChange={(event) => {
                    handleSelectChange(event, "newCustomerTitle");
                  }}
                  disabled={
                    isEditMode && newCustomerTitle.id === companyRepId
                      ? true
                      : false
                  }
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  value={newCustomerEmail}
                  onChange={(e) => handleChange(e, "newCustomerEmail")}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>First Name</Label>
                <Input
                  type="text"
                  value={newCustomerFirstName}
                  onChange={(e) => handleChange(e, "newCustomerFirstName")}
                  required
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Last Name</Label>
                <Input
                  type="text"
                  value={newCustomerLastName}
                  onChange={(e) => handleChange(e, "newCustomerLastName")}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Phone Number</Label>
                <Input
                  type="number"
                  value={newCustomerPhoneNo}
                  onChange={(e) => handleChange(e, "newCustomerPhoneNo")}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <div className={"pl-3 py-4 float-left"}>
              <Button
                color="success"
                size="sm"
                className="btn-rounded mr-2 text-white py-1 px-3"
                disabled={requesting}
                onClick={() => addCustomerStaff()}
              >
                {isEditMode ? "Update Staff" : "Add Staff"}
              </Button>
              <Button
                outline
                className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
                disabled={requesting}
                onClick={closeAddNewCustomerModal}
              >
                Cancel
              </Button>
            </div>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddNewStaff;
