import React from "react";
import { Table } from "reactstrap";
import "react-toggle/style.css"; // for ES6 modules
import "./index.scss";

const Records = (props) => {
  const { data, creditType, creditNoteDiscount, creditNoteStatus } = props;
  return (
    <Table responsive={true} className={"table-main"}>
      <thead className={"th-head tracker-head"}>
        <tr>
          <th>Invoice No</th>
          <th>Customer</th>
          <th>Current Invoice Amount</th>
          <th>Credit Note Issued</th>
          <th>Payable Invoice Amount</th>
          <th>Percentage Discount</th>
          <th>Credit Note Status</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {

          let creditNoteIssued = 0;
          if (creditType === "discount" && creditNoteDiscount){
            creditNoteIssued = ((creditNoteDiscount / 100 )* item.amount).toFixed(2)

          } else if (creditType === "value" && creditNoteDiscount){
            creditNoteIssued = creditNoteDiscount
          }

          let payableAmount = item.amount - creditNoteIssued

          let percentageDiscount = 0 ;
          if(creditType === "discount" && creditNoteDiscount){
            percentageDiscount = creditNoteDiscount
          } else if (creditType === "value" && creditNoteDiscount) {
            percentageDiscount = ((creditNoteDiscount * 100 )/ item.amount).toFixed(2)
          }
    
          
          return (
            <tr key={index}>
              <th>{item.invoiceNumber}</th>
              <th>{item.customer}</th>
              <th> {item.amountString}</th>
              <th>{creditNoteIssued}</th>
              <th>{payableAmount}</th>
              <th>{percentageDiscount}</th>
              <th>{creditNoteStatus(item.creditNoteRequestStatus) }</th>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default Records;

