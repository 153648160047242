import React, { Component } from "react";
import { Row, Col, Button, Container, Badge } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import { FaArrowLeft } from "react-icons/fa";
import ContentLayout from "../../../components/ContentLayout";
import AccountsTable from "./accounts-table";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { appHelpers } from "../../../_helpers";

const Fields = ({ label, value }) => {
  return (
    <div>
      <div>{label}</div>
      <h5 className="font-weight-bold">{value ? value : "-"}</h5>
    </div>
  );
};

class ViewSettlementSplitPerRevenue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agencyDetails: {},
      revenueSplitDetails: {},
      accounts: {
        primarySettlementSplitAccount: [],
        thirdPartySettlementSplitAccounts: [],
      },
    };
  }

  componentDidMount() {
    this.formatSettlementAccounts();
  }

  static getDerivedStateFromProps(props, state) {
    let pathState = props.location.state;

    if (pathState) {
      /**
       * @description pathState is obtained from /settings-settlements
       */
      if (
        props.location.state.revenueSplitDetails !== state.revenueSplitDetails
      ) {
        return {
          agencyDetails: props.location.state.agencyDetails,
          revenueSplitDetails: props.location.state.revenueSplitDetails,
        };
      }
    }

    return null;
  }

  /**
   * @param {String} pathname path to be routed to
   * @param {String} attribute name of the state attribute in new route
   * @param {*} value state to be passed into the new route
   */
  handleChangeRoute = (pathname, attribute, value) => {
    this.props.history.push({
      pathname,
      state: {
        agencyDetails: this.state.agencyDetails,
        thirdPartySettlementSplitAccounts: this.state.accounts
          .thirdPartySettlementSplitAccounts,
        [attribute]: value,
      },
    });
  };

  /**
   * @description This function splits settlement accounts intp primary and third party settlement accounts
   */
  formatSettlementAccounts = () => {
    const { revenueSplitDetails } = this.state;

    let primarySettlementSplitAccount = [];
    let thirdPartySettlementSplitAccounts = [];

    revenueSplitDetails &&
      revenueSplitDetails.settlementSplitAccounts &&
      revenueSplitDetails.settlementSplitAccounts.forEach((item) => {
        //If is beneficiary is true, it is a third party account, else, it is a secondary account
        if (item.isBeneficiary) {
          //present bank and currency in the dropdown required format
          item.bank = { id: item.bankId, name: item.bankName };
          item.currency = { id: item.currencyId, name: item.currencyName };
          thirdPartySettlementSplitAccounts.push(item);
        } else {
          primarySettlementSplitAccount.push(item);
        }
      });

    this.setState({
      accounts: {
        primarySettlementSplitAccount,
        thirdPartySettlementSplitAccounts,
      },
    });
  };

  render() {
    const { revenueSplitDetails, accounts } = this.state;
    const {
      splitTypeId,
      splitTypeName,
      revenueName,
      dateCreated,
      status,
    } = revenueSplitDetails;
    const { requesting } = this.props;

    return (
      <div>
        <ContentLayout
          requesting={requesting}
          pageTitle={"Settlement split details"}
          actions={
            <div className={"float-right"}>
              <Button
                className="btn-rounded mr-2 create-action-btn text-white"
                color={"success"}
                onClick={() =>
                  this.handleChangeRoute(
                    "/edit-settlement-split",
                    "revenueSplitDetails",
                    revenueSplitDetails
                  )
                }
              >
                Edit
              </Button>

              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          }
        >
          <Container>
            <Row className="mb-5">
              <Col md="3" xs="12">
                <Fields label="Revenue Name" value={revenueName} />
              </Col>
              <Col md="3" xs="12">
                <Fields label="Split Type" value={splitTypeName} />
              </Col>
              <Col md="3" xs="12">
                <Fields
                  label="Date Created"
                  value={
                    dateCreated && moment(dateCreated).format("Do MMMM, YYYY")
                  }
                />
              </Col>
              <Col md="3" xs="12">
                <Fields
                  label="Status"
                  value={
                    <Badge color={`${status ? "success" : "danger"}`}>
                      {status ? "active" : "Inactive"}
                    </Badge>
                  }
                />
              </Col>
            </Row>

            <h2 className="mt-2" style={{ fontWeight: "700" }}>
              Settlement Account
            </h2>
            <hr />
            <AccountsTable tableData={accounts.primarySettlementSplitAccount} />

            {accounts.primarySettlementSplitAccount &&
              accounts.primarySettlementSplitAccount.length === 0 && (
                <EmptyRecordMessage message={"No settlement account found"} />
              )}

            <h2 className="mt-2" style={{ fontWeight: "700" }}>
              Third Party Settlement Accounts
            </h2>
            <hr />
            <AccountsTable
              tableData={accounts.thirdPartySettlementSplitAccounts}
              splitTypeId={splitTypeId}
            />

            {accounts.thirdPartySettlementSplitAccounts &&
              accounts.thirdPartySettlementSplitAccounts.length === 0 && (
                <EmptyRecordMessage message={"No third party account found"} />
              )}
          </Container>
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, apiReducer } = state;
  return {
    user,
    requesting,
    apiReducer,
  };
};

export default connect(mapStateToProps)(ViewSettlementSplitPerRevenue);
