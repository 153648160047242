import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import { alertActions, apiActions, modalActions } from "../../_actions";
import { FaCheckCircle } from "react-icons/fa";
import { appConstants } from "../../_constants/app.constants";
import { commonService } from "../../_services";
import FullScreenModal from "../../components/FullScreenModal";
import VoidRequestDetails from "../../components/VoidRequestDetails";
import VoidRequestForm from "../../components/VoidRequestForm";
import DoubleInvoiceView from "../../components/DoubleInvoiceView";
import ViewEditedRecord from "../../components/page-components/search-invoice-components/view-edited-record";
import Alert from "../../components/Alert/index";
import { connect } from "react-redux";
import axios from "axios";
import { appHelpers, pageHelpers } from "../../_helpers";
import VoidRequestFullScreenModal from "../../components/VoidRequestFullScreenModal/index";
import GeneralAlert from "../../components/Api-response-alert/index";
let source;

class VoidRequestTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReview: false,
      showVoidAccept: false,
      showVoidDecline: false,
      declinedVoid: false,
      reviewDetails: {},
      comment: "",
      status: 1,
      processVoidMessage: "",
      oldInvoiceRecord: false, // Used to store the old Invoice values
      newInvoiceRecord: false, // Used to store the new Invoice values
      editedFields: null,
      logo: "",
      pageTitle: appConstants.MANAGE_WORKFLOW_APPROVAL,
      editApprovalStatus: "",
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    this.setState({ logo: this.props.settings.setLogo });
  }

  componentDidUpdate(prevProps) {
    const {
      apiReducer,
      displayGeneralAlert,
    } = this.props;

    if (this.props.settings.setLogo !== prevProps.settings.setLogo) {
      this.setState({
        logo: this.props.settings.setLogo,
      });
    }

    //This is called after the getInvoiceChanges action has been called and invoicesChanges has been updated in redux state.
    if (apiReducer.invoiceChanges !== prevProps.apiReducer.invoiceChanges) {
      this.setState(
        {
          oldInvoiceRecord: apiReducer.invoiceChanges.oldInvoice,
          newInvoiceRecord: apiReducer.invoiceChanges.newInvoice,
          editedFields: apiReducer.invoiceChanges.editedFields,
          voidModalData: apiReducer.invoiceChanges.newInvoice,
        },
        () => {
          this.viewEditedChanges();
        }
      );
    }

    //IF the general alert was previously open but is now cancelled
    if (
      !displayGeneralAlert.show &&
      prevProps.displayGeneralAlert.show === true &&
      this.props.searchingByEditedInvoices
    ) {
      this.clearProcessEdit();
    }
  }

  handleChange = (event, attribute) => {
    let currState = this.state;
    currState[attribute] = event.target.value;
    this.setState(currState);
  };
  //show void review
  doShowReview = (item, response) => {
    response && response.preventDefault();
    this.setState({ reviewDetails: item });
    this.setState({ showReview: response });
  };

  //control void modal
  doShowVoid = (response) => {
    this.setState({ showVoidAccept: response, showReview: false });
    this.setState({ showVoidDecline: response, showReview: false });
    this.setState({ declinedVoid: response, showReview: false });
  };

  // API INTEGRATIONS
  //api to process void request
  processVoidRequest = (status, reviewDetails) => {
    const { dispatch } = this.props;
    const { id, invoiceNumber, userName, initiatorComment } = reviewDetails;
    let payload = {
      activeWorkflowId: id,
      invoiceNumber,
      userName,
      status: status,
      // userId: user.userId,
      comment: initiatorComment,
    };
    dispatch(alertActions.startRequest());
    commonService.ProcessVoidRequest(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        //trigger general alert modal dispatch
        this.setState(
          {
            showVoidAccept: true,
            message: response.message,
          },
          () => this.props.sendRequest()
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  displayRequestModalForAprroval = (status) => {
    this.setState({
      editApprovalStatus: status,
    });
    this.props.dispatch(modalActions.displayVoidRequestModal({ show: true }));
  };
  //api to process edit request
  processEditRequest = () => {
    const { dispatch } = this.props;
    const { id, invoiceNumber } = this.state.newInvoiceRecord;
    const { comment, editApprovalStatus } = this.state;
    let statusEnum =
      editApprovalStatus === "approve"
        ? 1
        : editApprovalStatus === "decline"
        ? 2
        : 0;
    let payload = {
      invoiceEditId: id,
      invoiceNumber,
      status: statusEnum,
      comment: comment,
    };
    dispatch(alertActions.startRequest());
    commonService.processEditInvoice(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "success",
          })
        );
        
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "success",
          })
        );
      }
    });
  };

  //logic for the void action to accept or deline
  voidButton = (action) => {
    const { reviewDetails } = this.state;
    if (action === "accept") {
      this.setState({ showVoidDecline: false }, () => {
        this.processVoidRequest(this.state.status, reviewDetails);
      });
    }
    if (action === "decline") {
      this.setState(
        { showVoidAccept: false, showVoidDecline: true, status: 2 },
        () => {
          this.processVoidRequest(this.state.status, reviewDetails);
        }
      );
    }
    if (action === "send") {
      this.setState({ showVoidDecline: false, declinedVoid: true }, () => {
        this.processVoidRequest(this.state.status, reviewDetails);
      });
    }
  };

  //logic to pass modal details for void
  renderReview = (showReview, reviewDetails) => {
    let reviewElems = [];
    let key = 0;
    if (showReview) {
      const {
        amountInvoiced,
        initiatorName,
        Customer,
        invoiceNumber,
        revenue,
        name,
        initiatedDate,
        initiatorComment,
      } = reviewDetails;
      reviewElems.push(
        <FullScreenModal
          show={showReview}
          key={key++}
          callback={this.doShowReview}
          zIndex={this.props.requesting ? 10 : 9999}
        >
          <VoidRequestDetails
            role={"Created By"}
            requestTypeName={"Review Void Request"}
            name={initiatorName}
            invoiceNumber={invoiceNumber}
            customer={Customer}
            customerRole={name}
            revenue={revenue}
            invoiceDate={moment(initiatedDate).format("DD-MM-YYYY")}
            amount={amountInvoiced}
            buttonAcceptText={"accept"}
            buttonDeclineText={"decline"}
            acceptClicked={() => this.voidButton("accept")}
            declineClicked={() => this.voidButton("decline")}
            comment={initiatorComment}
          />
        </FullScreenModal>
      );
      return reviewElems;
    }
  };

  //render void accept view
  renderVoidView = (item) => {
    const { showVoidAccept, message } = this.state;
    let voidView = [];
    let count = 0;

    voidView.push(
      <FullScreenModal
        show={showVoidAccept}
        key={count + 1}
        callback={this.doShowVoid}
        width={"35%"}
        top={"30%"}
      >
        <Alert
          icon={<FaCheckCircle size={100} color={"#06d79d"} />}
          message={message}
        />
      </FullScreenModal>
    );
    return voidView;
  };

  //REMOVE THIS IF NOT USED
  //render void decline view
  renderDeclineVoidView = (item) => {
    const { showVoidDecline } = this.state;
    let declineView = [];
    let count = 0;

    declineView.push(
      <FullScreenModal
        show={showVoidDecline}
        key={count + 1}
        callback={this.doShowVoid}
        zIndex={this.props.requesting ? 10 : 9999}
      >
        <VoidRequestForm
          role={"Supervisor"}
          requestType={"Void Request"}
          name={item.initiatorName ? item.initiatorName : ""}
          invoiceNumber={item.invoiceNumber ? item.invoiceNumber : ""}
          customer={item.customerName ? item.customerName : ""}
          customerRole={item.name ? item.name : ""}
          revenue={item.revenue ? item.revenue : ""}
          invoiceDate={
            item.initiatedDate
              ? moment(item.initiatedDate).format("DD-MM-YYYY")
              : ""
          }
          amount={item.amountInvoiced ? item.amountInvoiced : 0}
          comment={item.initiatorComment ? item.initiatorComment : ""}
          placeholder={"Insert comment here"}
          buttonText={"send"}
          sendClicked={() => this.voidButton("send")}
          value={this.state.value}
          onChange={(event) => this.handleChange(event, "comment")}
        />
      </FullScreenModal>
    );
    return declineView;
  };

  //render decline alert view for void
  renderDeclinedAlert = (item) => {
    const { declinedVoid } = this.state;
    let declinedAlert = [];
    let count = 0;

    declinedAlert.push(
      <FullScreenModal
        show={declinedVoid}
        key={count + 1}
        callback={this.doShowVoid}
      >
        <Alert
          icon={<FaCheckCircle size={100} color={"#06d79d"} />}
          message={"Void Request Declined"}
        />
      </FullScreenModal>
    );
    return declinedAlert;
  };

  getInvoiceChanges = (item) => {
    const { invoiceNumber, agencyId } = item;
    const payload = {
      invoiceNumber,
      agencyId,
    };
console.log("item", item);
    this.props.dispatch(apiActions.getInvoiceChanges(payload, source.token));
  };
  // logic for void request IMPLEMENTATIONS
  // ...to view edited changes
  viewEditedChanges = (item) => {
    const { dispatch } = this.props;
    let updateOldInvoiceData = {
      invoiceTitle: "Old Invoice",
      isNewInvoice: false,
    };
    let updateNewInvoiceData = {
      invoiceTitle: "New Invoice",
      isNewInvoice: true,
    };

    let editedFields = this.state.editedFields.map((item) => item.fieldName);

    let changedFields = { updatedFields: editedFields };
console.log(changedFields, "change");

    this.setState((prevState) => ({
      oldInvoiceRecord: {
        ...prevState.oldInvoiceRecord,
        ...updateOldInvoiceData,
      },
      newInvoiceRecord: {
        ...prevState.newInvoiceRecord,
        ...updateNewInvoiceData,
        ...changedFields, // The new invoice needs this array tp be able to highlight changes
      },
    }));

    //This will trigger the display of the double invoice modal
    dispatch(modalActions.displayDoubleInvoices({ show: true }));
  };

  clearProcessEdit = () => {
    const { dispatch } = this.props;
    dispatch(modalActions.displayDoubleInvoices({ show: false }));
    dispatch(modalActions.displayVoidRequestModal({ show: false }));
    this.setState({
      editApprovalStatus: "",
      oldInvoiceRecord: false,
      newInvoiceRecord: false,
      comments: "",
    });
    appHelpers.enableScroll();
    this.props.sendRequest();
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(modalActions.displayGeneralAlert({ show: false }));
    if (source) {
      source.cancel();
    }
  }

  render() {
    console.log(this.state.editedFields, "editFields");
    const {
      showReview,
      reviewDetails,
      showVoidAccept,
      showVoidDecline,
      declinedVoid,
      oldInvoiceRecord,
      newInvoiceRecord,
      voidModalData,
    } = this.state;
    const {
      selectedCard,
      tableData,
      BgColor,
      requestType,
      alertComponentsReducer,
      requesting,
      
    } = this.props;

    // const pageIndex =  currentPage === 0 ? 1 : ((pageSize * (currentPage - 1)) + 1);
    const displayDoubleInvoices = alertComponentsReducer.displayDoubleInvoices;

    const displayVoidRequestModal =
      alertComponentsReducer.displayVoidRequestModal;

    return (
      <React.Fragment>
        <Table responsive={true} className={"table-main"}>
          <thead style={{ backgroundColor: `${BgColor}`, color: "white" }}>
            <tr>
              <th style={{ padding: "none" }}>Invoice</th>
              <th style={{ padding: "none" }}>Date</th>
              <th style={{ padding: "none" }}>Amount Invoiced</th>
              <th style={{ padding: "none" }}>Customer</th>
              <th style={{ padding: "none" }}>Name</th>
              <th style={{ padding: "none" }}>Revenue</th>
              <th style={{ padding: "none" }}>Location</th>
              <th style={{ padding: "none" }}>State</th>
              <th style={{ padding: "none" }}>
                {selectedCard === 3 ? "Action" : "Status"}
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{ borderTop: "12px solid transparent" }}
                  >
                    <th>{item.invoiceNumber}</th>
                    <th>{moment(item.initiatedDate).format("DD-MM-YYYY")}</th>
                    <th>
                      {appHelpers.numberWithCommasOnly(
                        item.amountInvoiced,
                        appConstants.DEFAULT_PRECISION
                      )}
                    </th>
                    <th>{item.customerName}</th>
                    <th>{item.name}</th>
                    <th>{item.revenue}</th>
                    <th>{item.location}</th>
                    <th>{item.state}</th>
                    {selectedCard === 3 && requestType === "void" ? (
                      <th>
                        <a
                          href="/#"
                          onClick={this.doShowReview.bind(this, item)}
                          title="Review"
                        >
                          {" "}
                          Review{" "}
                        </a>{" "}
                      </th>
                    ) : selectedCard === 3 && requestType === "edit" ? (
                      <th>
                        <span onClick={this.getInvoiceChanges.bind(this, item)}>
                          {" "}
                          Review{" "}
                        </span>{" "}
                      </th>
                    ) : (
                      <th>{item.status}</th>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {displayDoubleInvoices.show && (
          <DoubleInvoiceView
            displayDoubleInvoices={displayDoubleInvoices}
            dispatch={this.props.dispatch}
            requesting={requesting}
          >
            <ViewEditedRecord
              displayOldInvoiceRecord={pageHelpers.renderInvoiceView.call(
                this,
                oldInvoiceRecord
              )}
              displayNewInvoiceRecord={pageHelpers.renderInvoiceView.call(
                this,
                newInvoiceRecord
              )}
              showDeclineApproveButtons
              onClickDecline={(e) =>
                this.displayRequestModalForAprroval("decline")
              }
              onClickApprove={(e) =>
                this.displayRequestModalForAprroval("approve")
              }
            />
          </DoubleInvoiceView>
        )}
        {this.props.displayGeneralAlert.show && <GeneralAlert />}

        {this.renderReview(showReview, reviewDetails)}
        {showVoidAccept && this.renderVoidView(reviewDetails)}
        {showVoidDecline && this.renderDeclineVoidView(reviewDetails)}
        {declinedVoid && this.renderDeclinedAlert(reviewDetails)}
        {displayVoidRequestModal.show && (
          <VoidRequestFullScreenModal
            dispatch={this.props.dispatch}
            displayVoidRequestModal={displayVoidRequestModal}
            requesting={this.props.requesting}
            requestType={"Approve Edit Request"}
            role={"Supervisor"}
            name={voidModalData.supervisor ? voidModalData.supervisor : null}
            invoiceNumber={
              voidModalData.invoiceNumber ? voidModalData.invoiceNumber : ""
            }
            customer={
              voidModalData.customerName
                ? voidModalData.customerName
                : voidModalData.customer
            }
            customerRole={"Staff"}
            revenue={voidModalData.revenue ? voidModalData.revenue : ""}
            invoiceDate={
              voidModalData.invoiceDate
                ? moment(voidModalData.invoiceDate).format("YYYY-MM-DD")
                : ""
            }
            amount={
              voidModalData.amount
                ? appHelpers.numberWithCommasOnly(voidModalData.amount)
                : 0
            }
            placeholder={"Insert comment here"}
            buttonText={"Send"}
            value={this.state.comment}
            onChange={(event) => this.handleChange(event, "comment")}
            initiateRequest={() => this.processEditRequest()}
            decimalPrecision={voidModalData.decimalPrecisions}
          />
        )}
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    alert,
    requesting,
    displayGeneralAlert,
    apiReducer,
    alertComponentsReducer,
    settings,
  } = state;
  return {
    alert,
    requesting,
    apiReducer,
    alertComponentsReducer,
    displayGeneralAlert,
    settings,
  };
};

export default connect(mapStateToProps)(VoidRequestTable);
