import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import "./dashboard.scss"
import OverviewCard from "../../components/OverviewCard";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Badge,
  Container,
} from "reactstrap";
import { appHelpers } from "../../_helpers";
import BarChartCard from "../../components/BarChartCard";
import DoughnutChartCard from "../../components/DoughnutChartCard";
// import Spinner from "../../components/Spinner";
import ActivityCard from "../../components/ActivityCard";
import FullScreenModal from "../../components/FullScreenModal";
import ActionCard from "../../components/ActionCard";
import { commonActions, alertActions } from "../../_actions";
import { commonService } from "../../_services/common.service";
import { appConstants } from "../../_constants/app.constants";
import moment from "moment";
import ProgressLoader from "../../components/ProgressLoader";
import axios from "axios";

let source;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showWelcomeScreen: true,
      activities: {},
      activitiesSpinner: true,
      requesting: true,
      invoicePaymentSummary: {},
      invoicePaymentSummarySpinner:true,
      pieChartData:{},
      pieChartDataSpinner:true,
      barChartData:{},
      barChartDataSpinner:true,
      dashboardData: {},
    };

    source = axios.CancelToken.source();
  }

  componentWillReceiveProps(newProps) {


    if (newProps.dashboard) {
      let newValues = newProps.dashboard.picker;
      let oldValues = this.props.dashboard.picker;

      let isSame = Object.is(newValues, oldValues);

      if (!isSame) {
        this.setState({ activitiesSpinner: true, invoicePaymentSummarySpinner:true, pieChartDataSpinner:true, barChartDataSpinner:true })
        this.getMyActivities(newValues);
        this.getAdminDashboard(newValues);
      }

    }

  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";
    
    //Call branding if there is no method to display select agency view
   appHelpers.checkDerivedStateFromPropsMethod.call(this);
    
    this.props.dispatch(commonActions.setPeriodPickerVisibility(true));
    
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endDate =  moment().format("YYYY-MM-DD");
  
    let payload = {
      startDate,
      endDate
    };
    
    this.getMyActivities(payload);
    this.getAdminDashboard(payload)

  }

  getInvoiceAndPaymentPerRevenue = (payload) => {
    this.props.dispatch(alertActions.startRequest());
    commonService.getInvoiceAndPaymentPerRevenue(payload, source.token)
      .then(res => {
        this.props.dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE){
          this.setState({ barChartData: response.data }, () => {
            this.setState({ barChartDataSpinner: false })
          })
        }
        else if (res.status === appConstants.ERROR_RESPONSE){
            this.props.dispatch(alertActions.error(res.response))
        }
      })
  };

  getdInvoiceAndPaymentSummary = (payload) => {
    this.props.dispatch(alertActions.startRequest());
    commonService.getInvoiceAndPaymentSummary(payload, source.token)
      .then(res => {
        this.props.dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({ invoicePaymentSummary: response.data }, () => {
            this.setState({ invoicePaymentSummarySpinner: false })
          })
        }
        else if (res.status === appConstants.ERROR_RESPONSE) {
          this.props.dispatch(alertActions.error(res.response))
        }

      })
  }
  
  getAdminDashboard = (payload) => {
    this.props.dispatch(alertActions.startRequest());
    commonService.getAdminDashboard(payload, source.token)
    .then(res => {
      // this.props.dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          dashboardData: response.data
        }, () => {
          this.setState({pieChartDataSpinner: false,
            invoicePaymentSummarySpinner: false,
            barChartDataSpinner : false,
          })
          
        })
    }
    else if (res.status === appConstants.ERROR_RESPONSE) {
      this.props.dispatch(alertActions.error(res.response))
    }
   })
    this.setState({ requesting: false })
  };

  getMyActivities = (payload) => {
    this.props.dispatch(alertActions.startRequest());
    commonService.getMyActivities(payload, source.token)
      .then(res => {
        this.props.dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({ activities: response.data }, () => {
            this.setState({ activitiesSpinner: false })
          })
        }
        else if (res.status === appConstants.ERROR_RESPONSE) {
          this.props.dispatch(alertActions.error(res.response))
        }


      })
  }

  getTopFiveInvoiceAndPaymentPerRevenue = (payload) => {
    this.props.dispatch(alertActions.startRequest());
    commonService.getTopFiveInvoiceAndPaymentPerRevenue(payload, source.token)
      .then(res => {
        this.props.dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({ pieChartData: response.data }, () => {
            this.setState({ pieChartDataSpinner: false })
          })
        }
        else if (res.status === appConstants.ERROR_RESPONSE) {
          this.props.dispatch(alertActions.error(res.response))
        }


      })

    this.setState({ requesting: false })
  }

  dismissWelcomeScreen = value => {
    this.props.dispatch(commonActions.setWelcomeScreenVisibility(value));
    
  };


  handleClose = (value) => {
    const body = document.body;
    body.style.overflow = "scroll";
    this.setState({ showWelcomeScreen: false})
    this.props.dispatch(commonActions.setWelcomeScreenVisibility(value));
  }

  componentWillUnmount() {
    this.props.dispatch(commonActions.setPeriodPickerVisibility(false));

    if (source) {
      source.cancel()
    }
  }
  
  // renderActionCards = () => {
  //   const { user } = this.props;
  // };

  render() {
    // const { showWelcomeScreen } = this.state;
    const { requesting, user, showWelcomeScreen, dashboard: { type } } = this.props;
    const { invoiceRaised, invoicePaid, invoiceOutstanding, invoiceVoided } = this.state.activities;
    const { activitiesSpinner,invoicePaymentSummarySpinner,pieChartDataSpinner,barChartDataSpinner } = this.state;
    const { dashboardData } = this.state;
    // const {top5InvoiceNaira,top5PaymentNaira} = pieChartData;
    const {top5Invoice,top5Payment, nairaInvoice, dollarInvoice, nairaPayment, dollarPayment} = dashboardData
  
    return (
      <div>
        {
         requesting &&
        <ProgressLoader message={"Sending Request"}/>
        }
        {/* Overview cards */}
       {this.state.showWelcomeScreen && 
        <FullScreenModal
          show={showWelcomeScreen}
          callback={ this.dismissWelcomeScreen}
          zIndex={100}
          width={"65%"}
        >
          <Card className="p-4" style={{ borderRadius: "12px"}} >
            <Container className="text-center">
              <Badge color="primary" style={{fontSize:"9px"}}>{`Last seen on ${appHelpers.formatDateSpecific(
                user.lastActvityDate,
                "LLLL"
              )}`}</Badge>
              <div className={'welcome-message'}>
                <h1 className="pt-4 font-bold">{`Welcome ${user.fullName}`}</h1>
                <p className="font-14">What would you like to do today?</p>
              </div>
              

              <Row className="mt-2 p-4">
                {user.permission && user.permission.map((item, index) => {
                  return (
                    <Col key={index} sm={12} md={4}>
                      <ActionCard
                        image={
                          <img
                            width="120"
                            className="img rounded-circle my-1 shadow image-card"
                            alt={"icon"}
                            src={item.iconName}
                          />
                        }
                        action={item.permission_Title ? item.permission_Title : null}
                        actionDetails={item.permission_Description}
                        route={item.route}
                      />
                    </Col>
                  );
                  // }
                })}
              </Row>
            </Container>
          </Card>
          <h4 style={{color:"#ffffff",marginLeft:"50%", cursor:"pointer"}}
           onClick={() => this.handleClose(false)}>
          Skip</h4>
        </FullScreenModal>}

        <Row>
          <Col md="6" lg="3">
            <OverviewCard
              label={`Invoices ${type}`}
              amount={appHelpers.numberWithCommas(nairaInvoice? nairaInvoice.itemTotal:"0")}
              currency="naira"
              count={nairaInvoice? nairaInvoice.itemCount:"0"}
              borderColor="#40D999"
              visible={invoicePaymentSummarySpinner}
            />
          </Col>
          <Col md="6" lg="3">
            <OverviewCard
              label={`Invoices ${type}`}
              amount={appHelpers.numberWithCommas(dollarInvoice ?  dollarInvoice.itemTotal:"0")}
              currency="dollar"
              count={dollarInvoice ? dollarInvoice.itemCount:"0"}
              borderColor="#40D999"
              visible={invoicePaymentSummarySpinner}
            />
          </Col>
          <Col md="6" lg="3">
            <OverviewCard
              label={`Payments ${type}`}
              amount={appHelpers.numberWithCommas(nairaPayment ? nairaPayment.itemTotal:"0")}
              currency="naira"
              count={nairaPayment ? nairaPayment.itemCount:"0"}
              borderColor="#28baf0"
              visible={invoicePaymentSummarySpinner}
            />
          </Col>
          <Col md="6" lg="3">
            <OverviewCard
              label={`Payments ${type}`}
              amount={appHelpers.numberWithCommas(dollarPayment ? dollarPayment.itemTotal:"0")}
              currency="dollar"
              count={dollarPayment ? dollarPayment.itemCount:"0"}
              borderColor="#28baf0"
              visible={invoicePaymentSummarySpinner}
            />
          </Col>
        </Row>

        <Row>
          {/* Bar chart of invoices versus payments */}
          <Col lg="8" md="12">
            <BarChartCard
              title="Total number of invoices issued and payments made"
              // barData={ barChartData && !barChartDataSpinner ? barChartData : ""}
              barData={dashboardData && !barChartDataSpinner ? dashboardData : ""}

              visible={barChartDataSpinner}
            />
          </Col>
          {/* Doughnut chart of top 5 revenue lines */}
          <Col lg="4" md="12">
            <DoughnutChartCard
              title="Top 5 Revenue Lines (Invoices)"
              doughnutData={!pieChartDataSpinner ? top5Invoice:""}
              pieChartData={!pieChartDataSpinner ? dashboardData.top5Invoice:""}
              visible={pieChartDataSpinner}
            />
            
          </Col>
        </Row>

        <Row>
          {/* Activites for the month */}
          <Col lg="8" md="12">
            <Card className="activities pb-4 px-4">
              <CardBody>
                <CardTitle>
                  <h4 className="sub-heading text-center pb-4">
                    See your activities this month
                  </h4>
                </CardTitle>
                <div style={{ height: "350px" }}>
                  <Row>
                    <Col md="6">
                      <ActivityCard
                        label="Invoices Raised"
                        bgColor="#28BAF0"
                        value={invoiceRaised}
                        visible={activitiesSpinner}
                      />
                    </Col>
                    <Col md="6">
                      <ActivityCard
                        label="Invoices Voided"
                        bgColor="#F54A34"
                        value={invoiceVoided}
                        visible={activitiesSpinner}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <ActivityCard
                        label=" Invoices Paid"
                        bgColor="#40d999"
                        value={invoicePaid}
                        visible={activitiesSpinner}
                      />
                    </Col>
                    <Col md="6">
                      <ActivityCard
                        label="Outstanding Invoices"
                        bgColor="#6C77C9"
                        value={invoiceOutstanding}
                        visible={activitiesSpinner}
                      />
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg="4" md="12">
            <DoughnutChartCard
              title="Top 5 Revenue Lines (Payments)"
              doughnutData={!pieChartDataSpinner ? top5Payment:""}
              pieChartData={!pieChartDataSpinner ? dashboardData.top5Payment:""}
              visible={pieChartDataSpinner}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user, showWelcomeScreen, dashboard, requesting } = state;
  return {
    user,
    showWelcomeScreen,
    dashboard,
    requesting
  };
};

export default connect(mapStateToProps)(Dashboard);
