import React from "react";
import { appHelpers } from "../../_helpers";

const PrintInvoice = ({
  invoiceNumber,
  rrr,
  invoiceDate,
  clientName,
  issuerName,
  printDate,
  paymentStatus,
  bills,
  amountString,
  logo,
  currencySign,
  decimalPrecision,
}) => {
  return (
    <div id={"divcontents"}>
      <div className={"logo"}>
        <img src={logo} alt="logo" />
      </div>
      <div className={"child-wrapper"}>
        <label className="print-item-name">Invoice Number</label>
        <span className="print-item-price">{invoiceNumber}</span>
      </div>
      <div className={"child-wrapper"}>
        <label className="print-item-name">RRR</label>
        <span className="print-item-price">{rrr}</span>
      </div>
      <div className={"child-wrapper"}>
        <label className="print-item-name">Invoice Date</label>
        <span className="print-item-price">
          {appHelpers.formatInvoiceDate(invoiceDate)}
        </span>
      </div>
      <div className={"child-wrapper"}>
        <label className="print-item-name">Client Name</label>
        <span className="print-item-price">{clientName}</span>
      </div>
      <div className={"child-wrapper"}>
        <label className="print-item-name">Issuer Name</label>
        <span className="print-item-price">{issuerName}</span>
      </div>
      <div className={"child-wrapper"}>
        <label className="print-item-name">Print Date</label>
        <span className="print-item-price">
          {appHelpers.formatInvoiceDate(printDate)}
        </span>
      </div>
      {/*<div className="print-space"></div>*/}
      <div className={"status"}>{paymentStatus}</div>
      {bills &&
        bills.map((item, i) => {
          return (
            <div key={i + 1} className={"bills-wrapper"}>
              <label className="bills-header print-item-name">
                <b>ITEM {i + 1}</b>
              </label>
              <div className={"child-wrapper"}>
                <label className="print-item-name">Description</label>
                <span className="print-item-price">{item.description}</span>
              </div>
              <div className={"child-wrapper"}>
                <label className="print-item-name">Quantity</label>
                <span className="print-item-price">{item.quantity}</span>
              </div>
              <div className={"child-wrapper"}>
                <label className="print-item-name">Amount</label>
                <span className="print-item-price">
                  <b>
                    {currencySign}{" "}
                    {appHelpers.numberWithCommasOnly(
                      Number(item.amount),
                      2
                    )}
                  </b>
                </span>
              </div>
            </div>
          );
        })}
      <div className="print-space"></div>
      <hr />
      <div className={"child-wrapper"}>
        <p className={"grand-total print-item-name"}>
          <b>Grand Total</b>
        </p>
        <p className={"print-item-price grand-total"}>
          <b>
            {currencySign}{" "}
            {appHelpers.numberWithCommasOnly(
              Number(amountString),
              2
            )}
          </b>
        </p>
      </div>
      <br />
      <br />
      <div className={"child-wrapper"}>
        <label className="print-item-name">By: ViGiPay</label>
        <span className="print-item-price"></span>
      </div>
      <div>
        <div className="footer">
          <h4 className={"footnote"}>Footnotes</h4>
          <p>
            Complaints allowed within 24 hours upon receipt of this invoice.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrintInvoice;

export const printCSS =
  "#divcontents{" +
  "width:320px;" +
  "font: 17 arial, sans-serif;" +
  "height: auto; " +
  "font-weight: 500; " +
  "font-size: 18px; " +
  "position: relative;" +
  "overflow : auto" +
  " }" +
  ".child-wrapper{" +
  "width:100%;" +
  "float:left;" +
  "display: inline-block;" +
  "clear: left;" +
  " }" +
  ".logo{" +
  "text-align: center;" +
  "}" +
  ".logo img{" +
  "width: 100px;" +
  "}" +
  "#divcontents p{" +
  "}" +
  ".child-wrapper p:nthChild(2){" +
  "text-align: right;" +
  "}" +
  ".child-wrapper h6{" +
  "margin-bottom: 0;" +
  "}" +
  ".grand-total{" +
  "font-weight: bold;" +
  "font-size: 16px;" +
  "}" +
  ".footnote{" +
  "font-weight: bold;" +
  "margin-bottom: 0;" +
  "}" +
  ".right-item {" +
  "position:absolute;" +
  "left: 60%;" +
  "text-align:right;" +
  "font: 14px arial, sans-serif;" +
  "word-wrap: break-word;" +
  // 'min-width: 160px;' +
  // 'max-width: 160px;' +
  "}" +
  ".print-item-name{" +
  "float:left;" +
  "text-align:left;" +
  "font: 14px arial, sans-serif;" +
  "margin: 5px 0;" +
  "}" +
  ".print-item-price{" +
  "float:right;" +
  "padding-right:5px;" +
  "text-align:right;" +
  "font: 14px arial, sans-serif;" +
  "word-wrap: break-word;" +
  "min-width: 160px;" +
  "max-width: 160px;" +
  "margin: 5px 0;" +
  " }" +
  ".status{" +
  "color: #ccc;" +
  "opacity: 0.5;" +
  "font-size: 40px;" +
  "transform: rotate(300deg);" +
  "-webkit-transform: rotate(300deg)" +
  // 'position: absolute' +
  // 'left: 50%' +
  // 'top: 70%' +
  " }" +
  ".print-space{" +
  "clear:left;" +
  "padding: 10px;" +
  "}" +
  ".footer{" +
  "clear:left;" +
  "padding-top: 20px;" +
  "text-align:left;" +
  "font: 12px arial, sans-serif;" +
  "}";
