import { modalConstants } from "../_constants";

function success(message) {
  return { type: modalConstants.SUCCESS, message };
}

function error(message) {
  return { type: modalConstants.ERROR, message };
}

function notify(message) {
  return { type: modalConstants.NOTIFY, message };
}

function clear() {
  return { type: modalConstants.CLEAR };
}

function displayGeneralAlert(payload) {
  return { type: modalConstants.DISPLAY_GENERAL_ALERT, payload };
}

function displaySecondaryAlert(payload) {
  return { type: modalConstants.DISPLAY_SECONDARY_ALERT, payload };
}

function displayDialogAlert(payload) {
  return { type: modalConstants.DISPLAY_DIALOG_ALERT, payload };
}

function displayShareInvoiceModal(payload) {
  return { type: modalConstants.DISPLAY_SHARE_INVOICE, payload };
}

function displayInvoiceSheet(payload) {
  return { type: modalConstants.DISPLAY_INVOICE_SHEET, payload };
}

function displayCreatedInvoice(payload) {
  return { type: modalConstants.DISPLAY_CREATED_INVOICE, payload };
}

function displayVoidRequestModal(payload) {
  return { type: modalConstants.DISPLAY_VOID_REQUEST, payload };
}
function displayChangePasswordModal(payload) {
  return { type: modalConstants.DISPLAY_CHANGE_PASSWORD_MODAL, payload };
}
function displayDoubleInvoices(payload) {
  return { type: modalConstants.DISPLAY_DOUBLE_INVOICES, payload };
}

//
export const modalActions = {
  notify,
  success,
  error,
  clear,
  displayGeneralAlert,
  displaySecondaryAlert,
  displayDialogAlert,
  displayShareInvoiceModal,
  displayInvoiceSheet,
  displayCreatedInvoice,
  displayVoidRequestModal,
  displayChangePasswordModal,
  displayDoubleInvoices,
};
