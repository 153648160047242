import React from "react";
import profile from "../../../assets/images/users/generic-user.png";
import { Button } from "reactstrap";

const PersonalInformation = ({
  firstName,
  lastName,
  emailAddress,
  userRole,
  phoneNumber,
  openChangePasswordModal,
}) => {
  return (
    <div className="profile">
      <div className="profile__title">
        <h3 className="">Personal Information</h3>
      </div>
      <div className="profile__img">
        <img
          src={profile}
          alt={"user"}
          className="rounded-circle my-4 shadow"
          width="100"
        />
      </div>
      <div className="profile__info">
        <h4>{`${firstName} ${lastName}`}</h4>
        <p>{userRole}</p>
      </div>
      <div className="profile__details">
        <div className="profile__details-row">
          <div className="profile__details_col">
            <h4>First Name</h4>
            <p>{firstName}</p>
          </div>
          <div className="profile__details_col">
            <h4>Last Name</h4>
            <p>{lastName}</p>
          </div>
          <div className="profile__details_col">
            <h4>Email address</h4>
            <p>{emailAddress}</p>
          </div>
          <div className="profile__details_col">
            <h4>Phone Number</h4>
            <p>{phoneNumber}</p>
          </div>
          <div className="profile__details_col">
            <h4>Password</h4>
            <Button
              className="btn-sm btn-rounded px-4 mr-2 text-white small-action font-weight-bold"
              color="info"
              onClick={openChangePasswordModal}
            >
              Change Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
