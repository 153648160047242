import React from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

const mapStateToProps = state => ({
    ...state
});

class SidebarRight extends React.Component {

    constructor(props) {
        super(props);
        this.expandLogo = this.expandLogo.bind(this);
        this.toggle = this.toggle.bind(this);
        this.activeRoute.bind(this);
        this.state = {
            collapse: false
        };
    }
    /*--------------------------------------------------------------------------------*/
    /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
    /*--------------------------------------------------------------------------------*/
    expandLogo() {
        document.getElementById("logobg").classList.toggle("expand-logo");
    }
    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }
    /*--------------------------------------------------------------------------------*/
    /*Verifies if routeName is the one active (in browser input)                      */
    /*--------------------------------------------------------------------------------*/
    activeRoute(routeName) { return this.props.location.pathname.indexOf(routeName) > -1 ? 'selected' : ''; }

    render() {

        const { children} = this.props;
        return (
            <aside className="left-sidebarRight" id="right-sidebarbg"  onMouseEnter={this.expandLogo} onMouseLeave={this.expandLogo}>
                <div className="scroll-sidebar">
                    <PerfectScrollbar className="sidebar-nav-right">
                        {children}
                    </PerfectScrollbar>
                </div>
            </aside>
        );
    }
}
export default connect(mapStateToProps)(SidebarRight);
