import React from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import ProgressLoader from "../ProgressLoader";
// import {commonActions} from "../../_actions";

const ContentLayout = ({ pageTitle, actions, children, requesting, height, middleContent}) => {
  return (
    // <div onClick={() => dispatch(commonActions.showRightSidebar(false))}>
    <div>
      {requesting && <ProgressLoader message={"Sending Request"} />}
      <Card
        style={{
          borderRadius: "0.5rem 0.5rem 0 0",
          backgroundColor: "rgb(255,255,255)",
          minHeight: height,
        }}
        className="p-3"
      >
        <CardHeader
          style={{ background: "transparent", padding: "0.9rem 1.25rem" }}
        >
          <Row
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              alignItems: "flex-end",
            }}
            className="pb-2"
          >
            <Col md={`${middleContent ? 4 : 6}`}>
              <div>
                <h2
                  style={{
                    fontWeight: "700",
                    fontSize: "24px",
                    marginBottom: 0,
                  }}
                >
                  {pageTitle}
                </h2>
              </div>
            </Col>
            {middleContent && (
              <Col md="3" sm="6">
                {middleContent}
              </Col>
            )}

            <Col md={`${middleContent ? 5 : 6}`} sm="6">
              {actions}
            </Col>
          </Row>
        </CardHeader>
        <CardBody style={{ backgroundColor: "rgba(255,255,255, 0.9)" }}>
          {children}
        </CardBody>
      </Card>
    </div>
  );
};

export default ContentLayout;
