import { alertConstants, appConstants } from "../_constants";
import { commonService } from "../_services";
import { history } from "../_helpers";
import { alertActions } from "./alert.actions";
import { brandingService } from "../_services/branding.service";
import { setLogo } from "../redux/settings/action";
// import { alertActions } from '../_actions';

export const commonActions = {
  login,
  logOut,
  setPeriodPickerVisibility,
  setWelcomeScreenVisibility,
  showMiniSidebarDropdown,
  showNotification,
  showRightSidebar,
  getAllStates,
  getAllOrganization,
  getEntities,
  getCountries,
  baseRoute,
  displayLoaderFullOverlay,
  saveGatewayCode,
  saveUserData,
  getBranding,
  saveSelectedLocationIds,
  getRoutes,
};

function login(payload) {
  return (dispatch) => {
    // dispatch(alertActions.startRequest());
    return commonService.login(payload).then(
      (response) => {
        if (response.status === appConstants.SUCCESS_RESPONSE) {
          let user = response.response;
          // user.AppRole = appHelpers.interpretRole(user);
          dispatch(success(user));
          // dispatch(alertActions.stopRequest());
          // dispatch(alertActions.clear());
          //redirect user

          // history.push("/dashboard");
          history.push("/dashboard");

          // userHomepage(response.response); //redirect user to homepage
        } else {
          dispatch(failure(response.response));
          dispatch(alertActions.stopRequest());
        }
      },
      (error) => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Unable to handle request";
        dispatch(failure(errorMessage));
        dispatch(alertActions.stopRequest());
      }
    );
  };
  function success(user) {
    return { type: appConstants.SIGN_IN_SUCCESS, user };
  }
  function failure(message) {
    return { type: alertConstants.ERROR, message };
  }
}

//payload1 contains agencyId & agencyCode
//payload2 contains the user object
function getBranding(payload1, payload2) {
  return (dispatch) => {
    dispatch(alertActions.startAppRequest());
    return brandingService.getBranding(payload1).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (response.data && typeof response.data === "object") {
          dispatch(alertActions.stopAppRequest());
          dispatch(saveUserData(response.data, payload2));
          dispatch(setLogo(response.data.logoURL));
        }
        if (response.data === null) {
          dispatch(saveUserData(response.data, payload2));
          dispatch(setLogo(null));
          dispatch(alertActions.stopAppRequest());
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(failure(response));
        dispatch(alertActions.stopAppRequest());
      }
    });
  };
  function failure(message) {
    return { type: alertConstants.ERROR, message };
  }
}
function logOut() {
  // commonService.logOut();
  // return { type: appConstants.SIGN_OUT };
  return (dispatch) => {
    dispatch(success({}));
    function success(user) {
      return { type: appConstants.SIGN_OUT, user };
    }
  };
}

function setPeriodPickerVisibility(visibility) {
  return { type: appConstants.SET_PERIOD_PICKER_VISIBILITY, visibility };
}

function setWelcomeScreenVisibility(visibility) {
  return { type: appConstants.SET_WELCOME_SCREEN_VISIBILITY, visibility };
}

function showMiniSidebarDropdown(show) {
  return { type: appConstants.SHOW_MINI_SIDEBAR_DROPDOWN, show };
}

//use this to control actions or styles you want to happen when notification bar is displayed
function showNotification(show) {
  return { type: appConstants.SHOW_NOTIFICATION, show };
}

function showRightSidebar(show) {
  return { type: appConstants.SHOW_RIGHT_SIDEBAR, show };
}

function baseRoute(route) {
  return { type: appConstants.BASE_ROUTE, route };
}

function displayLoaderFullOverlay(fullOverlay) {
  return { type: appConstants.DISPLAY_FULL_OVERLAY, fullOverlay };
}

function saveGatewayCode(gatewayCode) {
  return { type: appConstants.GATEWAY_CODE, gatewayCode };
}
function saveUserData(brandingPayload, userPayload) {
  let payload;

  if (userPayload) {
    payload = { ...brandingPayload, ...userPayload };
  } else {
    payload = { ...brandingPayload };
  }
  return { type: appConstants.USER_DATA, payload };
}

function getAllStates(cancelToken) {
  return (dispatch) => {
    return commonService.getStates(cancelToken).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (response.data) {
          dispatch(success(response));
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
          dispatch(failure(response.response));
        }
      }
    });
  };

  function success(states) {
    return { type: appConstants.STATE_SUCCESS, states };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getAllOrganization(cancelToken) {
  return (dispatch) => {
    return commonService.getAllOrganizations(cancelToken).then(
      (response) => {
        if (response.error == null && response.response) {
          dispatch(success(response.response));
        } else {
          dispatch(failure(response));
        }
      },
      (error) => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Unable to handle request";
        dispatch(failure(errorMessage));
      }
    );
  };

  function success(organizations) {
    return { type: appConstants.ORGANIZATION_SUCCESS, organizations };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function getEntities() {
  return (dispatch) => {
    return commonService
      .searchAppEntityTypes(appConstants.MAKER_CHECKER_APP_ID)
      .then(
        (response) => {
          if (response.status === appConstants.SUCCESS_RESPONSE) {
            dispatch(success(response.response.Results));
          } else if (response.status === appConstants.ERROR_RESPONSE) {
            dispatch(failure(response.response));
          }
        },
        (error) => {
          let errorMessage = error.response.data
            ? error.response.data
            : "Unable to handle request";
          dispatch(failure(errorMessage));
        }
      );
  };

  function success(entities) {
    return { type: appConstants.GET_ENTITIES, entities };
  }
  function failure(message) {
    return { type: alertConstants.ERROR, message };
  }
}

function getCountries(cancelToken) {
  return (dispatch) => {
    return commonService.getCountries(cancelToken).then(
      (response) => {
        if (response.error == null) {
          dispatch(success(response.response));
        } else {
          dispatch(failure(response));
        }
      },
      (error) => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Unable to handle request";
        dispatch(failure(errorMessage));
      }
    );
  };

  function success(countries) {
    return { type: appConstants.COUNTRIES_SUCCESS, countries };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function saveSelectedLocationIds(selectedIds) {
  return { type: appConstants.SAVE_SELECTED_LOCATION_IDS, selectedIds };
}

function getRoutes(routes) {
  return { type: appConstants.GET_ROUTES, routes };
}

// function getAllCreditNoteType(cancelToken) {
//   return (dispatch) => {
//     return commonService.getAllCreditTypes(cancelToken).then(
//       (response) => {
//         if (response.error == null && response.response) {
//           dispatch(success(response.response));
//         } else {
//           dispatch(failure(response));
//         }
//       },
//       (error) => {
//         let errorMessage = error.response.data
//           ? error.response.data
//           : "Unable to handle request";
//         dispatch(failure(errorMessage));
//       }
//     );
//   };

//   function success(creditTypes) {
//     return { type: appConstants.CREDITNOTE_TYPE, creditTypes};
//   }
//   function failure(message) {
//     return { type: appConstants.REQUEST_FAILURE, message };
//   }
// }