import React, { useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import "./index.scss";

const PrivacyPolicy = () => {
  const getUrl = () => {
    const queryArray = window.location.href.split("/");
    if (queryArray[3]) {
      return queryArray[3];
    }
    return null;
  };
  useEffect(() => {
    const body = document.body;
    body.style.overflow = "scroll";
  });

  return (
    <Card>
      <CardBody>
        <div className={"d-flex justify-content-end"}>
          {" "}
          {getUrl() === "privacy-policy" && (
            <>
              <Link to="/cookie-policy" className="pr-5">
                Cookie Policy
              </Link>
              <Link to="/customer-awarness-policy" className="pr-5">
                Customer Awareness Policy
              </Link>
              <Link to="/login" className="pr-5">
                Login
              </Link>
            </>
          )}
        </div>
        <h2>Preface</h2>
        <p>
          AviTech Software Solutions [AviTech] strongly believes in providing
          total privacy and protection to our customer’s personal data. The
          information our customers give us are gotten with full consent, are
          processed under legal basis and are not sold, rented, loaned or
          otherwise disclosed to third parties.
        </p>
        <p>
          Our policies are created to provide protection of the confidentiality
          and security of our customer’s personal information. We only require
          the minimum amount of personal information needed to fulfil our
          service to our customers. Our privacy standards are in compliance with
          the National Data Protection Regulation (NDPR) and the General Data
          Protection Regulation (GDPR).
        </p>
        <h6 className="font-weight-bold">1. Policy Overview</h6>
        <p>
          This Privacy Policy describes the process involved in the acquisition,
          processing, porting, storage and disposing of your personal data in
          connection with our websites, portals, mobiles applications, tools and
          services. This Privacy Policy also governs your rights regarding the
          foregoing AviTech Software Solutions [AviTech] takes pride in securing
          and maintaining the privacy of the data we collect from you.
        </p>
        <h6 className="font-weight-bold">2. Scope and Consent</h6>
        <p>
          This policy is enforced after you consent to it when you sign-up,
          access our products, services, content, features, technologies or
          functions offered from our websites, related sites, portals,
          applications and services.
        </p>
        <p>
          However, AviTech Software Solutions [AviTech] is not responsible for
          handling of information gotten by third party customer/sites through
          the use of our tools, portal, and services and will not be held liable
          for any breach or misuse of collected information.
        </p>
        <h6 className="font-weight-bold">3. Rights</h6>
        <p>
          You have the right of access to your personal data being processed by
          AviTech Software Solutions [Avitech] which includes requesting for an
          update, rectification, erasure, asking for a copy of your personal
          data, a withdrawal of consent at any time, complaining to a data
          protection authority, without affecting the lawfulness of processing
          based on consent given before the withdrawal.
        </p>
        <p>
          You can request for a restriction or deletion of your personal data
          based on the following:
        </p>
        <ul className="ml-5">
          <li>Non-legitimate grounds for processing</li>
          <li>Unlawful processing</li>
          <li>Erasure is required for compliance with a legal obligation.</li>
          <li>Inaccuracy of personal data</li>
          <li>Change in initial purpose</li>
        </ul>
        <p>
          All requests shall be made in writing to the Data Protection Officer
          via email at{" "}
          <a
            href="mailto:dpo@avitechng.com"
            className="text-decoration: underline; color: inherit;"
          >
            dpo@avitechng.com
          </a>{" "}
        </p>
        <h6 className="font-weight-bold">4. Use of personal information</h6>
        <p>
          The collection and use of personal data by AviTech Software Solutions
          [Avitech] is guided by certain principles. These principles state that
          personal data should:
        </p>
        <ul className="ml-5">
          <li>be processed fairly, lawfully and in a transparent manner.</li>
          <li>
            be obtained for a specified and lawful purpose and shall not be
            processed in any manner incompatible with such purposes .
          </li>
          <li>
            be adequate, relevant and limited to what is necessary to fulfil the
            purpose of processing.
          </li>
          <li>be accurate and, where necessary, up to date.</li>
          <li>
            Not be kept for longer than necessary for the purpose of processing.
          </li>
          <li>be processed in accordance with the data subject’s rights .</li>
          <li>
            be kept safe from unauthorised processing, and accidental loss,
            damage or destruction using adequate technical and organizational
            measures.
          </li>
        </ul>
        <h6 className="font-weight-bold">
          5. Personal Data Collected and Used in Avitech
        </h6>
        <p>
          We collect the following personal information in order to provide
          personalized and improved experience. Personal Data may be given to us
          directly by you or by people or companies authorized by you to act on
          your behalf. We may also collect Personal Data about you from third
          parties in connection with our services. No Personal Data about you
          will be collected without your consent.{" "}
        </p>
        <p>
          Where we need to collect Personal Data by law, or under the terms of a
          contract we have with you and you fail to provide that data when
          requested, we may not be able to provide the services envisaged or
          requested.
        </p>
        <h6 className="font-weight-bold">Automatic Information</h6>
        <p>
          In general, website visitors do not need to provide personalized
          information to AviTech Software Solutions[Avitech]. We may collect
          "aggregate data," i.e., group data with no personal identifiers such
          as IP address, device type, geo-location information, computer and
          connection information, mobile network information, statistics on page
          views, traffic to and from the sites, referral URL, ad data, and
          standard web log data and other information.
        </p>
        <p>
          We use this aggregate data to help us understand how the Sites are
          being used and to improve usability. We also use it to enhance the
          quality and availability of products and services we offer.
        </p>
        <p>
          Personal data that are provided and retained allows AviTech Software
          Solutions [Avitech] provide services to its users. AviTech Software
          Solutions [Avitech] solely holds the information and engages in no
          contact-sharing program with other organizations. The information
          collected are
        </p>
        <p>
          <b>Contact Information: </b> first and last name, email address. phone
          number, town/city/state, country, citizenship, and any other
          information required to process your information as a user of our
          platforms
        </p>
        <p>
          <b> Websites Cookies: </b>(small text files) when a user visits a
          website, and these cookies are used to analyse aggregate user
          behaviour on our website. AviTech Software Solutions [Avitech]
          websites ask permission of the visitor prior to setting cookies.
          Should the visitor agree, AviTech Software Solutions [Avitech] server
          shall only collect the following information:
        </p>
        <p className={"ml-4"}>
          i. The visitor’s IP address (including the domain name associated with
          the IP address, i.e., using reverse look-up). <br />
          ii. The date and time of the visit to the website. <br />
          iii. The pages visited on the website. iv. The browser being used.{" "}
          <br />
          v. In addition, where this is available, AviTech Software Solutions
          [Avitech] shall also collect:
          <ul className={"ml-5"}>
            <li>The country from which the visitor is accessing the website</li>
            <li>The language of the browser being used.</li>
            <li>
              The website from which the visitor is accessing the AviTech
              Software Solutions [Avitech]website
            </li>
            <li>
              The search word used (if the site is accessed via a search
              engine).
            </li>
            <li>The type of connection and operating system.</li>
          </ul>
        </p>
        <p>
          We only use these data to improve the visitor’s website experience.
          Please review our Cookie Policy to learn more about how we use
          cookies. <Link to="/cookie-policy">(cookie policy)</Link>
        </p>
        <p>
          <b>Third Party Source: </b> We may collect information from third
          party websites such as social media sites which authorizes AviTech
          Software Solutions [Avitech] to access, store and use user’s
          information which will be handled in accordance with this policy.
        </p>
        <p>
          <b>Mobile Devices: </b>
          Some of our applications can be accessed through mobile application or
          mobile-optimised website from which we collect user’s data. This
          policy applies to access and use of data through mobile devices.{" "}
        </p>
        <p>
          <b>Surveys: </b> In order to ensure that the services we offer meet
          your requirements, we may ask for your feedback in form of surveys and
          polls. Any feedback received from you shall only be used for the
          purpose of improving our services and shall not be disclosed and are
          used for research and publication purposes.
        </p>
        <p>
          <b>Inquiries: </b>When an inquiry is sent to us through our contact
          form, we use the personal data that you have stated in the contact
          form to send a response to you. Any personal data received from you
          shall not be used for any other purpose without your prior consent and
          knowledge and shall not be disclosed.
        </p>
        <h6 className="font-weight-bold">6. Duration of Storage</h6>
        <p>
          We shall store your personal data until they are no longer necessary
          for the purpose which we had collected it. Your information is only
          retained in accordance with legal and regulatory requirements for the
          collected data.
        </p>
        <h6 className="font-weight-bold">7. Data Storage and Security</h6>
        <p>
          AviTech Software Solutions [Avitech] and its partners use a
          security-cleared data processor to store files and data on secure
          cloud-based servers hosted in the Republic of Ireland region of Amazon
          Web Services, and the North Europe Region of Microsoft Azure. The data
          processors are certified under the applicable frameworks and thereby
          guarantees and operates an appropriate standard of data protection and
          data security. Regardless of where your information is processed, we
          apply the same protections described in this policy and you have the
          right (upon your request) to be informed of the appropriate safeguards
          for data protection in the foreign country.
        </p>
        <p>
          We protect your Personal Data using physical, technical, and
          administrative security measures to reduce the risks of loss, misuse,
          unauthorized access, disclosure and alteration. All data is accessed
          via secure connections and stored on encrypted servers and encrypted
          storage services. We also use firewalls and physical access controls
          for our data centres, and information access authorization controls.
        </p>
        <p>
          {" "}
          Despite our efforts to establish a secure environment for the website,
          you should be aware that no information is completely secure on the
          internet. Therefore, you should always take the necessary safeguards
          and precautions on your own equipment. In the event of an actual or
          suspected breach of your Personal Data, we will use best effort to
          remedy the breach within one (1) month from the date we report the
          breach to you.
        </p>
        <h6 className="font-weight-bold">8. Children’s Privacy</h6>
        <p>
          We do not use our platforms to knowingly solicit data from children or
          individuals under the age of Sixteen (16). Where a parent or guardian
          discovers that a child or individual under the age of Sixteen (16) has
          provided us with his/her Personal Data without their consent, he or
          she should contact us at{" "}
          <a
            href="mailto:dpo@avitechng.com"
            className="text-decoration: underline; color: inherit;"
          >
            dpo@avitechng.com
          </a>{" "}
          and we shall immediately delete the user’s account from our servers.
        </p>
        <h6 className="font-weight-bold">
          <h6 className="font-weight-bold">8. Children’s Privacy</h6>
        </h6>
        <p>
          a. The Company shall take reasonable steps to ensure personal data is
          accurate. <br />
          b. Where necessary for the lawful basis on which data is processed,
          steps shall be put in place to ensure that personal data is kept up to
          date. <br />
          c. The Company shall ensure that updated personal data reflect across
          all boards to which it is being used.
        </p>
        <h6 className="font-weight-bold">10. Archiving / removal</h6>
        <p>
          a. To ensure that personal data is kept for no longer than necessary,
          the Company puts in place a data retention policy for each area in
          which personal data is processed and review this process annually.{" "}
          <br />
          b. This retention policy considers what data should/must be retained,
          for how long, and why. <br />
        </p>
        <h6 className="font-weight-bold">11. Transfer of Personal Data</h6>
        <p>
          We do not rent or sell your personally identifiable information to
          other individuals or organizations. <br />
          However, we may transfer your personal data to third parties when it
          is necessary to provide you with our service. Third parties could
          include:
        </p>
        <ul className={"ml-5"}>
          <li>Undertakings within AviTech Software Solutions [Avitech]</li>
          <li>Business partners</li>
          <li>Business partners</li>
          <li>Legal or regulatory authority</li>
          <li>Application program interface (API) Users</li>
          <li>
            Security-cleared data processors/subcontractors, who are assisting
            us or the group with IT or other services.
          </li>
        </ul>
        <p>
          When we transfer your personal data to business partners, you should
          be aware that they might have stored personal data concerning you
          collected through other means, e.g., if you have been in contact with
          them in another context.{" "}
        </p>
        <p>
          {" "}
          We also transfer your personal data to the above or other third
          parties if we are obliged to do so according to legislation or in
          order to protect our or the group’s interests in legal disputes.
        </p>
        <h6 className="font-weight-bold">
          Transfer of Personal Data to Third Countries
        </h6>
        <p>
          AviTech Software Solutions [Avitech] partners with various technology
          vendors from time to time. This shall result in a transfer of personal
          data to a third country or international organization.
        </p>
        <p>
          {" "}
          In order to ensure an equal level of security for such transfer in
          accordance with the NDPR, AviTech Software Solutions [Avitech] has
          chosen to work only with vendors that have entered Standard
          Contractual Clauses with AviTech Software Solutions [Avitech].
        </p>
        <h6 className="font-weight-bold">12. Changes to This Policy</h6>
        <p>
          We may update this Policy from time to time without any prior notice
          to you or consent. Thus, you are advised to consult this Platform
          periodically for any changes. We will notify you of any changes by
          posting the revised Policy on this Platform. Please note that changes
          shall be effective immediately after they are updated on this
          Platform.
        </p>
        <h6 className="font-weight-bold">13. Contact</h6>
        <p>
          If you want to lodge a complaint over our processing of your personal
          data or have further requests, please contact the Data Protection
          Officer directly at{" "}
          <a
            href="mailto:dpo@avitechng.com"
            className="text-decoration: underline; color: inherit;"
          >
            dpo@avitechng.com
          </a>{" "}
        </p>
        <p>
          AviTech Software Solutions [Avitech] maintains an incidence response
          plan used in dealing with incidents relating to unlawful disclosure,
          loss, alteration, destruction, access to our customer’s personal data
          collected, transmitted, stored or processed in any way.
        </p>{" "}
        <p>
          {" "}
          We will work with the National Information Technology Development
          Agency (NITDA) to resolve any issues that we cannot resolve with you
          directly.
        </p>{" "}
        <p className={"mt-5"}>Date of last revision: 1st June 2021</p>
      </CardBody>
    </Card>
  );
};

export default PrivacyPolicy;
