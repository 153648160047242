import React from 'react';
import ServerPaginatedTable from "../../components/ServerPagination";
// import ViewIcon from 'react-icons/lib/md/remove-red-eye';
import { FaEye } from "react-icons/fa";
import EmptyRecordMessage from "../../components/EmptyRecordMessage/EmptyRecordMessage";
import { appHelpers } from "../../_helpers";
import {Badge} from "reactstrap";
import ToolTipItem from "../../components/Tooltip";
import { Table} from "reactstrap";

const DataTable = ({data, handApprovalModal, handApprovalModalArray }) =>{
    return (data.length === 0) ?
        (<EmptyRecordMessage message='No record match your search!'/>) :
        (
                <Table responsive={true} className={"table-main"}>
                        <thead className={"th-head"}>
                        <tr>
                            <th>
                                <span>Entity</span>
                            </th>
                            <th>
                                <span>Created By</span>
                            </th>
                            <th>
                                <span>Created Date</span>
                            </th>
                            <th>
                                <span>Approved By</span>
                            </th>
                            <th>
                                <span>Status</span>
                            </th>
                            <th>
                                <span>Action</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((record, i) =>
                            <tr key={i+1}>
                                <td style={{ padding: "none" }}
                                    className="text-capitalize">{appHelpers.interpretEntityTypeId(record.entityTypeId)}</td>
                                <td style={{ padding: "none" }}>{record.createdBy}</td>
                                <td style={{ padding: "none" }}>{appHelpers.formatDateSpecific(record.createdDate)}</td>
                                <td style={{ padding: "none" }}>{record.approvedBy}</td>
                                <td style={{ padding: "none" }}>
                                    {record.statusId === 0 &&
                                        <Badge color={"secondary"}>&nbsp;Pending&nbsp;</Badge>
                                    }
                                    {record.statusId === 1 &&
                                        <Badge color='success'>&nbsp;Approved&nbsp;</Badge>
                                    }
                                    {record.statusId === 2 &&
                                        <Badge color={'danger'}>&nbsp;Rejected&nbsp;</Badge>
                                    }
                                </td>
{/*                                 
                                 <td style={{ padding: "none" }}>
                                 {Array.isArray(record.payloadViewTemplate) && 
                                 <span className="pointer" onClick={() => handApprovalModalArray(record)}>
                                     <ToolTipItem key={'viewRecord'} title={<FaEye size="18" color="#3da7e2"/>} content='View' id={'viewRecord'}/>
                                 </span>}

                                {typeof record.payloadViewTemplate === 'object' &&
                                    <span className="pointer" onClick={() => handApprovalModal(record)}>
                                        <ToolTipItem key={'viewRecord'} title={<FaEye size="18" color="#3da7e2"/>} content='View' id={'viewRecord'}/>
                                    </span>}
                                </td> */}
                               <td style={{ padding: "none" }}>
                                
                                 <span className="pointer" onClick={() => handApprovalModal(record)}>
                                     <ToolTipItem key={'viewRecord'} title={<FaEye size="18" color="#3da7e2"/>} content='View' id={'viewRecord'}/>
                                 </span>
                               </td>
                            </tr>
                        )}
                        </tbody>
                </Table>
        )
}

class PaginatedResult extends React.Component {

    render() {
        const {  Size, currentPage, totalCount,navigate, records, handApprovalModal, handApprovalModalArray} = this.props;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-4" >
                        <ServerPaginatedTable  currentPage={currentPage} fetchMore={navigate} target={<DataTable data={records} handApprovalModal={handApprovalModal} handApprovalModalArray={handApprovalModalArray} />} Size={Size} totalCount={totalCount }/>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaginatedResult
