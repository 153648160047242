import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function displayLoaderFullOverlay(state = initialState.displayLoaderFullOverlay, action) {
  
  switch (action.type) {
    case appConstants.DISPLAY_FULL_OVERLAY:
      return action.fullOverlay;
    default:
      return state;
  }
}