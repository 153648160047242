import React from 'react';
import { modalActions} from "../../_actions";
import FullScreenModal from "../FullScreenModal";
import {connect} from "react-redux";
import ShareInvoice from "./shareInvoice";
import {appHelpers} from "../../_helpers";

const ShareInvoiceFullScreenModal = ({
                                       displayShareInvoice, dispatch, handleShareEmailChange, shareInvoice,
                                       onkeydownEnterShareInvoice, requesting
                               }) => {
  return (
    <FullScreenModal
      show={displayShareInvoice.show}
      width={"35%"}
      callback={(res)  => {
        appHelpers.fullScreenCallback.call(this, res, dispatch, displayShareInvoice, modalActions.displayShareInvoiceModal({
          show: false
        }))
      }}
      zIndex={requesting ? 10 : 999}
    >
      <ShareInvoice
        handleShareEmailChange={handleShareEmailChange}
        shareInvoice={shareInvoice}
        onkeydownEnterShareInvoice={onkeydownEnterShareInvoice}
        requesting={requesting}
      />
    
    </FullScreenModal>
  );
};

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps)(ShareInvoiceFullScreenModal);

