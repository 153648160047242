import React from 'react'
import {Row, Col, Label, Input} from "reactstrap";

export default function ReasonForChange({
  name,
  id,
  value,
  handleChange,
  componentErrorMessages,
}) {
  return (
    <Row>
      <Col md="6">
        <Label>Reason for Update</Label>
        <Input
          rows="4"
          cols="50"
          _errs_={componentErrorMessages.reasonForChange}
          //   invalid={false}
          type="textarea"
          name={name}
          id={name}
          value={value}
          onChange={(e) => handleChange(e, name)}
          defaultValue=""
          //   disabled={}
        />
        {componentErrorMessages.reasonForChange && (
          <span className={"inputError"}>
            {componentErrorMessages.reasonForChange}
          </span>
        )}
      </Col>
    </Row>
  );
}
