import React, { Component } from "react";
import TableStructure from "./record";
import { Button } from "reactstrap";
import SearchBar from "../../../components/SearchBar";
import ContentLayout from "../../../components/ContentLayout";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaPlusCircle,
  FaTimes,
} from "react-icons/fa";
import { Row, Col } from "reactstrap";
import { appHelpers } from "../../../_helpers";
import { commonService } from "../../../_services/common.service";
import { appConstants } from "../../../_constants/app.constants";
import { connect } from "react-redux";
import axios from "axios";
import { alertActions } from "../../../_actions";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import "./index.scss";
import { RenderAlertModal } from "../../../components/AlertModal";

let source;

class ManageUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      userSearchValue: "",
      showAlertModal: false,
      alertMessage: "",
      alertIcon: "",
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    //Call branding if there is no method to display select agency view
    appHelpers.checkDerivedStateFromPropsMethod.call(this);

    this.searchUsers();
  }

  searchUsers = (pageNumber = 1) => {
    const { dispatch } = this.props;
    const { userSearchValue, pageSize } = this.state;
    dispatch(alertActions.startRequest());

    const payload = {
      pageSize: pageSize,
      from: pageNumber,
      parameter: {
        searchQuery: userSearchValue,
      },
    };

    commonService.searchUsers(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          currentPage: pageNumber === 0 ? 1 : pageNumber,
          data: response.results,
          totalCount: response.totalCount,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (response === "Please Check your internet connectivity") {
          dispatch(alertActions.error(response));
        }
      }
    });
  };

  createUser = () => {
    this.props.history.push({
      pathname: "/settings-create-user",
      state: { pageTitle: appConstants.CREATE_USER, showUserProfile: false },
    });
  };

  editUser = (e, item) => {
    e.stopPropagation();
    const { user } = this.props;
    if (this.userConditions(user.vggRole, item)) {
      this.props.history.push({
        pathname: "/settings-create-user",
        state: {
          item,
          pageTitle: appConstants.EDIT_USER,
          showUserProfile: false,
        },
      });
    }
  };

  showUserProfile = (e, item) => {
    const { user } = this.props;
    if (
      e.target.parentElement.className === "table-row" ||
      e.target.classList.contains("table-row")
    ) {
      if (this.userConditions(user.vggRole, item)) {
        this.props.history.push({
          pathname: "/settings-create-user",
          state: {
            item,
            showUserProfile: true,
            pageTitle: appConstants.USER_PROFILE,
          },
        });
      }
      //     if ((user.vggRole === appConstants.ROLES.CLIENT_ADMIN && item.vggRole === appConstants.ROLES.CLIENT_ADMIN)
      //         ||
      //         (user.vggRole === appConstants.ROLES.CLIENT_ADMIN && item.vggRole === appConstants.ROLES.CLIENT_USER)){
      //         this.props.history.push({
      //             pathname: '/settings-create-user',
      //             state: {item, showUserProfile: true, "pageTitle": appConstants.USER_PROFILE}
      //         })
      // } else {
      //         dispatch(alertActions.error("You dont have permission to view this user's profile"));
      //     }
    }
  };

  userConditions = (userVggRole, item) => {
    const { dispatch } = this.props;

    let pushToView = false;
    if (
      (userVggRole === appConstants.ROLES.CLIENT_ADMIN &&
        item.vggRole === appConstants.ROLES.CLIENT_ADMIN) ||
      (userVggRole === appConstants.ROLES.CLIENT_ADMIN &&
        item.vggRole === appConstants.ROLES.CLIENT_USER)
    ) {
      pushToView = true;
    } else if (
      (userVggRole === appConstants.ROLES.VGG_ADMIN &&
        item.vggRole === appConstants.ROLES.CLIENT_ADMIN) ||
      (userVggRole === appConstants.ROLES.VGG_ADMIN &&
        item.vggRole === appConstants.ROLES.VGG_USER)
    ) {
      pushToView = true;
    } else {
      dispatch(
        alertActions.notify("You dont have permission to perform this action")
      );
    }

    return pushToView;
  };

  activateUser = (payload) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    commonService.activateUser(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(response, true, this.searchUsers());
        // this.setState({
        //     showAlertModal: true,
        //     alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />,
        //     alertMessage: response.message,
        //
        // }, () => {
        //     this.searchUsers();
        //     appHelpers.dismissModal.call(this, "showAlertModal");
        // });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response, true, this.searchUsers());
      }
    });
  };

  deactivateUser = (payload) => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());
    commonService.deactivateUser(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(response, true, this.searchUsers());
        // this.setState({
        //     showAlertModal: true,
        //     alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />,
        //     alertMessage: response.message,
        //
        // }, () => {
        //     this.searchUsers();
        //     appHelpers.dismissModal.call(this, "showAlertModal");
        // });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response, true, this.searchUsers());
      }
    });
  };

  handleSwitchChecked = (e, item) => {
    if (e.target.checked === true) {
      this.activateUser(item.userId);
    }
    if (e.target.checked === false) {
      this.deactivateUser(item.userId);
    }
  };
  searchBarChange = (e) => {
    this.setState({ userSearchValue: e.target.value }, () => {
      if (this.state.userSearchValue === "") {
        this.searchUsers();
      }
    });
  };

  search = (e) => {
    e.preventDefault();
    this.searchUsers();
  };

  //Alert Notifications
  displaySuccessResponseAlert = (response, action, query) => {
    this.setState(
      {
        showAlertModal: true,
        alertIcon: (
          <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
        ),
        alertMessage: response.message,
      },
      () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
      }
    );
    if (action) {
      return { query };
    }
  };

  displayErrorResponseAlert = (response, action, query) => {
    const { dispatch } = this.props;
    if (response === "Please Check your internet connectivity") {
      dispatch(alertActions.error(response));
    } else if (typeof response === "string") {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    } else {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response.message,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    }
  };

  confirmEmail = (payload) => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());
    commonService.confirmEmail(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(response);
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response);
      }
    });
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      data,
      currentPage,
      totalCount,
      pageSize,
      userSearchValue,
      showAlertModal,
      alertMessage,
      alertIcon,
      requesting,
    } = this.state;
    const { user } = this.props;
    return (
      <ContentLayout
        pageTitle={appConstants.MANAGE_USERS}
        requesting={this.props.requesting}
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className=" btn-rounded mr-2 big-action-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <Row className={"row-space"}>
          <Col>
            <Button
              className="btn-rounded mr-2 create-action-btn text-white"
              onClick={this.createUser}
              color={"success"}
              disabled={requesting}
            >
              <FaPlusCircle className={"icon-margin mb-1"} />
              Create New User
            </Button>
          </Col>
        </Row>

        <Row className={"settings-spacing"}>
          <Col sm={{ size: 5 }} className="text-center">
            <SearchBar
              placeholder="Search user"
              onChange={this.searchBarChange}
              value={userSearchValue}
              showSearchButton
              search={this.search}
            />
          </Col>
        </Row>
        {data.length === 0 && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}
        {data.length > 0 && (
          <Row>
            <Col md={"12"}>
              <TableStructure
                tableData={data}
                editUser={this.editUser}
                showUserProfile={this.showUserProfile}
                pageSize={pageSize}
                totalCount={totalCount}
                currentPage={currentPage}
                fetchMoreRecords={this.searchUsers}
                handleSwitchChecked={this.handleSwitchChecked}
                user={user}
                confirmEmail={this.confirmEmail}
              />
            </Col>
          </Row>
        )}
        {showAlertModal && (
          <RenderAlertModal
            message={alertMessage}
            icon={alertIcon}
            showAlertModal={showAlertModal}
            hideAlertView={this.hideAlertView}
          />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, alert, progress, user, requesting } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(ManageUsers);
