import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const staffService = {
  searchSelfServiceCustomerStaff,
  activateStaff,
  deactivateStaff,
  createStaffs,
  getRoles,
  getSelfServiceUserLocation,
  getCustomerStaffDetails
};

function searchSelfServiceCustomerStaff(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SelfService/SearchSelfServiceCustomerStaffBDA`, payload, cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function activateStaff(payload, cancelToken) {
    return appHelpers
      .postRequest(
        `${appConstants.PAS_URL}/api/SelfService/ActivateSelfServiceCustomerStaffBDA?CustomerStaffId=${payload.CustomerStaffId}`, payload, cancelToken
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      });
  }

  function deactivateStaff(payload, cancelToken) {
    return appHelpers
      .postRequest(
        `${appConstants.PAS_URL}/api/SelfService/DeactivateSelfServiceCustomerStaffBDA?CustomerStaffId=${payload.CustomerStaffId}`, payload, cancelToken
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      });
  }

  function createStaffs(payload, cancelToken) {
    return appHelpers
      .postRequest(
        `${appConstants.PAS_URL}/api/SelfService/ManageSelfServiceCustomerStaffBDA`, payload, cancelToken
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      });
  }

function getRoles(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SelfService/GetSelfServiceRolesBDA`, payload, cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getSelfServiceUserLocation(payload, cancelToken) {
    return appHelpers
      .getRequest(
        `${appConstants.PAS_URL}/api/SelfService/GetSelfServiceUserLocationBDA?CustomerStaffId=${payload.CustomerStaffId}&OrganizationId=${payload.OrganizationId}`, cancelToken
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      });
  }

  function getCustomerStaffDetails(payload, cancelToken) {
    return appHelpers
      .getRequest(
        `${appConstants.PAS_URL}/api/SelfService/GetSelfServiceCustomerDetailsBDA`, payload, cancelToken
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      });
  }
  