import React, { Component } from "react";
import TableStructure from "../../search-invoice/record";
import QuickSearch from "../../../components/page-components/search-invoice-components/quick-search";
import { Row, Col } from "reactstrap";
import Summary from "../../../components/page-components/summary";
import AdvancedSearch from "../../../components/page-components/search-invoice-components/advanced-search";
import ContentLayout from "../../../components/ContentLayout";
import "../../search-invoice/index.scss";
import { appHelpers, pageHelpers } from "../../../_helpers";
import { commonService } from "../../../_services/common.service";
import { appConstants } from "../../../_constants/app.constants";
import { connect } from "react-redux";
import {
  alertActions,
  apiActions,
  commonActions,
  modalActions,
  selfServiceActions,
} from "../../../_actions";
import moment from "moment";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import PrintInvoice from "../../../components/MobilePrint/print-invoice";
import VoidRequestFullScreenModal from "../../../components/VoidRequestFullScreenModal";
import GeneralAlert from "../../../components/Api-response-alert";
import ShareInvoiceFullScreenModal from "../../../components/ShareInvoice/ShareInvoiceFullScreenModal";
import CreatedInvoiceFullScreenModal from "../../../components/CreatedInvoiceFullScreenModal";
import SearchInvoiceActionButtons from "../../../components/page-components/search-invoice-components/action-buttons";
import axios from "axios";

let source;

class CustomerSearchInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetails: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      searchBarValue: "",
      invoiceSummaryData: [],
      nairaInvoicedValue: 0,
      dollarInvoicedValue: 0,
      nairaPaidValue: 0,
      dollarPaidValue: 0,
      nairaVoidedValue: 0,
      dollarVoidedValue: 0,
      nairaCountValue: 0,
      dollarCountValue: 0,
      invoiceNumbers: [],
      invoiceNumber: "",
      revenue: "",
      revenueObj: null,
      customers: [],
      customer: "",
      searchType: 0,
      buttonType: "customer",
      buttonState: "inactive",
      buttonColor: "info",
      invoiceBtnOutline: true,
      customerBtnOutline: true,
      states: [],
      state: "",
      statusArray: [
        { name: "All", id: "-1" },
        { name: "Paid", id: 1 },
        { name: "Partly paid", id: 2 },
        { name: "Unpaid", id: 3 },
        { name: "Voided", id: 4 },
      ],
      status: "",
      dateTypes: [
        { name: "Created Date", id: 1 },
        { name: "Invoice Date", id: 2 },
      ],
      dateType: 1,
      currencies: ["Dollar", "Naira"],
      currency: "",
      date: new Date(),
      invoiceModalData: false,
      agencyId: 0,
      locations: [],
      location: "",
      revenues: [],
      startDate: moment().startOf("day"),
      endDate: moment(),
      user: "",
      voidComment: "",
      quickSearchValue: "",
      clickedRow: false,
      selectedInvoiceNumber: null,
      shareEmail: "",
      quickSearchType: "",
      actions: [
        {
          name: "Share",
          icon: "fa-upload",
          action: this.handleShareAction,
          width: "6.5rem",
        },
        {
          name: "Print",
          icon: "fa-print",
          action: () => this.downloadInvoice(true),
          width: "6rem",
        },
        {
          name: "Mobile Print",
          icon: "fa-print",
          action: () => appHelpers.mobilePrintAction("print-panel"),
        },
        {
          name: "Download",
          icon: "fa-download",
          action: () => this.downloadInvoice(false),
          width: "8rem",
        },
        {
          name: "Pay",
          icon: "fa-money-bill-alt",
          action: () => appHelpers.redirectToPaymentGateway.call(this),
          width: "6rem",
        },
      ],
      logo: "",
      isVggRole: false,
      customerName: "",
      customerEmail: "",
      customerPhoneNumber: "",
      customerAddress: "",
      openTooltip: false,
      showTooltip: false,
      openInvSheetTooltip: false,
      invSheetTooltipTarget: false,
      invSheetToolTipPlacement: "right",
      invSheetTooltipCopyText: "",
      showInvoiceSheetTooltip: false,
      decimalPrecision: appConstants.DEFAULT_PRECISION,
      pageName: "Customer Search Invoice", //This is used in pageHelpers.js to know which search invoice view the user is on
      subRevenues: [],
      subRevenue: {},
    };

    source = axios.CancelToken.source();
  }

  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;
    if (pathState) {
      if (pathState.selectedAgencyDetails !== state.selectedAgencyDetails) {
        return {
          organizationDetails: pathState.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { organizationDetails } = this.state;

    //get route the user is coming from
    let previousPath;
    if (this.props.history.location.state) {
      previousPath = this.props.history.location.state.from;
    }

    //set Role related state
    let vggAdminRole =
      user.vggRole === appConstants.ROLES.VGG_ADMIN ||
      user.vggRole === appConstants.ROLES.VGG_USER;

    //Everytime a user with a VGG Role vists this page, the user must select an organization
    //organizationDetails===undefined prevents redirect from happening again once an organization is selected

    if (vggAdminRole && organizationDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationId: vggAdminRole
            ? organizationDetails && organizationDetails.id
            : user.organizationId,
        },
        () => this.fetchInitialReferenceData()
      );
    }
  };

  fetchInitialReferenceData = () => {
    this.getLocations();
    // this.getCustomers();
    this.getRevenues();
    const { states, apiReducer } = this.props;
    const { currencies } = apiReducer;

    if (!states) {
      this.getStates();
    } else {
      this.setState({ states: states.data });
    }

    //Redux states
    if (!currencies) {
      this.getCurrencies();
    } else {
      this.setState({ currencies });
    }
    this.getCustomers();
  };

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    this.setState({ logo: this.props.settings.setLogo });
    this.props.dispatch(commonActions.setPeriodPickerVisibility(false));

    this.routeUserBasedOnRole();
  }

  componentDidUpdate(prevProps) {
    const {
      apiReducer,
      alertComponentsReducer,
      selfServiceReducer,
    } = this.props;

    if (this.props.settings.setLogo !== prevProps.settings.setLogo) {
      this.setState({
        logo: this.props.settings.setLogo,
      });
    }

    if (this.props.states !== prevProps.states) {
      this.setState({
        states: this.props.states.data,
      });
    }

    if (apiReducer.currencies !== prevProps.apiReducer.currencies) {
      this.setState({
        currencies: apiReducer.currencies,
      });
    }

    if (
      apiReducer.revenuesByOrganization !==
      prevProps.apiReducer.revenuesByOrganization
    ) {
      this.setState({
        revenues: apiReducer.revenuesByOrganization,
      });
    }

    if (apiReducer.subRevenues !== prevProps.apiReducer.subRevenues) {
      this.setState({
        subRevenues: apiReducer.subRevenues,
      });
    }

    if (
      selfServiceReducer.selfServiceLocations !==
      prevProps.selfServiceReducer.selfServiceLocations
    ) {
      this.setState({
        locations: selfServiceReducer.selfServiceLocations,
      });
    }

    if (
      selfServiceReducer.selfServiceCustomerDetails !==
      prevProps.selfServiceReducer.selfServiceCustomerDetails
    ) {
      this.setState(
        {
          customer: selfServiceReducer.selfServiceCustomerDetails,
          customerName:
            selfServiceReducer.selfServiceCustomerDetails.customerName,
          customerEmail: selfServiceReducer.selfServiceCustomerDetails.email,
          customerPhoneNumber:
            selfServiceReducer.selfServiceCustomerDetails.phone,
          customerAddress:
            selfServiceReducer.selfServiceCustomerDetails.address,
        }
      );
    }

    if (
      alertComponentsReducer.displayCreatedInvoice.show !==
      prevProps.alertComponentsReducer.displayCreatedInvoice.show
    ) {
      if (!alertComponentsReducer.displayCreatedInvoice.show) {
        this.setState({
          clickedRow: "",
          invoiceNumber: "",
        });
      }
    }
  }

  /* API CALLS */
  getCustomers = () => {
    return this.props.dispatch(
      selfServiceActions.fetchSelfServiceCustomerDetails.call(
        this,
        source.token,
        "customerSearchInvoice"
      )
    );
  };

  getStates = () => {
    this.props.dispatch(commonActions.getAllStates(source.token));
  };

  getCurrencies = () => {
    return this.props.dispatch(apiActions.getCurrencies(source.token, true));
  };

  getLocations = () => {
    return this.props.dispatch(
      selfServiceActions.fetchSelfServiceLocations(source.token)
    );
  };

  getRevenues = () => {
    let payload = {
      organizationId: this.state.organizationId,
      isSelfService: true,
    };
    return this.props.dispatch(apiActions.getRevenues(payload, source));
  };

  advancedClicked = (type) => {
    // type 1 = advanced Search. type 2 = quick search
    this.setState({ searchType: type, quickSearchValue: null });
    if (type === 0) {
      this.resetFields();
    }
  };

  quickSearch = (quickSearchType) => {
    //the quickSearchType parameter is used to
    // determine if the user is search by invoice number or customerName
    const { quickSearchValue } = this.state;

    this.setState({ quickSearchType });
    this.setState(
      { buttonState: "active", buttonType: quickSearchType },
      () => {
        if (
          this.state.buttonType === "invoice" &&
          this.state.buttonState === "active"
        ) {
          this.setState({
            buttonColor: "info",
            invoiceBtnOutline: false,
            customerBtnOutline: true,
          });
        } else if (
          this.state.buttonType === "customer" &&
          this.state.buttonState === "active"
        ) {
          this.setState({
            buttonColor: "info",
            customerBtnOutline: false,
            invoiceBtnOutline: true,
          });
        }
      }
    );
    this.searchInvoiceNumber(0, quickSearchValue, quickSearchType);
  };

  searchInvoiceNumber = (pageNumber = 0, searchValue = null, searchType) => {
    const { dispatch, user } = this.props;
    const {
      pageSize,
      location,
      subRevenue,
      status,
      currency,
      dateType,
      invoiceNumber,
      customer,
      state,
      startDate,
      endDate,
      organizationId,
      // revenueObj,
    } = this.state;

    let payload = {
      pageSize: pageSize,
      from: pageNumber === 1 || pageNumber === 0 ? 0 : pageNumber - 1,
      parameter: {},
    };

    //If user is a vgg admin pass the selected organization Id
    // else pass the user's organization Id as agencyId
    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      payload.parameter.agencyId = organizationId;
    } else {
      payload.parameter.agencyId = user.organizationId;
    }

    if (searchType !== "invoice" && searchType !== "customer") {
      // start date, end date, dateType should not be passed on
      // invoice or customer quick search
      payload.parameter.startDate =
        moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      payload.parameter.endDate =
        endDate.format("YYYY-MM-DD") + "T23:00:00.000Z";
      payload.parameter.dateType = dateType;
    }

    //Stop endpoint from being called if search input is empty
    if (searchType === "invoice" && searchValue === "") {
      dispatch(alertActions.notify("Please input an invoice number"));
      return;
    }

    if (searchType === "customer" && searchValue === "") {
      dispatch(alertActions.notify("Please input a customer name"));
      return;
    }

    if (
      searchType === "invoice" &&
      (searchValue !== null || searchValue !== "")
    ) {
      payload.parameter.invoiceNumber = searchValue;
    } else if (invoiceNumber !== "" && searchType !== "customer") {
      payload.parameter.invoiceNumber = invoiceNumber;
    }
    if (customer !== "") {
      // payload.parameter.name = customer.customerName; // removed based on Kayode's request
      payload.parameter.providerId = customer.id;
    }

    if (location !== "") {
      payload.parameter.locationId = location;
    }

    if (subRevenue?.id !== "") {
      payload.parameter.revenueId = subRevenue.id;
      // this.setState({ decimalPrecision: revenueObj.decimalPrecision });
    }
    // else {
    //   this.setState({ decimalPrecision: appConstants.DEFAULT_PRECISION });
    // }

    if (status !== "") {
      payload.parameter.status = status;
    }

    if (currency !== "") {
      payload.parameter.currencyId = currency.id;
    }

    if (state !== "") {
      payload.parameter.state = state;
    }

    dispatch(alertActions.startRequest());
    commonService.searchInvoice(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          invoiceDetails: response.results,
          currentPage: pageNumber === 0 ? 1 : pageNumber,
          totalCount: response.totalCount,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(
          alertActions.error(response?.message ? response.message : res.response)
        );
        if (response === "Please Check your internet connectivity") {
          this.setState({
            invoiceDetails: [],
            totalCount: 0,
          });
        } else {
          this.setState({
            invoiceDetails: [],
            totalCount: 0,
          });
        }
      }
    });

    this.fetchInvoiceSearchSummary(pageNumber, searchValue, searchType);
  };

  //Download search invoice
  downloadSearchInvoice = (pageNumber = 0, type, searchValue, searchType) => {
    const { dispatch, user } = this.props;
    const {
      location,
      subRevenue,
      status,
      currency,
      dateType,
      invoiceNumber,
      customer,
      state,
      startDate,
      endDate,
      quickSearchType,
      quickSearchValue,
      organizationId,
    } = this.state;

    let payload = {
      parameter: {
        pageSize: -1,
        type: type,
      },
    };

    //If user is a vgg admin pass the selected organization Id
    // else pass the user's organization Id as agencyId
    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      payload.parameter.agencyId = organizationId;
    } else {
      payload.parameter.agencyId = user.organizationId;
    }

    if (quickSearchType === "invoice" && searchType === 0) {
      payload.parameter.invoiceNumber = quickSearchValue;
    } else if (invoiceNumber !== "") {
      payload.parameter.invoiceNumber = invoiceNumber;
    }
    if (customer !== "") {
      // payload.parameter.name = customer.customerName; // removed based on Kayode's request
      payload.parameter.providerId = customer.id;
    }

    if (quickSearchType !== "invoice" && quickSearchType !== "customer") {
      // start date, end date, dateType should not be passed on
      // invoice or customer quick search
      payload.parameter.startDate =
        moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      payload.parameter.endDate =
        endDate.format("YYYY-MM-DD") + "T23:00:00.000Z";
      payload.parameter.dateType = dateType;
    }
    if (location !== "") {
      payload.parameter.locationId = location;
    }

    if (subRevenue?.id !== "") {
      payload.parameter.revenueId = subRevenue.id;
    }

    if (status !== "") {
      payload.parameter.status = status;
    }

    if (currency !== "") {
      payload.parameter.currencyId = currency.id;
    }

    if (state !== "") {
      payload.parameter.state = state;
    }

    dispatch(alertActions.startRequest());
    commonService.downloadSearchInvoice(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.success(response.message));
        appHelpers.downloadFile(response.downloadUrl, "Invoices");
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.message));
        }
        this.setState({ requestingPayments: false });
      }
    });
  };

  //call the download endpoint and open a blank screen to display the pdf file
  printSearchInvoice = (pageNumber = 0, searchValue = null, searchType) => {
    const { dispatch, user } = this.props;
    const {
      pageSize,
      location,
      subRevenue,
      status,
      currency,
      dateType,
      invoiceNumber,
      customer,
      state,
      startDate,
      endDate,
      organizationId,
    } = this.state;

    let payload = {
      pageSize: pageSize,
      from: pageNumber === 1 || pageNumber === 0 ? 0 : pageNumber - 1,
      parameter: {
        startIndex: pageNumber,
        pageSize: pageSize,
        type: "pdf",
      },
    };

    //If user is a vgg admin pass the selected organization Id
    // else pass the user's organization Id as agencyId
    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      payload.parameter.agencyId = organizationId;
    } else {
      payload.parameter.agencyId = user.organizationId;
    }

    if (searchType === "invoice" && searchValue !== null) {
      payload.parameter.invoiceNumber = searchValue;
    } else if (invoiceNumber !== "") {
      payload.parameter.invoiceNumber = invoiceNumber;
    }
    if (customer !== "") {
      // payload.parameter.name = customer.customerName; // removed based on Kayode's request
      payload.parameter.providerId = customer.id;
    }

    if (searchType !== "invoice" && searchType !== "customer") {
      // start date, end date, dateType should not be passed on
      // invoice or customer quick search
      payload.parameter.startDate =
        moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      payload.parameter.endDate =
        endDate.format("YYYY-MM-DD") + "T23:00:00.000Z";
      payload.parameter.dateType = dateType;
    }

    if (searchType !== "invoice" && searchType !== "customer") {
      // do not pass dateType when searching by invoice or customer number
      payload.parameter.dateType = dateType;
    }

    if (location !== "") {
      payload.parameter.locationId = location;
    }

    if (subRevenue?.id !== "") {
      payload.parameter.revenueId = subRevenue.id;
    }

    if (status !== "") {
      payload.parameter.status = status;
    }

    if (currency !== "") {
      payload.parameter.currencyId = currency.id;
    }

    if (state !== "") {
      payload.parameter.state = state;
    }

    dispatch(alertActions.startRequest());

    commonService.downloadSearchInvoice(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        window.open(response.downloadUrl, "_blank");
        dispatch(alertActions.success(response.message));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.message));
        }
        this.setState({ requestingPayments: false });
      }
    });
  };

  //fetch invoice summary data
  fetchInvoiceSearchSummary = (
    pageNumber = 0,
    searchValue = null,
    searchType
  ) => {
    const { dispatch, user } = this.props;
    const {
      pageSize,
      location,
      subRevenue,
      status,
      currency,
      dateType,
      invoiceNumber,
      customer,
      state,
      startDate,
      endDate,
      organizationId,
    } = this.state;

    let payload = {
      pageSize: pageSize,
      from: pageNumber,
      parameter: {
        startIndex: pageNumber,
        pageSize: pageSize,
      },
    };

    //If user is a vgg admin pass the selected organization Id
    // else pass the user's organization Id as agencyId
    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      payload.parameter.agencyId = organizationId;
    } else {
      payload.parameter.agencyId = user.organizationId;
    }

    if (searchType === "onPageLoad") {
      // On component did mount pass start and end date parameters
      payload.parameter.startDate =
        moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      payload.parameter.endDate =
        endDate.format("YYYY-MM-DD") + "T23:00:00.000Z";
      payload.parameter.dateType = dateType;
    }
    if (searchType === "advancedSearch") {
      // On advanced search pass start and end date parameters
      payload.parameter.startDate =
        moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      payload.parameter.endDate =
        endDate.format("YYYY-MM-DD") + "T23:00:00.000Z";
      payload.parameter.dateType = dateType;
    }

    if (searchType === "invoice" && searchValue !== null) {
      //If search is by invoice number
      payload.parameter.invoiceNumber = searchValue;
    } else if (invoiceNumber !== "") {
      payload.parameter.invoiceNumber = invoiceNumber;
    }
    if (customer !== "") {
      // payload.parameter.name = customer.customerName; // removed based on Kayode's request
      payload.parameter.providerId = customer.id;
    }
    if (searchType !== "invoice" && searchType !== "customer") {
      // do not pass dateType when searching by invoice or customer number
      payload.parameter.dateType = dateType;
      payload.parameter.startDate =
        moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      payload.parameter.endDate =
        endDate.format("YYYY-MM-DD") + "T23:00:00.000Z";
    }

    if (location !== "") {
      payload.parameter.locationId = location;
    }

    if (subRevenue?.id !== "") {
      payload.parameter.revenueId = subRevenue.id;
    }

    if (status !== "") {
      payload.parameter.status = status;
    }

    if (currency !== "") {
      payload.parameter.currencyId = currency.id;
    }

    if (state !== "") {
      payload.parameter.state = state;
    }

    dispatch(alertActions.startRequest());
    commonService
      .fetchInvoiceSearchSummary(payload, source.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          // dispatch(alertActions.success())

          this.setState(
            {
              invoiceSummaryData: response.results,
            },
            () => {
              if (this.state.invoiceSummaryData.length > 0) {
                this.state.invoiceSummaryData.forEach((item) => {
                  if (item.currency === "Naira") {
                    this.setState({
                      nairaInvoicedValue: item.amount,
                      nairaPaidValue: item.paid,
                      nairaVoidedValue: item.voided,
                      nairaCountValue: item.count,
                    });
                  } else if (item.currency === "Dollar") {
                    this.setState({
                      dollarInvoicedValue: item.amount,
                      dollarPaidValue: item.paid,
                      dollarVoidedValue: item.voided,
                      dollarCountValue: item.count,
                    });
                  }
                });
              } else {
                this.setState({
                  nairaInvoicedValue: 0,
                  nairaPaidValue: 0,
                  nairaVoidedValue: 0,
                  nairaCountValue: 0,
                  dollarInvoicedValue: 0,
                  dollarPaidValue: 0,
                  dollarVoidedValue: 0,
                  dollarCountValue: 0,
                });
              }
            }
          );
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          // dispatch(alertActions.error(res.response));
        }
      });
  };

  initiateVoidRequest = (item) => {
    let { dispatch, user } = this.props;
    const { voidComment } = this.state;

    let payload = {
      invoiceNumber: item.invoiceNumber,
      reason: voidComment,
      email: user.userName,
    };

    dispatch(apiActions.initiateVoidRequest.call(this, payload, source.token));
  };

  shareInvoice = () => {
    let { dispatch } = this.props;
    let { invoiceNumber, shareEmail } = this.state;

    let payload = {
      invoiceNumber: invoiceNumber,
      recipientEmail: shareEmail,
    };

    dispatch(apiActions.shareInvoice.call(this, payload, source.token));
  };

  viewInvoice = (e, item) => {
    e.persist();

    this.setState(
      {
        invoiceModalData: item,
        clickedRow: item.invoiceNumber,
        invoiceNumber: item.invoiceNumber,
        currency: item.currencyId,
      },
      () => {
        if (e.target.nodeName !== "path") {
          this.props.dispatch(
            modalActions.displayCreatedInvoice({ show: true })
          );
        }
      }
    );
  };

  // mouseover/ mouseleave functions for table
  invoiceMouseOver = (e) => {
    let copyIcon = e.currentTarget.nextElementSibling;

    copyIcon.classList.remove("invoice-copy-icon-hide");
    copyIcon.classList.add("invoice-copy-icon-show");

    // const element = document.querySelector(`.${className} span`);
    // element.style.display = "block";
    // if (element) {
    //   this.setState({ tooltipTarget: element, showTooltip: true }, () => {
    //     this.toggleTooltip(tooltip);
    //   });
    // }
  };

  invoiceMouseOut = (e) => {
    let copyIcon = e.currentTarget.lastElementChild;
    copyIcon.classList.add("invoice-copy-icon-hide");
    copyIcon.classList.remove("invoice-copy-icon-show");

    // const element = document.querySelector(`.${className} span`);
    // element.style.display = "none";
    // this.closeTooltip(openTooltip);
  };

  toggleTooltip = (tooltip) => {
    this.setState({ [tooltip]: true });
  };

  closeTooltip = (tooltip) => {
    this.setState({
      [tooltip]: false,
      showInvoiceSheetTooltip: false,
      invSheetTooltipTarget: "",
      invSheetToolTipPlacement: "right",
      invSheetTooltipCopyText: "",
    });
  };

  //for invoice sheet
  invSheetMouseOver = (id, tooltip, placement, copyText) => {
    this.setState(
      {
        invSheetTooltipTarget: id,
        invSheetToolTipPlacement: placement,
        invSheetTooltipCopyText: copyText,
        showInvoiceSheetTooltip: true,
      },
      () => {
        this.toggleTooltip(tooltip);
      }
    );
  };

  // invSheetMouseOut = (tooltip) => {
  //   this.closeTooltip(tooltip);
  // };

  invSheetCopyIconMouseOut = (tooltip) => {
    this.closeTooltip(tooltip);
  };

  copyIconMouseover = (e, className, tooltip) => {
    const element = e.currentTarget.parentElement;

    if (element.classList.contains(className)) {
      this.setState({ tooltipTarget: element, showTooltip: true }, () => {
        this.toggleTooltip(tooltip);
      });
    }
  };

  copyIconMouseLeave = (tooltip) => {
    this.closeTooltip(tooltip);
  };

  invoiceSheetMouseOver = (e) => {
    const target = e.currentTarget.lastElementChild;
    target.classList.remove("inv-copy-wrapper-hide");
    target.classList.add("inv-copy-wrapper-show");
  };

  invoiceSheetMouseOut = (e) => {
    const target = e.currentTarget.lastElementChild;
    target.classList.add("inv-copy-wrapper-hide");
    target.classList.remove("inv-copy-wrapper-show");
  };

  invoiceSheetCopyIconMouseover = (e, tooltip, placement, copyText) => {
    let id = e.currentTarget.id;
    const element = e.currentTarget.parentElement;
    if (element.classList.contains("inv-copy-wrapper-show")) {
      this.setState(
        {
          invSheetTooltipTarget: id,
          invSheetToolTipPlacement: placement,
          invSheetTooltipCopyText: copyText,
          showInvoiceSheetTooltip: true,
        },
        () => {
          this.toggleTooltip(tooltip);
        }
      );
    }
  };

  getRevenueLocationMapping = (subRevenueId) => {
    let { dispatch, apiReducer } = this.props;

    dispatch(alertActions.startRequest());
    commonService
      .getRevenueLocationMapping(subRevenueId, source.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          //if there is a response, it means that the selected revenue is tied to a particular location, so you need to reset the locations
          //Otherwise the revenue is available to any location, so no need to reset the state - as explained by Afis
          if (response.data !== null && response.data.length > 0) {
            this.setState({
              locations: response.data,
            });
          }
          if (response.data === null) {
            this.setState({
              locations: apiReducer.selfServiceLocations,
            });
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
        }
      });
  };

  getSubRevenues = (revenueId) => {
    const { dispatch, user } = this.props;
    const { organizationId } = this.state;

    let payload = {
      revenueGroupId: revenueId,
    };

    if (
      user.vggRole === appConstants.ROLES.VGG_ADMIN ||
      user.vggRole === appConstants.ROLES.VGG_USER
    ) {
      payload.organizationId = organizationId;
    } else {
      payload.organizationId = user.organizationId;
    }

    return dispatch(apiActions.getSubRevenues(payload, source, false));
  };

  handleSelectChange = (payload, attribute) => {
    let currState = this.state;
    if (!payload) {
      currState[attribute] = "";
      if (attribute === "revenue") {
        // location and customer need to be reset once revenue is cleared
          this.setState({
            subRevenue: {},
            subRevenues: [],
            location: "",
          },
          ()=>{ this.getLocations();
          this.getCustomers();
        }
          )
      }
    } else {
      if (attribute === "location") {
        currState[attribute] = payload.id;
      } else if (attribute === "customer") {
        currState[attribute] = payload;
      } else if (attribute === "revenue") {
        currState[attribute] = payload;
        if (payload.hasSubRevenue) {
          //empty previous state, then set new state through API call
          this.setState(
            {
              subRevenue: {},
              subRevenues: [],
              location: "",
            },
            () => this.getSubRevenues(payload.id)
          );
        } else {
          this.setState({
            subRevenue: payload,
            subRevenues: this.state.revenues,
          });
          //fetch location mappings using revenueId since there are no subrevenues
          this.getRevenueLocationMapping(payload.id);
          //might have to remove this later, based on QA feedback
        }
      } else if (attribute === "subRevenue") {
        currState[attribute] = payload;
        //fetch location mappings using subRevenueId
        this.getRevenueLocationMapping(payload.id);
      } else if (attribute === "state") {
        currState[attribute] = payload.name;
      } else if (attribute === "status") {
        currState[attribute] = payload.id;
      } else if (attribute === "currency") {
        currState[attribute] = payload;
      } else if (attribute === "dateType") {
        currState[attribute] = payload.id;
      } else {
        currState[attribute] = payload.Id;
      }
    }
    this.setState({ currState });
  };
  

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  handleShareAction = () => {
    this.props.dispatch(
      modalActions.displayShareInvoiceModal({
        show: true,
      })
    );
  };

  handleShareEmailChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onkeydownEnterShareInvoice = (e) => {
    if (e.key === "Enter") {
      this.shareInvoice();
    }
  };

  printAction = () => {
    this.printSearchInvoice(0);
  };

  downloadAction = () => {
    this.downloadSearchInvoice(0, "pdf");
  };
  voidAction = () => {
    const { user, dispatch } = this.props;
    //if user has supervisor allow the user to see the void modal,
    // otherwise alert the user that he does not have a supervisor
    if (user.supervisor !== "" || user.supervisor !== null) {
      dispatch(modalActions.displayVoidRequestModal({ show: true }));
    } else {
      dispatch(modalActions.displayGeneralAlert({ show: true }));
      dispatch(modalActions.displayVoidRequestModal({ show: false }));
    }
  };

  dismissModals = () => {
    setTimeout(() => {
      this.props.dispatch(
        modalActions.displayVoidRequestModal({ show: false })
      );
      this.props.dispatch(modalActions.displayGeneralAlert({ show: false }));
    }, 5000);
  };

  //download individual invoice number
  downloadInvoice = (print) => {
    const { dispatch } = this.props;
    const { invoiceModalData } = this.state;
    let payload = { invoiceNumber: invoiceModalData.invoiceNumber };
    dispatch(apiActions.downloadSearchedInvoice(print, payload, source.token));
  };

  resetFields = () => {
    this.setState({
      dateType: 1,
      startDate: moment().startOf("day"),
      endDate: moment(),
      revenue: "",
      subRevenue: {},
      location: "",
      invoiceNumber: "",
      customer: "",
      state: "",
      status: "",
      currency: "",
    });
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(modalActions.displayCreatedInvoice({ show: false }));
    dispatch(modalActions.displayVoidRequestModal({ show: false }));
    dispatch(modalActions.displayGeneralAlert({ show: false }));

    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      currentPage,
      pageSize,
      totalCount,
      nairaInvoicedValue,
      dollarInvoicedValue,
      nairaPaidValue,
      dollarPaidValue,
      nairaVoidedValue,
      dollarVoidedValue,
      nairaCountValue,
      dollarCountValue,
      invoiceNumbers,
      invoiceNumber,
      revenue,
      subRevenue,
      subRevenues,
      customer,
      customers,
      searchType,
      buttonColor,
      invoiceBtnOutline,
      customerBtnOutline,
      states,
      state,
      statusArray,
      dateTypes,
      dateType,
      currencies,
      currency,
      startDate,
      endDate,
      locations,
      location,
      invoiceDetails,
      status,
      revenues,
      clickedRow,
      invoiceModalData,
      logo,
      quickSearchType,
      quickSearchValue,
      isVggRole,
      customerName,
      customerEmail,
      customerPhoneNumber,
      customerAddress,
      openTooltip,
      tooltipTarget,
      showTooltip,
      decimalPrecision,
    } = this.state;

    let printInvDate =
      invoiceModalData.invoiceDate !== null || undefined
        ? moment(invoiceModalData.invoiceDate).format("YYYY-MM-DD")
        : "";
    let printDate = moment().format("YYYY-MM-DD");

    const { alertComponentsReducer, user, requesting, dispatch } = this.props;
    let displayVoidRequestModal =
      alertComponentsReducer.displayVoidRequestModal;
    const shareInvoiceModal = alertComponentsReducer.shareInvoiceModal;
    const displayCreatedInvoice = alertComponentsReducer.displayCreatedInvoice;

    return (
      <ContentLayout
        requesting={requesting}
        pageTitle={"Search Invoice"}
        actions={
          <SearchInvoiceActionButtons
            isVggRole={isVggRole}
            navigateToPreviousRoute={() =>
              appHelpers.navigateToPreviousRoute.call(this)
            }
            searchType={searchType}
            advancedClicked={this.advancedClicked}
          />
        }
      >
        <div>
          {searchType === 0 && (
            <QuickSearch
              right={34}
              color={buttonColor}
              invoiceBtnOutline={invoiceBtnOutline}
              customerBtnOutline={customerBtnOutline}
              onChange={this.handleChange}
              searchCustomerName={() => this.quickSearch("customer")}
              searchInvoiceNumber={() => this.quickSearch("invoice")}
              search={() => this.searchInvoiceNumber()}
              isCustomer={true}
            />
          )}
          {searchType === 1 && (
            <AdvancedSearch
              invoiceNumbers={invoiceNumbers}
              invoiceNumber={invoiceNumber}
              selectChange={this.handleSelectChange}
              handleChange={this.handleChange}
              revenueLine={revenues}
              revenue={revenue}
              subRevenues={subRevenues}
              subRevenue={subRevenue}
              customers={customers}
              customer={customer}
              locations={locations}
              states={states}
              location={location}
              status={status}
              state={state}
              statusArray={statusArray}
              dateTypes={dateTypes}
              dateType={dateType}
              currencies={currencies}
              currency={currency}
              startDate={startDate}
              endDate={endDate}
              changeDatePicker={this.changeDatePicker}
              advancedSearch={() =>
                this.searchInvoiceNumber(0, null, "advancedSearch")
              }
              isCustomer={true}
              customerName={customerName}
              customerEmail={customerEmail}
              customerPhoneNumber={customerPhoneNumber}
              customerAddress={customerAddress}
            />
          )}
          <Summary
            marginTop={searchType === 0 ? "3rem" : "4rem"}
            invoicedTopValue={
              <h4 className={"card-summary"}>
                &#8358;{" "}
                {appHelpers.numberWithCommasOnly(
                  nairaInvoicedValue,
                  decimalPrecision
                )}
              </h4>
            }
            paidTopValue={
              <h4 className={"card-summary"}>
                &#8358;{" "}
                {appHelpers.numberWithCommasOnly(
                  nairaPaidValue,
                  decimalPrecision
                )}
              </h4>
            }
            voidedTopValue={
              <h4 className={"card-summary"}>
                &#8358;{" "}
                {appHelpers.numberWithCommasOnly(
                  nairaVoidedValue,
                  decimalPrecision
                )}
              </h4>
            }
            countTopValue={
              <h4 className={"card-summary"}>{nairaCountValue}</h4>
            }
            invoicedBottomValue={
              <h4 className={"card-summary"}>
                &#36;{" "}
                {appHelpers.numberWithCommasOnly(
                  dollarInvoicedValue,
                  decimalPrecision
                )}
              </h4>
            }
            paidBottomValue={
              <h4 className={"card-summary"}>
                &#36;{" "}
                {appHelpers.numberWithCommasOnly(
                  dollarPaidValue,
                  decimalPrecision
                )}
              </h4>
            }
            voidedBottomValue={
              <h4 className={"card-summary"}>
                &#36;{" "}
                {appHelpers.numberWithCommasOnly(
                  dollarVoidedValue,
                  decimalPrecision
                )}
              </h4>
            }
            countBottomValue={
              <h4 className={"card-summary"}>{dollarCountValue}</h4>
            }
          />
          <hr />

          {invoiceDetails.length === 0 && (
            <EmptyRecordMessage message={"No record match your search"} />
          )}
          {invoiceDetails.length > 0 && (
            <Row>
              <Col md={"12"}>
                <TableStructure
                  currentPage={currentPage}
                  fetchMoreRecords={(callBackValue) =>
                    this.searchInvoiceNumber(
                      callBackValue,
                      quickSearchValue,
                      quickSearchType
                    )
                  } //callBackValue is the parameter accepted by the callback function in handlePageChange in ServerPaginatedTable component
                  pageSize={pageSize}
                  totalCount={totalCount}
                  tableData={invoiceDetails}
                  viewInvoice={this.viewInvoice}
                  clickedRow={clickedRow}
                  searchType={searchType}
                  quickSearchType={quickSearchType}
                  downloadSearchInvoice={this.downloadSearchInvoice}
                  isCustomer={true}
                  copyInvoiceNumber={pageHelpers.copyToClipboard}
                  invoiceMouseOver={this.invoiceMouseOver}
                  invoiceMouseOut={this.invoiceMouseOut}
                  openTooltip={openTooltip}
                  tooltipTarget={tooltipTarget}
                  showTooltip={showTooltip}
                  copyIconMouseover={this.copyIconMouseover}
                  copyIconMouseLeave={this.copyIconMouseLeave}
                />
              </Col>
            </Row>
          )}
          <div id="print-panel" style={{ display: "none" }}>
            <PrintInvoice
              invoiceNumber={invoiceModalData.invoiceNumber}
              clientName={invoiceModalData.customerName}
              paymentStatus={invoiceModalData.status}
              invoiceDate={printInvDate}
              issuerName={invoiceModalData.agency}
              printDate={printDate}
              bills={invoiceModalData.bills}
              amountString={invoiceModalData.amountStringPlain}
              logo={logo}
              rrr={invoiceModalData.remitaRetrievalReference}
              currencySign={
                currency === 840 ? (
                  <span>&#36;</span>
                ) : currency === 978 ? (
                  <span>&#163;</span>
                ) : (
                  <span>&#8358;</span>
                )
              }
              decimalPrecision={invoiceModalData.decimalPrecisions}
            />
          </div>
          {displayCreatedInvoice.show && (
            <CreatedInvoiceFullScreenModal
              displayCreatedInvoice={displayCreatedInvoice}
              invoiceSheetActions={pageHelpers.getInvoiceSheetActions.call(
                this,
                invoiceModalData
              )}
              requesting={requesting}
              dispatch={dispatch}
              renderInvoiceView={pageHelpers.renderInvoiceView.call(
                this,
                invoiceModalData
              )}
            />
          )}
          {displayVoidRequestModal.show && (
            <VoidRequestFullScreenModal
              dispatch={this.props.dispatch}
              displayVoidRequestModal={displayVoidRequestModal}
              requesting={this.props.requesting}
              role={"Supervisor"}
              requestType={"Void Request"}
              name={user.supervisor}
              invoiceNumber={invoiceModalData.invoiceNumber}
              customer={invoiceModalData.customerName}
              customerRole={"Staff"}
              revenue={invoiceModalData.revenue}
              invoiceDate={moment(invoiceModalData.invoiceDate).format(
                "YYYY-MM-DD"
              )}
              amount={appHelpers.numberWithCommasOnly(invoiceModalData.amount)}
              placeholder={"Insert comment here"}
              buttonText={"Send"}
              value={this.state.value}
              onChange={this.handleChange}
              initiateRequest={() => this.initiateVoidRequest(invoiceModalData)}
              decimalPrecision={invoiceModalData.decimalPrecisions}
            />
          )}
          {this.props.displayGeneralAlert.show && <GeneralAlert />}
        </div>
        {shareInvoiceModal.show && (
          <ShareInvoiceFullScreenModal
            displayShareInvoice={shareInvoiceModal}
            dispatch={this.props.dispatch}
            handleShareEmailChange={this.handleShareEmailChange}
            shareInvoice={this.shareInvoice}
            onkeydownEnterShareInvoice={this.onkeydownEnterShareInvoice}
            isRequesting={this.props.isRequesting}
            requesting={requesting}
          />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user,
    requesting,
    settings,
    states,
    apiReducer,
    alertComponentsReducer,
    displayGeneralAlert,
    selfServiceReducer,
  } = state;
  return {
    user,
    requesting,
    settings,
    states,
    apiReducer,
    alertComponentsReducer,
    displayGeneralAlert,
    selfServiceReducer,
  };
};

export default connect(mapStateToProps)(CustomerSearchInvoice);
