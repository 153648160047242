import React from "react";
import { Table } from "reactstrap";
import "./index.scss";

const AccountsTable = ({ tableData, splitTypeId }) => {
  return (
    <Table responsive striped hover>
      <thead className={"table-head-blue"}>
        <tr>
          <th>Bank Name</th>
          <th>Account Name</th>
          <th>Account Number</th>
          <th>Split Value</th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((item, index) => {
            return (
              <tr key={index} className={"table-row"}>
                <td>{item.bankName}</td>
                <td>{item.accountNumber}</td>
                <td>{item.accountName}</td>
                <td>
                  {item.thirdPartyShare +
                    `${splitTypeId === 1 ? ` ${item.currencyName}` : "%"}`}
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default AccountsTable;
