import React from "react";
import { Table } from "reactstrap";
import "./index.scss";
import { appHelpers } from "../../_helpers";
import { FaTimes, FaCheck } from "react-icons/fa";

const CheckboxGrid = ({
  title,
  checkAll,
  checkAllBoxes,
  label,
  data,
  checkboxChange,
  displayCheckAllBoxes,
  disableAllCheckboxes,
}) => {
  return (
    <div className={"checkbox-grid"}>
      <div className={"checkbox-grid__title-container"}>
        <h4 className={"checkbox-grid__title"}>{title}</h4>
        {displayCheckAllBoxes && (
          <button className={"checkbox-grid__btn"} onClick={checkAllBoxes}>
            {checkAll ? <FaTimes /> : <FaCheck />}{" "}
            {label}
          </button>
        )}
      </div>
      <div className={"checkbox-grid__table-container"}>
        <Table
          className={"checkbox-grid__table table-main"}
          responsive={true}
          striped
        >
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            {appHelpers.tableContent(
              data,
              checkboxChange,
              disableAllCheckboxes
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default CheckboxGrid;
