export const initialState = {
  alert: {},
  modal: {},
  requesting: false,
  appRequesting: false,
  activity: {},
  // airlines: [],
  // airports: [],
  user: false,
  showPeriodPicker: false,
  showWelcomeScreen: true,
  showMiniSidebarDropdown: false,
  showRightSidebar: true,
  regions: false,
  states: false,
  organizations: false,
  entities: false,
  countries: false,
  showNotification: false,
  baseRoute: "/",
  displayLoaderFullOverlay: false,
  displayGeneralAlert: {},
  gatewayCode: null,
  userData: false,
  selectedLocationIds: false,
  savedRoutes: false,
};
