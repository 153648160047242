import React from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import SelectList from "../../../components/SelectList/selectList";


const SearchPanel = ({ firstName, lastName, email, role,
    handleChange, roles, titles, selectChange, phoneNo, title,
    componentErrorMessages, editing }) => {
    return (
        <div>
            <Form>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="invoiceNumber">Title</Label>
                            <SelectList
                                placeholder={'Select title...'}
                                data={titles}
                                valueKey={'id'}
                                textField={'name'}
                                selectValue={title}
                                onValueChange={(event) => { selectChange(event, 'title') }} />
                            {componentErrorMessages.title && <span className={"inputError"}>{componentErrorMessages.title}</span>}
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="invoiceNumber">First Name</Label>
                            <Input
                                type="text"
                                name="firstName"
                                id="firstName"
                                value={firstName}
                                onChange={handleChange}
                                defaultValue=""
                                className={"search-inputs"}
                            />
                            {componentErrorMessages.firstName && <span className={"inputError"}>{componentErrorMessages.firstName}</span>}
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="revenue"> Last Name </Label>
                            <Input
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={lastName}
                                onChange={handleChange}
                                defaultValue=""
                                className={"search-inputs"}
                            />
                        </FormGroup>
                        {componentErrorMessages.lastName && <span className={"inputError"}>{componentErrorMessages.lastName}</span>}
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="state"> Email </Label>
                            <Input
                                type="text"
                                name="email"
                                id="email"
                                value={email}
                                onChange={handleChange}
                                defaultValue=""
                                className={"search-inputs"}
                                disabled={editing}
                            />
                            {componentErrorMessages.email && <span className={"inputError"}>{componentErrorMessages.email}</span>}
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="revenue">Phone Number </Label>
                            <Input
                                type="text"
                                name="phoneNo"
                                id="phoneNo"
                                value={phoneNo}
                                onChange={handleChange}
                                defaultValue=""
                                className={"search-inputs"}
                            />
                        </FormGroup>
                        {componentErrorMessages.phoneNumber && <span className={"inputError"}>{componentErrorMessages.phoneNumber}</span>}
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="status">Role </Label>
                            <SelectList
                                placeholder={'Select role...'}
                                data={roles}
                                valueKey={'roleId'}
                                textField={'roleName'}
                                selectValue={role}
                                onValueChange={(event) => { selectChange(event, 'role') }} />
                            {componentErrorMessages.role && <span className={"inputError"}>{componentErrorMessages.role}</span>}
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default SearchPanel;

