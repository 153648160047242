import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const settlementAccountService = {
  getAllBanks,
  verifyAccount,
  getAccounts,
  searchSettlementAccount,
  createSettlementAccount,
  getSettlementAccountLocationRevenue,
  activateSettlementAccount,
  deactivateSettlementAccount
};

function getAllBanks(cancelToken) {
  return appHelpers
    .getRequest(`${appConstants.PAS_URL}/api/SettlementAccount/GetAllBanksBDA`, cancelToken)
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function verifyAccount(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SettlementAccount/VerifyAccountNumberBDA?accountNumber=${payload.accountNumber}&bankId=${payload.bankId}`, cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAccounts(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SettlementAccount/GetAllAcountTypeBDA`, cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchSettlementAccount(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SettlementAccount/SearchSettlementAccountBDA`,
      payload, cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function createSettlementAccount(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SettlementAccount/ManageSettlementAccountBDA`,
      payload, cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getSettlementAccountLocationRevenue(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/SettlementAccount/GetSettlementAccountLocationRevenueBDA?agencyId=${payload.agencyId}&settlementAccountId=${payload.settlementAccountId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function activateSettlementAccount(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SettlementAccount/ActivateSettlementAccountBDA`,
      payload
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function deactivateSettlementAccount(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/SettlementAccount/DeactivateSettlementAccountBDA`,
      payload
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

