import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "reactstrap";
import { Doughnut } from "react-chartjs-2";
import { appHelpers } from "../../_helpers/app.helpers";

import './index.css';
import Spinner from "../Spinner";


const ListColors = ["#40D999", "#F57734", "#C96CAC", "#28BAF0", "#6C77C9"];
const formatDataWithColors = (colors, data) => {
  return {
    colors,
    data
  }
}

const renderFirstListGroups = (doughnutData) => {
  let listNodes = [];
  if (doughnutData) {

    let firstPart = doughnutData.slice(0, 3);
    firstPart = formatDataWithColors(ListColors.slice(0, 3), firstPart);
    for (let i = 0; i < firstPart.data.length; i++) {
      let name = firstPart.data[i].name;
      let count = firstPart.data[i].id;
      let color = firstPart.colors[i];
      listNodes.push(
        <div key={`${count}-${i}`}>
          <ListGroupItem className="px-0" key={count}>
            <i className="fas fa-square-full mr-1" style={{ color: `${color}` }}></i>
            {name}
            <span className="float-right font-bold">{count}</span>
          </ListGroupItem>
        </div>
      );
    }
    return listNodes;

  }
}

const renderSecondListGroups = (doughnutData) => {
  let listNodes = [];
  if (doughnutData) {

    let secondPart = doughnutData.slice(3, 5);
    secondPart = formatDataWithColors(ListColors.slice(3, 5), secondPart);
    for (let i = 0; i < secondPart.data.length; i++) {
      let name = secondPart.data[i].name;
      let count = secondPart.data[i].id;
      let color = secondPart.colors[i];
      listNodes.push(
        <div key={count}>
          <ListGroupItem className="px-0" key={i}>
            <i className="fas fa-square-full mr-1" style={{ color: `${color}` }}></i>
            {name}
            <span className="float-right font-bold">{count}</span>
          </ListGroupItem>
        </div>
      );
    }
    return listNodes;

  }
}



function formatDoughnutData(apiData) {
  let labels = appHelpers.findAllByKey(apiData, "name");
  let itemCount = appHelpers.findAllByKey(apiData, "itemCount");
  let datasets = [];
  let newObj = {
    data: itemCount,
    backgroundColor: ["#40D999", "#F57734", "#C96CAC", "#28BAF0", "#6C77C9"],
    hoverBackgroundColor: [
      "#40D999",
      "#F57734",
      "#C96CAC",
      "#28BAF0",
      "#6C77C9"
    ]
  }
  datasets.push(newObj)
  return {
    labels: labels,
    datasets: datasets
  };
}

const DoughnutChartCard = ({ title, doughnutData, chartLegence, pieChartData, visible=true }) => {
   let formattedDoughnutData;
  if (doughnutData !== null) {
    formattedDoughnutData = formatDoughnutData(doughnutData);
  }
  return (
    <Card className={"pb-4"}>
      <CardBody>
        <CardTitle className="text-center pb-4">{title}</CardTitle>
        <div style={{ height: "350px",justifyContent: "center",alignItems: "center",display: "flex" }}>
           {visible === false?<div>
            <div
              className="chart-wrapper"
              style={{ width: "100%", margin: "0 auto", height: 150 }}
            >
              
              {pieChartData.length <= 0 ?
                <center><div>No Data </div></center>
                :
                <Doughnut
                  data={formattedDoughnutData}
                  options={{
                    maintainAspectRatio: false,
                    legend: {
                      display: false,
                      position: "bottom",
                      labels: { fontFamily: "Poppins" }
                    }
                  }}
                />
              }
            </div>

            <Row>
              <Col md="6">
                <ListGroup flush className="font-12">
                  {renderFirstListGroups(pieChartData)}
                </ListGroup>
              </Col>
              <Col md="6">
                <ListGroup flush className="font-12">
                  {renderSecondListGroups(pieChartData)}
                </ListGroup>
              </Col>
            </Row>
          </div>:
          <Spinner size={50} visible={true} align="center"/>

          }
        </div>

      </CardBody>
    </Card>
  );
};

export default DoughnutChartCard;
