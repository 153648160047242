import React, { Component } from "react";
import ViewBankInformation from "./view-bank-information";
import ContentLayout from "../../../components/ContentLayout";
import CheckboxGrid from "../../../components/CheckboxGrid";
import SectionTitle from "../../../components/SectionTitle";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import "./index.scss";
import { appHelpers } from "../../../_helpers";
import ButtonEdit from "../../../components/ButtonEdit";
import { FaArrowLeft } from "react-icons/fa";
import {apiActions, modalActions} from "../../../_actions";
import GeneralAlert from "../../../components/Api-response-alert";
import { appConstants } from "../../../_constants";
import SimilarRevenueAlert from "./similarRevenueAlert";
import axios from "axios";

let source;

class ReviewAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathState: {},
      revenues: [],
      locations: [],
      bankName: "",
      accountNumber: "",
      alias: "",
      accountType: "",
      currency: "",
      isActivePrimaryAccount: false,
      accountId: null,
      agencyDetails: {},
      status: false,
      accountRecords: [],
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { apiReducer, dispatch } = this.props;
    const { currencies, banks, accountTypes } = apiReducer;

    let pathState = this.props.location.state; //pathState can be coming from 1. "/settings-bank-account",  2. "/add-new-bank-account"
    if (pathState) {
      let record = pathState.record;

      this.setState({
        pathState,
        accountNumber: record.accountNumber,
        alias: record.alias,
        agencyDetails: pathState.agencyDetails,
        status: record.status,
        accountName: record.accountName,
      });

      if (record.accountId) {
        // accountID is present if it is an existing record.
        this.setState({ accountId: record.accountId });
      }

      if (record.accountType.id === 1) {
        this.setState({
          isActivePrimaryAccount: record.isActivePrimaryAccount,
        });
      }

      if (pathState.from === "/add-new-bank-account") {
        // These set of data comes as an object with key value pairs
        this.setState({
          bankName: record.bankValue,
          accountType: record.accountType,
          currency: record.currencyValue,
        });

        // if it is a secondary account and user is coming from the add new account view
        if (record.accountType.id === 2) {
          this.setState({
            revenues: record.revenues,
            locations: record.locations,
          });
        }
      }

      // if it is a secondary account and user is coming from bank account grid view ("/settings-bank-account")
      if (pathState.from === "/settings-bank-account") {
        if (pathState.record.accountType === 2) {
          // call revenuelocation endpoint to update revenues & locations in state
          let payload = {
            agencyId: pathState.record.agencyId,
            settlementAccountId: pathState.record.accountId,
          };
          dispatch(apiActions.getSettlementAccountLocationRevenue(payload, source.token));
        }
      }
    }

    // Fetch currency, bank and accountType data via redux if they are not available
    // If they are available set the corresponding value in state to the object
    // form using the appropriate appHelper function.
    if (!currencies) {
      this.getCurrency();
    } else {
      if (pathState.from === "/settings-bank-account") {
        this.setState({
          currency: appHelpers.getCurrencyValue(
            currencies,
            pathState.record.currencyValue
          ),
        });
      }
    }

    if (!banks) {
      this.fetchBanks();
    } else {
      if (pathState.from === "/settings-bank-account") {
        this.setState({
          bankName: appHelpers.getBankValue(banks, pathState.record.bankValue),
        });
      }
    }

    if (!accountTypes) {
      this.fetchAccountTypes();
    } else {
      if (pathState.from === "/settings-bank-account") {
        this.setState({
          accountType: appHelpers.getAccountType(
            accountTypes,
            pathState.record.accountType
          ),
        });
      }
    }
  }

  getCurrency = () => {
    return this.props.dispatch(apiActions.getCurrencies(source.token));
  };

  fetchBanks = () => {
    return this.props.dispatch(apiActions.fetchBanks(source.token));
  };

  fetchAccountTypes = () => {
    return this.props.dispatch(apiActions.fetchAccountTypes(source.token));
  };

  componentDidUpdate(prevProps) {
    const { apiReducer } = this.props;
    const { pathState } = this.state;
    if (apiReducer.currencies !== prevProps.apiReducer.currencies) {
      this.setState({
        currencies: apiReducer.currencies,
      });

      if (pathState) {
        if (
          pathState.action === "view" &&
          pathState.from === "/settings-bank-account"
        ) {
          let currency = appHelpers.getCurrencyValue(
            apiReducer.currencies,
            pathState.record.currencyValue
          );
          this.setState({ currency });
        }
      }
    }

    if (
      apiReducer.settlementAccountLocations !==
      prevProps.apiReducer.settlementAccountLocations
    ) {
      this.setState({
        locations: apiReducer.settlementAccountLocations,
      });
    }

    if (
      apiReducer.settlementAccountRevenues !==
      prevProps.apiReducer.settlementAccountRevenues
    ) {
      this.setState({
        revenues: apiReducer.settlementAccountRevenues,
      });
    }

    if (apiReducer.banks !== prevProps.apiReducer.banks) {
      this.setState({
        banks: apiReducer.banks,
      });
      if (pathState) {
        if (
          pathState.action === "view" &&
          pathState.from === "/settings-bank-account"
        ) {
          this.setState({
            bankName: appHelpers.getBankValue(
              apiReducer.banks,
              pathState.record.bankValue
            ),
          });
        }
      }
    }

    if (apiReducer.accountTypes !== prevProps.apiReducer.accountTypes) {
      this.setState({
        accountTypes: apiReducer.accountTypes,
      });

      if (pathState) {
        if (
          pathState.action === "view" &&
          pathState.from === "/settings-bank-account"
        ) {
          this.setState({
            accountType: appHelpers.getAccountType(
              apiReducer.accountTypes,
              pathState.record.accountType
            ),
          });
        }
      }
    }
  }

  addNewAccount = () => {
    const { dispatch,user } = this.props;
    const {
      bankName,
      accountNumber,
      alias,
      accountType,
      currency,
      isActivePrimaryAccount,
      revenues,
      locations,
      accountId,
      pathState,
      agencyDetails,
      status,
      accountName,
      accountRecords,
    } = this.state;

    let revenueIds = appHelpers.filterCheckboxId("revenue", revenues);
    let locationIds = appHelpers.filterCheckboxId("location", locations);

    let payload = {
      bankId: bankName.id,
      accountNumber: accountNumber,
      accountName: accountName,
      accountType: accountType.id,
      agencyId:
        user.vggRole === appConstants.ROLES.VGG_ADMIN
          ? agencyDetails && agencyDetails.id
          : user.organizationId,
      currencyId: currency.id,
      alias: alias,
      isActive: status,
      isPrimaryAccount: isActivePrimaryAccount,
      revenueIds: revenueIds,
      locationIds: locationIds,
      hasSimilarRevenue: accountRecords.length === 0 ? true : false, //if similar revenues exist, set the property to true, else set it to false
    };

    if (accountId) {
      payload.accountId = accountId;
    }
    dispatch(
      apiActions.createSettlementAccount(
        payload,
        source.token,
        pathState.agencyDetails,
        this.setSimilarAccountRecords
      )
    );
  };

  setSimilarAccountRecords = (response) => {
    this.setState({
      accountRecords: response.existingItems,
      message: response.message,
    });
  };

  editAccountInfo = () => {
    const { pathState } = this.state;

    const editPathState = {
      pageTitle: appConstants.EDIT_ACCOUNT,
      record: pathState.record,
      agencyDetails: pathState.agencyDetails,
      action: "edit",
      from: "/review-account-info",
    };

    appHelpers.navigateRoute.call(this, "/add-new-bank-account", editPathState);
  };

  goBack = () => {
    appHelpers.navigateToPreviousRoute.call(this);
  };

  cancel = () => {};

  hideAlertView = () => {
    const { dispatch } = this.props;

    const body = document.body;
    body.style.overflow = "scroll";
    dispatch(modalActions.displaySecondaryAlert({}));
  };

  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
  }

  render() {
    const {
      bankName,
      accountNumber,
      alias,
      accountType,
      currency,
      revenues,
      locations,
      status,
      accountName,
      accountId,
      pathState,
      accountRecords,
      message,
    } = this.state;
    const { requesting } = this.props;

    return (
      <ContentLayout
        pageTitle={"Account Information"}
        requesting={requesting}
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className=" btn-rounded mr-2 big-action-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <div className={"d-flex justify-content-end"}>
          <ButtonEdit onClick={this.editAccountInfo} />
        </div>
        <div>
          <SectionTitle title={"Bank Information"} />

          <ViewBankInformation
            bankName={bankName && bankName.name}
            accountNumber={accountNumber}
            accountName={accountName}
            alias={alias}
            accountType={accountType && accountType.name}
            currency={currency && currency.name}
            checked={status}
          />
        </div>
        <div className={"mt-4"}>
          <SectionTitle title={"Account Mapping"} />
          {accountType.name === "Primary" && (
            <div>
              <span className={"d-block"}>
                This account is a primary account and is mapped to all revenue
                lines and locations.
              </span>
            </div>
          )}
          {accountType.id === 2 && (
            <div>
              <div className={"mt-4"}>
                <CheckboxGrid title={"Location Mapping"} data={locations} />
              </div>
              <div className={"mt-5"}>
                <CheckboxGrid title={"Revenue Mapping"} data={revenues} />
              </div>
            </div>
          )}
        </div>
        {pathState.from !== "/settings-bank-account" && (
          <div className={"next-step-buttons"}>
            <button className={"ghost-button"} onClick={this.editAccountInfo}>
              Go back
            </button>
            <Button
              color="success"
              className="btn-sm btn-rounded mr-2 text-white"
              onClick={this.addNewAccount}
              disabled={requesting}
            >
              {accountId ? "Update account" : "Add new account"}
            </Button>
          </div>
        )}
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
        {this.props.alertComponentsReducer.secondaryAlert.show && (
          <SimilarRevenueAlert
            cancel={this.hideAlertView}
            addNewAccount={this.addNewAccount}
            requesting={this.props.requesting}
            accountRecords={accountRecords}
            message={message}
            hideAlertView={this.hideAlertView}
          />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    requesting,
    displayGeneralAlert,
    apiReducer,
    alertComponentsReducer,
    user
  } = state;
  return {
    requesting,
    displayGeneralAlert,
    apiReducer,
    alertComponentsReducer,
    user
  };
};

export default connect(mapStateToProps)(ReviewAccount);

// Additional Info
// This view is used for both reviewing (after an account has been created/edited)
// and viewing (when user wants to view bank account details from "/settings-bank-account" route)
// bank account details.
//From this view the user creates and edit's bank accounts.
