import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const paymentsService = {
  getPaymentDetailsByRefNo,
  getRequeryPaymentStatus,
  generateRRRForInvoice,
  validateQR,
};

function getPaymentDetailsByRefNo(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Payment/GetPaymentDetailByReferenceNoBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getRequeryPaymentStatus(payload) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Invoice/RequeryPaymentStatusBDA?invoicenumber=${payload.invoicenumber}&agencyId=${payload.agencyId}`
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function generateRRRForInvoice(payload) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Invoice/GeneratePaymentReferenceBDA?invoicenumber=${payload.invoicenumber}`
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function validateQR(payload) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Invoice/ValidateInvoiceQRBDA?inv=${payload.inv}&code=${payload.code}`
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}
