import {appConstants} from '../_constants';
import {initialState} from "../_store";

export function gatewayCode(state=initialState.gatewayCode, action) {
  switch (action.type) {
    case appConstants.GATEWAY_CODE:
      return action.gatewayCode;
    default:
      return state
  }
}
