import React, { Component } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import {
  settlementAccountService,
  settlementSplitService,
  commonService,
} from "../../../_services";
import { appConstants } from "../../../_constants";
import CheckboxGrid from "../../../components/CheckboxGrid";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { appHelpers } from "../../../_helpers";
import { alertActions } from "../../../_actions";
import ThirdPartySettlementAccountForm from "./thirdparty-settlement-account-form";
import axios from "axios";

let source;

class AddSettlementSplit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splitType: 1,
      settlementAccounts: [
        {
          bank: { id: "", name: "Select Bank" },
          currency: { id: "", name: "Select Currency" },
          accountNumber: "",
          thirdPartyShare: 0,
        },
      ],
      splitTypes: [],
      settlementAccountRevenueLinesData: [],
      selectAllRevenueLines: false,
      settlementAccountError: [
        {
          bankError: "",
          accountNumberError: "",
          currencyError: "",
          thirdPartyShareError: "",
        },
      ],
      componentErrorMessages: {},
      verifyingAccount: false,
    };

    source = axios.CancelToken.source();
  }

  async componentDidMount() {
    this.fetchBanks();
    this.getCurrency();

    await this.setAgencyBasedOnRole();
    await this.getSettlementSplitRevenue();
    this.fetchSettlementSplitTypes();
  }

  // fetchItemsFromStore = () => {
  //   const { apiReducer } = this.props;
  //   const { currencies, banks } = apiReducer;

  //   if (!currencies) {

  //   }

  //   if (!banks) {
  //   }
  // };

  // getCurrency = () => {
  //   return this.props.dispatch(apiActions.getCurrencies());
  // };

  // fetchBanks = () => {
  //   return this.props.dispatch(apiActions.fetchBanks());
  // };

  static getDerivedStateFromProps(props, state) {
    let pathState = props.location.state;

    if (pathState) {
      /**
       * @description agencyDetails is set only when the user is a VGG admin
       * pathState is obtained from /settings-settlements route
       */

      if (props.location.state.agencyDetails !== state.agencyDetails) {
        return {
          agencyDetails: props.location.state.agencyDetails,
        };
      }

      /**
       * @description settlementSplitReviewDetails is set only in `review mode`
       * This would be reached if the settlemnt split is to be edited
       * pathState is obtained from /review-settlements-split route
       */
      if (
        props.location.state.settlementSplitReviewDetails !==
        state.settlementSplitReviewDetails
      ) {
        return {
          splitType:
            props.location.state.settlementSplitReviewDetails.splitType,
          splitTypes:
            props.location.state.settlementSplitReviewDetails.splitTypes,
          settlementAccountRevenueLinesData:
            props.location.state.settlementSplitReviewDetails
              .settlementAccountRevenueLinesData,
          settlementAccounts: props.location.state.settlementSplitReviewDetails
            .settlementAccounts
            ? props.location.state.settlementSplitReviewDetails
                .settlementAccounts
            : [
                {
                  bank: { id: "", name: "Select Bank" },
                  currency: { id: "", name: "Select Currency" },
                  accountNumber: "",
                  thirdPartyShare: 0,
                },
              ],
          splitBatch:
            props.location.state.settlementSplitReviewDetails.splitBatch,
          isReview: true,
        };
      }
    }

    return null;
  }

  setAgencyBasedOnRole = () => {
    const { agencyDetails } = this.state;
    const { user } = this.props;

    this.setState({
      agencyId:
        user.vggRole === appConstants.ROLES.VGG_ADMIN //if user is a vgg admin
          ? agencyDetails.id //use the selected agency from the previous route /settings-settlements
          : user.organizationId, //else use logged in user organizationId
    });
  };

  fetchSettlementSplitTypes = () => {
    let { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    settlementSplitService.getSettlementSplitTypes(source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          splitTypes: response.data,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  fetchBanks = () => {
    let { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    settlementAccountService.getAllBanks(source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          banks: response.data,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getCurrency = () => {
    let { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    commonService.getCurrency(source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({ currencies: response.data });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getSettlementSplitRevenue = () => {
    let { dispatch } = this.props;
    let { agencyId } = this.state;

    let payload = {
      agencyId,
    };

    dispatch(alertActions.startRequest());
    settlementSplitService
      .getSettlementSplitRevenue(payload, source.token)
      .then((res) => {
        let response = res.response;
        dispatch(alertActions.stopRequest());
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({
            settlementAccountRevenueLinesData: response.data
              ? response.data
              : [],
          });
          //set initial state once data is fetched
          if (
            this.state.settlementSplitDetails ||
            this.state.settlementSplitReviewDetails
          ) {
            this.setInitialState();
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          this.setState({
            settlementAccountRevenueLinesData: [],
          });
          dispatch(alertActions.error(res.response));
        }
      });
  };

  /**
   *
   * @param {Object} payload An object of the form { accountNumber: "", bankId: 0};
   * @param {Number} index
   */
  verifyAccount = (payload, index) => {
    this.setState({
      currentIndex: index,
      verifyingAccount: true,
    });
    settlementAccountService
      .verifyAccount(payload, source.token)
      .then((res) => {
        this.setState({
          verifyingAccount: false,
        });
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          //set index of the verified account here
          this.setAccountVerificationDetails(index, response);
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          this.setAccountVerificationDetails(index, response);
        }
      });
  };

  setAccountVerificationDetails = (index, response) => {
    let { settlementAccounts } = this.state;
    settlementAccounts[index]["accountVerificationDetails"] = {
      message: response.message,
      ...response.data,
    };
    this.setState({
      settlementAccounts,
    });
  };

  reviewSettlementSplit = () => {
    const {
      splitType,
      splitTypes,
      agencyId,
      settlementAccountRevenueLinesData,
      settlementAccounts,
      componentErrorMessages,
    } = this.state;
    const { dispatch } = this.props;

    let payload = {
      splitType,
      settlementAccountRevenueLinesData,
      agencyId,
      settlementAccounts,
      splitTypes,
    };

    if (this.validateInput()) {
      this.handleChangeRoute("/review-settlement-split", "payload", payload);
    } else {
      dispatch(
        alertActions.error(
          componentErrorMessages.settlementAccountRevenueLinesError
            ? "Please select at least one revenue line"
            : "Ensure all fields are properly filled"
        )
      );
    }
  };

  /**
   * @param {String} pathname path to be routed to
   * @param {String} attribute name of the state attribute in new route
   * @param {*} value state to be passed into the new route
   */
  handleChangeRoute = (pathname, attribute, value) => {
    this.props.history.push({
      pathname,
      state: { [attribute]: value },
    });
  };

  addSettlementAccount = () => {
    this.addSettlementAccountError();
    this.setState({
      settlementAccounts: [
        ...this.state.settlementAccounts,
        {
          currency: "",
          bank: "",
          accountNumber: "",
          thirdPartyShare: "",
        },
      ],
    });
  };

  removeSettlementAccount = (index) => {
    if (this.state.settlementAccounts.length > 1) {
      this.removeSettlementAccountError();

      const settlementAccounts = this.state.settlementAccounts.filter(
        (e, idx) => idx !== index
      );
      this.setState({ settlementAccounts });
    }
  };

  handleRadioButtonChange = (event, attribute, value) => {
    this.setState({ [attribute]: value });
  };

  handleChange = (event, attribute) => {
    this.setState({ [attribute]: event.target.checked });
  };

  handleChangeAccountDetails = (event, attribute, index) => {
    const { settlementAccounts } = this.state;
    settlementAccounts[index][attribute] = event.target.value;
    this.setState({ settlementAccounts });

    //call the API only when the account number field has 10 digits
    if (attribute === "accountNumber") {
      if (
        settlementAccounts[index]["accountNumber"].length >= 10 &&
        settlementAccounts[index]["bank"].id
      ) {
        let payload = {
          accountNumber: settlementAccounts[index]["accountNumber"],
          bankId: settlementAccounts[index]["bank"].id,
        };
        this.verifyAccount(payload, index);
      }
    }
  };

  handleSelectChangeAccountDetails = (payload, attribute, index) => {
    const { settlementAccounts } = this.state;
    settlementAccounts[index][attribute] = payload;
    this.setState({ settlementAccounts });

    //call the acount verification API whan bank is selected and
    // when the account number field has 10 digits

    if (attribute === "bank") {
      if (
        settlementAccounts[index]["accountNumber"].length >= 10 &&
        settlementAccounts[index]["bank"].id
      ) {
        let payload = {
          accountNumber: settlementAccounts[index]["accountNumber"],
          bankId: settlementAccounts[index]["bank"].id,
        };
        this.verifyAccount(payload, index);
      }
    }
  };

  selectAllCheckboxes = () => {
    const { settlementAccountRevenueLinesData } = this.state;

    /**
     * @var settlementAccountRevenueLinesData carries the format below 
     *  settlementAccountRevenueLinesData: [
        { name: "Revenue 1", activated: false },
        { name: "Revenue 2", activated: false },
      ],
     */
    this.setState({ selectAllRevenueLines: !this.state.selectAllRevenueLines });

    if (this.state.selectAllRevenueLines) {
      let revenuesActivated = settlementAccountRevenueLinesData.map((item) => {
        item.activated = false;
        return item;
      });
      this.setState({ settlementAccountRevenueLinesData: revenuesActivated });
    } else {
      let revenuesActivated = settlementAccountRevenueLinesData.map((item) => {
        item.activated = true;
        return item;
      });
      this.setState({ settlementAccountRevenueLinesData: revenuesActivated });
    }
  };

  // This onChange handler, gets the checked value
  checkboxChange = (e, type) => {
    const { settlementAccountRevenueLinesData } = this.state;

    let checkedStatus = e.target.checked; //Value of the check input
    let changedCheckBox = e.target.parentNode.parentNode.textContent; //The text for the element that was checked.
    // This would be used to identify the object in the array that was checked, so as to accurately update it's activated property.

    let updatedRecord; //Variable to hold the record that was updated
    if (type === "revenueLines") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedCheckBox,
        settlementAccountRevenueLinesData
      );
      this.setState({ settlementAccountRevenueLinesData: updatedRecord });
    }
  };

  /**@description Add new error fields to settlementAccountError
   *
   **/

  addSettlementAccountError = () => {
    this.setState({
      settlementAccountError: [
        ...this.state.settlementAccountError,
        {
          bankError: "",
          accountNumberError: "",
          currencyError: "",
          thirdPartyShareError: "",
        },
      ],
    });
  };

  /**@description remove error fields to settlementAccountError
   *
   **/
  removeSettlementAccountError = (index) => {
    if (this.state.settlementAccountError.length > 1) {
      const settlementAccountError = this.state.settlementAccountError.filter(
        (e, idx) => idx !== index
      );
      this.setState({ settlementAccountError });
    }
  };

  /**
   * @description validates all inputs on the view
   */
  validateInput = () => {
    let _errs_ = {
      splitTypeError: "",
      settlementAccountError: this.state.settlementAccountError,
      settlementAccountRevenueLinesError: "",
    };

    let formValid = true;
    if (this.state.splitType === "") {
      _errs_["splitTypeError"] = "Please select a split type";
      formValid = false;
    }

    if (this.state.settlementAccountRevenueLinesData) {
      let countOfActivatedItems = 0;
      this.state.settlementAccountRevenueLinesData.forEach((item) => {
        if (item.activated) {
          countOfActivatedItems += 1;
        }
        //if at least one item is activated, break the loop. i.e do not return an error
        if (countOfActivatedItems >= 1) {
          _errs_["settlementAccountRevenueLinesError"] = "";
          formValid = true;
          return;
        }

        _errs_["settlementAccountRevenueLinesError"] =
          "Please select a revenue line";
        formValid = false;
      });
    }

    if (this.state.settlementAccounts) {
      this.state.settlementAccounts.forEach((account, i) => {
        let settlementAccountError = _errs_["settlementAccountError"];

        //if bank is empty, return an error
        if (!account.bank || account.bank.id === "") {
          settlementAccountError[i]["bankError"] = "Please select a bank";
          formValid = false;
        } else {
          settlementAccountError[i]["bankError"] = "";
        }

        //if account name is missing from the settlement accounts array,
        // return an error stating that one of the accounts still needs verification
        if (
          !account.accountVerificationDetails ||
          !account.accountVerificationDetails.accountName
        ) {
          settlementAccountError[i]["accountNumberError"] =
            "Account Number lookup failed";
          formValid = false;
        } else {
          settlementAccountError[i]["accountNumberError"] = "";
        }

        //if currency is empty, return an error
        if (!account.currency || account.currency.id === "") {
          settlementAccountError[i]["currencyError"] =
            "Please select a currency";
          formValid = false;
        } else {
          settlementAccountError[i]["currencyError"] = "";
        }

        //if third party share is less than 0, return an error
        if (account.thirdPartyShare <= 0) {
          settlementAccountError[i]["thirdPartyShareError"] =
            "Third party share must be greater than 0";
          formValid = false;
        }
        //if the split type is not a flat fee, the third party share should be 99 or below
        else if (this.state.splitType !== 1 && account.thirdPartyShare >= 100) {
          settlementAccountError[i]["thirdPartyShareError"] =
            "Third party share must not be greater than 99";
          formValid = false;
        } else {
          settlementAccountError[i]["thirdPartyShareError"] = "";
        }
      });
    }

    this.setState({
      componentErrorMessages: _errs_,
      settlementAccountError: _errs_.settlementAccountError,
    });
    return formValid;
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      splitType,
      splitTypes,
      banks,
      currencies,
      settlementAccounts,
      settlementAccountRevenueLinesData,
      selectAllRevenueLines,
      currentIndex,
      verifyingAccount,
      componentErrorMessages,
    } = this.state;
    const { requesting } = this.props;

    let accountErrorMessages = componentErrorMessages.settlementAccountError;
    return (
      <div>
        <ContentLayout
          requesting={requesting}
          pageTitle={"New Settlement Split"}
          actions={
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          }
        >
          <h2 style={{ fontWeight: "700" }}>Split Type</h2>
          <hr />

          <FormGroup tag="fieldset" className="my-5">
            {splitTypes &&
              splitTypes.map((type) => {
                return (
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        checked={splitType === type.id}
                        style={{ width: "30px" }}
                        onClick={(e) =>
                          this.handleRadioButtonChange(e, "splitType", type.id)
                        }
                      />
                      {type.name}
                    </Label>
                  </FormGroup>
                );
              })}
          </FormGroup>

          {splitTypes.length === 0 && !requesting && (
            <EmptyRecordMessage message={"No Split types found"} />
          )}

          <h2 className="mt-5" style={{ fontWeight: "700" }}>
            Revenue Lines
          </h2>
          <hr style={{ height: "12px" }}></hr>
          {settlementAccountRevenueLinesData && (
            <CheckboxGrid
              title={"Select a revenue line"}
              checkAll={selectAllRevenueLines}
              label={!selectAllRevenueLines ? "Select all" : "Deselect all"}
              displayCheckAllBoxes={
                settlementAccountRevenueLinesData.length > 0 ? true : false
              }
              checkAllBoxes={() => this.selectAllCheckboxes("revenueLines")}
              data={settlementAccountRevenueLinesData}
              checkboxChange={(e) => this.checkboxChange(e, "revenueLines")}
            />
          )}

          {settlementAccountRevenueLinesData.length === 0 && !requesting && (
            <EmptyRecordMessage
              message={"No revenue lines mapped to this organization's account"}
            />
          )}

          <h2 className="mt-5" style={{ fontWeight: "700" }}>
            Third Party Account
          </h2>
          <hr style={{ height: "12px" }}></hr>
          <ThirdPartySettlementAccountForm
            banks={banks}
            currencies={currencies}
            splitType={splitType}
            settlementAccounts={settlementAccounts}
            handleSelectChangeAccountDetails={
              this.handleSelectChangeAccountDetails
            }
            handleChangeAccountDetails={this.handleChangeAccountDetails}
            currentIndex={currentIndex}
            verifyingAccount={verifyingAccount}
            accountErrorMessages={accountErrorMessages}
            addSettlementAccount={this.addSettlementAccount}
            removeSettlementAccount={this.removeSettlementAccount}
          />

          <div className="float-right mt-5">
            <Button
              outline
              className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              Go back
            </Button>
            <Button
              color="success"
              className="btn-sm btn-rounded  mr-2 text-white"
              onClick={this.reviewSettlementSplit}
              disabled={requesting || verifyingAccount}
            >
              Proceed
            </Button>
          </div>
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, apiReducer } = state;
  return {
    user,
    requesting,
    apiReducer,
  };
};

export default connect(mapStateToProps)(AddSettlementSplit);
