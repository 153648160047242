import React, { Component } from "react";
import { Table } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "./index.scss";
// import Toggle from "react-toggle";
import {appConstants} from "../../../_constants";



const Records = ({ tableData, user, editStaff,showStaffProfile,
                    activateStaff, deactivateStaff,
                   migrateToSSO}) => {
  return (
    <Table responsive={true} className={"table-main"}>
      <thead className={"table-head"}>
        <tr>
          <th style={{ padding: "none" }}>Name</th>
          <th style={{ padding: "none" }}>Email</th>
          <th style={{ padding: "none" }}>Status</th>
          <th style={{ padding: "none" }}>SSO Activation</th>
          <th style={{ padding: "none" }}>Action</th>
          {/*<th style={{ padding: "none" }}>Action</th>*/}
          {/*{((user.userRole === appConstants.ROLES.CUSTOMER_ADMIN || user.vggRole === appConstants.ROLES.CLIENT_ADMIN) &&*/}
          
          {/*<th style={{ padding: "none"}}></th>)}*/}
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.map((item,index) => {
          return (
            <tr key={index} onClick={(e) => showStaffProfile(e, item)} className={"table-row"}>
              <th style={{ padding: "none" }}>{`${item.firstName} ${item.lastName}`}</th>
              <th style={{ padding: "none" }}>{item.email}</th>
              <th style={{ padding: "none" }}>
                {item.status === true
                  &&
                  <span className={"dark-green__100"}>Active</span>
                }
                {item.status === false
                  &&
                <span className={"dark-red__100"}>Not Active</span>
                }
              </th>
              {
                <td>{(item.isActiveSSOUser) &&
                <span className={"activated-badge"}>Activated</span>}
                  {!(item.isActiveSSOUser) &&
                  <span className={"deactivated-badge"}>Deactivated</span>
                  }
                </td>
              }
              <td style={{ padding: "none" }}>
              {((user.userRole === appConstants.ROLES.CUSTOMER_ADMIN || item.vggRole === appConstants.ROLES.CLIENT_ADMIN) &&
                <div className="dropdown">
                  <p
                    className="dropbtn"
                  >
                    <span className="threedots"/>
                  </p>
                  <ul className="dropdown-content">
                    <li onClick={(e) =>  editStaff(e, item)}>Edit</li>
                    {!item.status &&
                    <li onClick={() => activateStaff(item)}>Activate</li>
                    }
                    {
                      item.status &&
                      <li onClick={() => deactivateStaff(item)}>Deactivate</li>
                    }
                    {
                      !item.isActiveSSOUser &&
                      <li onClick={() => migrateToSSO(item)}>Migrate to SSO</li>
                    }
                  </ul>
                </div>

              // <th style={{ padding: "none" }}>
              // <span>
              //    <Badge onClick={(e) => editStaff(e, item)}
              //    className={"badge-spacing"}
              //    size="xl" pill>Edit</Badge>
              //
              //
              //     <label className={"toggle-label"}>
              //       <Toggle
              //           checked={item.status}
              //           onChange={(e) => handleSwitchChecked(e, item)} />
              //     </label>
              // </span>
              // </th>
              )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};


export default class TableStructure extends Component {
  render() {
    let {
      tableData,
      currentPage,
      fetchMoreRecords,
      pageSize,
      totalCount,
      editStaff,
      showStaffProfile,
      activateStaff,
      deactivateStaff,
      migrateToSSO,
        user
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : ((pageSize * (currentPage - 1)) + 1);
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMoreRecords}
          pageSize={pageSize}
          totalCount={totalCount}
          target={<Records
              tableData={tableData}
              pageIndex={pageIndex}
              editStaff={editStaff}
              showStaffProfile={showStaffProfile}
              user={user}
              activateStaff={activateStaff}
              deactivateStaff={deactivateStaff}
              migrateToSSO={migrateToSSO}
          />}
        />
      </div>
    );
  }
}
