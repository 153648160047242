import React from "react";
import InvoiceSheet from "../components/InvoiceSheet";
import moment from "moment";
import { appHelpers } from "./app.helpers";
import { appConstants } from "../_constants";

export const pageHelpers = {
  // getInvoiceSheetActions: function (item) {
  //   console.log(item, "myItem");
  //   const { actions } = this.state;
  //   let copiedActions = [...actions];
  //   // let relevantActions;
  //   let relevantActions = [...copiedActions];

  //   // Show the requery remita button if the invoice is unpaid
  //   // else do not display it
  //   if (
  //     this.state.pageName !== "Customer Search Invoice" &&
  //     item.status &&
  //     item.status.toLowerCase() === "unpaid"
  //   ) {
  //     copiedActions.push({
  //       name: "Re-query Payment",
  //       icon: "fa-question",
  //       action: () => this.requeryPayment(false),
  //     });
  //     relevantActions = [...copiedActions];
  //   } else if (item.status && item.status.toLowerCase() === "unpaid") {
  //     relevantActions = copiedActions.filter(
  //       (item) => item.name !== "Re-query Payment"
  //     );
  //   }

  //   return relevantActions;
  // },
  getInvoiceSheetActions: function (item) {
    console.log(item, "myItem");
    const { actions } = this.state;
    const { user } = this.props;
    let copiedActions = [...actions];
    let relevantActions = [...copiedActions];
    const invoiceCreatedByUser = user.fullName?.toLowerCase() === item.createdByName?.toLowerCase();
    const vggAdmin = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    // Show the requery remita button if the invoice is unpaid and currency is naira
    if (
      this.state.pageName !== "Customer Search Invoice" &&
      item.status &&
      item.status.toLowerCase() === "unpaid" &&
      item.currency &&
      item.currency.toLowerCase() === "naira" &&
      (!item.remitaRetrievalReference || item.remitaRetrievalReference.trim() === "")
    ) {
      // copiedActions.push({
      //   name: "Generate RRR",
      //   icon: "fa-file",
      //   action: () => this.generateRRRForInvoice(false),
      // });

      if (invoiceCreatedByUser || vggAdmin) {
        copiedActions.push({
            name: "Generate RRR",
            icon: "fa-file",
            action: () => this.generateRRRForInvoice(false),
        });
    }
      relevantActions = [...copiedActions];
    } else if (item.status && item.status.toLowerCase() === "unpaid") {
      relevantActions = copiedActions.filter(
        (action) => action.name !== "Generate RRR"
      );
    }
  
    // Show the requery remita button if the invoice is unpaid
    if (
      this.state.pageName !== "Customer Search Invoice" &&
      item.status &&
      item.status.toLowerCase() === "unpaid"
    ) {
      copiedActions.push({
        name: "Re-query Payment",
        icon: "fa-question",
        action: () => this.requeryPayment(false),
      });
      relevantActions = [...copiedActions];
    } else if (item.status && item.status.toLowerCase() === "unpaid") {
      relevantActions = copiedActions.filter(
        (action) => action.name !== "Re-query Payment"
      );
    }
  
    return relevantActions;
  },
  
  getInvoiceSheetNestedActions: function (item) {
    const { user } = this.props;
    const { invoiceSettings } = this.state;

    let result = [];
    let relevantActions = [];

    const invoiceCreatedByUser =
      user.fullName.toLowerCase() === item.createdByName.toLowerCase();

    const vggAdmin = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    const invoiceMaxAge = appHelpers.addHoursToDate(
      new Date(item.createdDate),
      invoiceSettings.invoiceAge
    ); //Maximum amount of time invoice can be edited

    const isValidEdit = new Date() < invoiceMaxAge; // tells if the invoice is still valid for editing.

    const isNotApproved = !item.isEdited; // tells if invoice edit has not been approved by the supervisor, use inv feequency instead of isEdited

    //Show the Manage Invoice Dropdown if the user is the one that created the invoice
    if (invoiceCreatedByUser) {
      relevantActions.push({
        id: 1,
        title: "Manage Invoice",
        actions: [],
      });

      // Show the Edit Invoice button if the invoice is unpaid
      //invoiceCreatedByUser && tem.status.toLowerCase() === "unpaid" && isValidEdit && isNotApproved
      if (
        invoiceCreatedByUser &&
        item.status.toLowerCase() === "unpaid" &&
        isValidEdit
      ) {
        relevantActions[0].actions.push({
          id: 1,
          name: "Edit Inovice",
          icon: "fa-pencil-alt",
          action: () => this.editinvoiceAction(item),
        });
      }
      // Show the void request action for user that created the invoice,
      // else do not display it
      if (invoiceCreatedByUser) {
        relevantActions[0].actions.push({
          name: "Request Void",
          icon: "fa-ban",
          action: this.voidAction,
        });
      }
    }

    //Show the manage Invoice Dropdown if the user is a VGG Admin
    if (
      (vggAdmin && item.status.toLowerCase() === "paid") ||
      (vggAdmin && item.status.toLowerCase() === "partial payment")
    ) {
      relevantActions.push({
        id: 1,
        title: "Manage Invoice",
        actions: [],
      });

      if (
        item.status.toLowerCase() === "paid" ||
        (item.status.toLowerCase() === "partial payment" &&
          isValidEdit)
      ) {
        relevantActions[0].actions.push({
          id: 1,
          name: "Edit Inovice",
          icon: "fa-pencil-alt",
          action: () => this.editinvoiceAction(item),
        });
      }
    }

    result = [...relevantActions];
    return result;
  },

  // for rrr generation
  getInvoiceSheetOtherActions: function (item) {
    const { actions } = this.state;
    let rrrActions = [...actions];
    // let relevantActions;
    let mainActions = [...rrrActions];

    // Show the requery remita button if the invoice is unpaid
    // else do not display it
    if (
      this.state.pageName !== "Customer Search Invoice" &&
      item.status &&
      item.status.toLowerCase() === "unpaid" &&  item.currency &&
      item.status.toLowerCase() === "naira"
    ) {
      rrrActions.push({
        name: "Generate RRR",
        icon: "fa-file-o",
        action: () => this.generateRRRForInvoices(false),
      });
      mainActions = [...rrrActions];
    } else if (item.status && item.status.toLowerCase() === "unpaid") {
      mainActions = rrrActions.filter(
        (item) => item.name !== "Generate RRR"
      );
    }

    return mainActions;
  },
  renderInvoiceView: function (item) {
    const {
      logo,
      openInvSheetTooltip,
      invSheetTooltipTarget,
      invSheetToolTipPlacement,
      invSheetTooltipCopyText,
      showInvoiceSheetTooltip,
      reasonForChange,
      pageTitle,
    } = this.state;
    const searchingByEditedInvoices =
      this.props.searchingByEditedInvoices ||
      this.state.searchingByEditedInvoices;
console.log(item.updatedFields, "updatedFields");
    return item ? (
      <InvoiceSheet
        organizationLogo={logo}
        rrrNumber={item.remitaRetrievalReference}
        companyName={item.customer}
        companyLocation={item.location}
        invoiceSheetTitle={item.revenue}
        customerName={item.customerName || item.customer}
        customerNumber={item.customerAIN}
        invoiceNumber={item.invoiceNumber}
        paymentStatus={item.status}
        customerAddress={item.customerAddress}
        revenueCode={item.revenueCode}
        invoiceDate={moment(item.invoiceDate).format("YYYY-MM-DD")}
        invoiceCreator={item.createdByName}
        invoiceItems={item.bills}
        totalAmount={item.amount}
        totalAmountInWords=""
        customerPhone={item.customerPhone}
        creditNoteAmountString={item.creditNoteAmountString}
        amountPayableString={item.amountPayableString}
        creditNoteRequestStatus={item.creditNoteRequestStatus}
        creditNoteReference={item.creditNoteReference}
        amountPayable={item.amountPayable}
        amountString={item.amountString}
        customerEmail={
          searchingByEditedInvoices
            ? item?.customerEmail
            : item?.customerEmails[0]
        }
        copyDescription={pageHelpers.copyToClipboard}
        copyAmount={pageHelpers.copyToClipboard}
        copyRRR={pageHelpers.copyToClipboard}
        copyPhone={pageHelpers.copyToClipboard}
        copyInvoiceNo={pageHelpers.copyToClipboard}
        tooltipTarget={invSheetTooltipTarget}
        openTooltip={openInvSheetTooltip}
        toolTipPlacement={invSheetToolTipPlacement}
        // mouseOver={this.invSheetMouseOver}
        copyText={invSheetTooltipCopyText}
        invSheetCopyIconMouseOut={this.invSheetCopyIconMouseOut}
        showInvoiceSheetTooltip={showInvoiceSheetTooltip}
        enableMouseOver={pageTitle !== appConstants.MANAGE_WORKFLOW_APPROVAL}
        enableMouseOut={pageTitle !== appConstants.MANAGE_WORKFLOW_APPROVAL}
        invoiceSheetMouseOver={this.invoiceSheetMouseOver}
        invoiceSheetMouseOut={this.invoiceSheetMouseOut}
        invoiceSheetCopyIconMouseover={this.invoiceSheetCopyIconMouseover}
        // toggleTooltip={this.toggleTooltip}
        currency={
          item.currencyId === 840
            ? "Dollars"
            : item.currencyId === 978
            ? "Euro"
            : "Naira"
        }
        currencySymbol={
          item.currencyId === 840 ? (
            <span>&#36;</span>
          ) : item.currencyId === 978 ? (
            <span>&#8364;</span>
          ) : (
            <span>&#8358;</span>
          )
        }
        coin={
          item.currencyId === 840
            ? "Cents"
            : item.currencyId === 978
            ? "Cents"
            : "Kobo"
        }
        decimalPrecision={item.decimalPrecisions}
        invoiceTitle={item.invoiceTitle}
        updatedFields={item.updatedFields}
        isNewInvoice={item.isNewInvoice}
        showReasonForUpdate={
          pageTitle === appConstants.EDIT_INVOICE ||
          item.invoiceTitle === "New Invoice"
        }
        reasonForUpdatingInvoice={
          pageTitle === appConstants.EDIT_INVOICE
            ? reasonForChange
            : item.editComment
        }
        revenueGroupTag={item.agencyId === 10 || item.agencyId === 3918 || item.agencyId === 5087 || item.agencyId === 19946 ? "Directorate" : null}
        revenueGroup={item.revenueGroupName}
          />
    ) : null;
  },
  // This onChange handler, gets the checked value
  checkboxChange: function (e, type) {
    const { permissions, revenues, locations } = this.state;

    let checkedStatus = e.target.checked; //Value of the check input
    let changedPermission = e.target.parentNode.parentNode.textContent; //The text for the element that was checked.
    // This would be used to identify the object in the array that was checked, so as to accurately update it's activated property.
    let updatedRecord; //Variable to hold the record that was updated

    if (type === "permissions") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        permissions
      );
      this.setState({ permissions: updatedRecord });
    }

    if (type === "revenue") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        revenues
      );
      this.setState({ revenues: updatedRecord });
    }

    if (type === "location") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        locations
      );
      this.setState({ locations: updatedRecord });
    }
  },
  selectAllCheckboxes: function (type) {
    const { revenues, locations } = this.state;
    if (type === "revenue") {
      this.setState(
        (prevState) => ({ selectAllRevenues: !prevState.selectAllRevenues }),
        () => {
          if (this.state.selectAllRevenues) {
            let revenueActivated = revenues.map((item) => {
              item.activated = true;
              return item;
            });
            this.setState({ revenues: revenueActivated });
          } else if (!this.state.selectAllRevenues) {
            let revenueActivated = revenues.map((item) => {
              item.activated = false;
              return item;
            });
            this.setState({ revenues: revenueActivated });
          }
        }
      );
    }
    if (type === "location") {
      this.setState(
        (prevState) => ({ selectAllLocations: !prevState.selectAllLocations }),
        () => {
          if (this.state.selectAllLocations) {
            let locationsActivated = locations.map((item) => {
              item.activated = true;
              return item;
            });
            this.setState({ locations: locationsActivated });
          } else if (!this.state.selectAllLocations) {
            let locationsActivated = locations.map((item) => {
              item.activated = false;
              return item;
            });
            this.setState({ locations: locationsActivated });
          }
        }
      );
    }
  },
  copyToClipboard: function (element) {
    let doc = document,
      text = doc.querySelector(`.${element}`),
      range,
      selection;

    if (doc.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(text);
      range.select();
      document.execCommand("copy");
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
    }
  },
  copyToClipboard2: function (element) {
    let pasteText = document.getElementById(element);
    pasteText.select();
    document.execCommand("copy");
  },
  findMatchingText: (editableArr) => {
    // Not needed, remove
    //This is used to find matching names between the parameters in the invoice record coming from the API and the props in the invoiceSheet component
    let modifiedArr = editableArr.map((item) => {
      let defaultValue = item;
      switch (item) {
        case "remitaRetrievalReference":
          item = "rrrNumber";
          break;
        case "customer":
          item = "companyName";
          break;
        case "location":
          item = "companyLocation";
          break;
        case "revenue":
          item = "invoiceSheetTitle";
          break;
        case "customerName":
          item = "customerName";
          break;
        case "customerAIN":
          item = "customerNumber";
          break;
        case "invoiceNumber":
          item = "invoiceNumber";
          break;
        case "status":
          item = "paymentStatus";
          break;
        case "customerAddress":
          item = "customerAddress";
          break;
        case "revenueCode":
          item = "revenueCode";
          break;
        case "invoiceDate":
          item = "invoiceDate";
          break;
        case "createdByName":
          item = "invoiceCreator";
          break;
        case "bills":
          item = "invoiceItems";
          break;
        case "ItemQuantity":
          item = "quantity";
          break;
        case "customerPhone":
          item = "customerPhone";
          break;
        case "customerEmails":
          item = "customerEmail";
          break;
        case "currencyId":
          item = "currency";
          break;
        default:
          item = defaultValue;
      }
      return item;
    });

    return modifiedArr;
  },
  highLight: (name, arr) => {
    //this is used to add the highlight class to an invoice element if it is a part of the updated invoice items
    if (arr) {
      let result;
      result = arr.find((item) => item === name);
      console.log(result,"result");
// console.log(item, "highlight");
      return result ? "highlight" : "";
    } else {
      return "";
    }
  },
  disableField: (arr, name, pageTitle) => {
    let isActive = false;
    if (arr) {
      const obj = arr.find((item) => item?.field === name);
      isActive = obj?.isActive
    }

    if (pageTitle === appConstants.EDIT_INVOICE && !isActive) {
      return true;
    }
    return false;
  },
  
};
