import React, { Component } from "react";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import InvoiceSheet from "../../../components/InvoiceSheet";
import { commonService, customerService } from "../../../_services";
import {
  alertActions,
  apiActions,
  commonActions,
  modalActions,
  selfServiceActions,
} from "../../../_actions";
import { appConstants } from "../../../_constants";
import moment from "moment";
import FullScreenModal from "../../../components/FullScreenModal";
import "../../createinvoice/createinvoice.scss";
import { appHelpers, pageHelpers } from "../../../_helpers";
import PrintInvoice from "../../../components/MobilePrint/print-invoice";
import AddNewCustomer from "../../../components/page-components/create-invoice-components/add-new-customer";
import SimilarCustomer from "../../../components/page-components/create-invoice-components/similar-customer";
import SearchPanel from "../../../components/page-components/create-invoice-components/search-panel";
import DialogFullScreenModal from "../../../components/DialogFullScreenModal";
import GeneralAlert from "../../../components/Api-response-alert";
import ShareInvoiceFullScreenModal from "../../../components/ShareInvoice/ShareInvoiceFullScreenModal";
import DisplayInvoiceFullScreenModal from "../../../components/DisplayInvoiceFullScreenModal";
import CreatedInvoiceFullScreenModal from "../../../components/CreatedInvoiceFullScreenModal";
import CreateInvoiceActionButtons from "../../../components/page-components/create-invoice-components/action-buttons";
import axios from "axios";

let source;

class CustomerCreateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revenues: [],
      subRevenues: [],
      subRevenue: "",
      customers: [],
      customersList: [],
      locations: [],
      revenueGroups: [],
      revenueGroup: "",
      revenue: "",
      revenueCode: "",
      location: "",
      customer: "",
      currency: { id: 566, name: "Naira", code: "NGN", currencySymbol: "₦" },
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      customerNumber: "",
      billDate: new Date(),
      totalAmount: 0,
      rrrNumber: "",
      showDiscardModal: false,
      showPreview: false,
      componentError: false,
      showDialog: false,
      dialogMessage: "",
      shareEmail: "",
      invoiceItems: [
        {
          description: "",
          quantity: 1,
          rate: 0,
          amount: 0,
        },
      ],
      isLiveView: false,
      componentErrorMessages: [],
      invoiceSheetActions: [
        {
          name: "Proceed",
          icon: "fa-arrow-right",
          action: () => this.createInvoice(true),
          width: "6rem",
        },
        {
          name: "Edit",
          icon: "fa-pen-square",
          action: this.editInvoice,
          width: "6rem",
        },
        {
          name: "Discard",
          icon: "fa-trash-alt",
          action: this.showDiscardModal,
          width: "6rem",
        },
      ],
      createdInvoiceActions: [
        {
          name: "Share",
          icon: "fa-upload",
          action: this.handleShareAction,
          width: "6rem",
        },
        {
          name: "Print",
          icon: "fa-print",
          action: () => this.downloadInvoice(true),
          width: "6rem",
        },
        {
          name: "Mobile Print",
          icon: "fa-print",
          action: () => appHelpers.mobilePrintAction("print-panel"),
        },
        {
          name: "Download",
          icon: "fa-download",
          action: () => this.downloadInvoice(),
        },
        {
          name: "Pay",
          icon: "fa-money-bill-alt",
          action: () => this.pay(),
          width: "6rem",
        },
      ],
      logo: "",
      invoiceNumber: "",
      newCustomerName: "",
      newContactFirstName: "",
      newContactLastName: "",
      newCustomerEmail: "",
      newCustomerPhoneNo: "",
      newCustomerAddress: "",
      showAddNewCustomer: false,
      showSimilarCustomer: false,
      openInvSheetTooltip: false,
      invSheetTooltipTarget: false,
      invSheetToolTipPlacement: "right",
      invSheetTooltipCopyText: "",
      showInvoiceSheetTooltip: false,
      decimalPrecision: appConstants.DEFAULT_PRECISION,
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    this.routeUserBasedOnRole();
    const body = document.body;
    body.style.overflow = "scroll";
  }

  fetchInitialReferenceData = () => {
    const { apiReducer } = this.props;
    const { currencies } = apiReducer;

    this.setState({ logo: this.props.settings.setLogo });
    this.props.dispatch(commonActions.setPeriodPickerVisibility(false));
    this.getCustomers();
    this.getSettingsByKey();
    this.getRevenueGroupSettings();
    this.getRevenues();
    this.getLocations();
    this.getCustomers();

    //Redux states
    if (!currencies) {
      this.getCurrencies();
    } else {
      this.setState({ currencies });
    }
  };

  componentDidUpdate(prevProps) {
    const {
      alertComponentsReducer,
      apiReducer,
      selfServiceReducer,
    } = this.props;

    if (this.props.settings.setLogo !== prevProps.settings.setLogo) {
      this.setState({
        logo: this.props.settings.setLogo,
      });
    }

    if (this.props.displayGeneralAlert !== prevProps.displayGeneralAlert) {
      if (this.props.displayGeneralAlert.performAction) {
        this.displayCreatedInvoiceSheet();
      }
    }

    if (
      alertComponentsReducer.displayCreatedInvoice !==
      prevProps.alertComponentsReducer.displayCreatedInvoice
    ) {
      if (!alertComponentsReducer.displayCreatedInvoice.show) {
        this.clearInputFields();
      }
    }

    if (apiReducer.currencies !== prevProps.apiReducer.currencies) {
      this.setState({
        currencies: apiReducer.currencies,
      });
    }

    // if (
    //   selfServiceReducer.selfServiceRevenues !==
    //   prevProps.selfServiceReducer.selfServiceRevenues
    // ) {
    //   this.setState({
    //     revenues: selfServiceReducer.selfServiceRevenues,
    //   });
    // }
    if (
      apiReducer.revenuesByOrganization !==
      prevProps.apiReducer.revenuesByOrganization
    ) {
      this.setState({
        revenues: apiReducer.revenuesByOrganization,
      });
    }

    if (apiReducer.subRevenues !== prevProps.apiReducer.subRevenues) {
      this.setState({
        subRevenues: apiReducer.subRevenues,
      });
    }

    if (
      selfServiceReducer.selfServiceLocations !==
      prevProps.selfServiceReducer.selfServiceLocations
    ) {
      this.setState({
        locations: selfServiceReducer.selfServiceLocations,
      });
    }

    if (
      selfServiceReducer.selfServiceCustomerDetails !==
      prevProps.selfServiceReducer.selfServiceCustomerDetails
    ) {
      this.setState({
        customer: selfServiceReducer.selfServiceCustomerDetails,
        name: selfServiceReducer.selfServiceCustomerDetails.customerName,
        email: selfServiceReducer.selfServiceCustomerDetails.email,
        phoneNumber: selfServiceReducer.selfServiceCustomerDetails.phone,
        address: selfServiceReducer.selfServiceCustomerDetails.address,
      });
    }
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   */
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;
    if (pathState) {
      if (pathState.selectedAgencyDetails !== state.selectedAgencyDetails) {
        return {
          organizationDetails: pathState.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  /**
   * @description Route the user based on the role of the logged in user
   */
  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { organizationDetails } = this.state;

    //get route the user is coming from
    let previousPath;
    if (this.props.history.location.state) {
      previousPath = this.props.history.location.state.from;
    }

    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    //Everytime a user with a VGG Role vists this page, the user must select an organization
    //organizationDetails===undefined prevents redirect from happening again once an organization is selected

    if (vggAdminRole && organizationDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationId: vggAdminRole
            ? organizationDetails && organizationDetails.id
            : user.organizationId,
        },
        () => this.fetchInitialReferenceData()
      );
    }
  };

  /* API CALLS */
  // getRevenues = () => {
  //   return this.props.dispatch(
  //     selfServiceActions.fetchSelfServiceRevenues(source.token)
  //   );
  // };

  getRevenues = () => {
    let payload = {
      organizationId: this.state.organizationId,
      isSelfService: true,
    };
    return this.props.dispatch(apiActions.getRevenues(payload, source, true));
  };

  getSubRevenues = (revenueId) => {
    let payload = {
      organizationId: this.state.organizationId,
      revenueGroupId: revenueId,
    };
    return this.props.dispatch(apiActions.getSubRevenues(payload, source));
  };

  getLocations = () => {
    return this.props.dispatch(
      selfServiceActions.fetchSelfServiceLocations(source.token)
    );
  };

  getCustomers = () => {
    return this.props.dispatch(
      selfServiceActions.fetchSelfServiceCustomerDetails()
    );
  };

  getCurrencies = () => {
    return this.props.dispatch(apiActions.getCurrencies(source.token, true));
  };

  getSettingsByKey = () => {
    let { dispatch } = this.props;
    let key = "CompanyRepresentative";
    dispatch(alertActions.startRequest());
    commonService.getSettingsByKey(key, source.token).then((res) => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          companyRepId: response.data.value,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getRevenueGroupSettings() {
    //TODO: change this implementation once Kayode provides the settings API
    const { organizationId } = this.state;

    this.setState(
      {
        //revenueGroupTag should be set to the API response
        //it is currently hardcoded to cater for NCAA directorate request
        revenueGroupTag: organizationId === 10 ? "Directorate" : null,
      },
      () => {
        if (this.state.revenueGroupTag) {
          this.getRevenueGroups();
        }
      }
    );
  }

  getRevenueGroups = () => {
    let { dispatch } = this.props;
    let { organizationId } = this.state;

    let payload = {
      agencyId: organizationId,
    };
    dispatch(alertActions.startRequest());
    commonService.getRevenueGroups(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          revenueGroups: response.data,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  createInvoice = (msg) => {
    const { dispatch, user } = this.props;
    const {
      billDate,
      subRevenue,
      customer,
      currency,
      location,
      name,
      email,
      phoneNumber,
      address,
      rrrNumber,
      invoiceItems,
      revenueGroup,
      organizationId,
    } = this.state;

    let payload = {
      invoiceDate: moment(billDate).format("YYYY-MM-DD"),
      revenueId: subRevenue.id,
      customerAIN: customer.customerAIN,
      currencyId: currency.id,
      locationId: location.id,
      name: name,
      emails: email,
      phone: phoneNumber,
      address: address,
      bills: invoiceItems,
      RevenueGroupId: revenueGroup.id ? revenueGroup.id : 0,
      rrr: rrrNumber,
      enableCredit: false,
      userId: user.userId,
      checkSimilarInvoice: msg,
      agencyId: organizationId,
    };

    dispatch(alertActions.startRequest());
    commonService.createInvoice(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "success",
          })
        );
        dispatch(modalActions.displayInvoiceSheet({ show: false }));
        dispatch(modalActions.displayCreatedInvoice({ show: true }));
        this.setState({ invoiceNumber: response.id }, () => {
          this.downloadInvoice();
          if (this.props.displayGeneralAlert.show) {
            this.dismissModals();
          }
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        appHelpers.enableScroll();
        if (response.message && response.message.includes("similar invoice")) {
          dispatch(
            modalActions.displayDialogAlert({
              show: true,
              message: response.message,
              declineText: "No",
              approveText: "Yes create invoice",
            })
          );
          this.setState({ showDialog: true, dialogMessage: response.message });
        } else {
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "failure",
              performAction: true,
            })
          );
          dispatch(modalActions.displayInvoiceSheet({ show: false }));
          dispatch(
            modalActions.displayCreatedInvoice({ showCreatedInvoice: false })
          );
          this.dismissModals();
        }
      }
    });
  };

  shareInvoice = () => {
    let { dispatch } = this.props;
    let { invoiceNumber, shareEmail } = this.state;

    let payload = {
      invoiceNumber: invoiceNumber,
      recipientEmail: shareEmail,
    };

    dispatch(apiActions.shareInvoice.call(this, payload, source.token));
  };

  // download invoice
  downloadInvoice = (print) => {
    const { dispatch } = this.props;
    const { invoiceNumber } = this.state;

    let payload = { invoiceNumber: invoiceNumber };
    dispatch(apiActions.downloadSearchedInvoice(print, payload, source.token));
  };

  pay = () => {
    const { invoiceNumber } = this.state;
    appHelpers.navigateRoute.call(this, "/payment", invoiceNumber);
  };

  /* Handlers */

  handleSelectChange = (selected, attribute) => {
    let errorMessages = this.state.componentErrorMessages;
    errorMessages[attribute] = false;
    if (!selected) {
      this.setState({ [attribute]: "" }, () => {
        if (this.state.customer === "") {
          this.setState({
            name: "",
            email: "",
            phoneNumber: "",
            address: "",
          });
        }
      });
    } else {
      if (attribute === "revenue") {
        this.setState(
          {
            [attribute]: selected,
            subRevenue: "",
            subRevenues: [],
            location: "",
          },
          () => {
            if (selected.hasSubRevenue) {
              this.getSubRevenues(selected.id);
            }
            if (!selected.hasSubRevenue) {
              this.setState({ subRevenues: [selected] });
            }
          }
        );
      } else if (attribute === "subRevenue") {
        let newRate = selected.rate === null ? 0 : selected.rate;
        let updatedInvoiceItemsRate = this.state.invoiceItems.map((item) => {
          item.rate = newRate;
          item.amount = item.quantity * newRate;
          return item;
        });
        this.setState({
          [attribute]: selected,
          invoiceItems: updatedInvoiceItemsRate,
          decimalPrecision: selected.decimalPrecision,
        });
      } else if (attribute === "location") {
        this.setState({ [attribute]: selected });
      } else if (attribute === "currency") {
        this.setState({ [attribute]: selected });
      } else if (attribute === "revenueGroup") {
        this.setState({ [attribute]: selected });
      } else {
        this.setState({ [attribute]: selected.Id });
      }
    }
    this.setState({ componentErrorMessage: errorMessages });
  };

  addInvoiceItemFields = () => {
    let newRate =
      this.state.subRevenue.rate === null ? 0 : this.state.subRevenue.rate;

    this.setState({
      invoiceItems: [
        ...this.state.invoiceItems,
        {
          description: "",
          quantity: 1,
          rate: newRate,
          amount: 1 * newRate,
        },
      ],
    });
  };

  removeInvoiceItem = (index) => (event) => {
    const invoiceItems = this.state.invoiceItems.filter(
      (e, idx) => idx !== index
    );

    let totalAmount = invoiceItems.reduce(this.addItemsAmount, 0);
    this.setState({ invoiceItems, totalAmount });
  };

  validateInput = () => {
    const { invoiceItems } = this.state;
    let _errs_ = [];
    let formValid = true;
    if (this.state.revenue === "") {
      _errs_["revenue"] = "Please select revenue";
      formValid = false;
    }
    if (this.state.subRevenue === "") {
      _errs_["subRevenue"] = "Please select sub revenue";
      formValid = false;
    }
    if (this.state.name === "") {
      _errs_["name"] = "Please enter a name";
      formValid = false;
    }
    if (this.state.email === "") {
      _errs_["email"] = "Please enter an email address";
      formValid = false;
    }
    if (this.state.phoneNumber === "") {
      _errs_["phoneNumber"] = "Please enter a phone number";
      formValid = false;
    }
    if (this.state.location === "") {
      _errs_["location"] = "Please select location";
      formValid = false;
    }
    if (this.state.address === "") {
      _errs_["address"] = "Please enter an address";
      formValid = false;
    }
    if (this.state.currency === "") {
      _errs_["currency"] = "Please select currency";
      formValid = false;
    }
    if (this.state.revenueGroup === "" && this.state.revenueGroupTag) {
      _errs_["revenueGroup"] = `Please select ${this.state.revenueGroupTag}`;
      formValid = false;
    }
    for (let i = 0; i < invoiceItems.length; i++) {
      if (this.state.invoiceItems[i].description === "") {
        _errs_["description"] = "Please add an item description";
        formValid = false;
      }
      if (
        this.state.invoiceItems[i].quantity === "" ||
        this.state.invoiceItems[i].quantity === 0
      ) {
        _errs_["quantity"] = "Please add a quantity";
        formValid = false;
      }
      if (
        this.state.invoiceItems[i].rate === "" ||
        this.state.invoiceItems[i].rate === 0
      ) {
        _errs_["rate"] = "Please add a rate";
        formValid = false;
      }
      if (this.state.invoiceItems[i].quantity < 0) {
        _errs_["quantity"] = "Quantity cannot be less than 1";
        formValid = false;
      }
      if (this.state.invoiceItems[i].rate < 0) {
        _errs_["rate"] = "Rate cannot be less than zero";
        formValid = false;
      }
    }

    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  showDiscardModal = () => {
    appHelpers.enableScroll();
    this.props.dispatch(
      modalActions.displayDialogAlert({
        show: true,
        approveText: "Yes",
        declineText: "No",
        message: "Are you sure you want to cancel this invoice",
      })
    );
    this.setState({ showDiscardModal: true });
  };

  handleItemChange = (index) => (event) => {
    const newInvoiceItems = this.state.invoiceItems.map((item, key) => {
      if (index !== key) return item;

      let updatedItem = { ...item, [event.target.name]: event.target.value };

      if (updatedItem.quantity < 0) {
        this.props.dispatch(
          alertActions.notify("Quantity cannot be less than 1")
        );
      }

      if (updatedItem.quantity !== "" && updatedItem.rate !== "") {
        updatedItem.amount = updatedItem.quantity * updatedItem.rate;
      } else {
        updatedItem.amount = 0;
      }
      return { ...updatedItem };
    });

    let totalAmount = newInvoiceItems.reduce(this.addItemsAmount, 0);
    this.setState({ invoiceItems: newInvoiceItems, totalAmount: totalAmount });
  };

  handleFocus = (index) => (event) => {
    let { invoiceItems } = this.state;

    const focusedInvoiceItems = invoiceItems.map((item, key) => {
      if (index !== key) return item;
      let focusedItem;
      if (event.target.value === "0") {
        focusedItem = { ...item, [event.target.name]: "" };
      } else {
        focusedItem = { ...item };
      }

      return focusedItem;
    });

    this.setState({ invoiceItems: focusedInvoiceItems });
  };

  handleBlur = (index) => (event) => {
    let { invoiceItems } = this.state;

    const bluredInvoiceItems = invoiceItems.map((item, key) => {
      if (index !== key) return item;
      let bluredItem;
      if (event.target.value === "") {
        bluredItem = { ...item, [event.target.name]: 0 };
      } else {
        bluredItem = { ...item };
      }

      return bluredItem;
    });

    this.setState({ invoiceItems: bluredInvoiceItems });
  };

  addItemsAmount = (total, item) => {
    return total + item.amount;
  };

  handleChange = (event, attribute) => {
    let currState = this.state;
    currState[attribute] = event.target.value;
    let errorMessages = this.state.componentErrorMessages;
    errorMessages[attribute] = false;
    this.setState(currState);
    this.setState({ componentErrorMessages: errorMessages });
  };

  handleShareAction = () => {
    this.props.dispatch(
      modalActions.displayShareInvoiceModal({
        show: true,
      })
    );
  };

  handleShareEmailChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onkeydownEnterShareInvoice = (e) => {
    if (e.key === "Enter") {
      this.shareInvoice();
    }
  };

  changeRRR = (event) => {
    this.setState({ rrrNumber: event.target.value });
  };
  changeDatePicker = (date) => {
    this.setState({ billDate: date });
  };

  toggleLiveView = () => {
    this.setState({ isLiveView: !this.state.isLiveView });
  };

  isShowPreview = () => {
    this.setState({ showPreview: true });
  };

  editInvoice = () => {
    appHelpers.enableScroll();
    this.props.dispatch(modalActions.displayInvoiceSheet({ show: false }));
  };

  addCustomer = () => {
    const { dispatch, user } = this.props;
    const {
      newCustomerName,
      newContactFirstName,
      newContactLastName,
      newCustomerEmail,
      newCustomerPhoneNo,
      newCustomerAddress,
      companyRepId,
    } = this.state;

    let payload = {
      id: 0,
      name: newCustomerName,
      category: 1,
      type: 1,
      address: newCustomerAddress,
      rcNumber: "",
      incorpDate: "",
      debtRecovery: 0,
      accountCode: "",
      agencyId:
        user.vggRole === appConstants.ROLES.VGG_ADMIN
          ? user.organizationId
          : undefined,
      fromCreateInvoice: true,
      customerStaff: [
        {
          titleId: companyRepId,
          firstName: newContactFirstName,
          lastName: newContactLastName,
          email: newCustomerEmail,
          phoneNumber: newCustomerPhoneNo,
        },
      ],
    };

    dispatch(alertActions.startRequest());
    customerService.addCustomer(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.getCustomers();
        this.hideSimilarCustomer();
        this.hideAddNewCustomer();
        this.setState({
          customer: {
            id: response.id.id,
            customerName: response.id.name,
            customerAIN: response.id.customerAIN,
          },
          name: response.id.name,
          email: response.id.email,
          phoneNumber: response.id.phoneNumber,
          address: response.id.address,
        });
        dispatch(alertActions.success(response.message));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(response.message));
        this.setState({
          customer: "",
          name: "",
          email: "",
          phoneNumber: "",
          customerAIN: "",
          address: "",
        });
      }
    });
  };

  similarCustomerCheck = () => {
    const { dispatch } = this.props;
    const { newCustomerName } = this.state;

    let payload = {
      customerName: newCustomerName,
    };

    dispatch(alertActions.startRequest());
    customerService.similarCustomerCheck(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          similarCustomersList: response.data,
        });
        if (response.data) {
          this.showSimilarCustomer();
        } else {
          this.addCustomer();
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
      }
    });
  };

  resetCreateCustomerForm = () => {
    this.setState({
      newCustomerName: "",
      newContactFirstName: "",
      newContactLastName: "",
      newCustomerEmail: "",
      newCustomerPhoneNo: "",
      newCustomerAddress: "",
      cancelPressed: false,
    });
  };

  renderInvoiceView = () => {
    const { user } = this.props;
    const {
      rrrNumber,
      invoiceItems,
      invoiceNumber,
      billDate,
      totalAmount,
      address,
      subRevenue,
      revenueGroupTag,
      revenueGroup,
      location,
      customer,
      logo,
      name,
      phoneNumber,
      email,
      currency,
      openInvSheetTooltip,
      invSheetTooltipTarget,
      invSheetToolTipPlacement,
      invSheetTooltipCopyText,
      showInvoiceSheetTooltip,
      isLiveView,
      decimalPrecision,
    } = this.state;

    return (
      <InvoiceSheet
        organizationLogo={logo}
        invoiceNumber={invoiceNumber}
        rrrNumber={rrrNumber}
        companyName={user.organization}
        companyLocation={location.address}
        invoiceSheetTitle={subRevenue.name}
        customerName={name}
        customerNumber={customer.customerAIN}
        revenueCode={subRevenue.code}
        revenueGroupTag={revenueGroupTag}
        revenueGroup={revenueGroup.name}
        invoiceDate={billDate}
        customerAddress={address}
        invoiceItems={invoiceItems}
        totalAmount={totalAmount}
        totalAmountInWords=""
        invoiceCreator={user.fullName}
        customerPhone={phoneNumber}
        customerEmail={email}
        currency={currency.name}
        currencySymbol={currency.currencySymbol}
        coin={
          currency.id === 840 ? "Cents" : currency.id === 978 ? "Cents" : "Kobo"
        }
        copyDescription={pageHelpers.copyToClipboard}
        copyAmount={pageHelpers.copyToClipboard}
        copyRRR={pageHelpers.copyToClipboard}
        copyPhone={pageHelpers.copyToClipboard}
        copyInvoiceNo={pageHelpers.copyToClipboard}
        tooltipTarget={invSheetTooltipTarget}
        openTooltip={openInvSheetTooltip}
        toolTipPlacement={invSheetToolTipPlacement}
        copyText={invSheetTooltipCopyText}
        showInvoiceSheetTooltip={showInvoiceSheetTooltip}
        // mouseOut={this.invSheetMouseOut}
        // mouseOver={this.invSheetMouseOver}
        invSheetCopyIconMouseOut={this.invSheetCopyIconMouseOut}
        invoiceSheetMouseOver={this.invoiceSheetMouseOver}
        invoiceSheetMouseOut={this.invoiceSheetMouseOut}
        invoiceSheetCopyIconMouseover={this.invoiceSheetCopyIconMouseover}
        isLiveView={isLiveView}
        decimalPrecision={decimalPrecision}
      />
    );
  };

  renderPreviewView = (showPreview) => {
    let prevElem = [];
    let count = 0;
    const { requesting } = this.props;
    if (showPreview) {
      prevElem.push(
        <FullScreenModal
          show={showPreview}
          key={count + 1}
          callback={this.hidePreviewView}
          zIndex={requesting ? 10 : 999}
        >
          {this.renderInvoiceView()}
        </FullScreenModal>
      );
      return prevElem;
    }
  };

  /**
   * @description Create customer on customer dropdown
   */
  renderAddNewCustomer = () => {
    let nodeElems = [];
    let count = 0;
    const {
      showAddNewCustomer,
      newCustomerName,
      newContactFirstName,
      newContactLastName,
      newCustomerEmail,
      newCustomerPhoneNo,
      newCustomerAddress,
      similarCustomersList,
    } = this.state;

    nodeElems.push(
      <FullScreenModal
        show={showAddNewCustomer}
        key={count + 1}
        callback={this.hideAddNewCustomer}
        zIndex={10}
        width={"35%"}
      >
        <AddNewCustomer
          newCustomerName={newCustomerName}
          newContactFirstName={newContactFirstName}
          newContactLastName={newContactLastName}
          newCustomerEmail={newCustomerEmail}
          newCustomerPhoneNo={newCustomerPhoneNo}
          newCustomerAddress={newCustomerAddress}
          handleChange={this.handleChange}
          closeAddNewCustomerModal={this.hideAddNewCustomer}
          similarCustomerCheck={this.similarCustomerCheck}
          similarCustomersList={similarCustomersList}
        />
      </FullScreenModal>
    );
    return nodeElems;
  };

  renderSimilarCustomer = () => {
    let nodeElems = [];
    let count = 0;
    const { requesting } = this.props;
    const { showSimilarCustomer, similarCustomersList } = this.state;

    nodeElems.push(
      <FullScreenModal
        show={showSimilarCustomer}
        key={count + 1}
        callback={this.hideSimilarCustomer}
        zIndex={20}
        width={"35%"}
      >
        <SimilarCustomer
          requesting={requesting}
          addCustomer={this.addCustomer}
          closeSimilarCustomerModal={this.hideSimilarCustomer}
          selectCustomer={this.selectCustomer}
          similarData={similarCustomersList}
        />
      </FullScreenModal>
    );
    return nodeElems;
  };

  declineCancelInvoice = () => {
    const { dispatch } = this.props;
    appHelpers.enableScroll();
    dispatch(modalActions.displayDialogAlert({ show: false }));
    this.setState({ showDiscardModal: false });
  };

  discardInvoice = () => {
    appHelpers.enableScroll();
    const { dispatch } = this.props;
    dispatch(modalActions.displayDialogAlert({ show: false }));
    dispatch(modalActions.displayInvoiceSheet({ show: false }));
    this.setState({
      rrrNumber: "",
      name: "",
      invoiceNumber: "",
      billDate: new Date(),
      address: "",
      invoiceItems: [
        {
          description: "",
          quantity: 1,
          rate: 0,
          amount: 0,
        },
      ],
      totalAmount: 0,
      customer: "",
      location: "",
      phoneNumber: "",
      revenue: "",
      email: "",
      currency: { id: 566, name: "Naira", code: "NGN", currencySymbol: "₦" },
    });
  };

  //clear input fields on close of created invoice
  clearInputFields = () => {
    this.setState({
      revenue: "",
      subRevenue: "",
      location: "",
      currency: { id: 566, name: "Naira", code: "NGN", currencySymbol: "₦" },
      rrr: "",
      totalAmount: 0,
      invoiceNumber: "",
      invoiceItems: [
        {
          description: "",
          quantity: 1,
          rate: 0,
          amount: 0,
        },
      ],
      revenueGroup: "",
    });
  };

  selectCustomer = (item) => {
    this.setState({
      customer: item,
      name: item.customerName,
      email: item.email,
      phoneNumber: item.phone,
      customerAIN: item.customerAIN,
      address: item.address,
    });
    this.hideSimilarCustomer();
    this.hideAddNewCustomer();
  };

  /* Modal Callbacks */
  cancelDialog = () => {
    const { dispatch } = this.props;

    appHelpers.enableScroll();
    dispatch(
      modalActions.displayDialogAlert({
        show: false,
        message: "",
        declineText: "",
        approveText: "",
      })
    );
    this.setState({ showDialog: false });
  };

  approveDialog = () => {
    const { dispatch } = this.props;

    this.createInvoice(false);
    dispatch(
      modalActions.displayDialogAlert({
        show: false,
        message: "",
        declineText: "",
        approveText: "",
      })
    );
    this.setState({ showDialog: false });
  };

  displayCreatedInvoiceSheet = () => {
    const { displayGeneralAlert, dispatch } = this.props;

    if (displayGeneralAlert.message) {
      if (displayGeneralAlert.message.includes("successfully")) {
        dispatch(modalActions.displayInvoiceSheet({ show: false }));
        dispatch(modalActions.displayCreatedInvoice({ show: true }));
      }
    }
  };

  showInvoiceSheet = () => {
    if (this.validateInput()) {
      this.props.dispatch(modalActions.displayInvoiceSheet({ show: true }));
    }
  };

  hidePreviewView = () => {
    this.setState({ showPreview: false });
  };

  openAddNewCustomerModal = () => {
    appHelpers.enableScroll();
    this.setState({ showAddNewCustomer: true });
  };

  hideAddNewCustomer = () => {
    appHelpers.enableScroll();
    this.setState({ showAddNewCustomer: false });
    this.resetCreateCustomerForm();
  };

  showSimilarCustomer = () => {
    appHelpers.enableScroll();
    this.setState({ showSimilarCustomer: true });
  };

  hideSimilarCustomer = () => {
    appHelpers.enableScroll();
    this.setState({ showSimilarCustomer: false });
  };

  dismissModals = () => {
    appHelpers.enableScroll();
    setTimeout(() => {
      this.props.dispatch(modalActions.displayGeneralAlert({ show: false }));
      this.props.dispatch(modalActions.displayInvoiceSheet({ show: false }));
      this.setState({
        showPreview: false,
      });
    }, 5000);
  };

  //tooltips
  invoiceSheetMouseOver = (e) => {
    const target = e.currentTarget.lastElementChild;
    target.classList.remove("inv-copy-wrapper-hide");
    target.classList.add("inv-copy-wrapper-show");
  };

  invoiceSheetMouseOut = (e) => {
    const target = e.currentTarget.lastElementChild;
    target.classList.add("inv-copy-wrapper-hide");
    target.classList.remove("inv-copy-wrapper-show");
  };

  invoiceSheetCopyIconMouseover = (e, tooltip, placement, copyText) => {
    let id = e.currentTarget.id;
    const element = e.currentTarget.parentElement;
    if (element.classList.contains("inv-copy-wrapper-show")) {
      this.setState(
        {
          invSheetTooltipTarget: id,
          invSheetToolTipPlacement: placement,
          invSheetTooltipCopyText: copyText,
          showInvoiceSheetTooltip: true,
        },
        () => {
          this.toggleTooltip(tooltip);
        }
      );
    }
  };

  invSheetCopyIconMouseOut = (tooltip) => {
    this.closeTooltip(tooltip);
  };
  // invSheetMouseOver = (id, tooltip, placement, copyText) => {
  //   this.setState(
  //     {
  //       invSheetTooltipTarget: id,
  //       invSheetToolTipPlacement: placement,
  //       invSheetTooltipCopyText: copyText,
  //       showInvoiceSheetTooltip: true,
  //     },
  //     () => {
  //       this.toggleTooltip(tooltip);
  //     }
  //   );
  // };

  // invSheetMouseOut = (tooltip) => {
  //   this.closeTooltip(tooltip);
  // };

  toggleTooltip = (tooltip) => {
    this.setState({ [tooltip]: true });
  };

  closeTooltip = (tooltip) => {
    this.setState({
      [tooltip]: false,
      showInvoiceSheetTooltip: false,
      invSheetTooltipTarget: "",
      invSheetToolTipPlacement: "right",
      invSheetTooltipCopyText: "",
    });
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    this.setState({
      showPreview: false,
    });
    dispatch(modalActions.displayGeneralAlert({ show: false, message: "" }));
    dispatch(modalActions.displayInvoiceSheet({ show: false, message: "" }));
    dispatch(modalActions.displayCreatedInvoice({ show: false }));
    dispatch(modalActions.displayShareInvoiceModal({ show: false }));
    dispatch(modalActions.displayDialogAlert({ show: false, message: "" }));

    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      revenues,
      customers,
      locations,
      currencies,
      revenue,
      subRevenue,
      subRevenues,
      location,
      customer,
      currency,
      name,
      email,
      address,
      revenueGroups,
      revenueGroup,
      isRequesting,
      invoiceItems,
      isLiveView,
      rrrNumber,
      invoiceNumber,
      billDate,
      totalAmount,
      phoneNumber,
      componentErrorMessages,
      showPreview,
      showDiscardModal,
      showDialog,
      logo,
      showAddNewCustomer,
      showSimilarCustomer,
      revenueGroupTag,
      invoiceSheetActions,
      createdInvoiceActions,
      decimalPrecision,
    } = this.state;
    const { user, alertComponentsReducer, requesting, dispatch } = this.props;
    const dialogAlert = alertComponentsReducer.dialogAlert;
    const shareInvoiceModal = alertComponentsReducer.shareInvoiceModal;
    const displayInvoiceSheet = alertComponentsReducer.displayInvoiceSheet;
    const displayCreatedInvoice = alertComponentsReducer.displayCreatedInvoice;

    let printInvDate =
      billDate !== null || undefined
        ? moment(billDate).format("YYYY-MM-DD")
        : "";
    let printDate = moment().format("YYYY-MM-DD");

    return (
      <div>
        <ContentLayout
          requesting={requesting}
          pageTitle="Create New Invoice"
          actions={
            <CreateInvoiceActionButtons
              showPreview={showPreview}
              isLiveView={isLiveView}
              isShowPreview={this.isShowPreview}
              toggleLiveView={this.toggleLiveView}
            />
          }
        >
          <SearchPanel
            isLiveView={isLiveView}
            isCustomer={true}
            componentErrorMessages={componentErrorMessages}
            revenues={revenues}
            revenue={revenue}
            subRevenue={subRevenue}
            subRevenues={subRevenues}
            customers={customers}
            customer={customer}
            billDate={billDate}
            name={name}
            email={email}
            phoneNumber={phoneNumber}
            address={address}
            currencies={currencies}
            currency={currency}
            rrrNumber={rrrNumber}
            handleChange={this.handleChange}
            location={location}
            locations={locations}
            revenueGroupTag={revenueGroupTag}
            revenueGroups={revenueGroups}
            revenueGroup={revenueGroup}
            invoiceItems={invoiceItems}
            removeInvoiceItem={this.removeInvoiceItem}
            handleItemChange={this.handleItemChange}
            handleFocus={this.handleFocus}
            handleBlur={this.handleBlur}
            addInvoiceItemFields={this.addInvoiceItemFields}
            showInvoiceSheet={this.showInvoiceSheet}
            isRequesting={isRequesting}
            renderInvoiceView={this.renderInvoiceView()}
            handleSelectChange={this.handleSelectChange}
            openAddNewCustomerModal={this.openAddNewCustomerModal}
            changeDatePicker={this.changeDatePicker}
            cancelInvoice={this.clearInputFields}
            decimalPrecision={decimalPrecision}
          />

          <div id="print-panel" style={{ display: "none" }}>
            <PrintInvoice
              invoiceNumber={invoiceNumber}
              clientName={name}
              paymentStatus={"Unpaid"}
              invoiceDate={printInvDate}
              issuerName={user.fullName}
              printDate={printDate}
              bills={invoiceItems}
              amountString={totalAmount}
              rrr={rrrNumber}
              logo={logo}
              currencySign={
                currency.id === 840 ? (
                  <span>&#36;</span>
                ) : currency.id === 978 ? (
                  <span>&#163;</span>
                ) : (
                  <span>&#8358;</span>
                )
              }
              decimalPrecision={decimalPrecision}
            />
          </div>

          {displayInvoiceSheet.show && (
            <DisplayInvoiceFullScreenModal
              displayInvoiceSheet={displayInvoiceSheet}
              invoiceSheetActions={invoiceSheetActions}
              requesting={requesting}
              dispatch={dispatch}
              renderInvoiceView={this.renderInvoiceView()}
            />
          )}
          {this.props.displayGeneralAlert.show && <GeneralAlert />}

          {dialogAlert.show && (
            <DialogFullScreenModal
              displayDialogAlert={dialogAlert}
              dispatch={this.props.dispatch}
              element={<p>{dialogAlert.message}</p>}
              declineClicked={
                showDiscardModal
                  ? this.declineCancelInvoice
                  : showDialog
                  ? this.cancelDialog
                  : ""
              }
              buttonDeclineText={dialogAlert.declineText}
              approveClicked={
                showDiscardModal
                  ? this.discardInvoice
                  : showDialog
                  ? this.approveDialog
                  : ""
              }
              buttonApproveText={dialogAlert.approveText}
              requesting={requesting}
            />
          )}
          {showPreview === true && this.renderPreviewView(showPreview)}

          {displayCreatedInvoice.show && (
            <CreatedInvoiceFullScreenModal
              displayCreatedInvoice={displayCreatedInvoice}
              invoiceSheetActions={createdInvoiceActions}
              requesting={requesting}
              dispatch={dispatch}
              renderInvoiceView={this.renderInvoiceView()}
              clearInputFields={this.clearInputFields}
            />
          )}
          {shareInvoiceModal.show && (
            <ShareInvoiceFullScreenModal
              displayShareInvoice={shareInvoiceModal}
              dispatch={this.props.dispatch}
              handleShareEmailChange={this.handleShareEmailChange}
              shareInvoice={this.shareInvoice}
              onkeydownEnterShareInvoice={this.onkeydownEnterShareInvoice}
              isRequesting={this.props.isRequesting}
              requesting={requesting}
            />
          )}
          {showAddNewCustomer && this.renderAddNewCustomer()}
          {showSimilarCustomer && this.renderSimilarCustomer()}
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user,
    requesting,
    settings,
    alertComponentsReducer,
    displayGeneralAlert,
    apiReducer,
    selfServiceReducer,
  } = state;
  return {
    user,
    requesting,
    settings,
    alertComponentsReducer,
    displayGeneralAlert,
    apiReducer,
    selfServiceReducer,
  };
};

export default connect(mapStateToProps)(CustomerCreateInvoice);
