import React from "react";
import { Table } from "reactstrap";

export const DuplicateMergeCustomersTable = ({ tableData }) => {
  return (
    <Table responsive striped hover>
      <thead className={"table-head-blue"}>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Customer ID</th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((item, index) => {
            return (
              <tr key={index} className={"table-row"}>
                <td>{item.customerName}</td>
                <td>{item.email}</td>
                <td>{item.id}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};
