import React from 'react';
import {Button, Col, Label, Row, Input} from "reactstrap";
import SelectList from "../../../components/SelectList/selectList";
import {FaPlusCircle} from "react-icons/fa";

const SearchPanel = ({name, role, supervisorList, supervisor,    selectChange,ProcessList, process, addLevel, createWorkFlow, componentErrorMessages, disableProcess, workflowName, handleChange, disableWorkflowName}) => {
    return (
        <div>
            <Row style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)", paddingBottom: '30px' }}>
                <Col md="3">
                    <div
                        style={{flexDirection:"column"}}
                        className="d-flex">
                        <p style={{fontSize:"30px", fontWeight:"bold", marginBottom:"0px"}}>{name}</p>
                        <p style={{fontSize:"12px"}}>{role}</p>
                    </div>
                </Col>
                 <Col md="3" style={{fontSize:"12px", lineHeight: "10px"}}>
                      <Label style={{fontSize:"12px"}}>Workflow Name</Label>
                    <Input
                        name={'workflowName'}
                        type="workflowName"
                        value={workflowName}
                        onChange={handleChange}
                        disabled={disableWorkflowName}
                        />
                    {componentErrorMessages.workflowName && <span className={"inputError"}>{componentErrorMessages.workflowName}</span>}
                 </Col>
                <Col md="2" style={{fontSize:"12px", lineHeight: "10px"}}>
                    <Label style={{fontSize:"12px"}}>{`Map ${name} to`}</Label>
                    <SelectList
                        placeholder={"Select Supervisor"}
                        data={supervisorList}
                        valueKey={'userId'}
                        textField={'name'}
                        selectValue={supervisor}
                        onValueChange={(event) => {selectChange(event, 'supervisor')}}
                    />
                    {componentErrorMessages.supervisor && <span className={"inputError"}>{componentErrorMessages.supervisor}</span>}
                </Col>
                <Col md="2" style={{fontSize:"12px", lineHeight: "10px"}}>
                    <Label style={{fontSize:"12px"}}>Process</Label>
                    <SelectList 
                        placeholder={"Select Process"}
                        data={ProcessList}
                        valueKey={'name'}
                        textField={'name'}
                        // selectValue={process.name}
                        selectValue={process}
                        disabled={disableProcess}
                        onValueChange={(event) => {selectChange(event, 'process')}}
                    />
                    {componentErrorMessages.process && <span className={"inputError"}>{componentErrorMessages.process}</span>}
                </Col>
                <Col md="2">
                    {addLevel ? <Button
                        // className={"big-button ml-8  px-2"}
                        color="success"
                        className="btn-rounded ml-8 px-4 text-white"
                        // outline color="secondary"
                        onClick={createWorkFlow}
                        style={{ marginTop:"29px"}}>
                       <FaPlusCircle className={"icon-margin"}/> Add Level
                    </Button>
                    :
                    <Button
                        // className={"big-button ml-8  px-4"}
                        className="btn-rounded ml-8 px-4 text-white"
                        color="success"
                        // outline color="secondary"
                        style={{backgroundColor:"#40D999", color:"#fff", marginTop:"27px"}}
                        onClick={createWorkFlow}>
                         Map User
                    </Button>
                    }
                </Col>
            </Row>
        </div>
    );
};

export default SearchPanel;