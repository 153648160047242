import React from 'react';
import Empty from "../../assets/images/empty.svg"
import "./style.css";
import AddRecordButton from "../AddRecordButton";


const EmptyState = ({ actionText, message, addRecord }) => (
    <main>
        <div>
            <img src={Empty} alt="empty" />
        </div>
        <p>{message}</p>
       <AddRecordButton
         actionText={actionText}
         addRecord={addRecord}
       />
    </main>
);
export default EmptyState;
