import React from "react";
import { Card, CardBody } from "reactstrap";

const Alert = ({ icon, message, ifRequery , invoiceNumber}) => {
  return (
    <Card style={{ borderRadius: "10px", height: "50%", overflowY: "auto" }}>
      <CardBody
        className={"d-flex justify-content-center"}
        style={{ padding: "4rem 2rem" }}
      >
        <div>
          <p className={"text-center"}> {icon}</p>
          <h3 style={{ fontWeight: "600", textAlign: "center" }}>{message}</h3>
        </div>
        <div>
          {/* <p>
            {" "}
            Invoice number {invoiceNumber} has not been paid. <br /> kindly
            contact customer to make payment.
          </p> */}
        </div>
      </CardBody>
    </Card>
  );
};

export default Alert;
