import {appConstants} from '../_constants';
import {initialState} from "../_store";

export function regions(state=initialState.regions, action) {
    switch (action.type) {
        case appConstants.REGIONS_SUCCESS:
            return action.regions;
        default:
            return state
    }
}
