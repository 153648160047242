import React, { Component } from "react";
import SearchPanel from "./search-panel";
import { Button, Row, Col } from "reactstrap";
import ContentLayout from "../../../components/ContentLayout";
import { FaArrowLeft } from "react-icons/fa";
import { connect } from "react-redux";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { commonService } from "../../../_services";
import { alertActions, apiActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import { appHelpers } from "../../../_helpers";
import TableStructure from "./record";
import axios from "axios";
import "./index.scss";
import moment from "moment";
import RequestCard from "./request-card";



let source;
const presentDate = new Date();


class MyRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      searchQuery: "",
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      tableData: [],
      creditTypes: [],
      creditType: "",
      startDate: moment().subtract(1, "month"), // This keeps the start date from a month ago
      endDate: moment(presentDate, "YYYY-MM-DD"), //End date by default will always be current date
      pendingRequest: 0,
      approvedRequest: 0,
      totalRequest: 0,
      selectedStatus: "",
      selectedCard: 0,
    }
    source = axios.CancelToken.source();
  }

  componentDidMount() {
    const body = document.body;
    body.style.overflow = "scroll";
    this.fetchInitialReferenceData();
    this.searchRequest();
  }

  static getDerivedStateFromProps(props, state) {
    let pathState = props.location.state;

    if (pathState) {
  
      if (
        props.location.state.requestDetailsRecord !== state.requestDetailsRecord
      ) {
        return {
          requestDetailsRecord: props.location.state.requestDetailsRecord,
        };
      }
    }

    return null;
  }

  fetchInitialReferenceData = () => {
    // this.getAllCreditNoteType();
    
    const { apiReducer } = this.props;
    const { creditTypes } = apiReducer;

    if (!creditTypes) {
      this.getAllCreditNoteType();
    } else {
      this.setState({ creditTypes: creditTypes });
    }
  };

  componentDidUpdate(prevProps) {
    const { apiReducer } = this.props;
    if (apiReducer.creditTypes !== prevProps.apiReducer.creditTypes) {
      this.setState({
        creditTypes: apiReducer.creditTypes,
      });
    }
  }

  getAllCreditNoteType = () => {
    return this.props.dispatch(apiActions.getAllCreditNoteType(source.token));

  }
  //  handler
  handleChange() {

  }

  // api for search
  searchRequest = (pageNumber = 0) => {
    const { dispatch, user } = this.props;
    const { pageSize, startDate, endDate, searchQuery, creditType, selectedStatus} = this.state;
    var nextPageNUmber = 0;
    if (typeof pageNumber === "number") {
      nextPageNUmber = pageNumber;
    }

    let payload = {
      pageSize: pageSize,
      from: nextPageNUmber,
      parameter: {
        startDate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59",
        agencyId: user.organizationId,
        searchText: searchQuery,
        creditNoteType:creditType.id,
        requestStatus: selectedStatus,
      },
    };

    dispatch(alertActions.startRequest());
    commonService.searchCreditNote(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          // totalCount: response.totalCount,
          totalCount: selectedStatus === 0 ? response.totalPendingCount : selectedStatus === 1 ? response.totalApprovedCount : response.totalCount,
          tableData: response.results,
          pendingRequest: response.totalPendingCount,
          totalRequest: response.totalCount,
          approvedRequest: response.totalApprovedCount,
          creditNoteValue: response.totalCreditAmountString,
          currentPage: pageNumber === 0 ? 1 : pageNumber,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.message));
        }
      }
    });
  };
  downloadReport = () => {

  }

  handleSelectChange = (payload, attribute) => {
    let currState = this.state;
      if (!payload) {
        currState[attribute] = "";
      }else {
        if (attribute === "creditType") {
          currState[attribute] =payload;
        }
      }
      this.setState({ currState });
    };
    
    updateTable = id => {
      // let status = { pending: 0, approved: 1, rejected: 2, all: -1 };
  
      switch (id) {
        case 0:
          this.setState({ selectedCard: 0 , selectedStatus: ""}, () => {
            this.searchRequest();
          });
          break;
        case 1:
          this.setState({ selectedCard: 1, selectedStatus: 1}, () => {
            this.searchRequest();
          });
          break;
        case 2:
          this.setState({ selectedCard: 2, selectedStatus: 0}, () => {
            this.searchRequest();
          });
          break;
        default:
          this.setState({ selectedCard: 0, selectedStatus: "" });
          break;
      }
    };

  changeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  searchBarChange = (event) => {
    this.setState({ [event.target.name]: event.target.value },
    );
  };

  search = () => {
    this.searchRequest();
  };

  showRequestDetails = (pathname, attribute,value) => {
    this.props.history.push({
      pathname,
      state: { [attribute]: value },
    });
  }

  render() {
    const {
      totalCount,
      currentPage,
      pageSize,
      tableData,
      creditTypes,
      startDate,
      endDate,
      searchQuery,
      pendingRequest,
      approvedRequest,
      totalRequest,
    } = this.state;
    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle={"Credit Note Request"}
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className=" btn-rounded mr-2 big-action-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <Row>
          <SearchPanel
            placeholder="Search"
            value={searchQuery}
            onSearch={this.searchRequest}
            startDate={startDate}
            endDate={endDate}
            onChange={this.searchBarChange}
            creditTypes={creditTypes}
            changeDatePicker={this.changeDatePicker}
            selectChange={this.handleSelectChange}          />
        </Row>
        <br />

        <Row>
        <Col md="6" lg="3" style={{marginRight:"40px"}}>
        <RequestCard
              label="Total request"
              requestDetails={totalRequest}
              borderColor="#1354D3"
              getCardDetails={this.updateTable.bind(this,0)}
            />
            </Col>
            <Col md="6" lg="3" style={{marginRight:"40px"}}>
        <RequestCard
              label="Approved"
              requestDetails={approvedRequest}
              borderColor="#13676D"
              getCardDetails={this.updateTable.bind(this, 1)}
            />
            </Col>
            <Col md="6" lg="3">
        <RequestCard
              label="Pending"
              requestDetails={pendingRequest}
              borderColor="#FFC31A"
              getCardDetails={this.updateTable.bind(this, 2)}
            />
            </Col>
        </Row>

        {tableData.length === 0 && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}
        {tableData.length > 0 && (
            <Row style={{ marginTop: "50px" }}>
              <Col md={"12"}>
                <TableStructure
                  currentPage={currentPage}
                  fetchMoreRecords={this.searchRequest}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  tableData={tableData}
                  selectedCard={this.state.selectedCard}
                  showRequestDetails={this.showRequestDetails}
                />
              </Col>
            </Row>
          )}
      </ContentLayout>
    );
  }
}


const mapStateToProps = (state) => {
  const { user, requesting, alert, apiReducer, creditTypes } = state;
  return {
    user,
    requesting,
    alert,
    apiReducer,
    creditTypes
  };
};
export default connect(mapStateToProps)(MyRequest);
