import {apiConstants} from "../_constants";

const INIT_STATE = {
  selfServiceRevenues: null,
  selfServiceLocations: null,
  selfServiceCustomerDetails: null,
  selfServiceUserLocations: null,
};

export default (state = INIT_STATE, action)=>  {
  switch (action.type) {
    case apiConstants.SAVE_SELF_SERVICE_REVENUES:
      return {
        ...state,
        selfServiceRevenues: action.selfServiceRevenues
      };
    case apiConstants.SAVE_SELF_SERVICE_LOCATIONS:
      return {
        ...state,
        selfServiceLocations: action.selfServiceLocations
      };
    case apiConstants.SAVE_SELF_SERVICE_USER_LOCATIONS:
      return {
        ...state,
        selfServiceUserLocations: action.selfServiceUserLocations
      };
    case apiConstants.SAVE_SELF_SERVICE_CUSTOMER_DETAILS:
      return {
        ...state,
        selfServiceCustomerDetails: action.selfServiceCustomerDetails
      };
    default:
      return state;
  }
}
