import React from "react";
import "./index.scss";
import Pagination from "react-js-pagination";
import { FaCaretRight, FaCaretLeft } from "react-icons/fa";
import { Button, Row, Col } from "reactstrap";
import { FaDownload } from "react-icons/fa";

class ServerPaginatedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  handlePageChange(pageNumber, callback) {
    this.setState({ activePage: pageNumber });
    callback(pageNumber);
  }

  render() {
    let {
      target,
      totalCount,
      pageSize,
      fetchMore,
      currentPage,
      topDownload,
      bottomDownload,
      downloadPdf,
      downloadExcel,
      actionButtons,
      onClickDecline,
      onClickApprove,
      requesting,
    } = this.props;

    return (
      <div>
        <div className={"pagination-download-wrapper"}>
          <Pagination
            hideDisabled={true}
            activePage={currentPage}
            nextPageText={<FaCaretRight className={"caret"} size={"2em"} />}
            prevPageText={<FaCaretLeft className={"caret"} size={"2em"} />}
            lastPageText={"Last"}
            // firstPageText={'First'}
            itemsCountPerPage={pageSize}
            totalItemsCount={totalCount}
            pageRangeDisplayed={7}
            onChange={(pageNumber) => {
              this.handlePageChange(pageNumber, fetchMore);
            }}
          />
          {topDownload && (
            <div className={"download__button-wrapper"}>
              <Button
                onClick={downloadPdf}
                className={"download__button--pdf btn-rounded btn-outline-info"}
              >
                Download PDF <FaDownload />
              </Button>
              <Button
                onClick={downloadExcel}
                className={
                  "download__button--excel btn-rounded btn-outline-info"
                }
              >
                Download Excel <FaDownload />
              </Button>
            </div>
          )}
        </div>
        {target}
        <div className={"pagination-download-wrapper"}>
          <Pagination
            hideDisabled={true}
            activePage={currentPage}
            nextPageText={<FaCaretRight className={"caret"} size={"2em"} />}
            prevPageText={<FaCaretLeft className={"caret"} size={"2em"} />}
            lastPageText={"Last"}
            // firstPageText={'First'}
            itemsCountPerPage={pageSize}
            totalItemsCount={totalCount}
            pageRangeDisplayed={7}
            onChange={(pageNumber) => {
              this.handlePageChange(pageNumber, fetchMore);
            }}
          />
          {bottomDownload && (
            <div className={"download__button-wrapper"}>
              <Button
                onClick={downloadPdf}
                className={"download__button--pdf btn-rounded btn-outline-info"}
              >
                Download PDF <FaDownload />
              </Button>
              <Button
                onClick={downloadExcel}
                className={
                  "download__button--excel btn-rounded btn-outline-info"
                }
              >
                Download Excel <FaDownload />
              </Button>
            </div>
          )}
          {actionButtons && (
            <Row>
              <Col
                md="12"
                // className="d-flex justify-content-center"
                style={{ marginRight: "25rem" }}
              >
                <Button
                  color="danger"
                  className="btn-sm btn-rounded px-4 mr-2 text-white"
                  onClick={onClickDecline}
                >
                  Cancel
                </Button>
                <Button
                  color="success"
                  className="btn-sm btn-rounded px-4 mr-2 text-white"
                  onClick={onClickApprove}
                  disabled={requesting}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default ServerPaginatedTable;
