import React, { Component } from "react";
import TableStructure from "./record";
import { Button } from "reactstrap";
import SearchBar from "../../../components/SearchBar";
import ContentLayout from "../../../components/ContentLayout";
import { FaArrowLeft, FaCheckCircle, FaTimes } from "react-icons/fa";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { appHelpers } from "../../../_helpers";
import { commonService } from "../../../_services/common.service";
import { appConstants } from "../../../_constants/app.constants";
import { connect } from "react-redux";
import { alertActions } from "../../../_actions";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import "./index.scss";
import { RenderAlertModal } from "../../../components/AlertModal";
import axios from "axios";

let source;

class WorkflowManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      workflowSearchValue: "",
      email: "",
      componentErrorMessages: [],
      showAlertModal: false,
      alertIcon: "",
      alertMessage: "",
      getWorkflowResponseMessage: "",
    };
    source = axios.CancelToken.source();
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    //Call branding if there is no method to display select agency view
    appHelpers.checkDerivedStateFromPropsMethod.call(this);

    this.searchWorkflow();
  }

  searchWorkflow = (pageNumber = 1) => {
    const { dispatch } = this.props;
    const { workflowSearchValue, pageSize } = this.state;

    const payload = {
      pageSize: pageSize,
      from: pageNumber,
      parameter: {
        searchQuery: workflowSearchValue,
      },
    };

    dispatch(alertActions.startRequest());
    commonService.searchWorkflow(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (response.results.length === 0) {
          this.setState({ getWorkflowResponseMessage: response.message });
        }
        this.setState({
          currentPage: pageNumber === 0 ? 1 : pageNumber,
          data: response.results,
          totalCount: response.totalCount,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (response === "Please Check your internet connectivity") {
          dispatch(alertActions.error(response));
        }
      }
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  searchBarChange = (e) => {
    this.setState({ workflowSearchValue: e.target.value }, () => {
      if (this.state.workflowSearchValue === "") {
        this.searchWorkflow();
      }
    });
  };

  search = (e) => {
    e.preventDefault();
    this.searchWorkflow();
  };

  validateInput = () => {
    let _errs_ = [];
    let formValid = true;
    if (this.state.email === "") {
      _errs_["email"] = "Please enter an email address";
      formValid = false;
    }
    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  getUserByEmail = () => {
    const { email } = this.state;
    const { dispatch } = this.props;

    if (this.validateInput()) {
      dispatch(alertActions.startRequest());

      commonService.getUserByEmail(email, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          let pathState = {
            userDetails: { ...response.data },
          };
          appHelpers.navigateRoute.call(this, "/user-mapping", pathState);
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          this.displayErrorResponseAlert(response, false);
        }
      });
    }
  };
  getWorkFlowLevels = (e, item) => {
    if (
      e.target.parentElement.className === "table-row" ||
      e.target.classList.contains("table-row")
    ) {
      const { dispatch } = this.props;

      dispatch(alertActions.startRequest());

      commonService.getWorkFlowLevels(item.id, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        // let workFlowLevels = {...response.data};

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          let statePath = {
            workFlowLevels:
              response.data !== null ? [...response.data] : response.data,
            selectedWorkflowDetails: item,
          };

          appHelpers.navigateRoute.call(this, "/user-mapping", statePath);
          // }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          this.displayErrorResponseAlert(response, false);
        }
      });
    }
  };

  activateWorkFlow = (payload) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    commonService.activateWorkFlow(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(response, true, this.searchWorkflow());
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response, true, this.searchWorkflow());
      }
    });
  };

  deactivateWorkFlow = (payload) => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());
    commonService.deactivateWorkFlow(payload).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(response, true, this.searchWorkflow());
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response, true, this.searchWorkflow());
      }
    });
  };

  handleSwitchChecked = (e, item) => {
    if (e.target.checked === true) {
      this.activateWorkFlow(item.id);
    }
    if (e.target.checked === false) {
      this.deactivateWorkFlow(item.id);
    }
  };

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  //Alert Notifications
  displaySuccessResponseAlert = (response, action, query) => {
    this.setState(
      {
        showAlertModal: true,
        alertIcon: (
          <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
        ),
        alertMessage: response.message,
      },
      () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
      }
    );
    if (action) {
      return { query };
    }
  };

  displayErrorResponseAlert = (response, action, query) => {
    const { dispatch } = this.props;
    if (response === "Please Check your internet connectivity") {
      dispatch(alertActions.error(response));
    } else if (typeof response === "string") {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    } else {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response ? response.message : "",
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    }
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      data,
      currentPage,
      totalCount,
      pageSize,
      workflowSearchValue,
      email,
      componentErrorMessages,
      showAlertModal,
      alertIcon,
      alertMessage,
      requesting,
      getWorkflowResponseMessage,
    } = this.state;

    return (
      <ContentLayout
        pageTitle={"Workflow Management"}
        requesting={this.props.requesting}
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className="big-action-btn btn-rounded mr-2"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <Row className={"row-space"}>
          <Col md="3">
            <FormGroup>
              <Label>Email</Label>
              <Input
                name={"email"}
                type="email"
                value={email}
                onChange={this.handleChange}
              />
              {componentErrorMessages.email && (
                <span className={"inputError"}>
                  {componentErrorMessages.email}
                </span>
              )}
            </FormGroup>
          </Col>
          <Col md="3">
            <Button
              className="btn-rounded big-action-btn text-white mt-4 mr-0 ml-0"
              color={"success"}
              onClick={this.getUserByEmail}
              disabled={requesting}
            >
              Create Workflow
            </Button>
          </Col>
        </Row>

        <Row className={"row-spacing"}>
          <Col sm={{ size: 5 }} className="text-center">
            <SearchBar
              placeholder="Search workflow name or username"
              showSearchButton
              onChange={this.searchBarChange}
              value={workflowSearchValue}
              search={this.search}
            />
          </Col>
        </Row>
        {data.length === 0 && (
          <EmptyRecordMessage message={getWorkflowResponseMessage} />
        )}
        {data.length > 0 && (
          <Row>
            <Col md={"12"}>
              <TableStructure
                tableData={data}
                showUserMapping={this.getWorkFlowLevels}
                pageSize={pageSize}
                totalCount={totalCount}
                currentPage={currentPage}
                fetchMoreRecords={this.searchWorkflow}
                handleSwitchChecked={this.handleSwitchChecked}
              />
            </Col>
          </Row>
        )}
        {showAlertModal && (
          <RenderAlertModal
            message={alertMessage}
            icon={alertIcon}
            showAlertModal={showAlertModal}
            hideAlertView={this.hideAlertView}
          />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, alert, progress, user, requesting } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(WorkflowManagement);
