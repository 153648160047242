import React, { Component } from "react";
import { FormGroup, Label, Row, Col, Button } from "reactstrap";
import moment from "moment";
import { FaArrowLeft } from "react-icons/fa";
import { DatePicker } from "../../../components/DatePicker";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import Summary from "./summary";
import { commonService } from "../../../_services";
import { alertActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import TableStructure from "./record";
import axios from "axios";
import { appHelpers } from "../../../_helpers";
import "./index.scss";

let source;
class PerformanceTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      startDate: moment(new Date()),
      endDate: moment(new Date()),
      performanceDetails: [],
      totalCount: 0,
      currentPage: 1,
      pageSize: 10,
      nairaInvoicedValue: 0,
      dollarInvoicedValue: 0,
      nairaPaidValue: 0,
      dollarPaidValue: 0,
      nairaCountValue: 0,
      dollarCountValue: 0,
      organizationId: 0,
      agencies: [],
      isVggRole: false,
      paidNairaTopValue: 0,
      paidDollarBottomValue: 0,
    };
    source = axios.CancelToken.source();
  }

  static getDerivedStateFromProps(props, state) {
    let pathState = props.location.state;
    if (pathState) {
      if (
        props.location.state.selectedAgencyDetails !==
        state.selectedAgencyDetails
      ) {
        return {
          agencyDetails: props.location.state.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    const { user } = this.props;
    const { agencyDetails } = this.state;
    //get route the user is coming from
    let previousPath = this.props.history.location.state.from;

    this.setState({
      isVggRole: user.vggRole === appConstants.ROLES.VGG_ADMIN ? true : false,
      organizationName: agencyDetails ? agencyDetails.name : user.organization,
    });
    //Everytime a user with a VGG Role vists this page, the user must select an agency
    //agencyDetails===undefined prevents redirect from happening again once an agency is selected
    if (
      user.vggRole === appConstants.ROLES.VGG_ADMIN &&
      agencyDetails === undefined
    ) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      this.getPerformanceTracker();
    }
  }

  getPerformanceTracker = (pageNumber = 0) => {
    const { dispatch, user } = this.props;
    const { pageSize, startDate, endDate, agencyDetails } = this.state;
    var nextPageNUmber = 0;
    if (typeof pageNumber === "number") {
      nextPageNUmber = pageNumber;
    }

    let payload = {
      pageSize: pageSize,
      from: nextPageNUmber,
      parameter: {
        startDate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59",
        agencyId:
          user.vggRole === appConstants.ROLES.VGG_ADMIN
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
      },
    };

    dispatch(alertActions.startRequest());
    commonService.performanceTracker(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          performanceDetails: response.results,
          totalCount: response.totalCount,
          tableData: response.results,
          nairaInvoicedValue: response.trackerSummary.totalOverDueNaira,
          nairaPaidValue: response.trackerSummary.totalPaidNaira,
          nairaCountValue: response.trackerSummary.totalNairaCount,
          dollarInvoicedValue: response.trackerSummary.totalOverDueDollar,
          dollarPaidValue: response.trackerSummary.totalPaidDollar,
          dollarCountValue: response.trackerSummary.totalDollarCount,
          paidNairaTopValue: response.trackerSummary.totalPaidNairaCount,
          paidDollarBottomValue: response.trackerSummary.totalPaidDollarCount,
          currentPage: pageNumber === 0 ? 1 : pageNumber,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.message));
        }
      }
    });
  };

  downloadPerformanceTracker = (pageNumber = 0, type) => {
    const { dispatch, user } = this.props;
    const { endDate, startDate, pageSize, agencyDetails } = this.state;

    let payload = {
      pageSize: pageSize,
      from: pageNumber === 1 || pageNumber === 0 ? 0 : pageNumber - 1,
      parameter: {
        agencyId:
          user.vggRole === appConstants.ROLES.VGG_ADMIN
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
        startDate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59",
        downloadType: type,
      },
    };

    dispatch(alertActions.startRequest());
    commonService
      .downloadPerformanceTracker(payload, source.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(alertActions.success(response.message));
          appHelpers.downloadFile(response.downloadUrl, "Performance Tracker");
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (typeof response === "string") {
            dispatch(alertActions.error(response));
          } else {
            dispatch(alertActions.error(response.message));
          }
        }
      });
  };

  changeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      startDate,
      endDate,
      totalCount,
      currentPage,
      pageSize,
      performanceDetails,
      isRequesting,
      nairaInvoicedValue,
      nairaPaidValue,
      nairaCountValue,
      dollarInvoicedValue,
      dollarPaidValue,
      dollarCountValue,
      isVggRole,
      paidNairaTopValue,
      paidDollarBottomValue,
    } = this.state;

    return (
      <div>
        <ContentLayout
          requesting={this.props.requesting}
          pageTitle="Performance Tracker"
          actions={
            isVggRole && (
              <div className={"float-right"}>
                <Button
                  outline
                  color="info"
                  className=" btn-rounded mr-2 big-action-btn"
                  onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
                >
                  <FaArrowLeft className={"icon-margin"} />
                  Back
                </Button>
              </div>
            )
          }
        >
          <Summary
            invoicedTopValue={
              <h4 className={"card-summary"}>
                &#8358;{" "}
                {appHelpers.numberWithCommasOnly(
                  nairaInvoicedValue,
                  appConstants.DEFAULT_PRECISION
                )}
              </h4>
            }
            paidTopValue={
              <h4 className={"card-summary"}>
                &#8358;{" "}
                {appHelpers.numberWithCommasOnly(
                  nairaPaidValue,
                  appConstants.DEFAULT_PRECISION
                )}
              </h4>
            }
            countTopValue={
              <h4 className={"card-summary"}>{nairaCountValue}</h4>
            }
            invoicedBottomValue={
              <h4 className={"card-summary"}>
                &#36;{" "}
                {appHelpers.numberWithCommasOnly(
                  dollarInvoicedValue,
                  appConstants.DEFAULT_PRECISION
                )}
              </h4>
            }
            paidBottomValue={
              <h4 className={"card-summary"}>
                &#36;{" "}
                {appHelpers.numberWithCommasOnly(
                  dollarPaidValue,
                  appConstants.DEFAULT_PRECISION
                )}
              </h4>
            }
            countBottomValue={
              <h4 className={"card-summary"}>{dollarCountValue}</h4>
            }
            paidNairaTopValue={
              <h4 className={"card-summary"}>{paidNairaTopValue}</h4>
            }

            paidDollarBottomValue={
              <h4 className={"card-summary"}>{paidDollarBottomValue}</h4>
            }
          />

          <Row>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>Start date</b>
                </Label>
                <DatePicker
                  date={startDate}
                  name="startDate"
                  onChange={(date) => this.changeDatePicker(date, "startDate")}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>End date</b>
                </Label>
                <DatePicker
                  date={endDate}
                  onChange={(date) => this.changeDatePicker(date, "endDate")}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="float-left mt-5">
            <Button
              color="success"
              className="btn-sm btn-rounded  mr-2 text-white"
              onClick={this.getPerformanceTracker}
              disabled={isRequesting}
            >
              Search
            </Button>
          </div>

          <br />
          <br />

          {performanceDetails.length === 0 && (
            <EmptyRecordMessage message={"No record match your search"} />
          )}
          {performanceDetails.length > 0 && (
            <Row style={{ marginTop: "50px" }}>
              <Col md={"12"}>
                <TableStructure
                  currentPage={currentPage}
                  fetchMore={this.getPerformanceTracker}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  apiData={performanceDetails}
                  user={this.props.user}
                  downloadPerformanceTracker={this.downloadPerformanceTracker}
                />
              </Col>
            </Row>
          )}
        </ContentLayout>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { user, requesting } = state;
  return {
    user,
    requesting,
  };
};
export default connect(mapStateToProps)(PerformanceTracker);
