import React from 'react';
import {Table} from "reactstrap";
import './index.css';
import {appHelpers} from "../../../_helpers";

const Permissions = ({permissions, checkboxChange}) => {

    return (
        <div className={"table-container"}>
            <Table className={"permissions-table table-main"} responsive={true}  striped >
                <thead >
                <tr>
                    {/*{tableHeaderColumns.map(item => <th>{item}</th>)}*/}
                </tr>
                </thead>
                <tbody >
                {appHelpers.tableContent(permissions, checkboxChange)}
                </tbody>
            </Table>
        </div>

    );
};

export default Permissions;