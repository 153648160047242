import React from "react";
import {
    Button,
    Form,
    FormGroup,
    Col,
    Label,
    Row,
    Input
} from "reactstrap";
import { DatePicker } from "../../../components/DatePicker";
import "./index.scss";
import SelectList from "../../../components/SelectList/selectList";

const SearchPanel = ({
    searchQuery,
    onChange,
    selectChange,
    creditType,
    creditTypes,
    search,
    startDate,
    endDate,
    requesting,
    changeDatePicker,
    onSearch,
}) => {
    return (
        <Form onSubmit={e => e.preventDefault()}>
            {/* <Container> */}
            <Row>
                <Col xs="12" md="3">
                    <FormGroup>
                        <Label for="date">Start Date</Label>
                        <DatePicker
                        //  id="startDate"
                        //  name="startDate"
                        //  value={startDate}
                            date={startDate}
                            onChange={(date) => changeDatePicker(date, "startDate")}
                            />
                    </FormGroup>
                </Col>
                <Col xs="12" md="3">
                    <FormGroup>
                        <Label for="date">End Date</Label>

                        <DatePicker
                        //   id="endDate"
                        //   name="endDate"
                        //   value={endDate}
                            date={endDate}
                            onChange={(date) => changeDatePicker(date, "endDate")}
                            />
                    </FormGroup>
                </Col>
                <Col xs="12" md="3">
                    <FormGroup className="mb-4">
                        <Label>Credit Note Type</Label>
                        <SelectList
                            data={creditTypes}
                            valueKey={"id"}
                            textField={"name"}
                            selectValue={creditType}
                            onValueChange={(event) => {
                                selectChange(event, "creditType");
                            }}
                            placeholder={"Select Type..."}
                        />
                    </FormGroup>
                </Col>
                <Col xs="12" md="3">
                    <FormGroup>
                        <Label for="search">Search Box</Label>
                        <Input
                            type="text"
                            name={"searchQuery"}
                            onChange={onChange}
                            id={searchQuery}
                            value={searchQuery}
                        />
                    </FormGroup>
                </Col>
                <Col xs="12" md="3" className={"search-button"}>
                    <Button
                        // size="lg"
                        color="success"
                        className="btn-rounded px-4 text-white"
                        disabled={requesting}
                        onClick={() => onSearch(1)}
                    >
                        {requesting ? `Searching...` : `Search`}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SearchPanel;
