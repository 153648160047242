import React, { Component } from "react";
import ContentLayout from "../../../components/ContentLayout";
import { appHelpers } from "../../../_helpers";
import { FaArrowLeft, FaTimes, FaCheckCircle } from "react-icons/fa";
import axios from "axios";
import { FormGroup, Label, Input, Row, Col, Button, Table } from "reactstrap";
import { connect } from "react-redux";
import { DatePicker } from "../../../components/DatePicker";
import moment from "moment";
import TableStructure from "./record";
import { supportService } from "../../../_services";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { appConstants } from "../../../_constants";
import { alertActions, modalActions } from "../../../_actions";
import FullScreenModal from "../../../components/FullScreenModal";
import Alert from "../../../components/Alert";

let source;
const presentDate = new Date();

class ManagePaymentReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, "month"), // This keeps the start date from a month ago
      endDate: moment(presentDate, "YYYY-MM-DD"), //End date by default will always be current date
      takeRecord: [],
      invoiceDetails: [],
      startIndex: 0,
      pageSize: 10,
      totalCount: 0,
      takeRecord: [],
      showAlertModal: false,
      isVggRole: false,
      organizationId: 0,
      agencies: [],
    };
    this.checkboxChangeAll = this.checkboxChangeAll.bind(this);
    source = axios.CancelToken.source();
  }

  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;

    if (pathState) {
      if (
        props.location.state.selectedAgencyDetails !==
        state.selectedAgencyDetails
      ) {
        return {
          agencyDetails: props.location.state.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    this.routeUserBasedOnRole();
  }

  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { agencyDetails } = this.state;

    //get route the user is coming from
    let previousPath = this.props.history.location.state.from;

    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    //Everytime a user with a VGG Role vists this page, the user must select an agency
    //agencyDetails===undefined prevents redirect from happening again once an agency is selected
    if (vggAdminRole && agencyDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is already selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationName: agencyDetails
            ? agencyDetails.name
            : user.organization,
          organizationId: vggAdminRole
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
        },
        () => {
          this.getInvoiceDetails();
        }
      );
    }
  };

  changeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  checkboxChange = (event, item) => {
    console.log(item, "thisItem");
    const target = event.target;
    const { takeRecord } = this.state;
    if (Array.isArray(item)) {
      console.log(item, "theItem");
      return;
    }
    if (target.checked) {
      const selectedField = {
        agencyId: item.agencyId,
        invoiceNumber: item.invoiceNumber,
        amount: item.amount,
        amountPaid: item.amountPaid,
        remitaRetrievalReference: item.remitaRetrievalReference,
      };

      // Add the selectedField to the takeRecord array if it's not already present
      if (
        !takeRecord.some(
          (record) => record.invoiceNumber === selectedField.invoiceNumber
        )
      ) {
        this.setState((prevState) => ({
          takeRecord: [...prevState.takeRecord, selectedField],
        }));
      }
    } else {
      // Remove the corresponding record from takeRecord array
      this.setState((prevState) => ({
        takeRecord: prevState.takeRecord.filter(
          (record) => record.invoiceNumber !== item.invoiceNumber
        ),
      }));
    }
  };

  checkboxChangeAll = (event) => {
    const { invoiceDetails, takeRecord } = this.state;
    const target = event.target;
    const selectAll = target.checked;

    let updatedTakeRecord = [...takeRecord];

    if (selectAll) {
      invoiceDetails.map((item) => {
        const selectedField = {
          agencyId: item.invoice.agencyId,
          invoiceNumber: item.invoice.invoiceNumber,
          amount: item.invoice.amount,
          amountPaid: item.invoice.amountPaid,
          remitaRetrievalReference: item.invoice.remitaRetrievalReference,
        };
        console.log(selectedField, "selected1");
        if (updatedTakeRecord.some((record) => record.invoiceNumber === selectedField.invoiceNumber)) {
          // updatedTakeRecord.push(selectedField);
          return
        } else {
          console.log(item.invoice, "selected2");
            updatedTakeRecord.push(selectedField);

        }

      });
    } else {
      // invoiceDetails.map((item) => {
      //   updatedTakeRecord = updatedTakeRecord.filter(
      //     (record) => record.invoiceNumber !== item.invoice.invoiceNumber
      //   );
      // });
      updatedTakeRecord = [];
    }
    console.log(updatedTakeRecord, "updatedTakeRecord");

    this.setState({
      selectAll: selectAll,
      takeRecord: updatedTakeRecord,
    });
  };

  getInvoiceDetails = (pageNumber = 0) => {
    let { dispatch } = this.props;
    let {
      startDate,
      endDate,
      startIndex,
      pageSize,
      organizationId,
    } = this.state;
    var nextPageNUmber = 0;
    if (typeof pageNumber === "number") {
      nextPageNUmber = pageNumber;
    }
    let payload = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      startIndex: nextPageNUmber,
      pageSize: pageSize,
      agencyId: organizationId,
    };

    dispatch(alertActions.startRequest());
    supportService.getPaidInvoicesWithoutNotification(payload).then((res) => {
      let response = res.response;
      console.log(response.result, "whatNow");
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          invoiceDetails: response.result,
          totalCount: response.totalCount,
          startIndex: pageNumber === 0 ? 1 : pageNumber,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.setState({
          invoiceDetails: [],
        });
      }
    });
  };

  initiateBulkMarkAsPaid = () => {
    let { dispatch } = this.props;
    let { takeRecord } = this.state;
    let payload = [...takeRecord];

    dispatch(alertActions.startRequest());
    supportService.initiateBulkMarkAsPaid(payload).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState(
          {
            showAlertModal: true,
            alertMessage: "Invoice(s) sent for approval successfully",
            alertIcon: (
              <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
            ),
          },
          () => {
            appHelpers.dismissModal.call(this, "showAlertModal");
            this.clearFields();
            this.getInvoiceDetails();
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.setState(
          {
            showAlertModal: true,
            alertMessage: res.response,
            alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          },
          () => {
            appHelpers.dismissModal.call(this, "showAlertModal");
            // this.handleDialogClose();
          }
        );
      }
    });
    // }
  };

  renderAlertModal = (message, icon) => {
    let alertView = [];
    let count = 0;
    const { showAlertModal } = this.state;

    alertView.push(
      <FullScreenModal
        show={showAlertModal}
        key={count + 1}
        isInvoiceSheet={true}
        callback={this.hideAlertView}
        width={"35%"}
        top={"10%"}
      >
        <Alert icon={icon} message={message} />
      </FullScreenModal>
    );
    return alertView;
  };

  clearFields = () => {
    this.setState({
      takeRecord: [],
    });
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      startDate,
      endDate,
      showAlertModal,
      alertIcon,
      alertMessage,
      invoiceDetails,
      takeRecord,
      totalCount,
      pageSize,
      startIndex,
      viewDialogOpen,
      componentErrorMessages,
    } = this.state;
    const { requesting } = this.props;

    return (
      <div>
        <ContentLayout
          requesting={this.props.requesting}
          pageTitle={"Manage Payment Report"}
          actions={
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          }
        >
          <Row>
            <Col md={"3"}>
              <FormGroup>
                <Label for="date">Start Date</Label>
                <DatePicker
                  date={startDate}
                  onChange={(date) => this.changeDatePicker(date, "startDate")}
                />
              </FormGroup>
            </Col>
            <Col md={"3"}>
              <FormGroup>
                <Label for="date">End Date</Label>

                <DatePicker
                  date={endDate}
                  onChange={(date) => this.changeDatePicker(date, "endDate")}
                />
              </FormGroup>
            </Col>
            <Col md="3" xs="12" className="text-right">
              <FormGroup>
                <Label className="invisible">Invisible Button Label</Label>
                <Button
                  className="btn-rounded create-action-btn text-white w-75"
                  onClick={() => this.getInvoiceDetails()}
                  color={"success"}
                  disabled={requesting}
                >
                  Search
                </Button>
              </FormGroup>
            </Col>
          </Row>

          {invoiceDetails.length === 0 && (
            <EmptyRecordMessage message={"No record match your search"} />
          )}
          {invoiceDetails.length > 0 && (
            <Row style={{ marginTop: "76px" }}>
              <Col md={"12"}>
                <TableStructure
                  currentPage={startIndex}
                  fetchMore={this.getInvoiceDetails}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  apiData={invoiceDetails}
                  checkboxChange={this.checkboxChange}
                  checkboxChangeAll={this.checkboxChangeAll}
                  initiateRRR={this.initiateBulkMarkAsPaid}
                  // user={this.props.user}
                  // downloadInvoiceAging={this.downloadInvoiceAging}
                  takeRecord={takeRecord}
                />
              </Col>
            </Row>
          )}

          {showAlertModal === true &&
            this.renderAlertModal(alertMessage, alertIcon)}
          <br />
          <br />
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, alert } = state;
  return {
    user,
    requesting,
    alert,
  };
};
export default connect(mapStateToProps)(ManagePaymentReport);
