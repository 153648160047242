import React from 'react';

const ActiveCheckbox = ({checkboxChange, text, checked}) => {
  return (
    <div>
      <input
        className={"account-checkbox d-inline"}
        type="checkbox"
        onChange={checkboxChange}
        checked={checked}
      />
      <span> {text}</span>
    </div>
  );
};

export default ActiveCheckbox;