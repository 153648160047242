import React from "react";
import { Card, CardBody } from "reactstrap";
import Spinner from "../Spinner"

// import "./card.css";
const ActivityCard = ({ bgColor, label, value, visible = true }) => {
  return (
    <Card style={{ background: `${bgColor}`, minHeight:"85%" }}>
      <CardBody>
        <div className="d-flex">
          <div className="stats">
            <h3 className=" mt-1 mb-3 text-white-50" color="grey">{label}</h3>
            {visible === false ? <h2 style={{fontSize: "3.5rem"}} className="text-white">{value}</h2>:
            <Spinner size={30}  visible={visible} />}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ActivityCard;
