import React from 'react';
import {Row, Col, Label, FormGroup, Input} from 'reactstrap';
import SelectList from "../../../components/SelectList/selectList";

const ContactDetails = ({name, designation, phoneNumber, email, handleChange,
                          removeContact, componentErrorMessages, designations,
                          selectChange, index}) => {
    return (
        <div>
            <Row>
                <Col md={3}>
                     <FormGroup>
                        <Label for="name"> Name </Label>
                        <Input
                            type="name"
                            name="name"
                            id="item-name"
                            value={name}
                            onChange={handleChange}
                            // defaultValue=""
                            className={"search-inputs"}
                        />
                    </FormGroup>
                    {componentErrorMessages.name && <span className={"inputError"}>{componentErrorMessages.name}</span>}
                </Col>
                <Col md={2}>
                     <FormGroup>
                        <Label for="designation"> Designation </Label>
                       <SelectList
                         placeholder={'Select...'}
                         data={designations}
                         valueKey={'id'}
                         textField={'name'}
                         selectValue={designation}
                         onValueChange={(event) => {selectChange(event, 'designation', index)}}/>
                       {/*<Input*/}
                       {/*  type="number"*/}
                       {/*  name="designation"*/}
                       {/*  id="item-designation"*/}
                       {/*  value={designation}*/}
                       {/*  onChange={handleChange}*/}
                       {/*  defaultValue=""*/}
                       {/*  className={"search-inputs"}*/}
                       {/*/>*/}
                    </FormGroup>
                    {componentErrorMessages.designation && <span className={"inputError"}>{componentErrorMessages.designation}</span>}

                </Col>
                <Col md={3}>
                     <FormGroup>
                        <Label for="phone"> Phone Number </Label>
                        <Input
                            type="text"
                            name="phoneNumber"
                            id="item-phoneNumber"
                            value={phoneNumber}
                            onChange={handleChange}
                            defaultValue=""
                            className={"search-inputs"}
                        />
                    </FormGroup>
                    {componentErrorMessages.phoneNumber && <span className={"inputError"}>{componentErrorMessages.phoneNumber}</span>}
                </Col>
                <Col md={3}>
                     <FormGroup>
                        <Label for="email"> Email Address </Label>
                        <Input
                            type="text"
                            name="emailAddress"
                            id="item-email"
                            value={email}
                            onChange={handleChange}
                            defaultValue=""
                            className={"search-inputs"}
                        />
                    </FormGroup>
                    {componentErrorMessages.emailAddress && <span className={"inputError"}>{componentErrorMessages.emailAddress}</span>}
                </Col>
                <Col sm={1}>
                    <FormGroup>
                        <h4
                            className="center-block pointer-cursor remove-contact"
                            onClick={removeContact}
                        >
                            <i className="fa fa-minus-circle pr-2 f-icon"></i>
                        </h4>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
};

export default ContactDetails;