import React, { Component } from "react";
import { Table, Badge } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import Toggle from 'react-toggle'
import "react-toggle/style.css" // for ES6 modules
import "./index.scss";
import {appConstants} from "../../../_constants";


const Records = ( props) => {

  const {apiData, handleSwitchChecked, user} = props;

  return (
    <Table responsive={true} className={"table-main"}>
      <thead className={"th-head"}>
        <tr>
          <th style={{ padding: "none" }}>Service Type</th>
          <th style={{ padding: "none" }}>Code</th>
          {user.vggRole === appConstants.ROLES.VGG_ADMIN &&
          <th style={{padding: "none"}}>Agency</th>
          }
          {/* <th style={{ padding: "none" }}>Rev</th> */}
          <th style={{ padding: "none" }}>Rate</th>
          <th style={{ padding: "none" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {apiData.map((item,index) => {
          return (
            <tr key={index}>
              <th style={{ padding: "none" }}>{item.name}</th>
              <th style={{ padding: "none" }}>{item.code}</th>
              {user.vggRole === appConstants.ROLES.VGG_ADMIN &&
              <th style={{ padding: "none" }}>{item.agencyName}</th>
              }
              {/* <th style={{ padding: "none" }}>{item.revenueGroupName}</th> */}
              <th style={{ padding: "none" }}>{item.rate}</th>
              <th style={{ padding: "none" }}>{item.status !== false && (
              <Badge 
              className={"badge-spacing"}
              onClick={props.toggleModalFunction.bind(this,item)}
              size="xl" pill>Edit</Badge>)}
              {
                  <label className={"toggle-label"}>
                    <Toggle
                    // defaultChecked={item.status}
                    checked={item.status}
                    onChange={(e) => handleSwitchChecked(e, item)}
                    />
                  </label>
                }</th>        
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};


export default class TableStructure extends Component {
  render() {
    let {
      apiData,
      currentPage,
      fetchMore,
      pageSize,
      totalCount,
      toggleModalFunction,
      handleSwitchChecked,
      toggle,
      changeSwitch,
        user
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : ((pageSize * (currentPage - 1)) + 1);
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageSize={pageSize}
          totalCount={totalCount}
          target={<Records 
            apiData={apiData}   
            toggle={toggle}
            changeSwitch={changeSwitch}
            toggleModalFunction={toggleModalFunction}
            handleSwitchChecked={handleSwitchChecked}
            pageIndex={pageIndex}
            user={user}
          />}
        />
      </div>
    );
  }
}
