import { alertActions } from "../alert.actions";
import { apiConstants, appConstants } from "../../_constants";
import { selfService } from "../../_services/self-service";
import { staffService } from "../../_services/staff.service";

export const selfServiceActions = {
  fetchSelfServiceRevenues,
  fetchSelfServiceLocations,
  fetchSelfServiceCustomerDetails,
  fetchSelfServiceUserLocation,
};

function fetchSelfServiceRevenues(cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return selfService.getSelfServiceRevenues(cancelToken).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(saveSelfServiceRevenues(response.data));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(res.response));
        dispatch(failure(response.response));
      }
    });
  };
  function saveSelfServiceRevenues(selfServiceRevenues) {
    return {
      type: apiConstants.SAVE_SELF_SERVICE_REVENUES,
      selfServiceRevenues,
    };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function fetchSelfServiceLocations(cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return selfService.getSelfServiceLocations(cancelToken).then((res) => {
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(saveSelfServiceLocations(response.data));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(res.response));
        dispatch(failure(response.response));
      }
    });
  };
  function saveSelfServiceLocations(selfServiceLocations) {
    return {
      type: apiConstants.SAVE_SELF_SERVICE_LOCATIONS,
      selfServiceLocations,
    };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function fetchSelfServiceCustomerDetails(cancelToken, customerSearchInvoice) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return selfService
      .getSelfServiceCustomerDetails(cancelToken)
      .then((res) => {
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(saveSelfServiceCustomerDetails(response.data));
          if (customerSearchInvoice) {
            this.searchInvoiceNumber(0, null, "onPageLoad");
          }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(alertActions.error(res.response));
          dispatch(failure(response.response));
        }
      });
  };
  function saveSelfServiceCustomerDetails(selfServiceCustomerDetails) {
    return {
      type: apiConstants.SAVE_SELF_SERVICE_CUSTOMER_DETAILS,
      selfServiceCustomerDetails,
    };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}

function fetchSelfServiceUserLocation(payload, cancelToken) {
  return (dispatch) => {
    dispatch(alertActions.startRequest());
    return staffService
      .getSelfServiceUserLocation(payload, cancelToken)
      .then((res) => {
        const { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(saveSelfServiceUserLocations(response.data));
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.stopRequest());
          dispatch(alertActions.error(res.response));
          dispatch(failure(response.response));
        }
      });
  };
  function saveSelfServiceUserLocations(selfServiceUserLocations) {
    return {
      type: apiConstants.SAVE_SELF_SERVICE_USER_LOCATIONS,
      selfServiceUserLocations,
    };
  }
  function failure(message) {
    return { type: appConstants.REQUEST_FAILURE, message };
  }
}
