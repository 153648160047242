import React, { Component } from "react";
import TableStructure from "./record";
import { Button, Row, Col, Input, FormGroup, Label, Table } from "reactstrap";
import ContentLayout from "../../components/ContentLayout";
import { appHelpers } from "../../_helpers";
import { commonService, paymentsService } from "../../_services";
import { appConstants } from "../../_constants/app.constants";
import { connect } from "react-redux";
import SelectList from "../../components/SelectList/selectList";
// import { store } from "../../_store";
import { FaArrowLeft } from "react-icons/fa";
import { alertActions, commonActions } from "../../_actions";
import EmptyRecordMessage from "../../components/EmptyRecordMessage/EmptyRecordMessage";
import "./index.scss";
import moment from "moment";
import axios from "axios";
import { pageHelpers } from "../../_helpers";
import { modalActions, apiActions } from "../../_actions";
import CreatedInvoiceFullScreenModal from "../../components/CreatedInvoiceFullScreenModal";
import PrintInvoice from "../../components/MobilePrint/print-invoice";
import ShareInvoiceFullScreenModal from "../../components/ShareInvoice/ShareInvoiceFullScreenModal";
import GeneralAlert from "../../components/Api-response-alert";
let source;

class PaymentReference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentValue: 0,
      payments: [],
      paymentNumber: "",
      paymentNotificationDetails: [],
      paymentDetails: [],
      invoiceDetails: [],
      organizationId: 0,
      user: "",
      isVggRole: false,
      invoiceModalData: false,
      logo: "",
      invoiceContent: {},
      currency: "",
      shareEmail: "",
      actions: [
        {
          name: "Share",
          icon: "fa-upload",
          action: this.handleShareAction,
          width: "6.5rem",
        },
        {
          name: "Print",
          icon: "fa-print",
          action: () => this.downloadInvoice(true),
          width: "6rem",
        },
        {
          name: "Mobile Print",
          icon: "fa-print",
          action: () => appHelpers.mobilePrintAction("print-panel"),
        },
        {
          name: "Download",
          icon: "fa-download",
          action: () => this.downloadInvoice(false),
          width: "8rem",
        },
      ],
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";
    const { user } = this.props;
    this.props.dispatch(commonActions.setPeriodPickerVisibility(false));
    const { agencyDetails } = this.state;
    //get route the user is coming from
    let previousPath = this.props.history.location.state.from;
    this.setState({ logo: this.props.settings.setLogo });
    this.setState({
      isVggRole: user.vggRole === appConstants.ROLES.VGG_ADMIN ? true : false,
      organizationName: agencyDetails ? agencyDetails.name : user.organization,
    });
    //Everytime a user with a VGG Role vists this page, the user must select an agency
    //agencyDetails===undefined prevents redirect from happening again once an agency is selected
    if (
      user.vggRole === appConstants.ROLES.VGG_ADMIN &&
      agencyDetails === undefined
    ) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      this.getPaymentChannels();
    }
  }

  componentDidUpdate(prevProps) {
    const { apiReducer, alertComponentsReducer } = this.props;
    if (this.props.settings.setLogo !== prevProps.settings.setLogo) {
      this.setState({
        logo: this.props.settings.setLogo,
      });
    }

    if (this.props.states !== prevProps.states) {
      this.setState({
        states: this.props.states.data,
      });
    }

    if (apiReducer.currencies !== prevProps.apiReducer.currencies) {
      this.setState({
        currencies: apiReducer.currencies,
      });
    }

    if (
      apiReducer.revenuesByOrganization !==
      prevProps.apiReducer.revenuesByOrganization
    ) {
      this.setState({
        revenues: apiReducer.revenuesByOrganization,
      });
    }
    if (apiReducer.subRevenues !== prevProps.apiReducer.subRevenues) {
      this.setState({
        subRevenues: apiReducer.subRevenues,
      });
    }

    if (
      apiReducer.locationsByOrganization !==
      prevProps.apiReducer.locationsByOrganization
    ) {
      this.setState({
        locations: apiReducer.locationsByOrganization,
      });
    }

    if (apiReducer.customers !== prevProps.apiReducer.customers) {
      this.setState({
        customers: apiReducer.customers,
      });
    }

    if (apiReducer.invoiceSettings !== prevProps.apiReducer.invoiceSettings) {
      this.setState({
        invoiceSettings: apiReducer.invoiceSettings.settings,
      });
    }

    if (
      alertComponentsReducer.displayCreatedInvoice.show !==
      prevProps.alertComponentsReducer.displayCreatedInvoice.show
    ) {
      if (!alertComponentsReducer.displayCreatedInvoice.show) {
        this.setState({
          clickedRow: "",
          invoiceNumber: "",
        });
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    let pathState = props.location.state;
    if (pathState) {
      if (
        props.location.state.selectedAgencyDetails !==
        state.selectedAgencyDetails
      ) {
        return {
          agencyDetails: props.location.state.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  // HANDLERS
  handleSelectChange = (payload, attribute) => {
    this.setState({ [attribute]: payload });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  clearFields = () => {
    const { user } = this.props;
    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      this.setState({
        organizationId: "",
      });
    }
    this.setState({
      paymentNotificationDetails: [],
      paymentDetails: [],
      invoiceDetails: [],
    });
  };


  handleShareAction = () => {
    this.props.dispatch(
      modalActions.displayShareInvoiceModal({
        show: true,
      })
    );
  };

  handleShareEmailChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onkeydownEnterShareInvoice = (e) => {
    if (e.key === "Enter") {
      this.shareInvoice();
    }
  };

  shareInvoice = () => {
    let { dispatch } = this.props;
    let { invoiceModalData, shareEmail } = this.state;

    let payload = {
      invoiceNumber: invoiceModalData.invoiceNumber ,
      recipientEmail: shareEmail,
    };

    dispatch(apiActions.shareInvoice.call(this, payload, source.token));
  };

  dismissModals = () => {
    setTimeout(() => {
      this.props.dispatch(modalActions.displayGeneralAlert({ show: false }));
    }, 5000);
  };

  invoiceSheetMouseOver = (e) => {
    const target = e.currentTarget.lastElementChild;
    target.classList.remove("inv-copy-wrapper-hide");
    target.classList.add("inv-copy-wrapper-show");
  };

  invoiceSheetMouseOut = (e) => {
    const target = e.currentTarget.lastElementChild;
    target.classList.add("inv-copy-wrapper-hide");
    target.classList.remove("inv-copy-wrapper-show");
  };

  downloadInvoice = (print) => {
    const { dispatch } = this.props;
    const { invoiceModalData } = this.state;
    let payload = { invoiceNumber: invoiceModalData.invoiceNumber };
    dispatch(apiActions.downloadSearchedInvoice(print, payload, source.token));
  };
  // Api calls
  getPaymentChannels = () => {
    let { dispatch } = this.props;
    // const{agencyDetails}=this.state;
    dispatch(alertActions.startRequest());

    // let payload = {
    //     organizationId: user.vggRole === appConstants.ROLES.VGG_ADMIN
    //     ? agencyDetails && agencyDetails.id
    //     : user.organizationId,
    // };
    commonService.getPaymentChannels(source.token).then((res) => {
      let response = res.response;
      if (response && response.data) {
        dispatch(alertActions.stopRequest()); //This is put here because the customer endpoint takes a while to return response data
        //even after a 200 response is returned.
      }
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          payments: response.data,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  searchPaymentReference = () => {
    const { dispatch, user } = this.props;
    const { paymentValue, agencyDetails, paymentNumber } = this.state;

    let payload = {
      paymentReferenceNo: paymentNumber,
      paymentChannelCode: paymentValue ? paymentValue.code : 0,
      organizationId:
        user.vggRole === appConstants.ROLES.VGG_ADMIN
          ? agencyDetails && agencyDetails.id
          : user.organizationId,
    };

    dispatch(alertActions.startRequest());
    paymentsService
      .getPaymentDetailsByRefNo(payload, source.token)
      .then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({
            paymentNotificationDetails: response.data.paymentNotifications,
            paymentDetails: response.data.paymentReferenceDetails,
            invoiceDetails: response.data.invoiceDetails,
            invoiceContent: response.data.invoiceDetails,
          });
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
          if (typeof response === "string") {
            dispatch(alertActions.error(response));
          } else {
            dispatch(alertActions.error(response.message));
          }
          this.clearFields();
        }
      });
  };

 

  viewInvoice = (e, invoiceDetails) => {
    console.log(invoiceDetails, "ind");
    if (invoiceDetails) {
        this.setState(
          {
            invoiceModalData: {...invoiceDetails},
          },
          () => {
            this.props.dispatch(modalActions.displayCreatedInvoice({ show: true }));
          }
        );
    }
};


  hideAlertView = () => {
    // this.setState({ showAlertModal: response });
    const { dispatch } = this.props;
    dispatch(modalActions.displayCreatedInvoice({ show: false }));
    this.setState({ showAlertModal: false });
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(modalActions.displayGeneralAlert({ show: false }));
    dispatch(modalActions.displayCreatedInvoice({ show: false }));
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      requesting,
      payments,
      paymentValue,
      paymentNumber,
      paymentNotificationDetails,
      paymentDetails,
      invoiceDetails,
      isVggRole,
      clickedRow,
      invoiceModalData,
      logo,
      invoiceContent,
      currency,
    } = this.state;
    let printInvDate =
    invoiceModalData.invoiceDate !== null || undefined
      ? moment(invoiceModalData.invoiceDate).format("YYYY-MM-DD")
      : "";
  let printDate = moment().format("YYYY-MM-DD");
    let isGotten = Object.keys(invoiceDetails).length > 0 ? true : false;
    let isPayment = Object.keys(paymentDetails).length > 0 ? true : false;
    const { alertComponentsReducer, dispatch, user } = this.props;
    const displayCreatedInvoice = alertComponentsReducer.displayCreatedInvoice;
    const shareInvoiceModal = alertComponentsReducer.shareInvoiceModal;
    console.log(invoiceDetails, "deatils");
    console.log(invoiceContent, "content");
    console.log(invoiceModalData, "invoiceData");

    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle={"Search With Payment Reference"}
        actions={
          isVggRole === true ? (
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className="big-action-btn btn-rounded mr-2"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          ) : (
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          )
        }
      >
        <Row>
          <Col md={"4"}>
            <FormGroup>
              <Label>Payment Channel</Label>
              <SelectList
                placeholder={"Select..."}
                data={payments}
                valueKey={"id"}
                textField={"name"}
                selectValue={paymentValue}
                onValueChange={(event) => {
                  this.handleSelectChange(event, "paymentValue");
                }}
              />
            </FormGroup>
          </Col>
          <Col md={"4"}>
            <FormGroup>
              <Label>Payment Reference Number</Label>
              <Input
                placeholder="Enter here..."
                type="text"
                name="paymentNumber"
                id="paymentNumber"
                onChange={(e) => this.handleChange(e, "paymentNumber")}
                value={paymentNumber}
              />
            </FormGroup>
          </Col>
          <Col md={"3"}>
            <Button
              //   size="lg"
              color="success"
              className="btn-rounded px-4 text-white mt-4"
              onClick={() => this.searchPaymentReference()}
              disabled={requesting}
            >
              {requesting ? `Searching...` : `Search`}
            </Button>
          </Col>
        </Row>

        <hr />
        <br />
        <React.Fragment>
          <h5>
            <b>Payment Reference Details</b>
          </h5>
          <Table responsive={true} style={{ width: "70%" }}>
            <tr>
              <th className="paymentTable">Payment Reference No:</th>
              <td className="paymentTable">
                {isPayment && paymentDetails.paymentReferenceNumber}
              </td>
            </tr>
            <tr>
              <th className="paymentTable">Amount:</th>
              <td className="paymentTable">
                {isPayment && paymentDetails.amount}
              </td>
            </tr>
            <tr>
              <th className="paymentTable">Status:</th>
              <td className="paymentTable">
                {isPayment && paymentDetails.status}
              </td>
            </tr>
            <tr>
              <th className="paymentTable">OrderId(Invoice No):</th>
              <td className="paymentTable">
                {isPayment && paymentDetails.invoiceNumber}
              </td>
            </tr>
            <tr>
              <th className="paymentTable">Payment Date:</th>
              {paymentDetails.paymentDate === null ? (
                <td className="paymentTable">
                  {isPayment && paymentDetails.paymentDate}
                </td>
              ) : (
                <td className="paymentTable">
                  {isPayment &&
                    moment(paymentDetails.paymentDate).format("YYYY-MM-DD")}
                </td>
              )}
            </tr>
          </Table>

          <h3>
            <b>Payment Notification</b>
          </h3>
          {paymentNotificationDetails.length === 0 && (
            <EmptyRecordMessage message={"No record match your search"} />
          )}
          {paymentNotificationDetails.length > 0 && (
            <Row style={{ marginTop: "-3%" }}>
              <Col md={"12"}>
                <TableStructure
                  fetchMore={this.searchPaymentReference}
                  apiData={paymentNotificationDetails}
                  user={this.props.user}
                />
              </Col>
            </Row>
          )}
        </React.Fragment>
        <br />
        <br />

        <div style={{ marginTop: "-7%" }}>
          <h3>
            <b>Invoice Details</b>
          </h3>
          <Table responsive={true} style={{ width: "70%" }}>
            <tr>
              <th className="paymentTable">Invoice No:</th>
              <td
                className="paymentTable"
                onClick={(e) => this.viewInvoice(e, invoiceDetails)}
                style={{ cursor: "pointer" }}
              >
                {isGotten && invoiceDetails.invoiceNumber}
              </td>
            </tr>
            <tr>
              <th className="paymentTable">Organization:</th>
              <td className="paymentTable">
                {isGotten && invoiceDetails.organization}
              </td>
            </tr>
            <tr>
              <th className="paymentTable">Invoice Amount:</th>
              <td className="paymentTable">
                {isGotten && invoiceDetails.invoiceAmount}
              </td>
            </tr>
            <tr>
              <th className="paymentTable">Amount Paid:</th>
              <td className="paymentTable">
                {isGotten && invoiceDetails.amountPaid}
              </td>
            </tr>
            <tr>
              <th className="paymentTable">Created Date:</th>
              <td className="paymentTable">
                {isGotten &&
                  moment(invoiceDetails.dateCreated).format("YYYY-MM-DD")}
              </td>
              {/* <td className="paymentTable">{isGotten && paymentDetails.dateCreated}</td> */}
            </tr>
            <tr>
              <th className="paymentTable">Revenue:</th>
              <td className="paymentTable">
                {isGotten && invoiceDetails.revenue}
              </td>
            </tr>
            <tr>
              <th className="paymentTable">Customer:</th>
              <td className="paymentTable">
                {isGotten && invoiceDetails.customer}
              </td>
            </tr>
          </Table>
        </div>

        <div id="print-panel" style={{ display: "none" }}>
            <PrintInvoice
              invoiceNumber={invoiceModalData.invoiceNumber}
              clientName={invoiceModalData.customer}
              paymentStatus={invoiceModalData.status}
              invoiceDate={printInvDate}
              issuerName={invoiceModalData.createdByName}
              printDate={printDate}
              bills={invoiceModalData.bills}
              amountString={invoiceModalData.amount}
              logo={logo}
              rrr={invoiceModalData.remitaRetrievalReference}
              currencySign={
                currency === 840 ? (
                  <span>&#36;</span>
                ) : currency === 978 ? (
                  <span>&#163;</span>
                ) : (
                  <span>&#8358;</span>
                )
              }
              decimalPrecision={invoiceModalData.decimalPrecisions}
            />
          </div>
        {displayCreatedInvoice.show && (
          <CreatedInvoiceFullScreenModal
            displayCreatedInvoice={displayCreatedInvoice}
            invoiceSheetActions={pageHelpers.getInvoiceSheetActions.call(
              this,
              invoiceModalData
            )}
            requesting={requesting}
            dispatch={dispatch}
            renderInvoiceView={pageHelpers.renderInvoiceView.call(
              this,
              invoiceModalData
            )}
          />
        )}

{shareInvoiceModal.show && (
          <ShareInvoiceFullScreenModal
            displayShareInvoice={shareInvoiceModal}
            dispatch={this.props.dispatch}
            handleShareEmailChange={this.handleShareEmailChange}
            shareInvoice={this.shareInvoice}
            onkeydownEnterShareInvoice={this.onkeydownEnterShareInvoice}
            isRequesting={this.props.isRequesting}
            requesting={requesting}
          />
        )}
                  {this.props.displayGeneralAlert.show && <GeneralAlert />}

      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    modal,
    alert,
    progress,
    user,
    requesting,
    apiReducer,
    alertComponentsReducer,
    settings,
    displayGeneralAlert,
  } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
    apiReducer,
    alertComponentsReducer,
    settings,
    displayGeneralAlert,
  };
};

export default connect(mapStateToProps)(PaymentReference);
