import React, { Component } from "react";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";
import { connect } from "react-redux";
import { FaPen } from "react-icons/fa";
import ContentLayout from "../../../components/ContentLayout";
import CheckboxGrid from "../../../components/CheckboxGrid";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { appHelpers } from "../../../_helpers";
import { alertActions } from "../../../_actions";
import { settlementSplitService } from "../../../_services";
import { appConstants } from "../../../_constants";
import axios from "axios";

let source;

const Fields = ({ label, value }) => {
  return (
    <div>
      <div>{label}</div>
      <h5 className="font-weight-bold">{value ? value : "-"}</h5>
    </div>
  );
};

class ReviewSettlementSplit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {},
    };

    source = axios.CancelToken.source();
  }

  static getDerivedStateFromProps(props, state) {
    let pathState = props.location.state;

    if (pathState) {
      /**
       * @description pathState is obtained from /add-settlemnt-split route
       */

      if (props.location.state.payload !== state.payload) {
        return {
          payload: props.location.state.payload,
        };
      }
    }

    return null;
  }

  createSettlementSplitApproval = () => {
    const { dispatch } = this.props;
    const { payload } = this.state;
    const {
      agencyId,
      splitType,
      settlementAccountRevenueLinesData,
      settlementAccounts,
    } = payload;

    //parse Account details to API required payload format
    let formattedSettlementAccounts = settlementAccounts.map(
      ({ currency, accountNumber, thirdPartyShare, bank}) => {
        return {
          accountNumber,
          bankId: bank ? bank.id : 0,
          currencyId: currency ? currency.id : 0,
          thirdPartyShare,
        };
      }
    );

    //parse revenueLines to API required payload format
    let revenueLines = [];
    settlementAccountRevenueLinesData.map(({ revenueId, activated }) => {
      if (activated) {
        revenueLines.push(revenueId);
        return revenueId;
      }
      return revenueId;
    });

    let requestPayload = {
      splitType,
      revenueIds: revenueLines,
      agencyId,
      settlementAccounts: [...formattedSettlementAccounts],
    };

    dispatch(alertActions.startRequest());
    settlementSplitService.createSettlementSplitApproval(requestPayload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());

      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        //agencyDetails is passed into the route because
        //some of its values are required in the next route
        this.props.history.push({
          pathname: "/view-settlement-splits",
          state: {
            from: "/settings-settlements",
            agencyDetails: { id: requestPayload.agencyId }, //required for VGG Admin
          },
        });

        dispatch(alertActions.success(response.message));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(response.message));
      }
    });
  };

  handleEditReview = () => {
    const { payload } = this.state;
    this.props.history.push({
      pathname: "/add-settlement-split",
      state: {
        agencyDetails: { id: payload.agencyId },
        settlementSplitReviewDetails: payload,
      },
    });
  };

     
  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
  }

  render() {
    const { payload } = this.state;
    const {
      splitType,
      settlementAccountRevenueLinesData,
      settlementAccounts,
      splitTypes,
    } = payload;
    const { requesting } = this.props;

    return (
      <div>
        <ContentLayout
          requesting={requesting}
          pageTitle={`Review Settlement Split`}
          actions={
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => this.handleEditReview()}
              >
                <FaPen className={"icon-margin"} />
                Edit
              </Button>
            </div>
          }
        >
          <h2 style={{ fontWeight: "700" }}>Split Type</h2>
          <hr />

          <FormGroup tag="fieldset" className="my-5">
            {splitTypes &&
              splitTypes.map((type) => {
                return (
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        checked={splitType === type.id}
                        style={{ width: "30px" }}
                        onClick={(e) =>
                          this.handleRadioButtonChange(e, "splitType", type.id)
                        }
                        disabled
                      />
                      {type.name}
                    </Label>
                  </FormGroup>
                );
              })}
          </FormGroup>
          {splitTypes.length === 0 && (
            <EmptyRecordMessage message={"No Split types found"} />
          )}

          {settlementAccountRevenueLinesData && (
            <CheckboxGrid
              title={"Select a revenue line"}
              label={""}
              displayCheckAllBoxes={false}
              data={settlementAccountRevenueLinesData}
              checkboxChange={(e) => this.checkboxChange(e, "revenueLines")}
              disableAllCheckboxes={true}
            />
          )}

          {settlementAccountRevenueLinesData.length === 0 && (
            <EmptyRecordMessage
              message={"No revenue lines mapped to this organization's account"}
            />
          )}

          <h2 className="mt-5" style={{ fontWeight: "700" }}>
            Third Party Account
          </h2>
          <hr style={{ height: "12px" }}></hr>

          <Container>
            {settlementAccounts &&
              settlementAccounts.map((account, index) => {
                return (
                  <Row
                    className="mb-2"
                    key={`Settlement Account Review${index}`}
                  >
                    <Col md="4" xs="12">
                      <Fields
                        label="Bank Name"
                        value={account ? account.bank.name : ""}
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <Fields
                        label="Account Number"
                        value={
                          account &&
                          account.accountNumber +
                            ` (${
                              account.accountVerificationDetails &&
                              account.accountVerificationDetails.accountName
                            })`
                        }
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <Fields
                        label="Currency"
                        value={account ? account.currency.name : ""}
                      />
                    </Col>
                    <Col md="4" xs="12">
                      <Fields
                        label={`Third party share of payment in
                      ${splitType === 1 ? " amount" : " %"}`}
                        value={
                          account &&
                          account.thirdPartyShare +
                            `${splitType === 1 ? " " : "%"}`
                        }
                      />
                    </Col>
                  </Row>
                );
              })}
          </Container>

          <div className="float-right mt-5">
            <Button
              outline
              className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              Cancel
            </Button>
            <Button
              color="success"
              className="btn-sm btn-rounded  mr-2 text-white"
              onClick={this.createSettlementSplitApproval}
              disabled={requesting}
            >
              Create Split
            </Button>
          </div>
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting } = state;
  return {
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(ReviewSettlementSplit);
