import React from "react";
import { Route, Redirect } from "react-router-dom";
import { store } from "../_store";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Object.keys(store.getState().user).length > 0 ? (
        <Component {...props} /> // authorised so return component
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        /> // not logged in so redirect to login page with the return url
      )
    }
  />
);

export default PrivateRoute;
