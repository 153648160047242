import React from "react";
import {Table, Badge} from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "react-toggle/style.css"; // for ES6 modules
import "./index.scss";
import Toggle from "react-toggle";


const AccountRecords = (props) => {
  const { accountRecords, editAccount,currentPage,
  fetchMore, totalCount, pageSize, handleSwitchChecked,
    showReviewAccountPage} = props;
  return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageSize={pageSize}
          totalCount={totalCount}
        //   downloadPdf={() => {
        //   downloadInvoiceAging(0, "pdf")
        // }}
        //   downloadExcel={() => downloadInvoiceAging(0, "excel")}
          target={
            <Table responsive={true} className={"table-main"}>
              <thead className={"th-head tracker-head"}>
              <tr>
                {/*<th> </th>*/}
                <th>Bank Name</th>
                <th>Account Number</th>
                <th>Account Name</th>
                <th>Account Type</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              {accountRecords.map((item, index) => {
                return (
                  <tr key={index} className={"table-row"} onClick={(e) => {item.status !== false &&  showReviewAccountPage(e, item) }}>
                    {/*<th>*/}
                    {/*  <Input*/}
                    {/*    style={{ position: "static", width: "40px" }}*/}
                    {/*    type="checkbox"*/}
                    {/*    id="checkbox"*/}
                    {/*    onChange={(e) => props.checkboxChange(e, item)}*/}
                    {/*  />*/}
                    {/*</th>*/}
                    <th>{item.bankName}</th>
                    <th>{item.accountNumber}</th>
                    <th>{item.accountName} <span className={`${item.isActive ? "active-record" : null}`}>{item.isActive ? "Active" : null}</span></th>
                    <th>{item.accountTypeName}</th>
                    {/*<th className={"edit-accounts"} onClick={(e) => editAccount(e, item)}>Edit</th>*/}
                    <th   style={{ padding: "none" }}>
                      {item.status !== false &&(
                        <Badge onClick={(e) => editAccount(e, item)}
                               className={" edit-accounts badge-spacing"}
                               size="xl" pill>Edit</Badge>)}
                      {
                        <label className={"toggle-label"}>
                          <Toggle
                            checked={item.status}
                            onChange={(e) => handleSwitchChecked(e, item)} />
                        </label>
                      }</th>
                  </tr>
                );
              })}
              </tbody>
            </Table>
          }
        />
      </div>
  );
};

export default AccountRecords;
