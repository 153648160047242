import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function showRightSidebar(state = initialState.showRightSidebar, action) {

    switch (action.type) {
        case appConstants.SHOW_RIGHT_SIDEBAR:
            return action.show;
        default:
            return state;
    }
}
