import {appConstants} from '../_constants';
import {initialState} from "../_store";

export function countries(state=initialState.countries, action) {
    switch (action.type) {
        case appConstants.COUNTRIES_SUCCESS:
            return action.countries;
        default:
            return state
    }
}
