import { alertConstants } from "../_constants";
import { initialState } from "../_store";

export function appRequesting(state = initialState.appRequesting, action) {
  switch (action.type) {
    case alertConstants.START_APP_REQUEST:
      return action.data;
    case alertConstants.STOP_APP_REQUEST:
      return action.data;
    default:
      return state;
  }
}
