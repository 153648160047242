import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function savedRoutes(state = initialState.savedRoutes, action) {
  switch (action.type) {
    case appConstants.GET_ROUTES:
      return { ...state, routes: action.routes };
    default:
      return state;
  }
}
