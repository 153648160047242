import React, { useEffect } from "react";
import "./payment.css";
import { Row, Col, FormGroup, Button, Container, Table } from "reactstrap";
import { FaCheckCircle, FaLock, FaTimes} from "react-icons/fa";
import { appConstants } from "../../_constants";
import moment from "moment";
// import EPIBSLogo from "../../assets/images/ebips-assets/ebips-logo.svg";
import EPIBSLogo from "../../assets/images/ebips-assets/ebips-logo.png";
import axios from "axios";
import { connect } from "react-redux";
import { paymentsService } from "../../_services";
import { alertActions } from "../../_actions";
import { LoaderMobile } from "./loading";
import { RenderAlertMobileModal } from "../../components/MobileModal";
import { appHelpers } from "../../_helpers";
import { FaCopy } from "react-icons/fa";



let source;

class PaymentSecurity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequesting: false,
      error: false,
      componentErrorMessages: [],
      componentError: false,
      invoiceNumber: "",
      code: "",
      invoiceDetails: {},
      validResponse: null,
      showAlertModal: false,
      alertIcon: "",
      alertMessage: "",
      copySuccess: false
    };
    source = axios.CancelToken.source();
    this.handleCopyClick = this.handleCopyClick.bind(this);
  }

  componentDidMount() {
    this.validateInvoiceQR();
  }

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  copyToClipboard = (textToCopy) => {
    const el = document.createElement("textarea");
    el.value = textToCopy;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  
    // Set copySuccess to true and then reset it after 2.5 seconds
    this.setState({ copySuccess: true });
    setTimeout(() => this.setState({ copySuccess: false }), 2500);
  };

  // handleCopyClick = () => {
  //   // Call copyToClipboard with invoiceNumber
  //   this.copyToClipboard(this.props.invoiceDetails?.invoiceNumber);
  // };

  handleCopyClick = () => {
    console.log(invoiceDetails, "det");
  // Check if invoiceDetails exists before copying
  const { invoiceDetails } = this.state;
  if (invoiceDetails && invoiceDetails.invoiceNumber) {
    this.copyToClipboard(invoiceDetails.invoiceNumber);
  }
};

  validateInvoiceQR = () => {
    let { dispatch, location } = this.props;

    const urlSearchParams = new URLSearchParams(location.search);
    const inv = urlSearchParams.get("inv");
    const code = urlSearchParams.get("code");

    let payload = {
      inv: inv,
      code: code,
    };

    dispatch(alertActions.startRequest());
    paymentsService.validateQR(payload).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          invoiceDetails: response,
          validResponse: true,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response, true);
      }
    });
  };

  displayErrorResponseAlert = (response, action, query) => {
    const { dispatch } = this.props;
    if (response === "Please Check your internet connectivity") {
      dispatch(alertActions.error(response));
    } else if (typeof response === "string") {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={30} color={appConstants.DANGER_COLOR} />,
          alertMessage: response,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    } else {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={20} color={appConstants.DANGER_COLOR} />,
          alertMessage: response ? response.message : "",
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    }
  };

  handlePaymentClick = () => {
    const { invoiceDetails } = this.state;
    if (invoiceDetails && invoiceDetails.paymentLink) {
      window.open(invoiceDetails.paymentLink, '_blank');
    } else {
      console.error("Payment link is not available.");
    }
  };
  render() {
    const { userData, match } = this.props;
    const { invoiceDetails, validResponse, showAlertModal, alertIcon, alertMessage, copySuccess } = this.state;
    const { requesting } = this.props;
    // let logo = userData.logoUrl ? userData.logoUrl : EPIBSLogo;
console.log(userData, "userData");
    return (
      <>
        {requesting && <LoaderMobile />}

        <div className="stract">
          {validResponse && (
            <div className="card-payment">
              <Row>
                <Col sm={12} lg={6}>
                  <Row className="agency-logo">
                    <div>
                      <img src={invoiceDetails.logoUrl} alt="logo" />
                    </div>
                  </Row>
                  <Row>
                    <Col>
                      <p className="invoice-auth">Invoice Authenticated</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="invoice-details">
                        {invoiceDetails.message}
                        <br />
                        {/* Please proceed with the payment. */}
                      </p>
                    </Col>
                  </Row>
                  {/* {validResponse && ( */}
                  <Row className="payment-logo">
                    <Col sm={12} lg={12} md={12} className="center-logo">
                      <FaCheckCircle
                        size={100}
                        color={appConstants.SUCCESS_COLOR}
                      />
                    </Col>
                  </Row>
                  <div style={{marginBottom: "30px"}}>
                  <Row
                    style={{
                      backgroundColor: "#FAFAFA",
                      // padding: "50px",
                      borderRadius: "10px",
                      width: "330px",
                      height: "70px",
                      margin: "auto",
                      padding:"8px"
                    }}
                  >
                    <Col
                      xs="4"
                      className="custom-stract"
                    >
                      <div
                       className="custom-column"
                      >
                        Invoice Date:
                      </div>
                      <div
                        className="down-column"

                      >
                         {moment(invoiceDetails?.invoiceDate).format(
                              "YYYY-MM-DD"
                            )}
                      </div>
                    </Col>
                    <Col
                      xs="4"
                      className="custom-container"
                    >
                      <div
                        className="custom-column"

                      >
                        Invoice No:
                      </div>
                      <div
                        className="down-column"
                      >
                        {invoiceDetails?.invoiceNumber}
                        {copySuccess && <span style={{fontSize: "10px", fontWeight: "bold"}} className="tooltips">Copied!</span>}
                      </div>
                      <FaCopy onClick={this.handleCopyClick} style={{ cursor: "pointer" }} color="green" />
                    </Col>
                    <Col
                      xs="4"
                      className="custom-container"
                    >
                      <div
                        className="custom-column"
                      >
                        Amount:
                      </div>
                      <div
                      className="down-column"

                      >
                        {appHelpers.numberWithCommasOnly(invoiceDetails?.amountString)}
                      </div>
                    </Col>
                  </Row>
                  </div>
                  {invoiceDetails.deleted === false && 
                    invoiceDetails.agencyId === 20 && invoiceDetails.amountPaid !==
                      invoiceDetails.invoiceAmount && (
                      <Row style={{ margin: "20px", justifyContent: "center" }}>
                        <Col lg={12} sm={12} className="center-button">
                          <button className="payment-button" onClick={this.handlePaymentClick}>
                            Make Payment
                          </button>
                        </Col>
                      </Row>
                    )}
                  {invoiceDetails.deleted === false &&
                    invoiceDetails.agencyId === 20 && invoiceDetails.amountPaid !==
                      invoiceDetails.invoiceAmount && (
                      <Row className="payment-status">
                        <Col className="center-content">
                          <FaLock color="#BABABA" />
                          <p className="payment-secure">Payment secured</p>
                        </Col>
                      </Row>
                    )}
                </Col>
              </Row>
            </div>
          )}
        </div>
        {showAlertModal && (
          <RenderAlertMobileModal
            message={alertMessage}
            icon={alertIcon}
            showAlertModal={showAlertModal}
            hideAlertView={this.hideAlertView}
          />
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  const { alert, user, requesting, baseRoute, userData, appRequesting } = state;
  return {
    alert,
    user,
    requesting,
    baseRoute,
    userData,
    appRequesting,
  };
}
export default connect(mapStateToProps)(PaymentSecurity);
