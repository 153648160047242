import React, { Component } from "react";
import { Table, Badge } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "./index.scss";
import Toggle from "react-toggle";


const Records = ( props) => {
  const {tableData, showUserMapping, handleSwitchChecked} = props;

  return (
    <Table responsive={true} style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer"}}>
      <thead style={{ backgroundColor: "#D9F7EB", color: "#000" }}>
        <tr>
          <th style={{ padding: "none" }}>Workflow Name</th>
          <th style={{ padding: "none" }}>Username</th>
          <th style={{ padding: "none" }}>Role</th>
          <th style={{ padding: "none" }}>Organization</th>
          <th style={{ padding: "none" }}>Process</th>
          <th style={{ padding: "none" }}>Action</th>
          {/*<th style={{ padding: "none"}}></th>*/}
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.map((item,index) => {
          return (
            <tr key={index} onClick={(e) => showUserMapping(e, item)}  className={"table-row"}>
              <th style={{ padding: "none" }}>{item.name}</th>
              <th style={{ padding: "none" }}>{item.userName}</th>
              <th style={{ padding: "none" }}>{item.roleName}</th>
              <th style={{ padding: "none" }}>{item.agencyName}</th>
              <th style={{ padding: "none" }}>{item.process}</th>
              <th style={{ padding: "none" }}>{item.status !== false &&(<Badge
              className={"badge-spacing table-row"}
              size="xl" pill>View</Badge>)}{
                <label className={"toggle-label"}>
                  <Toggle
                      defaultChecked={item.status}
                      checked={item.status}
                      onChange={(e) => handleSwitchChecked(e, item)} />
                </label>
                }</th>        
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};


export default class TableStructure extends Component {
  render() {
    let {
      tableData,
      currentPage,
      fetchMoreRecords,
      pageSize,
      totalCount,
      showUserMapping,
      handleSwitchChecked
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : ((pageSize * (currentPage - 1)) + 1);
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMoreRecords}
          pageSize={pageSize}
          totalCount={totalCount}
          target={<Records tableData={tableData}   
          pageIndex={pageIndex} showUserMapping={showUserMapping} handleSwitchChecked={handleSwitchChecked}/>}
        />
      </div>
    );
  }
}
