import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import {
  FaArrowLeft,
  FaTimes,
  FaCheckCircle,
  FaBold,
  FaItalic,
  FaTextHeight,
  FaAlignLeft,
  FaPaperclip,
  FaImage,
  FaVideo,
} from "react-icons/fa";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import { appHelpers } from "../../../_helpers";
import axios from "axios";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { supportService } from "../../../_services";
import { alertActions, modalActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import "./index.scss";
import moment from "moment";
import Alert from "../../../components/Alert";
import FullScreenModal from "../../../components/FullScreenModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

let source;
const presentDate = new Date();

class InvoiceAgingConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      agingDetails: [],
      mildlyDue: 0,
      due: 0,
      overDue: 0,
      organizationId: 0,
      viewDialogOpen: false,
      showAlertModal: false,
      alertIcon: "",
      alertMessage: "",
      componentErrorMessages: [],
      isBold: false,
      isItalic: false,
      isAlign: false,
      message: "",
      editorValue: "",
      notificationEmails: "",
      autoNotify: false,

    };

    source = axios.CancelToken.source();
  }

  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;

    if (pathState) {
      if (
        props.location.state.selectedAgencyDetails !==
        state.selectedAgencyDetails
      ) {
        return {
          agencyDetails: props.location.state.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    this.routeUserBasedOnRole();
  }

  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { agencyDetails } = this.state;

    //get route the user is coming from
    let previousPath = this.props.history.location.state.from;

    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    //Everytime a user with a VGG Role vists this page, the user must select an agency
    //agencyDetails===undefined prevents redirect from happening again once an agency is selected
    if (vggAdminRole && agencyDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is already selected, set the following states
      this.setState({
        isVggRole: vggAdminRole ? true : false,
        organizationName: agencyDetails
          ? agencyDetails.name
          : user.organization,
        organizationId: vggAdminRole
          ? agencyDetails && agencyDetails.id
          : user.organizationId,
      });
    }
  };

  handleChange = (event, attribute) => {
    this.setState({ [attribute]: event.target.value });
  };

  handleFormatChange = (value) => {
    this.setState({ editorValue: value });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      autoNotify: !prevState.autoNotify, // Toggle the checkbox state
    }));
  };

  validateInput = () => {
    let _errs_ = [];
    let formValid = true;
    if (this.state.mildlyDue === 0 || this.state.mildlyDue <= 0) {
      _errs_["mildlyDue"] = "Please input a valid mildy due value";
      formValid = false;
    }

    if (this.state.due === 0 || this.state.due <= 0) {
      _errs_["due"] = "Please input a valid due value";
      formValid = false;
    }
    if (this.state.overDue === 0 || this.state.overDue <= 0) {
      _errs_["overDue"] = "Please input a valid overdue value";
      formValid = false;
    }
    if (this.state.editorValue === "") {
        _errs_["editorValue"] = "Please include notification template";
        formValid = false;
      }
    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  saveInvoiceAgingDetails = () => {
    let { dispatch } = this.props;
    const { mildlyDue, overDue, due, organizationId, editorValue, autoNotify, notificationEmails} = this.state;
    if (this.validateInput()) {
      let payload = {
        agencyId: organizationId,
        midlydue: mildlyDue,
        due: due,
        overdue: overDue,
        notificationEmails: notificationEmails,
        autoNotify: autoNotify,
        notificationTemplate: editorValue
      };

      dispatch(alertActions.startRequest());
      supportService.saveInvoiceAgingDetails(payload).then((res) => {
        let response = res.response;
        dispatch(alertActions.stopRequest());
        // if (res.status === appConstants.SUCCESS_RESPONSE) {
        //   this.setState({
        //     agingDetails: response.data,
        //   });
        // } else if (res.status === appConstants.ERROR_RESPONSE) {
        //   this.setState({
        //     agingDetails: [],
        //   });
        // }
        if (res.status === appConstants.SUCCESS_RESPONSE) {
            this.setState(
              {
                showAlertModal: true,
                alertMessage: "Configuration successfully saved",
                alertIcon: (
                  <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
                ),
              },
              () => {
                appHelpers.dismissModal.call(this, "showAlertModal");
                this.handleDialogClose();
              }
            );
          } else if (res.status === appConstants.ERROR_RESPONSE) {
            this.setState(
              {
                showAlertModal: true,
                alertMessage: res.response,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
              },
              () => {
                appHelpers.dismissModal.call(this, "showAlertModal");
                // this.handleDialogClose();
              }
            );
          }
      });
    }
  };

  handleDialogClose = () => {
    this.setState({
        due: 0,
        overDue: 0,
        mildlyDue: 0,
        organizationId: 0,
        notificationEmails: "",
        autoNotify: false,
        editorValue: "",
    });
    // window.location.reload();
    this.resetForm();
  };

  handleDialogClickOpen = () => {
    this.setState({
      viewDialogOpen: true,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };
 

  resetError() {
    this.props.dispatch(modalActions.clear());
    this.props.dispatch(alertActions.clear());
  }

  resetForm() {
    this.setState({
      mildlyDue: 0,
      overDue: 0,
      due: 0,
    });
    this.resetError();
    // window.location.reload();
  }

  renderAlertModal = (message, icon) => {
    let alertView = [];
    let count = 0;
    const { showAlertModal } = this.state;

    alertView.push(
      <FullScreenModal
        show={showAlertModal}
        key={count + 1}
        isInvoiceSheet={true}
        callback={this.hideAlertView}
        width={"35%"}
        top={"10%"}
      >
        <Alert icon={icon} message={message} />
      </FullScreenModal>
    );
    return alertView;
  };
  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }
  render() {
    const {
      agingDetails,
      overDue,
      mildlyDue,
      due,
      showAlertModal,
      alertIcon,
      alertMessage,
      viewDialogOpen,
      componentErrorMessages,
      isBold,
      isItalic,
      isAlign,
      message,
      notificationEmails,
      editorValue
    } = this.state;
    const { requesting } = this.props;
    const editorStyle = {
        height: '150px', 
      };
    return (
      <div>
        <ContentLayout
          requesting={this.props.requesting}
          pageTitle={"Invoice Aging Configuration"}
          actions={
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          }
        >
          <Row>
            <Col md="6" xs="12">
              <p>Use the provided fields to configure your invoice aging</p>
            </Col>
          </Row>
          <br />

          <Row>
            <Col md="3" xs="12">
              <FormGroup>
                <Label>Mildly Due Period(days)</Label>
                <Input
                  type="number"
                  name="mildlyDue"
                  value={mildlyDue}
                  onChange={(event) => this.handleChange(event, "mildlyDue")}
                  _errs_={componentErrorMessages.mildlyDue}
                />
              </FormGroup>
              {componentErrorMessages.mildlyDue && (
                <span className={"inputError"}>
                  {componentErrorMessages.mildlyDue}
                </span>
              )}
            </Col>
            <Col md="3" xs="12">
              <FormGroup>
                <Label>Due Period(days)</Label>
                <Input
                  type="number"
                  name="due"
                  value={due}
                  onChange={(event) => this.handleChange(event, "due")}
                  _errs_={componentErrorMessages.due}
                />
              </FormGroup>
              {componentErrorMessages.due && (
                <span className={"inputError"}>
                  {componentErrorMessages.due}
                </span>
              )}
            </Col>
            <Col md="3" xs="12">
              <FormGroup>
                <Label>Overdue Period(days)</Label>
                <Input
                  type="number"
                  name="overDue"
                  value={overDue}
                  onChange={(event) => this.handleChange(event, "overDue")}
                  _errs_={componentErrorMessages.overDue}
                />
              </FormGroup>
              {componentErrorMessages.overDue && (
                <span className={"inputError"}>
                  {componentErrorMessages.overDue}
                </span>
              )}
            </Col>
          </Row>

          <br />

          <Row>
            <Col md="6" xs="12">
              <p>
                <b>Notification Template</b>
              </p>
              {componentErrorMessages.editorValue && (
                <span className={"inputError"}>
                  {componentErrorMessages.editorValue}
                </span>
              )}
            </Col>
          </Row>

          <Row>
            <Col md="6" xs="12">
              <ReactQuill
                value={editorValue}
                onChange={this.handleFormatChange}
                style={editorStyle}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline"],
                    ["image", "code-block"],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" }
                      ],
                  ],
                }}
                theme="snow"
              />
            </Col>
          </Row>
          <br />
          <br />
          <br />



          <Row>
            <Col md="6" xs="12">
              <p>
                <b>Add Priority Email</b>(Optional)
              </p>
            </Col>
          </Row>

          <Row>
            <Col md="6" xs="12">
              <p>
                Note: Notifications will be sent to the provided email(s), based
                on your set conditions
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="4" xs="12">
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="text"
                  name="notificationEmails"
                  placeholder="Seperate email address..."
                    value={notificationEmails}
                    onChange={(event) => this.handleChange(event, "notificationEmails")}
                //   _errs_={componentErrorMessages.notificationEmails}
                />
              </FormGroup>
              {/* {componentErrorMessages.notificationEmails && (
                <span className={"inputError"}>
                  {componentErrorMessages.notificationEmails}
                </span>
              )} */}
            </Col>
          </Row>
          <Row>
            <Input
              style={{ position: "static", width: "20px", marginLeft: "14px" }}
              type="checkbox"
              id="checkbox"
              checked={this.state.autoNotify}
              onChange={this.handleCheckboxChange}
            />
            <label
              htmlFor="checkbox"
              style={{ marginLeft: "10px", marginTop: "10px" }}
            >
              Send notifications automatically
            </label>
          </Row>
          <br />
          <Row>
            <Col md="3" xs="12">
              <Button
                className="btn-rounded create-action-btn text-white w-75"
                onClick={() => this.saveInvoiceAgingDetails()}
                color={"success"}
                disabled={requesting}
              >
                Save
              </Button>
            </Col>
          </Row>
          <br />
          <br />

          {showAlertModal === true &&
            this.renderAlertModal(alertMessage, alertIcon)}
          <br />
          <br />
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, alert } = state;
  return {
    user,
    requesting,
    alert,
  };
};
export default connect(mapStateToProps)(InvoiceAgingConfiguration);
