import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

import "./index.scss";

export default function CookiesConsent({ setCookies, cookieName }) {
  return (
    <footer className="cookies-poilcy-footer">
      <p>This site uses cookies</p>
      <p>
        These cookies are used to collect information about how you interact. We
        use this information in order to improve and customize your browsing
        experience and for analytics and metrics. For information about our
        cookies, see our{" "}
        <Link to="/cookie-policy">
          <span>Cookie policy.</span>
        </Link>{" "}
        Read about our Privacy policy{" "}
        <Link to="/privacy-policy">
          <span>here.</span>{" "}
        </Link>
      </p>
      <div>
        <Button
          color="info"
          outline
          onClick={() => setCookies(cookieName, true, 365)}
        >
          <span>Accept</span>
        </Button>
      </div>
    </footer>
  );
}
