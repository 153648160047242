import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const customerService = {
  addCustomer,
  activateCustomer,
  deactivateCustomer,
  searchCustomer,
  getCustomerDetails,
  getCustomerStaff,
  addCustomerStaff,
  activateCustomerStaff,
  deactivateCustomerStaff,
  similarCustomerCheck,
  fetchCustomerCategories,
  mergeCustomer,
  getMergeCustomerDetails,
  unMergeCustomer,
};
function addCustomer(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Customer/CreateCustomerBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function activateCustomer(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Customer/ActivateCustomerBDA?CustomerId=${payload.customerId}`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function deactivateCustomer(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Customer/DeactivateCustomerBDA?CustomerId=${payload.customerId}`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchCustomer(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Customer/SearchCustomerBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getCustomerDetails(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Customer/GetCustomerDetailsBDA?CustomerId=${payload.customerId}&agencyId=${payload.agencyId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getCustomerStaff(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Customer/GetCustomerStaffsBDA?CustomerId=${payload.customerId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function addCustomerStaff(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Customer/AddCustomerStaffBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function activateCustomerStaff(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Customer/ActivateCustomerStaffBDA?CustomerStaffId=${payload.customerStaffId}`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function deactivateCustomerStaff(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Customer/DeactivateCustomerStaffBDA?CustomerStaffId=${payload.customerStaffId}`,
      payload
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function similarCustomerCheck(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Customer/SimilarCustomerCheckBDA?customerName=${payload.customerName}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function fetchCustomerCategories(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Customer/GetCategoriesBDA`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function mergeCustomer(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Customer/MergeCustomerBDA`,
      // `https://bda-new.test.vggdev.com/api/Customer/MergeCustomer`,
      payload
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getMergeCustomerDetails(payload, cancelToken) {
  return appHelpers
    .getRequest(
      // `https://bda-new.test.vggdev.com/api/Customer/GetMergeCustomerDetails?agencyId=${payload.agencyId}&parentCustomerId=${payload.parentCustomerId}`,
      `${appConstants.PAS_URL}/api/Customer/GetMergeCustomerDetailsBDA?agencyId=${payload.agencyId}&parentCustomerId=${payload.parentCustomerId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function unMergeCustomer(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Customer/UnmergeCustomerBDA`,
      // `https://bda-new.test.vggdev.com/api/Customer/UnmergeCustomer`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}
