import React from 'react';
import {Row, Col} from "reactstrap";
import SummaryCard from "../SummaryCard";

const Summary = ({invoicedTopValue, paidTopValue, voidedTopValue, countTopValue,
                     invoicedBottomValue, paidBottomValue, voidedBottomValue,
                     countBottomValue, marginTop}) => {
    return (
        <div style={{marginTop: marginTop}} className={"summary"}>
           <Row>
               <Col md={12}>
                   <h3 className={"mb-0"}>Summary</h3>
               </Col>
           </Row>
        <hr className={"mb-4"}/>
        <Row>
            <Col md={3}>
                <SummaryCard
                    cardTitle={"Amount Invoiced"}
                    bgColor={"#def5fd"}
                    topValue= { invoicedTopValue}
                    bottomValue={invoicedBottomValue}
                />
            </Col>
            <Col md={3}>
                <SummaryCard
                    cardTitle={"Amount Paid"}
                    bgColor={"#e3f8ef"}
                    topValue={ paidTopValue}
                    bottomValue={paidBottomValue}
                    />
            </Col>
            <Col md={3}>
                <SummaryCard
                    cardTitle={"Amount Voided"}
                    bgColor={"#fde4e0"}
                    topValue= {voidedTopValue }
                    bottomValue={voidedBottomValue}
                />
            </Col>
            <Col md={3}>
                <SummaryCard
                    cardTitle={"Count"}
                    bgColor={"#f6e9f3"}
                    topValue= {countTopValue }
                    bottomValue={countBottomValue}
                />
            </Col>
        </Row>
        </div>
    );
};

export default Summary;