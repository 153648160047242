import React from "react";
import { Row, Col, Container } from "reactstrap";
import moment from "moment";

const Fields = ({ label, value }) => {
  return (
    <div>
      <div>{label}</div>
      <h5 className="font-weight-bold">{value ? value : "-"}</h5>
    </div>
  );
};

const CustomerDetailsView = ({ customerDetails }) => {
  return (
    <Container>
      <Row className="mb-2">
        <Col md="3" xs="12">
          <Fields
            label="Cutomer Category"
            value={customerDetails ? customerDetails.categoryName : ""}
          />
        </Col>
        <Col md="3" xs="12">
          <Fields
            label="Type"
            value={customerDetails ? customerDetails.typeName : ""}
          />
        </Col>
        <Col md="3" xs="12">
          <Fields
            label="RC Number"
            value={customerDetails ? customerDetails.rcNumber : ""}
          />
        </Col>
        <Col md="3" xs="12">
          <Fields
            label="Debt Recovery"
            value={customerDetails && customerDetails.debtRecovery + "%"}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md="3" xs="12">
          <Fields
            label="Email Address"
            value={customerDetails ? customerDetails.email : ""}
          />
        </Col>
        <Col md="3" xs="12">
          <Fields
            label="Address"
            value={customerDetails ? customerDetails.address : ""}
          />
        </Col>
        <Col md="3" xs="12">
          <Fields
            label="Date of Incorporation"
            value={
              customerDetails &&
              moment(customerDetails.incorpDate).format("Do MMMM, YYYY")
            }
          />
        </Col>
        <Col md="3" xs="12">
          <Fields
            label="Account Code"
            value={customerDetails ? customerDetails.accountCode : ""}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerDetailsView;
