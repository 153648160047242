import React from "react";
import {connect} from "react-redux";
import Spinner from '../../assets/images/spinner.svg'
import "./index.scss";

export const AppProgressLoader = ({message, displayLoaderFullOverlay}) => (
  <div style={{ textAlign: "center", zIndex:  3500 }} className={`${displayLoaderFullOverlay ? "app-progress-full-overlay" : "app-progress-overlay"}`}>
      <div className={"app-progress-loading-icon"}>
        <img className="w-75" src={Spinner} alt="loading"/>
          <h5>{message}</h5>
      </div>
  </div>
)

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps)(AppProgressLoader);