import React, { Component } from "react";
import { Button, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import ContentLayout from "../../../components/ContentLayout";
import TableStructure from "./record";
import EmptyState from "../../../components/EmptyState";
import { appConstants } from "../../../_constants";
import { appHelpers } from "../../../_helpers";
import { settlementSplitService } from "../../../_services";
import { alertActions } from "../../../_actions";
import axios from "axios";

let source;

class SettlementSplits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settlementSplits: [],
      name: "",
      ain: "",
      customerCode: "",
      isVggRole: false,
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
    };

    source = axios.CancelToken.source();
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   */
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;

    if (pathState) {
      if (
        props.location.state.selectedAgencyDetails !==
        state.selectedAgencyDetails
      ) {
        return {
          agencyDetails: props.location.state.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    this.routeUserBasedOnRole();
  }

  /**
   * @description Route the user based on the role of the logged in user
   */
  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { agencyDetails } = this.state;

    //get route the user is coming from
    let previousPath = this.props.history.location.state.from;

    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    //Everytime a user with a VGG Role vists this page, the user must select an agency
    //agencyDetails===undefined prevents redirect from happening again once an agency is selected
    if (vggAdminRole && agencyDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is already selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationName: agencyDetails
            ? agencyDetails.name
            : user.organization,
          organizationId: vggAdminRole
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
        },
        () => this.searchSettlementSplits()
      );
    }
  };

  searchSettlementSplits = (pageNumber = 1) => {
    const { dispatch } = this.props;
    const { organizationId, pageSize } = this.state;

    let payload = {
      pageSize,
      from: pageNumber,
      parameter: {
        searchQuery: "",
        agencyId: organizationId,
      },
    };

    dispatch(alertActions.startRequest());
    settlementSplitService.searchSettlementSplits(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          currentPage: pageNumber === 0 ? 1 : pageNumber,
          settlementSplits: response.results,
          totalCount: response.totalCount,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.setState({
          settlementSplits: [],
          totalCount: 0,
        });
      }
    });
  };

  handleChange = (event, attribute) => {
    this.setState({ [attribute]: event.target.value });
  };

  /**
   * @param {String} pathname path to be routed to
   * @param {String} attribute name of the state attribute in new route
   * @param {*} value state to be passed into the new route
   */
  handleChangeRoute = (pathname, attribute, value) => {
    this.props.history.push({
      pathname,
      state: { agencyDetails: this.state.agencyDetails, [attribute]: value },
    });
  };

  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
  }
  
  render() {
    const {
      settlementSplits,
      isVggRole,
      organizationName,
      currentPage,
      pageSize,
      totalCount,
    } = this.state;
    const { requesting } = this.props;

    return (
      <ContentLayout
        pageTitle="Settlement Splits"
        requesting={requesting}
        actions={
          isVggRole && (
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className="btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          )
        }
      >
        {settlementSplits.length === 0 && (
          <EmptyState
            message={"No settlement split has been created yet"}
            actionText={"New settlement split"}
            addRecord={() => this.handleChangeRoute("/add-settlement-split")}
          />
        )}

        {settlementSplits.length > 0 && (
          <>
            <Row className="my-2 d-flex justify-content-between align-items-center">
              <h4 className="font-weight-bold">{organizationName}</h4>
              <Button
                className="btn-rounded mr-2 create-action-btn text-white"
                color={"success"}
                disabled={requesting}
                onClick={() => this.handleChangeRoute("/add-settlement-split")}
              >
                Add Settlement Split
              </Button>
            </Row>
            {/* <Label className="ml-2">{totalCount} Results</Label> */}
            <Row>
              <Col md={"12"}>
                <TableStructure
                  tableData={settlementSplits}
                  handleChangeRoute={this.handleChangeRoute}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  fetchMoreRecords={this.searchSettlementSplits}
                />
              </Col>
            </Row>
          </>
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting } = state;
  return {
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(SettlementSplits);
