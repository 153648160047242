import { combineReducers } from "redux";

//reducers
import settings from "./../redux/settings/reducer";
import { activity } from "./activity.reducer";
import { requesting } from "./requesting.reducer";
import { user } from "./user.reducer";
import { alert } from "./alert.reducer";
import { modal } from "./modal.reducer";
import { showPeriodPicker } from "./periodpickervisibility.reducer";
import { showWelcomeScreen } from "./welcomescreenvisibility.reducer";
import { dashboard } from "./dashboard.reducer";
import { showMiniSidebarDropdown } from "./showminisidebardropdown.reducer";
import { showNotification } from "./showNotification.reducer";
import { showRightSidebar } from "./showRightSidebar.reducer";
import { regions } from "./regions.reducer";
import { states } from "./state.reducer";
import { organizations } from "./organizations.reducer";
import { entities } from "./entityTypes.reducer";
import { countries } from "./countries.reducer";
import { baseRoute } from "./baseRoute.reducer";
import { displayLoaderFullOverlay } from "./displayLoaderFullOverlay.reducer";
import { displayGeneralAlert } from "./general-alert.reducer";
import { gatewayCode } from "./gatewayCode.reducer";
import { userData } from "./userData.reducer";
import { appRequesting } from "./app-requesting";
import apiReducer from "./api.reducer";
import alertComponentsReducer from "./alert-components-reducer";
import selfServiceReducer from "./self-service.reducer";
import { selectedLocationIds } from "./selectedLocationIds.reducer";
import { savedRoutes } from "./savedRoutes.reducer";

const appReducers = combineReducers({
  settings,
  activity,
  requesting,
  user,
  alert,
  modal,
  showPeriodPicker,
  showWelcomeScreen,
  dashboard,
  showMiniSidebarDropdown,
  showNotification,
  showRightSidebar,
  regions,
  states,
  organizations,
  entities,
  countries,
  baseRoute,
  displayLoaderFullOverlay,
  displayGeneralAlert,
  gatewayCode,
  userData,
  appRequesting,
  apiReducer,
  alertComponentsReducer,
  selfServiceReducer,
  selectedLocationIds,
  savedRoutes,
});

export default appReducers;
