import React, { Component } from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import moment from "moment";
import { FaArrowLeft } from "react-icons/fa";
import { DatePicker } from "../../../components/DatePicker";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import { commonService } from "../../../_services";
import { alertActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import SelectList from "../../../components/SelectList/selectList";
import { appHelpers } from "../../../_helpers";
import TableStructure from "./record";
import axios from "axios";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { FaTimes, FaCheckCircle } from "react-icons/fa";
import { RenderAlertModal } from "../../../components/AlertModal";

let source;

class InvoiceAgeAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      startDate: moment(new Date()),
      endDate: moment(new Date()),
      selectedStatus: 0,
      Status: [
        { name: "All", id: -1 },
        { name: "Midly due", id: 1 },
        { name: "Due", id: 2 },
        { name: "Overdue", id: 3 },
      ],
      dateTypes: [
        { name: "Created Date", id: 1 },
        { name: "Invoice Date", id: 2 },
      ],
      dateType: { name: "Created Date", id: 1 },
      revenues: [],
      customers: [],
      componentErrorMessages: [],
      currency: { id: 566, name: "Naira", code: "NGN" },
      locations: [],
      revenue: 0,
      location: 0,
      customer: 0,
      address: "",
      organizationId: 0,
      invoiceNumber: "",
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      invoiceAgingDetails: [],
      user: "",
      takeRecord: [],
      showAlertModal: false,
      invoiceNumbers: [""],
      agencies: [],
      isVggRole: false,
      precision: appConstants.DEFAULT_PRECISION,
    };

    source = axios.CancelToken.source();

  }

  /**
   *
   * @param {*} props
   * @param {*} state
   */
  static getDerivedStateFromProps(props, state) {
    //path state is from /select-agency route
    //path state will not exist if the user is NOT a VGG Admin because only
    //VGG admins are routed to the /select-agency page first

    let pathState = props.location.state;

    if (pathState) {
      if (
        props.location.state.selectedAgencyDetails !==
        state.selectedAgencyDetails
      ) {
        return {
          agencyDetails: props.location.state.selectedAgencyDetails,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    this.routeUserBasedOnRole();
  }

  /**
   * @description Route the user based on the role of the logged in user
   */
  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { agencyDetails } = this.state;

    //get route the user is coming from
    let previousPath = this.props.history.location.state.from;

    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    //Everytime a user with a VGG Role vists this page, the user must select an agency
    //agencyDetails===undefined prevents redirect from happening again once an agency is selected
    if (vggAdminRole && agencyDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is already selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationName: agencyDetails
            ? agencyDetails.name
            : user.organization,
          organizationId: vggAdminRole
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
        },
        () => {
          this.getRevenues();
          this.getCustomers();
          this.getLocations();
          this.getCurrencies();
          this.searchAgingInvoice();
        }
      );
    }
  };

  /* API CALLS */
  getRevenues = () => {
    let { dispatch } = this.props;
    let { organizationId } = this.state;

    let payload = {
      organizationId,
    };
    // dispatch(alertActions.startRequest());
    commonService.getRevenues(payload, source.token).then((res) => {
      // dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        response.data !== null && this.setState({ revenues: response.data });
        // this.setState({ revenues: response.data === null ? []: response.data});
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getCustomers = () => {
    let { dispatch } = this.props;
    let { organizationId } = this.state;

    dispatch(alertActions.startRequest());
    let payload = {
      organizationId,
    };
    commonService.getCustomers(payload, source.token).then((res) => {
      let response = res.response;
      if (response && response.data) {
        dispatch(alertActions.stopRequest()); //This is put here because the customer endpoint takes a while to return response data
        //even after you receive a 200 response.
      }
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          customers: response.data,
          customer: "",
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getLocations = () => {
    let { dispatch } = this.props;
    let { organizationId } = this.state;

    let payload = {
      organizationId,
    };

    commonService.getLocations(payload, source.token).then((res) => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        response.data !== null && this.setState({ locations: response.data });
        // this.setState({ locations: response.data });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getCurrencies = () => {
    let { dispatch } = this.props;
    commonService.getCurrency(source.token).then((res) => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({ currencies: response.data });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  searchAgingInvoice = (pageNumber = 0) => {
    const { dispatch } = this.props;
    const {
      endDate,
      startDate,
      invoiceNumber,
      customer,
      revenue,
      location,
      currency,
      dateType,
      selectedStatus,
      pageSize,
      organizationId,
    } = this.state;

    var nextPageNUmber = 0;
    if (typeof pageNumber === "number") {
      nextPageNUmber = pageNumber;
    }
    let payload = {
      pageSize: pageSize,
      from: nextPageNUmber,
      parameter: {
        agencyId: organizationId,
        startDate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59",
        dateType: dateType ? dateType.id : 0,
        locationId: location ? location.id : 0,
        revenueId: revenue ? revenue.id : 0,
        customerId: customer ? customer.id : 0,
        currencyId: currency ? currency.id : 0,
        status: selectedStatus ? selectedStatus.id : 0,
        invoiceNumber: invoiceNumber,
      },
    };

    dispatch(alertActions.startRequest());
    commonService.invoiceAgingAnalysis(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          invoiceAgingDetails: response.results,
          totalCount: response.totalCount,
          currentPage: pageNumber === 0 ? 1 : pageNumber,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.message));
        }
      }
    });
  };

  sendNotification = (event) => {
    event.persist();
    const { dispatch } = this.props;
    const { takeRecord } = this.state;

    let payload = {
      invoiceNumbers: takeRecord,
    };

    dispatch(alertActions.startRequest());
    commonService.sendNotification(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(
          response,
          true,
          this.searchAgingInvoice()
        );
        dispatch(alertActions.success("successful"));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(
          response,
          true,
          this.searchAgingInvoice()
        );
      }
    });
  };

  downloadInvoiceAging = (pageNumber = 0, type) => {
    const { dispatch, user } = this.props;
    const {
      endDate,
      startDate,
      invoiceNumber,
      customer,
      revenue,
      location,
      currency,
      dateType,
      selectedStatus,
      pageSize,
      agencyDetails,
    } = this.state;

    let payload = {
      pageSize: pageSize,
      from: pageNumber === 1 || pageNumber === 0 ? 0 : pageNumber - 1,
      // from: 1,
      parameter: {
        agencyId:
          user.vggRole === appConstants.ROLES.VGG_ADMIN
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
        startDate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59",
        dateType: dateType.id,
        locationId: location.id,
        revenueId: revenue.id,
        customerId: customer.id,
        currencyId: currency.id,
        status: selectedStatus.id,
        invoiceNumber: invoiceNumber,
        downloadType: type,
      },
    };

    dispatch(alertActions.startRequest());
    commonService.downloadInvoiceAging(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.success(response.message));
        appHelpers.downloadFile(response.downloadUrl, "Invoices");
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.message));
        }
      }
    });
  };

  clearFields = () => {
    this.setState({
      takeRecord: [],
    });
  };
  //Alert Notifications

  displaySuccessResponseAlert = (response, action, query) => {
    this.setState(
      {
        showAlertModal: true,
        alertIcon: (
          <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
        ),
        alertMessage: response.message,
      },
      () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
      }
    );
    this.clearFields();
    // this.hideModal();
    if (action) {
      return { query };
    }
  };
  /*HANDLERS */
  handleSelectChange = (payload, attribute) => {
    this.setState({ [attribute]: payload });
  };

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  checkboxChange = (event, item) => {
    event.persist();
    const target = event.target;
    let invoiceNumber = item.invoiceNo;

    if (target.checked) {
      let joined = this.state.takeRecord.concat(invoiceNumber);
      this.setState({ takeRecord: joined });
    } else {
      this.setState({
        takeRecord: this.state.takeRecord.filter(
          (item) => item !== invoiceNumber
        ),
      });
    }
  };

  dismissModal = (alert) => {
    setTimeout(() => {
      const body = document.body;
      body.style.overflow = "scroll";
      this.setState({ [alert]: false });
    }, 5000);
  };

  displayErrorResponseAlert = (response, action, query) => {
    const { dispatch } = this.props;
    if (response === "Please Check your internet connectivity") {
      dispatch(alertActions.error(response));
    } else if (typeof response === "string") {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    } else {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response.message,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    }
  };
  componentWillUnmount () {
   
    if (source) {
      source.cancel()
    }
   }
  render() {
    const {
      selectedStatus,
      Status,
      revenues,
      revenue,
      componentErrorMessages,
      currency,
      currencies,
      customers,
      customer,
      location,
      locations,
      startDate,
      endDate,
      dateTypes,
      dateType,
      currentPage,
      pageSize,
      totalCount,
      invoiceAgingDetails,
      isRequesting,
      invoiceNumber,
      showAlertModal,
      alertMessage,
      alertIcon,
      takeRecord,
      isVggRole,
    } = this.state;

    return (
      <div>
        <ContentLayout
          requesting={this.props.requesting}
          pageTitle="Invoice aging analysis"
          actions={
            isVggRole && (
              <div className={"float-right"}>
                <Button
                  outline
                  color="info"
                  className=" btn-rounded mr-2 big-action-btn"
                  onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
                >
                  <FaArrowLeft className={"icon-margin"} />
                  Back
                </Button>
              </div>
            )
          }
        >
          <Row>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>Invoice number</b>
                </Label>
                <Input
                  type="text"
                  name="invoiceNumber"
                  id="invoiceNumber"
                  value={invoiceNumber}
                  onChange={(e) => this.handleChange(e, "invoiceNumber")}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>Customer</b>
                </Label>
                <SelectList
                  placeholder={"Select customer..."}
                  _errs_={componentErrorMessages.customers}
                  data={customers}
                  valueKey={"id"}
                  // notClearable={true}
                  textField={"customerName"}
                  selectValue={customer}
                  onValueChange={(event) => {
                    this.handleSelectChange(event, "customer");
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>Revenue</b>
                </Label>
                <SelectList
                  placeholder={"Select revenue..."}
                  _errs_={componentErrorMessages.revenues}
                  data={revenues}
                  valueKey={"id"}
                  textField={"name"}
                  // notClearable={true}
                  selectValue={revenue}
                  onValueChange={(event) => {
                    this.handleSelectChange(event, "revenue");
                  }}
                />
                {componentErrorMessages.revenue && (
                  <span className={"inputError"}>
                    {componentErrorMessages.revenue}
                  </span>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>Location</b>
                </Label>
                <SelectList
                  placeholder={"Select location..."}
                  _errs_={componentErrorMessages.location}
                  data={locations}
                  valueKey={"id"}
                  textField={"address"}
                  // notClearable={true}
                  selectValue={location}
                  onValueChange={(event) => {
                    this.handleSelectChange(event, "location");
                  }}
                />
                {componentErrorMessages.location && (
                  <span className={"inputError"}>
                    {componentErrorMessages.location}
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>Date type</b>
                </Label>
                <SelectList
                  placeholder={"Select date type..."}
                  data={dateTypes}
                  valueKey={"id"}
                  textField={"name"}
                  // notClearable={true}
                  selectValue={dateType}
                  onValueChange={(event) => {
                    this.handleSelectChange(event, "dateType");
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>Start date</b>
                </Label>
                <DatePicker
                  date={startDate}
                  name="startDate"
                  onChange={(date) => this.changeDatePicker(date, "startDate")}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>End date</b>
                </Label>
                <DatePicker
                  date={endDate}
                  onChange={(date) => this.changeDatePicker(date, "endDate")}
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>Currency</b>
                </Label>
                <SelectList
                  placeholder={"All"}
                  data={currencies}
                  valueKey={"id"}
                  // notClearable={true}
                  textField={"name"}
                  selectValue={currency}
                  onValueChange={(event) => {
                    this.handleSelectChange(event, "currency");
                  }}
                />
                {componentErrorMessages.currency && (
                  <span className={"inputError"}>
                    {componentErrorMessages.currency}
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label>
                  <b>Status</b>
                </Label>
                <SelectList
                  data={Status}
                  valueKey={"id"}
                  // notClearable={true}
                  textField={"name"}
                  selectValue={selectedStatus}
                  onValueChange={(event) => {
                    this.handleSelectChange(event, "selectedStatus");
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <div className="float-left mt-5">
            <Button
              color="success"
              className="btn-sm btn-rounded  mr-2 text-white"
              onClick={this.searchAgingInvoice}
              disabled={isRequesting}
            >
              Search
            </Button>
          </div>

          {invoiceAgingDetails.length === 0 && (
            <EmptyRecordMessage message={"No record match your search"} />
          )}
          {invoiceAgingDetails.length > 0 && (
            <Row style={{ marginTop: "76px" }}>
              <Col md={"12"}>
                <TableStructure
                  currentPage={currentPage}
                  fetchMore={this.searchAgingInvoice}
                  pageSize={pageSize}
                  totalCount={totalCount}
                  apiData={invoiceAgingDetails}
                  checkboxChange={this.checkboxChange}
                  sendNotification={this.sendNotification}
                  user={this.props.user}
                  downloadInvoiceAging={this.downloadInvoiceAging}
                  takeRecord={takeRecord}
                />
              </Col>
            </Row>
          )}

          {showAlertModal && (
            <RenderAlertModal
              message={alertMessage}
              icon={alertIcon}
              showAlertModal={showAlertModal}
              hideAlertView={this.hideAlertView}
            />
          )}
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, alert } = state;
  return {
    user,
    requesting,
    alert,
  };
};
export default connect(mapStateToProps)(InvoiceAgeAnalysis);
