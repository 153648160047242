import React, { Component } from "react";
import { Table, Button, Input } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "react-toggle/style.css"; // for ES6 modules
import "./index.scss";
import { appConstants } from "../../../_constants";
import moment from "moment";
import { appHelpers } from "../../../_helpers";

const Records = (props) => {
  const { apiData, user } = props;
  return (
    <Table responsive={true} className={"table-main"}>
      <thead className={"th-head tracker-head"}>
        <tr>
          <th></th>
          <th>Invoice No</th>
          <th>Customer</th>
          {user.vggRole === appConstants.ROLES.VGG_ADMIN && (
            <th>Agency</th>
          )}
          <th>Location</th>
          <th>Revenue</th>
          <th>Amount</th>
          <th>Date</th>
          <th>Invoice Age</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {apiData.map((item, index) => {
          return (
            <tr key={index}>
              <th>
                <Input
                  style={{ position: "static", width: "40px" }}
                  type="checkbox"
                  id="checkbox"
                  onChange={(e) => props.checkboxChange(e, item)}
                />
              </th>
              <th>{item.invoiceNo}</th>
              <th>{item.customer}</th>
              {user.vggRole === appConstants.ROLES.VGG_ADMIN && (
                <th>{item.agencyName}</th>
              )}
              <th>{item.location}</th>
              <th>{item.revenue}</th>
              {/* appHelper for precision return error cos the amountString is a string and not a number and amountString was created by kayode because the QA requested that amount must be with the respective currency from the backend then */}
              <th>{item.amountString}</th>
              <th>
                {moment(item.createdDate).format("YYYY-MM-DD")}
              </th>
              <th>{appHelpers.showInDays(item.totalDays)}</th>
              <th>{item.agingStatus}</th>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default class TableStructure extends Component {
  render() {
    let {
      apiData,
      currentPage,
      fetchMore,
      pageSize,
      totalCount,
      user,
      checkboxChange,
      takeRecord,
      sendNotification,
      downloadInvoiceAging,
    } = this.props;
    const pageIndex = pageSize * (currentPage - 1) + 1;

    return (
      <div>
        {takeRecord.length > 0 && (
          <Button
            className="btn-rounded mr-2  float-right mb-2 mt-3 btn btn-outline-info"
            onClick={(e) => sendNotification(e)}
          >
            Send notification
          </Button>
        )}
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageSize={pageSize}
          totalCount={totalCount}
          bottomDownload
          downloadPdf={() => {
            downloadInvoiceAging(0, "pdf")
          }}
          downloadExcel={() => downloadInvoiceAging(0, "excel")}
          target={
            <Records
              apiData={apiData}
              pageIndex={pageIndex}
              user={user}
              checkboxChange={checkboxChange}
            />
          }
        />
      </div>
    );
  }
}
