import React, {Component} from 'react';
import {Button} from "reactstrap";
import ContentLayout from "../../components/ContentLayout";
import {connect} from "react-redux";
import NoSidebarLayout from "../../layouts/layout-components/no-sidebar-layout";
import "./sprite-ui.scss"
import RadioButton from "../../components/CustomRadioButton/custom-radio-btn";
import {commonActions} from "../../_actions";

class PaymentInvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDetails: [],
      paymentChannels: [],
      proceedToPayment: false,
      disableInput: true,
      checked: false,
      showPaymentChannels: false
    }
  }
  
  componentDidMount() {
    let dataFromPath = this.props.history.location.state;
    const {gatewayCode} = this.props;
  
    if (dataFromPath) {
      let filteredPaymentChannels = dataFromPath.paymentChannels.filter(item => item.logo !== "");
      
      dataFromPath && this.setState(
        {invoiceDetails: dataFromPath.invoiceDetails,
          paymentChannels: filteredPaymentChannels
        }, () => {
          
          if ((this.state.invoiceDetails.amountPaid < this.state.invoiceDetails.amount )){
            this.setState({showPaymentChannels: true})
          }
          
          if (gatewayCode === null) {
            this.setState({disableInput: true})
          }else if (gatewayCode) {
            this.setState({disableInput: false})
          }
          
        });
  }
    
  }
  
  changeRadioBtn = (item) => {
    const {code} = item;
    const {amount, amountPaid} = this.state.invoiceDetails;
    const {dispatch} = this.props;
  
    dispatch(commonActions.saveGatewayCode(code))
    setTimeout(() => {
      const {gatewayCode} = this.props;
      if (amountPaid < amount && gatewayCode !== null) {
        this.setState({disableInput: false})
      }
    })
  };
  
  proceedToPayment = () => {
    const {gatewayURL} = this.state.invoiceDetails;
    const formElement = document.getElementById("payment-details-form");
    formElement.action = gatewayURL;
    formElement.method = 'POST';
    formElement.submit();
  };
  
  cancel = () => {
    this.props.history.push("/payment")
  
  };
  
  render() {
    const {invoiceDetails, paymentChannels, disableInput, showPaymentChannels} = this.state;
    const {status} = invoiceDetails;
    const {requesting} = this.props;
    
    return (
      <div>
        <NoSidebarLayout>
          <ContentLayout
            pageTitle={"Invoice Details"}
            height={'70vh'}
          >
            {
              invoiceDetails.length === 0 &&
              <div  className="no-invoice-details">
                  <h3>No invoice details</h3>
              </div>
            }
            {
              invoiceDetails &&
              <div className="invoice-details">
                <div className={'invoice-details__row'}>
                  <div className={"invoice-details__row-item"}>
                    <p>Payment Reference</p>
                    <p><b>{invoiceDetails.paymentReference}</b></p>
                  </div>
                  <div className={"invoice-details__row-item"}>
                    <p>Customer Name</p>
                    <p><b>{invoiceDetails.customerName}</b></p>
                  </div>
                  <div className={"invoice-details__row-item"}>
                    <p>Email</p>
                    <p><b>{invoiceDetails.email}</b></p>
                  </div>
                  <div className={"invoice-details__row-item"}>
                    <p>Currency</p>
                    <p><b>{invoiceDetails.currency}</b></p>
                  </div>
                  <div className={"invoice-details__row-item"}>
                    <p>Phone Number</p>
                    <p><b>{invoiceDetails.phoneNumber}</b></p>
                  </div>
                  <div className={"invoice-details__row-item"}>
                    <p>Status</p>
                    <p className={`${status === "Unpaid"
                      ? "amt-red" : status === "Paid"
                      ? "amt-green" : status === "Partly Paid"
                      ? "amt-yellow" : null
                    }`}><b>{status}</b></p>
                  </div>
                  <div className={"invoice-details__row-item"}>
                    <p>Invoice Amount</p>
                    <p className={"amt"}><b>{invoiceDetails.amount}</b></p>
                  </div>
                  <div className={"invoice-details__row-item "}>
                    <p>Amount Paid</p>
                    <p className={"paid-amt"}><b>{invoiceDetails.amountPaid}</b></p>
                  </div>
                  <div className={"invoice-details__row-item"}>
                    <p>Outstanding Amount</p>
                    <p className={`${invoiceDetails.outstandingBalance  > 0 && "outstanding-amt"}`}><b>{invoiceDetails.outstandingBalance}</b></p>
                  </div>
                </div>
                <div style={{display: showPaymentChannels ? 'block' : 'none'}} className={'invoice-details__channel'}>
                  <h5>Select payment channel</h5>
                  <form className={"invoice-details__channel-wrapper"}>
                    {paymentChannels.map((item) => {
                      return <div key={item.id} className={"invoice-details__channel-wrapper-sub"}>
                        <RadioButton
                          name={"logo"}
                          value={item.code}
                          checked={this.props.gatewayCode === item.code}
                          onChange={() => this.changeRadioBtn(item)}
                        />
                        <img src={item.logo} alt={"payment-channel-logo"} className={"invoice-details__channel-logo"}/>
                      </div>
                    })}
        
                  </form>
                </div>
                <div style={{display: showPaymentChannels ? 'flex' : 'none'}}  className={"invoice-details__action-btns"}>
                  <Button
                    color="success"
                    className="btn-sm btn-rounded mr-2 text-white"
                    onClick={this.proceedToPayment}
                    disabled={requesting || disableInput}
                  >
                    Proceed to Payment
                  </Button>
                  <Button
                    outline
                    className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
                    onClick={this.cancel}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            }
            
            {/*HIDDEN FORM*/}
            <form
              id={'payment-details-form'}
            >
              <input
                type="text"
                name="GatewayCode"
                id="GatewayCode"
                value={this.props.gatewayCode} //THIS IS THE PAYMENT CHANNEL CODE
              />
              <input
                type="text"
                name="PaymentReference"
                id="PaymentReference"
                value={invoiceDetails.paymentReference}
              />
              <input
                type="text"
                name="CustomerName"
                id="CustomerName"
                value={invoiceDetails.customerName}
              />
              <input
                type="email"
                name="Email"
                id="Email"
                value={invoiceDetails.email}
              />
              <input
                type="text"
                name="Currency"
                id="Currency"
                value={invoiceDetails.currencyId}
              />
              <input
                type="text"
                name="PhoneNumber"
                id="PhoneNumber"
                value={invoiceDetails.phoneNumber}
              />
              <input
                type="number"
                name="Amount"
                id="Amount"
                value={invoiceDetails.outstandingBalance}
              />
              <input
                type="number"
                name="TerminalId"
                id="TerminalId"
                value={invoiceDetails.terminalId}
              />
              <input
                type="text"
                name="Description"
                id="Description"
                value={invoiceDetails.description}
              />
              <input
                type="text"
                name="CallbackUrl"
                id="CallbackUrl"
                value={invoiceDetails.callbackURL}
              />
              <input
                type="text"
                name="ClientCode"
                id="ClientCode"
                value={invoiceDetails.clientCode}
              />
              <input
                type="text"
                name="AccessKey"
                id="AccessKey"
                value={invoiceDetails.accessKey}
              />
              {/*<input type="submit" value="Proceed To Payment"/>*/}
            </form>
          </ContentLayout>
        </NoSidebarLayout>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});


export default connect(mapStateToProps)(PaymentInvoiceDetails);