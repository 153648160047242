import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import { appHelpers } from "../../_helpers";
import Spinner from "../Spinner";



function formatBarChartData(invoice, payment) {
  if(invoice && payment){
  let invoiceData, paymentData, paymentCount ;
  invoiceData =  Object.values(invoice);
  if(payment.length>0){
  paymentData  = Object.values(payment);
  paymentCount = _.map(paymentData, 'itemCount')
  }else{
    paymentCount= appHelpers.randomArrayOfNumbers(invoiceData.length,0);
  }
  let labels =  _.map(invoiceData, 'name')
  let invoiceCount = _.map(invoiceData, 'itemCount')
  
  let InvoiceObj = {
    label:"Invoice",
    backgroundColor: "#40D999",
    borderColor: "#40D999",
    data:invoiceCount
  }
  let PaymentObj = {
    label:"Payment",
    backgroundColor: "#28BAF0",
    borderColor: "#28BAF0",
    data:paymentCount
  }
  let datasets = [];
  datasets.push(InvoiceObj);
  datasets.push(PaymentObj);

  let barData = {
    // labels:["PASSENGER SERVICE CHARGE (DOMESTIC)", "Tuesday", "Wednesday", "thiiirddds"],
    labels,
    datasets:datasets
  }


  return barData;
  }
  
}


const BarChartCard = ({ title, barData, visible = true }) => {
  // let invoice = barData.nairaInvoiceBD;
  // let payment = barData.nairaPaymentBD;
  //

  let invoice = barData.invoiceBD2;
  let payment = barData.paymentBD2;
  let formattedBarChartData = formatBarChartData(invoice,payment);
  
  return (
    <Card className=" pb-4">
      <CardBody>
        <CardTitle className="text-center pb-4">{title}</CardTitle>
       {
         barData.invoiceBD2 <= 0 ?
         <center><div style={{margin: "0 auto", height: 350,justifyContent: "center",alignItems: "center",display: "flex" }}>No Data </div></center>
         :
        <div
          className="chart-wrapper"
          style={{ width: "100%", margin: "0 auto", height: 350,justifyContent: "center",alignItems: "center",display: "flex"  }}
        >
        
          { visible === false ?
            <Bar
            data={formattedBarChartData}
            options={{
              maintainAspectRatio: true,
              legend: {
                display: true,
                position: "bottom",
                boxWidth: 1,
                labels: { fontFamily: "Poppins" }
              },
              scales: {
                yAxes: [
                  {
                    gridLines: { display: true },
                    ticks: { fontFamily: "Poppins" }
                  }
                ],
                xAxes: [
                  {
                    gridLines: { display: false },
                    ticks: { fontFamily: "Poppins", fontSize: 10, labelMaxWidth: 10 ,
                    callback: function(label) {
                      if (/\s/.test(label)) {
                        return label.split(" ");
                      }else{
                        return label;
                      }              
                    }
                  },
                    // labelMaxWidth: 10,
                    // labelWrap: true,
                    // afterFit: function(scaleInstance) {
                    //   scaleInstance.width = 100; // sets the width to 100px
                    // }
                   
                  }
                ]
              }
            }}
          />
        :
        <Spinner size={50} visible={true} align="center"/>
        }
       
        
        
        </div>
}
      

      </CardBody>
    </Card>
  );
};

export default BarChartCard;
