import React from "react";
import { modalActions } from "../../_actions";
import FullScreenModal from "../FullScreenModal";
import { connect } from "react-redux";
import { appHelpers } from "../../_helpers";
import VoidRequestForm from "../VoidRequestForm";

const VoidRequestFullScreenModal = ({
  dispatch,
  requesting,
  requestType,
  role,
  name,
  invoiceNumber,
  customer,
  revenue,
  invoiceDate,
  amount,
  placeholder,
  buttonText,
  value,
  onChange,
  initiateRequest,
  displayVoidRequestModal,
  decimalPrecision,
}) => {
  return (
    <FullScreenModal
      show={displayVoidRequestModal.show}
      callback={(res) => {
        appHelpers.fullScreenCallback.call(
          this,
          res,
          dispatch,
          displayVoidRequestModal,
          modalActions.displayVoidRequestModal({
            show: false,
          })
        );
      }}
      zIndex={requesting ? 10 : 999}
    >
      <VoidRequestForm
        role={role}
        name={name}
        requestType={requestType}
        invoiceNumber={invoiceNumber}
        customer={customer}
        revenue={revenue}
        invoiceDate={invoiceDate}
        amount={amount}
        placeholder={placeholder}
        buttonText={buttonText}
        value={value}
        onChange={onChange}
        initiateRequest={initiateRequest}
        decimalPrecision={decimalPrecision}
      />
    </FullScreenModal>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(VoidRequestFullScreenModal);
