import React from "react";
import { Table } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "./index.scss";

const AgencyTable = ({ tableData, handleSelectAgency }) => {
  return (
    <Table responsive striped hover>
      <thead className={"table-head-blue"}>
        <tr>
          <th>Organization</th>
          <th>Email</th>
          <th>Phone Number</th>
          <th>Organization Code</th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((item, index) => {
            return (
              <tr
                key={index}
                className="table-row"
                onClick={() => handleSelectAgency(item)}
              >
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td>{item.code}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

const TableStructure = ({
  tableData,
  handleSelectAgency,
  currentPage,
  fetchMoreRecords,
  pageSize,
  totalCount,
}) => {
  const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
  return (
    <ServerPaginatedTable
      currentPage={currentPage}
      fetchMore={fetchMoreRecords}
      pageSize={pageSize}
      totalCount={totalCount}
      target={
        <AgencyTable
          tableData={tableData}
          handleSelectAgency={handleSelectAgency}
          pageIndex={pageIndex}
        />
      }
    />
  );
};

export default TableStructure;
