import React, { Component } from "react";
import { Table, Button, Input } from "reactstrap";
import ContentLayout from "../../../components/ContentLayout";
import { appHelpers } from "../../../_helpers";
import { connect } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import { customerService } from "../../../_services";
import { appConstants } from "../../../_constants";
import { alertActions, modalActions } from "../../../_actions";
import axios from "axios";
import GeneralAlert from "../../../components/Api-response-alert";

let source;
class CustomersToMerge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customersToMerge: [],
      selectedCustomer: null,
      disableProceedBtn: false,
      parentCustomer: null,
      subCustomers: [],
      agencyId: null,
      agencyDetails: null,
    };
    source = axios.CancelToken.source();
  }

  // componentWillMount = () => {
  //   let stateFromPath = this.props.history.location.state;

  //   if (stateFromPath) {
  //     let customersToMerge = stateFromPath.customersToMerge;
  //     this.setState({
  //       customersToMerge,
  //       agencyId: stateFromPath.agencyId,
  //     });
  //   }
  // };

  componentDidMount = () => {
    let stateFromPath = this.props.history.location.state;

    if (stateFromPath) {
      let customersToMerge = stateFromPath.customersToMerge;
      this.setState({
        customersToMerge,
        agencyId: stateFromPath.agencyId,
        agencyDetails: stateFromPath.agencyDetails,
      });
    }
  };

  storeSelectedCustomer = (e, selectedItem) => {
    this.setState((prevState) => ({
      parentCustomer: selectedItem,
      subCustomers: prevState.customersToMerge.filter(
        (item) => item.id !== selectedItem.id
      ),
    }));
  };

  mergeCustomers = () => {
    let { parentCustomer, subCustomers, agencyId, agencyDetails } = this.state;
    const { dispatch } = this.props;
    if (this.validateCustomersToMerge()) {
      let childCustomerIds = subCustomers.map((item) => item.id);
      let payload = {
        agencyId: agencyId,
        parentCustomerId: parentCustomer.id,
        childCustomerIds,
      };
      dispatch(alertActions.startRequest());
      customerService.mergeCustomer(payload, source.token).then((res) => {
        let response = res.response;
        dispatch(alertActions.stopRequest());
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          let pathState = {
            mergedCustomers: response.data,
            message: response.message,
            route: "/settings-customers-to-merge",
            agencyId,
            agencyDetails,
          };
          appHelpers.navigateRoute.call(
            this,
            "/settings-merged-customers",
            pathState
          );
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(
            modalActions.displayGeneralAlert({
              show: true,
              message: response.message,
              action: "failure",
            })
          );
        }
      });
    }
  };

  //validate that a parent customer is chosen
  validateCustomersToMerge = () => {
    const { parentCustomer } = this.state;
    const { dispatch } = this.props;
    let isValid = true;
    if (!parentCustomer) {
      isValid = false;
      dispatch(
        modalActions.displayGeneralAlert({
          show: true,
          message:
            "Please select a parent customer to complete the merge process.",
          action: "failure",
        })
      );
    }
    return isValid;
  };

  componentWillUnmount = () => {
    if (source) {
      source.cancel();
    }
    this.setState({ parentCustomer: null });
  };

  render() {
    const { disableProceedBtn, customersToMerge } = this.state;
    const { requesting } = this.props;
    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle={"Merge Customer"}
        actions={
          <div className={"float-right"}>
            <Button
              color="info"
              outline
              className="btn-rounded mr-2 big-action-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              disabled={requesting}
            >
              <FaArrowLeft className={"mb-1 mr-1"} /> Back
            </Button>
          </div>
        }
      >
        <h5>Choose parent customer to merge</h5>
        <Table responsive striped hover>
          <thead className={"table-head-blue"}>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Email address</th>
              <th>IN</th>
            </tr>
          </thead>
          <tbody>
            {customersToMerge &&
              customersToMerge.map((item, index) => {
                return (
                  <tr key={index} className={"table-row"}>
                    <td>
                      <Input
                        style={{
                          position: "static",
                          width: "40px",
                          marginLeft: "3px",
                        }}
                        type="radio"
                        name="parentCustomer"
                        onChange={(e) => this.storeSelectedCustomer(e, item)}
                      />
                    </td>
                    <td>{item.customerName}</td>
                    <td>{item.email}</td>
                    <td>{item.customerAIN}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <section className={"proceed-section"}>
          <div className={"proceed-section__prompt"}></div>
          <div className={"proceed-section__buttons"}>
            {/* <button
              className={"ghost-button"}
              onClick={() =>
                appHelpers.navigateRoute.call(this, "/settings-bank-account", {
                  from: "/add-new-bank-account",
                  agencyDetails: pathState.agencyDetails,
                })
              }
            >
              Go back
            </button> */}
            <Button
              color="success"
              className="btn-sm px-4 btn-rounded mr-2 text-white"
              onClick={this.mergeCustomers}
              disabled={disableProceedBtn}
            >
              Proceed
            </Button>
          </div>
        </section>
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, displayGeneralAlert } = state;
  return {
    user,
    requesting,
    displayGeneralAlert,
  };
};

export default connect(mapStateToProps)(CustomersToMerge);

// export default customersToMerge;
