import React from "react";
import {Tooltip} from 'reactstrap';

class ToolTipItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen : false
        }
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        })
    }

    render() {
        return (
            <span>
                <span className="mr-1" color="secondary" id={'Tooltip-' + this.props.id}>
                    {this.props.title}
                </span>
                <Tooltip placement='bottom' isOpen={this.state.tooltipOpen} target={'Tooltip-' + this.props.id} toggle={this.toggle}>
                   {this.props.content}
                </Tooltip>
            </span>
        )
    }
}

export default ToolTipItem;