import React from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { DatePicker } from "../../components/DatePicker";
import "./audittrailStyle.scss";
import ContentLayout from "../../components/ContentLayout";
import moment from "moment";
import TableFormat from "./audittrailtable";
import { alertActions, commonActions } from "../../_actions";
import { commonService } from "../../_services/common.service";
import { appConstants } from "../../_constants/app.constants";
// import SelectList from "../../components/SelectList/selectList";
import Select from "react-select";
// import "react-select/dist/react-select.css";
import axios from "axios";
import { connect } from "react-redux";
import EmptyRecordMessage from "../../components/EmptyRecordMessage/EmptyRecordMessage";
import { appHelpers } from "../../_helpers";
import { FaArrowLeft } from "react-icons/fa";

let source;

const presentDate = new Date();

class AuditTrail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      search: "",
      auditType: "",
      startDate: moment().subtract(1, "month"), // This keeps the start date from a month ago
      endDate: moment(presentDate, "YYYY-MM-DD"), //End date by default will always be current date
      currentPage: 1,
      organizationId: 0,
      auditTypes: [],
      status: "",
      pageSize: 10,
      totalCount: 0,
      searching: false,
      tableData: [],
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    //Call branding if there is no method to display select agency view
    appHelpers.checkDerivedStateFromPropsMethod.call(this);

    this.props.dispatch(commonActions.setPeriodPickerVisibility(false));

    document.querySelectorAll(".react-datepicker-wrapper").forEach((item) => {
      item.classList.add("form-control");
    });
    this.getAuditTrail();
    this.getAuditTypes();
    // document.querySelectorAll(".pagination").forEach(item => {
    //   item.classList.add("moveRight");
    // });
  }

  changeDatePicker = (date, target) => {
    this.setState({ [target]: date });
  };

  getAuditTrail = (pageNumber = 1) => {
    const { dispatch } = this.props;
    const { pageSize, startDate, endDate, search, auditType } = this.state;

    let payload = {
      pageSize: pageSize,
      from: pageNumber,
      parameter: {
        search: search,
        auditType: parseInt(auditType.value),
        fromDate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59",
      },
    };
    dispatch(alertActions.startRequest());
    commonService.searchAuditTrail(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          organizationId: response.data,
          totalCount: response.totalCount,
          tableData: response.results,
          currentPage: pageNumber === 0 ? 1 : pageNumber,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getAuditTypes = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());

    commonService.getAuditTypes(source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({ auditTypes: this.formatData(response.data) });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (response === "Please Check your internet connectivity") {
          dispatch(alertActions.error(response));
        }
      }
    });
  };

  formatData(data) {
    const res = data.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    return res;
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      if (this.state.search === "") {
        this.getAuditTrail();
      }
    });
  };

  handleSelectChange = (auditType) => {
    this.setState({ auditType: auditType });
    if (auditType === null) {
      this.setState({ auditType: "" });
    }
  };

  downloadAuditTrail = (type, pageNumber = 0) => {
    const { dispatch } = this.props;
    const { pageSize, startDate, endDate, search, auditType } = this.state;

    let payload = {
      pageSize: pageSize,
      from: pageNumber,
      parameter: {
        search: search,
        auditType: auditType ? parseInt(auditType.value) : null,
        fromDate: moment(startDate).format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment(endDate).format("YYYY-MM-DD") + "T23:59:59",
        downloadType: type,
      },
    };
    dispatch(alertActions.startRequest());
    commonService.downloadAuditTrail(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());

      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(alertActions.success(response.message));
        appHelpers.downloadFile(response.downloadUrl, "Audits");
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
        } else {
          dispatch(alertActions.error(response.message));
        }
        this.setState({ requestingPayments: false });
      }
    });
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      tableData,
      startDate,
      endDate,
      search,
      pageSize,
      currentPage,
      totalCount,
      requesting,
      auditTypes,
      auditType,
    } = this.state;

    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle="Audit Trail"
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className="big-action-btn btn-rounded mr-2"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <Row>
          <Col md={"3"}>
            <FormGroup>
              <Label for="date">Start Date</Label>
              <DatePicker
                date={startDate}
                onChange={(date) => this.changeDatePicker(date, "startDate")}
              />
            </FormGroup>
          </Col>
          <Col md={"3"}>
            <FormGroup>
              <Label for="date">End Date</Label>

              <DatePicker
                date={endDate}
                onChange={(date) => this.changeDatePicker(date, "endDate")}
              />
            </FormGroup>
          </Col>
          <Col md={"3"}>
            <FormGroup>
              <Label>Audit Type</Label>
              <Select
                className={"select-list"}
                classNamePrefix={"react-select"}
                value={auditType}
                onChange={this.handleSelectChange}
                options={auditTypes}
                placeholder={"Select Audit Type..."}
                defaultValue={auditType}
              />
            </FormGroup>
          </Col>
          <Col md={"3"}>
            <FormGroup>
              <Label for="search">Search</Label>
              <Input
                type="text"
                name="search"
                onChange={this.handleInputChange}
                id="search"
                value={search}
              />
            </FormGroup>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col md={"3"} className={"search-button"}>
            <Button
              // size="lg"
              color="success"
              className="btn-rounded px-4 text-white"
              onClick={this.getAuditTrail.bind(this, 0)}
              disabled={requesting}
            >
              {requesting ? `Searching...` : `Search`}
            </Button>
          </Col>
        </Row>
        <br />
        <br />
        {tableData.length === 0 && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}
        {tableData.length > 0 && (
          <Row>
            <Col md={"12"}>
              <TableFormat
                currentPage={currentPage}
                fetchMore={this.getAuditTrail}
                pageSize={pageSize}
                totalCount={totalCount}
                apiData={tableData}
                downloadPdf={this.downloadAuditTrail}
              />
            </Col>
          </Row>
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, alert, progress, user, requesting } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(AuditTrail);
