import React from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const styles = {
  actionCard: {
    borderRadius: "15px",
    border: "1px solid #eee",
    // whiteSpace: "nowrap",
    minheight: "270px",
    "&:hover": {
      outline: "0",
      boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
    }
  }
};

const ActionCard = ({ image, action, actionDetails, route }) => {
  return (
    <Link to={{
      pathname: `${route}`,
      state: {
        from: `${route}`
      }
    }}>
      <Card className=" p-3" style={styles.actionCard}>
        <CardBody>
          {image}
          <h3 className="font-bold font-16 mt-4">{action}</h3>
          <p>{actionDetails}</p>
        </CardBody>
      </Card>
    </Link>
  );
};

export default ActionCard;
