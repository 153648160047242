import React from 'react';
import {Card, CardTitle} from 'reactstrap';

const SummaryCard = ({cardTitle, cardStyle, bgColor, topValue, bottomValue}) => {
    return (
        <Card body inverse color={cardStyle} className={"d-flex py-2 px-4"} style={{ color: "#404a4c", backgroundColor: bgColor, borderRadius: "10px"}}>
            <CardTitle style={{color: "#404a4c", fontWeight: 700, fontSize: "14px"}}>{cardTitle}</CardTitle>
            {topValue}
            <hr/>
            {bottomValue}
        </Card>
    )
};
// 108, 440,175.93

export default SummaryCard;
