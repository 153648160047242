import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function userData(state = initialState.userData, action) {
  
  switch (action.type) {
    case appConstants.USER_DATA:
      const data = action.payload;
      
      let userDataToPersist = {
        organizationId: data.organizationId,
        organization: data.organization,
        userName: data.userName,
        logoUrl: data.logoURL,
        backgroundUrl: data.backgroundURL,
      };
      // if (!userDataToPersist.backgroundImgAddress) {
      //   userDataToPersist.backgroundImgAddress = "https://cdn.pixabay.com/photo/2020/09/17/12/39/sunflowers-5579060_1280.jpg"
      // }
      return Object.assign({}, state, userDataToPersist);
      
    default:
      return state;
  }
}
