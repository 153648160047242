import React from 'react';
import {Row, Col, Button} from 'reactstrap';
import Toggle from "react-toggle";

const UserRole = ({role, editRoleProfile, userDetails, status, handleActivationStatus}) => {
    return (
        <div>
            <Row>
                <Col md={6}>
                    <h1>{role}
                    <span className={"ml-2"}>
                        <label className={"toggle-label"}>
                            <Toggle
                                // defaultChecked={status}
                                checked={status}
                                onChange={(e) => handleActivationStatus(e)} />
                        </label>
                    </span>
                    </h1>
                </Col>
                <Col md={6}>
                    <Button
                        color="primary"  className=" btn-rounded float-right small-action-btn"
                        onClick={() => editRoleProfile(userDetails)}
                    >Edit Permissions</Button>
                </Col>
            </Row>
            
        </div>
    );
};

export default UserRole;