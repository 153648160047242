import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Button,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import { alertActions } from "../../_actions";
import { appConstants } from "../../_constants";
import { commonService } from "../../_services";
import { history } from "../../jwt/_helpers";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import "./resetpassword.css";
import { appHelpers } from "../../_helpers/app.helpers";
import axios from "axios";
import EPIBSBGImage from "../../assets/images/ebips-assets/Ebips-image.png";
import EPIBSLogo from "../../assets/images/ebips-assets/ebips-logo.svg";

let source;
class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      newPassword: "",
      confirmPassword: "",
      isRequesting: false,
      error: false,
      componentError: false,
      componentErrorMessages: [],
      code: "",
      year: "",
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    // solution two to get code from the url
    // if(window.location.search) {
    // const code = window.location.search.split('=')?.[1]
    // this.setState({code})
    // console.log("code", code);

    // }

    //solution one to get code
    const urlParams = window.location.href.split("?")[1]?.replace("code=", "");
    let code = urlParams;
    this.setState({ code });

    //2019 solution to get code
    // console.log("window", urlParams && urlParams.split('=')[1]);
    // console.log("widdo", window.location.href.split('?')[1]?.replace('code=',''));
    // console.log(code, "code");

    // let urlObject = new URLSearchParams(window.location.search);
    // let code = Number(urlObject.get('code'));
    // this.setState({code})

    let date = new Date();
    let year = date.getFullYear();
    this.setState({ year });

    appHelpers.getBrandingFromDomainURL.call(this);
  }

  componentWillReceiveProps = function (nextProps) {
    const { alert } = nextProps;
    if (alert) {
      if (alert.type === appConstants.SUCCESS) {
        this.setState({ error: false, success: alert.message });
      } else if (alert.type === appConstants.CLEAR) {
        this.setState({ success: false, error: false });
      } else {
        this.setState({ success: false, error: alert.message });
      }
    }
  };

  handleChange = (event, attribute) => {
    let currState = this.state;
    currState[attribute] = event.target.value;
    let errorMessages = this.state.componentErrorMessages;
    errorMessages[attribute] = false;
    this.setState(currState);
    this.setState({ componentErrorMessages: errorMessages });
  };

  validateInput = () => {
    let _errs_ = [];
    let formValid = true;
    if (this.state.userName === "") {
      _errs_["userName"] = "Please enter email";
      formValid = false;
    }
    if (this.state.newPassword === "") {
      _errs_["newPassword"] = "Please enter password";
      formValid = false;
    }
    if (this.state.confirmPassword === "") {
      _errs_["confirmPassword"] = "Confirm Password is required";
      formValid = false;
    }
    if (this.state.newPassword !== this.state.confirmPassword) {
      _errs_["newPassword"] =
        "The new password and confirm password does not match";
      formValid = false;
    }
    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.resetError();
    if (this.validateInput()) {
      this.setState({ isRequesting: true });
      this.processSubmit();
    }
  };

  processSubmit = () => {
    const { userName, newPassword, confirmPassword, code } = this.state;
    const { dispatch } = this.props;
    let payload = {
      userName: userName,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      code: code,
    };
    commonService.resetPassword(payload, source.token).then(
      (response) => {
        this.setState({ isRequesting: false });
        if (response.status === appConstants.SUCCESS_RESPONSE) {
          let user = response.response;
          dispatch(success(user));
          store.addNotification({
            message: response.response.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              // onScreen: true
            },
          });

          history.push("/login");
        }
        // else if(response.status === appConstants.ERROR_RESPONSE && response.response.hasOwnProperty("message") && response.response.message.includes("Invalid")) {
        else if (
          response.status === appConstants.ERROR_RESPONSE &&
          response.response.hasOwnProperty("message")
        ) {
          store.addNotification({
            message: response.response.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              // onScreen: true
            },
          });
        } else {
          store.addNotification({
            message: response.response,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              // onScreen: true
            },
          });
        }
      },
      (error) => {
        let errorMessage = error.response.data
          ? error.response.data
          : "Unable to handle request";
        dispatch(failure(errorMessage));
        this.setState({ isRequesting: false });
        // dispatch(alertActions.stopRequest());
      }
    );

    function success(user) {
      return { type: appConstants.SIGN_IN_SUCCESS, user };
    }
    function failure(message) {
      return { type: appConstants.ERROR, message };
    }
  };

  resetError() {
    this.props.dispatch(alertActions.clear());
  }

  renderPageResponse() {
    if (this.state.error) {
      return (
        <div className="alert alert-danger text-capitalize-first">
          {this.state.error}
        </div>
      );
    } else if (this.state.componentError) {
      return (
        <div className="alert alert-danger text-capitalize-first">
          {this.state.componentError}
        </div>
      );
    } else if (this.state.success) {
      return (
        <div className="alert alert-success text-capitalize-first">
          {this.state.success}
        </div>
      );
    }
  }

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    let pageResponse = this.renderPageResponse();
    const { isRequesting, componentErrorMessages, year } = this.state;
    const { userData } = this.props;

    let backgroundImage = userData.backgroundUrl
      ? userData.backgroundUrl
      : EPIBSBGImage;
    let logo = userData.logoUrl ? userData.logoUrl : EPIBSLogo;
    return (
      <div
        style={{
          backgroundImage: `linear-gradient(  rgb(4, 36, 65) 20%, rgba(7, 52, 91, 0.705) 90%),url(${backgroundImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
          height: "100vh",
        }}
      >
        <Container>
          <Row
            className={
              "d-flex align-items-center justify-content-center mt-4 vh100"
            }
          >
            <Col md={6}>
              <h1
                className="headerText"
                style={{
                  fontWeight: "600",
                  marginTop: "5px",
                  fontSize: "25px",
                }}
              >
                Electronic Billing, Invoicing <br /> and Payment Solution
              </h1>
              <p
                className="baseText"
                style={{ fontSize: "10px", fontWeight: "300" }}
              >
                Improve transparency and accountability and streamline <br />{" "}
                your invoice-to-Cash Operations across all Payment Methods{" "}
                <br /> and Channel on a Single Platform.
              </p>
            </Col>
            <Col sm={12} md={6}>
              <Card className="o-hidden border-0 shadow-lg my-5" id="cardLog">
                <CardBody className="card-body p-0">
                  <Row>
                    <img
                      src={logo}
                      alt="BDA Logo"
                      style={{
                        verticalAlign: "middle",
                        borderStyle: "none",
                        width: "25%",
                        marginLeft: "40%",
                        marginTop: "20px",
                      }}
                    />

                    <center>
                      <p
                        style={{
                          fontSize: "0.8rem",
                          margin: "10px 0px 0 90px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Enter Your New password below
                      </p>
                    </center>

                    <div className="col-lg-12">
                      <div className="p-9">
                        <div className="text-center" />
                        <form className="user">
                          <center>
                            {pageResponse}
                            <FormGroup>
                              <input
                                // size="lg"
                                // type="Text"
                                name="Email"
                                value={this.state.userName}
                                // aria-describedby="userNameHelp"
                                _errs_={componentErrorMessages.userName}
                                placeholder="Email"
                                onChange={(event) =>
                                  this.handleChange(event, "userName")
                                }
                              />
                              {componentErrorMessages.userName && (
                                <span className={"inputError"}>
                                  {componentErrorMessages.userName}
                                </span>
                              )}
                              {/* <span className="bar"></span> */}
                            </FormGroup>

                            <FormGroup>
                              <input
                                // size="lg"
                                type="password"
                                name="Enter your new password"
                                value={this.state.newPassword}
                                _errs_={componentErrorMessages.newPassword}
                                // aria-describedby="userNameHelp"
                                placeholder="New Password"
                                onChange={(event) =>
                                  this.handleChange(event, "newPassword")
                                }
                              />
                              {componentErrorMessages.newPassword && (
                                <span className={"inputError"}>
                                  {componentErrorMessages.newPassword}
                                </span>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <input
                                size="lg"
                                type="password"
                                name="Confirm Password"
                                value={this.state.confirmPassword}
                                // aria-describedby="emailHelp"
                                _errs_={componentErrorMessages.confirmPassword}
                                placeholder="Confirm Password"
                                onChange={(event) =>
                                  this.handleChange(event, "confirmPassword")
                                }
                              />
                              {componentErrorMessages.confirmPassword && (
                                <span className={"inputError"}>
                                  {componentErrorMessages.confirmPassword}
                                </span>
                              )}
                              {/* <span className="bar"></span> */}
                            </FormGroup>

                            <div className="text-center">
                              {/* style={{ marginBottom: "4rem" }} */}

                              {/* <span className="bar"></span> */}

                              <Button
                                color={"success"}
                                onClick={this.handleSubmit}
                                id="loginButton"
                                className={
                                  "btn-rounded ml-3 mb-2 mt-2 px-4 loginBtn"
                                }
                                disabled={isRequesting}
                              >
                                {isRequesting ? (
                                  <span>
                                    Submitting...{" "}
                                    <i className="fa fa-spinner fa-spin"></i>
                                  </span>
                                ) : (
                                  `Submit`
                                )}

                                {this.state.btn}
                              </Button>
                            </div>
                          </center>

                          <center>
                            <p
                              style={{
                                fontWeight: "bold",
                                marginBottom: "0.1rem",
                                fontSize: "10px",
                                // paddingBottom:"10px"
                              }}
                            >
                              Federal Ministry of Aviation, Nigeria
                            </p>
                            <p style={{ marginTop: "4px", fontSize: "10px" }}>
                              {`AVITECH All Rights Reserved, Copyright \u00A9 ${year}`}
                            </p>
                          </center>
                        </form>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert, user, requesting, userData } = state;
  return {
    alert,
    user,
    requesting,
    userData,
  };
}

export default connect(mapStateToProps)(ResetPasswordPage);
