import React, { useEffect } from "react";
import "./payment.css";
import {connect} from "react-redux";


let source;

export const LoaderMobile = ({}) => (
    <div style={{ textAlign: "center" }} >
        <div class="loading-scr">
        <div class="loading-animation">
          <p class="loading-log">Validating...</p>
          <div class="load-bar"></div>
        </div>
      </div>
    </div>
  )
  
  const mapStateToProps = (state) => ({
    ...state
  });
  
  export default connect(mapStateToProps)(LoaderMobile);




