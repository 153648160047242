import React, { Component } from "react";
import PersonalInformation from "./personal-information";
import { modalActions } from "../../../_actions";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import { appHelpers } from "../../../_helpers";
import { alertActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import GeneralAlert from "../../../components/Api-response-alert";
import BackButton from "../../../components/BackButton";
import "./index.scss";
import ChangePasswordModal from "./change-password";
import { commonService } from "../../../_services";
import axios from "axios";

let source;

class ViewUserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      firstName: "Ayomikun",
      lastName: "Davis",
      emailAddress: "ayomikundavis@faan.com",
      userRole: "Transaction Manager",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      userDetails: null,
      inputError: {
        newPassword: "New Password is required",
        confirmPassword: "Confirm Password is required",
      },
      invalidNewPassword: true,
      invalidConfirmPassword: true,
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    let { user } = this.props;
    let nameArr = user.fullName.split(" ");
    // this.getUserByEmail(user.userName);
    this.setState({
      firstName: nameArr[0],
      lastName: nameArr[1],
      emailAddress: user.userName,
      userRole: user.userRole,
      phoneNumber: user.phoneNumber,
    });
  }

  componentDidUpdate(prevProps) {
    const { alertComponentsReducer } = this.props;

    /* If the modal's show state is false, clear the necessary input fields */
    if (
      alertComponentsReducer.displayChangePasswordModal.show !==
      prevProps.alertComponentsReducer.displayChangePasswordModal.show
    ) {
      if (!alertComponentsReducer.displayChangePasswordModal.show) {
        this.setState({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      }
    }
  }

  openChangePasswordModal = () => {
    const { dispatch } = this.props;
    dispatch(
      modalActions.displayChangePasswordModal({ show: true, message: "" })
    );
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  togglePassword = (inputId, toggleElementId) => {
    let inputElement = document.querySelector(`#${inputId}`);
    let toggleIcon = document.querySelector(`#${toggleElementId}`);
    const type =
      inputElement.getAttribute("type") === "password" ? "text" : "password";
    inputElement.setAttribute("type", type);
    toggleIcon.classList.toggle("fa-eye-slash");
  };

  cancel = () => {
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  /* API */
  changePassword = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const { user, dispatch } = this.props;

    const payload = {
      currentPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      userName: user.userName,
    };

    dispatch(alertActions.startRequest());
    commonService.changePassword(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      const { response } = res;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "success",
          })
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "failure",
          })
        );
      }
    });
  };

  /* Currently not using this endpoint. */
  // getUserByEmail = (email) => {
  //   const { dispatch } = this.props;

  //   dispatch(alertActions.startRequest());
  //   commonService.getUserByEmail(email, source.token).then((res) => {
  //     dispatch(alertActions.stopRequest());

  //     const { response } = res;

  //     if (res.status === appConstants.SUCCESS_RESPONSE) {
  //       this.setState({
  //         userDetails: response.data,
  //         firstName: response.firstName,
  //         lastName: response.lastName,
  //         emailAddress: response.email,
  //         phoneNumber: response.phoneNum,
  //         userRole: response.userRole,
  //       });
  //     } else if (res.status === appConstants.ERROR_RESPONSE) {
  //       dispatch(alertActions.error(res.response));
  //     }
  //   });
  // };

  validateInput = () => {
    const { newPassword, confirmPassword } = this.state;
    let formValid = true;
    let err = {};
    //new password
    if (newPassword === "") {
      err["newPassword"] = "New Password is required";
      formValid = false;
    }
    if (newPassword !== "" && newPassword.length <= 7) {
      err["newPassword"] =
        "New Password must contain a minimum of eight characters";
      formValid = false;
    }
    if (newPassword !== "" && !appHelpers.specialCharactersRegEx(newPassword)) {
      err["newPassword"] =
        "New  Password must contain at least 8 characters, a lower case alphabet, an uppercase alphabet and a number";
      formValid = false;
    }

    //confirm password
    if (confirmPassword === "") {
      err["confirmPassword"] = "Confirm Password is required";
      formValid = false;
    }
    if (confirmPassword !== "" && confirmPassword.length <= 7) {
      err["confirmPassword"] =
        "Confirm Password must contain a minimum of eight characters";
      formValid = false;
    }
    if (
      confirmPassword !== "" &&
      !appHelpers.specialCharactersRegEx(confirmPassword)
    ) {
      err["confirmPassword"] =
        "Confirm  Password must contain at least 8 characters, a lower case alphabet, an uppercase alphabet and a number";
      formValid = false;
    } else if (newPassword !== confirmPassword) {
      err["confirmPassword"] = "Confirm  Password does not match New Password";
      formValid = false;
    }

    this.setState({ inputError: err });
    // if (!this.state.inputError.newPassword && !newPassword !== "") {
    //   this.setState({ invalidNewPassword: false });
    // }
    // if (!this.state.inputError.confirmPassword || !confirmPassword !== "") {
    //   this.setState({ invalidConfirmPassword: false });
    // }
    // });

    return formValid;
  };

  handleMouseOut = () => {
    let err = {};

    //new password
    // if (newPassword === "") {
    //   err["newPassword"] = "New Password is required";
    // }
    // if (newPassword !== "" && newPassword.length <= 7) {
    //   err["newPassword"] =
    //     "New Password must contain a minimum of eight characters";
    // }
    // if (newPassword !== "" && !appHelpers.specialCharactersRegEx(newPassword)) {
    //   err["newPassword"] =
    //     "New  Password must contain at least 8 characters, a lower case alphabet, an uppercase alphabet and a number";
    // }

    //confirm password
    // if (confirmPassword === "") {
    //   err["confirmPassword"] = "Confirm Password is required";
    // }
    // if (confirmPassword !== "" && confirmPassword.length <= 7) {
    //   err["confirmPassword"] =
    //     "Confirm Password must contain a minimum of eight characters";
    // }
    // if (
    //   confirmPassword !== "" &&
    //   !appHelpers.specialCharactersRegEx(confirmPassword)
    // ) {
    //   err["confirmPassword"] =
    //     "Confirm  Password must contain at least 8 characters, a lower case alphabet, an uppercase alphabet and a number";
    // }

    this.setState({ inputError: err });

    this.validateInput();
  };

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(modalActions.displayChangePasswordModal({ show: false }));
    dispatch(modalActions.displayGeneralAlert({ show: false }));

    if (source) {
      source.cancel();
    }
  }
  render() {
    const {
      phoneNumber,
      firstName,
      lastName,
      emailAddress,
      userRole,
      oldPassword,
      newPassword,
      confirmPassword,
      inputError,
      // invalidNewPassword,
      // invalidConfirmPassword,
    } = this.state;

    const {
      requesting,
      dispatch,
      alertComponentsReducer,
      displayGeneralAlert,
    } = this.props;

    const displayChangePasswordModal =
      alertComponentsReducer.displayChangePasswordModal;

    return (
      <ContentLayout
        pageTitle={"User Profile"}
        requesting={requesting}
        actions={
          <BackButton
            navigateBack={() => appHelpers.navigateToPreviousRoute.call(this)}
          />
        }
      >
        <PersonalInformation
          firstName={firstName}
          lastName={lastName}
          emailAddress={emailAddress}
          userRole={userRole}
          phoneNumber={phoneNumber}
          openChangePasswordModal={this.openChangePasswordModal}
        />
        {displayChangePasswordModal.show && (
          <ChangePasswordModal
            togglePassword={this.togglePassword}
            handleChange={this.handleChange}
            oldPassword={oldPassword}
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            changePassword={this.changePassword}
            cancel={this.cancel}
            displayChangePasswordModal={displayChangePasswordModal}
            dispatch={dispatch}
            requesting={requesting}
            inputError={inputError}
            handleMouseOut={this.handleMouseOut}
            // invalidNewPassword={invalidNewPassword}
            // invalidConfirmPassword={invalidConfirmPassword}
          />
        )}
        {displayGeneralAlert.show && <GeneralAlert />}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user,
    requesting,
    alertComponentsReducer,
    displayGeneralAlert,
  } = state;
  return {
    user,
    requesting,
    alertComponentsReducer,
    displayGeneralAlert,
  };
};

export default connect(mapStateToProps)(ViewUserProfile);
