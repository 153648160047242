import React from 'react';
import "./custom-radio-btn.scss"

const RadioButton = ({ name, checked, onChange, value,  }) => (
  <label className="label-container">
    <input
      type="radio"
      name={name}
      // id={'radio-btn'}
      checked={checked}
      onChange={(e) => onChange(e, value)}
      value={value}
      className={'custom-radio-btn'}
    />
    <span className="checkmark" />
  </label>
);

export default RadioButton;
