import React, { Component } from "react";
import { FormGroup, Label, Input, Row, Col, Button, Form } from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
import { DatePicker } from "../../../components/DatePicker";
import ContentLayout from "../../../components/ContentLayout";
import { customerService, commonService } from "../../../_services";
import { appConstants } from "../../../_constants";
import SelectList from "../../../components/SelectList/selectList";
import { appHelpers } from "../../../_helpers";
import { FaArrowLeft } from "react-icons/fa";
import axios from "axios";
import { alertActions } from "../../../_actions";

let source;

class NewCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      category: 0,
      type: {},
      address: "",
      incorpDate: "",
      rcNumber: "",
      debtRecovery: 0,
      accountCode: "",
      customerCategories: [],
      designations: [],
      types: [
        { id: 1, name: "Public Limited Company" },
        { id: 2, name: "Limited Liability Company" },
      ],
      staffDetails: [
        {
          designation: { id: 5, name: "Company Representative" },
          staffName: "",
          phoneNumber: "",
          emailAddress: "",
          staffLastName: "",
        },
      ],
      isEdit: false,
      allowSelfService: false,
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    this.fetchCustomerCategories();
    this.fetchDesignations();
    this.getSettingsByKey();
    this.setAgencyBasedOnRole();

    if (this.state.customerDetails) {
      this.setInitialState();
    }
  }

  static getDerivedStateFromProps(props, state) {
    let pathState = props.location.state;

    if (pathState) {
      /**
       * @description agencyDetails is set only when the user is a VGG admin
       * pathState is obtained from /manage-customers route
       */

      if (props.location.state.agencyDetails !== state.agencyDetails) {
        return {
          agencyDetails: props.location.state.agencyDetails,
        };
      }

      /**
       * @description customerDetails is set only in edit mode
       * This would be reached if the customer is to be edited
       * pathState is obtained from /customer-details route
       */

      if (props.location.state.customerDetails !== state.customerDetails) {
        return {
          customerDetails: props.location.state.customerDetails,
          isEdit: true,
          allowSelfService:
            props.location.state.customerDetails.allowSelfService,
        };
      }
    }

    return null;
  }

  setAgencyBasedOnRole = () => {
    const { isEdit, customerDetails, agencyDetails } = this.state;
    const { user } = this.props;

    this.setState({
      agencyId: isEdit //If it is in edit mode
        ? customerDetails.agencyId //use the existing agencyId
        : user.vggRole === appConstants.ROLES.VGG_ADMIN //else (it would be in create mode) if user is a vgg admin
        ? agencyDetails.id //use the selected agency from the previous route /manage-customer
        : user.organizationId, //else use logged in user organizationId
    });
  };

  /**
   * @description This function is only executed in edit mode
   */
  setInitialState = () => {
    const { customerDetails, companyRepId } = this.state;
    //parse staff details to UI required payload format

    let formattedCustomerStaffDetails = [
      {
        designation: { id: companyRepId, name: "Company Representative" },
        staffName: "",
        phoneNumber: "",
        emailAddress: "",
        staffLastName: "",
      },
    ];

    if (customerDetails.customerStaff.length > 0) {
      formattedCustomerStaffDetails = customerDetails.customerStaff.map(
        ({
          id,
          firstName,
          lastName,
          email,
          phoneNumber,
          titleId,
          titleName,
          staffLastName,
        }) => {
          return {
            designation: { id: titleId, name: titleName },
            staffName: firstName,
            phoneNumber,
            emailAddress: email,
            id,
            staffLastName: lastName,
          };
        }
      );
    }

    this.setState({
      customerId: customerDetails.id,
      customerName: customerDetails.customerName,
      category: {
        name: customerDetails.categoryName,
        id: customerDetails.categoryId,
      },
      type: { name: customerDetails.typeName, id: customerDetails.typeId },
      address: customerDetails.address,
      incorpDate:
        customerDetails.incorpDate &&
        moment(customerDetails.incorpDate).format("YYYY-MM-DD"),
      rcNumber: customerDetails.rcNumber,
      debtRecovery: customerDetails.debtRecovery,
      accountCode: customerDetails.accountCode,
      staffDetails: [...formattedCustomerStaffDetails],
    });
  };

  resetForm = () => {
    const { companyRepId } = this.state;

    let formattedCustomerStaffDetails = [
      {
        designation: { id: companyRepId, name: "Company Representative" },
        staffName: "",
        phoneNumber: "",
        emailAddress: "",
        staffLastName: "",
      },
    ];

    this.setState({
      customerId: "",
      customerName: "",
      category: {},
      allowSelfService: false,
      type: {},
      address: "",
      incorpDate: "",
      rcNumber: "",
      debtRecovery: 0,
      accountCode: "",
      staffDetails: [...formattedCustomerStaffDetails],
    });
  };

  getSettingsByKey = () => {
    let { dispatch } = this.props;
    let key = "CompanyRepresentative";
    dispatch(alertActions.startRequest());
    commonService.getSettingsByKey(key).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          companyRepId: parseInt(response.data.value),
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  fetchCustomerCategories = () => {
    let { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    customerService.fetchCustomerCategories(source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          customerCategories: response.data,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  fetchDesignations = () => {
    let { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    commonService.getAllDesignations(source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          designations: response.data,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  saveCustomer = () => {
    const { dispatch } = this.props;
    const {
      customerName,
      category,
      type,
      address,
      incorpDate,
      debtRecovery,
      accountCode,
      rcNumber,
      customerId,
      isEdit,
      agencyId,
      allowSelfService,
    } = this.state;

    //parse staff details to API required payload format
    let formattedCustomerStaffDetails = this.state.staffDetails.map(
      ({
        designation,
        staffName,
        emailAddress,
        phoneNumber,
        id,
        staffLastName,
      }) => {
        return {
          titleId: designation ? designation.id : 0,
          firstName: staffName,
          email: emailAddress,
          phoneNumber,
          id: isEdit ? id : undefined,
          lastName: staffLastName,
        };
      }
    );

    let payload = {
      id: isEdit ? customerId : 0,
      name: customerName,
      category: category ? category.id : 0,
      type: type ? type.id : 0,
      address,
      rcNumber,
      incorpDate: incorpDate && moment(incorpDate).format("YYYY-MM-DD"),
      debtRecovery,
      accountCode,
      agencyId,
      fromCreateInvoice: false,
      allowSelfService: allowSelfService,
      customerStaff: [...formattedCustomerStaffDetails],
    };

    dispatch(alertActions.startRequest());
    customerService.addCustomer(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());

      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        //customerDetails is passed into the route because
        //some of its values are required in the next route
        this.props.history.push({
          pathname: "/customer-details",
          state: {
            from: "/manage-customers",
            selectedCustomer: { id: response.id.id, agencyId },
            agencyDetails: { id: agencyId }, //required for VGG Admin
          },
        });
        this.resetForm();

        dispatch(alertActions.success(response.message));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(response.message));
      }
    });
  };

  changeDatePicker = (date) => {
    this.setState({ incorpDate: date });
  };

  addStaffDetails = () => {
    this.setState({
      staffDetails: [
        ...this.state.staffDetails,
        {
          designation: "",
          staffName: "",
          phoneNumber: "",
          emailAddress: "",
          staffLastName: "",
        },
      ],
    });
  };

  removeStaffDetails = (index) => {
    if (this.state.staffDetails.length > 1) {
      const staffDetails = this.state.staffDetails.filter(
        (e, idx) => idx !== index
      );
      this.setState({ staffDetails });
    }
  };

  handleChange = (event, attribute) => {
    this.setState({ [attribute]: event.target.value });
  };

  checkboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleSelectChange = (payload, attribute) => {
    this.setState({ [attribute]: payload });
  };

  handleChangeStaffDetails = (event, attribute, index) => {
    const { staffDetails } = this.state;
    staffDetails[index][attribute] = event.target.value;
    this.setState({ staffDetails });
  };

  handleSelectChangeStaffDetails = (payload, attribute, index) => {
    const { staffDetails, companyRepId } = this.state;
    staffDetails[index][attribute] = payload;
    this.setState({ staffDetails });

    //Only allow user to select one company representative
    //LOGIC: Remove the company representative if the user tries to select it a second time
    if (attribute === "designation") {
      if (payload.id === companyRepId) {
        this.removeStaffDetails(index);
      }
    }
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      customerName,
      category,
      type,
      address,
      incorpDate,
      rcNumber,
      debtRecovery,
      accountCode,
      types,
      customerCategories,
      designations,
      staffDetails,
      isEdit,
      companyRepId,
      allowSelfService,
    } = this.state;
    const { requesting } = this.props;

    return (
      <div>
        <ContentLayout
          requesting={requesting}
          pageTitle={`${isEdit ? "Edit" : "New"} Customer`}
          actions={
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          }
        >
          <Row>
            <Col lg="3">
              <FormGroup>
                <Label>Name&#x2a;</Label>
                <Input
                  type="text"
                  name="name"
                  id="customerName"
                  value={customerName}
                  onChange={(e) => this.handleChange(e, "customerName")}
                  required
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Customer category&#x2a;</Label>
                <SelectList
                  placeholder={"Select category..."}
                  data={customerCategories}
                  valueKey={"id"}
                  textField={"name"}
                  selectValue={category}
                  onValueChange={(event) => {
                    this.handleSelectChange(event, "category");
                  }}
                  disabled={isEdit ? true : false}
                />
                {isEdit && (
                  <p className="small text-danger">
                    This field cannot be edited
                  </p>
                )}
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Types&#x2a;</Label>
                <SelectList
                  placeholder={"Select types..."}
                  data={types}
                  valueKey={"id"}
                  textField={"name"}
                  selectValue={type}
                  onValueChange={(event) => {
                    this.handleSelectChange(event, "type");
                  }}
                  disabled={isEdit ? true : false}
                />
                {isEdit && (
                  <p className="small text-danger">
                    This field cannot be edited
                  </p>
                )}
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Address&#x2a;</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  value={address}
                  onChange={(e) => this.handleChange(e, "address")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="3">
              <FormGroup>
                <Label>RC number</Label>
                <Input
                  name="rcNumber"
                  id="rcNumber"
                  value={rcNumber}
                  onChange={(e) => this.handleChange(e, "rcNumber")}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Date of incorporation</Label>
                <DatePicker
                  date={incorpDate}
                  name="incorpDate"
                  value={incorpDate}
                  onChange={this.changeDatePicker}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Debt recovery(%)</Label>
                <Input
                  type="number"
                  name="debtRecovery"
                  id="debtRecovery"
                  value={debtRecovery}
                  onChange={(e) => this.handleChange(e, "debtRecovery")}
                />
              </FormGroup>
            </Col>
            <Col lg="3">
              <FormGroup>
                <Label>Account code</Label>
                <Input
                  type="text"
                  name="accountCode"
                  id="accountCode"
                  value={accountCode}
                  onChange={(e) => this.handleChange(e, "accountCode")}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="6">
              <Form>
                <FormGroup check>
                  <Label check className={"mr-4"}>
                    <Input
                      style={{ marginLeft: "-10rem", marginTop: "10px" }}
                      type="checkbox"
                      id="checkbox"
                      name={"allowSelfService"}
                      defaultChecked={allowSelfService}
                      onChange={(e) => {
                        this.checkboxChange(e, "allowSelfService");
                      }}
                    />
                  </Label>
                </FormGroup>
              </Form>
              <span className={"ml-4"}>Self-service</span>
            </Col>
          </Row>
          <h2 className="mt-5" style={{ fontWeight: "700" }}>
            Customer staff details
          </h2>
          <hr style={{ height: "12px" }}></hr>

          {staffDetails.map((staff, index) => {
            return (
              <Row key={`Staff Details ${index}`}>
                <Col lg="4">
                  <FormGroup>
                    {index === 0 && <Label>Title&#x2a;</Label>}
                    <SelectList
                      placeholder={"Select category..."}
                      data={designations}
                      valueKey={"id"}
                      textField={"name"}
                      selectValue={staff.designation}
                      notClearable={index === 0 ? true : false}
                      disabled={
                        staff.designation.id &&
                        staff.designation.id === companyRepId
                          ? true
                          : false
                      }
                      onValueChange={(event) => {
                        this.handleSelectChangeStaffDetails(
                          event,
                          "designation",
                          index
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    {index === 0 && <Label>First Name&#x2a;</Label>}
                    <Input
                      type="text"
                      name="staffName"
                      id="staffName"
                      value={staff.staffName}
                      onChange={(e) =>
                        this.handleChangeStaffDetails(e, "staffName", index)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    {index === 0 && <Label>Last Name&#x2a;</Label>}
                    <Input
                      type="text"
                      name="staffLastName"
                      id="staffLastName"
                      value={staff.staffLastName}
                      onChange={(e) =>
                        this.handleChangeStaffDetails(e, "staffLastName", index)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    {index === 0 && <Label>Phone number&#x2a;</Label>}
                    <Input
                      type="number"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={staff.phoneNumber}
                      onChange={(e) =>
                        this.handleChangeStaffDetails(e, "phoneNumber", index)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    {index === 0 && <Label>Email address&#x2a;</Label>}
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={staff.emailAddress}
                      onChange={(e) =>
                        this.handleChangeStaffDetails(e, "emailAddress", index)
                      }
                    />
                  </FormGroup>
                </Col>
                {this.state.allowSelfService === true && (
                  <Col lg="4">
                    <FormGroup>
                      {index === 0 && <Label>Role</Label>}
                      <Input
                        type="text"
                        name="role"
                        id="role"
                        value={
                          staff.designation.id === companyRepId
                            ? "Customer Admin"
                            : "Location User"
                        }
                        onChange={(e) =>
                          this.handleChangeStaffDetails(e, "role", index)
                        }
                      />
                    </FormGroup>
                  </Col>
                )}
                {(staff.designation.id === undefined ||
                  staff.designation.id !== companyRepId) && (
                  <Col lg="1">
                    {index === 0 && <Label className="invisible">Inv</Label>}
                    <FormGroup>
                      <h4
                        className="pt-2"
                        onClick={() => this.removeStaffDetails(index)}
                      >
                        <i className="fa fa-minus-circle"></i>
                      </h4>
                    </FormGroup>
                  </Col>
                )}
              </Row>
            );
          })}

          {!this.state.allowSelfService && (
            <h5
              onClick={this.addStaffDetails}
              className={"pointer-cursor"}
              style={{ color: "#7DD5F6" }}
            >
              <i
                className="fa fa-plus-circle pr-2"
                style={{ color: "#7DD5F6" }}
              ></i>
              Add another staff
            </h5>
          )}

          <div className="float-left mt-5">
            <Button
              color="success"
              className="btn-sm btn-rounded  mr-2 text-white"
              onClick={this.saveCustomer}
              disabled={requesting}
            >
              {isEdit ? "Update" : "Create"} customer
            </Button>
            <Button
              outline
              className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              Cancel
            </Button>
          </div>
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting } = state;
  return {
    user,
    requesting,
  };
};

export default connect(mapStateToProps)(NewCustomer);
