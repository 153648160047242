import React, {Component} from 'react';
import ContentLayout from "../../components/ContentLayout";
import {connect} from "react-redux";
import NoSidebarLayout from "../../layouts/layout-components/no-sidebar-layout";
import "./sprite-ui.scss"
import {commonService} from "../../_services";
import {appConstants} from "../../_constants";
import {alertActions} from "../../_actions";
import BackButton from '../../components/BackButton';
import {appHelpers} from "../../_helpers/app.helpers"
import axios from "axios";

let source;

class ConfirmPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlObject: null,
      responseData: {},
      status: "",
      failureResponse: "",
      modifiedUrlArray: []
    };
  
    source = axios.CancelToken.source();
  }
  
  componentWillMount() {
    let url = window.location.href;
    if( url.substring(0, url.indexOf("?")) === "") {
       return;
    } else {
  
      let baseUrlLength = url.substring(0, url.indexOf("?")).length + 1; // get length of URL after the ? character
      let remainingURL = '&' + url.substring(baseUrlLength); //slice of url after ? character and add & in front of the remaining URL string
  
      let splitRestUrl = remainingURL.split("&");
      
      //removed the base URL
      let urlArrayWithoutBase = splitRestUrl.slice(1);
  
      //constructed an object with the url query string and their values
      const urlObject = {
        transactionReference: null,
        paymentReference: null,
        responseCode: null,
        responseDescription: null,
        GatewayCode: null,
      };
  
      // Looped through each array item, got the values before and after ('=')
      // and stored them respectively in the urlObjProperty & urlObjValue
      //variables. Then assigned urlObjValue as value to respective
      //properties in urlObject
  
      const modifiedUrlArray = urlArrayWithoutBase.map(item => {
        let itemLength = item.substring(0, item.indexOf("=")).length;
        let urlObjProperty = item.substring(0, item.indexOf("="));
    
        let urlObjValue = item.substring(itemLength + 1);
        urlObject[urlObjProperty] = urlObjValue;
        
        return urlObjProperty;
      });
  
      this.setState({urlObject, modifiedUrlArray})
      
    }
  }
  
  componentDidMount(cancelToken) {
    const {dispatch} = this.props;
    const {urlObject} = this.state;
    
    if (urlObject) {
  
      dispatch(alertActions.startRequest());
      commonService.confirmPayment(urlObject, cancelToken)
        .then(res => {
          dispatch(alertActions.stopRequest());
          const {response} = res;
          if(res.status === appConstants.SUCCESS_RESPONSE) {
            this.setState({responseData: response.data, status: "success"})
        
          } else if (res.status === appConstants.ERROR_RESPONSE) {
            this.setState({responseData: response.data, failureResponse: response.message,  status: "failure"})
          }
        })
    }
  
  }

  navigateBack = () => {
    const {user, history} = this.props;
    
    if(appHelpers.isEmptyObject(user)) {
      history.push("/payment")
    } else {
      if (user.userRole === appConstants.ROLES.CUSTOMER_ADMIN || user.userRole === appConstants.ROLES.LOCATION_USER){
          history.push("/customer-search-invoice")
      } else {
        history.push({pathname: "/search-invoice", state: {
          from: `/search-invoice`
        }})
      }
    }
  }
  
  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
  }
  
  render() {
    const {urlObject, status, responseData, failureResponse} = this.state;
    return (
      <div>
        <NoSidebarLayout>
          <ContentLayout
            pageTitle={"Confirm Payment"}
            height={'70vh'}
            requesting={this.props.requesting}
            actions={
              <BackButton
              navigateBack={this.navigateBack}
              />
            }
          >
            <div className="confirm-payment">
              <div className="confirm-payment__details">
                {status === "success" && (
                  <div className={"confirm-payment__details-success"}>
                    <h3>Congratulations! Your payment was successful</h3>
                    <p><span>Reason</span>: <b>{responseData.reason}</b></p>
                    <p><span>Transaction Reference</span>: <b>{responseData.transactionReference}</b></p>
                    <p><span>Payment Reference</span>: <b>{responseData.paymentReference}</b></p>
                    <p><span>Amount Paid</span>: <b>{responseData.amountPaid}</b></p>
                    <p><span>Payment Currency</span>: <b>{responseData.paymentCurrency}</b></p>
                  </div>
                )
                }
  
                {status === "failure" && responseData && (
                  <div className={"confirm-payment__details-failed"}>
                    <h3>{responseData.message}</h3>
                    <p><span>Reason</span>: <b>{responseData.reason}</b></p>
                    <p><span>Transaction Reference</span>: <b>{responseData.transactionReference}</b></p>
                    <p><span>Payment Reference</span>: <b>{responseData.paymentReference}</b></p>
                    <p><span>Amount Paid</span>: <b>{responseData.amountPaid}</b></p>
                    <p><span>Payment Currency</span>: <b>{responseData.paymentCurrency}</b></p>
                  </div>
                )
                }
                {status === "failure" && !responseData && (
                  <div>
                    <h3>{failureResponse}</h3>
                  </div>
                )
                }
  
                {!urlObject && (
                  <div>
                    <h3>No payment history</h3>
                  </div>
                )}
                
              </div>
            </div>
          </ContentLayout>
        </NoSidebarLayout>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});


export default connect(mapStateToProps)(ConfirmPayment);