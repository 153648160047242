import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody, } from "reactstrap";
import { appHelpers } from "../../_helpers";
import ContentLayout from "../../components/ContentLayout";
import { FaArrowLeft, FaCheckCircle, FaTimes } from "react-icons/fa";
import { appConstants } from "../../_constants";
import SearchPanel from "./search-panel"
import moment from "moment";
import { store } from "../../_store";
import { connect } from 'react-redux';
import { alertActions, commonActions } from "../../_actions"
import { commonService } from "../../_services";
import PaginatedResult from "./records";
import ViewTemplate from "./viewTemplate";
import TableFormat from "./fmsDataTemplate";
import FullScreenModal from "../../components/FullScreenModal";
import Alert from "../../components/Alert";
import "./index.scss";
import axios from "axios";

let source;

const Categories = [
    { Id: 1, Name: 'Status' },
    { Id: 2, Name: 'Entities' },
    /*This should be catered for but the API is not supporting it at the moment*/
    // { Id_: 3, Name: 'Created By'},
];

const CreatedByCat = [
    { Name: "Everyone", Id_: "CB1", type: 3 },
    { Name: "Me", Id_: "CB2", type: 3 }
];

const StatusCat = [
    { Name: "All", Id_: -1, type: 1 },
    { Name: "Pending", Id_: appConstants.MAKER_CHECKER_STATUS.PENDING, type: 1 },
    { Name: "Approved", Id_: appConstants.MAKER_CHECKER_STATUS.APPROVED, type: 1 },
    { Name: "Rejected", Id_: appConstants.MAKER_CHECKER_STATUS.REJECTED, type: 1 }
];

class MakerChecker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(1, 'month'),
            endDate: new Date(),
            filterModes: Categories,
            currentFilterParameter: {},
            selectedFilterMode: 1,
            searchOptions: [],
            filterValues: [{ Name: "All", Id_: -1, type: 1 }],
            filterValueKey: '',
            filterLabelKey: '',
            //page error
            contentError: false,  //Errors that affect content on the page
            componentError: false,  //Errors generated within the component
            componentErrorMessages: [],
            error: false, //Error state mutable via Redux
            success: false, //Success message mutable via Redux
            //Notification
            notification: {
                // title: 'Default Title',
                message: 'Default Message',
                level: 'success',
                dismissible: true,
            },
            records: [],
            Size: 10,
            currentPage: 1,
            contentErrorText: false,
            openModal: false,
            totalCount: 0,
            payloadViewTemplate: null,
            ActiveRecord: false,
            Comment: '',
            Entities: [],
            modalClass: "",
            showAlertModal: false,
            alertMessage: "",
            alertIcon: "",
        };

        source = axios.CancelToken.source();
        // this._notificationSystem = null;
        // this._lastNotificationAdded = null;
    }

    componentDidMount() {
        /* Remove overflow hidden caused by modal */
        const body = document.body;
        body.style.overflow = "scroll";

        //Call branding if there is no method to display select agency view
        appHelpers.checkDerivedStateFromPropsMethod.call(this);

        const reduxState = store.getState();
        const { dispatch } = this.props;

        if (!reduxState.entities) {
            dispatch(commonActions.getEntities())
        } else {
            this.prepareEntities(reduxState.entities)
        }

        // Set default Category selection
        this.updateCatValue({ Name: 'Status', Id: 1 });
        this.handleCatChange({ Name: 'Status', Id: 1 });

        this.search(null);
    }


    componentWillReceiveProps(nextProps, nextContext) {
        const { entities } = nextProps;
        if (entities)
            this.prepareEntities(entities);
    }


    prepareEntities = (entities) => {
        const preparedEntities = entities.map(function (item) {
            return {
                type: 2,
                Id_: item.EntityTypeId,
                Name: item.EntityType,
                ...item
            }
        });
        this.setState({ Entities: preparedEntities })
    };


    updateCatValue = (newValue) => {
        this.setState({
            selectedFilterMode: newValue.Id
        });
        this.handleCatChange(newValue);
    }

    //populate the multi search options with appropriate category options
    handleCatChange = (option) => {
        if (option) {// incase all category options are remove
            switch (option.Id) {
                case 2:
                    return this.setState({
                        filterOptions: this.state.Entities,
                        filterLabelKey: 'Id_',
                        filterValueKey: 'Name',
                    });
                case 3:
                    return this.setState({
                        filterOptions: CreatedByCat,
                        filterLabelKey: 'Id_',
                        filterValueKey: 'Name',
                    });
                case 1:
                    return this.setState({
                        filterOptions: StatusCat,
                        filterLabelKey: 'Id_',
                        filterValueKey: 'Name',
                    });
                default:
                    return this.state;
            }
        }
        return this.setState({ searchOptions: [] });
    }

    // remove option if option in that category is already selected
    switchOptionsPerCategory = (currentArray, newArray, category) => {
        const { selectValue } = this.state;
        let currentCat = (category) ? category : selectValue;

        let optionToReplace = currentArray.filter(option => option.cat === currentCat.value)[0];

        if (optionToReplace) {
            return newArray.filter(option => option.label !== optionToReplace.label);
        } else {
            return newArray;
        }
    }

    //update selected options categories
    handleSelectChange = (newValue, category) => {
        let currentArray = this.state.searchArray;
        //debugger;
        let newArray = (currentArray.length > 0) ? this.switchOptionsPerCategory(this.state.searchArray, newValue, category) : newValue;
        this.setState({ searchArray: newArray });
    }

    handleSelectedFilterValuesChange = (target) => {
        if (!target) {
            this.setState({ filterValues: [] })
        }
        if (target) {
            let currFilterValues = this.state.filterValues;
            let newValue = target.filter(x => !currFilterValues.includes(x));
            let removedValue = currFilterValues.filter(x => !target.includes(x));
            if (removedValue.length === 0) {
                let found = false;
                currFilterValues.forEach(function (item, key) {
                    if (item.type === newValue[0].type) {
                        currFilterValues[key] = newValue[0];
                        found = true;
                    }
                });
                if (!found) {
                    currFilterValues = target;
                }
                this.setState({ filterValues: currFilterValues });
            } else {
                this.setState({ filterValues: target });
            }
        }

    };

    formatSearchParameters = (res) => {
        const { filterValues } = this.state;
        const { userName, userRole } = this.props.user;
        let params = {
            UserName: userName
        };

        let StatusId = false; let EntityTypeId = false;
        filterValues.forEach(item => {
            if (item.type === 1 && item.Id_ !== -1) {
                StatusId = item.Id_
            } else if (item.type === 2) {
                EntityTypeId = item.Id_
            }
        });
        if (StatusId !== false) params.StatusId = StatusId;
        if (EntityTypeId !== false) params.EntityTypeId = EntityTypeId;
        return {
            AppId: parseInt(appConstants.MAKER_CHECKER_APP_ID), 
            RoleName: userRole,
            ...params
        };

    };

    resetError() {
        // this.props.dispatch(modalActions.clear());
    }

    toggleModal = () => {
        this.setState({ openModal: !this.state.openModal, Comment: "" });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };


    search = async (pageNumber) => {
        const { dispatch } = this.props;
        const { searchArray, Size } = this.state;
        this.setState({ contentError: false });
        if (pageNumber === null) {
            dispatch(alertActions.startRequest());
            pageNumber = 1;
        } else {
            dispatch(alertActions.stopRequest());
        }

        let searchParams = this.formatSearchParameters(searchArray);

        let payload__ = {
            "Size": Size,
            "From": pageNumber,
            ...searchParams
        };
console.log(payload__, "checker");
        dispatch(alertActions.startRequest());
        commonService.searchMakerChecker(payload__, source.token)
            .then(res => {
                dispatch(alertActions.stopRequest());
                if (res.status === appConstants.SUCCESS_RESPONSE) {
                    const { totalCount, makeCheck } = res.response;
                    this.setState({
                        currentPage: pageNumber,
                        records: makeCheck, totalCount
                    });
                }
                else if (res.status === appConstants.ERROR_RESPONSE) {
                    dispatch(alertActions.error(res.response));

                }
            }).catch(err => {
                dispatch(alertActions.error(err));
                dispatch(alertActions.stopRequest());

            })
    };

    // handApprovalModalArray = (ActiveRecord) => {
    //     const payloadViewTemplate = ActiveRecord.payloadViewTemplate;
    //     this.setState({ ActiveRecord, payloadViewTemplate, openModal: !this.state.openModal });
    // };

    handApprovalModal = (ActiveRecord) => {
        const payloadViewTemplate = JSON.parse(ActiveRecord.payloadViewTemplate);
        this.setState({ ActiveRecord, payloadViewTemplate, openModal: !this.state.openModal });
    }; 
    handleApproval = (status) => {
        const { Comment, ActiveRecord } = this.state;
        const { dispatch, user } = this.props;
        const trimmedComment = Comment.trim();

        
        if (trimmedComment === "") {
            this.setState({ ErrorMessage: "Please provide a comment" })
        } else {
            dispatch(alertActions.startRequest());
            const payload = {
                makeCheckerId: ActiveRecord.id,
                comment: Comment,
                status: ActiveRecord.statusId === 0 ? 1 : null
            };
           
            commonService.approveMakerCheckerUpload(payload)
            // let requestHTTP = (status === appConstants.MAKER_CHECKER_STATUS.APPROVED) ? commonService.approveMakerCheckerUpload(payload, source.token) : commonService.approveMakerCheckerUpload(payload, source.token);
            .then(res => {
                if (res.status === appConstants.SUCCESS_RESPONSE) {
                    const successMessage = (status === appConstants.MAKER_CHECKER_STATUS.APPROVED) ? "Record approved successfully" : "Record rejected successfully";
                    this.setState({
                        showAlertModal: true,
                        alertMessage: successMessage,
                        alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
                    }, () => {
                        this.toggleModal();
                        appHelpers.dismissModal.call(this, "showAlertModal")
                    });
                    dispatch(alertActions.stopRequest());
                }
                else if (res.status === appConstants.ERROR_RESPONSE) {
                    this.setState({
                        showAlertModal: true,
                        alertMessage: res.response,
                        alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                    }, () => {
                        appHelpers.dismissModal.call(this, "showAlertModal");
                        this.toggleModal();
                    })
                }
                // dispatch(alertActions.error(res.response));
                dispatch(alertActions.stopRequest());

                /* Fetch updated records */
                this.search(this.state.currentPage);
            })
        }
    };

    handleReject = (status) => {
        const { Comment, ActiveRecord } = this.state;
        const { dispatch, user } = this.props;
        const trimmedComment = Comment.trim();

        if (trimmedComment === "") {
            this.setState({ ErrorMessage: "Please provide a comment" })
        } else {
            dispatch(alertActions.startRequest());
            const payload = {
                makeCheckerId: ActiveRecord.id,
                comment: Comment,
                status: ActiveRecord.statusId === 0 ? 2 : null
            };
            commonService.approveMakerCheckerUpload(payload)

            // let requestHTTP = (status === appConstants.MAKER_CHECKER_STATUS.APPROVED) ? commonService.approveMakerCheckerUpload(payload, source.token) : commonService.approveMakerCheckerUpload(payload, source.token);
            // requestHTTP
            .then(res => {
                if (res.status === appConstants.SUCCESS_RESPONSE) {
                    const successMessage = (status === appConstants.MAKER_CHECKER_STATUS.APPROVED) ? "Record approved successfully" : "Record rejected successfully";
                    this.setState({
                        showAlertModal: true,
                        alertMessage: successMessage,
                        alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
                    }, () => {
                        this.toggleModal();
                        appHelpers.dismissModal.call(this, "showAlertModal")
                    });
                    dispatch(alertActions.stopRequest());
                }
                else if (res.status === appConstants.ERROR_RESPONSE) {
                    this.setState({
                        showAlertModal: true,
                        alertMessage: res.response,
                        alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                    }, () => {
                        appHelpers.dismissModal.call(this, "showAlertModal");
                        this.toggleModal();
                    })
                }
                // dispatch(alertActions.error(res.response));
                dispatch(alertActions.stopRequest());

                /* Fetch updated records */
                this.search(this.state.currentPage);
            })
        }
    };

    renderAlertModal = (message, icon) => {
        let alertView = [];
        let count = 0;
        const { showAlertModal } = this.state;

        alertView.push(
            <FullScreenModal
                show={showAlertModal}
                key={count + 1}
                isInvoiceSheet={true}
                callback={this.hideAlertView}
                width={"35%"}
                top={"10%"}
            >
                <Alert icon={icon} message={message} />
            </FullScreenModal>
        );
        return alertView;
    };

    componentWillUnmount() {
        if (source) {
            source.cancel()
        }
    }

    render() {
        const { contentErrorText, filterModes, selectedFilterMode, filterOptions,
            filterLabelKey, filterValues, filterValueKey, openModal, ActiveRecord,
            Comment, currentPage, Size, records, totalCount, tableData,
            payloadViewTemplate, requesting, showAlertModal, alertIcon, alertMessage, EntityTypeId, componentErrorMessages
        } = this.state;
        console.log(payloadViewTemplate, "template");
        console.log(ActiveRecord, "record");

        return (
            <ContentLayout
                requesting={this.props.requesting}
                pageTitle={"Maker Checker"}
                actions={
                    <div className={"float-right"}>
                        <Button
                            outline
                            color="info"
                            className=" big-action-btn btn-rounded mr-2"
                            onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
                        >
                            <FaArrowLeft className={"icon-margin"} />
                            Back
                        </Button>

                    </div>
                }>
                <SearchPanel
                    contentErrorText={contentErrorText}
                    categories={filterModes}
                    selectedFilterMode={selectedFilterMode}
                    filterOptions={filterOptions}
                    filterLabelKey={filterLabelKey}
                    filterValueKey={filterValueKey}
                    filterValues={filterValues}
                    onFilterModeValueChange={this.updateCatValue}
                    onSelectedFilterValuesChange={this.handleSelectedFilterValuesChange}
                    onSearch={this.search}
                    startDateChange={startDate => this.setState({ startDate })}
                    endDateChange={endDate => this.setState({ endDate })}
                />
                <div className={"mt-4"}>
                    <PaginatedResult
                        records={records}
                        currentPage={currentPage}
                        navigate={this.search}
                        Size={Size}
                        totalCount={totalCount}
                        handApprovalModal={this.handApprovalModal}
                        // handApprovalModalArray={this.handApprovalModalArray}
                    />
                </div>

                {payloadViewTemplate !== null && typeof payloadViewTemplate === 'object' && !Array.isArray(payloadViewTemplate) && (
                    <Modal size={'lg'} isOpen={openModal} toggle={this.toggleModal} className={`${this.state.modalClass} maker-checker-modal`} style={{ top: "10%" }}>
                        <ModalHeader toggle={this.toggleModal}>Approval Form</ModalHeader>
                        <ModalBody>


                            <ViewTemplate
                                record={ActiveRecord}
                                Comment={Comment}
                                handleChange={this.handleChange}
                                componentErrorMessages={componentErrorMessages}
                                payload={payloadViewTemplate}
                            />
                        </ModalBody>
                        <ModalFooter>
                            {
                                (ActiveRecord &&
                                    ActiveRecord.statusId === appConstants.MAKER_CHECKER_STATUS.PENDING &&
                                    ActiveRecord.createdBy === this.props.user.userName
                                ) &&
                                <h6 className={'text-muted'}>
                                    Record is pending!
                                </h6>
                            }
                            {
                                (ActiveRecord &&
                                    ActiveRecord.statusId === appConstants.MAKER_CHECKER_STATUS.PENDING &&
                                    ActiveRecord.createdBy !== this.props.user.userName
                                ) &&
                                <span style={{marginRight: "17rem"}}>
                                    <Button className="btn-avi-primary" disabled={!Comment || requesting} onClick={(e) => this.handleApproval(appConstants.MAKER_CHECKER_STATUS.APPROVED)} >
                                        <FaCheckCircle /> Approve
                                    </Button>
                                    &nbsp;
                                    <Button color="danger" disabled={!Comment || requesting} onClick={(e) => this.handleReject(appConstants.MAKER_CHECKER_STATUS.REJECTED)}>
                                        &nbsp;&nbsp;<FaTimes /> Reject&nbsp;&nbsp;
                                    </Button>
                                </span>
                            }
                            {/*Approved Record*/}
                            {
                                (ActiveRecord && ActiveRecord.statusId === appConstants.MAKER_CHECKER_STATUS.APPROVED) &&
                                <h6 className={'text-avi-primary'}>
                                    Record Approved by {ActiveRecord.approvedBy}
                                </h6>

                            }
                            {/*Rejected Record*/}
                            {
                                (ActiveRecord && ActiveRecord.statusId === appConstants.MAKER_CHECKER_STATUS.REJECTED) &&
                                <h6 className={'text-danger'}>
                                    Record Rejected by {ActiveRecord.approvedBy}
                                </h6>

                            }

                        </ModalFooter>
                    </Modal>)}

                    {payloadViewTemplate !== null && Array.isArray(payloadViewTemplate) &&  (
                    <Modal size={'lg'} isOpen={openModal} toggle={this.toggleModal} className={`${this.state.modalClass} maker-checker-modal`} style={{ top: "10%" }}>
                        <ModalHeader toggle={this.toggleModal}>Approval Form</ModalHeader>
                        <ModalBody>
                            <TableFormat
                                record={ActiveRecord}
                                Comment={Comment}
                                handleChange={this.handleChange}
                                payload={payloadViewTemplate}
                            />
                        </ModalBody>
                        <ModalFooter>
                            {
                                (ActiveRecord &&
                                    ActiveRecord.statusId === appConstants.MAKER_CHECKER_STATUS.PENDING &&
                                    ActiveRecord.createdBy === this.props.user.userName
                                ) &&
                                <h6 className={'text-muted'}>
                                    Record is pending!
                                </h6>
                            }
                            {
                                (ActiveRecord &&
                                    ActiveRecord.statusId === appConstants.MAKER_CHECKER_STATUS.PENDING &&
                                    ActiveRecord.createdBy !== this.props.user.userName
                                ) &&
                                <span style={{marginRight: "17rem"}}>
                                    <Button className="btn-avi-primary" disabled={!Comment || requesting} onClick={(e) => this.handleApproval(appConstants.MAKER_CHECKER_STATUS.APPROVED)} >
                                        <FaCheckCircle /> Approve
                                    </Button>
                                    &nbsp;
                                    <Button color="danger" disabled={!Comment || requesting} onClick={(e) => this.handleReject(appConstants.MAKER_CHECKER_STATUS.REJECTED)}>
                                        &nbsp;&nbsp;<FaTimes /> Reject&nbsp;&nbsp;
                                    </Button>
                                </span>
                            }
                            {/*Approved Record*/}
                            {
                                (ActiveRecord && ActiveRecord.statusId === appConstants.MAKER_CHECKER_STATUS.APPROVED) &&
                                <h6 className={'text-avi-primary'}>
                                    Record Approved by {ActiveRecord.approvedBy}
                                </h6>

                            }
                            {/*Rejected Record*/}
                            {
                                (ActiveRecord && ActiveRecord.statusId === appConstants.MAKER_CHECKER_STATUS.REJECTED) &&
                                <h6 className={'text-danger'}>
                                    Record Rejected by {ActiveRecord.approvedBy}
                                </h6>

                            }

                        </ModalFooter>
                    </Modal>)}

                 

                {showAlertModal === true &&
                    this.renderAlertModal(alertMessage, alertIcon)}
            </ContentLayout>
        );
    }
}

const mapStateToProps = state => {
    const { modal, alert, progress, user, requesting, organizations, entities } = state;
    return {
        modal,
        alert,
        progress,
        user,
        requesting,
        organizations,
        entities
    };
};

export default connect(mapStateToProps)(MakerChecker);