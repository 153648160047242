import {appConstants} from '../_constants';
import {initialState} from "../_store";

export function organizations(state=initialState.organizations, action) {
    switch (action.type) {
        case appConstants.ORGANIZATION_SUCCESS:
            return action.organizations;
        default:
            return state
    }
}
