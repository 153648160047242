import React from 'react';
import {Card, CardBody} from 'reactstrap';

const RequestCard = ({color, bgColor, headerColor, action, value, getCardDetails}) => {
    return (
        <Card style={{ color: color, backgroundColor: bgColor, borderRadius: "10px"}} onClick={getCardDetails}>
            <CardBody>
               <h5 style={{fontWeight: "600px", color: headerColor}} >{action}</h5>
                <p style={{fontSize: "60px", marginBottom: 0}}>{value}</p>
            </CardBody>
        </Card>
    )
};

export default RequestCard;