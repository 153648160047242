import React, { Component } from "react";
import FullScreenModal from "./FullScreenModal";
import Alert from "./Alert";
import { connect } from "react-redux";
import { appConstants, modalConstants} from "../_constants";
import { modalActions} from "../_actions";
import {  FaCheckCircle,} from "react-icons/fa";


export const RenderAlertModal = ({ message, icon, showAlertModal, hideAlertView }) => {
  let alertView = [];
  let count = 0;
  // const { showAlertModal } = this.state;

  alertView.push(
    <FullScreenModal
      show={showAlertModal}
      key={count + 1}
      callback={hideAlertView}
      width={"35%"}
      // top={"10%"}
    >
      <Alert icon={icon} message={message} />
    </FullScreenModal>
  );
  return alertView;
  // return <div>Hello</div>;
};


class AlertModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
        modalOpen: false,
      visible: true,
        message: "hello"
    };
  }
  
  componentWillReceiveProps = function (nextProps) {
      const { modal } = nextProps;
      if (modal) {
          if (modal.type === modalConstants.NOTIFY) {
              const msg_ = modal.message;
              this.setState({
                  message: msg_,
                  notificationColor: "warning"
              });
              this.showAlertModal();
              this.props.dispatch(modalActions.clear());
          } else if (modal.type === modalConstants.SUCCESS) {
              const msg_ = modal.message;
              this.setState({
                  message: msg_,
                  notificationColor: "success"
              });
              this.showAlertModal();
              this.props.dispatch(modalActions.clear());
          } else if (modal.type === modalConstants.ERROR) {
              const msg_ =
                typeof modal.message === "string"
                  ? modal.message
                  : "Unable to process request";
              this.setState({
                  message: msg_,
                  notificationColor: "danger"
              });
              this.showAlertModal();
              this.props.dispatch(modalActions.clear());
          }
      }
  };

    clearAlertTimeout = () => {
        if (this.alertTimeout !== null) {
            clearTimeout(this.alertTimeout);
        }
    };

    hideAlertView = (response) => {
        this.setState({ modalOpen: response });
    };

  showAlertModal = () => {
      // const {dispatch} = this.props;
      this.setState({ modalOpen: true })
      this.clearAlertTimeout();
      this.alertTimeout = setTimeout(
        function() {
            this.setState({ modalOpen: false });
        }.bind(this),
        10000
      );
  }
  render() {
      const {modalOpen, message,} = this.state;
      // let count = 0;
    return (
      <div>
      <div style={{display: modalOpen ? "block" : "none"}}>
        {message}
      </div>
      <FullScreenModal
        show={modalOpen}
        // key={count + 1}
        // callback={this.hideAlertView}
        width={"35%"}
        top={"30%"}
      >
        <Alert icon={<FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR}/>} message={message} />
      </FullScreenModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, requesting, alert } = state;
  return {
    modal,
    requesting,
    alert
  };
};

export default connect(mapStateToProps)(AlertModal);
