import React, { Component } from "react";
import { Table} from "reactstrap";
import ServerPaginatedTable from "../../components/ServerPagination";
import "react-toggle/style.css"; // for ES6 modules
import moment from "moment";



const Records = (props) => {
  const { apiData} = props;
  return (
    <Table responsive={true} className={"table-main"}>
      <thead className={"th-head tracker-head"}>
        <tr>
          <th>Payment Reference No</th>
          <th>Invoice No</th>
          <th>Amount</th>
          <th>Payment Date</th>
          <th>Created Date</th>
        </tr>
      </thead>
      <tbody>
        {apiData.map((item, index) => {
          return (
            <tr key={index}>
              <th>{item.paymentReferenceNumber}</th>
              <th>{item.invoiceNumber}</th>
              <th>{item.amount}</th>
              <th>{moment(item.paymentDate).format("YYYY-MM-DD")}</th>
              <th>
                {moment(item.createdDate).format("YYYY-MM-DD")}
              </th>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default class TableStructure extends Component {
  render() {
    let {
      apiData,
      currentPage,
      fetchMore,
      pageSize,
      totalCount,
      user,
    } = this.props;
    const pageIndex = pageSize * (currentPage - 1) + 1;

    return (
      <div>
        
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageSize={pageSize}
          totalCount={totalCount}
          
         target={
            <Records
              apiData={apiData}
              pageIndex={pageIndex}
              user={user}
            />
          }
        />
      </div>
    );
  }
}
