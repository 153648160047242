import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const commonService = {
  login,
  logOut,
  forgotPassword,
  resetPassword,
  changePassword,
  searchFlights,
  getAllAirports,
  searchInvoice,
  getLocations,
  getCustomers,
  getRevenues,
  getStates,
  getCurrency,
  fetchVoidInvoices,
  createInvoice,
  initiateVoidRequest,
  fetchInvoiceSearchSummary,
  ProcessVoidRequest,
  searchAuditTrail,
  downloadSearchInvoice,
  getInvoiceAndPaymentPerRevenue,
  getInvoiceAndPaymentSummary,
  getMyActivities,
  getTopFiveInvoiceAndPaymentPerRevenue,
  downloadInvoice,
  shareInvoice,
  getAllRole,
  getRolePermissions,
  createEditRoles,
  searchLocation,
  createLocation,
  getAllRegions,
  getAllOrganizations,
  activateRole,
  deactivateRole,
  searchRoles,
  searchWorkflow,
  getUserByEmail,
  getWorkFlowLevels,
  getWorkFlowProcess,
  activateWorkFlow,
  deactivateWorkFlow,
  deleteWorkFlowLevel,
  updateWorkFlowLevel,
  activateLocation,
  deactivateLocation,
  searchManageService,
  createServiceType,
  searchUsers,
  createNewUsers,
  getSupervisors,
  createWorkFlow,
  getUserLocationRevenue,
  activateUser,
  deactivateUser,
  confirmEmail,
  adminResetPassword,
  searchAppEntityTypes,
  searchMakerChecker,
  createMakerChecker,
  approveMakerChecker,
  rejectMakerChecker,
  getAllDesignations,
  getOrganizationDetails,
  searchOrganizations,
  getCountries,
  getLocalGovernments,
  updateOrganization,
  getPaymentChannels,
  activateServiceType,
  deactivateServiceType,
  confirmNewUser,
  getRevenueCustomerMapping,
  getRevenueLocationMapping,
  getAuditTypes,
  getAdminDashboard,
  addWorkFlowLevels,
  invoiceAgingAnalysis,
  performanceTracker,
  sendNotification,
  downloadInvoiceAging,
  downloadPerformanceTracker,
  getSettingsByKey,
  downloadAuditTrail,
  getWebPaymentDetails,
  confirmPayment,
  getRevenueGroups,
  GetAllRevenuesAndRevenueGroup,
  GetSubRevenues,
  getEditableFields,
  createEditInvoice,
  GetInvoiceEditDetails,
  editInvoice,
  getInvoiceChanges,
  processEditInvoice,
  getInvoiceSettings,
  getInvoiceChangesForSearch,
  getAllCreditTypes,
  searchCreditNote,
  processCreditNote,
  downloadSearchReport,
  uploadReportSheet,
  InitiateReportApproval,
  approveMakerCheckerUpload,
  getAllRemitaChannel,
  getAllNotificationType,
  remitaFailureReport,
  getFooterOptions,
};

function login(payload, cancelToken) {
  return (
    appHelpers
      // .postRequest(`${appConstants.PAS_URL}/api/Account/LoginBDA`, payload)
      .postRequest(
        // `http://bda-new.test.vggdev.com/api/Account/Login`,
        `${appConstants.PAS_URL}/api/Account/LoginBDA`,
        payload,
        cancelToken
      )
      .then((res) => {
        const { error } = res.response;
        if (!error) {
          return res;
        } else {
          return appHelpers.formatPromiseResponse(
            error,
            appConstants.ERROR_RESPONSE
          );
        }
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function forgotPassword(email, cancelToken) {
  return (
    appHelpers
      // .getRequest(`${appConstants.PAS_URL}`)
      .getRequest(
        `${appConstants.PAS_URL}/api/Account/ForgotPasswordBDA?email=${email}`,
        cancelToken
      )
      // .getRequest(`${appConstants.PAS_URL}/api/airport/search`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function resetPassword(payload, cancelToken) {
  return (
    appHelpers
      // .postRequest(`http://bda-new.test.vggdev.com/api/Account/ResetPassword`, payload)
      .postRequest(
        `${appConstants.PAS_URL}/api/Account/ResetPasswordBDA`,
        payload,
        cancelToken
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function changePassword(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Account/ChangePasswordBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function ProcessVoidRequest(payload, cancelToken) {
  return (
    appHelpers
      // .postRequest(`${appConstants.PAS_URL}/api/Account/Login`, payload)
      .postRequest(
        `${appConstants.PAS_URL}/api/Invoice/ProcessVoidRequestBDA`,
        payload,
        cancelToken
      )
      .then((res) => {
        const { error } = res.response;
        if (!error) {
          return res;
        } else {
          return appHelpers.formatPromiseResponse(
            error,
            appConstants.ERROR_RESPONSE
          );
        }
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function logOut() {}

function searchFlights(payload) {
  return (
    appHelpers
      // .postRequest(`${appConstants.PAS_URL}/api/Flight/BSPTripsSearchFlight`, payload, true)
      // .postRequest(`${appConstants.PAS_URL}/api/Flight/BSPTripsSearchFlight`, payload, true)
      .postRequest(
        `${appConstants.PAS_URL}/api/Flight/SearchFlightBDA`,
        payload,
        true
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

//tHIS IS NOT BEING USED IN THIS PROJECT
function getAllAirports() {
  return (
    appHelpers
      .getRequest(`${appConstants.PAS_URL}`)
      // .getRequest(`${appConstants.PAS_URL}/api/airport/search`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function fetchVoidInvoices(payload, cancelToken) {
  return (
    appHelpers
      // .postRequest(`${appConstants.PAS_URL}/api/Invoice/GetVoidInvoiceRequest`, payload)
      .postRequest(
        `${appConstants.PAS_URL}/api/Invoice/VoidRequestSummaryBDA`,
        payload,
        cancelToken
      )
      .then((res) => {
        const { error } = res.response;
        if (!error) {
          return res;
        } else {
          return appHelpers.formatPromiseResponse(
            error,
            appConstants.ERROR_RESPONSE
          );
        }
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

//Search Invoice
function searchInvoice(payload, cancelToken) {
  return (
    appHelpers
      // .postRequest(`${appConstants.PAS_URL}/api/Invoice/InvoiceSearchBDA`, payload, true)
      .postRequest(
        `${appConstants.PAS_URL}/api/Invoice/InvoiceSearchBDA`,
        payload,
        cancelToken
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function getLocations(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Location/GetLocationsBDA?OrganizationId=${payload.organizationId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getCustomers(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Customer/GetCustomersBDA?OrganizationId=${
        payload.organizationId
      }&includeChildCustomers=${
        payload.includeChildCustomers ? payload.includeChildCustomers : false
      }`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getRevenues(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Revenue/GetRevenuesBDA?OrganizationId=${payload.organizationId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getStates(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Location/GetStatesBDA`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getCurrency(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Currency/GetCurrenciesBDA`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function createInvoice(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Invoice/CreateInvoiceBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function initiateVoidRequest(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Invoice/InitiateVoidBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function fetchInvoiceSearchSummary(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Invoice/InvoiceSearchSummaryBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function downloadSearchInvoice(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Invoice/DownloadSearchResultBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchAuditTrail(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Audit/SearchAuditTrailBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

// Dashboard api Integrations :)
function getInvoiceAndPaymentPerRevenue(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Dashboard/GetInvoiceAndPaymentPerRevenueBDA?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getInvoiceAndPaymentSummary(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Dashboard/GetInvoiceAndPaymentSummaryBDA?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAdminDashboard(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Dashboard/AdminDashboardBDA?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getMyActivities(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Dashboard/GetMyActivitiesBDA?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}
function getTopFiveInvoiceAndPaymentPerRevenue(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Dashboard/GetTop5InvoiceAndPaymentPerRevenueBDA?startDate=${payload.startDate}&endDate=${payload.endDate}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function downloadInvoice(payload) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Invoice/DownloadInvoiceBDA?InvoiceNumber=${payload.invoiceNumber}`
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function shareInvoice(payload) {
  return appHelpers
    .postRequest(`${appConstants.PAS_URL}/api/Invoice/ShareInvoiceBDA`, payload)
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAllRole(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Role/GetAllRoleBDA?OrganizationId=${payload.OrganizationId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getRolePermissions(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${
        payload.RoleId
          ? `${appConstants.PAS_URL}/api/Role/GetRolePermissionsBDA?RoleId=${payload.RoleId}`
          : `${appConstants.PAS_URL}/api/Role/GetRolePermissionsBDA`
      }`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function createEditRoles(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Role/ManageRolePermissionBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchLocation(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Location/SearchLocationBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function createLocation(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Location/CreateLocationBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAllRegions() {
  return appHelpers
    .getRequest(`${appConstants.PAS_URL}/api/Location/GetAllRegionBDA`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAllOrganizations(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Organization/GetAllOrganizationBDA`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchOrganizations(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Organization/SearchOrganizationBDA`,
      payload
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function deactivateRole(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Role/DeactivateRoleBDA?RoleId=${payload.RoleId}`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function activateRole(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Role/ActivateRoleBDA?RoleId=${payload.RoleId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchRoles(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Role/SearchRolesBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchWorkflow(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Workflow/GetAllWorkflowBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getUserByEmail(email, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/UserManagement/GetUserByEmailBDA?email=${email}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getWorkFlowLevels(id, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Workflow/GetWorkflowLevelsBDA?workflowId=${id}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function addWorkFlowLevels(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Workflow/AddWorkflowLevelBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getWorkFlowProcess(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Workflow/GetWorkflowProcessBDA`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function activateWorkFlow(workFlowId, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Workflow/ActivateWorkflowBDA?WorkflowId=${workFlowId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function deactivateWorkFlow(workFlowId) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Workflow/DeactivateWorkflowBDA?WorkflowId=${workFlowId}`
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function deleteWorkFlowLevel(workFlowLevelId, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Workflow/DeleteWorkflowLevelBDA?WorkflowLevelId=${workFlowLevelId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function updateWorkFlowLevel(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Workflow/UpdateWorkflowLevelBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function activateLocation(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Location/ActivateLocationBDA?LocationId=${payload.LocationId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function deactivateLocation(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Location/DeactivateLocationBDA?LocationId=${payload.LocationId}`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchManageService(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Revenue/SearchRevenueBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function createServiceType(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Revenue/CreateRevenueBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchUsers(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/UserManagement/SearchUsersBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function createNewUsers(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/UserManagement/CreateNewUserBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getSupervisors(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/UserManagement/GetSupervisorUsersBDA?agencyId=${payload.agencyId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function createWorkFlow(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Workflow/CreateWorkflowBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getUserLocationRevenue(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/UserManagement/GetUserLocationRevenueBDA?agencyId=${payload.agencyId}&UserId=${payload.UserId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function activateUser(userId, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/UserManagement/ActivateUserBDA?UserId=${userId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function deactivateUser(userId, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/UserManagement/DeactivateUserBDA?UserId=${userId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function confirmEmail(userId, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/UserManagement/UserEmailConfirmationBDA?UserId=${userId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function adminResetPassword(email, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Account/AdminResetPasswordBDA?email=${email}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchAppEntityTypes(AppId) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/RoleEntity/Application?appId=${AppId}`
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}
function createMakerChecker(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/MakerChecker/CreateMakerChecker`,
      payload
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchMakerChecker(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/MakerChecker/SearchPendingApprovalsBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function approveMakerChecker(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/MakerChecker/ApproveMakerChecker`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function rejectMakerChecker(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/MakerChecker/RejectMakerChecker`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAllDesignations(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Organization/GetAllDesignationBDA`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getOrganizationDetails(orgId, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Organization/GetOrganizationDetailsBDA?OrganizationId=${orgId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getCountries(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Location/GetCountriesBDA`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getLocalGovernments(stateId, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Location/GetLocalGovernmentsBDA?StateId=${stateId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function updateOrganization(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Organization/UpdateOrganizationBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getPaymentChannels(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Revenue/GetPaymentChannelsBDA`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function activateServiceType(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Revenue/ActivateRevenueBDA?RevenueId=${payload.RevenueId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function deactivateServiceType(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Revenue/DeactivateRevenueBDA?RevenueId=${payload.RevenueId}`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function confirmNewUser(payload) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/UserManagement/ConfirmUserBDA?UserToken=${payload.userToken}`
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getRevenueCustomerMapping(revenueId, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Revenue/GetRevenueCustomerMappingBDA?revenueId=${revenueId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getRevenueLocationMapping(revenueId, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Revenue/GetRevenueLocationMappingBDA?revenueId=${revenueId}`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAuditTypes(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Audit/GetAuditActionBDA`,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function invoiceAgingAnalysis(payload, cancelToken) {
  return (
    appHelpers
      // .postRequest( `http://bda-new.test.vggdev.com/api/InvoiceAgingAnalysis/Search`, payload)
      .postRequest(
        `${appConstants.PAS_URL}/api/InvoiceAgingAnalysis/SearchBDA`,
        payload,
        cancelToken
      )
      .then((res) => {
        const { error } = res.response;
        if (!error) {
          return res;
        } else {
          return appHelpers.formatPromiseResponse(
            error,
            appConstants.ERROR_RESPONSE
          );
        }
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function performanceTracker(payload, cancelToken) {
  return (
    appHelpers
      // .postRequest( `http://bda-new.test.vggdev.com/api/InvoiceAgingAnalysis/PerformanceTracker`, payload)
      .postRequest(
        `${appConstants.PAS_URL}/api/InvoiceAgingAnalysis/PerformanceTrackerBDA`,
        payload,
        cancelToken
      )
      .then((res) => {
        const { error } = res.response;
        if (!error) {
          return res;
        } else {
          return appHelpers.formatPromiseResponse(
            error,
            appConstants.ERROR_RESPONSE
          );
        }
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function sendNotification(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/InvoiceAgingAnalysis/SendNotificationBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function downloadInvoiceAging(payload) {
  return (
    appHelpers
      .postRequest(
        `${appConstants.PAS_URL}/api/InvoiceAgingAnalysis/DownloadInvoiceAgingAnalysisBDA`,
        payload
      )
      // .postRequest(`http://bda-new.test.vggdev.com/api/InvoiceAgingAnalysis/DownloadInvoiceAgingAnalysis`, payload)
      .then((res) => {
        const { error } = res.response;
        if (!error) {
          return res;
        } else {
          return appHelpers.formatPromiseResponse(
            error,
            appConstants.ERROR_RESPONSE
          );
        }
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function downloadPerformanceTracker(payload, cancelToken) {
  return (
    appHelpers
      .postRequest(
        `${appConstants.PAS_URL}/api/InvoiceAgingAnalysis/DownloadPerformanceTrackerBDA`,
        payload,
        cancelToken
      )
      // .postRequest(`http://bda-new.test.vggdev.com/api/InvoiceAgingAnalysis/DownloadPerformanceTracker`, payload)
      .then((res) => {
        const { error } = res.response;
        if (!error) {
          return res;
        } else {
          return appHelpers.formatPromiseResponse(
            error,
            appConstants.ERROR_RESPONSE
          );
        }
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function getSettingsByKey(key, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Settings/GetSettingsByKeyBDA?Key=${key}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getRevenueGroups(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Revenue/GetRevenueGroupsBDA?agencyId=${payload.agencyId}&isDirectorate=${payload.isDirectorate}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function downloadAuditTrail(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Audit/DownloadAuditTrailBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getWebPaymentDetails(invoiceNumber) {
  return appHelpers
    .getRequestWithoutAuthorization(
      `${appConstants.PAS_URL}/api/Payment/GetWebPaymentDetailsBDA?InvoiceNumber=${invoiceNumber}`
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function confirmPayment(payload, cancelToken) {
  return appHelpers
    .postRequestWithoutAuthorization(
      `${appConstants.PAS_URL}/api/Payment/ConfirmPaymentBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

//Revenue groups
function GetAllRevenuesAndRevenueGroup(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Revenue/GetAllRevenuesAndRevenueGroupsBDA?OrganizationId=${payload.organizationId}&IsSelfService=${payload.isSelfService}
`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function GetSubRevenues(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Revenue/GetSubRevenuesBDA?OrganizationId=${payload.organizationId}&RevenueGroupId=${payload.revenueGroupId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

// edit invoice settings
function getEditableFields(payload, cancelToken) {
  return appHelpers
    .getRequest(
           `${appConstants.PAS_URL}/api/Settings/GetInvoiceSettingsBDA?agencyId=${payload.agencyId}`
    ,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}



function createEditInvoice(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Settings/ManageInvoiceSettingsBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function GetInvoiceEditDetails(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Invoice/GetInvoiceEditDetailsBDA?InvoiceNumber=${payload.invoiceNumber}&invoiceId=${payload.invoiceId}&agencyId=${payload.agencyId}`,cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function processEditInvoice(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Invoice/ProcessEditInvoiceBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function editInvoice(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Invoice/EditInvoiceBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getInvoiceChanges(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Invoice/GetInvoiceChangesBDA?InvoiceNumber=${payload.invoiceNumber}&agencyId=${payload.agencyId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getInvoiceSettings(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Settings/GetInvoiceSettingsBDA?agencyId=${payload.agencyId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getInvoiceChangesForSearch(payload, cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Invoice/GetInvoiceChangesForSearchBDA?InvoiceNumber=${payload.invoiceNumber}&agencyId=${payload.agencyId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAllCreditTypes(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/CreditNoteType/getallBDA`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function searchCreditNote(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/CreditNoteRequest/SearchBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function processCreditNote(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/CreditNoteRequest/ProcessBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function downloadSearchReport(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/CreditNoteRequest/SearchDownloadBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

//Report sheet upload

function uploadReportSheet(payload, data,cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/InvoiceStatusManagement/UploadFileBDA?replaceRRR=${payload.replaceRRR}`,
      data,
      cancelToken,
    
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}



function InitiateReportApproval(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/InvoiceStatusManagement/InitiateApprovalBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      const { error } = res.response;
      if (!error) {
        return res;
      } else {
        return appHelpers.formatPromiseResponse(
          error,
          appConstants.ERROR_RESPONSE
        );
      }
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function approveMakerCheckerUpload(payload, cancelToken) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/MakerChecker/ApproveMakerCheckerBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAllRemitaChannel(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Open/GetAllRemitaChannelBDA`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAllNotificationType(cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Open/GetAllRemitaReportTypeBDA`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function remitaFailureReport(payload, cancelToken) {
  return appHelpers
    .postRequest(
        // `https://bda-new.test.vggdev.com/api/Open/GetRemitaFailureReport`,
      `${appConstants.PAS_URL}/api/Open/GetRemitaFailureReportBDA`,
      payload,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getFooterOptions(payload,cancelToken) {
  return appHelpers
    .getRequest(
      `${appConstants.PAS_URL}/api/Open/GetInvoiceFooterOptionsBDA?agencyId=${payload.agencyId}`,
      cancelToken
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

