export const apiConstants = {
  CURRENCIES_SUCCESS: "CURRENCIES_SUCCESS",
  REVENUES_SUCCESS: "REVENUES_SUCCESS",
  SUB_REVENUES_SUCCESS: "SUB_REVENUES_SUCCESS",
  LOCATIONS_SUCCESS: "LOCATIONS_SUCCESS",
  REVENUE_LOCATION_SUCCESS: "REVENUE_LOCATION_SUCCESS",
  FETCH_BANKS_SUCCESS: "FETCH_BANKS_SUCCESS",
  FETCH_ACCOUNT_TYPES_SUCCESS: "FETCH_ACCOUNT_TYPES_SUCCESS",
  FETCH_BANK_ACCOUNT_SUCCESS: "FETCH_BANK_ACCOUNT_SUCCESS",
  CREATE_BANK_ACCOUNT_SUCCESS: "CREATE_BANK_ACCOUNT_SUCCESS",
  // REMOVE_BANK_ACCOUNT_SUCCESS: "REMOVE_BANK_ACCOUNT_SUCCESS",
  ACCOUNT_REVENUE_LOCATION_SUCCESS: "ACCOUNT_REVENUE_LOCATION_SUCCESS",
  ACTIVATE_BANK_ACCOUNT_SUCCESS: "ACTIVATE_BANK_ACCOUNT_SUCCESS",
  DEACTIVATE_BANK_ACCOUNT_SUCCESS: "DEACTIVATE_BANK_ACCOUNT_SUCCESS",
  ACTIVATE_DEACTIVATE_BANK_ACCOUNT_SUCCESS:
    "ACTIVATE_DEACTIVATE_BANK_ACCOUNT_SUCCESS",
  SAVE_SELF_SERVICE_REVENUES: "SAVE_SELF_SERVICE_REVENUES",
  SAVE_SELF_SERVICE_LOCATIONS: "SAVE_SELF_SERVICE_LOCATIONS",
  SAVE_SELF_SERVICE_USER_LOCATIONS: "SAVE_SELF_SERVICE_USER_LOCATIONS",
  SAVE_SELF_SERVICE_CUSTOMER_DETAILS: "SAVE_SELF_SERVICE_CUSTOMER_DETAILS",
  CUSTOMER_SUCCESS: "CUSTOMER_SUCCESS",
  INVOICE_SETTINGS_SUCCESS: "INVOICE_SETTINGS_SUCCESS",
  INVOICE_CHANGES_SUCCESS: "INVOICE_CHANGES_SUCCESS",
  INVOICE_CHANGES_FOR_SEARCH_SUCCESS: "INVOICE_CHANGES_FOR_SEARCH_SUCCESS",
  CREDITNOTE_TYPE: "CREDITNOTE_TYPE_SUCCESS"
};
