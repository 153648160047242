import React, { Component } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import ServerPaginatedTable from "../../components/ServerPagination";

const UploadSheetTable = (props) => {
  const { tableData } = props;
  return (
    <Table
      responsive={true}
      className={"table-main"}
      style={{ backgroundColor: "white" }}
    >
      <thead className={"audit-head"}>
        <tr>
          <th style={{ padding: "none" }}>Invoice Number</th>
          <th style={{ padding: "none" }}>Amount</th>
          <th style={{ padding: "none" }}>Narration</th>
          <th style={{ padding: "none" }}>Payor</th>
          <th style={{ padding: "none" }}>Date Transmitted</th>
          <th style={{ padding: "none" }}>Errors</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((item, index) => {
          return (
            <tr key={index} style={{ borderTop: "12px solid transparent" }}>
              <th>{item.invoiceNumber}</th>
              <th>{item.amount}</th>
              <th>{item.narration}</th>
              <th>{item.payor}</th>
              <th>{moment(item.dateTransmitted).format("YYYY-MM-DD")}</th>
              {item.errorComment !== "" ? (
                <th style={{ backgroundColor: "red" }}>{item.errorComment}</th>
              ) : (
                <th>{item.errorComment}</th>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default class TableFormat extends Component {
  render() {
    let {
      tableData,
      currentPage,
      fetchMore,
      pageSize,
      totalCount,
      onClickDecline,
      onClickApprove,
      requesting,
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageSize={pageSize}
          requesting={requesting}
          actionButtons
          onClickDecline={(e) => onClickDecline()}
          onClickApprove={(e) => onClickApprove(e)}
          totalCount={totalCount}
          target={
            <UploadSheetTable tableData={tableData} pageIndex={pageIndex} />
          }
        />
      </div>
    );
  }
}
