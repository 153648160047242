import React from "react";


const Spinner = ({ size, visible, align="left" }) => {
  let fontSize = `${size}px`;
  return (
    align === "center"?visible&&<center><i className="fa fa-spinner fa-spin" style={{fontSize:fontSize}}></i></center>
    :
     visible&&<i className="fa fa-spinner fa-spin" style={{fontSize:fontSize, color:"black"}}></i>
  );
};

export default Spinner;