import React from 'react';
import { modalActions} from "../../_actions";
import FullScreenModal from "../FullScreenModal";
import {connect} from "react-redux";
import {appHelpers} from "../../_helpers";

const CreatedInvoiceFullScreenModal = ({
  displayCreatedInvoice,
  dispatch,
  invoiceSheetActions,
  renderInvoiceView,
  requesting,
  invoiceSheetNestedActions,
}) => {
  return (
    <FullScreenModal
      show={displayCreatedInvoice.show}
      isInvoiceSheet={true}
      actions={invoiceSheetActions}
      nestedActions={invoiceSheetNestedActions}
      callback={(res) => {
        appHelpers.fullScreenCallback.call(
          this,
          res,
          dispatch,
          displayCreatedInvoice,
          modalActions.displayCreatedInvoice({
            show: false,
          })
        );
      }}
      zIndex={requesting ? 10 : 999}
    >
      {renderInvoiceView}
    </FullScreenModal>
  );
};

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps)(CreatedInvoiceFullScreenModal);

