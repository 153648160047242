import React, { Component } from "react";
import { Table, Badge } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import ToolTipItem from "../../../components/Tooltip";
import { FaTrashAlt } from "react-icons/fa";
import "./index.scss";


const Records = ( props) => {
  const {tableData, isShowModal, deleteWorkFlow, process} = props;

  return (
      <div>
          <h4 style={{padding:"10px"}}>{process}</h4>
    <Table responsive={true} style={{ fontSize: "14px", fontWeight: "600", cursor: "pointer"}}>
      <thead style={{ backgroundColor: "#D9F7EB", color: "#000" }}>
        <tr>
          <th style={{ padding: "none" }}>Level</th>
          <th style={{ paddingRight: "none" }}>Mapped to</th>
          <th style={{ paddingRight: "none"}}>Action</th>
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.map((item,index) => {
          return (
            <tr key={index}>
              <th style={{ paddingRight: "120px" }}>{item.workflowLevel}</th>
              <th style={{ paddingRight: "90px" }}>{item.personName}<span style={{paddingLeft:"7px"}}><Badge
              onClick={(e) => isShowModal(e, item)}
              className={"map-badge"}
                size="xl" pill>Change</Badge></span>{
                  
                } </th>   
                      
              <th style={{ padding: "none" }}>{
                  <span onClick={() => deleteWorkFlow(item)}>
                    <ToolTipItem
                    //   key={"viewRecord"}
                      title={<FaTrashAlt size={"15"} color={"#F88071"} />}
                      content="Action"
                    />
                  </span>
                }</th>        
            </tr>
          );
        })}
      </tbody>
    </Table>
      </div>
  );
};


export default class TableStructure extends Component {
  render() {
    let {
      tableData,
      currentPage,
      fetchMoreRecords,
      pageSize,
      totalCount,
      isShowModal,
        deleteWorkFlow,
      process
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : ((pageSize * (currentPage - 1)) + 1);
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMoreRecords}
          pageSize={pageSize}
          totalCount={totalCount}
          target={<Records tableData={tableData}   
          pageIndex={pageIndex}
          process={process}
          isShowModal={isShowModal} deleteWorkFlow={deleteWorkFlow}/>}
        />
      </div>
    );
  }
}
