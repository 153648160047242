import {appConstants} from '../_constants';
import {initialState} from "../_store";

export function states(state=initialState.states, action) {
    switch (action.type) {
        case appConstants.STATE_SUCCESS:
            return action.states;
        default:
            return state
    }
}
