import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

export const MonthDatePicker = ({ date, setDate }) => {
  return (
    <DatePicker
      selected={date}
      onChange={date => setDate(date)}
      dateFormat="MM/yyyy"
      showMonthYearPicker
    />
  );
};

export const DatePicker = ({ date, onChange, disabled }) => {
  return (
    <Datetime
      //   defaultValue={date}
      value={date}
      locale="en-gb"
      closeOnSelect
      timeFormat={false}
      dateFormat="DD-MM-YYYY"
      inputProps={{ disabled: disabled }}
      onChange={onChange}
    />
  );
};
