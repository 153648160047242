import React, { Component } from "react";
import { Table, Badge } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "react-toggle/style.css" // for ES6 modules
import Toggle from 'react-toggle'
import "./index.scss";
import {appConstants} from "../../../_constants";


const Records = (props) => {
  const {apiData, handleSwitchChecked, user} = props;
  return (
    <Table responsive={true}  className={"table-main"}>
      <thead className={"th-head"}>
        <tr>
          <th style={{ padding: "none" }}>Location</th>
          <th style={{ padding: "none" }}>Code</th>
          {user.vggRole === appConstants.ROLES.VGG_ADMIN &&
          <th style={{padding: "none"}}>Agency</th>
          }
          <th style={{ padding: "none" }}>State</th>
          <th style={{ padding: "none" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {apiData.map((item,index) => {
          return (
            <tr key={index}>
              <th style={{ padding: "none" }}>{item.location}</th>
              <th style={{ padding: "none" }}>{item.code}</th>
              {user.vggRole === appConstants.ROLES.VGG_ADMIN &&
              <th style={{ padding: "none" }}>{item.agencyName}</th>
              }

              <th style={{ padding: "none" }}>{item.stateName}</th>
              <th style={{ padding: "none" }}>
                {item.status !== false  &&(
                <Badge
              className={"badge-design"}
              disabled={true}
              onClick={props.toggleModalFunction.bind(this,item)}
              size="xl" pill>Edit</Badge>)}{
                <label className={"toggle-label"}>
                      <Toggle
                          // defaultChecked={item.status}
                          checked={item.status}
                          onChange={(e) => handleSwitchChecked(e, item)} />
                  </label>
                }</th>        
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};


export default class TableStructure extends Component {
  render() {
    let {
      apiData,
      currentPage,
      fetchMore,
      pageSize,
      totalCount,
      toggleModalFunction,
      handleSwitchChecked,
      toggle,
      acivateLocation,
      changeSwitch,
        user
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : ((pageSize * (currentPage - 1)) + 1);
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageSize={pageSize}
          totalCount={totalCount}
          target={<Records apiData={apiData}
                           toggleModalFunction={toggleModalFunction}
                           changeSwitch={changeSwitch}
                           toggle={toggle}
                           acivateLocation={acivateLocation}
                           handleSwitchChecked={handleSwitchChecked}
                           pageIndex={pageIndex}
                           user={user}
          />}
        />
      </div>
    );
  }
}
