import React from "react";
import {
    Button,
    Form,
    FormGroup,
    Label,
    Col,
    Container,
    Row
} from "reactstrap";

import SelectList from "../../components/SelectList/selectList";

const SearchPanel = ({
                         requestTypes,
                         requestType,
                         onFilterModeValueChange,
                         selectChange,
                         onSearch,
                      
                     }) => {
    return (
        <Form onSubmit={e => e.preventDefault()}>
            <Container>
                <Row>
                    <Col xs="6" sm="4">
                        <FormGroup className="mb-4">
                            <Label>Request Type</Label>
                            <SelectList
                                data={requestTypes}
                                valueKey={"id"}
                                textField={"name"}
                                selectValue={requestType}
                                onValueChange={(event) => {
                                    selectChange(event, "requestType");
                                  }}                                
                                placeholder={"Select Type..."}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs="6" sm="1">
                        <label>&nbsp;</label>
                        <Button
                          color={"success"}
                          className="btn-sm btn-rounded px-4 py-2 mr-2 text-white"
                          onClick={() => onSearch(1)}>
                            Search
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
};

export default SearchPanel;
