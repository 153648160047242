import React from "react";
import SelectList from "../SelectList/selectList";
import {
  Card,
  CardBody,
  CardTitle,
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const ServiceType = ({
  modalTitle,
  serviceValue,
  codeValue,
  agencies,
  agency,
  selectChange,
  handleChange,
  rateValue,
  showRate,
  addClick,
  addText,
  cancelClick,
  checkboxChange,
  taxInclusive,
  disabled,
  requesting,
  defaultValue,
  allowUnRegisterCustomer,
  allowSelfService,
  decimalPrecision,
  componentErrorMessages,
  revenueGroups,
  revenueGroup,
}) => {
  return (
    <Card style={{ borderRadius: "10px" }}>
      <CardBody style={{ padding: "30px" }}>
        <CardTitle
          style={{
            fontSize: "18px",
            padding: "5px",
            fontWeight: "bold",
            borderBottom: "0.5px solid rgba(0, 0, 0, 0.3)",
            marginBottom: "20px",
          }}
        >
          {modalTitle}
        </CardTitle>

        <Form>
          <Row form>
            <Col md="6">
              <FormGroup>
                <Label>Service Type Name</Label>
                <Input
                  type="text"
                  value={serviceValue}
                  name="serviceValue"
                  onChange={handleChange}
                />
              </FormGroup>
              {componentErrorMessages.serviceValueError && (
                <span className={"inputError"}>
                  {componentErrorMessages.serviceValueError}
                </span>
              )}
            </Col>

            <Col md="6">
              <FormGroup>
                <Label>Code</Label>
                <Input
                  type="text"
                  value={codeValue}
                  name="codeValue"
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md="6">
              <FormGroup>
                <Label>Agency</Label>
                <SelectList
                  placeholder={"Select agency"}
                  data={agencies}
                  valueKey={"id"}
                  textField={"name"}
                  selectValue={agency["id"]}
                  disabled={disabled}
                  defaultValue={defaultValue}
                  onValueChange={(event) => {
                    selectChange(event, "agency");
                  }}
                />
                 {componentErrorMessages.agencyError && (
                <span className={"inputError"}>
                  {componentErrorMessages.agencyError}
                </span>
              )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Rate</Label>
                <Input
                  type="text"
                  value={rateValue}
                  name="rateValue"
                  onChange={handleChange}
                  disabled={disabled}
                />
              </FormGroup>
              {componentErrorMessages.rateValue && (
                <span className={"inputError"}>
                  {componentErrorMessages.rateValue}
                </span>
              )}
            </Col>
          </Row>
          <Row form>
            <Col md="6">
              <FormGroup>
                <Label>Decimal Precision</Label>
                <Input
                  type="text"
                  value={decimalPrecision}
                  name="decimalPrecision"
                  onChange={handleChange}
                  min={2}
                  max={5}
                  required
                />
              </FormGroup>
              {componentErrorMessages.decimalPrecisionError && (
                <span className={"inputError"}>
                  {componentErrorMessages.decimalPrecisionError}
                </span>
              )}
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Revenue Group</Label>
                <SelectList
                  placeholder={"Select group..."}
                  data={revenueGroups}
                  valueKey={"id"}
                  textField={"name"}
                  selectValue={revenueGroup}
                  onValueChange={(event) => {
                    selectChange(event, "revenueGroup");
                  }}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row className={"mb-2"}>
            {showRate && (
              <>
                <Col md="4" sm="12">
                  <FormGroup check>
                    <Label check className={"mr-4"}>
                      <Input
                        style={{ marginLeft: "-10rem", marginTop: "10px" }}
                        type="checkbox"
                        id="checkbox"
                        defaultChecked={taxInclusive}
                        name={"taxInclusive"}
                        onChange={(e) => checkboxChange(e)}
                      />
                    </Label>
                  </FormGroup>
                  <span className={"ml-4"}>Tax Inclusive</span>
                </Col>
                <Col md="4" sm="12">
                  <FormGroup check>
                    <Label check className={"mr-4"}>
                      <Input
                        style={{ marginLeft: "-10rem", marginTop: "10px" }}
                        type="checkbox"
                        id="checkbox"
                        name={"allowUnRegisterCustomer"}
                        defaultChecked={allowUnRegisterCustomer}
                        onChange={(e) => checkboxChange(e)}
                      />
                    </Label>
                  </FormGroup>
                  <span className={"ml-4"}>Allow unregister customer</span>
                </Col>
              </>
            )}
            <Col md="4" sm="12">
              <FormGroup check>
                <Label check className={"mr-4"}>
                  <Input
                    style={{ marginLeft: "-10rem", marginTop: "10px" }}
                    type="checkbox"
                    id="checkbox"
                    name={"allowSelfService"}
                    defaultChecked={allowSelfService}
                    onChange={(e) => checkboxChange(e)}
                    disabled={disabled}
                  />
                </Label>
              </FormGroup>
              <span className={"ml-4"}>Self-service</span>
            </Col>
          </Row>
          <div style={{ float: "left", marginTop: "10px" }}>
            <Button
              color="success"
              size="sm"
              className="btn-rounded  mr-2 text-white"
              disabled={requesting}
              onClick={() => addClick()}
            >
              {addText}
            </Button>
            <Button
              outline
              className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
              disabled={requesting}
              onClick={cancelClick}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ServiceType;
