import React from "react";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Container,
} from "reactstrap";

const FilterParameters = ({
  name,
  searchOrganizations,
  requesting,
  handleChange,
}) => {
  return (
    <Container>
      <Row>
        <Col md="3" xs="12">
          <FormGroup>
            <Label>Organization Name</Label>
            <Input
              type="text"
              name="name"
              value={name}
              onChange={(e) => handleChange(e, "name")}
            />
          </FormGroup>
        </Col>

        <Col md="3" xs="12" className="text-right">
          <FormGroup>
            <Label className="invisible">Invisible Button Label</Label>
            <Button
              className="btn-rounded create-action-btn text-white w-75"
              onClick={() => searchOrganizations()}
              color={"success"}
              disabled={requesting}
            >
              Search
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default FilterParameters;
