import React from 'react'
import {Row, Col, Button} from "reactstrap";

function ViewEditedRecord({
  displayOldInvoiceRecord,
  displayNewInvoiceRecord,
  showDeclineApproveButtons,
  onClickDecline,
  onClickApprove,
}) {
  return (
    <>
      <Row>
        <Col md="6">{displayOldInvoiceRecord}</Col>
        <Col md="6">{displayNewInvoiceRecord}</Col>
      </Row>

      {showDeclineApproveButtons && (
        <Row>
          <Col md="12" className="d-flex justify-content-center">
            <Button
              color="danger"
              className="btn-sm btn-rounded px-4 mr-2 text-white"
              onClick={onClickDecline}
            >
              Decline
            </Button>
            <Button
              color="success"
              className="btn-sm btn-rounded px-4 mr-2 text-white"
              onClick={onClickApprove}
            >
              Approve
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ViewEditedRecord;