import React from 'react'
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import "./index.scss"


 function CreditNoteCard({title, text, link, clickedCard}) {
    return (
        <Card onClick={clickedCard} tabindex="1" className="credit-note__card-wrapper">
        <CardBody>
          <CardTitle className="credit-note__card-title">{title}</CardTitle>
          <CardText>
            {text}
          </CardText>

        </CardBody>
      </Card>
    );
}

export default CreditNoteCard;