import React from "react";
import { Row, Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { DatePicker } from "../../../components/DatePicker";
import SelectList from "../../../components/SelectList/selectList";

const CreditNoteSearchPanel = ({
  invoiceNumber,
  selectChange,
  revenueLine,
  revenue,
  subRevenues,
  subRevenue,
  states,
  state,
  customer,
  customers,
  locations,
  location,
  status,
  statusArray,
  dateTypes,
  dateType,
  changeDatePicker,
  currencies,
  currency,
  startDate,
  endDate,
  referenceNumber,
  handleChange,
  searchInvoiceNumber,
  clearInputs
}) => {
  return (
    <Form>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="customer"> Customer Name </Label>
            <SelectList
              placeholder={"Select customer..."}
              data={customers}
              valueKey={"customerName"}
              textField={"customerName"}
              selectValue={customer}
              onValueChange={(event) => {
                selectChange(event, "customer");
              }}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="invoiceNumber">Invoice Number</Label>
            <Input
              type="text"
              name="invoiceNumber"
              id="invoiceNum"
              value={invoiceNumber}
              onChange={handleChange}
              defaultValue=""
              className={"search-inputs"}
            />
          </FormGroup>
        </Col>
        {/* <Col md={4}>
          <FormGroup>
            <Label for="referenceNumber">Reference Number</Label>
            <Input
              type="text"
              name="referenceNumber"
              id="referenceNumber"
              value={referenceNumber}
              onChange={handleChange}
              defaultValue=""
              className={"search-inputs"}
            />
          </FormGroup>
        </Col> */}
        <Col md={4}>
          <FormGroup>
            <Label for="revenue"> Revenue </Label>
            <SelectList
              placeholder={"Select revenue..."}
              data={revenueLine}
              valueKey={"id"}
              textField={"name"}
              selectValue={revenue}
              onValueChange={(event) => {
                selectChange(event, "revenue");
              }}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
            <FormGroup>
              <Label for="revenue"> Sub-Revenue </Label>
              <SelectList
                placeholder={"Select Sub-Revenue..."}
                data={subRevenues}
                valueKey={"id"}
                textField={"name"}
                selectValue={subRevenue}
                onValueChange={(event) => {
                  selectChange(event, "subRevenue");
                }}
                disabled={!revenue.hasSubRevenue}
                //enable only when there is a subRevenue
              />
            </FormGroup>
          </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="location"> Locations </Label>
            <SelectList
              placeholder={"Select location..."}
              data={locations}
              valueKey={"id"}
              textField={"address"}
              selectValue={location?.id}
              onValueChange={(event) => {
                selectChange(event, "location");
              }}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
            <FormGroup>
              <Label for="state"> State </Label>
              <SelectList
                placeholder={"Select state..."}
                data={states}
                valueKey={"name"}
                textField={"name"}
                selectValue={state.name}
                onValueChange={(event) => {
                  selectChange(event, "state");
                }}
              />
            </FormGroup>
          </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="dateType"> Date Type </Label>
            <SelectList
              placeholder={"Select date type..."}
              data={dateTypes}
              valueKey={"id"}
              textField={"name"}
              selectValue={dateType.id}
              onValueChange={(event) => {
                selectChange(event, "dateType");
              }}
            />
          </FormGroup>
        </Col>
        {/* <Col md={4}>
          <FormGroup>
            <Label for="status"> Status </Label>
            <SelectList
              placeholder={"Select status..."}
              data={statusArray}
              valueKey={"id"}
              textField={"name"}
              selectValue={status && status.id}
              onValueChange={(event) => {
                selectChange(event, "status");
              }}
            />
          </FormGroup>
        </Col> */}
        <Col md={4}>
          <FormGroup>
            <Label for={"date"} style={{ display: "block" }}>
              Start Date
            </Label>
            <DatePicker
              date={startDate}
              onChange={(date) => changeDatePicker(date, "startDate")}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for={"date"} style={{ display: "block" }}>
              End Date
            </Label>
            <DatePicker
              date={endDate}
              onChange={(date) => changeDatePicker(date, "endDate")}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="currency"> Currency </Label>
            <SelectList
              placeholder={"Select currency..."}
              data={currencies}
              valueKey={"id"}
              textField={"name"}
              selectValue={currency}
              onValueChange={(event) => {
                selectChange(event, "currency");
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ marginTop: "29px" }}>
      <Col md={2}  >
            <Button
              color="success"
              className=" small-action-btn btn-rounded px-4 mr-2 text-white"
              onClick={searchInvoiceNumber}
            >
              Search
            </Button>
          </Col>
          <Col md={1} >
            <Button
              outline
              className=" small-action-btn btn-rounded px-4 mr-2 "
              onClick={clearInputs}
            >
              Clear
            </Button>
          </Col>
      </Row>
    </Form>
  );
};

export default CreditNoteSearchPanel;