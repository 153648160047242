import React, { Component } from "react";
import { Table } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "./index.scss";
import moment from "moment";
import { appHelpers } from '../../../_helpers';

const ReportTable = ({ tableData, handleSelectAgency, selectedCard, showRequestDetails, currentPage, pageSize }) => {

  return (
    <Table  responsive striped hover className={"table-main"}>
      <thead className={"th-head"}>
        <tr>
          <th>S/N</th>
          <th>Credit Ref</th>
          <th>Date</th>
          <th>Invoice Number</th>
          <th>Customer</th>
          <th>Revenue Line</th>
          <th>Amount</th>
          <th>Type</th>
          {/* <th>Status</th> */}
          {/* <th>Action</th> */}
          <th>{selectedCard === 2 ? "Action" : "Status"}</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((item, index) => {
          return (
            <tr
              key={index}
              className="table-row"
            >
              <td>{index + 1 + (currentPage - 1) * pageSize}</td>
              <td>{item.referenceNumber}</td>
              <td>{moment(item.createdOn).format("YYYY-MM-DD")}</td>
              <td>{item.invoiceNumber}</td>
              <td>{item.serviceProviderName}</td>
              <td>{item.revenueName}</td>
              <td>{appHelpers.getCurrencyXter(item.currencyName.toLowerCase())} {`${appHelpers.numberWithCommas(item.creditAmount)}`}</td>
              <td>{item.creditNoteTypeName}</td>
              {/* <td>{item.requestStatusName}</td> */}

              {/* <td>
                  <a
                    href="/#"
                    // onClick={(e) => showRequestDetails(e, item)}           
                    onClick = {(e) => showRequestDetails(
                      "/request-details",
                      "requestDetailsRecord",
                      item,
                      e.preventDefault()
                    )}         
                    title="Review"
                  >
                    {" "}
                    Review{" "}
                  </a>{" "}
                </td> */}
             {selectedCard === 2 ? (
                <th>
                  <a
                    href="/#"
                    // onClick={(e) => showRequestDetails(e, item)}           
                    onClick = {(e) => showRequestDetails(
                      "/request-details",
                      "requestDetailsRecord",
                      item,
                      e.preventDefault()
                    )}         
                    title="Review"
                  >
                    {" "}
                    Review{" "}
                  </a>{" "}
                </th>

              ) : (
                <th>{item.requestStatusName}</th>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default class TableStructure extends Component {
  render() {
    let { tableData,
      currentPage,
      fetchMoreRecords,
      pageSize,
      totalCount,
      showRequestDetails,
      selectedCard
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
    return (
      <ServerPaginatedTable
        currentPage={currentPage}
        fetchMore={fetchMoreRecords}
        pageSize={pageSize}
        totalCount={totalCount}
        target={
          <ReportTable
            tableData={tableData}
            pageIndex={pageIndex}
            showRequestDetails={showRequestDetails}
            selectedCard={selectedCard}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        }
      />
    );
  }
};

