import React, { Component } from "react";
import AddBankInformation from "./add-bank-information";
import ContentLayout from "../../../components/ContentLayout";
import { Button } from "reactstrap";
import { appHelpers } from "../../../_helpers";
import { FaArrowLeft } from "react-icons/fa";
import { connect } from "react-redux";
import { appConstants } from "../../../_constants";
import { alertActions, apiActions } from "../../../_actions";
import SectionTitle from "../../../components/SectionTitle";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import CheckboxGrid from "../../../components/CheckboxGrid";
import { settlementAccountService } from "../../../_services";
import GeneralAlert from "../../../components/Api-response-alert";
import axios from "axios";

let source;

class AddNewBankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathState: {},
      banks: [],
      bankValue: "",
      accountNumber: "",
      alias: "",
      accountTypes: [],
      accountType: { id: 1, name: "Primary" },
      currencyValue: { id: 566, name: "Naira", code: "NGN" },
      selectAllRevenue: false,
      currencies: [],
      revenues: [],
      locations: [],
      isActivePrimaryAccount: false,
      status: true,
      selectAllLocations: false,
      selectAllLRevenues: false,
      disableProceedBtn: true,
      displayHelperText: {
        show: false,
        color: null,
        text: "",
      },
      verifiedAccountDetails: [],
      accountId: null,
      inputError: {},
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    const { apiReducer, dispatch, user } = this.props;
    const { currencies, banks, accountTypes } = apiReducer;

    // pathState is passed in these occasions
    // 1. User clicks on add new location (pathState will not have a record key) in "/settings-bank-account"
    // 2. User clicks on edit on accounts grid in "/settings-bank-account"
    // 3. User clicks on accounts grid row in "/settings-bank-account"
    // 4. User clicks on edit in "/review-bank-accounts"
    let pathState = this.props.location.state;
    let { record } = pathState;
    if (pathState) {
      this.setState({ pathState });

      if (record) {
        if (
          appHelpers.isObject(record.currencyValue) &&
          appHelpers.isObject(record.accountType)
        ) {
          this.setState({
            currencyValue: pathState.record.currencyValue,
            bankValue: pathState.record.bankValue,
            accountType: pathState.record.accountType,
            accountNumber: pathState.record.accountNumber,
          });
        }
      }
    }

    //EDIT FUNCTIONALITY FOR BOTH GRID and /review-account-info
    if (pathState && pathState.action === "edit") {
      this.setState({
        disableProceedBtn: false,
        status: pathState.record.status,
        accountId: pathState.record.accountId,
        verifiedAccountDetails: { accountName: pathState.record.accountName },
        alias: pathState.record.alias,
        accountNumber: pathState.record.accountNumber,
      });

      if (pathState.from === "/review-account-info") {
        if (pathState.record.accountType.id === 2) {
          this.setState({
            revenues: pathState.record.revenues,
            locations: pathState.record.locations,
          });
        }
      }

      if (pathState.record.accountType === 2 && !pathState.record.revenue) {
        // Call revenuelocation endpoint and set revenues & locations
        let payload = {
          agencyId:
            user.vggRole === appConstants.ROLES.VGG_ADMIN
              ? pathState.record.agencyId
              : user.organizationId,
          settlementAccountId: pathState.record.accountId,
        };
        dispatch(
          apiActions.getSettlementAccountLocationRevenue(payload, source.token)
        );
      }
    }

    if (!currencies) {
      this.getCurrency();
    } else {
      this.setState({ currencies }, () => {
        // Set currencyValue coming from "/settings-bank-account" and "/review-bank-account" routes in state
        if (record) {
          if (!appHelpers.isObject(pathState.record.currencyValue)) {
            this.setState({
              currencyValue: appHelpers.getCurrencyValue(
                currencies,
                pathState.record.currencyValue
              ),
            });
          }
        }
      });
    }

    if (!banks) {
      this.fetchBanks();
    } else {
      this.setState({ banks }, () => {
        //Set bankValue coming from "/settings-bank-account" and "/review-bank-account" routes in state
        if (record) {
          if (!appHelpers.isObject(pathState.record.bankValue)) {
            this.setState({
              bankValue: appHelpers.getBankValue(
                banks,
                pathState.record.bankValue
              ),
            });
          }
        }
      });
    }

    if (!accountTypes) {
      this.fetchAccountTypes();
    } else {
      this.setState({ accountTypes }, () => {
        //Set accountType for bank accounts grid edit view
        if (record) {
          if (!appHelpers.isObject(pathState.record.accountType)) {
            this.setState({
              accountType: appHelpers.getAccountType(
                accountTypes,
                pathState.record.accountType
              ),
            });
          }
        }
      });
    }
  }

  getCurrency = () => {
    return this.props.dispatch(apiActions.getCurrencies(source.token));
  };

  fetchBanks = () => {
    return this.props.dispatch(apiActions.fetchBanks(source.token));
  };

  fetchAccountTypes = () => {
    return this.props.dispatch(apiActions.fetchAccountTypes(source.token));
  };

  getSettlementAccountLocationRevenue = () => {
    const { user } = this.props;
    const { agencyDetails } = this.state.pathState;
    const { accountId } = this.state;

    let payload = {
      agencyId:
        user.vggRole === appConstants.ROLES.VGG_ADMIN
          ? agencyDetails.id
          : user.organizationId,
      settlementAccountId: accountId ? accountId : 0,
    };

    return this.props.dispatch(
      apiActions.getSettlementAccountLocationRevenue(payload, source.token)
    );
  };

  componentDidUpdate(prevProps) {
    const { apiReducer } = this.props;
    const { pathState } = this.state;
    if (apiReducer.currencies !== prevProps.apiReducer.currencies) {
      this.setState({
        currencies: apiReducer.currencies,
      });

      if (pathState.record) {
        if (!appHelpers.isObject(pathState.record.currencyValue)) {
          let currencyValue = appHelpers.getCurrencyValue(
            apiReducer.currencies,
            pathState.record.currencyValue
          );
          this.setState({ currencyValue });
        }
      }
    }

    if (apiReducer.locations !== prevProps.apiReducer.locations) {
      this.setState({
        locations: apiReducer.locations,
      });
    }

    if (apiReducer.revenues !== prevProps.apiReducer.revenues) {
      this.setState({
        revenues: apiReducer.revenues,
      });
    }

    if (
      apiReducer.settlementAccountLocations !==
      prevProps.apiReducer.settlementAccountLocations
    ) {
      this.setState({
        locations: apiReducer.settlementAccountLocations,
      });
    }

    if (
      apiReducer.settlementAccountRevenues !==
      prevProps.apiReducer.settlementAccountRevenues
    ) {
      this.setState({
        revenues: apiReducer.settlementAccountRevenues,
      });
    }

    if (apiReducer.banks !== prevProps.apiReducer.banks) {
      this.setState({
        banks: apiReducer.banks,
      });
      if (pathState.record) {
        if (!appHelpers.isObject(this.state.bankValue)) {
          this.setState({
            bankValue: appHelpers.getBankValue(
              apiReducer.banks,
              pathState.record.bankValue
            ),
          });
        }
      }
    }

    if (apiReducer.accountTypes !== prevProps.apiReducer.accountTypes) {
      this.setState({
        accountTypes: apiReducer.accountTypes,
      });

      if (pathState.record) {
        if (!appHelpers.isObject(pathState.record.accountType)) {
          this.setState({
            accountType: appHelpers.getAccountType(
              apiReducer.accountTypes,
              pathState.record.accountType
            ),
          });
        }
      }
    }
  }

  handleSelectChange = (payload, attribute) => {
    const { pathState, accountNumber } = this.state;
    const { revenueLocationData } = this.props.apiReducer;
    this.setState({ [attribute]: payload }, () => {
      if (payload !== null) {
        //If the account type selected is secondary
        if (attribute === "accountType" && payload.id === 2) {
          if (!revenueLocationData) {
            this.getSettlementAccountLocationRevenue();
          }
        }

        if (pathState.action === "edit" && attribute === "bankValue") {
          if (accountNumber.length === 10) {
            this.verifyAccountNumber(accountNumber);
          }
        }
      }
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleAccountChange = (event) => {
    const { bankValue } = this.state;
    if (!bankValue.id) {
      const displayVerifyingHelper = {
        show: true,
        color: "red",
        text: "Please select a bank account",
      };

      this.setState({ displayHelperText: displayVerifyingHelper });
      return;
    }
    if (bankValue.id) {
      const displayVerifyingHelper = {
        show: false,
        color: null,
        text: "",
      };
      this.setState(
        {
          accountNumber: event.target.value,
          displayHelperText: displayVerifyingHelper,
        },
        () => {
          if (this.state.accountNumber.length === 10) {
            this.verifyAccountNumber(this.state.accountNumber);
          }
        }
      );
    }
  };

  verifyAccountNumber = (accountNumber) => {
    const { bankValue } = this.state;
    const payload = {
      accountNumber,
      bankId: bankValue.id,
    };
    const { dispatch } = this.props;
    const displayVerifyingHelper = {
      show: true,
      color: "green",
      text: "Verifying account number...",
    };
    this.setState({
      displayHelperText: displayVerifyingHelper,
      disableProceedBtn: true,
    });
    settlementAccountService
      .verifyAccount(payload, source.token)
      .then((res) => {
        let { response } = res;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          const displaySuccessHelperText = {
            show: true,
            color: "green",
            text: response.data.accountName,
          };
          this.setState({
            displayHelperText: displaySuccessHelperText,
            disableProceedBtn: false,
            verifiedAccountDetails: response.data,
          });
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          const displayErrorHelperText = {
            show: true,
            color: "red",
            text: response.message,
          };
          this.setState({ displayHelperText: displayErrorHelperText });
          dispatch(alertActions.error(response.message));
        }
      });
  };

  checkboxChange = (e, type) => {
    const { revenues, locations } = this.state;
    let checkedStatus = e.target.checked; //Value of the check input
    let changedPermission = e.target.parentNode.parentNode.textContent; //The text for the element that was checked.
    // This would be used to identify the object in the array that was checked, so as to accurately update it's activated property.

    if (type === "active account") {
      this.setState({ status: checkedStatus });
    }

    if (type === "active primary account") {
      this.setState({ isActivePrimaryAccount: checkedStatus });
    }

    let updatedRecord; //Variable to hold the record that was updated

    if (type === "revenue") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        revenues
      );
      this.setState({ revenueData: updatedRecord });
    }
    if (type === "location") {
      updatedRecord = appHelpers.updatePermissionType(
        checkedStatus,
        changedPermission,
        locations
      );
      this.setState({ locationData: updatedRecord });
    }
  };

  goBack = () => {
    appHelpers.navigateToPreviousRoute.call(this);
  };

  selectAllCheckboxes = (type) => {
    const { revenues, locations } = this.state;
    if (type === "revenue") {
      this.setState(
        (prevState) => ({ selectAllRevenues: !prevState.selectAllRevenues }),
        () => {
          if (this.state.selectAllRevenues) {
            let revenueActivated = revenues.map((item) => {
              item.activated = true;
              return item;
            });
            this.setState({ revenues: revenueActivated });
          } else if (!this.state.selectAllRevenues) {
            let revenueActivated = revenues.map((item) => {
              item.activated = false;
              return item;
            });
            this.setState({ revenues: revenueActivated });
          }
        }
      );
    }
    if (type === "location") {
      this.setState(
        (prevState) => ({ selectAllLocations: !prevState.selectAllLocations }),
        () => {
          if (this.state.selectAllLocations) {
            let locationsActivated = locations.map((item) => {
              item.activated = true;
              return item;
            });
            this.setState({ locations: locationsActivated });
          } else if (!this.state.selectAllLocations) {
            let locationsActivated = locations.map((item) => {
              item.activated = false;
              return item;
            });
            this.setState({ locations: locationsActivated });
          }
        }
      );
    }
  };

  validateInput = () => {
    let err = {};
    let isInvalid = false;
    const { bankValue, currencyValue, accountType } = this.state;
    if (bankValue === "") {
      err.bankValue = "please select a bank account";
      isInvalid = true;
    }
    if (currencyValue === "") {
      err.currencyValue = "please select a currency";
      isInvalid = true;
    }
    if (accountType === "") {
      err.accountType = "please select an accountType";
      isInvalid = true;
    }
    this.setState({ inputError: err });
    return isInvalid;
  };

  reviewAccountInformation = () => {
    const { user } = this.props;
    const {
      pathState,
      revenues,
      locations,
      bankValue,
      accountNumber,
      alias,
      accountType,
      isActivePrimaryAccount,
      verifiedAccountDetails,
      currencyValue,
      accountId,
      status,
    } = this.state;
    const { agencyDetails } = pathState;

    let record = {
      accountId: accountId,
      accountNumber: accountNumber,
      accountName: verifiedAccountDetails.accountName,
      accountType: accountType,
      agencyId:
        user.vggRole === appConstants.ROLES.VGG_ADMIN
          ? agencyDetails.id
          : user.organizationId,
      bankValue: bankValue,
      currencyValue: currencyValue,
      isPrimaryAccount: isActivePrimaryAccount,
      status: status,
      alias,
    };

    if (accountType.id === 2) {
      record.revenues = revenues;
      record.locations = locations;
    }

    if (accountType.id === 1) {
      record.isActivePrimaryAccount = true;
    }

    const reviewPagePathState = {
      agencyDetails,
      record,
      from: "/add-new-bank-account",
    };
    if (!this.validateInput()) {
      appHelpers.navigateRoute.call(
        this,
        "/review-account-info",
        reviewPagePathState
      );
    }
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      pathState,
      banks,
      bankValue,
      accountType,
      accountTypes,
      currencies,
      currencyValue,
      accountNumber,
      alias,
      revenues,
      locations,
      selectAllLocations,
      selectAllRevenues,
      displayHelperText,
      disableProceedBtn,
      status,
      inputError,
    } = this.state;
    const { requesting } = this.props;

    return (
      <ContentLayout
        pageTitle={
          pathState && pathState.pageTitle
            ? pathState.pageTitle
            : appConstants.ADD_NEW_ACCOUNT
        }
        requesting={requesting}
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className=" btn-rounded mr-2 big-action-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <AddBankInformation
          handleChange={this.handleChange}
          handleSelectChange={this.handleSelectChange}
          handleAccountChange={this.handleAccountChange}
          banks={banks}
          bankValue={bankValue}
          accountType={accountType}
          currencyValue={currencyValue}
          accountNumber={accountNumber}
          displayHelperText={displayHelperText}
          alias={alias}
          accountTypes={accountTypes}
          currencies={currencies}
          checkboxChange={(e) => this.checkboxChange(e, "active account")}
          checked={status}
          inputError={inputError}
        />
        <div className={"mt-4"}>
          <SectionTitle title={"Account Mapping"} />
          {accountType && accountType.id === 1 && (
            <div>
              <span className={"d-block"}>
                Primary accounts are mapped to all revenue lines and locations.
              </span>
              <span className={"d-block"}>
                To map this account to a specific revenue line or location,
                change the account type to “Secondary”
              </span>
            </div>
          )}
        </div>
        {accountType && accountType.id === 2 && (
          <>
            <div className={"mt-4"}>
              {locations.length === 0 && (
                <EmptyRecordMessage message={"No location record"} />
              )}
              {locations.length > 0 && (
                <CheckboxGrid
                  title={"Select locations you want to map this account to"}
                  checkAll={selectAllLocations}
                  label={!selectAllLocations ? "Select all" : "Deselect all"}
                  displayCheckAllBoxes={true}
                  checkAllBoxes={() => this.selectAllCheckboxes("location")}
                  data={locations}
                  checkboxChange={(e) => this.checkboxChange(e, "location")}
                />
              )}
            </div>
            <div className={"mt-4"}>
              {revenues.length === 0 && (
                <EmptyRecordMessage message={"No revenue record"} />
              )}
              {revenues.length > 0 && (
                <CheckboxGrid
                  title={"Select revenue lines you want to map this account to"}
                  checkAll={selectAllRevenues}
                  label={!selectAllRevenues ? "Select all" : "Deselect all"}
                  displayCheckAllBoxes={true}
                  checkAllBoxes={() => this.selectAllCheckboxes("revenue")}
                  data={revenues}
                  checkboxChange={(e) => this.checkboxChange(e, "revenue")}
                />
              )}
            </div>
          </>
        )}
        <section className={"proceed-section"}>
          <div className={"proceed-section__prompt"}></div>
          <div className={"proceed-section__buttons"}>
            <button
              className={"ghost-button"}
              onClick={() =>
                appHelpers.navigateRoute.call(this, "/settings-bank-account", {
                  from: "/add-new-bank-account",
                  agencyDetails: pathState.agencyDetails,
                })
              }
            >
              Go back
            </button>
            <Button
              color="success"
              className="btn-sm btn-rounded mr-2 text-white"
              onClick={this.reviewAccountInformation}
              disabled={disableProceedBtn}
            >
              Proceed
            </Button>
          </div>
        </section>
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, apiReducer, displayGeneralAlert } = state;
  return {
    user,
    requesting,
    apiReducer,
    displayGeneralAlert,
  };
};

export default connect(mapStateToProps)(AddNewBankAccount);

// DOCUMENTATION
//This view is used to add and edit accounts.
// bankValue, currencyValue and accountType in state should be updated
//using state to objects because that is the data type the select field value
//is expecting.
