import React, {Component} from 'react';
import MerchantDetails from "./merchant-details";
import {Button} from "reactstrap";
import ContentLayout from "../../../components/ContentLayout";
import "./update-profile.css"
import LocationDetails from "./location-details";
import ContactDetails from "./contact-details";
import {appHelpers} from "../../../_helpers";
import {alertActions, commonActions} from "../../../_actions";
import {commonService} from "../../../_services";
import {appConstants} from "../../../_constants";
import {connect} from "react-redux";
import axios from "axios";
import {  FaArrowLeft, FaCheckCircle, FaTimes} from "react-icons/fa";
import {RenderAlertModal} from "../../../components/AlertModal";


let source;

class UpdateProfile extends Component {
    // _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            website: '',
            industries: [],
            organizations: [],
            orgName: "",
            ordId: null,
            industry: "",
            orgCode: "",
            countries: [],
            country: "",
            address: "",
            town: "",
            zipCode: "",
            states: [],
            state: "",
            designation: "",
            designations: {},
            localGovernments: [],
            LG: "",
            domainEmail: "",
            contactDetails: [
                {
                    name: "",
                    designation: "",
                    phoneNumber: "",
                    emailAddress: ""
                }
            ],
            showAlertModal: false,
            alertMessage: "",
            alertIcon: false,
            componentErrorMessages: [],
        }

        source = axios.CancelToken.source();
        // appHelpers.navigateRoute = appHelpers.navigateRoute.bind(this)
    }

    componentDidMount() {
        /* Remove overflow hidden caused by modal */
        const body = document.body;
        body.style.overflow = "scroll";
        
        this._isMounted = true;

        const {dispatch, states, countries} = this.props;

        if (states) {
            this.setState({states: states.data})
        } else {
            dispatch(commonActions.getAllStates(source.token))
        }

        if (countries) {
            this.setState({countries: countries.data})
        } else {
            dispatch(commonActions.getCountries(source.token))
        }

        this.getAllOrganizations();

    }

    componentWillReceiveProps = function (nextProps) {
        const {states , countries} = nextProps;
        if(states) {
            this.setState({states: states.data})
        }

        if(countries) {
            this.setState({countries: countries.data})
        }
    };

    getAllOrganizations = () => {
        const {dispatch} = this.props;

        dispatch(alertActions.startRequest());
        commonService.getAllOrganizations(source.token).then(res => {
            
            // dispatch(alertActions.stopRequest());
            let response = res.response;
            if (res.status === appConstants.SUCCESS_RESPONSE) {
                this.setState({ organizations: response.data }, () => {
                    this.getAllDesignations();
                });
            } else if (res.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(res.response));
            }
        });
    };

    getAllDesignations = () => {
        const {dispatch} = this.props;

        dispatch(alertActions.startRequest());
        commonService.getAllDesignations(source.token).then(res => {
            // dispatch(alertActions.stopRequest());
            let response = res.response;
            if (res.status === appConstants.SUCCESS_RESPONSE) {
                this.setState({ designations: response.data }, () => {
                    if(this.state.designations.length > 0) {
                        // organization details is called here because the designations data
                        // has to be mapped through each individual item in the contactDetails
                        //Array.
                        this.getOrganizationDetails(response.data)
                    }
                   
                });
            } else if (res.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(res.response));
            }
        });
    };

    getLocalGovernments = (stateId, lgaId) => {
        const {dispatch} = this.props;

        dispatch(alertActions.startRequest());
        commonService.getLocalGovernments(stateId, source.token).then(res => {
            dispatch(alertActions.stopRequest());
            let response = res.response;
            if (res.status === appConstants.SUCCESS_RESPONSE) {
                this.setState({ localGovernments: response.data }, () => {
                    this.setState({LG: appHelpers.getLGA(this.state.localGovernments, lgaId) })
                });
            } else if (res.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(res.response));
            }
        });
    };


    //Pass designations data to this method to provide contact details with
    // the designation data
    getOrganizationDetails = (designations) => {
        const {dispatch, user} = this.props;

        dispatch(alertActions.startRequest());
        commonService.getOrganizationDetails(user.organizationId, source.token).then(res => {
            dispatch(alertActions.stopRequest());
            let response = res.response;
            let organizationDetails = response.data;
            if (res.status === appConstants.SUCCESS_RESPONSE) {
                this.setState({
                    website: organizationDetails.website,
                    orgCode: organizationDetails.code,
                    orgId: organizationDetails.id,
                    orgName: appHelpers.getOrganizationId(this.state.organizations, organizationDetails.id),
                    address: organizationDetails.address,
                    zipCode: organizationDetails.postalCode,
                    state: appHelpers.getState(this.state.states, organizationDetails.stateId),
                    // LG: appHelpers.getLGA(this.state.localGovernments,organizationDetails.lgaId),
                    country: appHelpers.getCountry(this.state.countries, organizationDetails.countryId),
                    industry: organizationDetails.industry,
                    domainEmail: organizationDetails.domainEmail,
                    // contactDetails: [...organizationDetails.contacts]

                } , () => {
                    if (this.state.state !== ""){
                        this.getLocalGovernments(this.state.state.id, organizationDetails.lgaId);
                    }
                    if (organizationDetails.contacts.length > 0) {
                        let modifiedOrganizationDetails = organizationDetails.contacts.map(item => { // modify contacts to include designations
                            item.designations = designations;
                            return item
                        });
                        this.setState({contactDetails: modifiedOrganizationDetails})
                    }
                   
                    
                });
            } else if (res.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(res.response));
            }
        });
    };

    handleSelectChange = (payload, attribute, index) => {
        let currState = this.state;
        // let errorMessages = this.state.componentErrorMessages;
        // errorMessages[attribute] = false;
        // currState[attribute] = (payload) ? payload[attribute] : ''; //Cater for setting up data and clearing data
        if (payload) {
            if (attribute === 'designation') {
                const newContactDetails = this.state.contactDetails.map((item, key) => {
            
                    if (index !== key) return item;
                    let updatedItem = {...item, designation: payload.id}
            
                    return {...updatedItem};
                })
                this.setState({designation: payload.id})
                return this.setState({contactDetails: newContactDetails})
            }
        }
        if (!payload) {
            currState[attribute] = "";
        } else {
            if (attribute === "industry") {
                currState[attribute] = payload.id;
            } else if (attribute === "orgName") {
                currState[attribute] = payload;
            } else if (attribute === "revenue") {
                currState[attribute] = payload.id;
            } else if (attribute === "designation") {
                currState[attribute] = payload;
            } else if (attribute === "country") {
                currState[attribute] = payload;
            } else if (attribute === "state") {
                this.setState({state: payload}, () => {
                    this.getLocalGovernments(this.state.state.id)
                })
            }
            else if (attribute === "LG") {
                currState[attribute] = payload;
            }else {
                currState[attribute] = payload.Id;
            }
        }
        this.setState({currState});
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    changeContactDetails = index => event => {
        const newContactDetails = this.state.contactDetails.map((item, key) => {
            if (index !== key) return item;

            let updatedItem;
            if (event.target.name === "designation") {
                updatedItem = {...item, [event.target.name]: parseInt(event.target.value)};
            } else {
                updatedItem = {...item, [event.target.name]: event.target.value};
            }

            return {...updatedItem}
        });
        
        this.setState({contactDetails: newContactDetails})
    };

    addContactDetails = () => {
        this.setState({
            contactDetails: [
                ...this.state.contactDetails,
                {
                    name: "",
                    designation: "",
                    phoneNumber: "",
                    emailAddress: "",
                    designations: [...this.state.designations]
                },
            ]
        });
    };

    updateOrganization = () => {
        const {dispatch} = this.props;

        const {website, orgCode, orgName, address, zipCode, state, LG,
            country, industry, contactDetails, domainEmail, organizations} = this.state;

        if (this.validateInput()) {
            const payload = {
                id: orgName.id,
                name: appHelpers.getName(organizations, orgName.id),
                industry: industry,
                code: orgCode,
                website: website,
                address: address,
                countryId: country.id,
                stateId: state.id,
                lgaId: LG.id,
                postalCode: zipCode,
                domainEmail: domainEmail,
                contacts: [...contactDetails]
            };

            dispatch(alertActions.startRequest());

            commonService.updateOrganization(payload, source.token)
                .then(res => {
                    dispatch(alertActions.stopRequest());
                    let response = res.response;

                    if (res.status === appConstants.SUCCESS_RESPONSE) {
                        this.setState({
                            showAlertModal: true,
                            alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR}/>,
                            alertMessage: response.message
                        }, () => {
                            this.getOrganizationDetails(this.state.designations);
                            appHelpers.dismissModal.call(this, "showAlertModal");
                        })
                    } else if (res.status === appConstants.ERROR_RESPONSE) {
                        if (response === "Please Check your internet connectivity") {
                            dispatch(alertActions.error(response));
                        } else if (typeof response === "string") {
                            this.setState({
                                showAlertModal: true,
                                alertIcon: <FaTimes size={100} color={appConstants.SUCCESS_COLOR}/>,
                                alertMessage: response
                            }, () => appHelpers.dismissModal.call(this, "showAlertModal"))
                        } else {
                            this.setState({
                                showAlertModal: true,
                                alertIcon: <FaTimes size={100} color={appConstants.SUCCESS_COLOR}/>,
                                alertMessage: response.message
                            }, () => appHelpers.dismissModal.call(this, "showAlertModal"))
                        }
                    }
                })
        }

    };

    hideAlertView = (response) => {
        this.setState({ showAlertModal: response });
    };


    //Validate input fields
    validateInput = () => {
        // const {contactDetails} = this.state;
        let _errs_ = [];
        let formValid = true;
        if (this.state.website === "") {
            _errs_["website"] = "Please enter a website";
            formValid = false;
        }
        if (this.state.industry === "") {
            _errs_["industry"] = "Please enter an industry";
            formValid = false;
        }
        if (this.state.country === "") {
            _errs_["country"] = "Please select a country";
            formValid = false;
        }
        if (this.state.address === "") {
            _errs_["address"] = "Please enter an address";
            formValid = false;
        }
        if (this.state.state === "") {
            _errs_["state"] = "Please select a state";
            formValid = false;
        }
        if (this.state.LG === "") {
            _errs_["LG"] = "Please select an LGA";
            formValid = false;
        }
        if (this.state.zipCode === "") {
            _errs_["zipCode"] = "Please enter a zip code";
            formValid = false;
        }

        // contactDetails.map((item, index) => {
        //     if (item.name[index] === "") {
        //         _errs_["name"] = "Please enter a name";
        //         formValid = false;
        //     }
        //     if (item.designation === "") {
        //         _errs_["designation"] = "Please enter a designation";
        //         formValid = false;
        //     }
        //     if (item.emailAddress === "") {
        //         _errs_["emailAddress"] = "Please enter an email address";
        //         formValid = false;
        //     }
        //     if (item.phoneNumber === "") {
        //         _errs_["phoneNumber"] = "Please enter a phone number";
        //         formValid = false;
        //     }
        //     if (item.phoneNumber.length !== 11 ) {
        //         _errs_["phoneNumber"] = "Phone number should not be more or less than eleven digits";
        //         formValid = false;
        //     }
        // });
        if (this.state.contactDetails[0].name === "") {
            _errs_["name"] = "Please enter a name";
            formValid = false;
        }
        if (this.state.contactDetails[0].designation === "") {
            _errs_["designation"] = "Please enter a designation";
            formValid = false;
        }
        if (this.state.contactDetails[0].emailAddress === "") {
            _errs_["emailAddress"] = "Please enter an email address";
            formValid = false;
        }
        if (this.state.contactDetails[0].phoneNumber === "") {
            _errs_["phoneNumber"] = "Please enter a phone number";
            formValid = false;
        }
        if (this.state.contactDetails[0].phoneNumber.length !== 11 ) {
            _errs_["phoneNumber"] = "Phone number should not be more or less than eleven digits";
            formValid = false;
        }

        this.setState({ componentErrorMessages: _errs_ });
        return formValid;
    };

    removeContact = index => {
    const contactDetails = this.state.contactDetails.filter(
        (e, idx) => idx !== index
    );
    this.setState({ contactDetails });
    };


    componentWillUnmount () {
        // this._isMounted = false;
        if (source) {
            source.cancel()
          }
    }

    render() {
        const {website, industries, organizations, orgName, industry, orgCode,
            countries, country, address, states, localGovernments, LG, zipCode,
            state, contactDetails, showAlertModal, alertMessage, alertIcon,
            componentErrorMessages, isRequesting} = this.state;

        return (
            <div>
                <ContentLayout
                    requesting={this.props.requesting}
                    pageTitle={"Update Profile"}
                    actions={
                        <div className={"float-right"}>
                            <Button
                                color="info"
                                outline
                                className="btn-rounded mr-2 big-action-btn"
                                onClick={() =>appHelpers.navigateToPreviousRoute.call(this )}                            >
                                <FaArrowLeft className={"mb-1 icon-margin"}/>Back
                            </Button>
                        </div>
                    }

                >
                    <div>
                        <h4 className={'section-title'}>Merchant details</h4>
                        <MerchantDetails
                            website={website}
                            industries={industries}
                            organizations={organizations}
                            orgName={orgName}
                            industry={industry}
                            orgCode={orgCode}
                            handleChange={this.handleChange}
                            selectChange={this.handleSelectChange}
                            componentErrorMessages={componentErrorMessages}
                        />
                    </div>
                    <div>
                        <h4 className={'section-title'}>Location details</h4>
                        <LocationDetails
                            countries={countries}
                            country={country}
                            address={address}
                            states={states}
                            localGovernments={localGovernments}
                            LG={LG}
                            zipCode={zipCode}
                            state={state}
                            handleChange={this.handleChange}
                            selectChange={this.handleSelectChange}
                            componentErrorMessages={componentErrorMessages}

                        />
                    </div>
                    <div className={'last-section'}>
                        <h4 className={'section-title'}>Contact details</h4>
                        {
                          
                            contactDetails.map((item, index) => {
                            return(
                                <ContactDetails
                                    designations={item.designations}
                                    name={item.name}
                                    designation={appHelpers.getDesignations(item.designations, item.designation)}
                                    phoneNumber={item.phoneNumber}
                                    email={item.emailAddress}
                                    handleChange={this.changeContactDetails(index)}
                                    componentErrorMessages={componentErrorMessages}
                                    removeContact={() =>this.removeContact(index)}
                                    selectChange={this.handleSelectChange}
                                    index={index} // this is used for designation handleSelectChange
                        
                                />
                            )
                        })
                            }
                        <h6
                            onClick={this.addContactDetails}
                            // style={{ cursor: "pointer" }}
                            className={`pointer-cursor add-contacts`}
                        >
                            <i className="fa fa-plus-circle pr-2"></i>Add another item
                        </h6>
                    </div>
                    <div className={'mt-4'}>
                        <Button
                            className="btn-sm btn-rounded px-4 mr-2 text-white small-action-btn"
                            color="success" 
                            // className="big-button mr-2 small-action-btn"
                            onClick={this.updateOrganization}
                            disabled={isRequesting}>
                            Save
                        </Button>
                        {/*<Button*/}
                        {/*    color="primary" outline className="btn-rounded mr-2 small-action-btn"*/}
                        {/*    onClick={this.cancel}>*/}
                        {/*    Cancel*/}
                        {/*</Button>*/}
                    </div>
                    {showAlertModal &&
                    <RenderAlertModal
                        message={alertMessage}
                        icon={alertIcon}
                        showAlertModal={showAlertModal}
                        hideAlertView={this.hideAlertView}
                    />
                    }
                </ContentLayout>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { user, requesting, countries, states } = state;
    return {
        user,
        requesting,
        countries,
        states
    };
};

export default connect(mapStateToProps)( UpdateProfile);

