import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const creditNoteService = {
    searchCreditNoteRequest,
    initiateCreditNoteRequest
};

function searchCreditNoteRequest(payload, cancelToken) {
    return (
        appHelpers
          .postRequest(
            `${appConstants.PAS_URL}/api/CreditNoteRequest/ProcessBDA`,
            payload,
            cancelToken
          )
          .then((res) => {
            return res;
          })
          .catch((error) => {
            let errorMessage = appHelpers.interpretErrorResponse(error);
            return appHelpers.formatPromiseResponse(
              errorMessage,
              appConstants.ERROR_RESPONSE
            );
          })
      );
}

function initiateCreditNoteRequest(payload, cancelToken) {
  return (
      appHelpers
        .postRequest(
          `${appConstants.PAS_URL}/api/CreditNoteRequest/InitiateBDA`,
          payload,
          cancelToken
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          let errorMessage = appHelpers.interpretErrorResponse(error);
          return appHelpers.formatPromiseResponse(
            errorMessage,
            appConstants.ERROR_RESPONSE
          );
        })
    );
}