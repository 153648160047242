import { modalConstants } from "../_constants";

const INIT_STATE = {
  secondaryAlert: {},
  dialogAlert: {},
  shareInvoiceModal: {},
  displayInvoiceSheet: {},
  displayCreatedInvoice: {},
  displayVoidRequestModal: {},
  displayChangePasswordModal: {},
  displayDoubleInvoices: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case modalConstants.DISPLAY_SECONDARY_ALERT:
      return {
        ...state,
        secondaryAlert: action.payload,
      };
    case modalConstants.DISPLAY_DIALOG_ALERT:
      return {
        ...state,
        dialogAlert: action.payload,
      };
    case modalConstants.DISPLAY_SHARE_INVOICE:
      return {
        ...state,
        shareInvoiceModal: action.payload,
      };
    case modalConstants.DISPLAY_INVOICE_SHEET:
      return {
        ...state,
        displayInvoiceSheet: action.payload,
      };
    case modalConstants.DISPLAY_CREATED_INVOICE:
      return {
        ...state,
        displayCreatedInvoice: action.payload,
      };
    case modalConstants.DISPLAY_VOID_REQUEST:
      return {
        ...state,
        displayVoidRequestModal: action.payload,
      };
    case modalConstants.DISPLAY_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        displayChangePasswordModal: action.payload,
      };
    case modalConstants.DISPLAY_DOUBLE_INVOICES:
      return {
        ...state,
        displayDoubleInvoices: action.payload,
      };
    default:
      return state;
  }
};
