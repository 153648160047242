import React from "react";
import FullScreenModal from "../FullScreenModal";
import { Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import "./index.scss";
import { appHelpers } from "../../_helpers";
import { modalActions } from "../../_actions";

const SecondaryAlert = ({
  children,
  alertComponentsReducer,
  hideAlertView,
  modalWidth,
  dispatch,
}) => {
  const { secondaryAlert } = alertComponentsReducer;
  return (
    <FullScreenModal
      show={secondaryAlert.show}
      width={modalWidth ? modalWidth : "70%"}
      // callback={hideAlertView}
      callback={(res) => {
        appHelpers.fullScreenCallback.call(
          this,
          res,
          dispatch,
          secondaryAlert,
          modalActions.displaySecondaryAlert({
            show: false,
          })
        );
      }}
    >
      <Card className={`secondary-alert-card`}>
        <CardBody>{children}</CardBody>
      </Card>
    </FullScreenModal>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(SecondaryAlert);
