import React, { Component } from "react";
import { Table, Button, Input } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "react-toggle/style.css"; // for ES6 modules
import "./index.scss";
import moment from "moment";

const Records = (props) => {
  const { data , creditNoteStatus} = props;
  return (
    <Table responsive={true} className={"table-main"}>
      <thead className={"th-head tracker-head"}>
        <tr>
          <th></th>
          <th>Invoice No</th>
          <th>Payment Status</th>
          <th>Date</th>
          <th>Amount Invoiced</th>
          <th>Amount Paid</th>
          <th>Customer</th>
          <th>Revenue</th>
          <th>Credit Note Status</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={index}>
              <th>
                <Input
                  style={{ position: "static", width: "40px" }}
                  type="checkbox"
                  id="checkbox"
                  onChange={(e) => props.checkboxChange(e, item)}
                />
              </th>
              <th>{item.invoiceNumber}</th>
              <th>{item.paymentStatus}</th>
                <th> {moment(item.createdDate).format("YYYY-MM-DD")}</th>
              <th>{item.amountString}</th>
              <th>{item.amountPaidString}</th>
              <th>{item.customer}</th>
              <th>
                {item.revenue}
              </th>
              <th>{creditNoteStatus(item.creditNoteRequestStatus) }</th>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default class TableStructure extends Component {
  render() {
    let {
      data,
      currentPage,
      fetchMore,
      pageSize,
      totalCount,
      proceedToApply,
      checkboxChange,
      selectedRecord,
      creditNoteStatus
    } = this.props;
    const pageIndex = pageSize * (currentPage - 1) + 1;

    return (
      <div>
        {selectedRecord.length > 0 && (
          <Button
            className="btn-rounded mr-2  float-right mb-2 mt-3 btn btn-outline-info"
            onClick={(e) => proceedToApply(e)}
          >
            Proceed to Apply
          </Button>
        )}
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMore}
          pageSize={pageSize}
          totalCount={totalCount}
          target={
            <Records
              data={data}
              pageIndex={pageIndex}
              checkboxChange={checkboxChange}
              creditNoteStatus={creditNoteStatus}
            />
          }
        />
      </div>
    );
  }
}
