import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";
import SelectList from "../../../components/SelectList/selectList";

const ThirdPartySettlementAccountForm = ({
  splitType,
  settlementAccounts,
  handleChangeAccountDetails,
  handleSelectChangeAccountDetails,
  removeSettlementAccount,
  addSettlementAccount,
  banks,
  currencies,
  currentIndex,
  verifyingAccount,
  accountErrorMessages,
}) => {
  return (
    <div>
      {settlementAccounts &&
        settlementAccounts.map((account, index) => {
          return (
            <Row key={`Settlement accounts ${index}`}>
              <Col md="4">
                <FormGroup>
                  <Label>Bank name&#x2a;</Label>
                  <SelectList
                    placeholder={"Select bank..."}
                    data={banks}
                    valueKey={"id"}
                    textField={"name"}
                    selectValue={account.bank}
                    notClearable={index === 0 ? true : false}
                    onValueChange={(event) => {
                      handleSelectChangeAccountDetails(event, "bank", index);
                    }}
                  />

                  <div className="text-danger">
                    {accountErrorMessages &&
                      accountErrorMessages[index] &&
                      accountErrorMessages[index].bankError}
                  </div>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Account Number&#x2a;</Label>
                  <Input
                    type="text"
                    name="accountNumber"
                    id="accountNumber"
                    value={account.accountNumber}
                    onChange={(e) =>
                      handleChangeAccountDetails(e, "accountNumber", index)
                    }
                    valid={
                      account.accountVerificationDetails &&
                      account.accountVerificationDetails.accountName
                    }
                  />
                  {currentIndex === index && verifyingAccount && (
                    <div className="text-success">
                      Verifying account number...
                    </div>
                  )}
                  {account.accountVerificationDetails && (
                    <div
                      className={
                        account.accountVerificationDetails.accountName
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      {account.accountVerificationDetails.accountName ||
                        account.accountVerificationDetails.message}
                    </div>
                  )}

                  <div className="text-danger">
                    {!account.accountVerificationDetails &&
                      accountErrorMessages &&
                      accountErrorMessages[index] &&
                      accountErrorMessages[index].accountNumberError}
                  </div>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Currency&#x2a;</Label>
                  <SelectList
                    placeholder={"Select Currency..."}
                    data={currencies}
                    valueKey={"id"}
                    textField={"name"}
                    selectValue={account.currency}
                    notClearable={index === 0 ? true : false}
                    onValueChange={(event) => {
                      handleSelectChangeAccountDetails(
                        event,
                        "currency",
                        index
                      );
                    }}
                  />

                  <div className="text-danger">
                    {accountErrorMessages &&
                      accountErrorMessages[index] &&
                      accountErrorMessages[index].currencyError}
                  </div>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>
                    Third party share of payment in
                    {`${splitType === 1 ? " amount" : " %"}`}
                  </Label>
                  <Input
                    type="number"
                    min={0}
                    max={100}
                    name="thirdPartyShare"
                    id="thirdPartyShare"
                    value={account.thirdPartyShare}
                    onChange={(e) =>
                      handleChangeAccountDetails(e, "thirdPartyShare", index)
                    }
                  />

                  <div className="text-danger">
                    {accountErrorMessages &&
                      accountErrorMessages[index] &&
                      accountErrorMessages[index].thirdPartyShareError}
                  </div>
                </FormGroup>
              </Col>

              {settlementAccounts.length > 1 && (
                <Col md="4">
                  <Label className="invisible">Inv</Label>
                  <FormGroup>
                    <h6
                      className="pt-2"
                      onClick={() => removeSettlementAccount(index)}
                    >
                      <i className="fa fa-trash text-danger"></i>
                      <span className="text-danger pl-2">
                        Clear account information
                      </span>
                    </h6>
                  </FormGroup>
                </Col>
              )}
            </Row>
          );
        })}

      <h5
        onClick={() => addSettlementAccount()}
        className={"pointer-cursor"}
        style={{ color: "#7DD5F6" }}
      >
        <i className="fa fa-plus-circle pr-2" style={{ color: "#7DD5F6" }}></i>
        Add another settlement account
      </h5>
    </div>
  );
};

export default ThirdPartySettlementAccountForm;
