import React from "react";
import {Table, Badge} from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "react-toggle/style.css";
import moment from "moment"; // for ES6 modules

const selfServiceRecords = (props) => {
  const { setupRecords, editRecord, currentPage,
    fetchMore, totalCount, pageSize,
    showReviewSetup} = props;
  return (
    <div>
      <ServerPaginatedTable
        currentPage={currentPage}
        fetchMore={fetchMore}
        pageSize={pageSize}
        totalCount={totalCount}
        target={
          <Table responsive={true} className={"table-main"}>
            <thead className={"th-head tracker-head"}>
            <tr>
              <th>Maximum Unpaid Invoice</th>
              <th>Location</th>
              <th>Last Modified</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {setupRecords.map((item, index) => {
              return (
                <tr key={index} className={"table-row"} onClick={(e) => {item.status !== false &&  showReviewSetup(e, item) }}>
                  <th>{item.count}</th>
                  <th>{item.location}</th>
                  <th>{moment(item.lastModified).format("MMM DD, YYYY hh:mm a")}</th>
                  <th   style={{ padding: "none" }}>
                    {item.status !== false &&(
                      <Badge onClick={(e) => editRecord(e, item)}
                             className={" edit-setup badge-spacing"}
                             size="xl" pill>Edit</Badge>)}
                  </th>
                </tr>
              );
            })}
            </tbody>
          </Table>
        }
      />
    </div>
  );
};

export default selfServiceRecords;
