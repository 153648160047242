import React, { Component } from "react";
import './faq.css'
import './nested-Accordion'


class AccordionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      subContent: "",
    };
  }

  toggleAccordion = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
   
  };

  // openDetails = ()=> {
  //   this.props.showDetails(this.props)
  // }

  openDetails = (index) => {
    const { title, firstContent, subContent } = this.props;
    // this.setState({
    //   selectedIndex: index
    // })
    this.props.handleSelectedIndex(index)
    this.props.showDetails({
      title,
      firstContent,
      subContent
    });
  }

  render() {
    const { title, firstContent,} = this.props;
    const { isOpen, subContent } = this.state;

    return (
      <div className="accordion-item">
      <div className="accordion-header" onClick={this.toggleAccordion}>
        <div className="header-content" style={{marginRight:"65%"}}>
          <p style={{whiteSpace: "nowrap"}}><strong>{title}</strong></p>
          <div className="arrow-icon">
              {isOpen ? <span>▲</span> : <span>▼</span>}
            </div>
          
        </div>
      </div>

      {isOpen &&
       this.props.firstContent.map(
        (content, index)=>
       
      <div className="accordion-content-wrapper">
      <div onClick={()=>this.openDetails(index)} className="accordion-content">
        {content}
      </div>
      <div className="arrow-icon">
              {isOpen ? <span>▶</span> : <span>▼</span>}
            </div>
    </div>


       )
      }
            
    </div>
    );
  }
}


export default AccordionItem;
