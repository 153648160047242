import React from "react";
import {  Table } from "reactstrap";
import {appConstants} from "../../../_constants";

const StaffTable = ({ tableData, user, editCustomerStaff, activateStaff, deactivateStaff, companyRepId,
                      allowSelfService, migrateStaff}) => {

  return (
    <Table responsive striped className={"table-res"}>
      <thead className={"table-head-blue"}>
        <tr>
          <th>Title</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Phone Number</th>
          <th>Email address</th>
          <th>Status</th>
            {
              allowSelfService &&
              <th>SSO Activation</th>
            }
         
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map((item, index) => {
            return (
              <tr key={index} className={"table-row"}>
                <td>{item.titleName}</td>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.phoneNumber}</td>
                <td>{item.email}</td>
                <td>{item.status === true ? "Active" : "Not Active"}</td>
                {
                  allowSelfService &&
                  <td>{(item.isActiveSSOUser) &&
                  <span className={"activated-badge"}>Activated</span>}
                    {!(item.isActiveSSOUser) &&
                    <span className={"deactivated-badge"}>Deactivated</span>
                    }
                  </td>
                }
                {/*<td>*/}
                {/*  {item.titleName !== "Company Representative" &&*/}
                {/*  <Toggle*/}
                {/*    checked={item.status}*/}
                {/*    onChange={(e) => handleSwitchChecked(e, item)}*/}
                {/*    />*/}
                {/*  }*/}
                {/* */}
                {/*</td>*/}
                {/*show the ellipsis element if user is a vgg admin and record title is not a
                 company representative and customer is a self service cusomer*/}
                {/*Also show the ellipsis if user is not a  vgg admin*/}
                <td>
                  {
                    ((user.vggRole === appConstants.ROLES.VGG_ADMIN && allowSelfService && item.titleId === parseInt(companyRepId))
                      ||
                    (user.vggRole !== appConstants.ROLES.VGG_ADMIN ))
                    &&
                      <div className="dropdown">
                        <p
                          className="dropbtn"
                        >
                          <span className="threedots"/>
                        </p>
                        <ul className="dropdown-content">
                          <li onClick={() => editCustomerStaff(item)}>Edit</li>
                          {(user.vggRole !== appConstants.ROLES.VGG_ADMIN && !item.status) &&
                          <li onClick={() => activateStaff(item)}>Activate</li>
                          }
                          {(user.vggRole !== appConstants.ROLES.VGG_ADMIN && item.status) &&
                          <li onClick={() => deactivateStaff(item)}>Deactivate</li>
                          }
                          {allowSelfService && (!item.isActiveSSOUser) &&
                          <li onClick={() => migrateStaff(item)}>Migrate to SSO</li>
                          }
                        </ul>
                      </div>
                  }
                  {
                    (user.vggRole === appConstants.ROLES.VGG_ADMIN && !allowSelfService)
                    &&
                    <div className="dropdown">
                      <p
                        className="dropbtn"
                      >
                        <span className="threedots"/>
                      </p>
                      <ul className="dropdown-content">
                        <li onClick={() => editCustomerStaff(item)}>Edit</li>
                        {!item.status &&
                        <li onClick={() => activateStaff(item)}>Activate</li>
                        }
                        {(item.status && (item.titleId !== parseInt(companyRepId))) &&
                        <li onClick={() => deactivateStaff(item)}>Deactivate</li>
                        }
                      </ul>
                    </div>
                  }
                </td>
                {/*<td>*/}
                {/*  <FaEllipsisV*/}
                {/*    onClick={() => editCustomerStaff(item)}*/}
                {/*    id={`Tooltip${index}`}*/}
                {/*  />*/}
                {/*  <UncontrolledTooltip*/}
                {/*    placement="bottom-end"*/}
                {/*    target={`Tooltip${index}`}*/}
                {/*    */}
                {/*  >*/}
                {/*    Edit Staff*/}
                {/*  </UncontrolledTooltip>*/}
                {/*</td>*/}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default StaffTable;
