import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function showWelcomeScreen(state = initialState.showWelcomeScreen, action) {

  switch (action.type) {
    case appConstants.SET_WELCOME_SCREEN_VISIBILITY:
      return action.visibility;
    default:
      return state;
  }
}
