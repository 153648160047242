import { modalConstants } from "../_constants";
import { initialState } from "../_store";

export function modal(state = initialState.modal, action) {
  switch (action.type) {
    case modalConstants.SUCCESS:
      return Object.assign({}, state, {
        type: action.type,
        message: action.message
      });
    case modalConstants.ERROR:
      return Object.assign({}, state, {
        type: action.type,
        message: action.message
      });
    case modalConstants.CLEAR:
      return Object.assign({}, state, {
        type: action.type,
        message: action.message
      });
    case modalConstants.NOTIFY:
      return Object.assign({}, state, {
        type: action.type,
        message: action.message
      });
    default:
      return state;
  }
}
