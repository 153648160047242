import React, { Component } from "react";
import { FaUpload } from "react-icons/fa";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import "./index.scss";
import { alertActions, commonActions, modalActions } from "../../../_actions";
import { brandingService } from "../../../_services/branding.service";
import { appConstants } from "../../../_constants";
import { Button } from "reactstrap";
import GeneralAlert from "../../../components/Api-response-alert";
import { appHelpers } from "../../../_helpers";
import axios from "axios";

let source;

class Branding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoFile: null,
      imageFile: null,
      logoFileName: "",
      domianUrl: "",
    };

    source = axios.CancelToken.source();
  }

  componentDidMount() {
    //Call branding if there is no method to display select agency view
    appHelpers.checkDerivedStateFromPropsMethod.call(this);

    let domainUrl = appHelpers.getDomainOrigin();
    this.setState({ domainUrl });
  }

  handleInputChange = (e) => {
    e.persist();

    if (e.target.files.length > 0) {
      this.setState({ [e.target.name]: e.target.files[0] }, () => {
        if (e.target.name === "logoFile") {
          this.displaySelectedImage(this.state.logoFile, "selected-logo-img");
        }
        if (e.target.name === "imageFile") {
          this.displaySelectedImage(this.state.imageFile, "selected-bg-img");
        }
      });
    }
    e.target.value = "";
  };

  //This function creates a new image object
  // and appends it inside a div with the specified className argument
  // at the bottom of the image it renders selected file properties
  displaySelectedImage = (file, className) => {
    const img = new Image();
    const selectedElement = document.querySelector(`.${className}`);

    if (selectedElement.hasChildNodes()) {
      selectedElement.textContent = "";
    }

    img.addEventListener("load", () => {
      if (this.checkImageDimensions(className, img)) {
        selectedElement.appendChild(img);
        selectedElement.insertAdjacentHTML(
          "beforeend",
          `<div>${file.name} ${img.width}×${img.height} ${
            file.type
          } ${Math.round(file.size / 1024)}KB<div>`
        );
        window.URL.revokeObjectURL(img.src);
      }
    });

    if (file) {
      img.src = window.URL.createObjectURL(file);
    }
  };

  checkImageDimensions = (className, img) => {
    const { dispatch } = this.props;
    let checkedDimensions = true;
    if (
      className === "selected-logo-img" &&
      (img.width > 200 || img.height > 200)
    ) {
      dispatch(
        modalActions.displayGeneralAlert({
          show: true,
          message: "The logo dimensions cannot exceed 200 * 200 pixels",
          action: "failure",
        })
      );
      this.setState({ logoFile: "" });
      checkedDimensions = false;
    }
    if (
      className === "selected-bg-img" &&
      (img.width > 2500 || img.height > 2500)
    ) {
      dispatch(
        modalActions.displayGeneralAlert({
          show: true,
          message:
            "The background image dimensions cannot exceed 2500 * 2500 pixels",
          action: "failure",
        })
      );
      this.setState({ logoFile: "" });
      checkedDimensions = false;
    }
    return checkedDimensions;
  };

  createBranding = () => {
    const { dispatch, user } = this.props;
    const { logoFile, imageFile, domainUrl } = this.state;

    let payload = new FormData();
    payload.append("AgencyId", user.organizationId);
    payload.append("Logo", logoFile);
    payload.append("Background", imageFile);
    payload.append("DomainURL", domainUrl);

    dispatch(alertActions.startRequest());
    brandingService.createBranding(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        //SHOW SUCCESS MODAL
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "success",
          })
        );
        this.clearSelectedImage();
        let payload = {
          agencyId: user.organizationId,
          agencyCode: user.organizationCode,
        };

        dispatch(commonActions.getBranding(payload, user));
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        //SHOW ERROR MODAL
        dispatch(
          modalActions.displayGeneralAlert({
            show: true,
            message: response.message,
            action: "failure",
          })
        );
      }
    });
  };

  clearSelectedImage = () => {
    let selectedLogo = document.querySelector(".selected-logo-img");
    let selectedBgImg = document.querySelector(".selected-bg-img");

    selectedLogo.textContent = "";
    selectedBgImg.textContent = "";
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const { requesting } = this.props;
    return (
      <ContentLayout requesting={this.props.requesting} pageTitle="Branding">
        <h4 className="font-weight-bold pb-4">Company logo</h4>
        <div className={" branding-logo-container"}>
          <div className="file-upload d-flex align-items-center justify-content-center flex-column p-5">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => this.handleInputChange(e)}
              name={"logoFile"}
            />
            <div>
              <FaUpload size={30} />
            </div>
            <p className="font-weight-bold text-info text-center px-4 py-2 mb-0">
              Upload Company Logo
            </p>
            <p className="text-center mb-0">
              This image dimensions must not exceed 200x200 pixels. Formats
              accepted are JPG or PNG with a resolution of 300dpi
            </p>
          </div>
          <div>
            <div className={"selected-image selected-logo-img"}></div>
          </div>
        </div>

        <hr className="my-5" />
        <h4 className="font-weight-bold pb-4">Background Image</h4>

        <div className={" branding-logo-container"}>
          <div className="file-upload d-flex align-items-center justify-content-center flex-column p-5">
            <input
              type="file"
              accept="image/*"
              onChange={(e) => this.handleInputChange(e)}
              name={"imageFile"}
            />
            <div>
              <FaUpload size={30} />
            </div>
            <p className="font-weight-bold text-info text-center p-4 mb-0">
              Upload Background Image
            </p>
            <p className="text-center mb-0">
              This image dimensions must not exceed 2500x2500 pixels and must
              not exceed a file size of 5MB. Formats accepted are JPG or PNG
              with a resolution of 300dpi
            </p>
          </div>
          <div>
            <div className={"selected-image selected-bg-img"}></div>
          </div>
        </div>

        <div className={"mt-4"}>
          <Button
            color="success"
            className="btn-sm btn-rounded mr-2 text-white px-4"
            onClick={this.createBranding}
            disabled={requesting}
          >
            Send
          </Button>
        </div>
        {this.props.displayGeneralAlert.show && <GeneralAlert />}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Branding);
