import React from 'react';
import {connect} from "react-redux";
import FullScreenModal from "../FullScreenModal";
import Alert from "../Alert";
import { FaCheckCircle, FaTimes, FaExclamationCircle} from "react-icons/fa";
import {appConstants} from "../../_constants";
import {modalActions} from "../../_actions";

const hideAlertView = (response, dispatch, displayGeneralAlert) => {
  const body = document.body;
  body.style.overflow = "scroll";
  dispatch(modalActions.displayGeneralAlert({show: false, message: displayGeneralAlert.message, performAction: displayGeneralAlert.performAction}));
};

const GeneralAlert = ({displayGeneralAlert, dispatch}) => {
  return (
    <FullScreenModal
      show={displayGeneralAlert.show}
      width={"35%"}
      callback={(res)  => hideAlertView(res, dispatch, displayGeneralAlert)}
    >
       <Alert
        icon={displayGeneralAlert.action === "success" ?
          <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} /> :
          displayGeneralAlert.action === "failure" ?
          <FaTimes size={100} color={appConstants.DANGER_COLOR} /> :
          displayGeneralAlert.action === "info" ? 
          <FaExclamationCircle size={100} color={appConstants.WARNING_COLOR}
          /> :
          null
        }
        message={displayGeneralAlert.message}
      />
    </FullScreenModal>
  );
};

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps)(GeneralAlert);