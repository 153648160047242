import React from 'react';
import {
    Input, FormGroup, Label,
    Col,
    Container, Row, FormText
} from 'reactstrap';
import {appConstants} from "../../_constants";

const ViewTemplate = ({record, handleChange, Comment, payload, componentErrorMessages}) => {
    if(!payload)
        return;
    const payloadKeys = Object.keys(payload);
    return (
        <Container>
            <Row>
            {
                payloadKeys.map((item, key)=><Col sm="6" key={key}>
                    <FormGroup className="mb-4">
                        <Label>{item}</Label>
                        <Input type="text" disabled value={payload[item]}/>
                    </FormGroup>
                </Col>)
            }
            {
                record.statusId !== appConstants.MAKER_CHECKER_STATUS.PENDING &&
                <Col sm="12">
                    <FormGroup className="mb-4">
                        <Label>Comment</Label>
                        <Input type="text" disabled value={record.Comment} name="Comment"
                        />
                    </FormGroup>
                </Col>
            }
            {
                record.statusId === appConstants.MAKER_CHECKER_STATUS.PENDING &&
                <Col sm="12">
                    <FormGroup className="mb-4">
                        <Label>Comment</Label>
                        <Input type="text" value={Comment} name="Comment" onChange={handleChange}/>
                        
                        { !Comment.trim() && <FormText color="danger">Comment is required.</FormText> }
                        
                    </FormGroup>
                </Col>
            }
            </Row>

        </Container>
    );
};

export default ViewTemplate;
