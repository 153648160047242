import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function showPeriodPicker(state = initialState.showPeriodPicker, action) {

  switch (action.type) {
    case appConstants.SET_PERIOD_PICKER_VISIBILITY:
      return action.visibility;
    default:
      return state;
  }
}
