import React from 'react';
import "./index.scss";

const SearchBar = ({placeholder, value, onChange, right, name, search, showSearchButton}) => {
    return (
        <div className={"container"}>
            <form>
                <input className={"search-input searh-field"} type="text" name={name} placeholder={placeholder} value={value} onChange={onChange}/>
                {showSearchButton && <button className={"search-button"} style={{right: right ? right :  "32px"}} onClick={search}>Go</button>}
            </form>
        </div>
    )
};

export default SearchBar;