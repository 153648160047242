import React from "react";
import { connect } from "react-redux";
import {
  Nav,
  NavItem,
  NavLink,
  Button,
  Navbar,
  NavbarBrand,
  Collapse,
} from "reactstrap";
// import * as data from "./data";

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
// import logodarkicon from "../../../assets/images/logo-icon.png";
import logolighticon from "../../../assets/images/logo-light-icon1.png";
// import logodarktext from "../../../assets/images/logo-text.png";
// import logodarktext from "../../../assets/images/ebips-logo.svg";
import logolighttext from "../../../assets/images/logo-light-text.png";
// import profilephoto from "../../../assets/images/users/5.jpg";
import PeriodPicker from "../../../components/PeriodPicker";
import { Alert } from "reactstrap";
import { alertConstants } from "../../../_constants/alert.constants";
import { alertActions } from "../../../_actions/alert.actions";
import { commonActions } from "../../../_actions";
// import {setLogo, setNavbarBg} from "../../../redux/settings/action";

const mapStateToProps = (state) => ({
  ...state,
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.searchtoggle = this.searchtoggle.bind(this);
    this.showMobilemenu = this.showMobilemenu.bind(this);
    this.sidebarHandler = this.sidebarHandler.bind(this);
    this.state = {
      isOpen: false,
      collapse: false,
      visible: false,
      notificationMessage: "",
      notificationColor: "success",
      isDashboard: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    let element = document.getElementById("main-wrapper");
    // element.classList.toggle("mini-sidebar");
    if (element.classList.contains("mini-sidebar")) {
      element.setAttribute("data-sidebartype", "mini-sidebar");
      dispatch(commonActions.showMiniSidebarDropdown(true));
    }

    this.configureLogo();
  }

  /*------------------------------------------------*/
  /*Set custom Logo for each agency via redux actions*/
  /*-------------------------------------------------*/
  configureLogo = () => {
    const { userData } = this.props;
    if (userData.organizationId) {
      // window.alert(userData.organizationId)
      // dispatch(setLogo(userData.logoUrl))
    }
  };

  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/

  componentWillReceiveProps = function (nextProps) {
    const { alert } = nextProps;
    if (alert) {
      if (alert.type === alertConstants.NOTIFY) {
        const msg_ = alert.message;
        this.setState({
          notificationMessage: msg_,
          notificationColor: "warning",
        });
        this.showNotification();
        this.props.dispatch(alertActions.clear());
      } else if (alert.type === alertConstants.SUCCESS) {
        const msg_ = alert.message;
        this.setState({
          notificationMessage: msg_,
          notificationColor: "success",
        });
        this.showNotification();
        this.props.dispatch(alertActions.clear());
      } else if (alert.type === alertConstants.ERROR) {
        const msg_ =
          typeof alert.message === "string"
            ? alert.message
            : "Unable to process request";
        this.setState({
          notificationMessage: msg_,
          notificationColor: "danger",
        });
        this.showNotification();
        this.props.dispatch(alertActions.clear());
      }
    }
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  searchtoggle() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  clearAlertTimeout = () => {
    if (this.alertTimeout !== null) {
      clearTimeout(this.alertTimeout);
    }
  };

  showNotification = () => {
    const { dispatch } = this.props;
    this.setState({ visible: true }, () => {
      dispatch(commonActions.showNotification(true)); //use this to control actions or styles you want to happen when notification bar is displayed
    });
    this.clearAlertTimeout();
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ visible: false });
      }.bind(this),
      10000
    );
  };
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  showMobilemenu() {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  }
  sidebarHandler = () => {
    const { dispatch } = this.props;
    // console.log(showMiniSidebarDropdown)
    // dispatch(commonActions.showMiniSidebarDropdown(true))
    let element = document.getElementById("main-wrapper");
    switch (this.props.settings.activeSidebarType) {
      case "full":
      case "iconbar":
        element.classList.toggle("mini-sidebar");
        if (element.classList.contains("mini-sidebar")) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
          dispatch(commonActions.showMiniSidebarDropdown(true));
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
          dispatch(commonActions.showMiniSidebarDropdown(false));
        }
        break;

      case "overlay":
      case "mini-sidebar":
        element.classList.toggle("full");
        if (element.classList.contains("full")) {
          element.setAttribute("data-sidebartype", "full");
          // dispatch(commonActions.showMiniSidebarDropdown(true))
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.settings.activeSidebarType
          );
        }
        break;
      default:
        break;
    }
  };

  onDismiss = () => {
    this.setState({ visible: false });
  };

  logOut = () => {
    const { dispatch } = this.props;
    dispatch(commonActions.logOut());
  };

  render() {
    const { visible, notificationMessage, notificationColor } = this.state;
    return (
      <header
        className="topbar navbarbg"
        data-navbarbg={this.props.settings.activeNavbarBg}
      >
        <Navbar
          className={
            "top-navbar " +
            (this.props.settings.activeNavbarBg === "skin6"
              ? "navbar-light"
              : "navbar-dark")
          }
          expand="md"
        >
          {this.props.settings.showSidebarLayout && (
            <div
              className="navbar-header"
              id="logobg"
              data-logobg={this.props.settings.activeLogoBg}
            >
              {/*--------------------------------------------------------------------------------*/}
              {/* Mobile View Toggler  [visible only after 768px screen]                         */}
              {/*--------------------------------------------------------------------------------*/}
              <span
                className="nav-toggler d-block d-md-none"
                onClick={this.showMobilemenu}
              >
                <i className="ti-menu ti-close" />
              </span>
              {/*--------------------------------------------------------------------------------*/}
              {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
              {/*--------------------------------------------------------------------------------*/}
              <NavbarBrand href="/">
                <b className="logo-icon">
                  {/* <img src={logodarkicon} alt="homepage" className="dark-logo" /> */}
                  {this.props.showMiniSidebarDropdown && (
                    <img
                      src={this.props.settings.setLogo}
                      alt="homepage"
                      className=""
                      style={{ width: "40px" }}
                    />
                  )}

                  <img
                    src={logolighticon}
                    alt="homepage"
                    className="light-logo"
                  />
                </b>
                <span className="logo-text">
                  {!this.props.showMiniSidebarDropdown && (
                    <img
                      src={this.props.settings.setLogo}
                      alt="homepage"
                      className="dark-logo"
                    />
                  )}
                  <img
                    src={logolighttext}
                    className="light-logo"
                    alt="homepage"
                  />
                </span>
              </NavbarBrand>

              {/*--------------------------------------------------------------------------------*/}
              {/* Mobile View Toggler  [visible only after 768px screen]                         */}
              {/*--------------------------------------------------------------------------------*/}
              <span
                className="topbartoggler d-block d-md-none"
                onClick={this.toggle}
              >
                <i className="ti-more" />
              </span>
            </div>
          )}
          <Collapse
            className="navbarbg"
            isOpen={this.state.isOpen}
            navbar
            data-navbarbg={this.props.settings.activeNavbarBg}
          >
            {this.props.settings.showSidebarLayout && (
              <Nav className="float-left" navbar>
                <NavItem>
                  <NavLink
                    href="#"
                    className="d-none d-md-block"
                    onClick={this.sidebarHandler}
                  >
                    <i className="ti-menu" />
                  </NavLink>
                </NavItem>
                {this.props.showPeriodPicker && (
                  <NavItem>
                    <NavLink href="#" className="">
                      <PeriodPicker
                        selectedPeriod={(period) => console.log(period)}
                      />
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            )}
            <Nav className="ml-auto float-right" navbar>
              {/* <NavItem>
								<NavLink href="#" className="">
									<span onClick={this.searchtoggle}><i className="ti-search" /></span>
									<Collapse isOpen={this.state.collapse}>
										<form className="app-search">
	                                		<input type="text" className="form-control" placeholder="Search & enter" /> 
											<span className="srh-btn" onClick={this.searchtoggle}><i className="ti-close"></i></span> 
										</form>
									</Collapse>
								</NavLink>
							</NavItem> */}

              {/* <NavItem>
                <NavLink href="#" className="">
                  <PeriodPicker
                    selectedPeriod={period => console.log(period)}
                  />
                </NavLink>
              </NavItem> */}
              {/*--------------------------------------------------------------------------------*/}
              {/* Start Notifications Dropdown          	                                                   */}
              {/*--------------------------------------------------------------------------------*/}
              {/*<NavItem>*/}
              {/*  <NavLink href="#" className="">*/}
              {/*    <span>*/}
              {/*      <i className="ti-bell" />*/}
              {/*    </span>*/}
              {/*  </NavLink>*/}
              {/*</NavItem>*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Notifications Dropdown                                                                   */}
              {/*--------------------------------------------------------------------------------*/}

              {/*--------------------------------------------------------------------------------*/}
              {/* Start Settings                                                                 */}
              {/*--------------------------------------------------------------------------------*/}
              {/*<NavItem>*/}
              {/*  <NavLink href="#" className="">*/}
              {/*    <span>*/}
              {/*      <i className="ti-settings" />*/}
              {/*    </span>*/}
              {/*  </NavLink>*/}
              {/*</NavItem>*/}
              {/*--------------------------------------------------------------------------------*/}
              {/* End Settings                                                                   */}
              {/*--------------------------------------------------------------------------------*/}

              {this.props.settings.showSidebarLayout && (
                <NavItem className="align-content-center">
                  <NavLink href="/login" className="">
                    <Button
                      onClick={this.logOut}
                      size="sm"
                      color="success"
                      className="btn-rounded text-white px-3"
                    >
                      Log out
                    </Button>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Navbar>
        <div
          style={{
            width: "25%",
            float: "right",
            zIndex: 999999,
            position: "relative",
          }}
        >
          <Alert
            color={notificationColor}
            isOpen={visible}
            toggle={this.onDismiss}
            fade={false}
            className={"notification-alert"}
          >
            {notificationMessage}
          </Alert>
        </div>
      </header>
    );
  }
}
export default connect(mapStateToProps)(Header);
