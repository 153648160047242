import React, { Component } from "react";
import TableStructure from "./record";
import { Button } from "reactstrap";
import SearchBar from "../../../components/SearchBar";
import ContentLayout from "../../../components/ContentLayout";
import {
  FaArrowLeft,
  FaCheckCircle,
  FaPlusCircle,
  FaTimes,
} from "react-icons/fa";
import { Row, Col } from "reactstrap";
import { appHelpers } from "../../../_helpers";
import FullScreenModal from "../../../components/FullScreenModal";
import { commonService } from "../../../_services/common.service";
import { appConstants } from "../../../_constants/app.constants";
import { connect } from "react-redux";
import { alertActions, commonActions } from "../../../_actions";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import "./index.scss";
import ServiceCard from "../../../components/ServiceType/index";
import { RenderAlertModal } from "../../../components/AlertModal";
import axios from "axios";

// import UpdateUserCard from "../../../components/UpdateUserCard";
let source;

class ManageServiceType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      showModalView: false,
      serviceTypeValue: "",
      tableData: [],
      searching: false,
      codeValue: "",
      agency: 0,
      agencies: [],
      organizationId: 0,
      rateValue: 0,
      serviceValue: "",
      paymentChannelId: 0,
      isEdit: false,
      showAlertModal: false,
      alertMessage: "",
      alertIcon: "",
      erpCode: "",
      paymentChannels: [],
      code: "",
      showBorder: false,
      taxInclusive: false,
      allowUnRegisterCustomer: false,
      allowSelfService: false,
      recordId: 0,
      componentErrorMessages: [],
      decimalPrecision: appConstants.DEFAULT_PRECISION,
      revenueGroups: [],
      revenueGroup: {},
      tempRevenueGroups: [],
      isDirectorate: false,
    };
    source = axios.CancelToken.source();
  }

  componentDidMount() {
    const { dispatch, organizations, user } = this.props;

    //Call branding if there is no method to display select agency view
    appHelpers.checkDerivedStateFromPropsMethod.call(this);

    //If user is not vgg admin, set agency variable in state
    //to user's Organization ID
    if (user.vggRole !== appConstants.ROLES.VGG_ADMIN) {
      this.setState({
        agency: user.organizationId,
      });
    }
    if (organizations) {
      this.setState({ agencies: organizations.data });
    } else {
      dispatch(commonActions.getAllOrganization(source.token));
    }
    this.searchManageService();
    this.getPaymentChannels();
    this.getRevenueGroups();
  }

  componentWillReceiveProps = function (nextProps) {
    const { organizations } = nextProps;

    if (organizations) {
      this.setState({ agencies: organizations.data });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSelectChange = (payload, attribute) => {
    let currState = this.state;
    if (!payload) {
      currState[attribute] = "";
    } else {
      if (attribute === "agency") {
        const newRevenueGroup = this.state.tempRevenueGroups.filter(
          (item) => item?.agencyId === parseInt(payload.id)
        );
        this.setState({ revenueGroups: newRevenueGroup });
        currState[attribute] = payload.id;
      } else if (attribute === "revenueGroup") {
        currState[attribute] = payload;
      } else {
        currState[attribute] = payload.id;
      }
    }
    this.setState({ currState });
  };

  hideModal = () => {
    this.setState({ showModalView: false });
    this.resetForm();
  };

  isShowModal = () => {
    this.setState({ showModalView: true });
  };

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  handleEdit = (item) => {
    const newRevenueGroup = this.state.tempRevenueGroups.filter(
      (it) => it?.agencyId === parseInt(item.agencyId)
    );
    this.setState({ revenueGroups: newRevenueGroup });
    this.setState({
      showModalView: true,
      recordId: item.id,
      rateValue: item.rate,
      serviceValue: item.name,
      codeValue: item.code,
      agency: item.agencyId,
      taxInclusive: item.taxInclusive,
      allowUnRegisterCustomer: item.allowUnRegisterCustomer,
      allowSelfService: item.allowSelfService,
      decimalPrecision: item.decimalPrecision,
      revenueGroup: { id: item.revenueGroupId, name: item.revenueGroupName },
      isEdit: true,
    });
  };

  clearFields = () => {
    const { user } = this.props;
    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      this.setState({
        agency: "",
      });
    }
    this.setState({
      serviceValue: "",
      codeValue: "",
      rateValue: 0,
      taxInclusive: "",
      revenueGroup: {},
    });
  };

  handleSwitchChecked = (e, item) => {
    let payload = {
      RevenueId: item.id,
    };
    if (e.target.checked === true) {
      this.activateServiceType(payload);
    }
    if (e.target.checked === false) {
      this.deactivateServiceType(payload);
    }
  };

  resetForm = () => {
    const { user } = this.props;
    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      this.setState({
        agency: "",
      });
    }

    this.setState({
      serviceValue: "",
      codeValue: "",
      rateValue: 0,
      taxInclusive: "",
      allowUnRegisterCustomer: false,
      allowSelfService: false,
      isEdit: false,
      componentErrorMessages: [],
      decimalPrecision: 2,
      revenueGroup: {},
      // revenueGroups: [],
    });
  };

  renderModalView = () => {
    let modal = [];
    let count = 0;
    const {
      showModalView,
      agency,
      agencies,
      rateValue,
      isEdit,
      serviceValue,
      codeValue,
      taxInclusive,
      allowUnRegisterCustomer,
      allowSelfService,
      componentErrorMessages,
      decimalPrecision,
      revenueGroup,
      revenueGroups,
    } = this.state;
    const { user } = this.props;

    modal.push(
      <FullScreenModal
        show={showModalView}
        key={count + 1}
        callback={this.hideModal}
        width={"40%"}
        zIndex={10}
      >
        <ServiceCard
          modalTitle={isEdit ? "Edit Service Type" : "Add Service Type"}
          serviceValue={serviceValue}
          codeValue={codeValue}
          agencies={agencies}
          agency={agency}
          defaultValue={
            agencies && agencies.filter((option) => option.id === agency)
          }
          disabled={user.vggRole !== appConstants.ROLES.VGG_ADMIN}
          rateValue={rateValue}
          taxInclusive={taxInclusive}
          allowUnRegisterCustomer={allowUnRegisterCustomer}
          allowSelfService={allowSelfService}
          checkboxChange={this.checkboxChange}
          showRate
          selectChange={this.handleSelectChange}
          handleChange={this.handleChange}
          addClick={this.createServiceType}
          cancelClick={this.cancel}
          addText={isEdit ? "Save" : "Add Service Type"}
          componentErrorMessages={componentErrorMessages}
          decimalPrecision={decimalPrecision}
          revenueGroups={revenueGroups}
          revenueGroup={revenueGroup}
        />
      </FullScreenModal>
    );
    return modal;
  };

  searchBarChange = (e) => {
    this.setState({ serviceTypeValue: e.target.value }, () => {
      if (this.state.serviceTypeValue === "") {
        this.searchManageService();
      }
    });
  };

  search = (e) => {
    e.preventDefault();
    this.searchManageService();
  };

  cancel = () => {
    const body = document.body;
    body.style.overflow = "scroll";
    this.setState({ showModalView: false });
    this.resetForm();
  };

  dismissModal = (alert) => {
    setTimeout(() => {
      const body = document.body;
      body.style.overflow = "scroll";
      this.setState({ [alert]: false });
    }, 5000);
  };

  //handler for the tax inclusive checkbox

  checkboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  validateInput = () => {
    let _errs_ = {};
    let formValid = true;

    if (this.state.rateValue === 0 && this.state.allowSelfService === true) {
      _errs_["rateValue"] = "Please input Rate to proceed";
      formValid = false;
    }

    if (this.state.serviceValue === "") {
      _errs_["serviceValueError"] = "Service type name cannot be empty";
      formValid = false;
    }

    if (this.state.agency === 0 || this.state.agency === "") {
      _errs_["agencyError"] = "Agency field cannot be empty";
      formValid = false;
    }

    if (this.state.decimalPrecision < 2 || this.state.decimalPrecision > 5) {
      _errs_["decimalPrecisionError"] =
        "decimal precision must be between 2 and 5";
      formValid = false;
    }

    this.setState({ componentErrorMessages: _errs_ });
    return formValid;
  };
  //search service type
  searchManageService = (pageNumber = 1) => {
    const { dispatch } = this.props;
    const { pageSize, serviceTypeValue } = this.state;
    dispatch(alertActions.startRequest());

    const payload = {
      pageSize: pageSize,
      from: pageNumber,
      parameter: {
        searchQuery: serviceTypeValue,
      },
    };
    commonService.searchManageService(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          currentPage: pageNumber === 0 ? 1 : pageNumber,
          totalCount: response.totalCount,
          tableData: response.results,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(response));
      } else {
        dispatch(alertActions.error(response.message));
      }
    });
  };

  createServiceType = () => {
    const { dispatch } = this.props;
    const {
      agency,
      erpCode,
      recordId,
      taxInclusive,
      codeValue,
      serviceValue,
      isEdit,
      rateValue,
      allowUnRegisterCustomer,
      allowSelfService,
      decimalPrecision,
      revenueGroup,
    } = this.state;

    if (this.validateInput()) {
      let payload = {
        name: serviceValue,
        erpCode: erpCode,
        code: codeValue,
        agencyId: agency,
        rate: rateValue,
        taxInclusive,
        allowUnRegisterCustomer,
        allowSelfService,
        decimalPrecision,
        revenueGroupId: revenueGroup.id,
      };
      if (isEdit) {
        payload.id = recordId;
      }

      dispatch(alertActions.startRequest());
      commonService.createServiceType(payload, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;

        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.displaySuccessResponseAlert(
            response,
            true,
            this.searchManageService()
          );
          // dispatch(alertActions.success('successful'))
          //
          // this.setState({
          //     showAlertModal: true,
          //     alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />,
          //     alertMessage: response.message,
          //
          // }, () => this.dismissModal("showAlertModal"));
          // this.clearFields();
          // this.hideModal();
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          this.displayErrorResponseAlert(
            response,
            true,
            this.searchManageService()
          );
        }
        //     dispatch(alertActions.error(response))
        // } if (typeof response === "string") {
        //     dispatch(alertActions.error(response));
        //     this.setState({alertMessage: response})
        // } else {
        //     dispatch(alertActions.error(response.message));
        //     this.setState({alertMessage: response.message})
        // }
      });
    }
  };

  getPaymentChannels = () => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());

    commonService.getPaymentChannels(source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (Array.isArray(response.data)) {
          let filteredPaymentChannels = response.data.filter(
            (item) => item.logo !== ""
          );
          response.data !== null &&
            this.setState({ paymentChannels: filteredPaymentChannels });
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (response === "Please Check your internet connectivity") {
          dispatch(alertActions.error(response));
        }
      }
    });
  };

  activateServiceType = (payload) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    commonService.activateServiceType(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(
          response,
          true,
          this.searchManageService()
        );
        // this.setState({
        //     showAlertModal: true,
        //     alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />,
        //     alertMessage: response.message,
        //
        // }, () => {
        //     this.searchManageService();
        //     this.dismissModal("showAlertModal")
        // });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(
          response,
          true,
          this.searchManageService()
        );
        // if (typeof response === "string") {
        //     this.searchManageService()
        //     dispatch(alertActions.error(response));
        //     this.setState({alertMessage: response})
        // } else {
        //     this.searchManageService();
        //     dispatch(alertActions.error(response.message));
        //     this.setState({alertMessage: response.message})
        // }
      }
    });
  };

  deactivateServiceType = (payload) => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());
    commonService.deactivateServiceType(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(
          response,
          true,
          this.searchManageService()
        );
        // this.setState({
        //     showAlertModal: true,
        //     alertIcon: <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />,
        //     alertMessage: response.message,
        //
        // }, () => {
        //     this.searchManageService();
        //     this.dismissModal("showAlertModal")
        // });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(
          response,
          true,
          this.searchManageService()
        );
        // this.searchManageService();
        // if (typeof response === "string") {
        //     dispatch(alertActions.error(response));
        //     this.setState({alertMessage: response})
        // } else {
        //     dispatch(alertActions.error(response.message));
        //     this.setState({alertMessage: response.message})
        // }
      }
    });
  };

  //Alert Notifications
  displaySuccessResponseAlert = (response, action, query) => {
    this.setState(
      {
        showAlertModal: true,
        alertIcon: (
          <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
        ),
        alertMessage: response.message,
      },
      () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
      }
    );
    this.clearFields();
    this.hideModal();
    if (action) {
      return { query };
    }
  };

  displayErrorResponseAlert = (response, action, query) => {
    const { dispatch } = this.props;
    if (response === "Please Check your internet connectivity") {
      dispatch(alertActions.error(response));
    } else if (typeof response === "string") {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    } else {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response.message,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    }
  };

  //get revenue groups endpoint

  getRevenueGroups = () => {
    let { dispatch, user } = this.props;

    let payload = {
      // agencyId: user.organizationId,
      agencyId: 0,
      isDirectorate: false,
    };
    dispatch(alertActions.startRequest());
    commonService.getRevenueGroups(payload, source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        if (user.vggRole !== appConstants.ROLES.VGG_ADMIN) {
          const newRevenueGroup = response.data.filter(
            (item) => item?.agencyId === parseInt(user.organizationId)
          );
          this.setState({
            revenueGroups: newRevenueGroup ? newRevenueGroup : [],
            tempRevenueGroups: newRevenueGroup ? newRevenueGroup : [],
          });
        } else {
          this.setState({
            revenueGroups: response.data ? response.data : [],
            tempRevenueGroups: response.data ? response.data : [],
          });
        }
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }
  render() {
    const {
      totalCount,
      currentPage,
      pageSize,
      paymentChannels,
      showModalView,
      tableData,
      serviceTypeValue,
      showAlertModal,
      alertIcon,
      alertMessage,
    } = this.state;

    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle={"Manage Service Type"}
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className=" btn-rounded mr-2 big-action-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <Row className={"row-space"}>
          <Col>
            <Button
              // className="bigger-button mr-2 big-action-btn "
              className="btn-rounded mr-2 create-action-btn text-white"
              onClick={this.isShowModal}
              color={"success"}
            >
              <FaPlusCircle className={"icon-margin mb-1"} />
              Add Service Type
            </Button>
          </Col>
        </Row>

        <Row className={"settings-spacing"}>
          <Col sm={{ size: 5 }} className=" search-column text-center">
            <SearchBar
              placeholder="Search Service Type"
              showSearchButton
              value={serviceTypeValue}
              search={this.search}
              onChange={this.searchBarChange}
            />
          </Col>
          {/* <div className={'header-divider'}></div> */}
          <Col>
            <h4>Payment Channels</h4>
            <div>
              {paymentChannels.map((item, index) => {
                return (
                  <span key={index} className={"mr-4"}>
                    <img alt={"logo"} width="75" src={item.logo} />
                  </span>
                );
              })}
            </div>
          </Col>
        </Row>

        <br />
        <br />
        {tableData.length === 0 && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}
        {tableData.length > 0 && (
          <Row>
            <Col md={"12"}>
              <TableStructure
                apiData={tableData}
                toggleModalFunction={this.handleEdit}
                fetchMore={this.searchManageService}
                pageSize={pageSize}
                totalCount={totalCount}
                currentPage={currentPage}
                handleSwitchChecked={this.handleSwitchChecked}
                user={this.props.user}
              />
            </Col>
          </Row>
        )}
        {showModalView === true && this.renderModalView()}
        {/*{editModalView === true && this.renderEditModalView()}*/}
        {showAlertModal && (
          <RenderAlertModal
            message={alertMessage}
            icon={alertIcon}
            showAlertModal={showAlertModal}
            hideAlertView={this.hideAlertView}
          />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, alert, progress, user, requesting, organizations } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
    organizations,
  };
};

export default connect(mapStateToProps)(ManageServiceType);
