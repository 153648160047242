import React, { Component } from "react";
import ContentLayout from "../../../components/ContentLayout";
import { appHelpers } from "../../../_helpers";
import { connect } from "react-redux";
import { Button, Row, Col, FormGroup, Input } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";
import axios from "axios";
import Label from "reactstrap/lib/Label";
import EditableFields from "./editable-items";
import { commonService } from "../../../_services";
import { alertActions } from "../../../_actions";
import { appConstants } from "../../../_constants";
import { RenderAlertModal } from "../../../components/AlertModal";
import { FaCheckCircle, FaTimes} from "react-icons/fa";


let source;
class EditInvoiceSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVggRole: false,
      editablefieldsData: [],
      organizations: [],
      organizationId: null,
      organization: "",
      user: "",
      agencies: [],
      editFrequency: 0,
      editPeriod: 0,
      editableItems:[
        {
          id: 0,
         field: "",
         isActive: false
        }
      ],
      showAlertModal: false,
      alertIcon: "",
      alertMessage: "",
      isEditMode: false,
      settingId: 0
    };
 this.editPeriod = 0;
 this.editFrequency =0;
 this.editablefieldsData = [];
    source = axios.CancelToken.source();
  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";

    this.routeUserBasedOnRole();
  }

  /**
   * @description Route the user based on the role of the logged in user
   */

   static getDerivedStateFromProps(props, state) {
    let pathState = props.location.state;
    if (pathState) {
      if (
        props.location.state.selectedAgencyDetails !==
        state.selectedAgencyDetails
      ) {
        return {
          agencyDetails: props.location.state.selectedAgencyDetails,
        };
      }
    }

    return null;
  }
    
  routeUserBasedOnRole = () => {
    const { user } = this.props;
    const { agencyDetails } = this.state;

    //get route the user is coming from
    let previousPath = this.props.history.location.state.from;

    //set Role related state
    let vggAdminRole = user.vggRole === appConstants.ROLES.VGG_ADMIN;

    //Everytime a user with a VGG Role vists this page, the user must select an agency
    //agencyDetails===undefined prevents redirect from happening again once an agency is selected
    if (vggAdminRole && agencyDetails === undefined) {
      this.props.history.push({
        pathname: "/select-agency",
        state: { from: previousPath },
      });
    } else {
      //if organization is already selected, set the following states
      this.setState(
        {
          isVggRole: vggAdminRole ? true : false,
          organizationName: agencyDetails
            ? agencyDetails.name
            : user.organization,
          organizationId: vggAdminRole
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
        },
        () => {
          this.getEditableFields();
        }
      );
    }
  };

  // Handlers:
  // This onChange handler, gets the checked value
  checkboxChange = (e, type) => {
    const { editablefieldsData } = this.state;
// debugger;
    let checkedStatus = e.target.checked; //Value of the check input
    let changedFields = e.target.parentNode.parentNode.textContent; //The text for the element that was checked.
    // This would be used to identify the object in the array that was checked, so as to accurately update it's activated property.

    let updatedRecord; //Variable to hold the record that was updated
    if (type === "editableFields") {
      updatedRecord = appHelpers.updateEditableType(
        checkedStatus,
        changedFields,
        editablefieldsData
      );
      this.setState({ editablefieldsData: updatedRecord });
    }
    // debugger;

  };

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  resetFields = () => {
    let { editablefieldsData } = this.state;
    let updatedEditableFields = editablefieldsData.map((item) => {
      if (item.isActive === true) item.isActive = false;
      return item;
    });
    this.setState({
      editPeriod: 0,
      editFrequency: 0,
      editablefieldsData: updatedEditableFields,
    });
  };
  // input handler
  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  dismissModal = (alert) => {
    setTimeout(() => {
      const body = document.body;
      body.style.overflow = "scroll";
      this.setState({ [alert]: false });
    }, 5000);
  };

  //Alert Notifications
  displayErrorResponseAlert = (response, action, query) => {
    const { dispatch } = this.props;
    if (response === "Please Check your internet connectivity") {
      dispatch(alertActions.error(response));
    } else if (typeof response === "string") {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    } else {
      this.setState(
        {
          showAlertModal: true,
          alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
          alertMessage: response.message,
        },
        () => {
          appHelpers.dismissModal.call(this, "showAlertModal");
          if (action) {
            return query;
          }
        }
      );
    }
  };
  //Api
  getEditableFields = () => {
    const { dispatch, user} = this.props;
    const {agencyDetails} = this.state;
    const payload = {
      agencyId:
          user.vggRole === appConstants.ROLES.VGG_ADMIN
            ? agencyDetails && agencyDetails.id
            : user.organizationId,
    };
    

    dispatch(alertActions.startRequest());

    commonService.getEditableFields(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          editablefieldsData: response.data.invoiceFieldSettingsModels,
          editFrequency: response.data.settings.invoiceFrequency,
          editPeriod: response.data.settings.invoiceAge,
          settingId: response.data.settings.settingsId
        });
        this.editFrequency = response.data.settings.invoiceFrequency;
        this.editPeriod= response.data.settings.invoiceAge;
        this.editablefieldsData = response.data.invoiceFieldSettingsModels;
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (typeof response === "string") {
          dispatch(alertActions.error(response));
          this.setState({ alertMessage: response });
        } else {
          dispatch(alertActions.error(response.message));
          this.setState({ alertMessage: response.message });
        }
      }
    });
  };

  filteredEditableFields = () => {
    const { editablefieldsData } = this.state;

    const filteredFields = editablefieldsData
      .filter((item) => item.isActive === true)
      .map((item) => item.invoiceField);

    return filteredFields ? filteredFields : [];
  };
  createEditInvoice = () => {
    const {
      editPeriod,
      editFrequency,
      organizationId, 
      settingId,    
      editablefieldsData
    } = this.state;
    const { dispatch } = this.props;

    let newArr = []
    for(let i in editablefieldsData){
      newArr.push({   
      id: editablefieldsData[i]?.invoiceFieldId,
      field: editablefieldsData[i]?.invoiceField,
      isActive: editablefieldsData[i]?.isActive
      })
    }
      const checkArr = this.editablefieldsData.every(item => !item.isActive)
      let payload;
          // for creating new settings
      if (checkArr && this.editFrequency === 0 && this.editPeriod === 0) {
        payload = {
        invoiceAge: editPeriod,
        frequency: editFrequency,
        agencyId: organizationId,
        editableFields: newArr
        }
      } else {
          // for updating existing settings
        payload = {
        id: settingId,
        invoiceAge: editPeriod,
        frequency: editFrequency,
        agencyId: organizationId,
        editableFields: newArr
      };
    } 
      dispatch(alertActions.startRequest());

      commonService.createEditInvoice(payload, source.token).then((res) => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState(
            {
              // selectAllPermissions: false,
              showAlertModal: true,
              alertIcon: (
                <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
              ),
              alertMessage: response.message,
            },
            () => this.dismissModal("showAlertModal")
          );
          // if (pageTitle === appConstants.CREATE_ROLE) {
          //   this.resetFields();
          // }
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          if (response === "Please Check your internet connectivity") {
            dispatch(alertActions.error(response));
          } else if (typeof response === "string") {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response,
              },
              () => {
                appHelpers.dismissModal.call(this, "showAlertModal");
              }
            );
          } else {
            this.setState(
              {
                showAlertModal: true,
                alertIcon: (
                  <FaTimes size={100} color={appConstants.DANGER_COLOR} />
                ),
                alertMessage: response.message,
              },
              () => {
                appHelpers.dismissModal.call(this, "showAlertModal");
              }
            );
          }
        }
      });
    // }
  };

  editInvoice =() => {
    this.setState({
      isEditMode: true,
    })
  }

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    
    const {
      isVggRole,
      editablefieldsData,
      editFrequency,
      editPeriod,
      showAlertModal,
      alertMessage,
      alertIcon,
    } = this.state;

    return (
      <ContentLayout
      requesting={this.props.requesting}
        pageTitle={"Edit Invoice Setting"}
        actions={
          isVggRole === true ? (
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className="big-action-btn btn-rounded mr-2"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          ) : (
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          )
        }
      >
        <Row>
          <Col md={"4"}>
            <FormGroup>
              <Label>Invoice Edit Frequency</Label>
              <Input
                type="number"
                name="editFrequency"
                onChange={this.handleInputChange}
                id="editFrequency"
                value={editFrequency}
              />
              {/* <SelectList
                      // data={frequency}
                      valueKey={"id"}
                      textField={"fequencyName"}
                      // selectValue={frequency}
                      /> */}
            </FormGroup>
          </Col>
          <Col md={"4"}>
            <FormGroup>
              <Label>Invoice Edit Period</Label>
              <Input
                type="number"
                name="editPeriod"
                onChange={this.handleInputChange}
                id="editPeriod"
                value={editPeriod}
              />
            </FormGroup>
          </Col>
        </Row>
        {/* <Row className={"spacing-row"}>
              <Col md={"4"}>
                  <FormGroup>
                      <Label>Set Editable Items</Label>
                      <Button  outline
                      color="info"
                      className="big-action-btn btn-rounded ml-5"
                      >Edit</Button>
                  </FormGroup>
              </Col>
            </Row> */}
        <hr />
        <div className={"permissions-container"}>
          <h2 className={"permissions-title"}>Select Editable Items </h2>
          <EditableFields
            editableFields={editablefieldsData}
            isChecked={editablefieldsData.isActive}
            checkboxChange={(e) => this.checkboxChange(e, "editableFields")}
          />
          <Row className={"spacing-row"}>
            <Col md={"4"}>
              <FormGroup>
                {/* <Label>Set Editable Items</Label> */}
                {/* <Button
                  outline
                  color="info"
                  className="big-action-btn btn-rounded mr-3"
                >
                  Edit
                </Button> */}
                <Button
                  outline
                  color="info"
                  className="big-action-btn btn-rounded mr-5"
                  onClick={this.createEditInvoice}
                >
                  Save
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </div>
        {showAlertModal && (
          <RenderAlertModal
            message={alertMessage}
            icon={alertIcon}
            showAlertModal={showAlertModal}
            hideAlertView={this.hideAlertView}
          />
        )}
      </ContentLayout>
    );
  }
}
const mapStateToProps = (state) => {
  const { modal, progress, user, requesting, alert } = state;
  return {
    user,
    requesting,
    alert,
    modal,
    progress,
  };
};
export default connect(mapStateToProps)(EditInvoiceSettings);
