import React from "react";
import { FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import SelectList from "../../SelectList/selectList";
import CustomizedMenuSelect from "../../CustomizedMenuSelect";
import { DatePicker } from "../../DatePicker";
import "../../../views/createinvoice/createinvoice.scss";
import InvoiceItems from "../../../components/InvoiceItems/invoice-items";
import ReasonForChange from "./reason-for-change";
import { appConstants } from "../../../_constants";
import { pageHelpers } from "../../../_helpers";

const SearchPanel = ({
  isLiveView,
  componentErrorMessages,
  revenues,
  revenue,
  customers,
  customer,
  billDate,
  name,
  email,
  phoneNumber,
  address,
  currencies,
  currency,
  rrrNumber,
  handleChange,
  location,
  locations,
  revenueGroupTag,
  revenueGroups,
  revenueGroup,
  invoiceItems,
  removeInvoiceItem,
  handleItemChange,
  handleFocus,
  handleBlur,
  addInvoiceItemFields,
  showInvoiceSheet,
  isRequesting,
  renderInvoiceView,
  handleSelectChange,
  openAddNewCustomerModal,
  changeDatePicker,
  isCustomer,
  cancelInvoice,
  decimalPrecision,
  subRevenues,
  subRevenue,
  disableRate,
  isLocationMapping, // use this to check if it is the getRevenueLocationMapping endpoint that is populating the dropdown
  action = "Create invoice",
  requestingText = "Creating invoice...",
  displayReasonForChange = true,
  reasonForChange,
  pageTitle,
  editableFields,
  invoiceStatus,
  footerNote,
  footerOptions,
  advertConcession,
}) => {
  const isUpdateInvoice = pageTitle === appConstants.EDIT_INVOICE;

  return (
    <div>
      <Row>
        <Col md={`${isLiveView ? "6" : "12"}`}>
          <Row>
            <Col md={`${isLiveView ? "6" : "4"}`}>
              <FormGroup>
                <Label>Revenue</Label>
                <SelectList
                  placeholder={"Select revenue..."}
                  _errs_={componentErrorMessages.revenues}
                  data={revenues}
                  valueKey={"id"}
                  textField={"name"}
                  selectValue={revenue}
                  disabled={pageHelpers.disableField(
                    editableFields,
                    "Revenue",
                    pageTitle
                  )}
                  onValueChange={(event) => {
                    handleSelectChange(event, "revenue");
                  }}
                />
                {componentErrorMessages.revenue && (
                  <span className={"inputError"}>
                    {componentErrorMessages.revenue}
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={`${isLiveView ? "6" : "4"}`}>
              <FormGroup>
                <Label>Sub-Revenue</Label>
                <SelectList
                  placeholder={"Select revenue..."}
                  _errs_={componentErrorMessages.revenues}
                  data={subRevenues}
                  valueKey={"id"}
                  textField={"name"}
                  selectValue={subRevenue}
                  onValueChange={(event) => {
                    handleSelectChange(event, "subRevenue");
                  }}
                  disabled={pageHelpers.disableField(
                    editableFields,
                    "SubRevenue",
                    pageTitle
                  )}
                />
                {componentErrorMessages.subRevenue && (
                  <span className={"inputError"}>
                    {componentErrorMessages.subRevenue}
                  </span>
                )}
              </FormGroup>
            </Col>
            {!isCustomer && (
              <Col md={`${isLiveView ? "6" : "4"}`}>
                <FormGroup>
                  <Label>Customer</Label>
                  <CustomizedMenuSelect
                    placeholder={"Select customer..."}
                    _errs_={componentErrorMessages.customers}
                    data={customers}
                    valueKey={"id"}
                    textField={"customerName"}
                    selectValue={customer}
                    actionText={"Add New Customer"}
                    onValueChange={(event) => {
                      handleSelectChange(event, "customer");
                    }}
                    actionClicked={openAddNewCustomerModal}
                    disabled={pageHelpers.disableField(
                      editableFields,
                      "CustomerName",
                      pageTitle
                    )}
                  />
                  {componentErrorMessages.customer && (
                    <span className={"inputError"}>
                      {componentErrorMessages.customer}
                    </span>
                  )}
                </FormGroup>
              </Col>
            )}
            {isCustomer && (
              <Col md={`${isLiveView ? "6" : "4"}`}>
                <FormGroup>
                  <Label>Customer Name</Label>
                  <Input
                    _errs_={componentErrorMessages.name}
                    invalid={false}
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => handleChange(e, "name")}
                    disabled={
                      isCustomer ||
                      pageHelpers.disableField(
                        editableFields,
                        "Customer",
                        pageTitle
                      )
                    }
                  />
                  {componentErrorMessages.name && (
                    <span className={"inputError"}>
                      {componentErrorMessages.name}
                    </span>
                  )}
                </FormGroup>
              </Col>
            )}

            <Col md={`${isLiveView ? "6" : "4"}`}>
              <FormGroup>
                <Label for="billDate">Bill Date</Label>
                <DatePicker
                  date={billDate}
                  id="billDate"
                  name="billDate"
                  value={billDate}
                  onChange={changeDatePicker}
                  disabled={pageHelpers.disableField(
                    editableFields,
                    "Bill Date",
                    pageTitle
                  )}
                />
              </FormGroup>
            </Col>
            {!isCustomer && (
              <Col md={`${isLiveView ? "6" : "4"}`}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    _errs_={componentErrorMessages.name}
                    invalid={false}
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => handleChange(e, "name")}
                    disabled={
                      (customer &&
                        customer.customerName.toLowerCase() !== "others") || //disable editing if customer name is not others
                      pageHelpers.disableField(
                        editableFields,
                        "CustomerName",
                        pageTitle
                      ) // disable input if name is not an editable field
                    }
                  />
                  {componentErrorMessages.name && (
                    <span className={"inputError"}>
                      {componentErrorMessages.name}
                    </span>
                  )}
                </FormGroup>
              </Col>
            )}
            {/* <Col md={`${isLiveView ? "6" : "4"}`}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  _errs_={componentErrorMessages.name}
                  invalid={false}
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  // onChange={this.handleChange}
                  onChange={(e) => handleChange(e, "name")}
                />
                {componentErrorMessages.name && (
                  <span className={"inputError"}>
                        {componentErrorMessages.name}
                      </span>
                )}
              </FormGroup>
            </Col>
         */}
            <Col md={`${isLiveView ? "6" : "4"}`}>
              <FormGroup>
                <Label>Email Address</Label>
                <Input
                  type="text"
                  className="inputError"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => handleChange(e, "email")}
                  disabled={
                    isCustomer ||
                    pageHelpers.disableField(
                      editableFields,
                      "CustomerEmail",
                      pageTitle
                    )
                  }
                />
                {componentErrorMessages.email && (
                  <span className={"inputError"}>
                    {componentErrorMessages.email}
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={`${isLiveView ? "6" : "4"}`}>
              <FormGroup>
                <Label for="phoneNumber">Phone Number</Label>
                <Input
                  type="text"
                  _errs_={componentErrorMessages.phoneNumber}
                  name="phoneNumber"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => handleChange(e, "phoneNumber")}
                  disabled={
                    isCustomer ||
                    pageHelpers.disableField(
                      editableFields,
                      "CustomerPhoneNumber",
                      pageTitle
                    )
                  }
                />
                {componentErrorMessages.phoneNumber && (
                  <span className={"inputError"}>
                    {componentErrorMessages.phoneNumber}
                  </span>
                )}
              </FormGroup>
            </Col>

            <Col md={`${isLiveView ? "6" : "4"}`}>
              <FormGroup>
                <Label>Address</Label>
                <Input
                  type="text"
                  _errs_={componentErrorMessages.address}
                  name="address"
                  id="address"
                  value={address}
                  onChange={(e) => handleChange(e, "address")}
                  disabled={
                    isCustomer ||
                    pageHelpers.disableField(
                      editableFields,
                      "CustomerAddress",
                      pageTitle
                    )
                  }
                />
                {componentErrorMessages.address && (
                  <span className={"inputError"}>
                    {componentErrorMessages.address}
                  </span>
                )}
              </FormGroup>
            </Col>
            <Col md={`${isLiveView ? "6" : "4"}`}>
              <FormGroup>
                <Label>Currency</Label>
                <SelectList
                  placeholder={`${isUpdateInvoice ? "" : "Naira"}`}
                  _errs_={componentErrorMessages.currency}
                  data={currencies}
                  valueKey={"id"}
                  textField={"name"}
                  selectValue={currency}
                  onValueChange={(event) => {
                    handleSelectChange(event, "currency");
                  }}
                  disabled={pageHelpers.disableField(
                    editableFields,
                    "Currency",
                    pageTitle
                  )}
                />
                {componentErrorMessages.currency && (
                  <span className={"inputError"}>
                    {componentErrorMessages.currency}
                  </span>
                )}
              </FormGroup>
            </Col>

            <Col md={`${isLiveView ? "6" : "4"}`}>
              <FormGroup>
                <Label>RRR (optional)</Label>
                <Input
                  type="text"
                  name="rrrNumber"
                  id="rrrNo"
                  value={rrrNumber}
                  defaultValue=""
                  onChange={(e) => handleChange(e, "rrrNumber")}
                  disabled={pageHelpers.disableField(
                    editableFields,
                    "RRR",
                    pageTitle
                  )}
                />
              </FormGroup>
            </Col>
            <Col md={`${isLiveView ? "6" : "4"}`}>
              <FormGroup>
                <Label>Location</Label>
                <SelectList
                  placeholder={"Select location..."}
                  _errs_={componentErrorMessages.location}
                  data={locations}
                  valueKey={isLocationMapping ? "locationId" : "id"}
                  textField={isLocationMapping ? "locationAddress" : "address"}
                  selectValue={location}
                  onValueChange={(event) => {
                    handleSelectChange(event, "location");
                  }}
                  disabled={pageHelpers.disableField(
                    editableFields,
                    "Location",
                    pageTitle
                  )}
                />
                {componentErrorMessages.location && (
                  <span className={"inputError"}>
                    {componentErrorMessages.location}
                  </span>
                )}
              </FormGroup>
            </Col>
            {revenue.id === 112 && (
              <Col md={`${isLiveView ? "6" : "4"}`}>
                <FormGroup>
                  <Label>Footer note option</Label>
                  <SelectList
                    placeholder={"select footer note..."}
                    _errs_={componentErrorMessages.footerNote}
                    data={footerOptions}
                    valueKey={"optionName"}
                    textField={"optionValue"}
                    selectValue={footerNote}
                    onValueChange={(event) => {
                      handleSelectChange(event, "footerNote");
                    }}
                    disabled={pageHelpers.disableField(
                      editableFields,
                      "FooterOptions",
                      pageTitle
                    )}
                  />
                  {componentErrorMessages.footerNote && (
                    <span className={"inputError"}>
                      {componentErrorMessages.footerNote}
                    </span>
                  )}
                </FormGroup>
              </Col>
            )}
            {revenueGroupTag && (
              <Col md={`${isLiveView ? "6" : "4"}`}>
                <FormGroup>
                  <Label>{revenueGroupTag}</Label>
                  <SelectList
                    placeholder={`Select ${revenueGroupTag}...`}
                    _errs_={componentErrorMessages.revenueGroup}
                    data={revenueGroups}
                    valueKey={"id"}
                    textField={"name"}
                    selectValue={revenueGroup}
                    onValueChange={(event) => {
                      handleSelectChange(event, "revenueGroup");
                    }}
                    // disabled={pageHelpers.disableField(
                    //   editableFields,
                    //   "Revenue Group",
                    //   pageTitle
                    // )}
                  />
                  {componentErrorMessages.revenueGroup && (
                    <span className={"inputError"}>
                      {componentErrorMessages.revenueGroup}
                    </span>
                  )}
                </FormGroup>
              </Col>
            )}
          </Row>

          <InvoiceItems
            invoiceItems={invoiceItems}
            handleItemChange={handleItemChange}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            isCustomer={isCustomer}
            removeInvoiceItem={removeInvoiceItem}
            addInvoiceItemFields={addInvoiceItemFields}
            componentErrorMessages={componentErrorMessages}
            decimalPrecision={decimalPrecision}
            disableRate={disableRate}
            editableFields={editableFields}
            pageTitle={pageTitle}
            invoiceStatus={invoiceStatus}
          />

          {!isCustomer && displayReasonForChange && (
            <ReasonForChange
              name={"reasonForChange"}
              value={reasonForChange}
              componentErrorMessages={componentErrorMessages}
              handleChange={handleChange}
            />
          )}

          <div className="float-right">
            <Button
              color="success"
              className="btn-sm btn-rounded  mr-2 text-white"
              onClick={showInvoiceSheet}
              disabled={isRequesting}
            >
              {isRequesting ? requestingText : action}
            </Button>
            <Button
              onClick={cancelInvoice}
              outline
              className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
            >
              Cancel
            </Button>
          </div>
        </Col>

        {isLiveView && (
          <Col md="6">
            <div className={"live-view"}>{renderInvoiceView}</div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default SearchPanel;
