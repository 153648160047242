import React from "react";
import { Row, Col, Button } from "reactstrap";
// import moment from "moment";
import ContentLayout from "../../components/ContentLayout";
import RequestCard from "../../components/RequestCard";
import { appConstants } from "../../_constants/app.constants";
import { commonService } from "../../_services";
import { commonActions, alertActions } from "../../_actions";
import { connect } from "react-redux";
import axios from "axios";
import ServerPaginatedTable from "../../components/ServerPagination";
import VoidRequestTable from "./voidRequestTable";
import EmptyRecordMessage from "../../components/EmptyRecordMessage/EmptyRecordMessage";
import {appHelpers} from "../../_helpers";
import {FaArrowLeft} from "react-icons/fa";
import SearchPanel from "./search-panel";


let source;
class ManageWorkflowApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCard: 0,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      searching: false,
      totalRequest: 0,
      approvedRequests: 0,
      declinedRequests: 0,
      unattendedRequests: 0,
      tableData: 0,
      selectedStatus: -1,
      requestType: "void",
      requestTypes: [{ name: "void" }, { name: "edit" }],
      searchingByEditedInvoices: false,
    };

    source = axios.CancelToken.source();

  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";
    const { dispatch} = this.props;
  
    //Call branding if there is no method to display select agency view
    appHelpers.checkDerivedStateFromPropsMethod.call(this);
    
    this.sendRequest(null, -1, );
    dispatch(commonActions.setPeriodPickerVisibility(false));
  }


  sendRequest = (pageNumber , status) => {
    let { dispatch } = this.props;
    let { pageSize, selectedStatus, requestType} = this.state;
    let fromIndex;
    if (pageNumber === undefined || pageNumber === null) {
      fromIndex = 0;
      pageNumber = 0;
    } else {
      fromIndex = (pageNumber * pageSize) - pageSize;
    }
    let payload = {
      pageSize: pageSize,
      from: fromIndex,
      parameter: {
        // userId: user.userId,
        status: selectedStatus,
        // startIndex: (pageNumber === 1 || pageNumber === 0) ? 0 : pageNumber - 1,
        pageSize: pageSize,
        requestType: requestType,
      }
    };
    dispatch(alertActions.startRequest());
    commonService.fetchVoidInvoices(payload, source.token)
      .then(res => {
        dispatch(alertActions.stopRequest());
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          this.setState({
            totalRequest: response.data.totalRequest,
            approvedRequests: response.data.approved,
            declinedRequests: response.data.rejected,
            unattendedRequests: response.data.pending,
            tableData: response.data.wofkflow,
            totalCount: selectedStatus === 0 ? response.data.pending : selectedStatus === 1 ? response.data.approved : selectedStatus === 2 ? response.data.rejected : response.data.totalRequest,
            currentPage: (pageNumber === 0 ) ? pageNumber + 1 : pageNumber
          })
        }
        else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response))
        }
      })
  };

  updateTable = id => {
    // let status = { pending: 0, approved: 1, rejected: 2, all: -1 };

    switch (id) {
      case 0:
        this.setState({ selectedCard: 0 , selectedStatus: -1}, () => {
          this.sendRequest();
        });
        break;
      case 1:
        this.setState({ selectedCard: 1, selectedStatus: 1}, () => {
          this.sendRequest();
        });
        break;
      case 2:
        this.setState({ selectedCard: 2, selectedStatus: 2}, () => {
          this.sendRequest();
        });
        break;
      case 3:
        this.setState({ selectedCard: 3, selectedStatus: 0}, () => {
          this.sendRequest();
        });
        break;
      default:
        this.setState({ selectedCard: 0, selectedStatus: -1 });
        break;
    }
  };

  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
   }


   handleSelectChange = (payload, attribute) => {
    let currState = this.state;
    if (!payload) {
      currState[attribute] = "";
     
    } else {
      if (attribute === "requestType") {
        currState[attribute] = payload.name;
        if(payload.name === "edit"){
          this.setState({
            searchingByEditedInvoices: true,
          });
        }else {
          this.setState({ searchingByEditedInvoices: false });
        }
     
    }
    this.setState({ currState });
  }
  };

  render() {
    const { tableData, selectedCard, totalRequest, approvedRequests, declinedRequests,
      unattendedRequests, totalCount, currentPage, pageSize, requestTypes } = this.state;
    const TR_BgColor =
      selectedCard === 0
        ? appConstants.TOTALREQUESTS_ACTIVE
        : appConstants.TOTALREQUESTS_INACTIVE;
    const TR_TextColor = selectedCard === 0 ? "white" : "black";

    const AR_BgColor =
      selectedCard === 1
        ? appConstants.APPROVEDREQUEST_ACTIVE
        : appConstants.APPROVEDREQUESTS_INACTIVE;
    const AR_TextColor = selectedCard === 1 ? "white" : "black";

    const DR_BgColor =
      selectedCard === 2
        ? appConstants.DECLINEDREQUESTS_ACTIVE
        : appConstants.DECLINEDREQUESTS_INACTIVE;
    const DR_TextColor = selectedCard === 2 ? "white" : "black";

    const UR_BgColor =
      selectedCard === 3
        ? appConstants.UNATTENDEDQUESTS_ACTIVE
        : appConstants.UNATTENDEDQUESTS_INACTIVE;
    const UR_TextColor = selectedCard === 3 ? "white" : "black";

    const TABLE_BgColor =
      selectedCard === 0
        ? appConstants.TOTALREQUESTS_ACTIVE : selectedCard === 1
          ? appConstants.APPROVEDREQUEST_ACTIVE : selectedCard === 2
            ? appConstants.DECLINEDREQUESTS_ACTIVE: selectedCard === 3
            ?appConstants.UNATTENDEDQUESTS_ACTIVE:"";



    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle="Manage Workflow Approval"
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className="big-action-btn btn-rounded mr-2"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <SearchPanel
          requestTypes={requestTypes}
          selectChange={this.handleSelectChange}
          onSearch={this.sendRequest}
        />

        <br />
        <br />

        <Row>
          <Col md={"3"} className={"request-card"}>
            <RequestCard
              headerColor={TR_TextColor}
              color={TR_TextColor}
              bgColor={TR_BgColor}
              action={"Total Request"}
              value={totalRequest}
              getCardDetails={this.updateTable.bind(this, 0)}
            />
          </Col>
          <Col md={"3"} className={"request-card"}>
            <RequestCard
              headerColor={AR_TextColor}
              color={AR_TextColor}
              bgColor={AR_BgColor}
              action={"Approved Requests"}
              value={approvedRequests}
              getCardDetails={this.updateTable.bind(this, 1)}
            />
          </Col>
          <Col md={"3"} className={"request-card"}>
            <RequestCard
              headerColor={DR_TextColor}
              color={DR_TextColor}
              bgColor={DR_BgColor}
              action={"Declined Requests"}
              value={declinedRequests}
              getCardDetails={this.updateTable.bind(this, 2)}
            />
          </Col>
          <Col md={"3"} className={"request-card"}>
            <RequestCard
              headerColor={UR_TextColor}
              color={UR_TextColor}
              bgColor={UR_BgColor}
              action={"Unattended Requests"}
              value={unattendedRequests}
              getCardDetails={this.updateTable.bind(this, 3)}
            />
          </Col>
        </Row>

        <br />
        <br />
        {tableData.length === 0 && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}
        {tableData.length > 0 && (
          <Row>
            <Col md={12}>
              <ServerPaginatedTable
                currentPage={currentPage}
                fetchMore={this.sendRequest}
                pageSize={pageSize}
                totalCount={totalCount}
                target={
                  <VoidRequestTable
                    BgColor={TABLE_BgColor}
                    tableData={tableData}
                    selectedCard={this.state.selectedCard}
                    sendRequest={this.sendRequest}
                    requestType={this.state.requestType}
                    searchingByEditedInvoices={
                      this.state.searchingByEditedInvoices
                    }
                  />
                }
              />
            </Col>
          </Row>
        )}
        <br />
        <br />
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { requesting, user} = state;
  return {
    requesting,
    user
  }
}
export default connect(mapStateToProps)(ManageWorkflowApproval);
