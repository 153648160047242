import { appConstants } from "../_constants";
import { initialState } from "../_store";

export function showNotification(state = initialState.showNotification, action) {

    switch (action.type) {
        case appConstants.SHOW_NOTIFICATION:
            return action.show;
        default:
            return state;
    }
}
