import React, { Component } from "react";
import { Table, Badge } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import { appHelpers } from "../../../_helpers";
import "./index.scss";

const ReportTable = ({ tableData, downloadInvoice}) => {
  return (
    <Table responsive striped hover  className={"table-main"}>
      <thead className={"th-head"}>
        <tr>
          <th>Credit Ref</th>
          <th>Revenue Line</th>
          <th>Invoice No</th>
          <th>Customer</th>
          <th>Old Invoice Amount</th>
          <th>Credit Amount</th>
          <th>Payable Amount</th>
          <th>Credit Balance</th>
          <th>Type</th>
          <th>Payment</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((item, index) => {
            return (
              <tr
                key={index}
                className="table-row"
              >
                <td>{item.referenceNumber}</td>
                <td>{item.revenueName}</td>
                <td>{item.invoiceNumber}</td>
                <td>{item.serviceProviderName}</td>
                <td>{appHelpers.numberWithCommas(item.invoiceAmount)}</td>
                <td>{appHelpers.numberWithCommas(item.creditAmount)}</td>
                <td>{appHelpers.numberWithCommas(item.newInvoiceAmount)}</td>
                <td>{appHelpers.numberWithCommas(item.creditBalance)}</td>
                <td>{item.creditNoteTypeName}</td>
                <td>{item.requestStatusName}</td>
                <td style={{ padding: "none" }} onClick={(e) => downloadInvoice(e, item)}><Badge
              className={"badge-spacing table-row"}
              size="xl" pill>View</Badge></td>       
                {/* <td><GoKebabVertical
                >View</GoKebabVertical></td> */}
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default class TableStructure extends Component {
  render () {
  let {
  tableData,
  currentPage,
  fetchMoreRecords,
  pageSize,
  totalCount,
  downloadInvoice,
  downloadSearchInvoice,
} = this.props;
  const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
  return (
    <ServerPaginatedTable
      currentPage={currentPage}
      fetchMore={fetchMoreRecords}
      pageSize={pageSize}
      totalCount={totalCount}
      topDownload
      bottomDownload
      downloadPdf={() => {
        downloadSearchInvoice("pdf");
      }}
      downloadExcel={() => {
        downloadSearchInvoice("excel");
      }}
      target={
        <ReportTable
          tableData={tableData}
          pageIndex={pageIndex}
          downloadInvoice={downloadInvoice}     
             />
      }
    />
  );
    }
};

