import React, { Component } from "react";
import { Table } from "reactstrap";
import ServerPaginatedTable from "../../../components/ServerPagination";
import "./index.scss";
import moment from "moment";
import { appHelpers } from "../../../_helpers";

const ReportTable = ({ tableData, currentPage, pageSize }) => {
  return (
    <Table responsive striped hover className={"table-main"}>
      <thead className={"th-head"}>
        <tr>
          <th>S/N</th>
          <th>RRR</th>
          <th>Invoice Number</th>
          <th>Channel</th>
          <th>Total Amount Paid from Remita</th>
          <th>Invoice Amount</th>
          <th>Total Amount Processed on BDA</th>
          <th>Transaction Date</th>
          <th>Revenue</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((item, index) => {
          return (
            <tr key={index} className="table-row">
              <td>{index + 1 + (currentPage - 1) * pageSize}</td>
              <td>{item.rrr}</td>
              <td>{item.invoiceNumber}</td>
              <td>{item.channel}</td>
              <td>{item.totalRemitaAmount}</td>
              <td>{item.amount}</td>
              <td>{item.bdaProcessedAmount}</td>
              <td>{moment(item.transactionDate).format("YYYY-MM-DD")}</td>
              <td>{item.revenueName}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default class TableStructure extends Component {
  render() {
    let {
      tableData,
      currentPage,
      fetchMoreRecords,
      pageSize,
      totalCount,
      showRequestDetails,
      selectedCard,
    } = this.props;

    const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
    return (
      <ServerPaginatedTable
        currentPage={currentPage}
        fetchMore={fetchMoreRecords}
        pageSize={pageSize}
        totalCount={totalCount}
        target={
          <ReportTable
            tableData={tableData}
            pageIndex={pageIndex}
            showRequestDetails={showRequestDetails}
            selectedCard={selectedCard}
            currentPage={currentPage}
            pageSize={pageSize}
          />
        }
      />
    );
  }
}
