import React from 'react';
import {Row, Col, Button} from 'reactstrap';
import Toggle from "react-toggle";

const UserProfile = ({userName, role, email, editUserProfile, userDetails, handleActivationStatus, status}) => {
    return (
        <div>
            <Row>
                <Col md={4}>
                    <h2>{userName}</h2>
                    <>
                        <label className={"toggle-label"}>
                            <Toggle
                                // defaultChecked={status}
                                checked={status}
                                onChange={(e) => handleActivationStatus(e)} />
                        </label>
                  </>
                </Col>
                <Col md={4}>
                    <div >
                        <h6>Role</h6>
                        <p>{role}</p>
                    </div>
                    <div >
                        <h6>Email Address</h6>
                        <p>{email}</p>
                    </div>
                </Col>
                <Col md={4}>
                    {status !== false &&
                   <Button
                       style={{padding: "5px 30px", fontSize: "16px", marginTop: "20px"}}
                       color="primary"  className="btn-sm btn-rounded float-right"
                       onClick={() => editUserProfile(userDetails)}
                   >Edit Profile</Button>
                    }
                </Col>
            </Row>
        </div>
    );
};

export default UserProfile;