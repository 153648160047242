import React from "react";
import { Card, CardBody } from "reactstrap";
import Spinner from "../Spinner"
const setCurrency = currency => {
  switch (currency) {
    case "dollar":
      return <span>&#36;</span>;
    case "naira":
      return <span>&#8358;</span>;

    default:
      return <span></span>;
  }
};

const OverviewCard = ({ label, currency, amount, count, borderColor, visible=true }) => {
  return (
    <Card className="" style={{ borderRadius: "0.25rem", borderBottom: `0.3rem solid ${borderColor}` }}>
      <CardBody>
        <div className="d-flex">
          <div className="stats">
            <div><small className="text-uppercase">{label}</small></div>
            { visible === false ?<h4 className="mt-1 mb-4 font-bold font-20">
              {setCurrency(currency)}
              {`${amount}`}
            </h4>: <Spinner size={20}  visible={visible} />}
            <div><small className="text-uppercase">Count</small></div>
            {visible === false ?<h3 className="">{count}</h3>:<div><Spinner size={20}  visible={visible} /></div>}
          </div>
        </div>
      </CardBody>

    </Card>
  );
};

export default OverviewCard;
