import React from 'react';


class Footer extends React.Component {
  render() {
    return (
      <footer className="footer text-center">
        All Rights Reserved by Avitech Nigeria.
      </footer>
    );
  }
}
export default Footer;
