import React, { Component } from "react";
import TableStructure from "./record";
import { Button } from "reactstrap";
import SearchBar from "../../../components/SearchBar";
import ContentLayout from "../../../components/ContentLayout";
import { FaArrowLeft, FaCheckCircle, FaPlusCircle, FaTimes } from "react-icons/fa";
import { Row, Col } from "reactstrap";
import { appHelpers } from "../../../_helpers";
import FullScreenModal from "../../../components/FullScreenModal";
import { commonService } from "../../../_services/common.service";
import { appConstants } from "../../../_constants/app.constants";
import { connect } from "react-redux";
import {alertActions, commonActions} from "../../../_actions";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import "./index.scss";
import axios from "axios";
import AddEditCard from "../../../components/AddEditCard";
import  {RenderAlertModal} from "../../../components/AlertModal";

let source;

class ManageLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      showModalView: false,
      codeModal: "",
      locationModal: "",
      locationValue: "",
      code: "",
      state: 0,
      tableData: [],
      status: "",
      searching: false,
      agencies: [],
      agency: 0,
      states: [],
      recordId: 0,
      showAlertModal: false,
      alertIcon: "",
      alertMessage: "",
      editModalView: false,
      changeSwitch: false,
      isEdit: false,
    };

    source = axios.CancelToken.source();

  }

  componentDidMount() {
    /* Remove overflow hidden caused by modal */
    const body = document.body;
    body.style.overflow = "scroll";
  
    //Call branding if there is no method to display select agency view
    appHelpers.checkDerivedStateFromPropsMethod.call(this);
    
    const { dispatch, states, organizations, user } = this.props;

    if (states) {
      this.setState({ states: states.data });
    } else {
      dispatch(commonActions.getAllStates(source.token));
    }

    //If user is not vgg admin, set agency variable in state
    //to user's Organization ID
    if (user.vggRole !== appConstants.ROLES.VGG_ADMIN) {
      this.setState({ agency: user.organizationId });
    }
    if (organizations) {
      this.setState({ agencies: organizations.data });
    } else {
      dispatch(commonActions.getAllOrganization());
    }
    this.searchLocation();
  }

  componentWillReceiveProps = function (nextProps) {
    const { states, organizations } = nextProps;
    if (states) {
      this.setState({ states: states.data });
    }

    if (organizations) {
      this.setState({ agencies: organizations.data });
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSelectChange = (payload, attribute) => {
    let currState = this.state;
    if (!payload) {
      currState[attribute] = "";
    } else {
      if (attribute === "state") {
        currState[attribute] = payload.id;
      } else if (attribute === "agency") {
        currState[attribute] = payload.id;
      } else {
        currState[attribute] = payload.Id;
      }
    }
    this.setState({ currState });
  };

  hideModal = () => {
    this.setState({ showModalView: false });
    this.resetForm();
  };

  isShowModal = () => {
    this.setState({ showModalView: true });
  };

  hideAlertView = (response) => {
    this.setState({ showAlertModal: response });
  };

  handleEdit = (item) => {
    this.setState({
      showModalView: true,
      recordId: item.id,
      locationModal: item.location,
      codeModal: item.code,
      agency: item.agencyId,
      state: item.stateId,
      isEdit: true,
      selectedEditRecord: item,
    });
  };

  resetForm = () => {
    const { user } = this.props;
    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      this.setState({
        agency: "",
      });
    }
    this.setState({
      locationModal: "",
      codeModal: "",
      state: "",
      isEdit: false,
    });
  };

  renderModalView = () => {
    let modal = [];
    let count = 0;
    const {
      showModalView,
      states,
      state,
      agencies,
      agency,
      codeModal,
      locationModal,
      isEdit,
    } = this.state;
    const { user } = this.props;
    
    modal.push(
      <FullScreenModal
        show={showModalView}
        key={count + 1}
        callback={this.hideModal}
        width={"37%"}
        zIndex={10}
      >
        <AddEditCard
          modalTitle={isEdit ? "Edit Location" : "Add New Location"}
          labelOne={"Location Name"}
          locationModal={locationModal}
          codeModal={codeModal}
          states={states}
          state={state}
          fieldTwoValueKey={"id"}
          fieldTwoTextField={"name"}
          fieldTwoDefaultValue={isEdit ? states.filter(option => option.id === state) : ""}
          fieldThreePlaceholder={"Select agency"}
          fieldThreeData={agencies}
          disableAgency={user.vggRole !== appConstants.ROLES.VGG_ADMIN}
          fieldThreeValueKey={"id"}
          fieldTextField={"name"}
          fieldSelectValue={agency}
          defaultValue={isEdit && user.vggRole ? agencies.filter(option => option.id === agency) : agencies.filter(option => option.id === agency)}
          // defaultValue={isEdit && user.vggRole === appConstants.ROLES.VGG_ADMIN ? agencies.filter(option => option.id === selectedEditRecord.agencyId) : agencies.filter(option => option.id === agency)}
          fieldThreeAttribute={"agency"}
          showSelectRegion
          selectState={"State"}
          labelThree={"Agency"}
          labelFour={"Code"}
          addClick={this.createLocation.bind(this, 0)}
          selectChange={this.handleSelectChange}
          handleChange={this.handleChange}
          cancelClick={this.cancel}
          addText={isEdit ? "Save" : "Add Location"}
          cancelText={"Cancel"}
          right={"219px"}
        />
      </FullScreenModal>
    );
    return modal;
  };

  //search location
  searchLocation = (pageNumber = 1) => {
    const { dispatch } = this.props;
    const { pageSize, code, locationValue } = this.state;
    dispatch(alertActions.startRequest());

    const payload = {
      pageSize: pageSize,
      from: pageNumber,
      parameter: {
        location: locationValue,
        code: code,
      },
    };

    commonService.searchLocation(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          currentPage: pageNumber === 0 ? 1 : pageNumber,
          totalCount: response.totalCount,
          tableData: response.results,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        if (response === "Please Check your internet connectivity") {
          dispatch(alertActions.error(response));
        }
      }
    });
  };

  createLocation = () => {
    const { dispatch } = this.props;
    const {
      agency,
      state,
      recordId,
      isEdit,
      codeModal,
      locationModal,
    } = this.state;

    let payload = {
      location: locationModal,
      code: codeModal,
      agencyId: agency,
      stateId: state,
    };
    if (isEdit) {
      payload.id = recordId;
    }

    dispatch(alertActions.startRequest());
    commonService.createLocation(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(response, true, this.searchLocation())
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response, false)
      }
    });
  };

  activateLocation = (payload) => {
    const { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    commonService.activateLocation(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(response, true, this.searchLocation())
        // this.setState(
        //   {
        //     showAlertModal: true,
        //     alertIcon: (
        //       <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
        //     ),
        //     alertMessage: response.message,
        //   },
        //   () => {
        //     this.searchLocation();
        //     appHelpers.dismissModal.call(this, "showAlertModal");
        //   }
        // );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.displayErrorResponseAlert(response, true, this.searchLocation())
      }
      //   if (typeof response === "string") {
      //     this.searchLocation();
      //     dispatch(alertActions.error(response));
      //     this.setState({ alertMessage: response });
      //   } else {
      //     this.searchLocation();
      //     dispatch(alertActions.error(response.message));
      //     this.setState({ alertMessage: response.message });
      //   }
      // }
    });
  };
  
  deactivateLocation = (payload) => {
    const { dispatch } = this.props;
    dispatch(alertActions.startRequest());
    commonService.deactivateLocation(payload, source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;

      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.displaySuccessResponseAlert(response, true, this.searchLocation())
        // this.setState(
        //   {
        //     showAlertModal: true,
        //     alertIcon: (
        //       <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
        //     ),
        //     alertMessage: response.message,
        //   },
        //   () => {
        //     this.searchLocation();
        //     appHelpers.dismissModal.call(this, "showAlertModal");
        //   }
        // );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        
        this.displayErrorResponseAlert(response, true, this.searchLocation())
      //   this.searchLocation();
      //   if (typeof response === "string") {
      //     dispatch(alertActions.error(response));
      //     this.setState({ alertMessage: response });
      //   } else {
      //     dispatch(alertActions.error(response.message));
      //     this.setState({ alertMessage: response.message });
      //   }
      }
    });
  };

  clearFields = () => {
    const { user } = this.props;
    if (user.vggRole === appConstants.ROLES.VGG_ADMIN) {
      this.setState({
        agency: "",
      });
    }
    this.setState({
      locationModal: "",
      codeModal: "",
      state: "",
    });
  };

  handleSwitchChecked = (e, item) => {
    let payload = {
      LocationId: item.id,
    };
    if (e.target.checked === true) {
      this.activateLocation(payload);
    }
    if (e.target.checked === false) {
      this.deactivateLocation(payload);
    }
  };

  dismissModal = (alert) => {
    setTimeout(() => {
      const body = document.body;
      body.style.overflow = "scroll";
      this.setState({ [alert]: false });
    }, 5000);
  };

  searchBarChange = (e) => {
    this.setState({ locationValue: e.target.value }, () => {
      if (this.state.locationValue === "") {
        this.searchLocation();
      }
    });
  };

  search = (e) => {
    e.preventDefault();
    this.searchLocation();
  };

  cancel = () => {
    const body = document.body;
    body.style.overflow = "scroll";
    this.setState({ showModalView: false });
    this.resetForm();
  };
  
  //Alert Notifications
  
  displaySuccessResponseAlert = (response, action, query) => {
    this.setState(
      {
        showAlertModal: true,
        alertIcon: (
          <FaCheckCircle size={100} color={appConstants.SUCCESS_COLOR} />
        ),
        alertMessage: response.message,
      },
      () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
      }
    );
    this.clearFields();
    this.hideModal();
    if (action) {
      return {query}
    }
  };
  
  displayErrorResponseAlert = (response, action, query) => {
    const {dispatch} = this.props;
    if (response === "Please Check your internet connectivity") {
      dispatch(alertActions.error(response));
    }else if (typeof response === "string") {
      this.setState({
        showAlertModal: true,
        alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
        alertMessage: response,
        
      }, () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
        if(action){
          return query
        }
      });
      
    } else {
      this.setState({
        showAlertModal: true,
        alertIcon: <FaTimes size={100} color={appConstants.DANGER_COLOR} />,
        alertMessage: response.message,
        
      }, () => {
        appHelpers.dismissModal.call(this, "showAlertModal");
        if(action){
          return query
        }
      });
    }
  };

  componentWillUnmount () {
   
    if (source) {
      source.cancel()
    }
   }

  render() {
    const {
      currentPage,
      totalCount,
      pageSize,
      changeSwitch,
      showModalView,
      locationValue,
      tableData,
      showAlertModal,
      alertIcon,
      alertMessage,
      requesting
    } = this.state;
    
    return (
      <ContentLayout
        requesting={this.props.requesting}
        pageTitle={"Manage Location"}
        actions={
          <div className={"float-right"}>
            <Button
              outline
              color="info"
              className=" btn-rounded mr-2 big-action-btn"
              onClick={() =>appHelpers.navigateToPreviousRoute.call(this )}                            
              disabled={requesting}
            >
              <FaArrowLeft className={"icon-margin"} />
              Back
            </Button>
          </div>
        }
      >
        <Row className={"row-space"}>
          <Col>
            {" "}
            <Button
              className="btn-rounded mr-2 create-action-btn text-white"
              onClick={this.isShowModal}
              color={"success"}
            >
              <FaPlusCircle className={"icon-margin mb-1"} />
              Add New Location
            </Button>
          </Col>
        </Row>

        <Row className={"settings-spacing"}>
          <Col sm={{ size: 5 }} className="text-center">
            <SearchBar
              placeholder="Search Location"
              showSearchButton
              onChange={this.searchBarChange}
              value={locationValue}
              search={this.search}
            />
          </Col>
        </Row>

        <br />
        <br />
        {tableData.length === 0 && (
          <EmptyRecordMessage message={"No record match your search"} />
        )}
        {tableData.length > 0 && (
          <Row>
            <Col md={"12"}>
              <TableStructure
                apiData={tableData}
                pageSize={pageSize}
                totalCount={totalCount}
                changeSwitch={changeSwitch}
                currentPage={currentPage}
                fetchMore={this.searchLocation}
                toggleModalFunction={this.handleEdit}
                handleSwitchChecked={this.handleSwitchChecked}
                user={this.props.user}
              />
            </Col>
          </Row>
        )}

        {showModalView === true && this.renderModalView()}
        {showAlertModal && (
          <RenderAlertModal
            message={alertMessage}
            icon={alertIcon}
            showAlertModal={showAlertModal}
            hideAlertView={this.hideAlertView}
          />
        )}
      </ContentLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    modal,
    alert,
    progress,
    user,
    requesting,
    states,
    organizations,
  } = state;
  return {
    modal,
    alert,
    progress,
    user,
    requesting,
    states,
    organizations,
  };
};

export default connect(mapStateToProps)(ManageLocations);
