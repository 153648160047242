import React from 'react';
import { modalActions} from "../../_actions";
import FullScreenModal from "../FullScreenModal";
import {connect} from "react-redux";
import {appHelpers} from "../../_helpers";

const DisplayInvoiceFullScreenModal = ({
                                       displayInvoiceSheet, dispatch, invoiceSheetActions,
                                       renderInvoiceView, requesting
                                     }) => {
  return (
    <FullScreenModal
      show={displayInvoiceSheet.show }
      // key={count + 1}
      isInvoiceSheet={true}
      actions={invoiceSheetActions}
      callback={(res)  => {
        appHelpers.fullScreenCallback.call(this, res, dispatch, displayInvoiceSheet, modalActions.displayInvoiceSheet({
          show: false
        }))
      }}
      zIndex={requesting ? 10 : 999}
    >
      {renderInvoiceView}
    </FullScreenModal>
  );
};

const mapStateToProps = (state) => ({
  ...state
});

export default connect(mapStateToProps)(DisplayInvoiceFullScreenModal);

