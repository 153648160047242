import React from "react";
import {FaArrowLeft, FaEye } from "react-icons/fa";
import {Button} from "reactstrap"; 

const CreateInvoiceActionButtons = ({showPreview, isLiveView, isShowPreview,
    toggleLiveView}) => {
    return (
        <div className="float-right mb-2">
        {!showPreview && (
          <Button
            color="info"
            className="btn-sm btn-rounded px-4 mr-2"
            onClick={isShowPreview}
          >
            Preview
          </Button>
        )}
        {isLiveView && (
          <Button
            outline
            color="info"
            className="btn-sm btn-rounded px-4 mr-2"
            onClick={toggleLiveView}
          >
            <FaArrowLeft className={"icon-margin"} />
            Back
          </Button>
        )}
        {!isLiveView && (
          <Button
            outline
            color="info"
            className="btn-sm btn-rounded mr-2"
            onClick={toggleLiveView}
          >
            Live View <FaEye />
          </Button>
        )}
      </div>
    )
}

export default CreateInvoiceActionButtons;