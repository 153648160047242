import React from 'react';
import {Button} from "reactstrap";
import "./index.scss";
import { FaPlus} from 'react-icons/fa';

const AddRecordButton = ({actionText, addRecord}) => {
  return (
    <Button
      color="success"
      className="big-action-btn mr-2 text-white"
      onClick={addRecord}
    
    >
      <FaPlus className={"icon-margin"} />
      {actionText}
    </Button>
  );
};

export default AddRecordButton;