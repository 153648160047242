import React, { Component } from "react";
import { Table, Tooltip } from "reactstrap";
import ServerPaginatedTable from "../../components/ServerPagination";
import moment from "moment";
import { FaCopy, FaEye} from "react-icons/fa";
import "./index.scss";

const Records = ({
  tableData,
  viewInvoice,
  clickedRow,
  isCustomer,
  copyInvoiceNumber,
  invoiceMouseOut,
  invoiceMouseOver,
  showTooltip,
  openTooltip,
  // toggleTooltip,
  tooltipTarget,
  copyIconMouseover,
  copyIconMouseLeave,
  searchingByEditedInvoices,
  viewChanges,
}) => {
  return (
    <Table responsive={true} className={"table-main"}>
      <thead className={"th-head"}>
        <tr>
          <th style={{ padding: "none" }}>Invoice No</th>
          <th style={{ padding: "none" }}>Status</th>
          <th style={{ padding: "none" }}>Date</th>
          <th style={{ padding: "none" }}>Amount Invoiced</th>
          <th style={{ padding: "none" }}>Amount Paid</th>
          {!isCustomer && (
            <>
              <th style={{ padding: "none" }}>Customer</th>
              <th style={{ padding: "none" }}>Name</th>
            </>
          )}
          <th style={{ padding: "none" }}>Revenue</th>
          <th style={{ padding: "none" }}>Location</th>
          <th style={{ padding: "none" }}>State</th>
          {searchingByEditedInvoices && (
            <th style={{ padding: "none" }}> View Changes</th>
          )}
          {/*<th style={{ padding: "none" }}>Receipt</th>*/}
        </tr>
      </thead>
      <tbody>
        {tableData.map((item, index) => {
          return (
            <tr
              key={index}
              // onClick={(e) => viewInvoice(e, item)}
              // className={clickedRow === item.invoiceNumber && "clicked"}
              // onCopy={(e) => copyInvoiceNumber(e, item)}
            >
              <th
                className={`invoice-no-cell invoice-col-${index}`}
                onMouseLeave={(e) => invoiceMouseOut(e)}
              >
                <span
                  onClick={(e) => viewInvoice(e, item)}
                  onMouseOver={(e) => invoiceMouseOver(e, "invoice-no-cell")}
                >
                  {item.invoiceNumber}
                </span>
                <span className="invoice-copy-icon invoice-copy-icon-hide">
                  <FaCopy
                    onClick={(e) => copyInvoiceNumber(`invoice-col-${index}`)}
                    onMouseOver={(e) =>
                      copyIconMouseover(
                        e,
                        "invoice-copy-icon-show",
                        "openTooltip"
                      )
                    }
                    onMouseLeave={() => copyIconMouseLeave("openTooltip")}
                  />
                  {showTooltip && (
                    <Tooltip
                      placement="top-start"
                      isOpen={openTooltip}
                      target={tooltipTarget}
                      // toggle={toggleTooltip}
                    >
                      Copy
                    </Tooltip>
                  )}
                </span>
              </th>
              {/* <th
                style={{ padding: "none" }}
                className={`invoice-col-${index}`}
                id={`invoice-col-id-${index}`}
                onClick={(e) => viewInvoice(e, item)}
                onMouseOver={() =>
                  invoiceMouseOver(`invoice-col-${index}`, "openTooltip")
                }
                onMouseOut={() =>
                  invoiceMouseOut(`invoice-col-${index}`, "openTooltip")
                }
              >
                {item.invoiceNumber}
                <span
                  style={{ display: "none" }}
                  onClick={(e) => copyInvoiceNumber(`invoice-col-${index}`)}
                >
                  <FaCopy id={"copy-icon"} />
                  {showTooltip && (
                    <Tooltip
                      placement="bottom-start"
                      isOpen={openTooltip}
                      target={tooltipTarget}
                      // toggle={toggleTooltip}
                    >
                      Copy Invoice Number
                    </Tooltip>
                  )}
                </span>
              </th> */}
              <th style={{ padding: "none" }}>
                {item.status.toLowerCase() === "paid" ? (
                  <span className={"paid-invoice"}>{item.status}</span>
                ) : item.status.toLowerCase() === "unpaid" ? (
                  <span className={"unpaid-invoice"}>{item.status}</span>
                ) : (
                  <span>{item.status}</span>
                )}
              </th>
              <th style={{ padding: "none" }}>
                {item.invoiceDate
                  ? moment(item.invoiceDate).format("YYYY-MM-DD")
                  : "No Date"}
              </th>
              <th style={{ padding: "none" }}>{item.amountString}</th>
              <th style={{ padding: "none" }}>{item.amountPaidString}</th>
              {!isCustomer && (
                <>
                  <th style={{ padding: "none" }}>{item.customer}</th>
                  <th style={{ padding: "none" }}>{item.customerName}</th>
                </>
              )}

              <th style={{ padding: "none" }}>{item.revenue}</th>
              <th style={{ padding: "none" }}>{item.location}</th>
              <th style={{ padding: "none" }}>{item.locationState}</th>
              {searchingByEditedInvoices && (
                <th>
                  {
                    <span onClick={() => viewChanges(item)}>
                     <FaEye size={"16"} color={"#29baf1"} />
                    </span>
                  }
                </th>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default class TableStructure extends Component {
  render() {
    let {
      tableData,
      currentPage,
      fetchMoreRecords,
      pageSize,
      totalCount,
      viewInvoice,
      downloadSearchInvoice,
      searchType,
      clickedRow,
      quickSearchType,
      isCustomer,
      copyInvoiceNumber,
      invoiceMouseOut,
      invoiceMouseOver,
      openTooltip,
      // toggleTooltip,
      tooltipTarget,
      showTooltip,
      copyIconMouseover,
      copyIconMouseLeave,
      searchingByEditedInvoices,
      viewChanges,
    } = this.props;
    const pageIndex = currentPage === 0 ? 1 : pageSize * (currentPage - 1) + 1;
    return (
      <div>
        <ServerPaginatedTable
          currentPage={currentPage}
          fetchMore={fetchMoreRecords}
          pageSize={pageSize}
          totalCount={totalCount}
          topDownload
          bottomDownload
          downloadPdf={() => {
            downloadSearchInvoice(0, "pdf", quickSearchType, searchType);
          }}
          downloadExcel={() => {
            downloadSearchInvoice(0, "excel", quickSearchType, searchType);
          }}
          target={
            <Records
              tableData={tableData}
              clickedRow={clickedRow}
              viewInvoice={viewInvoice}
              pageIndex={pageIndex}
              searchType={searchType}
              isCustomer={isCustomer}
              copyInvoiceNumber={copyInvoiceNumber}
              invoiceMouseOut={invoiceMouseOut}
              invoiceMouseOver={invoiceMouseOver}
              openTooltip={openTooltip}
              // toggleTooltip={toggleTooltip}
              tooltipTarget={tooltipTarget}
              showTooltip={showTooltip}
              copyIconMouseover={copyIconMouseover}
              copyIconMouseLeave={copyIconMouseLeave}
              searchingByEditedInvoices={searchingByEditedInvoices}
              viewChanges={viewChanges}
            />
          }
        />
      </div>
    );
  }
}
