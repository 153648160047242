import React, { Component } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";
import { connect } from "react-redux";
import ContentLayout from "../../../components/ContentLayout";
import {
  settlementAccountService,
  settlementSplitService,
  commonService,
} from "../../../_services";
import { appConstants } from "../../../_constants";
import EmptyRecordMessage from "../../../components/EmptyRecordMessage/EmptyRecordMessage";
import { appHelpers } from "../../../_helpers";
import { alertActions } from "../../../_actions";
import ThirdPartySettlementAccountForm from "./thirdparty-settlement-account-form";
import axios from "axios";

let source;

class EditSettlementSplit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agencyDetails: {},
      splitTypes: [],
      settlementAccounts: [],
      settlementAccountError: [
        {
          bankError: "",
          accountNumberError: "",
          currencyError: "",
          thirdPartyShareError: "",
        },
      ],
      componentErrorMessages: {},
      verifyingAccount: false,
    };

    source = axios.CancelToken.source();
  }

  async componentDidMount() {
    this.fetchBanks();
    this.getCurrency();
    await this.setInitialState();
    await this.setAgencyBasedOnRole();
    this.fetchSettlementSplitTypes();
  }

  // fetchItemsFromStore = () => {
  //   const { apiReducer } = this.props;
  //   const { currencies, banks } = apiReducer;

  //   if (!currencies) {

  //   }

  //   if (!banks) {
  //   }
  // };

  // getCurrency = () => {
  //   return this.props.dispatch(apiActions.getCurrencies());
  // };

  // fetchBanks = () => {
  //   return this.props.dispatch(apiActions.fetchBanks());
  // };

  static getDerivedStateFromProps(props, state) {
    let pathState = props.location.state;

    if (pathState) {
      /**
       * @description settlementSplitDetails is set only in `edit mode`
       * This would be reached if the settlemnt split is to be edited
       * pathState is obtained from /view-settlement-split-per-revenue
       */

      if (
        props.location.state.revenueSplitDetails !== state.revenueSplitDetails
      ) {
        return {
          agencyDetails: props.location.state.agencyDetails,
          revenueSplitDetails: props.location.state.revenueSplitDetails,
          thirdPartySettlementSplitAccounts:
            props.location.state.thirdPartySettlementSplitAccounts.length === 0
              ? [
                  {
                    bank: { id: "", name: "Select Bank" },
                    currency: { id: "", name: "Select Currency" },
                    accountNumber: "",
                    thirdPartyShare: 0,
                  },
                ]
              : props.location.state.thirdPartySettlementSplitAccounts,
        };
      }
    }

    return null;
  }

  setInitialState = () => {
    const {
      revenueSplitDetails,
      thirdPartySettlementSplitAccounts,
    } = this.state;
    const { splitTypeId } = revenueSplitDetails;

    this.setState({
      splitType: splitTypeId,
      settlementAccounts: thirdPartySettlementSplitAccounts,
      revenueId: revenueSplitDetails.revenueId,
    });

    //initialize settlement account error array based on number of items in the settlement accounts array
    //Also verify account numbers immediately
    thirdPartySettlementSplitAccounts &&
      thirdPartySettlementSplitAccounts.map(async (item, i) => {
        await this.addSettlementAccountError();
        this.verifyAccount(
          { accountNumber: item.accountNumber, bankId: item.bank.id },
          i
        );
      });
  };

  setAgencyBasedOnRole = () => {
    const { agencyDetails } = this.state;
    const { user } = this.props;

    this.setState({
      agencyId:
        user.vggRole === appConstants.ROLES.VGG_ADMIN //if user is a vgg admin
          ? agencyDetails.id //use the selected agency from the previous route
          : user.organizationId, //else use logged in user organizationId
    });
  };

  fetchSettlementSplitTypes = () => {
    let { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    settlementSplitService.getSettlementSplitTypes(source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          splitTypes: response.data,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  fetchBanks = () => {
    let { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    settlementAccountService.getAllBanks(source.token).then((res) => {
      let response = res.response;
      dispatch(alertActions.stopRequest());
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({
          banks: response.data,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  getCurrency = () => {
    let { dispatch } = this.props;

    dispatch(alertActions.startRequest());
    commonService.getCurrency(source.token).then((res) => {
      dispatch(alertActions.stopRequest());
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState({ currencies: response.data });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  /**
   *
   * @param {Object} payload An object of the form { accountNumber: "", bankId: 0};
   * @param {Number} index
   */
  verifyAccount = (payload, index) => {
    this.setState({
      currentIndex: index,
      verifyingAccount: true,
    });
    settlementAccountService
      .verifyAccount(payload, source.token)
      .then((res) => {
        this.setState({
          verifyingAccount: false,
        });
        let response = res.response;
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          //set index of the verified account here
          this.setAccountVerificationDetails(index, response);
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          this.setAccountVerificationDetails(index, response);
        }
      });
  };

  setAccountVerificationDetails = (index, response) => {
    let { settlementAccounts } = this.state;
    settlementAccounts[index]["accountVerificationDetails"] = {
      message: response.message,
      ...response.data,
    };
    this.setState({
      settlementAccounts,
    });
  };

  updateSettlementSplitApproval = () => {
    const {
      splitType,
      agencyId,
      revenueId,
      settlementAccounts,
      componentErrorMessages,
    } = this.state;
    const { dispatch } = this.props;

    //parse Account details to API required payload format
    let formattedSettlementAccounts = settlementAccounts.map(
      ({ currency, accountNumber, thirdPartyShare, bank, accountId }) => {
        return {
          accountId,
          accountNumber,
          bankId: bank ? bank.id : 0,
          currencyId: currency ? currency.id : 0,
          thirdPartyShare,
        };
      }
    );
    let payload = {
      splitType,
      revenueIds: [revenueId],
      agencyId,
      settlementAccounts: formattedSettlementAccounts,
    };

    if (this.validateInput()) {
      dispatch(alertActions.startRequest());
      settlementSplitService
        .updateSettlementSplitApproval(payload, source.token)
        .then((res) => {
          dispatch(alertActions.stopRequest());

          let response = res.response;
          if (res.status === appConstants.SUCCESS_RESPONSE) {
            dispatch(alertActions.success(response.message));
            // this.handleChangeRoute("/review-settlement-split", "payload", payload);
          } else if (res.status === appConstants.ERROR_RESPONSE) {
            dispatch(alertActions.error(response.message));
          }
        });
    } else {
      dispatch(
        alertActions.error(
          componentErrorMessages.settlementAccountRevenueLinesError
            ? "Please select at least one revenue line"
            : "Ensure all fields are properly filled"
        )
      );
    }
  };

  /**@description Add new error fields to settlementAccountError
   *
   **/

  addSettlementAccountError = () => {
    this.setState({
      settlementAccountError: [
        ...this.state.settlementAccountError,
        {
          bankError: "",
          accountNumberError: "",
          currencyError: "",
          thirdPartyShareError: "",
        },
      ],
    });
  };

  /**
   * @param {String} pathname path to be routed to
   * @param {String} attribute name of the state attribute in new route
   * @param {*} value state to be passed into the new route
   */
  handleChangeRoute = (pathname, attribute, value) => {
    this.props.history.push({
      pathname,
      state: { [attribute]: value },
    });
  };

  addSettlementAccount = () => {
    this.addSettlementAccountError();
    this.setState({
      settlementAccounts: [
        ...this.state.settlementAccounts,
        {
          currency: "",
          bank: "",
          accountNumber: "",
          thirdPartyShare: "",
        },
      ],
    });
  };

  removeSettlementAccount = (index) => {
    if (this.state.settlementAccounts.length > 1) {
      this.removeSettlementAccountError();

      const settlementAccounts = this.state.settlementAccounts.filter(
        (e, idx) => idx !== index
      );
      this.setState({ settlementAccounts });
    }
  };

  handleRadioButtonChange = (event, attribute, value) => {
    this.setState({ [attribute]: value });
  };

  handleChange = (event, attribute) => {
    this.setState({ [attribute]: event.target.checked });
  };

  handleChangeAccountDetails = (event, attribute, index) => {
    const { settlementAccounts } = this.state;
    settlementAccounts[index][attribute] = event.target.value;
    this.setState({ settlementAccounts });

    //call the API only when the account number field has 10 digits
    if (attribute === "accountNumber") {
      if (
        settlementAccounts[index]["accountNumber"].length >= 10 &&
        settlementAccounts[index]["bank"].id
      ) {
        let payload = {
          accountNumber: settlementAccounts[index]["accountNumber"],
          bankId: settlementAccounts[index]["bank"].id,
        };
        this.verifyAccount(payload, index);
      }
    }
  };

  handleSelectChangeAccountDetails = (payload, attribute, index) => {
    const { settlementAccounts } = this.state;
    settlementAccounts[index][attribute] = payload;
    this.setState({ settlementAccounts });

    //call the acount verification API whan bank is selected and
    // when the account number field has 10 digits

    if (attribute === "bank") {
      if (
        settlementAccounts[index]["accountNumber"].length >= 10 &&
        settlementAccounts[index]["bank"].id
      ) {
        let payload = {
          accountNumber: settlementAccounts[index]["accountNumber"],
          bankId: settlementAccounts[index]["bank"].id,
        };
        this.verifyAccount(payload, index);
      }
    }
  };

  /**@description Add new error fields to settlementAccountError
   *
   **/

  addSettlementAccountError = () => {
    this.setState({
      settlementAccountError: [
        ...this.state.settlementAccountError,
        {
          bankError: "",
          accountNumberError: "",
          currencyError: "",
          thirdPartyShareError: "",
        },
      ],
    });
  };

  /**@description remove error fields to settlementAccountError
   *
   **/
  removeSettlementAccountError = (index) => {
    if (this.state.settlementAccountError.length > 1) {
      const settlementAccountError = this.state.settlementAccountError.filter(
        (e, idx) => idx !== index
      );
      this.setState({ settlementAccountError });
    }
  };

  /**
   * @description validates all inputs on the view
   */
  validateInput = () => {
    let _errs_ = {
      splitTypeError: "",
      settlementAccountError: this.state.settlementAccountError,
    };

    let formValid = true;
    if (this.state.splitType === "") {
      _errs_["splitTypeError"] = "Please select a split type";
      formValid = false;
    }

    if (this.state.settlementAccounts) {
      this.state.settlementAccounts.map((account, i) => {
        let settlementAccountError = _errs_["settlementAccountError"];

        //if bank is empty, return an error
        if (!account.bank || account.bank.id === "") {
          settlementAccountError[i]["bankError"] = "Please select a bank";
          formValid = false;
        } else {
          settlementAccountError[i]["bankError"] = "";
        }

        //if account name is missing from the settlement accounts array,
        // return an error stating that one of the accounts still needs verification
        if (
          !account.accountVerificationDetails ||
          !account.accountVerificationDetails.accountName
        ) {
          settlementAccountError[i]["accountNumberError"] =
            "Account Number lookup failed";
          formValid = false;
        } else {
          settlementAccountError[i]["accountNumberError"] = "";
        }

        //if currency is empty, return an error
        if (!account.currency || account.currency.id === "") {
          settlementAccountError[i]["currencyError"] =
            "Please select a currency";
          formValid = false;
        } else {
          settlementAccountError[i]["currencyError"] = "";
        }

        //if third party share is less than 0, return an error
        if (account.thirdPartyShare <= 0) {
          settlementAccountError[i]["thirdPartyShareError"] =
            "Third party share must be greater than 0";
          formValid = false;
        }
        //if the split type is not a flat fee, the third party share should be 99 or below
        else if (this.state.splitType !== 1 && account.thirdPartyShare >= 100) {
          settlementAccountError[i]["thirdPartyShareError"] =
            "Third party share must not be greater than 99";
          formValid = false;
        } else {
          settlementAccountError[i]["thirdPartyShareError"] = "";
        }

        return account;
      });
    }

    this.setState({
      componentErrorMessages: _errs_,
      settlementAccountError: _errs_.settlementAccountError,
    });
    return formValid;
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  render() {
    const {
      splitType,
      splitTypes,
      banks,
      currencies,
      settlementAccounts,
      revenueSplitDetails,
      currentIndex,
      verifyingAccount,
      componentErrorMessages,
    } = this.state;
    const { revenueName } = revenueSplitDetails;
    const { requesting } = this.props;

    let accountErrorMessages = componentErrorMessages.settlementAccountError;

    return (
      <div>
        <ContentLayout
          requesting={requesting}
          pageTitle={`Edit ${revenueName.toLowerCase()}`}
          actions={
            <div className={"float-right"}>
              <Button
                outline
                color="info"
                className=" btn-rounded mr-2 big-action-btn"
                onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
              >
                <FaArrowLeft className={"icon-margin"} />
                Back
              </Button>
            </div>
          }
        >
          <h2 style={{ fontWeight: "700" }}>Split Type</h2>
          <hr />

          <FormGroup tag="fieldset" className="my-5">
            {splitTypes &&
              splitTypes.map((type) => {
                return (
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        checked={splitType === type.id}
                        style={{ width: "30px" }}
                        onClick={(e) =>
                          this.handleRadioButtonChange(e, "splitType", type.id)
                        }
                      />
                      {type.name}
                    </Label>
                  </FormGroup>
                );
              })}
          </FormGroup>

          {splitTypes.length === 0 && !requesting && (
            <EmptyRecordMessage message={"No Split types found"} />
          )}

          <h2 className="mt-5" style={{ fontWeight: "700" }}>
            Third Party Account
          </h2>
          <hr style={{ height: "12px" }}></hr>
          <ThirdPartySettlementAccountForm
            banks={banks}
            currencies={currencies}
            splitType={splitType}
            settlementAccounts={settlementAccounts}
            handleSelectChangeAccountDetails={
              this.handleSelectChangeAccountDetails
            }
            handleChangeAccountDetails={this.handleChangeAccountDetails}
            currentIndex={currentIndex}
            verifyingAccount={verifyingAccount}
            accountErrorMessages={accountErrorMessages}
            addSettlementAccount={this.addSettlementAccount}
            removeSettlementAccount={this.removeSettlementAccount}
          />

          <div className="float-right mt-5">
            <Button
              outline
              className="btn-sm btn-rounded px-4 mr-2 cancel-btn"
              onClick={() => appHelpers.navigateToPreviousRoute.call(this)}
            >
              Go back
            </Button>
            <Button
              color="success"
              className="btn-sm btn-rounded  mr-2 text-white"
              onClick={() => this.updateSettlementSplitApproval()}
              disabled={requesting || verifyingAccount}
            >
              Proceed
            </Button>
          </div>
        </ContentLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, requesting, apiReducer } = state;
  return {
    user,
    requesting,
    apiReducer,
  };
};

export default connect(mapStateToProps)(EditSettlementSplit);
